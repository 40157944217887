@import "../../theme/main.scss";
@layer defaultprimengtable,
primemg-nobordertbl;

@layer defaultprimengtable {
  p-table {
    .p-datatable-scrollable-header-box {
      padding-right: 0;
    }

    table {
      font-size: $text-default-size;
      font-family: $font-family;
    }

    .p-datatable .p-datatable-thead>tr>th {
      font-size: $text-medium-size;
      font-weight: map-get($font-weights, 'medium');
      color: $utl-text-color-4;
      background-color: $utl-grey-color-14;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }

    .p-datatable .p-datatable-tbody>tr>td {
      font-size: $text-medium-size;
      color: $utl-text-color-4;
      font-weight: map-get($font-weights, 'medium');

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

}

.leaveBalanceTable {
  p-table {
    .p-datatable .p-datatable-thead>tr>th {
      border: none;
      font-size: $text-size-11;
      font-weight: map-get($font-weights , 'semi-bold');
      padding-left: 7px;
      height: 40px;

      &:first-child {
        padding-left: 15px;
      }

      .p-datatable tbody tr:nth-child(even) {
        background-color: #ffffff !important;
      }
    }

    .p-datatable .p-datatable-tbody>tr>td {
      border: none;
      border-top: solid 1px #efefef;
      height: 48px;
      font-size: $text-size-11;
      font-weight: map-get($font-weights , 'semi-bold');

      span {
        display: initial;
        width: auto;

      }
    }

    .p-datatable .p-datatable-tbody>tr>.detailContainer {
      border-top: none;
      background: #fff;
      padding-bottom: 30px;
    }
  }
}


/**************** Expense grid design ****************/
.table-expense {
  @include padding(left, top, right, bottom, 0, 0, 0, 0);

  .p-datatable {

    .p-datatable-scrollable-wrapper,
    .p-datatable-wrapper {
      @include border-radius(2px);
      @include border(1px, solid, $utl-grey-color-10);



      table {
        border-collapse: collapse;
        width: 100%;
        table-layout: fixed;

        .p-datatable-thead>tr>th {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, semi-bold);
          color: rgba(38, 46, 62, 0.87);
          @include background-color(transparent);
          @include padding(left, top, right, bottom, 10, 16, 10, 16);
          border: 0;

          span {
            display: block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .p-datatable-tbody {
          tr {
            &:nth-of-type(odd) {
              @include background-color($utl-grey-color-9);
            }

            &:nth-of-type(even) {
              @include background-color(transparent);
            }

            td {
              font-family: $font-family;
              font-size: $text-default-size;
              font-weight: map-get($font-weights, regular);
              @include padding(left, top, right, bottom, 10, 5, 10, 5);
              color: rgba(38, 46, 62, 0.87);
              border: 0;
            }
          }
        }
      }
    }

    .p-paginator-bottom {
      border: none;
      text-align: right;
      @include margin(left, top, right, bottom, 0, 25, 0, 0);
      @include background-color(transparent);
      background: none;
      justify-content: flex-end;

      .p-paginator-first {
        @include border(1px, solid, #c4c6cb);
        border-radius: 3px 0 0 3px;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        @include padding(left, top, right, bottom, 14, 6, 14, 6);

      }

      .p-paginator-prev {
        @extend .p-paginator-first;
        border-left: 0;
        border-radius: 0;
      }

      .p-paginator-pages {
        border-left: 0;
        border-radius: 0;


        .p-paginator-page {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          &.p-highlight {
            @extend .p-paginator-first;
            @include background-color($utl-theme-color);
            color: $utl-white;
            border-color: #00bfa5;
            border-radius: 0;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            @include padding(left, top, right, bottom, 14, 6, 14, 6);
          }
        }
      }

      .p-paginator-next {
        @extend .p-paginator-first;
        @include border-radius(0);
        border-left: 0;
        border-right: 0;
      }

      .p-paginator-last {
        @extend .p-paginator-first;
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

// attendance table
.p-element {
  &.YTDcustomTable {
    .p-datatable {
      .p-datatable-wrapper {
        table {
          .p-datatable-thead {
            tr>th {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding(left, top, right, bottom, 8, 8, 8, 8);
              @include background-color(#4d5ebb);
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, 'medium');
              text-align: left;
              color: #f3f3f3;
              @include border-pos(bottom, 0px, solid, transparent);
              @include border-pos(top, 1px, solid, #e2e2e2);
              @include border-pos(left, 1px, solid, #e2e2e2);
              @include border-pos(right, 1px, solid, #e2e2e2);
            }
          }


        }
      }

      .p-datatable-tbody {
        tr>td {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          @include padding(left, top, right, bottom, 8, 8, 8, 8);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'regular');
          text-align: left;
          color: $utl-text-color-4;
          @include border-pos(bottom, 1px, solid, #e2e2e2);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(left, 1px, solid, #e2e2e2);
          @include border-pos(right, 1px, solid, #e2e2e2);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}

// candidate document table css
.cls-doctbl {
  &.p-datatable {
    table {
      .p-datatable-thead>tr>th {

        opacity: 0.87;
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, 'semi-bold');
        color: $utl-text-color-4;
        @include background-color;
        @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));
        @include border-pos(top, 0px, solid, rgba(151, 151, 151, 0.5));
        @include border-pos(left, 0px, solid, rgba(151, 151, 151, 0.5));
        @include border-pos(right, 0px, solid, rgba(151, 151, 151, 0.5));
        @include padding(left, top, right, bottom, 16, 16, 10, 16);

        &:nth-child(1) {
          border-top-left-radius: 4px;
        }

        &:nth-child(5) {
          border-top-right-radius: 4px;
        }
      }

      .p-datatable-tbody>tr>td {

        opacity: 0.87;
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, 'regular');
        line-height: 2;
        color: $utl-text-color-4;
        @include background-color;
        @include border(0px, solid, rgba(151, 151, 151, 0.5));
        @include padding(left, top, right, bottom, 16, 8, 10, 8);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;


      }

      .p-datatable-tbody>tr:nth-child(odd) {
        background: #d5d5da
      }

      .p-datatable-tbody>tr>td:nth-child(1) {
        font-weight: map-get($font-weights, 'semi-bold');
      }
    }
  }
}

// salary structure table css
/*************** P-table css *********************/
.SalaryGrid {
  @include border(1px, solid, rgba(151, 151, 151, 0.5));

  &.p-datatable {
    .p-datatable-wrapper {
      table {
        thead {
          tr {
            th {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding-em (left, top, right, bottom, 1, 0.9, 1, 0.9);
              @include background-color(#f5f5f5);
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "semi-bold");
              text-align: left;
              color: $utl-text-color-4;
              @include border(0px, solid, transparent);

            }
          }
        }

        tbody {
          tr {
            td {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding-em (left, top, right, bottom, 0, 0, 0, 0);
              @include background-color;
              font-size: $text-medium-size;
              text-align: left;
              color: $utl-text-color-4;
              @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));
              @include border-pos(top, 0px, solid, transparent);
              @include border-pos(left, 0px, solid, transparent);
              @include border-pos(right, 0px, solid, transparent);
              font-weight: map-get($font-weights, 'medium');

              .spacing {
                @include padding-em (left, top, right, bottom, 1, 0.5, 1, 0.5);
                display: flex;
              }

              .cls-salaryinput {
                height: 32px;
                @include border-pos(left, 1px, solid, rgba(151, 151, 151, 0.5));
                @include border-pos(top, 0px, solid, transparent);
                @include border-pos(right, 0px, solid, transparent);
                @include border-pos(bottom, 0px, solid, transparent);
                @include background-color;
                margin-top: 0px !important;

                .mat-mdc-text-field-wrapper {
                  border-radius: 0 !important;

                  .mat-mdc-form-field-infix {
                    padding: 0 !important;
                    min-height: auto;

                    input {

                      background-color: #fff;

                      &.mat-mdc-input-element {


                        @include padding(left, top, right, bottom, 10, 0, 10, 0);
                        @include border-pos(left, 0.5px, solid, rgba(151, 151, 151, 0.5));
                        @include background-color;
                        vertical-align: middle;
                        box-sizing: border-box;
                        @include border-pos(top, 0, solid, transparent);
                        @include border-pos(right, 0, solid, transparent);
                        @include border-pos(bottom, 0, solid, transparent);
                        height: 32px;

                        &:focus {
                          border-color: #00bfa5;
                        }

                        &:disabled {
                          color: rgba(0, 0, 0, .38);
                          @include background-color(#f8f8f9);
                        }
                      }

                      &.cls-fbpInput {
                        color: $utl-white;
                        @include background-color(#5cc0a5);
                      }
                    }
                  }

                  .mdc-line-ripple {
                    display: none;
                  }
                }

                // .mat-mdc-form-field-subscript-wrapper {
                //   display: none !important
                // }

                &.mat-form-field-disabled {
                  .mat-mdc-text-field-wrapper {
                    border-radius: 0;

                    .mat-mdc-form-field-flex {
                      .mat-mdc-form-field-infix {
                        padding: 0 !important;

                        input {
                          background-color: #f8f8f9;
                          height: 32px;
                          padding: 0 10px;
                        }
                      }
                    }
                  }
                }
              }


            }

            &:last-child>td {
              @include border-pos(bottom, 0px, solid, transparent);
            }
          }

          &tr:nth-child(even) {
            @include background-color(transparent);
          }
        }
      }
    }
  }
}



// salary ytd table css
.SalaryYTDcustomTable {
  &.p-datatable {
    table {
      thead {
        tr {
          th {
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            @include padding(left, top, right, bottom, 10, 8, 10, 8);
            @include background-color(#4d5ebb);
            font-size: $text-size-11;
            text-align: left;
            color: #f3f3f3;
            @include border-pos(bottom, 0, solid, transparent);
            @include border-pos(top, 1px, solid, #e2e2e2);
            @include border-pos(left, 1px, solid, #e2e2e2);
            @include border-pos(right, 1px, solid, #e2e2e2);
            font-weight: map-get($font-weights, 'medium');
          }
        }
      }

      .p-datatable-scrollable-body {
        tbody {
          tr {
            td {
              text-align: left;
              color: $utl-text-color-4;
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding(left, top, right, bottom, 10, 8, 10, 8);
              font-size: $text-small-size;
              @include border-pos(bottom, 1px, solid, #e2e2e2);
              @include border-pos(top, 0, solid, transparent);
              @include border-pos(left, 1px, solid, #e2e2e2);
              @include border-pos(right, 1px, solid, #e2e2e2);
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}

// ErrorcustomTable

.ErrorcustomTable {
  .p-datatable {
    table {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;

      .p-datatable-thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          @include padding(left, top, right, bottom, 15, 8, 15, 8);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'medium');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          /* white-space: nowrap; */
          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: 18%;
          }
        }

        span {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .p-datatable-tbody {
        tr>td {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          @include padding(left, top, right, bottom, 15, 8, 15, 8);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'regular');
          text-align: left;
          color: $utl-text-color-4;
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);

          &:nth-child(1) {
            width: 10%;
          }

          &:nth-child(2) {
            width: 18%;
          }
        }

        span {
          display: block;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

    }
  }
}

// ytd css
.YTDcustomTable .p-datatable-scrollable-header-box {
  padding-right: 17px !important;
}

// primeng  no border table
.addbordertbl {
  .p-datatable-scrollable-view {
    border: 1px solid $utl-grey-color-10;
  }
}

@layer nobrdertbl {
  .primemg-nobordertbl {
    .p-datatable-scrollable-view {

      thead {
        tr {
          background-color: $teampabelbg;

          th {
            font-size: $text-name !important;
            font-family: $font-family !important;
            border-bottom-color: $utl-grey-color-10;
            color: rgba(40, 46, 61, 0.6) !important;
            border: 0 !important;
            padding: 10px 12px !important;
          }

          &:first-child {
            border-bottom: 1px solid $utl-grey-color-10 !important;
          }


        }
      }

      tbody {
        tr {
          border-bottom: 1px solid $utl-grey-color-10 !important;

          td {
            font-size: $text-name !important;
            font-style: normal !important;
            color: $utl-text-color-12 !important;
            padding: 10px 16px !important;
            border: 0 !important;

          }

        }
      }
    }

  }
}


// document table css

/************* custom table css ***********/
p-table {
  .p-datatable {
    &.customTable {

      table {
        thead {
          tr>th {
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            font-family: $font-family;
            @include padding(left, top, right, bottom, 15, 10, 15, 10);
            @include background-color($utl-grey-color-14);
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, 'medium');
            text-align: left;
            color: $utl-text-color-4;
            @include border(0px, solid, transparent);
          }

        }

        tbody {
          tr>td {
            @extend th;
            @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
            @include border-pos(top, 0px, solid, transparent);
            @include border-pos(right, 0px, solid, transparent);
            @include border-pos(left, 0px, solid, transparent);
          }

          tr {
            &:nth-child(even) {
              @include background-color(#fafafb);
            }
          }
        }
      }


    }


    p-paginator {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .p-paginator {

        @include border(0px, solid, transparent);
        @include background-color;
        @include margin(left, top, right, bottom, 0, 10, 0, 0);
        @include border-radius;

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
          @include border(1px, solid, #c4c6cb);

          &:focus {
            box-shadow: none;
          }
        }

        .p-paginator-pages {
          .p-paginator-page {
            @include border(1px, solid, #c4c6cb);

            &.p-highlight {
              @include background-color(#00bfa5);
              color: $utl-white;
              border-color: #00bfa5;
            }

            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

  }
}

/************* custom table css ***********/
p-table {
  &.no-padding {
    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-view {
        .p-datatable-scrollable-header-box {
          padding-right: 0 !important;


        }
      }
    }

    .p-datatable {
      thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          @include padding(left, top, right, bottom, 15, 10, 15, 10);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'medium');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;
          }
        }

      }


      tbody {
        tr>td {
          @extend th;
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;

            a {
              .pi {
                font-size: $text-size-11;
              }
            }
          }
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}

p-table {

  &.customTable {
    .p-datatable {
      thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          @include padding(left, top, right, bottom, 15, 10, 15, 10);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'medium');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;
          }
        }

      }

      tbody {
        tr>td {
          @extend th;
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;

            a {
              .pi {
                font-size: $text-size-11;
              }
            }
          }
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}