@import "_progressbar";
@import "_form-new";
@import "_applicability";
@import "_light-box";
@import "_chips";
@import "_button";
@import "print";

.no-border {
  @include border(0, solid, transparent);
}

.ui-widget {
  font-size: inherit;
}

.ui-carousel-button {
  width: 2.85714em;
  height: 2.85714em;
  @include abs-pos(calc(50% - 19px), initial, 0, initial);
  @include background(center center);
  @include background-color($utl-text-color-4);
  @include border-radius($radius: 96px);
  background-size: $size-14;
  @include shadow(2);
  cursor: pointer;
}

ul {
  list-style: none;
}

.avatar-content {
  max-height: 100%;
}

.mat-mdc-menu-panel {
  position: relative;
}

._hr_carousel {
  .ui-carousel {
    @extend .no-border;
    @include background-color(transparent);
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    font-size: inherit;

    .ui-carousel-header {
      @include border(0, solid, transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      height: 0;

      .ui-carousel-page-links {
        display: none;
      }

      .ui-carousel-prev-button {
        left: -19px;
        background-image: url(#{$assetPath}/icon/right-arrow-forward.svg);
        transform: rotate(180deg);
      }

      .ui-carousel-next-button {
        right: -19px;
        background-image: url(#{$assetPath}/icon/right-arrow-forward.svg);
      }

      .ui-state-disabled {
        @extend .ui-carousel-page-links;
      }
    }

    .ui-carousel-viewport {
      .ui-carousel-items {
        li {
          @include margin(left, top, right, bottom, 5, 5, 5, 5);
          @extend .no-border;
          @include background-color(transparent);
        }
      }
    }
  }
}

.arrow-circle {
  background-image: url(#{$assetPath}/icon/right-arrow-forward.svg);
  @extend .ui-carousel-button;
  @include abs-pos(calc(50% - 19px), -24px, 0, initial);
}

.chips-design {
  @include background-color(#e0e0e0 !important);
  @include border-radius(2px);
  @include margin(left, top, right, bottom, 0, 0, 8, 8);
}

.chips-text-styling {
  font-size: $text-medium-size;
  color: #424242;
  font-weight: map-get($font-weights, "semi-bold");
}

.form-material {
  .ui-widget {
    input {
      font-size: $text-default-size;
      color: $utl-grey-color-6;
      line-height: 20px;
    }

    .ui-autocomplete-token {
      @extend .chips-design;
      padding: 8px 10px 7px !important;

      .ui-autocomplete-token-icon {
        right: 8px;
        top: 55%;
      }

      .ui-autocomplete-token-label {
        @extend .chips-text-styling;
      }
    }
  }

  .autoCompleteOne {
    .ui-autocomplete-panel {
      ul {
        padding: 0;

        .ui-autocomplete-list-item {
          padding: 11px 18px 11px 0 !important;

          .ui-helper-clearfix {
            div {
              font-size: $text-default-size;
              color: $utl-text-color-12;
              font-weight: map-get($font-weights, "medium");
            }
          }
        }
      }
    }

    .ui-autocomplete-panel::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 10px;
      background-color: #f0f2f4;
    }

    .ui-autocomplete-panel::-webkit-scrollbar {
      width: 8px;
      background-color: #f0f2f4;
    }

    .ui-autocomplete-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.reportsubdropdown {
  display: flex;
  max-width: 455px;

  div {
    font-family: $font-family;
    font-size: $text-medium-size;
    color: $utl-text-color-22;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;

    b {
      font-size: $text-default-size;
      color: $utl-text-color-4;
    }
  }
}

.archive-box {
  .ui-fluid {
    @extend .chips-design;
    height: 24px;
    @include padding(left, top, right, bottom, 8, 0, 8, 0);
    @extend .chips-text-styling;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    line-height: 24px;
  }
}

// scroolable tabs----------------
._user-snippet {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-labels {
    flex-wrap: wrap;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label {
      margin: 0;
      max-width: 33.33%;
      flex: 0 0 33.33%;
      min-width: 33.33%;
      @include padding(left, top, right, bottom, 5, 16, 5, 16);

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-content {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        color: rgba(27, 36, 54, 0.7);

        .cls-counter {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          color: rgba(27, 36, 54, 0.7);
          @include padding(left, top, right, bottom, 5, 0, 0, 0);
        }
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-active {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-content {
        color: map-get($heading-colors, "dark-color");
        font-weight: map-get($font-weights, "bold");

        .cls-counter {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          color: rgba(27, 36, 54, 0.7);
          @include padding(left, top, right, bottom, 5, 0, 0, 0);
        }
      }
    }
  }
}

// Scrollbar--------------
._custom-scroll ._custom-scroll::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
  background-color: transparent;
}

._custom-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

._custom-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: none;
  background-color: #f2f2f2;
}

._custom-scroll.custom-new::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

._custom-scroll.custom-new::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: none;
  background-color: $utl-grey-color-32;
  transition: ease-in-out all 0.1s;

  &:hover {
    background-color: #E6E6E6;
  }
}

// not-allowed-card-----------------
._not-allowed-card {
  @include border-radius;
  @include background-color(rgba(255, 12, 62, 0.1));
  @include padding(left, top, right, bottom, 12, 10, 12, 10);

  figure {
    width: 24px;

    img {
      width: 100%;
    }
  }

  .error-msg {
    width: calc(100% - 24px);
    @include padding(left, top, right, bottom, 12, 0, 0, 0);

    h3,
    p {
      color: $utl-text-color-11;
    }

    h3 {
      font-weight: map-get($font-weights, "bold");
      line-height: 20px;
      font-size: $text-default-size;
    }

    p {
      font-weight: map-get($font-weights, "medium");
      line-height: 16px;
      font-size: $text-medium-size;
    }
  }
}

.checkbox-wrap {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  mat-checkbox {
    font-size: $text-default-size;

    span {
      font-weight: map-get($font-weights, "medium");
    }
  }
}

mat-form-field.custom-for-letter {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include padding(left, top, right, bottom, 0, 0, 0, 8);

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {
          label {
            font-size: $text-medium-size !important;
          }
        }
      }
    }
  }
}

.loan_inbox {
  .no-loan-inbox {
    .loan_inbox {
      max-width: 100%;
      margin-top: -29px;
      padding-bottom: 0;
    }
  }
}

.loan_inbox {
  .workflow_base {
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 0 0 !important;
    position: initial !important;
    left: 0;
    top: 0;
    width: 100%;

    .workflow_base_box1 {
      padding: 0 !important;

      &:before {
        left: 24px !important;
      }
    }

    .workflow_base_title1 {
      padding-left: 0 !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cls-enterlabel {
  font-size: $text-default-size;
  font-weight: map-get($font-weights, "medium");
  color: #6f778b;
  left: 0;
}

/************** Plan Tab Css ****************/
.cls-plantab {
  .mat-mdc-tab-header {
    @include padding(left, top, right, bottom, 16, 0, 16, 0);

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include margin(left, top, right, bottom, 0, 0, 25, 0);
            height: 64px;
            opacity: 1;

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label-content {
              font-family: $font-family;
              font-size: $heading-extra-small-font-size;
              font-weight: (map-get($font-weights, semi-bold));
              color: $utl-text-color-14;
            }
          }
        }
      }
    }
  }
}

/****************** Split pms btn css **********************/
.cls-krabtn {
  width: 100%;
  @include margin-em(left, top, right, bottom, 0, 1.642, 0, 0.5);

  .p-splitbutton,
  .ui-buttonset {
    width: 100%;

    .p-splitbutton-defaultbutton {
      width: 90%;
      @include border(1px, solid, $utl-theme-color);
      @include background-color(transparent);
      height: 40px;
      font-size: inherit;

      &:focus {
        box-shadow: none;
      }

      .p-button-label {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: (map-get($font-weights, semi-bold));
        text-align: center;
      }
    }

    .p-splitbutton-menubutton {
      @include border-pos(top, 1px, solid, $utl-theme-color);
      @include border-pos(right, 1px, solid, $utl-theme-color);
      @include border-pos(bottom, 1px, solid, $utl-theme-color);
      @include border-pos(left, 0px, solid, transparent);
      @include background-color(transparent);
      width: 10%;
      height: 40px;

      &:focus {
        box-shadow: none;
      }

      .ui-button-text {
        @include padding(left, top, right, bottom, 0, 12, 0, 12);
      }
    }

    p-menu {
      @include background-color;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);

      ul {
        .p-menuitem {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          .p-menuitem-link {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: (map-get($font-weights, medium));
            color: $utl-text-color-12;
            line-height: 2;
            height: 42px;
            @include padding(left, top, right, bottom, 10, 0, 10, 0);

            &:focus {
              box-shadow: none;
            }

            &:hover {
              @include background-color($utl-grey-color-17);
            }
          }
        }
      }
    }
  }
}


.cls-spilbtn {
  width: 100%;
  @include margin-em(left, top, right, bottom, 0, 0, 0, 0);

  .p-splitbutton,
  .ui-buttonset {
    width: 100%;

    .p-splitbutton-defaultbutton {
      width: 85%;
      @include border(1px, solid, $utl-theme-color);
      @include background-color($utl-theme-color);
      height: 28px;
      font-size: inherit;
      color: $utl-white;

      &:focus {
        box-shadow: none;
      }

      .p-button-label {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: (map-get($font-weights, semi-bold));
        text-align: center;
        color: $utl-white;
      }
    }

    .p-splitbutton-menubutton {
      @include border-pos(top, 1px, solid, $utl-theme-color);
      @include border-pos(right, 1px, solid, $utl-theme-color);
      @include border-pos(bottom, 1px, solid, $utl-theme-color);
      @include border-pos(left, 1px, solid, $utl-white);
      @include background-color($utl-theme-color);
      width: 15%;
      height: 28px;

      &:focus {
        box-shadow: none;
      }

      .p-button-icon {
        color: $utl-white;
      }

      .ui-button-text {
        @include padding(left, top, right, bottom, 0, 12, 0, 12);
      }
    }

    p-menu {
      @include background-color;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);

      ul {
        .p-menuitem {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          @include border-pos(bottom, 1px, solid, rgba(33, 32, 32, 0.1));

          &:last-child {
            @include border-pos(bottom, 0px, solid, rgba(33, 32, 32, 0.1));
          }


          .p-menuitem-link {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: (map-get($font-weights, medium));
            color: $utl-text-color-12;
            line-height: 2;
            height: 40px;
            @include padding(left, top, right, bottom, 10, 0, 10, 0);

            .p-menuitem-text {
              font-size: $text-default-size;
              color: #212020;
              text-transform: capitalize;
            }

            &:focus {
              box-shadow: none;
            }

            &:hover {
              @include background-color($utl-grey-color-17);
            }
          }
        }
      }
    }
  }
}

/**************** Goal craetion tab css ***********************/
.cls-goalkrabox {
  .mat-mdc-tab-header {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            max-width: 100%;
            flex: 0 0 100%;
            opacity: 1;
          }
        }

        .mat-ink-bar {
          @include background-color($secondary-text-color);
          // min-width: 33%;
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    .mat-mdc-tab-body {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-body-content {
        .cls-bodybox {
          @include padding-em(left, top, right, bottom, 2.142, 2.142, 2.142, 1.142);

          .cls-innerbox {
            @include margin-em(left, top, right, bottom, 0, 0, 0, 1.214);
          }
        }
      }
    }
  }
}



.blank-state-text {
  figure {
    margin: 0 auto 16px;
    max-width: 28%;

    img {
      width: 100%;
    }
  }

  h3 {
    font-size: $heading-extra-lagre-font-size;
    color: rgba(162, 172, 183, 0.5);
    font-weight: (map-get($font-weights, bold));
    line-height: 44px;
  }
}

#goal-KRA {
  .mat-expansion-panel-content {
    .mat-expansion-panel-body {
      .cls-contentbox {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include margin(left, top, right, bottom, 15, 0, 15, 0);
        @include border-pos(bottom, 1px, dashed, $utl-grey-color-10);
        font-size: inherit;

        .cls-KPIMain {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: (map-get($font-weights, "semi-bold"));
          line-height: 1.33;
          color: $secondary-text-color;
          border: 0;
          @include padding(left, top, right, bottom, 0, 16, 0, 10);
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          white-space: pre-wrap;
          display: flex;

          .cls-kpishow {
            .subtitle {
              margin-top: 0px;
            }
          }
        }

        .cls-wghtbox {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 0, 0, 0, 16);
          font-size: inherit;

          .cls-width {
            width: 23%;
            border: 0;
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include margin(left, top, right, bottom, 0, 0, 10, 0);
            font-size: inherit;

            &:last-child {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
            }

            .cls-kpiwght {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: (map-get($font-weights, "semi-bold"));
              line-height: 1.25;
              color: rgba(40, 49, 75, 0.5);
              border: 0;
              @include padding(left, top, right, bottom, 0, 0, 0, 5);
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .cls-kpivalue {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: (map-get($font-weights, semi-bold));
              line-height: 1.4;
              color: $primary-text-color;
              border: 0;
              @include padding(left, top, right, bottom, 0, 0, 0, 0);
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .cls-contentbox:last-child {
        @include border-pos(bottom, 0px, dashed, $utl-grey-color-10);
      }
    }
  }
}

/************** flyer css **************/
.cls-flyspace {
  @include padding-em(left, top, right, bottom, 1.071, 0, 1.071, 0);

  .cls-goalkrabox {
    .mat-mdc-tab-header {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-container {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-list {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-labels {

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label {
              @include padding(left, top, right, bottom, 12, 16, 12, 16);
            }
          }
        }
      }
    }
  }
}

.cls-headerborder {
  @include border(1px, solid, rgba(151, 151, 151, 0.5));
  @include border-radius;
  display: block;
  @include margin-em(left, top, right, bottom, 0, 0, 0, 1.714);

  ._user-snippet ._snippet-inner {
    padding: 11px 16px !important;
  }

  ._user-snippet hr {
    border-bottom: 0px solid rgba(151, 151, 151, 0.5) !important;
  }
}

.cls-dilogbox.p-dialog .p-dialog-titlebar-icon {
  display: none;
}

/*********** Progress bar css *********************/


._collapse-expansion {
  .mat-expansion-indicator {
    transform-origin: 51% 68%;
    position: relative;
    top: -4px;

    &:after {
      color: $utl-text-color-14;
    }
  }
}

._circle {
  @include border-radius(100%);
  height: 72px;
  width: 72px;
}

// Stepper-=======
._linear-stepper {
  .mat-horizontal-stepper-header-container {
    display: block;
    white-space: initial;
  }

  mat-step-header {
    height: auto;
    padding: 0;
    @include margin(left, top, right, bottom, 0, 0, 8, 0);
    text-transform: uppercase;
    display: inline-block;

    .mat-step-icon-state-number,
    .mat-step-icon-state-edit {
      display: none;
    }

    .mat-step-label {
      @include background-color(#a5a5a5);
      @include padding(left, top, right, bottom, 16, 0, 16, 0);
      display: flex;
      align-items: center;
      justify-content: center;
      color: $utl-white !important;
      font-size: $text-medium-size;
      font-weight: map-get($font-weights, "medium");
      height: 26px;
      font-family: $font-family;
      @include border-radius(3px);
    }

    .mat-step-label-selected {
      @include background-color(#00bfa5);
    }
  }

  .mat-stepper-horizontal-line {
    display: none;
  }

  .mat-horizontal-content-container {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include margin(left, top, right, bottom, 0, 16, 0, 0);
  }

  aw-wizard {
    .horizontal {
      .steps-indicator {
        display: block;
        padding-top: 0 !important;

        li {
          width: auto !important;
          display: inline-flex;
          font-size: larger;
          @include margin(left, top, right, bottom, 0, 0, 8, 8);
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          a {
            @include background-color(#a5a5a5);
            @include padding(left, top, right, bottom, 16, 0, 16, 0);
            display: flex;
            align-items: center;
            justify-content: center;
            color: $utl-white !important;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "medium");
            height: 26px;
            font-family: $font-family;
            @include border-radius(3px);
          }

          &:before {
            content: none !important;
          }

          &:after {
            content: none !important;
          }
        }

        .done {
          a {
            @include background-color($utl-green-14);
          }
        }

        .current {
          a {
            @include background-color($utl-theme-color);
          }
        }

        .editing {
          a {
            @include background-color($utl-theme-color);
          }
        }
      }
    }
  }
}

// Expansion-Header====
.form-expansion {
  .mat-expansion-panel-content {
    @include border-pos(top, 1px, solid, $utl-grey-color-18);
  }

  // .mat-expansion-panel-body{
  //   padding-bottom: 0;
  // }
}

.perform-expansion {
  .mat-expansion-panel-content {
    @include border-pos(top, 0px, solid, transparent);

    .mat-expansion-panel-body {
      @include padding(left, top, right, bottom, 13, 0, 0, 0);
    }
  }
}

/*************** helpdesk tab css *********************/
.cls-helptab {
  @include margin(left, top, right, bottom, 0, -77, 0, 0);

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    @include margin(left, top, right, bottom, 0, 33, 0, 0);
  }
}

.cls-payrolltab {
  @include margin(left, top, right, bottom, 0, -25, 0, 0);

  .mat-mdc-tab-header {
    @include padding(left, top, right, bottom, 0, 25, 0, 0);
    @include border(0px, solid, transparent);

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            height: auto;
            @include padding(left, top, right, bottom, 10, 8, 10, 8);
            min-width: auto;
            font-family: $font-family;
            font-weight: map-get($font-weights, "medium");
            font-size: $text-default-size;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            opacity: 1;
            color: $secondary-text-color;
            @include border(1px, solid, rgba(151, 151, 151, 0.28));
          }

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label-active {
            @include background-color($utl-grey-color-22);

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label-content {
              font-size: $text-default-size;
              color: $secondary-text-color;
              font-weight: map-get($font-weights, "medium");
            }
          }
        }

        .mat-ink-bar {
          @include background-color(transparent !important);
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
  }
}

// #garph-tab.mat-tab-group.mat-primary .mat-ink-bar {
//   background-color: transparent !important;
// }
// #garph-tab .mat-tab-label-active {
//   background-color: #f1f1f1 !important;
// }
/***************** dashboard select on engage *******************************/
#cls-selectdashboard {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-value-text {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          color: $utl-text-color-2;
        }
      }
    }

    // .mat-form-field-label-wrapper {}
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-ripple {
        height: 0;
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#cls-selectdashboard.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label,
#cls-selectdashboard.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  font-family: $font-family;
  font-size: $text-small-size;
  font-weight: map-get($font-weights, "medium");
  color: rgba(40, 44, 63, 0.5) !important;
}

._flyer-wrapper {
  .hide-on-flyer {
    display: none;
  }
}

/************** engage graph tab ******************/
#timeoffice-gph {
  display: inline-block;
  position: relative;
  width: 100%;

  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, -28, 0, 0);
    float: right;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-labels {
      @include border-radius;
      @include border(1px, solid, $utl-blue-15);
      display: inline-block;

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label {
        height: 30px;
        line-height: 30px;
        @include border-pos(right, 1.5px, solid, $utl-blue-15);
        opacity: 1;
        cursor: pointer;
        min-width: 86px;
        color: $utl-blue-15;

        &:last-child {
          @include border-pos(right, 0px, solid, $utl-blue-15);
        }

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          color: $utl-blue-15;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-active {
        @include background-color($utl-blue-15);
        color: $utl-white;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          @include background-color($utl-blue-15);
          color: $utl-white !important;
          height: 30px;
          line-height: 30px;
          min-width: 86px;
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    width: 100%;
  }
}

#timeoffice-gph.mat-mdc-tab-group.mat-primary .mat-ink-bar,
#timeoffice-gph.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

#engage-gph {
  display: inline-block;
  position: relative;
  width: 100%;

  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, -37, 30, 0);
    float: right;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-labels {
      @include border-radius;
      @include border(1px, solid, $utl-blue-15);
      display: inline-block;
      text-align: right;

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label {
        height: 39px;
        line-height: 39px;
        // @include border-pos(right, 1.5px, solid, $utl-blue-15);
        padding: 0;
        opacity: 1;
        cursor: pointer;
        min-width: 86px;
        // color: $utl-blue-15;

        // &:last-child {
        //   @include border-pos(right, 0px, solid, $utl-blue-15);
        // }

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          // color: $utl-blue-15;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-active {
        // @include background-color($utl-blue-15);
        // color: $utl-white;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          // @include background-color($utl-blue-15);
          // color: $utl-white !important;
          height: 39px;
          line-height: 39px;
          min-width: 86px;
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    width: 100%;
    padding-right: 30px;
  }
}

#engage-gph.mat-mdc-tab-group.mat-primary .mat-ink-bar,
#engage-gph.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

#import-gph {
  display: inline-block;
  position: relative;
  width: 100%;

  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, 0, 32, 0);
    float: right;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-labels {
      @include border-radius;
      @include border(1px, solid, $utl-blue-15);
      display: inline-block;

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label {
        height: 30px;
        line-height: 30px;
        @include border-pos(right, 1.5px, solid, $utl-blue-15);
        @include padding(left, top, right, bottom, 15, 0, 15, 0);
        opacity: 1;
        cursor: pointer;
        min-width: auto;
        color: $utl-blue-15;

        &:last-child {
          @include border-pos(right, 0px, solid, $utl-blue-15);
        }

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          color: $utl-blue-15;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-active {
        @include background-color($utl-blue-15);
        color: $utl-white;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          @include background-color($utl-blue-15);
          color: $utl-white !important;
          height: 30px;
          line-height: 30px;
          min-width: auto;
        }
      }
    }

    .mat-ink-bar {
      @include background-color(transparent);
      height: 0px;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    width: 100%;
  }
}

/*********** badges css *******************/
.badges-filter-wrapper {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    height: 0 !important;
  }

  .badges-tabs {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    mat-tab-group {
      .mat-mdc-tab-header {
        border-color: #e5e5e5;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-container {
          @include padding(left, top, right, bottom, 24, 0, 24, 0);

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-labels {
            display: flex;
            align-items: center;
            height: 60px;

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label {
              height: 60px;
              min-width: 115px;
              @include opacity(1);

              /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
              .mat-tab-label-content {
                font-size: $text-default-size;
                color: rgba(27, 36, 54, 0.7);
                font-weight: map-get($font-weights, "medium");
              }
            }

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label-active {

              /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
              .mat-tab-label-content {
                color: map-get($heading-colors, "dark-color");
                font-weight: map-get($font-weights, "bold");
              }
            }
          }
        }
      }
    }
  }
}

/********************* Goal and intitative css ************************/
.cls-goaldtl {
  .cls-flyspace {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    position: relative;
  }

  .cls-boxtop {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));

    .cls-goaltxt {
      object-fit: contain;
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "medium");
      line-height: 1.43;
      color: $utl-text-color-2;
      @include padding(left, top, right, bottom, 9, 24, 0, 30);
      display: inline-block;
    }
  }

  .Cls-goal-inititative {
    ._user-snippet {
      @include border(0px, solid, transparent);
    }
  }
}

.cls-initiative {
  .cls-boxtop {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));
    display: flex;
    justify-content: space-between;
    @include padding(left, top, right, bottom, 15, 0, 15, 0);

    .cls-goaltxt {
      object-fit: contain;
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "medium");
      line-height: 1.43;
      color: $utl-text-color-2;
      @include padding(left, top, right, bottom, 9, 24, 0, 30);
      display: inline-block;
    }
  }
}

// Flyer Badges--------------
._flyer-wrapper {

  .flyer-content {
    ._uBirthList {
      &:first-child {
        @include margin(left, top, right, bottom, 0, 24, 0, 0);
      }
    }

    .flyer-content-header {
      font-size: $text-default-size;
      line-height: 24px;
      color: $utl-text-color-12;
      font-weight: map-get($font-weights, "bold");
      @include margin(left, top, right, bottom, 0, 0, 0, 12);
    }
  }

  .flyer-footer {
    .flyer-footer-inner {
      button {
        flex: 0 0 47%;
        max-width: 47%;
      }

      .btn-default {
        @include border(1px, solid, #e8e8e8 !important);
        @include background-color($color: #fbfbfb !important);
        height: 40px;
      }
    }

    button {
      height: 2.5rem;
    }
  }

  .single-cta {
    .flyer-footer-inner {
      button {
        max-width: 100%;
        width: 200px;
      }
    }
  }
}

.g-search-points {
  .flyer-content {
    .badges-list-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .container-fluid {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
      }

      .payslip-card {
        padding: 16px !important;

        

        .points-by {
          font-size: $text-default-size !important;
        }
      }

      // .col-md-4,
      // .col-md-8 {
      //   max-width: 50%;
      //   flex: 0 0 50%;
      // }

      // .col-md-3 {
      //   max-width: 33.33%;
      //   flex: 0 0 33.33%;
      // }

      // .col-md-8 {
      //   display: flex;
      //   align-items: center;
      // }

      .cls-main-heading {
        font-size: $heading-small-font-size !important;
      }

      .redeem-cta {
        font-size: $text-medium-size !important;
      }
    }
  }
}

.profilescroll {
  height: calc(100vh - 370px);
}

.searchScrolll {
  height: calc(100vh - 245px);
}

.scrollheightmain {
  height: calc(100vh - 170px);
}

#cls-initiative .p-autocomplete-panel {
  max-width: 260px !important;
  left: initial !important;
  right: 0;
  min-width: 290px;
}

#cls-initiative .p-autocomplete-panel .p-autocomplete-list {
  width: 240px;
}

#cls-initiative .p-inputtext {
  padding: 7px 0px 0 !important;
  font-weight: 400;
  font-size: 11px;
}

#cls-initiative.p-inputwrapper-focus~label,
#cls-initiative.p-float-label,
#cls-initiative.p-inputwrapper-filled~label {
  top: -0.75em;
  font-size: 9px !important;
  color: rgba(40, 44, 63, 0.5) !important;
  font-weight: 500;
}

#Report-mgm .p-inputwrapper-focus~label,
#Report-mgm .p-float-label,
#Report-mgm .p-inputwrapper-filled~label {
  top: -0.2em;
}

#Report-mgm .p-float-label>label {
  color: #28314b !important;
  font-weight: 500;
}

/*********** 360 noimanation tab css *************/
._customize_tabs {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-header-pagination-controls-enabled {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }
  }
}

.competemcy-form-wrap {
  mat-accordion {
    mat-expansion-panel {
      @include border-radius(4px !important);

      .mat-content {
        h2 {
          .example-section {

            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
            .mat-checkbox-layout {

              // .mat-checkbox-inner-container {}
              /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
              .mat-checkbox-label {
                line-height: 1.5;
                white-space: normal;
                font-weight: 600;
                color: #2b3344;
              }
            }
          }
        }
      }

      .mat-expansion-panel-body {
        padding-bottom: 0;
      }
    }
  }
}

/********************* Goal and intitative css ************************/
.g-search-points {
  .flyer-content {
    .badges-list-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .container-fluid {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
      }

      .payslip-card {
        .img-container {
          width: 100% !important;
        }
      }
    }
  }
}

.cls-boxtop {
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));
}

.choice-selection {
  p-selectbutton {
    .p-selectbutton {
      @include border-radius;
      overflow: hidden;

      .p-button {
        border: none !important;
        height: 30px;
        font-size: $text-default-size;
        @include background-color($utl-white);

        .p-button-text {
          @include padding(left, top, right, bottom, 8, 6, 8, 6);
          height: 100%;
        }
      }
    }

    .p-selectbutton .p-button:not(.p-state-disabled):not(.p-state-active):hover {
      background-color: transparent;
    }
  }
}

.asset-selection {
  p-selectbutton {
    .p-selectbutton {
      @include border(1px, solid, $utl-blue-15);
      @include border-radius;
      overflow: hidden;

      .p-button {
        border: none !important;
        color: $utl-blue-15 !important;
        height: 30px;
        font-size: $text-default-size;
        @include background-color($utl-white);

        .p-button-text {
          @include padding(left, top, right, bottom, 8, 6, 8, 6);
          height: 100%;
        }
      }
    }
  }
}

.cls-searchgaol {
  .loan_inbox {
    max-width: 100% !important;
    margin-bottom: 10px !important;

    margin-top: 48px !important;
  }
}

.create-group-name {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-should-float {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      display: none !important;
    }
  }
}

.hide-tab {
  .mat-mdc-tab-header {
    display: none;
  }

  perfect-scrollbar {
    height: calc(100vh - 174px) !important;
  }
}

.cls-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 70px;

  // hr {
  //   box-shadow: 0px 0px 1px rgba(0, 0, 0, .54);
  // }
}

.header-details .header-title {
  margin-left: 15px !important;
}

/*********** Performance tab css *******************/
.cls-performancetab {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-label {
    height: 58px;
    @include padding(left, top, right, bottom, 34, 0, 34, 0);
    @include margin(left, top, right, bottom, 15, 0, 0, 0);
    cursor: pointer;
    box-sizing: border-box;
    opacity: 1;
    min-width: 92px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    position: relative;
    color: $utl-text-color-19;
  }
}

.cls-periodbox {
  .mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding-bottom: 0;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          @include border-pos(top, 0px, solid, transparent);
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        height: 0;

        &.mat-focused {
          @include background-color(transparent);
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-ripple {
          @include background-color(transparent !important);
        }
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        -webkit-transform: translateY(-0.75em) scale(0.75) !important;
        transform: translateY(-0.75em) scale(0.75) !important;
        width: 133.33333%;
        color: rgba(40, 44, 63, 0.5) !important;
        text-align: right;
        padding-right: 18%;
      }
    }
  }
}

.performance-table {
  .p-datatable {
    @include border(1px, solid, #cbcbcc);
    @include border-radius(3px);

    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-header-box {
        padding-right: 17px !important;
      }
    }
  }

  table {
    font-size: initial;
    table-layout: fixed;

    .p-datatable-thead {
      border-collapse: collapse;
      width: 100%;
      table-layout: fixed;

      tr>th {
        @include border-pos(bottom, 1px, solid, #cbcbcc);
        @include border-pos(top, 0px, solid, #cbcbcc);
        @include border-pos(right, 0px, solid, #cbcbcc);
        @include border-pos(left, 0px, solid, #cbcbcc);
        @include background-color(#f7f8fb);
        @include padding(left, top, right, bottom, 10, 10, 10, 10);

        span {
          color: #262e3e;
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "semi-bold");
        }
      }
    }

    .p-datatable-tbody {
      tr>td {
        @include border-pos(bottom, 1px, solid, rgba(0, 0, 0, 0.05));
        @include border-pos(top, 0px, solid, transparent);
        @include border-pos(right, 1px, solid, rgba(0, 0, 0, 0.05));
        @include border-pos(left, 0px, solid, transparent);
        @include background-color;
        @include padding(left, top, right, bottom, 10, 14, 10, 14);

        span,
        .cls-anch {
          color: #38404e;
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
        }

        .cls-empname {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.29;
          color: #525763;
        }

        .cls-sub {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.29;
          letter-spacing: normal;
          color: #525763;
        }
      }
    }
  }
}

/************ Flyer css *********************/
.feedback-flyer .loan_inbox {
  max-width: 100% !important;
  margin: 0 auto !important;
  padding: 10px 0px 32px !important;
}

/********* Ninebox Css *****************/
.ninebox-subtext .subtitle {
  margin-top: 0 !important;
}

/************** team css ********************/
.cls-imgbox .avatar-container {
  margin: auto;
}

/********** performance search css *****************/
.search-performance .cls-date {
  text-align: left !important;
}

/*performance filter css*/
.cls-peformancefilter {
  // .filter-tab {}

  #clsdashboardselect1 {
    @include border-radius(2px);
    @include border(0.5px, solid, rgba(161, 164, 172, 0.43));
    @include background-color;
    @include padding(left, top, right, bottom, 10, 2, 8, 5);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    min-width: 120px;
    width: 55%;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          @include padding-em(left, top, right, bottom, 0, 0.5, 0, 0.5);
          @include border-pos(top, 0, solid, transparent);
        }
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-ripple {
        @include background-color(transparent !important);
      }
    }
  }
}


/***** dilog box css of performance ***********/
.dialog-wrapper {
  @include padding(left, top, right, bottom, 8, 10, 8, 0);

  .cls-headingmain {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, medium);
    line-height: 4;
    // color: rgba(255, 255, 255, 0.76);
    display: inline;
  }

  .cls-dropdown {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, medium);
    color: $utl-white;
    display: inline;

    .mat-mdc-form-field {
      width: 60%;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-trigger {

          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          .mat-select-value {
            color: $utl-white;

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-value-text {
              color: $utl-white;

              span {
                color: $utl-white !important;
              }
            }

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-placeholder {
              color: $utl-white;
            }
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label {
            color: $utl-white !important;
          }
        }
      }
    }
  }
}

// Joy styling================
.clearnce-global-search {
  height: calc(100vh - 84px) !important;

  perfect-scrollbar {
    ._tasks-list {
      margin: 0;
    }
  }
}

// performance styling ===============
#clsdashboard-per {
  @include border-radius(2px);
  @include border(0.5px, solid, $utl-grey-color);
  @include background-color;
  @include padding(left, top, right, bottom, 5, 3, 5, 3);
  @include margin(left, top, right, bottom, 10, 0, 0, 0);
  width: 55%;

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include padding-em(left, top, right, bottom, 0, 0.5, 0, 0.5);
        @include border-pos(top, 0, solid, transparent);
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    height: 0;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-ripple {
      @include background-color(transparent !important);
    }
  }
}

// Goal layout css*****************
#policy-layout {
  .cls-krabox {
    .mat-accordion {
      .competency-box {
        .mat-expansion-panel-header {
          .mat-expansion-indicator {
            display: block;
          }
        }
      }
    }
  }
}

// Showmore css ******************
.showmore-text {
  .subtitle {
    margin-top: 0 !important;
  }
}

// Upload file **************
.upload-doc {
  @include border-pos(bottom, 1px, solid, #e9edf2);
  position: relative;

  &.dashed-border {
    @include border-pos(bottom, 1px, dashed, #D1D1D1);
    padding-bottom: 4px;
  }

  h3 {
    font-size: $text-medium-size;
    font-weight: map-get($font-weights, "medium");
  }

  label {
    font-weight: map-get($font-weights, "regular");
    color: rgb(103, 117, 124);
  }

  figure {
    @include abs-pos(initial, 0, 6px, initial);
    width: 24px;

    img {
      width: 100%;
    }
  }
}

.performance-blank-state {
  @include padding(left, top, right, bottom, 16, 0, 16, 0);

  .main-container-box {
    @include background-color($utl-white);
    @include padding(left, top, right, bottom, 0, 34, 0, 34);
  }
}

// Upload file **************
// .upload-doc{
//   @include border-pos(bottom, 1px, solid, #e9edf2);
//   position: relative;
//   h3{
//     color: #4e5e8d;
//     font-size: $text-medium-size;
//     font-weight: map-get($font-weights, 'medium');
//   }
//   label{
//     font-weight: map-get($font-weights, 'regular');
//     color: rgb(103, 117, 124);
//   }
//   figure{
//     @include abs-pos (initial, 0, 6px, initial);
//     width: 24px;
//     img{
//       width: 100%;
//     }
//   }
// }
//******** Chips css
.cls-chpsbox {
  @include background-color($utl-grey-color-31);
  @include padding(left, top, right, bottom, 10, 10, 10, 4);
  @include margin(left, top, right, bottom, 0, 0, 0, 20);

  .cls-chip {
    @include border(1px, solid, $utl-grey-color-10);
    @include background-color($utl-grey-color-32);
    color: $utl-grey-color-15;
    @include border-radius;
    @include padding(left, top, right, bottom, 12, 6, 12, 6);
    @include margin(left, top, right, bottom, 0, 0, 5, 5);
    font-size: $text-size-11;
    font-weight: map-get($font-weights, "medium");
    display: inline-flex;
  }
}

.pine-perk {

  .mat-mdc-tab-body-wrapper {
    @include padding(left, top, right, bottom, 11, 16, 11, 16);
  }
}


.mat-menu-box-1 {
  height: 40vh;
  width: 343px;

  .mat-mdc-menu-content {
    padding-top: 0 !important;

    .add-item {
      font-family: $font-family;
      // color: #12c4ac;
      font-size: $text-default-size;
      line-height: 38px;
      display: block;
      padding: 0 16px;
      // @include background-color($utl-grey-color-5);
    }
  }

  .expense-cate {
    position: relative;
    padding: 0 16px;
    font-family: $font-family;
    font-size: $text-medium-size;
    font-weight: 400;
    height: 35px;
    line-height: 35px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(0, 0, 0, .04);
    }

    .mat-menu-item {
      display: block;
      width: 100%;
      cursor: pointer;

    }

    .material-icons {

      color: $utl-secondary-red;
      cursor: pointer;
    }
  }
}

//*************** heading css ***********
.cls-main-heading {
  font-family: $font-family;
  font-size: $heading-small-font-size;
  font-weight: (map-get($font-weights, medium));
  line-height: 1.5;

  @include margin(left, top, right, bottom, 0, 0, 0, 0);
}



/************ social employee search **************/
.cls-socialinput {
  .autoCompleteOne {
    .ui-autocomplete-multiple-container {
      .ui-autocomplete-input-token {
        input {
          width: 100%;
        }
      }
    }
  }

  .cls-enterlabel {
    color: $primary-text-color;
    font-weight: map-get($font-weights, "medium");
    left: 0;
    font-size: $text-default-size;
  }
}

/************* employee search on project css **********************/
.cls-visibility {
  @include margin(left, top, right, bottom, 0, 0, 0, 0);

  .ui-inputtext {
    .ui-autocomplete-input-token {
      width: auto;
    }
  }
}

.applicability-list {
  .cls-radio1 {
    @include margin(left, top, right, bottom, 0, 0, 0, 32);

    .mat-mdc-radio-button {
      display: inline-block;

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, regular);
          line-height: 1.43;
          color: $utl-text-color-12;
          @include padding(left, top, right, bottom, 11, 0, 0, 0);
        }
      }
    }
  }

  .cls-radio2 {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .mat-mdc-radio-button {
      display: inline-block;

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, regular);
          line-height: 1.43;
          color: $utl-text-color-12;
          @include padding(left, top, right, bottom, 11, 0, 0, 0);
        }
      }
    }
  }
}

/************ file upload css ***************/
.custom-upload-file {
  h3 {
    color: $primary-text-color;
    font-size: $text-medium-size;
    font-weight: map-get($font-weights, "medium");
    line-height: 1.3;
  }

  .upload-section {
    position: relative;

    .click-div {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      font-size: $text-medium-size;

      span {
        @include abs-pos(initial, 0, 2px, initial);
      }
    }

    .uploaded-file {
      @include abs-pos(initial, initial, 2px, 0);
      @include border-pos(bottom, 1px, solid, #e9edf2);
      height: 26px;
      @include background-color($utl-white);

      .clsupload-link {
        span {
          font-size: $text-medium-size;
        }
      }

      button {
        width: 20px;

        img {
          width: 100%;
        }
      }
    }
  }
}

/******************* performance cursol ****************/
.performance-carousel {
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);

  .p-carousel {
    position: relative;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include border(0px, solid, transparent);
    @include background-color(transparent);

    .p-carousel-content {
      .p-carousel-container {
        .p-carousel-next {
          position: absolute;
          right: -18px;
          top: 45%;
          z-index: 1;
          background: url(/assets/images/Right_arrow.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 32px;
          width: 32px;
        }

        .p-carousel-prev {
          position: absolute;
          left: -23px;
          top: 45%;
          z-index: 1;
          background: url(/assets/images/Left_arrow.png);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 32px;
          width: 32px;
          transform: none;
        }

        .p-carousel-items-content {
          width: 100%;

          .p-carousel-items-container {
            .p-carousel-item {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include border(0px, solid, transparent);
              @include background-color(transparent);

              .cls-boxs {
                max-width: 105px;
                text-align: center;
                width: 100%;

                .cls-reviewtitle {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, medium);
                  text-align: center;
                  color: $secondary-text-color;
                  @include margin(left, top, right, bottom, 0, 0, 0, 6);
                  display: block;
                }

                .circle {
                  width: 72px;
                  height: 72px;
                  border-radius: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  margin: 0 auto;

                  .clscard-box {
                    span {
                      font-family: $font-family;
                      font-size: $text-default-size;
                      font-weight: map-get($font-weights, "semi-bold");
                      text-align: center;
                      color: $utl-white;
                    }
                  }

                  .cls-performancename {
                    font-family: $font-family;
                    font-size: $text-midum-small-size;
                    font-weight: map-get($font-weights, "regular");
                    text-align: center;
                    color: $utl-white;
                  }
                }

                .cls-period {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, semi-bold);
                  text-align: center;
                  color: $secondary-text-color;
                  @include margin(left, top, right, bottom, 0, 8, 0, 0);
                }
              }

              .cls-horizontal-line {
                height: 5px;
                @include border-radius;
                @include background-color($utl-theme-color);
                width: 100%;
              }

              &:last-child {
                .cls-horizontal-line {
                  display: none;
                }

                .p-carousel-header {
                  @include padding(left, top, right, bottom, 0, 0, 0, 0);
                  @include margin(left, top, right, bottom, 0, 0, 0, 0);
                  background: transparent;
                  @include border(0px, solid, transparent);

                  .p-carousel-header-title {
                    display: block;
                  }

                  .p-carousel-page-links {
                    display: none;
                  }

                  .p-state-disabled {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.reviewbox {
  @include margin(left, top, right, bottom, 0, 65, 0, 0);
  @include padding(left, top, right, bottom, 16, 0, 16, 0);
  @include border-radius(0);

  .mat-accordion {
    .mat-expansion-panel {
      @include border(1px, solid, $utl-text-color-18);

      .mat-expansion-panel-header {
        @include border-pos(bottom, 1px, solid, $utl-text-color-18);
        @include border-radius(0);
        @include padding(left, top, right, bottom, 16, 0, 16, 0);

        .mat-content {
          .mat-expansion-panel-header-title {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.57;
            color: $utl-text-color-14;
          }
        }
      }

      .mat-expansion-panel-content {
        font-family: $font-family;
        font-size: inherit;
        font-weight: map-get($font-weights, "medium");
        line-height: inherit;

        .mat-expansion-panel-body {
          @include padding(left, top, right, bottom, 16, 16, 16, 0);

          .mat-expansion-content {
            .previous-comments {
              ul {
                @include padding(left, top, right, bottom, 0, 0, 0, 0);

                li {
                  @include padding(left, top, right, bottom, 0, 0, 0, 0);
                  @include margin(left, top, right, bottom, 0, 0, 0, 0);

                  .mat-expansion-content-inner {
                    @include padding(left, top, right, bottom, 0, 16, 0, 16);
                    @include border-pos(bottom, 1.5px, dashed, $utl-grey-color-10);
                    display: flex;

                    .mat-expansion-content-header {
                      @include margin(left, top, right, bottom, 0, 0, 0, 12);
                      width: 29%;

                      ._snippet-inner {
                        ._user-detail {
                          h5 {
                            font-weight: map-get($font-weights, "bold");
                            font-size: $text-medium-size;

                            span {
                              font-weight: map-get($font-weights, "regular");
                              font-size: $text-medium-size;
                            }
                          }

                          .cls-empname {
                            font-family: $font-family;
                            font-size: $text-medium-size;
                            font-weight: map-get($font-weights, "semi-bold");
                            line-height: 1.33;
                            color: $secondary-text-color;

                            span {
                              font-family: $font-family;
                              font-size: $text-medium-size;
                              font-weight: map-get($font-weights, "regular");
                              line-height: 1.33;
                              color: $utl-grey-color-6;
                            }
                          }

                          .cls-comptext {
                            font-family: $font-family;
                            font-size: $text-small-size;
                            font-weight: map-get($font-weights, "regular");
                            line-height: 1.33;
                            color: $secondary-text-color;
                          }
                        }
                      }
                    }

                    .mat-expansion-footer {
                      width: 15%;

                      .mat-expansion-footer-inner {
                        @include padding(left, top, right, bottom, 0, 0, 0, 0);

                        small,
                        span {
                          color: $utl-text-color-12;
                          font-size: $text-medium-size;
                          font-weight: map-get($font-weights, "medium");
                          line-height: 20px;
                        }

                        span {
                          color: map-get($heading-colors, "utl-grey-color-6");
                          line-height: 16px;
                        }
                      }
                    }

                    .mat-expansion-user-input {
                      width: 55%;
                    }
                  }

                  @include margin(left, top, right, bottom, 0, 0, 0, 0);

                  &:last-child {
                    .mat-expansion-content-inner {
                      @include border-pos(bottom, 0, dashed, $utl-grey-color-10);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.component-slider-wrapper {
  #Report-carousel .p-carousel .p-carousel-viewport .p-carousel-items .p-carousel-item {
    @include margin(left, top, right, bottom, 8, 8, 8, 24);
    box-shadow: inset 0 0px 3px 0 rgba(0, 0, 0, 0.15);
    @include padding(left, top, right, bottom, 12, 12, 12, 12);
    cursor: pointer;
  }
}

// Expense receipt================
.expense-receipt {
  .cls-mainbox {
    @include padding(left, top, right, bottom, 16, 24, 16, 16);

    .expense-wrapper {
      @include background-color($utl-white);
      @include border(1px, solid, rgba(151, 151, 151, 0.5));
      @include border-radius;

      .expense-header {
        @include padding(left, top, right, bottom, 24, 16, 24, 16);
        @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, 0.5));

        h2 {
          font-size: $text-default-size;
          color: $utl-text-color-2;
          font-weight: map-get($font-weights, "semi-bold");

          .cls-receiptcode {
            @include border-radius(3px);
            @include background-color($utl-grey-color-6);
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.33;
            color: $utl-white;
            @include padding(left, top, right, bottom, 8, 4, 8, 4);
            @include margin(left, top, right, bottom, 16, 0, 0, 0);
          }
        }

        // span {
        //   width: 34px;
        // }
      }

      .expense-content {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
      }
    }
  }
}

.form-wrapper {
  .ui-autocomplete-multiple-container {
    padding-left: 0 !important;
  }
}

.login-popup {
  max-width: 100%;
}

.text-field {
  .p-fileupload-content .p-progressbar {
    display: none;
  }

  .chat-file-attach .p-fileupload-content .p-fileupload-files .p-fileupload-row {
    padding: 0;
    margin: 0;
    height: 96px;
    @include border(4px, solid, $utl-theme-color);
    @include border-radius;
    display: flex;
    flex-direction: column;
  }

  .chat-file-attach .p-fileupload-row>div {
    font-size: 0;
    width: 100%;
    height: 100%;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    &:first-child {
      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }
    }

    button {
      position: absolute;
      right: -8px;
      top: -8px;
      // background-image: url("/assets/images/icon/cross.png");
      @include background-color($primary-text-color);
      @include border-radius(50px);
      // background-size: 8px !important;
      // background-position: center !important;
      width: 18px;
      height: 18px;

      &:hover {
        // background-image: url("/assets/images/icon/cross.png") !important;
        @include background-color($primary-text-color !important);
        @include border-radius(50px);
        // background-size: 8px !important;
        // background-position: center !important;
      }
    }
  }

  .fileshare-container {
    width: 96px;
    height: 96px;

    .upload-cont {
      height: 96px;
      @include border-radius(2px);
    }
  }

  .dropfile-msg {
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
    @include background-color(#cfcfcf);

    img {
      width: 36px;
    }
  }

  .chat-file-attach .p-fileupload-buttonbar {
    height: 96px;

    .p-fileupload-choose {
      border: none !important;

      .p-button-label {
        display: none;
      }
    }
  }

  .p-fileupload-content {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }

  .pi-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

/********** tooltip css ****************/
.cls-dilogbox {
  .cls-dialogheading {
    font-family: $font-family;
    font-size: $heading-s-medium-font-size;
    font-weight: (map-get($font-weights, semi-bold));
    line-height: 1.5;
    color: $utl-text-color-11;
    @include margin(left, top, right, bottom, 0, 0, 0, 30);
  }

  .holds-the-iframe {
    font-size: $text-default-size;
    font-family: $font-family;
    font-weight: (map-get($font-weights, semi-bold));
    line-height: 1.43;
    color: $utl-text-color-11;
    @include margin(left, top, right, bottom, 0, 0, 0, 16);
  }
}

/**********  Job css ****************/
.curreny-value {
  position: relative;

  mat-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      padding-left: 18px;
    }
  }
}

.job-form-wrappper {
  p-autocomplete {
    input {
      padding-left: 0 !important;
      border-radius: 0;
    }
  }
}

.form-data {
  font-family: initial;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: initial;
  }

  .subtitle {
    strong {
      font-size: $text-medium-size;
      color: $utl-text-color-2;
      font-weight: map-get($font-weights, "semi-bold");
      @include margin(left, top, right, bottom, 0, 0, 0, 16);
      display: block;
      font-family: $font-family;
    }
  }

  p {
    margin: 0;
    font-size: initial;
    font-family: initial;
    font-family: $font-family;

    strong {
      font-size: $text-medium-size;
      color: $utl-text-color-2;
      font-weight: map-get($font-weights, "semi-bold");
      @include margin(left, top, right, bottom, 0, 0, 0, 16);
      display: block;
    }
  }

  ul {
    list-style-type: disc;
    @include padding(left, top, right, bottom, 16, 0, 0, 0);
    margin-bottom: 0;
    font-size: 15px;
    font-family: initial;

    li {
      font-size: initial;
      color: $utl-text-color-2;
      font-weight: map-get($font-weights, "regular");
      @include margin(left, top, right, bottom, 0, 0, 0, 3);
    }
  }
}

.edit-job-form-wrap {
  perfect-scrollbar {
    height: calc(100vh - 86px) !important;
  }

  .request_email_base {
    max-width: 80% !important;
  }
}

.login-preview-modal {
  .updated-banner {
    .static-img {
      .logo-updatation {
        width: 70px !important;
        height: 69px !important;
        left: 89px !important;
        top: -5px !important;
      }
    }
  }
}

.despense-flyer-content {
  .loan_inbox {
    max-width: 45% !important;
    padding: 8px 8px 2px !important;

    ._blue-ribbon-card {
      border: none;
    }

    .card-title {
      @include background-color(#fff8e2);
      font-size: $text-semimedium-size;
      font-weight: map-get($font-weights, "semi-bold");
      @include padding(left, top, right, bottom, 16, 12, 16, 12);
      @include margin(left, top, right, bottom, 0, 0, 0, 8);
      text-align: left !important;
    }
  }
}

.hint {
  font-size: $text-semimedium-size;
  font-weight: map-get($font-weights, "regular");

  b {
    text-decoration: underline;
  }
}

.selected-template {
  ul {
    list-style-type: disc;
    @include padding(left, top, right, bottom, 16, 0, 0, 0);
  }
}

.download-doc {
  text-decoration: underline;
  color: $utl-theme-color !important;
  cursor: pointer;
}

.box-2 {
  .result {
    height: 100vh;

    .cropper-canvas {
      transform: translateY(0) !important;
      height: 100% !important;

      img {
        object-fit: cover;
        height: 100% !important;
      }
    }
  }
}

.internal-recruiter-drop-down {
  .ui-autocomplete .ui-autocomplete-panel {
    min-width: 390px;
  }
}

/*********** expense toggle css *******************/
.cls-expansetoggle {
  .form-wrapper-inner {
    .mat-accordion {
      .mat-expansion-panel {
        .mat-expansion-panel-header {
          .mat-expansion-indicator {
            display: block;
          }
        }
      }
    }
  }
}

.ui-autocomplete-multiple-container {
  padding: 0;
}



.mat-mdc-form-field-error {
  span {
    color: #f44336 !important;
  }
}

.selected-template {
  ul {
    list-style-type: disc;
    @include padding(left, top, right, bottom, 16, 0, 0, 0);
  }
}

/************* Expense Report css *****************/
.cls-dilogbox.p-dialog {
  .p-widget-header.p-dialog-titlebar {
    .p-dialog-title {
      p-header {
        font-size: 14px;

        .cls-heading {
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          color: $utl-text-color-11;
          vertical-align: middle;
          display: inline-block;
        }

        .cls-exceptional {
          @include border-radius;
          @include background-color($utl-red-24);
          font-family: $font-family;
          font-size: $text-midum-small-size;
          font-weight: map-get($font-weights, "medium");
          color: $utl-white;
          min-width: 76px;
          height: 16px;
          display: inline-block;
          text-align: center;
          line-height: 16px;
          @include margin(left, top, right, bottom, 15, 0, 0, 0);
        }
      }
    }
  }
}

.clssave {
  @include text-truncate();
}

.attendance-location {
  @include background-color($utl-white);
  margin: 24px auto;
  @include padding(left, top, right, bottom, 24, 24, 24, 24);
  max-width: 52%;

  label,
  h4 {
    font-size: $text-medium-size;
  }

  label {
    color: map-get($heading-colors, "utl-grey-color-6");
    @include text-truncate();
    font-weight: map-get($font-weights, "medium");
  }

  h4 {
    color: $utl-text-color-12;
    line-height: 19px;
    font-weight: map-get($font-weights, "semi-bold");
  }

  .location-pics {
    figure {
      width: 64px;
      height: 64px;
      margin-left: 0px;
      margin-top: 0px;
      margin-right: 8px;
      margin-bottom: 0px;
      border: 1px solid #00937d;
      border-radius: 2px;
      background-color: #f2f2f2;

      img {
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: center;
        object-position: center;
      }
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
#login-register .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0;
}

.btn-h-40 {
  height: 40px;
}

.btn-h-36 {
  height: 36px;
}

.btn-h-32 {
  height: 32px;
}

/************* history carousel ***********/
.history-carousel {
  @include padding(left, top, right, bottom, 20, 21, 20, 21);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  display: inline-block;
  width: 100%;

  .p-carousel.p-component.p-carousel-horizontal {
    position: relative;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include border(0px, solid, transparent);
    @include background-color(transparent);

    .p-carousel-content {
      .p-carousel-container {
        .p-carousel-items-content {
          width: 100%;

          .p-carousel-items-container {
            .p-carousel-item {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include border(0px, solid, transparent);
              @include background-color(transparent);
              flex: 0 0 25% !important;

              .cls-boxs {
                max-width: 105px;
                text-align: center;
                width: 100%;

                .cls-reviewtitle {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, medium);
                  text-align: center;
                  color: $utl-white;
                  @include margin(left, top, right, bottom, 0, 0, 0, 0);
                  display: block;
                }

                .circle {
                  width: 102px;
                  height: 39px;
                  @include background-color($utl-green-14);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-direction: column;
                  margin: 0 auto;
                  @include border-radius(8px);
                  @include border(4px, solid, $utl-white);

                  .clscard-box {
                    span {
                      font-family: $font-family;
                      font-size: $text-default-size;
                      font-weight: map-get($font-weights, "semi-bold");
                      text-align: center;
                      color: $utl-white;
                    }
                  }

                  .cls-performancename {
                    font-family: $font-family;
                    font-size: $text-midum-small-size;
                    font-weight: map-get($font-weights, "regular");
                    text-align: center;
                    color: $utl-white;
                  }
                }

                .cls-period {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, semi-bold);
                  text-align: center;
                  color: $secondary-text-color;
                  @include margin(left, top, right, bottom, 0, 12, 0, 0);
                }
              }

              .cls-horizontal-line {
                height: 5px;
                @include border-radius;
                @include background-color($utl-grey-color-34);
                width: 100%;
                @include margin(left, top, right, bottom, 0, -26, 0, 0);
              }

              &:last-child {
                .cls-horizontal-line {
                  display: none;
                }
              }
            }
          }
        }
      }
    }

    .p-carousel-header {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      background: transparent;
      @include border(0px, solid, transparent);

      .p-carousel-header-title {
        display: block;
      }

      .p-carousel-page-links {
        display: none;
      }

      em {
        img {
          width: 100%;
        }
      }

      .p-carousel-next-button {
        position: absolute;
        right: -18px;
        top: 3%;
        z-index: 1;
        background: url(/assets/images/Right_arrow.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 32px;
        width: 32px;
      }

      .p-carousel-prev-button {
        position: absolute;
        left: -23px;
        top: 3%;
        z-index: 1;
        background: url(/assets/images/Left_arrow.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 32px;
        width: 32px;
      }

      .p-state-disabled {
        display: none;
      }
    }
  }
}

.visibility-setting {

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label {
    align-items: flex-start;
  }
}

.pac-container {
  z-index: 999999;
}

em {
  img {
    width: 100%;
  }
}

.internal-recruiter-drop-down {
  p-autocomplete {
    span {
      input {
        padding: 0 0 5px;
      }

      .ui-state-default {
        border-bottom: 1px solid #e9edf2;
      }
    }
  }

  .p-float-label>.ui-inputwrapper-filled~label {
    top: -0.95em;
    margin: 0;
  }
}

.performance-setting-wrap {

  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
  .mat-radio-label {
    white-space: initial;
  }
}

/*************** Expense chip design *****************/
.cls-chips-des {
  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-standard-chip {
        @include border-radius(2px);
        @include border(1px, solid, $utl-grey-color-16);
        @include background-color($utl-text-color-16);

        margin-right: 15px;

        .mdc-evolution-chip__cell {
          .mdc-evolution-chip__action {
            .mdc-evolution-chip__text-label {
              white-space: normal;
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "regular");
              color: #424242;
            }
          }
        }
      }
    }
  }
}

/******************* ORG CHART CSS **********************/
.cls-orgchart {
  @include border-radius;
  @include border(2px, solid, #e5e5e5);
  @include background-color;
  @include margin(left, top, right, bottom, 0, 24, 0, 0);
  @include padding(left, top, right, bottom, 32, 32, 32, 32);
  overflow: auto;
  height: calc(100vh - 166px);

  /* Let's get this party started */
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #eeeded;
    -webkit-box-shadow: inset 0 0 6px #eeeded;
    -webkit-border-radius: 6px;
    border-radius: 6px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #eeeded;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #7e7a7e;
    box-shadow: inset 0 0 6px #eeeded;
    -webkit-box-shadow: inset 0 0 6px #eeeded;
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: #7e7a7e;
  }

  &.p-organizationchart {
    .p-organizationchart-table {
      .p-organizationchart-node-content {
        @include border-radius(5px);
        @include shadow(13);
        @include border(1px, solid, rgba(151, 151, 151, 0.1));
        @include background-color($utl-grey-color-11);

        &.p-organizationchart-selectable-node {
          @include margin(left, top, right, bottom, 0, 0, 0, 28);
        }

        .node-content {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 190px;

          img {
            @include margin(left, top, right, bottom, 0, 0, 9, 0);
          }

          .cls-controlbox {
            .cls-name {
              font-family: $font-family;
              font-size: $text-default-size;
              font-weight: map-get($font-weights, regular);
              line-height: 1.43;
              color: $utl-text-color-2;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
              white-space: nowrap;
              max-width: 150px;
            }

            .cls-designation {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, regular);
              line-height: 1.33;
              color: #8d919a;
              width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;
              display: block;
              white-space: nowrap;
              max-width: 120px;
            }
          }
        }

        .p-node-toggler {
          position: absolute;
          bottom: -35px;
          margin-left: -12px;
          z-index: 2;
          left: 50%;

          &:focus {
            box-shadow: none;
          }

          .pi {
            &.pi-chevron-up:before {
              content: "";
            }

            &.pi-chevron-down:before {
              content: "";
            }
          }

          .pi-chevron-up {
            background-image: url(/assets/images/bigplus-icon.svg);
            height: 26px;
            width: 26px;
            background-repeat: no-repeat;
            background-position: 0px -3px;
            background-size: 100%;
          }

          .pi-chevron-down {
            background-image: url(/assets/images/bigminus-icon.svg);
            height: 26px;
            width: 26px;
            background-repeat: no-repeat;
            background-position: 0 -3px;
            background-size: 100%;
          }
        }
      }
    }
  }
}

/************ Expense dialog box *************/
.expense-dialog {
  .p-dialog-titlebar {
    display: none;
  }

  .dialog-wrapper {
    .header-title {
      font-family: $font-family;
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, semi-bold);
      line-height: 1.5;
      color: $utl-text-color-11;
      @include padding(left, top, right, bottom, 0, 10, 0, 0);
    }

    .cls-spacebox {
      @include margin(left, top, right, bottom, 0, 32, 0, 32);

      .message-text {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, medium);
        line-height: 1.43;
        color: $utl-text-color-12;
      }

      .cls-boldtext {
        @extend .message-text;
        font-weight: map-get($font-weights, semi-bold);
        color: $utl-text-color-11;
      }
    }

    .p-footer {
      @include margin(left, top, right, bottom, -20, 0, -20, 0);
      @include padding(left, top, right, bottom, 32, 24, 32, 10);
    }
  }
}

.cls-socialinbox {
  .avatar-container {
    margin: initial;
  }
}

// HR Post delete dropdown
._wish-cta {
  .ui-slidemenu-content {
    height: 70px;
  }

  .ui-slidemenu.ui-slidemenu-dynamic {
    left: initial !important;
    right: 0;
    top: 34px !important;
    text-align: left;
    border: none;
    background: #fff;
    width: 150px;
    padding: 10px 10px;

    .ui-slidemenu-rootlist {
      width: 100% !important;

      li {
        a {
          &:hover {
            background: #fff;
          }

          span {
            color: $utl-red-4;
            font-size: 12px;
          }
        }
      }
    }
  }

  .delete-post {
    button {
      opacity: 1;
      width: 32px;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border(0px, solid, transparent);

      &:focus {
        box-shadow: none;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background: rgba(0, 191, 165, 0.2);
      }

      span {
        font-size: 18px;
        color: $utl-theme-color;
      }
    }
  }
}

// Candidate Detail
.candidate-detail-wrapper {
  .ui-rating {
    a {
      .pi-star {
        color: #ffca54;
      }

      .pi-star-o {
        color: #e0e0e0;

        &:hover {
          color: #ffca54;
        }

      }
    }
  }

  .cadidate-canditure {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    mat-tab-group {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      mat-tab-header {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-container {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-labels {
            justify-content: space-between;
            @include margin(left, top, right, bottom, 24, 0, 24, 0);

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label {
              opacity: 1;

              /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
              .mat-tab-label-content {
                color: $utl-grey-color-6;
                font-size: $text-default-size;
                font-weight: map-get($font-weights, "medium");
              }
            }

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label.mat-tab-label-active {

              /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
              .mat-tab-label-content {
                color: $secondary-text-color;
                font-weight: map-get($font-weights, "bold");
              }
            }
          }
        }
      }
    }

    .search-candidate {
      p-autocomplete {
        .ui-autocomplete {
          input {
            @include border(1px, solid, #e5e5e5);
            @include border-radius;
            height: 36px;
            padding: 0 0 0 32px !important;
          }
        }
      }
    }

    .action-pop-up-content {

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      mat-radio-group {

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        mat-radio-button {
          label {
            color: $utl-text-color-2;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "medium");

            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
            .mat-radio-label-content {
              color: $utl-text-color-2;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "medium");
            }
          }
        }
      }
    }
  }
}



//*************** show more content text css ******************
.cls-content {
  .subtitle {
    margin-bottom: 0 !important;
  }
}

.time-selection {
  label {
    margin: 0;
    font-size: 11px;
  }

  p-calendar {
    input {
      width: 100%;
      @include background-color(transparent);
      @include border-radius(0);
      padding: 0px 0 3px !important;
      border-bottom: 1px solid #e9edf2;
      border-top: 0px solid transparent;
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;

      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-datepicker {
      &.p-datepicker-timeonly {
        .p-timepicker {
          button {
            width: 1rem;
            height: 1rem;
          }

          span {
            font-size: $text-medium-size;
          }
        }
      }
    }
  }
}

.time-select {
  label {
    // margin-top: -0.5rem;
    font-size: inherit;
  }

  p-calendar {
    input {
      width: 100%;
      @include background-color(transparent);
      @include border-radius(0);
      border-bottom: 1px solid #e9edf2;
      border-top: 0px solid transparent;
      border-left: 0px solid transparent;
      border-right: 0px solid transparent;

      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-datepicker {
      @include padding(left, top, right, bottom, 10, 15, 10, 0);

      &.p-datepicker-timeonly {
        .p-timepicker {
          button {
            width: 1rem;
            height: 1rem;
          }

          span {
            font-size: $text-medium-size;
          }
        }
      }
    }
  }
}

//************** dialog box css ***************************
.cls-taxregime.ui-dialog.ui-widget {
  .ui-dialog-titlebar {
    display: none;
  }

  .ui-dialog-content {
    @include padding(left, top, right, bottom, 32, 24, 20, 10);

    .cls-title {
      font-family: $font-family;
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      line-height: 1.5;
      color: $utl-text-color-11;
    }

    .cls-maintext {
      font-family: $font-family;
      font-size: $heading-extra-small-font-size;
      font-weight: map-get($font-weights, "medium");
      line-height: 1.75;
      color: rgba(75, 84, 110, 0.7);
      @include margin(left, top, right, bottom, 0, 10, 0, 20);
    }

    .cls-radio {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .mat-mdc-radio-button {
        @include margin(left, top, right, bottom, 0, 0, 25, 0);

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label {

          /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
          .mat-radio-label-content {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "medium");
            color: $utl-text-color-11;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            @include padding(left, top, right, bottom, 8, 2, 0, 0);
          }
        }
      }

      .pi {
        font-size: $heading-s-medium-font-size;
        vertical-align: middle;
        color: $utl-theme-color;
      }
    }

    .cls-anch {
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "regular");
      color: $utl-theme-color;
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      text-decoration: underline;
    }

    .cls-disclaimer {
      font-family: $font-family;
      font-size: $text-size-11;
      font-weight: map-get($font-weights, "regular");
      color: $utl-secondary-red;
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
    }
  }
}

//********************* Avtar design css **************
.cls-short-avatar {
  @include border-radius;
  @include shadow($level: 15);
  @include background-color;
  height: 64px;
  width: 64px;
  display: flex;
  @include margin(left, top, right, bottom, 0, 0, 0, 0);

  .avatar-container {
    .avatar-content {
      @include border-radius(4px !important);
    }
  }
}

.cls-shortcuts-avatar {
  @include border-radius(3px);
  @include shadow($level: 14);
  @include background-color;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include margin(left, top, right, bottom, 2, -3, 0, 0);

  img {
    width: 18px;
    height: 18px;
    @include border-radius(3px);
  }

  .avatar-container {
    .avatar-content {
      @include border-radius(4px !important);
      font-size: $text-size-11 !important;
    }
  }
}

// 21-April-2020 by Ajay Gupta
// ==============================(Candidate Detail Page)----------------------
// 22-April-2020 by Ajay Gupta
// ==============================(HR Post delete)----------------------
.delete-hr-post {
  button {
    height: 24px;
    @include t-14-font-style();
    color: #ff2e42;
    font-weight: map-get($font-weights, "medium");
  }
}

// 23-April-2020 by Ajay Gupta
// ==============================(Add Candidate-File upload preview)----------------------
.add-candidate-wrapper {
  .preview-box {
    @include border(1px, solid, #e5e5e5);
    @include background-color(#f5f5f5);
    width: 140px;
    height: 100px;
    box-sizing: content-box;
    position: relative;

    .delete-box {
      border: none;
      box-shadow: none;
      width: 17px;
      height: 17px;
      background: transparent;
      padding: 0;
      @include abs-pos($top: -8px, $right: -8px, $bottom: initial, $left: initial);
    }

    figure {
      width: 100%;
      height: 77px;
      @include background-color($utl-white);

      img {
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    .file-name {
      padding: 3px 30px 3px 3px;
      position: relative;
      display: flex;
      align-items: center;

      small {
        @include background-color($utl-theme-color);
        color: $utl-white;
        font-size: $text-midum-small-size;
        margin-right: 4px;
        display: flex;
        align-items: center;
        padding: 3px 3px;
        border-radius: 2px;
        font-weight: 600;
      }

      span {
        @include abs-pos($top: initial, $right: -1px, $bottom: -1px, $left: initial);
        width: 35px;
        display: block;

        img {
          width: 100%;
        }
      }

      b {
        @include text-truncate();
        color: $utl-text-color-4;
        font-weight: map-get($font-weights, "semi-bold");
        font-size: $text-medium-size;
        width: 100%;
        display: block;
      }
    }
  }
}

.filter-tools {
  mat-accordion {
    .mat-expansion-panel {
      border-bottom: none;
      @include background-color(transparent);

      .mat-expansion-panel-body {
        padding: 0;
      }
    }
  }

  .ui-rating {
    a {
      .pi-star-o {
        color: #e0e0e0;

        &:hover {
          color: #e0e0e0;
        }
      }
    }
  }

  .search-candidate {
    position: relative;
    margin-bottom: 24px;

    img {
      width: 24px;
      @include abs-pos($top: 7px, $right: initial, $bottom: initial, $left: 7px);
    }

    p-autocomplete,
    .searchfilter {
      input {
        @include border(1px, solid, #e5e5e5);
        @include background-color($utl-white);
        height: 36px;
        padding: 0 12px 0 35px !important;
        @include border-radius;
      }
    }
  }
}

.action-popup {
  &.newpage {
    .action-pop-up-content {
      .mat-mdc-tab-group {
        .mat-mdc-tab-header {
          margin-bottom: 10px;
        }
      }
    }
  }

  .action-pop-up-content {
    .mat-mdc-tab-group {
      .mat-mdc-tab-header {
        border-bottom: none !important;
        margin-bottom: 24px;

        .mat-mdc-tab-header-pagination {
          display: none
        }

        .mat-mdc-tab-label-container {
          flex-grow: 0;

          .mat-mdc-tab-list {
            .mat-ink-bar {
              display: none;
            }

            .mat-mdc-tab-labels {
              // @include border(1px, solid, $utl-theme-color);
              @include border-radius;
              padding: 0 !important;

              .mat-mdc-tab {
                min-width: 100px;
                padding: 0;
                height: 32px;
                // @include border-pos(right, 1.5px, solid, $utl-theme-color);
                opacity: 1;

                .mdc-tab__content {
                  .mdc-tab__text-label {
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights, "semi-bold");
                    color: $utl-theme-color;
                  }
                }

                &:last-child {
                  border-right: none !important;
                }
              }

              .mdc-tab--active {
                // @include background-color($utl-theme-color);

                .mdc-tab__content {
                  .mdc-tab__text-label {
                    // color: $utl-white;
                  }
                }

                .mdc-tab-indicator {
                  display: none;
                }
              }
            }
          }
        }
      }

      .form-wrapper {
        flex-wrap: wrap;
      }
    }


  }
}

//******************* Salary dashboard popup design *************************
.cls-salarypopup.ui-dialog.ui-widget {
  .ui-widget-header.ui-dialog-titlebar {
    background: none;
    @include padding(left, top, right, bottom, 32, 24, 19, 3);

    .ui-dialog-title {
      p-header {
        font-size: 14px;

        .pending-review-wrapper {
          width: calc(100% + 38px);
          @include padding(left, top, right, bottom, 16, 24, 16, 0);
          @include margin(left, top, right, bottom, -19, -13, -19, -13);
          display: block;
          @include background;

          h3 {
            color: $secondary-text-color;
            font-size: $heading-s-medium-font-size;
            font-weight: (map-get($font-weights, bold));

            i {
              margin-right: 0.5rem;
            }
          }

          a {
            float: right;
          }
        }
      }
    }
  }

  .ui-dialog-content {
    @include padding(left, top, right, bottom, 32, 0, 24, 24);

    .cls-disclamer {
      @include padding-em(left, top, right, bottom, 1.571, 0, 0, 0);
      @include margin-em(left, top, right, bottom, 0, 1.071, 0, 0);
      list-style: disc;

      li {
        font-size: $heading-extra-small-font-size;
        color: $utl-text-color-11;
        line-height: 1.75;
      }
    }

    .cls-gnltext {
      font-size: $heading-extra-small-font-size;
      line-height: 1.75;
      display: block;
      color: rgba(75, 84, 110, 0.7);
      @include margin-em(left, top, right, bottom, 0, 1.071, 0, 1.428);
    }
  }

  .ui-dialog-footer {
    @include padding(left, top, right, bottom, 24, 20, 24, 20);
  }
}

//************* confirmation popup *****************
.cls-offer.p-dialog {
  @include border-radius(2px);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  @include background-color;

  .p-dialog-titlebar {
    background: none;
    @include padding(left, top, right, bottom, 32, 24, 32, 0);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .ui-dialog-title {
      p-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-title {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }
    }

    .ui-dialog-titlebar-icon {
      display: none;
    }
  }

  .-dialog-content {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    perfect-scrollbar {
      @include padding(left, top, right, bottom, 32, 0, 32, 0);

      .dialog-content {
        .cls-subtxt {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.43;
          @include padding(left, top, right, bottom, 0, 24, 0, 18);
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        .cls-processbox {
          @include border-radius;
          @include border(1px, solid, $utl-grey-color-32);
          @include background-color;
          @include margin(left, top, right, bottom, 0, 56, 0, 7);
          @include padding(left, top, right, bottom, 24, 32, 24, 24);
          min-height: 96px;
          text-align: center;

          h3 {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "regular");
            color: $utl-text-color-7;
          }

          .cls-prsbar {
            .ui-progressbar {
              @include background-color(rgba(0, 191, 165, 0.25));
              @include border(0px, solid, transparent);

              .ui-progressbar-value {
                @include background-color($utl-theme-color);
              }
            }
          }
        }

        .cls-empcont {
          .mat-mdc-radio-group {
            .mat-mdc-radio-button {
              @include margin(left, top, right, bottom, 0, 0, 45, 0);

              /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
              .mat-radio-label-content {
                @extend .cls-subtxt;
                font-weight: map-get($font-weights, "regular");
                color: $utl-text-color-12;
                @include padding(left, top, right, bottom, 8, 0, 0, 0);
              }
            }
          }
        }
      }
    }
  }

  .p-dialog-footer {
    @include padding(left, top, right, bottom, 32, 24, 32, 24);

    .btn-cancel {
      @include background-color(transparent);
      min-width: 94px;
      @include border(1px, solid, rgba(26, 26, 26, 0.25));
    }

    .btn-cancel:hover,
    .btn-cancel.disabled:hover {
      box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12),
        0 8px 10px -5px rgba(92, 92, 92, 0.2);
      @include background-color(transparent);
      @include border(1px, solid, rgba(26, 26, 26, 0.25));
    }
  }
}

/*************** 360 tab css ****************/
.cls-360policy {
  .cls-policysixty {

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-body-wrapper {
      .mat-mdc-tab-body {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-body-content {
          p-autocomplete {
            .ui-autocomplete-panel {
              top: 30px !important;
            }
          }
        }
      }
    }
  }
}

/**************** attendance policy popup css ******************/
.img-pre-wrapper.ui-dialog.ui-widget {
  .ui-dialog-content {
    @include padding-em(left, top, right, bottom, 1.5, 0, 1.5, 0);
    @include margin-em(left, top, right, bottom, 0, 1.5, 0, 1.5);
  }
}

/**************** holiday dropdown css *******************/


.offer-job {
  .ui-carousel-viewport {
    min-height: 127px !important;

    .ui-carousel-item {
      @include background-color($utl-white !important);
      min-height: 342px;
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }
}

.cls-leave-card {
  ngx-avatar {
    .avatar-container {
      .avatar-content {
        font-size: 14px !important;
        font-family: $font-family !important;
        font-weight: map-get($font-weights, "medium") !important;
      }
    }
  }
}

/************** calendar dropdon menu css *******************/
.mat-mdc-menu-panel {
  min-height: 48px;

  .mat-mdc-menu-content {
    .cls-menutitle {
      @include padding(left, top, right, bottom, 16, 5, 16, 5);
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "semi-bold");
    }

    .mat-mdc-menu-item {
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "regular");
      height: 35px;
      line-height: 35px;
    }
  }
}

/****************** shortcut css *********************/
.cls-welcomechk,
.rectangle {
  .mat-mdc-checkbox {
    display: block;

    .mdc-form-field {

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }
}

/*************** OTP Code box css ***************/
.cls-otpbox.mat-form-field-appearance-legacy {
  width: 37px;
  height: 37px;
  @include margin(left, top, right, bottom, 0, 0, 8, 0);

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include border-radius(2px);
        @include border(1px, solid, rgba(73, 80, 94, 0.4));
        @include background-color;
        text-align: center;
        height: 37px;
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    @include background-color(rgba(73, 80, 94, 0.4) !important);
  }
}

/*************** share profile complation css **************/
.cls-otpbox {
  &.mat-mdc-form-field {
    width: 37px;
    height: 37px;
    @include margin(left, top, right, bottom, 0, 0, 8, 0);
    @include border-radius;

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding: 0 !important;
          min-height: 34px;

          .mat-mdc-input-element {
            text-align: center;
            height: 34px;
          }

        }
      }

      .mdc-line-ripple {
        display: none;
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

.cls-editorbox {
  .ui-widget {
    .ql-toolbar.ql-snow+.ql-container.ql-snow {
      .ql-editor {
        padding: 24px !important;
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "medium");
        line-height: 1.43;
        color: $utl-text-color-2;
      }
    }
  }
}

/*************** document upload css ***************/
.document-upload {
  .flyer-content {
    perfect-scrollbar {
      .cls-docbox {
        @include padding(left, top, right, bottom, 40, 0, 40, 0);
      }
    }
  }
}

p-carousel._hr_carousel.new .ui-carousel .ui-carousel-viewport .ui-carousel-items li {
  margin-right: 12px !important;
  position: relative;
  min-width: 220px;
  max-width: 222px;
  margin-left: 3px !important;
}

p-carousel._hr_carousel.new .ui-carousel {
  width: 100%;
}

p-carousel._hr_carousel.new .ui-carousel .ui-carousel-viewport {
  width: 100%;
}

.offer-job.new .ui-carousel-viewport .ui-carousel-item {
  min-height: 127px !important;
}


.cls-survwiz {
  aw-wizard-navigation-bar {
    &.vertical {
      height: calc(100vh - 160px) !important;
    }
  }
}

/************* Edit Goal Css ***************/
.Cls-request {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {
      @include padding(left, top, right, bottom, 24, 0, 24, 0);
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-mdc-tab-body-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-mdc-tab-body {
        .searchScrolll {
          height: auto;

          .cls-grouping {
            @include margin(left, top, right, bottom, 10, 30, 10, 0);
          }
        }

        .scrollheightmain {
          height: auto;

          .cls-grouping {
            @include margin(left, top, right, bottom, 10, 30, 10, 0);
          }
        }
      }
    }
  }
}

/*************** survey comment css *******************/
.mat-mdc-form-field {
  &.cls-surveycomment {
    .mdc-text-field--no-label {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      // .mat-form-field-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-textarea-control {
          height: 84px;
          margin: 0 !important;
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-infix {
          height: 90px;
          @include border-radius;
          @include border(1px, solid, #c8cdd1);
          @include background-color;
          padding: 5px 0px 5px 8px !important;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mdc-line-ripple {

        &:before,
        &:after {
          border: 0;
        }

      }
    }
  }
}

/**************** carrrer postal radio css ***************/
.cls-carrerradio {
  @include padding(left, top, right, bottom, 17, 0, 17, 0);

  .mat-mdc-radio-group {
    .mat-mdc-radio-button {

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label {
        display: flex;
        align-items: flex-start;
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.43;
        color: $utl-text-color-12;

        p {
          @extend .mat-radio-label;
          font-weight: map-get($font-weights, "regular");
          color: $utl-grey-color-6;
          @include margin(left, top, right, bottom, 0, 9, 0, 24);
        }
      }
    }
  }
}

.cls-cpaccordion {
  perfect-scrollbar {
    @include padding(left, top, right, bottom, 17, 0, 17, 0);

    .mat-accordion {
      .mat-expansion-panel {
        @include border-radius;
        @include border(1px, solid, rgba(151, 151, 151, 0.5));
        @include background-color;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);

        .mat-expansion-panel-header {
          height: 60px !important;

          .mat-expansion-panel-header-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include margin(left, top, right, bottom, 0, 0, 16, 0);

            b {
              font-family: $font-family;
              font-size: $text-default-size;
              font-weight: map-get($font-weights, "semi-bold");
              line-height: 1.57;
              color: $utl-text-color-14;
            }
          }
        }
      }
    }
  }
}

.cls-input {
  &.mat-form-field-disabled {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-suffix {
          @include margin(left, top, right, bottom, 16, 0, 0, 0);

          .mat-icon {
            cursor: pointer;
            width: 20px;
            color: #00bfa5;
          }
        }
      }
    }
  }
}

.cls-round {
  &.mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {
        @include border(1px, solid, #cecece);
        @include border-radius;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          @include padding(left, top, right, bottom, 16, 16, 16, 16);
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-suffix {
          @include margin(left, top, right, bottom, 16, 0, 0, 0);

          .mat-icon {
            cursor: pointer;
            width: 20px;
            color: #00bfa5;
          }
        }
      }
    }
  }
}

/********** editor css **********/
.cls-editorcontent {
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  @include padding(left, top, right, bottom, 0, 0, 0, 0);

  p {
    font-family: $font-family;
    font-size: 12px;
    font-weight: map-get($font-weights, "regular");
    // line-height   : 1.43;
    letter-spacing: 0.35px;
    color: $utl-text-color-11;

    img {
      width: 100%;
    }
  }
}

.custome-status.arrowcng .status-inner mat-form-field .mat-mdc-form-field-wrapper .mat-select-arrow-wrapper .mat-select-arrow {
  // border-color: #00bfa5 !important;
  top: -2px !important;
  position: relative;
}

/*--------- END --------*/
/************ job apply popup css *****************/
.cls-applyjobpopup {
  @include border-radius;
  @include shadow($level: 12);
  @include background-color;

  .p-dialog {
    overflow: visible;

    .p-dialog-heade {
      display: none;
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 16, 87, 16, 40);
      overflow: visible;

      .dialog-header {
        width: 111px;
        height: 111px;
        @include border-radius;
        @include shadow($level: 18);
        @include background-color;
        position: absolute;
        top: -55px;
        left: 52px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: contain;
          height: 100%;
          width: 111px;
        }
      }

      .btnclose {
        position: absolute;
        right: 22px;
        top: 20px;
      }

      .cls-mainheading {
        font-family: $font-family;
        font-size: $heading-large-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.71;
        color: rgba(27, 36, 54, 0.8);
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .cls-nortxt {
        @extend .cls-mainheading;
        font-size: $heading-extra-small-font-size;
        @include margin(left, top, right, bottom, 0, 0, 0, 39);
        line-height: normal;
      }
    }
  }
}

.cls-ctcimg {
  .main-container-box {
    .inner-container-box {
      .cls-imgsrc {
        max-width: 100% !important;
      }
    }
  }
}

/************* inside filter css **************/


.cls-autocompleteinside {
  width: 100%;

  .ui-autocomplete {
    width: 100%;

    .ui-autocomplete-input {
      width: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border-pos(bottom, 0px, solid, transparent);
    }
  }

  .ui-autocomplete-panel {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.171);
      -webkit-border-radius: 6px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background: rgba(0, 0, 0, 0.8);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.185);
    }

    &::-webkit-scrollbar-thumb {
      &:window-inactive {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.mat-mdc-radio-button.cusbtn label {
  margin: 0;
  display: flex;
  align-items: self-start;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cusbtn .mat-radio-container {
  margin-top: 2px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.cusbtn .mat-radio-label-content {
  white-space: normal;
}

/************ process btn css **************/
.cls-processbtn {
  @include background-color(transparent);
  @include border-radius;
  height: 33px;
  width: 33px;
  @include padding(left, top, right, bottom, 3, 3, 3, 3);
  cursor: pointer;

  .material-icons {
    img {
      width: 24px;
    }
  }
}

/*************** dropdown css ***************/
.cls-dropnoline {
  max-width: 100px;
  width: 100%;

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-top: 0;

        .mat-mdc-select {

          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          .mat-select-trigger {

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-value {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-select-value-text {
                span {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, "regular");
                  color: $utl-text-color-2;
                }
              }
            }
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label {
            font-family: $font-family;
            font-size: $text-small-size;
            font-weight: map-get($font-weights, "regular");
            color: rgba(40, 44, 63, 0.5) !important;
          }
        }
      }
    }
  }
}

.cls-surdropdown {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding-top: 0;

        .mat-mdc-select {

          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          .mat-select-trigger {

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-value {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-select-value-text {
                span {
                  font-family: $font-family;
                  font-size: $text-default-size;
                  font-weight: map-get($font-weights, "semi-bold");
                  color: $primary-text-color;
                }
              }
            }
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: 400 !important;
            color: $utl-grey-color-6 !important;
          }
        }
      }
    }
  }
}

.cls-selectbox {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        .mat-mdc-select {

          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
          .mat-select-trigger {

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-value {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-select-value-text {
                span {
                  font-family: $font-family;
                  font-size: $text-default-size;
                  font-weight: map-get($font-weights, "semi-bold");
                  color: $primary-text-color;
                  line-height: 2;
                }
              }
            }
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: 400 !important;
            color: $utl-grey-color-6 !important;
          }
        }
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0;
    }
  }

  &.mat-form-field-appearance-legacy {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-ripple {
      height: 0;
    }
  }
}

.cls-surveyRating {
  @include margin(left, top, right, bottom, 0, 5, 0, 0);
  display: block;

  .ui-rating {
    a {
      .ui-rating-icon.pi.pi-star-o {
        font-size: $heading-large-font-size;
        color: #ced7db;
        text-shadow: 2px 0px 1px #607d8b;
        transition: all 0.2s ease-in-out;

        &::before {
          content: "\e937" !important;
        }

        &:hover {
          font-size: $heading-large-font-size;
          text-shadow: 2px 0px 1px #f99830;
          color: #ffca54;
          transform: scale(1.3);
        }
      }

      .ui-rating-icon.pi.pi-star {
        font-size: $heading-large-font-size;
        text-shadow: 2px 0px 1px #f99830;
        color: #ffca54;
      }
    }
  }

  .ui-state-disabled {
    a {
      .ui-rating-icon.pi.pi-star-o {
        font-size: $heading-large-font-size;
        color: #ced7db;
        text-shadow: 2px 0px 1px #607d8b;
        transition: all 0.2s ease-in-out;
        cursor: initial;

        &::before {
          content: "\e937" !important;
          cursor: initial;
        }

        &:hover {
          font-size: $heading-large-font-size;
          color: #ced7db;
          text-shadow: 2px 0px 1px #607d8b;
          transition: all 0.2s ease-in-out;
          cursor: initial !important;
          transform: none;
        }
      }

      .ui-rating-icon.pi.pi-star {
        font-size: $heading-large-font-size;
        text-shadow: 2px 0px 1px #f99830;
        color: #ffca54;
        cursor: initial;
      }
    }
  }
}

/**************** donut graph css ***************/
.cls-appbox {
  .highcharts-figure {
    .highcharts-container {
      height: 147px !important;

      .highcharts-root {
        font-family: $font-family !important;
        font-size: $text-default-size !important;
        font-weight: map-get($font-weights, "bold");
        line-height: 1.14;
        text-align: center;
        color: $utl-text-color-11;

        .highcharts-title {
          position: absolute;
        }

        // .highcharts-label {
        //   transform: translate(center, center) !important;
        //   opacity: 1 !important;
        //   visibility: visible !important;
        // }

        // .highcharts-background {}

        // .highcharts-plot-background {}

        // .highcharts-pane-group {}
      }
    }

    .highcharts-credits {
      display: none;
    }
  }
}

.cls-boxbdr {
  #container {
    .highcharts-container {
      height: 210px !important;

      .highcharts-root {
        font-family: $font-family !important;
        font-size: $heading-s-medium-font-size !important;
        font-weight: map-get($font-weights, "bold");
        line-height: 1.14;
        text-align: center;
        color: $utl-text-color-11;

        // .highcharts-data-labels {
        //   transform: translate(89, 135) !important;
        // }
        .highcharts-title {
          position: absolute;
          text-transform: uppercase;
          font-size: 12px !important;
        }

        .highcharts-background {
          height: 210px !important;
        }

        // .highcharts-plot-background {}

        // .highcharts-pane-group {}
      }
    }

    .highcharts-credits {
      display: none;
    }
  }
}

/*********** EXP Autocomplete css ************/
.cls-autocomplete {
  .exp-autocomplete {
    &.ui-autocomplete-dd {
      width: 100%;

      input.ui-corner-all {
        padding: 0px 0px 9px !important;
        width: 100%;
      }

      .ui-autocomplete-dropdown {
        display: none;
      }
    }
  }

  label {
    color: $primary-text-color;
    font-weight: map-get($font-weights, "medium");
    left: 0;
    margin-top: -0.5em !important;
  }
}

/**************** common css ***************/
.m-r-8 {
  margin-right: 8px;
}

.m-l-8 {
  margin-left: 8px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-r-16 {
  margin-right: 16px;
}

.m-l-16 {
  margin-left: 16px;
}

.m-t-12 {
  margin-top: 12px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-b-16 {
  margin-bottom: 16px;
}

.m-t-24 {
  margin-top: 24px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-r-24 {
  margin-right: 24px;
}

.m-l-24 {
  margin-left: 24px;
}

.m-r-32 {
  margin-right: 32px;
}

.m-l-32 {
  margin-left: 32px;
}

.m-t-32 {
  margin-top: 32px;
}

.m-b-32 {
  margin-bottom: 32px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.p-r-32 {
  padding-right: 32px;
}

.p-l-32 {
  padding-left: 32px;
}

app-show-more-content.imagefix img {
  max-width: 100%;
}

.job-detail-wrapper img {
  max-width: 100%;
}

.statuscheck {
  width: 100%;
  max-width: 169px;
  text-align: left;
}

.tooltip .ui-tooltip-text {
  width: 100%;
  max-width: 169px;
  text-align: left;
}

.chipbox .p-float-label .ui-inputtext {
  font-weight: 400;
  font-size: 11px;
  border-radius: 2px;
  background-color: #e0e0e0;
  border: none;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: #848484;
  padding: 2px 6px !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

/************** exp dropdown ****************/
.cls-expdropdown {
  &.mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      padding: 0;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {
        padding: 0;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          padding: 0;

          .mat-mdc-select {

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-trigger {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-select-value {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value-text {
                  font-weight: map-get($font-weights, "regular");
                }
              }
            }
          }

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label-wrapper {

            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-label {
              font-family: $font-family;
              font-size: $text-size-11;
              font-weight: map-get($font-weights, "regular") !important;
              color: rgba(16, 16, 16, 0.5) !important;
            }
          }
        }
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0 !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-ripple {
      height: 0 !important;
    }
  }
}

/************* Split btn css *************/
.cls-splitbtn {
  .p-component {
    .p-splitbutton-defaultbutton {
      @include padding(left, top, right, bottom, 15, 7, 15, 7);
      font-weight: map-get($font-weights, "semi-bold");

      &:enabled {
        &:hover {
          @extend.p-button;
        }
      }
    }

    .p-splitbutton-menubutton {
      @extend .p-splitbutton-defaultbutton;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

/*************** INt Story css ******************/
.commentbox {
  background: #f5f7fa;
  padding: 6px 16px;
}

.fontfix {
  font-family: $font-family;
  font-size: 12px;
  line-height: 1.58;
  color: #282c3f;
}

img.cmnt-icon {
  width: 14px;
  vertical-align: text-bottom;
  margin: 0px 4px 0 0;
}

.grayscale {
  filter: grayscale(0.95);
  background: transparent;
  cursor: auto !important;
}

.grayscale::-webkit-slider-thumb {
  cursor: auto !important;
}

.grayscale::-moz-range-thumb {
  cursor: auto !important;
}

.Goal-initiativebox .cls-mainbox .cls-kramainbox {
  min-height: 453px;
}

.Goal-initiativebox .cls-mainbox .cls-kraresultbox {
  min-height: 453px;
}

// app-blank-state-one.addspace .Blank-stat-text {
//   margin-bottom: 32px !important;
// }
.bordereddivchek {
  border-radius: 4px;
  border: solid 2px #ebebeb;
  padding: 15px 11px 15px;
  margin-bottom: 15px;
  min-height: 72px;

  .customcheck {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
      align-items: baseline;

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-inner-container {
        width: 20px;
        height: 20px;
        border-radius: 2.9px;
        margin: 0 10px 0 0;
        position: relative;
        top: 5px;

        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-frame {
          border: solid 2.1px #9e9e9e;
          border-width: 2px;
          border-radius: 2.9px;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-label {
        white-space: initial;
        color: $utl-text-color-2;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "medium");
      }
    }
  }
}

// radio btn css ***********
.cls-radiogrp {
  .mat-mdc-radio-button {
    width: 25%;
  }
}

// inbox datepicker
.cls-inboxdate {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-label-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "regular");
            line-height: 1.43;
            color: $utl-white !important;
            font-weight: 500;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-focused .mat-form-field-label {
          color: $utl-white !important;
          font-weight: 500;
        }

        .mat-mdc-input-element {
          color: $utl-white !important;

          ::placeholder {
            color: $utl-white !important;
          }

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          ::ng-deep .mat-form-field-label {
            color: #fff !important;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-placeholder {
          color: $utl-white !important;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-prefix,
      .mat-form-field-suffix {
        .mat-datepicker-toggle {
          color: $utl-white;
        }
      }
    }
  }

  &.mat-form-field-appearance-legacy {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      color: $utl-white;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  &.mat-form-field-can-float.mat-form-field-should-float {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      color: #8b8d97;
    }
  }
}

//******** enterprise upload css
/* upload link css */
.upload-link {
  .emp-img-uploader.main-sidebar-social {
    .p-fileupload {
      .p-button {
        height: 80px;
      }
    }
  }

  .p-fileupload {
    .p-button {
      @include background-color(transparent);
      @include border(0px, solid, transparent);
      height: 120px;
      width: 120px;
      @include border-radius(100%);

      &:hover,
      &:focus {
        @include background-color(transparent);
        @include border(0px, solid, transparent);
        box-shadow: none;
        background: none;
      }

      .p-button-icon-left {
        display: none;
      }

      .p-button-label {
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "medium");
        color: $utl-theme-color;
        text-decoration: underline;
        cursor: pointer;
        @include padding(left, top, right, bottom, 0, 10, 0, 0);
      }
    }
  }
}

//******** support otp css
.divOuter {
  width: 285px;
  overflow: hidden;

  .divInner {
    left: 0;
    position: sticky;

    .partitioned {
      padding-left: 19px;
      letter-spacing: 29px;
      border: 0;
      // background-image: linear-gradient(to left, #ccc 70%, rgba(255, 255, 255, 0) 0%);
      // background-position: bottom;
      // background-size: 50px 1px;
      // background-repeat: repeat-x;
      // background-position-x: 35px;
      // width: 320px;
      // min-width: 320px;
      background-image: url(/assets/images/border.png);
      background-repeat: no-repeat;
      height: 44px;
      width: 403px;
      min-width: 389px;
      @include margin(left, top, right, bottom, -5, 0, 0, 5);
    }
  }
}

//******** Recuritment dashboard css
.cls-recruitmentgraph {
  .mat-mdc-tab-header {
    /* border-bottom: 0px solid rgba(0,0,0,.12); */
    /* border: solid 1px rgba(151, 151, 151, 0.28); */
    @include border(0px, solid, transparent);
    @include padding(left, top, right, bottom, 0, 0, 0, 33);
    @include margin(left, top, right, bottom, 0, -30, 0, 0);

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-header-pagination {
      display: none;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {
          display: flex;
          justify-content: flex-end;

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            height: auto;
            @include padding(left, top, right, bottom, 10, 8, 10, 8);
            min-width: auto;
            font-family: $font-family;
            margin-right: 0;
            opacity: 1;
            color: $secondary-text-color;
            @include border(1px, solid, rgba(151, 151, 151, 0.28));

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            &.mat-tab-label-active {
              @include background-color($utl-grey-color-22);
            }
          }
        }

        .mat-ink-bar {
          @include background-color(transparent !important);
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    .mat-mdc-tab-body {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-body-content {
        .cls-bartext {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          color: $utl-grey-color-20;
          @include margin(left, top, right, bottom, 0, 0, 0, 33);
          display: block;
        }
      }
    }
  }
}

//*********** radio group css dynamic form
.cls-radiogrp {
  .mat-mdc-radio-button {

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
      align-items: flex-start;
      white-space: normal;
    }
  }
}

//*********** pagination css
.cls-pagination {
  .p-paginator {
    @include border(1px, solid, $utl-text-color-18);
    @include background-color;

    .p-paginator-pages {
      .p-paginator-page {
        &.p-highlight {
          @include background-color($utl-theme-color);
          background: $utl-theme-color;
          @include border(1px, solid, $utl-theme-color);
        }
      }
    }
  }
}

.cls-importpagination {
  &.mat-mdc-paginator {
    .mat-mdc-paginator-outer-container {
      .mat-mdc-paginator-container {
        .mat-mdc-paginator-page-size {

          .mat-mdc-form-field {
            width: 55px;

            .mat-mdc-text-field-wrapper {
              height: 30px;

              .mat-mdc-form-field-flex {
                .mdc-notched-outline {

                  .mdc-notched-outline__leading,
                  .mdc-notched-outline__trailing {
                    border-top: 0;
                    border-right: 0;
                    border-left: 0;
                    border-radius: 0;
                  }
                }

                .mat-mdc-form-field-infix {
                  padding-top: 0 !important;



                  .mat-mdc-select {
                    .mat-mdc-select-trigger {
                      .mat-mdc-select-value {
                        .mat-mdc-select-value-text {
                          .mat-mdc-select-min-line {
                            font-size: 100%;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .mat-mdc-paginator-range-actions {
          .mat-mdc-paginator-range-label {
            margin: 0;
          }
        }
      }
    }
  }
}

.custom-cont .ps {
  overflow-y: scroll;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch;
  white-space: pre-line;

  & * {
    touch-action: pan-y !important;
  }
}

//**********cancel btn css
.btn-cancel {
  @include background-color(transparent);
  min-width: 94px;
  @include border(1px, solid, rgba(26, 26, 26, 0.25));

  &:hover,
  &.disabled:hover {
    box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12),
      0 8px 10px -5px rgba(92, 92, 92, 0.2);
    @include background-color(transparent);
    @include border(1px, solid, rgba(26, 26, 26, 0.25));
  }
}

.cls-notebox {
  display: inline-flex;
  align-items: flex-start;
  @include border-radius(3px);
  // @include background-color(rgba(255, 247, 247, 0.66));
  font-family: $font-family;
  font-size: $text-medium-size;
  font-weight: map-get($font-weights, "regular");
  line-height: 1.67;
  color: #282e3d;
  @include padding(left, top, right, bottom, 6, 5, 6, 5);
  @include margin(left, top, right, bottom, 0, 4, 0, 0);

  img {
    height: 15px;
    @include padding(left, top, right, bottom, 0, 0, 5, 0);
  }

  span {
    font-family: $font-family;
    font-size: 11px;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.67;
    @include padding(left, top, right, bottom, 0, 0, 7, 0);
  }
}

.cls-notebox ul {
  list-style: disc;
}

._custom-scroll.comments-des.flyer-content .row {
  margin: 0 !important;
}

.cls-requestwf {
  width: calc(100% - 30%);
  margin: auto;
}



// ReimbursementcustomTable css
.ReimbursementcustomTable {
  .p-datatable {
    .p-datatable-wrapper {
      table {
        border-collapse: collapse;
        width: 100%;
        table-layout: auto;
        @include border(1px, solid, #b7bbc5);

        .p-datatable-thead {
          tr {
            th {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding(left, top, right, bottom, 15, 8, 15, 8);
              @include background-color($utl-grey-color-14);
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "medium");
              text-align: left;
              color: $utl-text-color-4;
              @include border-pos(bottom, 1px, solid, #d5d5d5);
              @include border-pos(top, 0px, solid, transparent);
              @include border-pos(right, 0px, solid, transparent);
              @include border-pos(left, 0px, solid, transparent);
            }
          }
        }

        .p-datatable-tbody {
          tr {
            td {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include padding(left, top, right, bottom, 15, 8, 15, 8);
              background-color: transparent !important;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "regular");
              text-align: left;
              color: $utl-text-color-4;
              @include border-pos(bottom, 1px, solid, #d5d5d5 !important);


              &:nth-child(1) {
                background-color: #f1f8f7 !important;
              }


            }

            &:nth-last-child(1) {
              background-color: #e4e6f5 !important;
            }

            &:nth-child(even) {
              background-color: transparent;
            }
          }
        }

      }
    }
  }
}

.w-auto {
  width: auto !important;
}



// autocomplete css
._employee-box-strip h2 {
  font-size: $text-default-size;
  font-weight: map-get($font-weights, "semi-bold");
  color: $utl-text-color-12;
  line-height: normal;
}

// ul li css
.list-imgstyle {
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  list-style: none;

  li {
    @include margin(left, top, right, bottom, 0, 0, 0, 20);

    .list-text {
      font-family: $font-family;
      font-size: $text-default-size;
      color: $utl-text-color-12;
      font-weight: map-get($font-weights, medium);
    }

    .clsdotpad {
      @include padding(left, top, right, bottom, 0, 17, 0, 0);
    }
  }
}

.p-sidebar {
  font-weight: 500;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.fixcheck .mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
  position: relative;
  top: -3px;
}

.anni .p-carousel-items-content {
  width: 36% !important;
}

.clsfilter-box {
  @include padding(left, top, right, bottom, 24, 24, 24, 24);

  .clsfilter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cls-search-filter {
    @include border-radius;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, bold);
    @include padding(left, top, right, bottom, 11, 4, 11, 4);

  }
}



.helpdesk-confbox.custom .p-dialog .p-dialog-content .btnclose {
  top: 2px !important;
}

.helpdesk-confbox.custom .btn-success {
  min-width: 96px;
  padding: 9px;
}

// Search Card Design=================
.search-card-wrapper {
  @include padding(left, top, right, bottom, 24, 24, 24, 24);

  .card {
    cursor: pointer;
    @include background-color(#fafafa);
    @include border(1px, solid, rgba(0, 0, 0, 0.07));
    @include border-radius;
    flex: 0 0 203.7px;
    width: 100%;
    @include margin(left, top, right, bottom, 0, 0, 24, 24);

    &:nth-child(3n) {
      @include margin(left, top, right, bottom, 0, 0, 0, 24);
    }
  }
}

//global search css
.mhAutoComp {
  .p-autocomplete-multiple-container {
    max-height: 100px;
    overflow-y: auto;
    @include border-radius-pos(3px, 0, 0, 3px);
  }
}

.different {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 97px;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .hide-header {
    app-fcc-engage .engage-cards-container {
      margin: 0 auto !important;
      width: calc(100% - 14%);
    }
  }
}

@media only screen and (max-width: 320px) {
  .hide-header {
    app-fcc-engage .engage-cards-container {
      margin: 0 auto !important;

    }
  }

  .white-bg-card {
    .avatar-container {
      width: 32px !important;
      height: 32px !important;

      .avatar-content {
        font: 10px / 32px Helvetica, Arial, sans-serif !important;
      }

      img {
        width: 32px !important;
        height: 32px !important;
      }
    }
  }

  .shw-rside-30.theme-customize {
    width: calc(100% - 14%) !important;
  }

  .shw-rside-30 {
    width: calc(100% - 24%) !important;
  }

  .shw-rside-60 {
    width: calc(100% - 19%) !important;
  }

  #att_calendar table {
    table-layout: fixed;
  }

  .carrer-preview .cls-background {
    background-position: center center !important;
    background-size: 39em;
    background-color: #fff;
    min-height: 200px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .fulltxt .mat-checkbox-layout .mat-checkbox-label {
    white-space: normal;
    padding-top: 15px;
  }
}

//performance check box  issue fix
.cls-section {
  .mat-mdc-checkbox {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {
      align-items: flex-start;

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-inner-container {
        margin-top: 3px;
      }
    }
  }
}



// my team select css
._team-select {
  @include padding(left, top, right, bottom, 5, 4, 9, 4);
  @include border-radius(2px);
  @include border(0.5px, solid, #a1a4ac);
  @include background-color;
}



.tbl-box {


  .mat-menubutton {
    // @include border(2px, solid, $utl-theme-color);
    @include background-color(transparent);
    @include padding(left, top, right, bottom, 6, 2, 6, 2);
    @include margin(left, top, right, bottom, 5, 0, 5, 0);
    cursor: pointer;
    @include border-radius;
  }
}

.cls-teamchip {
  .mat-mdc-standard-chip {
    @include padding(left, top, right, bottom, 14.2, 6, 13.8, 6);
    @include margin(left, top, right, bottom, 0, 0, 8, 8);
    @include border-radius(16px);
    @include background-color(#e3e8f1);
    @include border(0px, solid, transparent);
    font-size: $text-medium-size;
    font-weight: map-get($font-weights, "semi-bold");
    line-height: 1.67;
    color: #424b5f;
  }
}

.cls-proformance-review .subtitle {
  font-weight: 600 !important;
}

span.chips-design.green {
  border-radius: 4px;
  // background-color: #54ab5d !important;
  color: #fff;
  font-size: 11px;
  height: 19px;
  margin: 4px 4px 0 0;
  text-align: center;
  // display: flex;
  width: max-content;
  padding: 2px 7px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.max-w17 {
  max-width: 24px;
  margin-top: 2px;
}

span.text-green {
  // color: #54ab5d;
  font-weight: 600;
}

#refer-carousel .p-carousel-item {
  min-width: 239px;
  max-width: 240px;
}

//geo location map css
.leaflet-container {
  height: 150px;

  .leaflet-pane {
    position: relative;
  }
}



.mat-mdc-select-panel {
  .mat-mdc-optgroup {
    font-size: initial;

    .mat-mdc-optgroup-label {
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, semi-bold);
    }

    .mat-mdc-option:not(.mat-mdc-option-multiple) {
      padding-left: 16px;

      /* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version.*/
      .mat-option-text {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, medium);
      }
    }
  }
}

// conditional workflow css
.cls-condflow {
  .mat-mdc-radio-group {
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    color: $utl-text-color-12;

    .mat-mdc-radio-button {

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-text-color-12;
        }
      }
    }
  }
}

// overflow css
.cls-overflowshow {
  overflow: visible;
  // .ps {
  //   overflow: visible !important;
  // }
}

.leaflet-pane {
  position: relative;
}

.cls-KPIMain.d-flex {
  .subtitle {
    margin-top: 0px;
    font-weight: 500;
    line-height: normal;
    font-size: 12px;
  }
}

// clearance accordion css
.clearancewrap-box {
  .mat-accordion {
    .mat-expansion-panel {
      // @include margin(left, top, right, bottom, -24, 15, -24, -17);
      // margin: 15px -24px -17px -24px;
      position: relative;
      z-index: 9;
      overflow: visible;

      .mat-expansion-panel-header {
        position: absolute;
        top: -76px;
        z-index: 9999;
        right: 0;
        @include background-color(transparent);
        height: 18px;
      }
    }

    .mat-expanded {
      .mat-expansion-panel-header {
        .mat-expansion-indicator {
          margin-top: 10px;
        }
      }
    }
  }
}

// hrops graphs css
.cls-hrops {
  .mat-mdc-tab-group {
    .mat-mdc-tab-header {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-container {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-list {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-labels {

            /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            .mat-tab-label {
              height: 58px;
              @include margin(left, top, right, bottom, 27, 0, 0, 0);
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// hr0ps table css
.customTable {
  .p-datatable {
    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-view {
        .p-datatable-scrollable-header {
          .p-datatable-scrollable-header-box {
            padding-right: 17px !important;
          }
        }
      }
    }
  }
}

.leavebalance-spacing {
  .clsgray-list {

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-select-value-text {
      font-size: 14px !important;
      font-weight: 600;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label {
      font-size: 14px !important;
      color: #262e3e !important;
    }
  }
}

.leaveBalanceTable p-table.customTable .p-datatable tbody tr:nth-child(even) {
  background-color: #ffffff;
}

//css for calendar popup
.mat-datepicker-popup {
  .mat-datepicker-content {
    .mat-calendar {
      .mat-calendar-content {
        .mat-calendar-table {
          .mat-calendar-table-header {
            tr {
              th {
                font-weight: map-get($font-weights, "semi-bold");
              }
            }
          }

          .mat-calendar-body {
            tr {
              td {
                &.mat-calendar-body-active {
                  .mat-calendar-body-selected {
                    @include background-color(#65d9ba);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.p-dialog.showcross {
  .p-dialog-header {
    display: block !important;

    .btnclose {
      top: 24px;
      right: 24px;
      bottom: initial;
      left: initial;
      position: absolute;
      z-index: 9;
    }
  }
}

.wall-emp-right p-autocomplete span.autoCompleteOne input {
  border-bottom: 0;
}

.organization-wise.fix-new {
  width: 100%;
  padding-right: 10px;
}

//eneterprise wall widget css
.cls-wizwhitebox {
  perfect-scrollbar {
    height: 100% !important;

    .wall-wiz-container {
      .team-box {
        @include margin(left, top, right, bottom, 0, 0, 0, 24);
        @include shadow($level: 1);
        @include border-radius;
        @include background-color;
      }
    }
  }
}

.search-cont {
  @include margin(left, top, right, bottom, 0, 0, 0, 0);

  .team-box {
    @include border-radius;
    @include shadow($level: 24);
    @include background-color(#f9fbfd);
    @include margin(left, top, right, bottom, 0, 0, 0, 8);
  }
}

.wizsearch-cont {
  perfect-scrollbar {
    height: 100% !important;
  }
}

// expense payment popup css
.fix-new {
  perfect-scrollbar {
    height: 100%;
  }
}

.ratingrec {
  .p-rating {
    display: flex;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.eyediv .mat-form-field-infix {
  display: inline-flex;
}

.post-update.linkd p-fileupload .chat-file-attach .p-fileupload-content {
  padding-top: 0 !important;
  border: 0 !important;
  background-color: none !important;
}

p-splitbutton.cls-splitbtn.fix button.p-splitbutton-defaultbutton.p-button.p-component {
  padding: 10px 22px;
  font-size: 13px;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.clsemp-statutory .myCtcStatCheck .mat-checkbox-label {
  color: $utl-text-color-14;
  font-size: $text-default-size;
  font-weight: map-get($font-weights, "semi-bold");
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxlist.myCtcStatCheck .mat-checkbox-inner-container {
  display: block;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxlist.myCtcStatCheck .mat-checkbox-label {
  color: #38404e;
  font-size: 14px;
  font-weight: 500;
}

.greenbtn .cls-splitbtn .p-component .p-splitbutton-defaultbutton,
.greenbtn .cls-splitbtn .p-component .p-splitbutton-menubutton {
  border: 1px solid #00bfa5;
  color: #ffffff;
  background-color: #00bfa5;
}

.importsec .cus-attacher .p-fileupload .p-fileupload-buttonbar span.p-button {
  // color: #12b995;
}


.tabsalign .mat-mdc-tab-group {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab {
            padding: 0;
            justify-content: flex-start;
            align-items: self-start;
            height: 37px;
            min-width: auto;
            margin-left: 32px;
          }
        }
      }
    }
  }
}


.welcomenote {
  .inbox-top-panel {
    padding-left: 0 !important;
  }
}

// settlement btn css
.cls-floatbtn {
  width: 100%;

  .p-splitbutton {
    width: 100%;

    .p-button {
      @include background-color($utl-theme-color);
      @include border(1px, solid, $utl-theme-color);
      height: 40px;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.quickhack {
  .p-dialog .p-dialog-content {
    min-height: 540px !important;
  }
}

input.search-input.archive {
  height: 27px;
  @include padding(left, top, right, bottom, 5, 4, 30, 5);
  border-radius: 2px;
  font-size: $text-medium-size;
  font-weight: map-get($font-weights, "medium");
  width: 93%;
}

.border-search-box.archive {
  ::-webkit-input-placeholder {
    color: #bdc7da !important;
    font-weight: map-get($font-weights, "light");
  }
}

// CXO Common filter css
.pcfilterbox {
  position: relative;

  .pcfiltersuggestion {
    position: absolute;
    z-index: 99;
    min-width: 100px;
    right: -102px;
    top: 49px;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    .cls-suggestionbox {
      display: flex;
      position: relative;

      .fs-14 {
        width: 160px;
        color: $utl-text-color-4;
        @include margin(left, top, right, bottom, 0, 2, 0, 0);
      }

      ul {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include margin(left, top, right, bottom, 10, 0, 0, 0);
        display: flex;
        width: 100%;

        li {
          list-style: none;
          @include background-color($utl-text-color-16);
          @include padding(left, top, right, bottom, 8, 0, 25, 0);
          @include margin(left, top, right, bottom, 0, 0, 12, 0);
          font-size: $text-medium-size;
          line-height: 25px;
          color: #424242;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
          display: block;
          max-width: 104px;

          span {
            display: inline-block;
            position: absolute;
            right: 9px;
            width: 10px;
            height: 10px;
            line-height: 25px;
          }

          &:hover {
            cursor: pointer;
          }
        }

        .cls-show {
          list-style: none;
          @include background-color(transparent);
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          .cls-showmore {
            font-size: $text-medium-size;
            line-height: 25px;
            color: $utl-theme-color;
            text-decoration: underline;
            position: relative;
            top: auto;
            text-align: left;
            right: auto;
            cursor: pointer;
          }
        }
      }
    }
  }

  .pcfilter {
    display: inline-block;
    width: 40px;
    height: 35.33px;
    text-align: center;
    padding: 7px 0;
    border: 0.5px solid rgba(161, 164, 172, 0.43);
    border-radius: 2px;
    background: #fff;
    position: relative;

    &.active {
      i {
        color: #00bfa5;
      }
    }

    i {
      color: #9e9e9e;
    }

    span {
      position: absolute;
      background: #ff5252;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      top: -10px;
      right: -10px;
      z-index: 99;
      color: #ffffff;
      font-size: 0.785em;
      line-height: 20px;
      font-weight: 600;
      text-align: center;
    }
  }
}

// form 16 acss
.importsec {
  .cls-imgbox {
    .ui-fileupload-row {
      display: flex;
      @include background-color(transparent);
      position: relative;
      overflow: initial;
      padding: 0;
      @include border(2px, solid, #f5f5f5);
      @include border-radius(2px);
      @include margin(left, top, right, bottom, 0, 10, 0, 10);
      flex-direction: column;
      flex-wrap: wrap;
      width: 138px;
      align-items: center;
      justify-content: center;

      .delete-box {
        background-image: url("/assets/images/close-dark.png") !important;
        @include background-color($utl-white);
        background-size: cover;
        @include border-radius(50px);
        width: 18px;
        height: 18px;
        text-indent: -99999px;
        right: -11px;
        position: absolute;
        top: 8px;
      }

      figure {
        // background-image   : url("/assets/images/excelfrm.png");
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        width: 59px;
        height: 67px;
        border: 0;
        border-radius: 2px;
        // background-image: url("assets/images/excelfrm.svg");
        margin-top: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .file-name {
        padding: 3px 30px 3px 3px;
        position: relative;
        display: flex;
        align-items: center;

        small {
          @include background-color($utl-theme-color);
          color: $utl-white;
          font-size: $text-midum-small-size;
          margin-right: 4px;
          display: flex;
          align-items: center;
          padding: 3px 3px;
          border-radius: 2px;
          font-weight: 600;
        }

        span {
          @include abs-pos($top: initial, $right: -1px, $bottom: -1px, $left: initial);
          width: 35px;
          display: block;

          img {
            width: 100%;
          }
        }

        b {
          @include text-truncate();
          color: $utl-text-color-4;
          font-weight: map-get($font-weights, "semi-bold");
          font-size: $text-medium-size;
          width: 100%;
          display: block;
        }
      }

      .normalCls {
        opacity: 1;
      }

      .clsFade {
        opacity: 0.4;
      }
    }
  }
}

#prediction .p-carousel-item {
  min-width: 567px;
  max-width: 567px;
}

.predic {
  .p-dialog .p-dialog-footer {
    border-top: 0;
    padding: 0 !important;

    button {
      margin-bottom: 0px;
      padding: 19px 32px 35px;
      border-top: 1px solid #c8c8c8;
    }
  }
}

.moodbotDialog.fix {
  max-height: 550px !important;

  .p-dialog-content {
    min-height: 405px;
    max-height: 418px;

    .moodbotChips {
      position: relative;
      min-height: 100px;
    }
  }
}


.figtext {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  font-size: 17px;
  margin: auto;
  top: 0;
  bottom: 0;
  max-width: 80px;
  word-break: break-word;
  height: 173px;
  flex-direction: column;
  font-weight: 900;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
}

.globalbadge {
  font-size: 12px !important;
  height: 74px !important;
  width: 61px !important;
}

.main {
  font-size: 10px !important;
  height: 77px !important;
  width: 75px !important;
  flex-direction: column;
  padding: 0 6px;
}

.main-home {
  font-size: 6px !important;
  width: 80px !important;
  padding: 0 13px;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  margin-top: -18px;
}

.comments-home app-hrone-chat-input .col-md-12 {
  padding: 13px 0px 0 6px !important;

  .overlapdiv {
    top: 10px;
  }
}

.sm-font {
  .p-autocomplete-token-label {
    font-size: 12px !important;
  }

  input {
    font-size: 12px !important;
    text-overflow: ellipsis;
  }
}

/************ Travel request css ***********/

.cls-timebox {
  @include margin(left, top, right, bottom, 0, 16, 0, 0);

  .p-float-label {
    .p-calendar {
      width: 100%;

      .p-inputtext {
        @include border-pos(bottom, 1px, solid, #e9edf2);
        @include border-pos(top, 0px, solid, transparent);
        @include border-pos(left, 0px, solid, transparent);
        @include border-pos(right, 0px, solid, transparent);
        @include border-radius(0);
        font-weight: map-get($font-weights, "regular");
        @include padding(left, top, right, bottom, 0, 0, 0, 5);

        &:enabled:focus {
          box-shadow: none;
        }
      }

      .p-datepicker {
        min-width: 80%;

        .p-timepicker {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          .p-hour-picker {
            .p-link {
              width: 1rem;
              height: 1rem;

              span {
                font-size: $text-default-size;
              }
            }

            span {
              font-size: $text-default-size;
            }
          }

          .p-separator {
            span {
              font-size: $text-default-size;
            }
          }

          .p-minute-picker {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);

            .p-link {
              width: 1rem;
              height: 1rem;

              span {
                font-size: $text-default-size;
              }
            }

            span {
              font-size: $text-default-size;
            }
          }
        }
      }
    }
  }

  .mat-mdc-form-field-error {
    span {
      font-size: $text-size-11;
      font-weight: map-get($font-weights, "regular");
    }
  }
}

.greybg {
  .cls-editorcontent {
    word-break: break-word;
  }
}

.cls-pscal {
  @include margin(left, top, right, bottom, 0, 7, 0, 0);

  .p-calendar {
    .p-inputtext {
      @include border-pos(bottom, 1px, solid, #e9edf2);
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-radius(0);
      font-weight: map-get($font-weights, "regular");
      @include padding(left, top, right, bottom, 0, 0, 0, 5);

      &:enabled:focus {
        box-shadow: none;
      }
    }

    .p-datepicker {
      min-width: 80%;

      .p-timepicker {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        .p-hour-picker {
          .p-link {
            width: 1rem;
            height: 1rem;

            span {
              font-size: $text-default-size;
            }
          }

          span {
            font-size: $text-default-size;
          }
        }

        .p-separator {
          span {
            font-size: $text-default-size;
          }
        }

        .p-minute-picker {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          .p-link {
            width: 1rem;
            height: 1rem;

            span {
              font-size: $text-default-size;
            }
          }

          span {
            font-size: $text-default-size;
          }
        }
      }
    }
  }

  .mat-mdc-form-field-error {
    span {
      font-size: $text-size-11;
      font-weight: map-get($font-weights, "regular");
    }
  }
}

#hardware-search {
  .p-carousel-content .p-carousel-container .p-carousel-next {
    display: none;
  }

  .p-carousel-content .p-carousel-container .p-carousel-prev {
    display: none;
  }
}

.spxfix {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding-top: 0px;
  }
}

.prog {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    display: flex;
    font-size: 0.857em !important;
    font-weight: 500 !important;
    line-height: 1.67 !important;
    color: #717171 !important;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-label-wrapper {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-label {
        // font-weight: map-get($font-weights, "medium") !important;
        // margin-bottom: 0;
        // color: #afb3bb !important;
        // font-family: $font-family;    font-size: 12px !important;
        font-size: 0.857em !important;
        font-weight: 500 !important;
        line-height: 1.67 !important;
        color: #717171 !important;
      }
    }
  }

  .mat-mdc-form-field {
    line-height: 1.5;
    color: $secondary-text-color !important;
    font-family: $font-family;
    font-size: $text-small-size;
  }
}

.status {

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text span {
    font-weight: map-get($font-weights, "medium") !important;
    margin-bottom: 0;
    font-family: $font-family;
    font-size: $text-small-size !important;
    color: $secondary-text-color !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    /* border: solid #00bfa5 !important; */
    border-width: 0px 3px 3px 0px !important;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
    transform: rotate(179deg);
    -webkit-transform: rotate(180deg);
    border-left: 4px solid transparent !important;
    border-right: 4px solid transparent !important;
    border-bottom: 4px solid #00bfa5 !important;
    margin: 0 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    background-color: #ffffff !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .cls-selected .mat-form-field-wrapper .mat-form-field-infix {
    padding-top: 0px !important;
  }
}

.custom-prog {
  max-width: 25%;
  padding-left: 38px;
}

#feedback-panel-acc .mat-expansion-indicator {
  border: 0 !important;
  background: #fafafa !important;
  top: 6px !important;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  position: absolute;
  right: 12px;
}

.pip-eval {
  ._user-snippet {
    ._snippet-inner {
      padding: 0px !important;

      .size-51-right {
        width: calc(100% - 0px) !important;
      }
    }
  }
}

.inbox-pip .loan_inbox {
  max-width: 100% !important;
  margin: 0 !important;
  padding: 9px 0 !important;
}

#hardware-inv .p-carousel-item {
  min-width: 100%;
  max-width: 100%;

  .p-carousel-items-content {
    margin: 0 auto;
    width: auto;
  }
}

#hardware-inv {
  .p-carousel-content .p-carousel-container .p-carousel-next {
    display: none;
  }

  .p-carousel-content .p-carousel-container .p-carousel-prev {
    display: none;
  }
}

#hardware-search .p-carousel-item {
  // min-width: 247px;
  max-width: 400px;

  .p-carousel-items-content {
    margin: 0 auto;
    width: auto;
  }
}

.view-page {
  perfect-scrollbar {
    padding-right: 20px;
  }

  .rowfix {

    .fixcol1,
    .fixcol3 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .fixcol2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    .p-float-label {
      margin-top: 10px;
      margin-bottom: 0px !important;
    }
  }

  .row2 {
    .serial {
      flex: 0 0 25%;
      max-width: 25%;
    }

    .col-md-2 {
      flex: 0 0 25%;
      max-width: 25%;

      input {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .full {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 0.5rem !important;
    }
  }

  .col-md-8 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rowfix6,
  .rowfix4 {
    .col-4 {
      flex: 0 0 49%;
      max-width: 49%;
    }
  }
}

.summary-page {

  .rowfix,
  .row2 {
    padding-right: 10px;
    margin-bottom: 10px;
  }

  .rowfix6,
  .rowfix4 {
    margin-top: 10px;
  }

  .serial {
    flex: 0 0 40%;
    max-width: 40%;
    margin-bottom: 10px;
  }

  .p-float-label {
    margin-top: 10px;
    margin-bottom: 0px !important;
  }

  .row2 {
    .serial {
      flex: 0 0 33%;
      max-width: 33%;
    }

    .col-md-2 {
      flex: 0 0 33%;
      max-width: 33%;
    }
  }
}

.comments-main {
  position: relative;
}

.comments-home {
  .emp-list-section {
    // width: 95% !important;
    // bottom: 0px !important;
    // top: auto;
    // z-index: 9;
    width: 60% !important;
    bottom: 0px !important;
    top: 100%;
    z-index: 9;
  }
}

// .attach {
//   .p-fileupload-content {
//     display: none;
//   }
// }
.viewfrm {
  .loan_inbox {
    max-width: 100% !important;
    margin: 0 !important;
  }
}

._flyer-wrapper {
  .flyer-content {
    .cls-viewreq {
      perfect-scrollbar {
        @include padding(left, top, right, bottom, 32, 0, 32, 0);
      }
    }
  }
}

.asset-card {
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  display: block;

  .card {
    @include background-color(#fafafa);
    @include border(1px, solid, rgba(0, 0, 0, 0.07));
    @include border-radius;
    flex: 0 0 203.7px;
    width: 100%;
    @include margin(left, top, right, bottom, 0, 0, 24, 24);

    &:nth-child(3n) {
      @include margin(left, top, right, bottom, 0, 0, 0, 24);
    }
  }
}

#teamokr .p-carousel-item {
  min-width: 361px;
  max-width: 452px;
  width: 100%;
}

.invest {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-label {
    font-size: 14px !important;
    color: #262e3e;
    font-size: 12px;
    font-weight: 500 !important;
  }
}

.taxcalc .taxbtn {
  right: 8.5% !important;

  .btn-close {
    top: 15px !important;
  }
}

.filterform {
  mat-label {
    color: $utl-grey-color-6;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 0;
  }

  .sm-font {
    ::-webkit-input-placeholder {
      font-size: 0.928em !important;
      font-size: $text-medium-size;
      font-weight: 500;
      margin-bottom: 0;
    }
  }
}

// calander css
.cls-caldat {
  .p-float-label {
    .cls-exp-calaender {
      span.p-calendar {
        .p-datepicker {
          left: -73.4531px !important;
        }
      }
    }
  }
}

.pending {
  height: auto;
  font-size: 12px;
  align-items: flex-start !important;
  display: flex;
  font-weight: 500;
  color: #f5792a;
  text-decoration: underline;

  img {
    height: auto;
    margin-bottom: 2px;
    width: 1.2em;
    margin-top: 2px;
    margin-right: 3px !important;
    padding-right: 0 !important;
  }
}

.approve {
  color: #45953c;
  font-size: 12px;
  align-items: flex-start !important;
  display: flex;
  font-family: $font-family;

  img {
    height: auto;
    margin-bottom: 2px;
    width: 1.2em;
    margin-top: 2px;
    margin-right: 3px !important;
    padding-right: 0 !important;
  }
}

.errormark {
  color: #c04d43;
  font-family: $font-family;
  font-size: 12px;
  align-items: flex-start !important;
  display: flex;
  text-decoration: underline;

  img {
    height: auto;
    margin-bottom: 2px;
    width: 1.2em;
    margin-top: 2px;
    margin-right: 3px !important;
    padding-right: 0 !important;
  }
}

.shw-rside-40-new {
  width: calc(100% - 47.6%) !important;
}

.shw-rside-35 {
  width: calc(100% - 60%) !important;
}

.cls-jobopen {
  .mat-mdc-tab-header {
    @include margin(left, top, right, bottom, -15, 0, -15, 0);

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            height: 61px;
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "medium");
            color: rgba(27, 36, 54, 0.7);
            @include margin(left, top, right, bottom, 25, 0, 0, 0);
            @include padding(left, top, right, bottom, 13, 0, 13, 0);
            opacity: 1;
          }
        }

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-active {

          /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            opacity: 1 !important;
          }
        }
      }
    }
  }
}

.jobopening-content {
  .jobopning {
    .subtitle {
      color: rgba(40, 49, 75, 0.66);
      @include margin(left, top, right, bottom, 0, 2, 0, 32);
      font-size: $text-default-size;
      line-height: 1.71;
    }
  }
}


.singleline {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    display: flex;
    padding-top: 0 !important;
  }

  .mat-mdc-input-element {
    color: #1b2436 !important;
    font-size: 10px !important;
    font-weight: 500;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
    padding: 0 0 6px !important;
  }
}

.status {

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color: $secondary-text-color;
    font-size: 14px !important;
    font-weight: 500;
    display: block;
    padding-bottom: 8px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0 !important;
    border: 0 !important;
  }
}

.w-29 {
  max-width: 29%;
}

.cls-selectborder {
  border-radius: 4px;
  height: 35px;
  @include margin(left, top, right, bottom, 0, 0, 0, 17);
  @include padding(left, top, right, bottom, 0, 0, 0, 0);

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0;
      @include background-color(transparent !important);

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-ripple {
        @include background-color(transparent !important);
      }
    }
  }

  &.shortlist {


    .mat-mdc-form-field {
      text-align: center;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-text-field-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-mdc-form-field-infix {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            border: 0;

            .mat-mdc-select {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-mdc-select-trigger {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value {

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-value-text {
                    // color: $utl-theme-color;
                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights , 'bold');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 33px;
                    text-transform: uppercase;
                  }
                }

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-mdc-select-arrow-wrapper {
                  // @include border-pos(left, 1.5px, solid, $utl-theme-color);
                  @include padding(left, top, right, bottom, 10, 5, 0, 11);

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-arrow {
                    // border-color: $utl-theme-color !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.reject {
    .mat-mdc-form-field {
      .mat-mdc-text-field-wrapper {
        text-align: center;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-wrapper {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-mdc-form-field-flex {

            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-mdc-form-field-infix {
              @include padding(left, top, right, bottom, 0, 0, 0, 0);
              border: 0;

              .mat-mdc-select {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-mdc-select-trigger {

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-value {

                    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                    .mat-select-value-text {
                      // color: #ff0000;
                      font-family: $font-family;
                      font-size: $text-medium-size;
                      font-weight: map-get($font-weights , 'bold');
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 33px;
                      text-transform: uppercase;
                    }
                  }

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-mdc-select-arrow-wrapper {
                    // @include border-pos(left, 1.5px, solid, #ff0000);
                    @include padding(left, top, right, bottom, 10, 5, 0, 11);

                    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                    .mat-select-arrow {
                      // border-color: #ff0000 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.pending {
    .mat-mdc-form-field {
      text-align: center;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-text-field-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-mdc-form-field-infix {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            border: 0;

            .mat-mdc-select {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-mdc-select-trigger {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value {

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-value-text {

                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights , 'bold');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    height: 33px;
                  }
                }

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-mdc-select-arrow-wrapper {
                  // @include border-pos(left, 1.5px, solid, #adc2d1);
                  padding: 5px 0 11px 10px;

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-arrow {
                    // border-color: #8fa2b0 !important;
                  }
                }
              }
            }
          }
        }

      }
    }
  }

  &.hold {
    border: 1px solid #c7c7c7;

    .mat-mdc-form-field {
      text-align: center;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-mdc-text-field-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-mdc-form-field-flex {

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-mdc-form-field-infix {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            border: 0;

            .mat-mdc-select {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-mdc-select-trigger {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value {

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-value-text {
                    .mat-mdc-select-min-line {
                      color: #737373;
                    }

                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights , 'bold');
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    height: 33px;
                  }
                }

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-mdc-select-arrow-wrapper {
                  @include border-pos(left, 1.5px, solid, #c7c7c7);
                  padding: 5px 0 11px 10px;

                  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                  .mat-select-arrow {
                    border-color: #898989 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


.filterlist {
  &.new {
    .mdc-evolution-chip-set {
      display: inline-block;
      flex-wrap: nowrap;

      .mdc-evolution-chip-set__chips {
        .mat-mdc-chip {
          &.newchip {
            border: 1px solid #e3e8f1 !important;
            background-color: #e3e8f1;


            border-radius: 16px !important;

            min-height: auto !important;
            cursor: pointer;
            min-width: 0;


          }

          .mdc-evolution-chip__cell {
            .mdc-evolution-chip__action {
              padding: 0;

              .mdc-evolution-chip__text-label {
                span {
                  padding: 4px 8px;
                  font-size: $text-medium-size !important;
                  color: #424b5f !important;
                  font-family: $font-family;
                  font-weight: 600;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: block;
                }
              }
            }
          }

          &.mat-mdc-chip-selected {

            .mdc-evolution-chip__cell {
              .mdc-evolution-chip__action {
                padding: 0;

                .mdc-evolution-chip__text-label {
                  span {
                    padding: 4px 8px;
                    font-size: $text-medium-size !important;
                    color: #fff !important;
                    font-family: $font-family;
                    font-weight: 600;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    display: block;
                    border-radius: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


// CXO performance tab css
.performance-tab {
  position: relative;

  .mat-mdc-tab-header {
    right: 36px !important;
    z-index: 9;

    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-labels {

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label {
        min-width: 100px !important;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-label-active {
        color: $utl-white !important;

        /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-label-content {
          color: $utl-white !important;
          min-width: 100px !important;
        }
      }
    }
  }
}

.show-chat {
  a {
    color: #007afc !important;
    font-weight: 600;
  }
}

.otherflyer {

  .mat-mdc-form-field-wrapper {
    width: 25%;
  }
}

.delete-hr-post.goalpage {
  .mat-mdc-menu-content {
    min-width: 145px !important;
  }
}

.tabsalign.new {
  .mat-mdc-tab-header {
    margin: 0 0px 20px;
  }
}

// input pay ot css
.cls-payot {


  .mat-mdc-form-field-wrapper {


    .mat-mdc-form-field-flex {


      .mat-mdc-form-field-infix {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        border-top: 0px solid transparent;
        font-weight: map-get($font-weights, "semi-bold");
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0px;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-ripple {
        @include background-color(transparent !important);
      }
    }
  }
}

// announcement accordain css
.cls-announcement {
  .p-accordion {
    .cls-announcementbrd {
      @include border-radius;
      @include border(1px, solid, rgba(0, 0, 0, 0.07));
      @include background-color;
      @include margin(left, top, right, bottom, 0, 0, 0, 8);

      p-accordiontab {
        .p-accordion-tab {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          .p-accordion-header {
            .p-accordion-header-link {
              @include padding(left, top, right, bottom, 16, 16, 16, 16);
              @include border-pos(top, 0px, solid, transparent);
              @include border-pos(right, 0px, solid, transparent);
              @include border-pos(left, 0px, solid, transparent);
              @include border-pos(bottom, 1px, solid, #e8e8e8);
              @include background-color(transparent);
              color: initial;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: row-reverse;

              &:focus {
                box-shadow: none;
              }

              .p-accordion-toggle-icon {
                font-size: 12px;
                @include margin(left, top, right, bottom, 0, 0, 0, 0);
              }

              p-header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 94%;

                .clssub-text {
                  display: flex;
                  align-items: flex-start;

                  img {
                    @include margin(left, top, right, bottom, 0, 0, 14, 0);
                  }

                  .cls-comtxt {
                    span {
                      font-family: $font-family;
                      font-size: $text-medium-size;
                      font-weight: map-get($font-weights, "bold");
                      line-height: 1.67;
                      color: #424242;
                    }
                  }
                }

                .cls-anch {
                  font-family: $font-family;
                  font-size: $text-small-size;
                  font-weight: map-get($font-weights, "medium");
                  line-height: 1.5;
                  text-align: right;
                  color: #00bba1;
                  text-decoration: underline;
                }
              }
            }
          }

          .p-toggleable-content {
            .p-accordion-content {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "medium");
              line-height: 1.67;
              color: #424242;
              @include padding(left, top, right, bottom, 16, 16, 16, 16);
              @include border(0px, solid, transparent);
              @include background-color(transparent);
            }
          }
        }
      }

      .cls-bottom-box {
        @include padding(left, top, right, bottom, 16, 8, 16, 8);
        @include border-pos(bottom, 0px, solid, transparent);
        @include border-pos(right, 0px, solid, transparent);
        @include border-pos(left, 0px, solid, transparent);
        @include border-pos(top, 1px, solid, #e8e8e8);
        @include background-color(#fafafa);
        border-radius: 0px 0px 4px 4px;

        .clasby-text {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "medium");
          line-height: 1.25;
          color: $utl-text-color-12;
          @include padding(left, top, right, bottom, 8, 0, 0, 0);
        }

        .cls-eventtext {
          @extend .clasby-text;
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          color: #424242;
        }

        .cls-status {
          @extend .clasby-text;
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
        }

        .clsdate-text {
          @extend .clasby-text;
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          color: rgba(82, 90, 110, 0.8);
        }

        .cls-space {
          @include margin(left, top, right, bottom, 8, 0, 8, 0);

          img {
            width: 5px;
          }
        }
      }
    }
  }
}

app-show-more-content.assettxt .subtitle {
  padding: 0 30px;
}


.font-sm {
  .Blank-stat-text {
    font-size: 22px !important;
    min-height: 274px;
    align-items: center !important;
    display: flex;
  }

  img {
    display: none;
  }
}

// mat-submenu css
.cls-menubuttonbox {
  @include background-color(transparent);
  @include border(0px, solid, transparent);
  @include margin(left, top, right, bottom, 32, 32, 0, 32);
  @include padding(left, top, right, bottom, 0, 0, 0, 0);

  .header-title {
    font-family: $font-family;
    font-size: $heading-extra-small-font-size;
    font-weight: map-get($font-weights, "semi-bold");
    line-height: 1.25;
    color: #3b3f50;
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    img {
      width: 40px;
      @include margin(left, top, right, bottom, 0, 0, 8, 0);
    }
  }
}


// candidate page  css
.candidate-detail-wrapper {
  .candidate-filter-wrapper {
    .cadidate-canditure {
      height: 100%;

      .mat-mdc-tab-group {
        height: 100%;

        .mat-mdc-tab-header {
          .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
              .mat-mdc-tab-labels {
                padding: 0 22px;

                .mdc-tab {
                  flex-grow: 1;
                }
              }
            }
          }
        }

        .mat-tab-body-wrapper {
          height: 100%;
        }
      }
    }
  }
}

// hrone invite flyer css
.cls-radiohrone {
  .mat-mdc-radio-button {

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label-content {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "medium");
        line-height: 1.43;
        color: $utl-text-color-12;
      }
    }
  }
}

.clsinner-html.candidate-eng {
  br {
    display: none;
  }
}

// create goal show more content
.cls-kpishow {
  .subtitle {
    div {
      color: $utl-text-color-12;
      font-weight: map-get($font-weights, "semi-bold");
    }
  }
}

.organization-wise {
  .form-wrap {
    .mat-mdc-form-field-error {
      display: inline-flex;
      height: auto;

      .error {
        color: #f44336;
        font-size: 9px;
        min-height: 16px;
        line-height: 20px;
        font-weight: 400;
      }
    }

    .error {
      color: #f44336;
      font-size: 9px;
      min-height: 16px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .cls-exp-calaender span.p-calendar {
    margin-top: 0px;

    .p-datepicker {
      min-width: 60%;
      @include padding(left, top, right, bottom, 10, 15, 10, 15);

      // top: auto !important;
      table {
        @include margin(left, top, right, bottom, 0, 0, 0, 8);

        td {
          @include padding(left, top, right, bottom, 2, 2, 2, 2);
        }
      }

      .p-datepicker-header {
        @include padding(left, top, right, bottom, 2, 2, 2, 2);

        .p-datepicker-next,
        .p-datepicker-prev {

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

//salary process dropdown css
.cls-salarydropdown {
  width: 40%;

  .mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          border-top: 0 solid transparent;

          .mat-mdc-select {

            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
            .mat-select-trigger {

              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
              .mat-select-value {

                /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
                .mat-select-value-text {
                  color: #a8a8a8;
                  font-size: 9px;
                  font-weight: 400;
                }
              }
            }
          }

          /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
          .mat-form-field-label-wrapper {
            top: 0;
            padding-top: 0;
          }
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        height: 0px;

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-ripple {
          @include background-color(transparent !important);
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-subscript-wrapper {
        margin-top: 0;
        top: 0;
      }
    }
  }
}

.badges-search {
  .cls-exp-calaender span.p-calendar.p-calendar-w-btn .p-inputtext {
    color: $utl-text-color-11;
    width: calc(100% - 16px);
  }

  .p-autocomplete.p-autocomplete-multiple .p-autocomplete-input-token input {
    color: $utl-text-color-11;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    color: $utl-text-color-11;
  }

  .p-button.p-button-icon-only {
    width: auto;
    color: $utl-text-color-11 !important;
  }
}

.assignchk {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-mdc-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
    margin-right: 7px;
  }
}

//performance highchart css
#reviewChart,
#idealChart {
  .highcharts-container {
    .highcharts-root {
      .highcharts-credits {
        display: none;
      }
    }
  }
}

.hrhndbk {
  .btnclose {
    top: 15px !important;
  }
}

app-request-header-detail.invhead .w-17 {
  width: auto !important;
}

.innercard {



  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-tab-labels {
    display: flex;
    justify-content: center;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-tab-label {
    opacity: 1 !important;
    height: 40px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-tab-labels {
    display: flex;
    justify-content: space-around;
    min-width: 115px;
    font-size: $text-medium-size;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mdc-tab--active .mdc-tab__text-label {
    color: #00bfa5 !important;
  }

  .mdc-tab-indicator__content--underline {
    border-color: #00bfa5 !important;
  }

  .mat-mdc-tab-group.mat-primary .mat-ink-bar,
  .mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: #00bfa5 !important;
  }
}


.main_card mat-tab-group.mat-mdc-tab-group.importsscrn.mat-primary {


  .mat-mdc-tab-label-container {
    padding: 5px 21px 0px;
  }

  .mat-mdc-tab-header {
    width: fit-content;
  }
}


.innercard .mat-mdc-tab-label-container {
  padding: 0 !important;
}

.bigfont {
  .mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-select-value-text {
      color: $utl-text-color-11;
      font-size: $heading-small-font-size !important;
      font-weight: map-get($font-weights, "semi-bold");
    }

    .mdc-line-ripple:before,
    .mdc-line-ripple:after {
      border-bottom-color: transparent !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
    .mat-mdc-select-arrow {
      color: $utl-theme-color;
    }
  }
}

.cls-fltdropdown.project.complete {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field .mat-form-field-wrapper .mat-form-field-flex {
    min-width: 175px;
    max-width: 175px;
  }
}

.cls-fltdropdown.project {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field .mat-form-field-wrapper .mat-form-field-flex {
    border-radius: 4px;
    border: solid 1px #e8e8e8;
    background-color: $utl-grey-color-28;
    max-width: 139px;
    width: 100%;
    min-width: 139px;
    @include padding(left, top, right, bottom, 15, 3, 5, 10);
    height: 32px;
    text-transform: uppercase;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-type-mat-select .mat-form-field-label {
    font-weight: 600 !important;
    color: #2d3547 !important;
    font-size: 12px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
    @include padding(left, top, right, bottom, 0, 2, 0, 0);
  }
}

// radio box css
.cls-radiobox {
  @include margin(left, top, right, bottom, 0, 16, 0, 0);

  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      display: block;
    }
  }
}

.p-order {
  .p-orderlist .p-orderlist-list .p-orderlist-item:not(.p-highlight):hover {
    background: transparent;
    color: #333333;
  }

  .p-orderlist-controls {
    display: none;
  }

  .p-orderlist .p-orderlist-list {
    border: 0;
    max-width: 100%;
    max-height: initial;
    overflow: hidden;

    .p-orderlist-item {
      @include margin(left, top, right, bottom, 3, 0, 0, 0);
      @include padding(left, top, right, bottom, 10, 9, 10, 9);
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "regular");
      line-height: 1.43;
      color: $utl-text-color-12;

      i {
        @include padding(left, top, right, bottom, 4, 4, 4, 4);
        @include margin(left, top, right, bottom, 0, 0, 12, 0);
        @include border-radius(2px);
        @include border(1px, solid, #e8e8e8);
        @include background-color(#fbfbfb);
      }

      &:hover {
        @include border-radius(1px);
        @include shadow($level: 25);
        @include background-color;
        cursor: move;
      }

      .p-highlight {
        color: #ffffff;
        background: transparent;
      }
    }
  }
}

.reviewbox.workforce .mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  background: $utl-grey-color-9 !important;
}

.reviewbox.workforce .mat-accordion .mat-expansion-panel {
  margin-bottom: 20px !important;
  border-bottom: 0px !important;

  .mat-expansion-panel-header {
    mat-expanded {
      height: 50px !important;
    }
  }
}

.reviewbox.workforce .mat-accordion .mat-expansion-panel .mat-expansion-panel-content .mat-expansion-panel-body {
  @include padding(left, top, right, bottom, 16, 16, 16, 16);
  border-bottom: 1px solid #cbcbcb;
}

.border.boarding {

  td,
  th {
    font-size: $text-semimedium-size !important;
  }
}

.shwinrw {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px #ddd;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0.9;
  }
}

.cls-selected .uom {
  .mat-mdc-option {
    font-weight: 700 !important;
  }
}

.candidate-view-details {
  .card-view {
    .mdc-evolution-chip-set {
      .mdc-evolution-chip-set__chips {
        .chipstyle {
          @include background-color($utl-text-color-16);
          @include border-radius(2px);
          @include border(1px, solid, $utl-text-color-16);
          display: flex;
          align-items: center;
          @include padding(left, top, right, bottom, 6, 3, 6, 3);
          margin-top: 0;

          min-height: 19px;

          .mat-mdc-standard-chip {
            .mdc-evolution-chip__action {
              .mat-mdc-chip-action-label {
                @include border-radius(2px);
                @include padding(left, top, right, bottom, 0, 1, 0, 1);
                height: 26px;
                width: 27px;
                line-height: 31px;
                font-size: 0.857em;
                font-weight: 500;
                color: #424242;
              }
            }
          }
        }

        .cls-blackbox {
          position: absolute;
          left: 0;
          top: 0;

          .blacklist-chip {
            @include background-color($utl-red-30);
            @include border-radius(3px);
            @include border(0px, solid, transparent);
            display: inline-flex;
            align-items: center;
            @include padding(left, top, right, bottom, 6, 3, 6, 3);
            font-size: $text-small-size;
            font-weight: 500;
            color: $utl-white;
            min-height: 20px;
          }
        }
      }
    }


  }
}

// .chipstyle {
//   @include background-color($utl-text-color-16);
//   @include border-radius(2px !important);
//   @include border(1px, solid, $utl-text-color-16);
//   display: flex;
//   align-items: center;
//   @include padding(left, top, right, bottom, 6, 3, 6, 2);
//   font-size: 0.857em;
//   font-weight: 500;
//   color: #424242;
//   min-height: 19px !important;
// }
.cls-selected .uom {
  .mat-mdc-option {
    font-weight: 700 !important;
  }
}

.cls-box {
  position: relative;

  .blacklist-chip {
    @include background-color($utl-red-30 !important);
    @include border-radius(3px);
    @include border(0px, solid, transparent);
    display: inline-flex;
    align-items: center;
    @include padding(left, top, right, bottom, 6, 3, 6, 3);
    font-size: $text-small-size;
    font-weight: 500;
    color: $utl-white !important;
    min-height: 20px;
  }

  .remove-blacklist {
    @include background-color(rgba(39, 44, 62, 0.06));
    @include border-radius(3px);
    @include border(0px, solid, transparent);
    display: inline-flex;
    align-items: center;
    @include padding(left, top, right, bottom, 6, 3, 6, 3);
    font-size: $text-small-size;
    font-weight: 500;
    color: $utl-text-color-12;
    min-height: 20px;
    cursor: pointer;
  }
}

// recent actions/imports search 
.customdropdwn {
  @include padding(left, top, right, bottom, 8, 8, 8, 8);
  height: 36px;
  @include background-color();
  border: 1px solid #ECEFF2;

  .mat-select-arrow {
    border-color: $utl-text-color-1 !important;
  }

  .mat-form-field-label-wrapper {
    top: -21px;
    left: -8px;
  }

  .mat-select-value-text {
    @include color($utl-text-color-12);
    font-weight: map-get($font-weights, "normal");
    font-size: $tabs-text;
    line-height: 17px;
  }

  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0;
  }
}

.actionflyer {
  @include border-radius;
  @include border(1px, solid, #7E7E7E);
  @include background-color;
  display: flex;
  align-items: center;
  @include padding(left, top, right, bottom, 3, 1, 3, 1);

  img {
    @include margin(left, top, right, bottom, 3, 0, 3, 0);
    width: 24px;
  }

  input {
    @include color($utl-text-color-12 !important);
    letter-spacing: 0.04em;
    font-weight: map-get($font-weights, "semi-bold");
    font-size: $text-name;
    line-height: 15px;
    opacity: 0.4;
    border: 0;
  }
}

// recent actions/imports search 
.customdropdwn {
  @include padding(left, top, right, bottom, 8, 8, 8, 8);
  height: 36px;
  @include background-color();
  border: 1px solid #ECEFF2;

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    border-color: $utl-text-color-1 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-value-text {
    @include color($utl-text-color-12);
    font-weight: map-get($font-weights, "normal");
    font-size: $tabs-text;
    line-height: 17px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-ripple {
    height: 0;
  }
}

.actionflyer {
  @include border-radius;
  @include border(1px, solid, #7E7E7E);
  @include background-color;
  display: flex;
  align-items: center;
  @include padding(left, top, right, bottom, 3, 1, 3, 1);

  img {
    @include margin(left, top, right, bottom, 3, 0, 3, 0);
    width: 24px;
  }

  input {
    @include color($utl-text-color-12 !important);
    letter-spacing: 0.04em;
    font-weight: map-get($font-weights, "semi-bold");
    font-size: $text-name;
    line-height: 15px;
    opacity: 0.4;
    border: 0;
  }
}

// custom search box
.cls-customSearch {
  @include border-radius;
  @include border(1.5px, solid, #e5e5e5);
  @include background-color;
  display: flex;
  align-items: center;
  @include padding(left, top, right, bottom, 3, 0, 3, 0);

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 20px;
  }

  .p-float-label {
    height: 38px;
  }

  img {
    @include margin(left, top, right, bottom, 5, 0, 5, 0);
    width: 20px;
  }

  input {
    @include border(0px, solid, transparent);
    width: 100%;
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    color: rgba(27, 36, 54, 0.5);
    height: 28px;
  }
}

// custom module search box
.cls-moduleSearch {

  // @include border-pos(bottom, 1px, solid, #9098A8);
  display: flex;
  align-items: center;
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  @include margin(left, top, right, bottom, 8, 16, 8, 0);

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 20px;
  }

  .p-float-label {
    height: 38px;
  }

  img {
    @include margin(left, top, right, bottom, 5, 0, 0, 0);
    width: 16px;
  }

  input {
    @include border(0px, solid, transparent);
    @include background-color(transparent);
    width: 100%;
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    color: rgba(255, 255, 255, .5);
    line-height: 20px;
    height: 24px;
  }
}

// add punch  calender css
.cls-punch-calaender {
  .p-calendar {
    .p-inputtext {
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(bottom, 1px, solid, #e9edf2);
      @include border-radius(0px);

      &:enabled:focus {
        box-shadow: none;
      }
    }

    .p-button {
      @include background-color(transparent);
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(bottom, 1px, solid, #e9edf2);

      .p-button-icon {
        color: #1b2436;
      }
    }

    .p-datepicker {
      max-width: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .p-datepicker-header {
        padding: 0.2em 0em 0.2em 0.2em;
      }

      .p-datepicker-group-container {
        .p-datepicker-group {
          .p-datepicker-calendar-container {
            table {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);

              thead {
                tr {
                  th {
                    padding: 0.2em 0em 0.2em 0.2em;

                    span {
                      width: 1rem;
                      height: 1rem;
                      font-size: $text-size-11;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    padding: 0.2em 0em 0.2em 0.2em;

                    span {
                      width: 1rem;
                      height: 1rem;
                      font-size: $text-size-11;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cls-punch-time {
  .p-calendar {
    .p-inputtext {
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(bottom, 1px, solid, #dddddd);
      @include border-radius(0px);

      &:enabled:focus {
        box-shadow: none;
      }
    }

    .p-datepicker {
      min-width: 75%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .p-timepicker {
        .p-hour-picker {
          .p-link {
            width: 1rem;
            height: 1rem;
          }
        }

        span {
          font-size: 1em;
        }
      }

      .p-minute-picker {
        .p-link {
          width: 1rem;
          height: 1rem;
        }

        span {
          font-size: 1em;
        }
      }
    }
  }
}

.mat-mdc-chip.chipstyle {
  color: #424242 !important;
}

.mat-mdc-chip.chipstyle.mat-mdc-standard-chip::after {
  background: transparent !important;
  font-size: 0.857em;
}

.icnset {
  top: 12px !important;
}

// mat select
.cls-subdate {
  display: flex;
  flex-direction: column;

  .cls-selecttitle {
    font-family: $font-family;
    font-size: $text-small-size;
    font-weight: map-get($font-weights, regular);
    line-height: normal;
    letter-spacing: normal;
    color: rgba(40, 44, 63, 0.5);
  }

  .cls-nobrdselect {
    @include border(0px, solid, transparent);
    @include background-color(transparent);

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        border-top: 1px solid transparent;
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      height: 0px;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-ripple {
        @include background-color(transparent !important);
      }
    }

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    &.mat-focused .mat-form-field-ripple {
      @include background-color(transparent !important);
    }
  }
}

.cls-travelsubtxt {
  .subtitle {
    font-size: $text-default-size !important;
  }
}

.hrone-dialog.snap .p-dialog {
  right: 6%;
}

//dynamic form radio css
.cls-radiogrps {
  .mat-mdc-radio-button {
    width: 100%;

    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
    .mat-radio-label {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;
      display: inline-flex;
      align-items: start;
      white-space: normal;
      vertical-align: middle;
      width: 100%;
    }
  }
}

// chat css
.userTag {
  font-size: inherit;
  color: rgba(0, 90, 158, 1) !important;
  text-decoration: underline !important;
}

.classselected .mat-mdc-input-element {
  color: $utl-primary-red;
}

.projectmang {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-mdc-tab-labels .mat-mdc-focus-indicator {
    opacity: 1 !important;
    font-weight: map-get($font-weights, bold !important);
    min-width: 126px !important;
    color: $utl-grey-color-6;
  }
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  background: transparent;
}

.projectfilter {

  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/


  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  .mat-mdc-chip-set {
    .mdc-evolution-chip-set__chips {
      @include margin(left, top, right, bottom, 10, 0, 0, 0 !important);

      .mat-mdc-chip {
        mat-icon {
          img {
            width: 17px;
            opacity: 0.45;
          }
        }

      }
    }
  }
}


.newform-page {

  .adv,
  .avail {
    @include padding(left, top, right, bottom, 0, 10, 0, 0);

    .applicability-list .cls-radio1 {
      margin-bottom: 15px;
    }
  }

  .visibility {
    @include padding(left, top, right, bottom, 0, 15, 0, 0);

    .applicability-list .cls-radio1 {
      margin-bottom: 21px;
    }
  }
}

.radiobtns-budget {
  .mat-mdc-radio-group {
    display: flex;
  }
}

// time picker css
.cls-matmenu-box {
  min-width: 50px !important;
  max-width: 140px !important;
  overflow: visible !important;
  height: 100%;
  max-height: 100% !important;
  border-radius: 4px;
  outline: 0;
}

// help desk css
.cls-helpdesk {
  &.p-autocomplete {
    .p-autocomplete-multiple-container {
      border-bottom: 1px solid #c8c8c8 !important;
    }
  }
}

.shw-rside-custom {
  width: calc(100% - 56%) !important;
}

.checkbox-proj {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  mat-checkbox span {
    label {
      font-weight: map-get($font-weights, "medium");
      color: $utl-text-color-12;
      font-size: $text-default--normal;
      @include padding(left, top, right, bottom, 0, 8, 0, 0);
    }
  }
}

app-emp-header-two.head-onboarding {
  ._user-snippet {
    @include background-color(rgba(208, 208, 208, 0.1));
    @include border(1px, solid, rgba(151, 151, 151, 0.33));
    @include border-radius(6px);

    ._snippet-inner {
      padding: 12px !important;
    }

    hr {
      display: none;
    }
  }
}

// deleted css
.clsdelete-btn {
  width: 24px;
  height: 24px;
  @include border-radius(2px);
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  color: #000;
  cursor: pointer;

  i {
    font-size: 17px;
  }
}

.projectcomments {

  .delete-post button span {
    color: $utl-grey-color-6 !important;
  }
}

.announcement {
  perfect-scrollbar {
    .ps__thumb-x {
      display: none !important;
    }
  }
}

app-show-more-content.showmore-text.text-wrap-perform div {
  white-space: break-spaces;
}

.text-wrap-compt {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-label {
    white-space: break-spaces !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    align-items: flex-start !important;
  }
}

.settlementcard .m-ln-15.m-rn-15 {
  margin: 0 !important;

  .col-4 {
    flex: 0 0 45%;
    max-width: 45%;
  }
}

.cls-outerbox,
.comment {
  ::-webkit-input-placeholder {
    color: $utl-grey-color-6 !important;
    font-weight: (map-get($font-weights, medium));
    font-size: $text-semimedium-size;
  }
}





.checkbox-proj-new {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-mdc-checkbox .mat-checkbox-frame {
    border-color: #9e9e9e;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-inner-container {
    width: 20px;
    height: 20px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-checked {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-frame {
      border-color: $utl-theme-color;
      background-color: transparent;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checkmark-path {
      stroke: $utl-theme-color !important;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-checked.mat-accent .mat-checkbox-background,
    .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
      background-color: transparent !important;
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: transparent !important;
  }
}

app-policy.policy-review {
  .uploadbtn {
    background: #f5f7fa !important;
    border-color: #ccc !important;
    color: #c8c8c8 !important;
    box-shadow: none !important;
    pointer-events: none;

    i {
      color: #c8c8c8 !important;
    }
  }

  .text-success {
    color: #c8c8c8 !important;
    pointer-events: none;
  }
}



.stylemenu {
  position: relative;
  box-shadow: 0 3px 13px 0 rgb(148 148 148 / 50%);
  border: solid 1px $utl-text-color-16;
  background-color: #ffffff !important;
  width: 169px;

  .mat-mdc-menu-item {
    height: auto !important;
    line-height: 32px !important;
    font-size: $text-semimedium-size !important;
    color: $utl-text-color-7 !important;
    font-weight: 600 !important;
    border-radius: 3px;


    &:hover {
      // color: $utl-theme-color !important;
      background-color: rgba(196, 196, 196, 0.2);
    }
  }

  h3 {
    color: rgba(45, 53, 71, 0.4) !important;
    font-size: $text-medium-size !important;
  }
}

.apps-card {
  .avatar-content {
    font-weight: 700 !important;
    font-size: $text-default-size !important;
  }
}

.projectname {
  margin-top: 30px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.checkboxtext .mat-checkbox-layout .mat-checkbox-label {
  white-space: normal;
}



// blacklist chip css

// .blacklist-chip {
//   @include background-color($utl-secondary-red);
//   @include border-radius(2px);
//   @include border(1px, solid, $utl-text-color-16);
//   display: flex;
//   align-items: center;
//   @include padding(left, top, right, bottom, 6, 3, 6, 2);
//   font-size: 0.857em;
//   font-weight: 500;
//   color: #424242;
//   min-height: 19px !important;
// }

// css for bug 47801

.cls-helpdesk {
  .p-autocomplete-panel {
    .addItem {
      font-family: $font-family;
      color: #12c4ac;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "semi-bold");
      @include background-color($utl-grey-color-5);
      cursor: pointer;
    }
  }
}

.comments-div.project {
  border: solid 1px rgba(39, 44, 62, 0.2) !important;
  padding-bottom: 0;

}

.comments-div {
  @include border-radius(4px);
  @include border(1px, solid, rgba(0, 0, 0, 0.12));
  @include padding(left, top, right, bottom, 11, 7, 11, 0);

  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0 !important;

          textarea {
            resize: none !important;
            font-size: 12px !important;
            font-weight: 500 !important;
            height: 60px;
          }

          .commt {
            font-size: 12px !important;
            font-weight: 500 !important;
          }
        }
      }

      .mdc-line-ripple {
        &::after {
          border-bottom-color: transparent;
        }

        &::before {
          border-bottom-color: transparent;
          border-width: 0px;
        }
      }
    }
  }





}



// .notesdes {
//   color: rgba(40, 44, 61, 0.5) !important;
// }
.bg-notes {
  @include background-color($utl-grey-color-31);
  @include padding(left, top, right, bottom, 30, 10, 30, 30);
  @include border-radius(6px);
  @include margin(left, top, right, bottom, 0, 22, 0, 5);
  position: relative;

  .notes {
    @include border-radius(2px);
    @include border(1px, dashed, rgba(39, 44, 62, 0.3));
    @include background-color($utl-white);
    @include padding(left, top, right, bottom, 11, 7, 11, 16);

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline,
    .mat-form-field-ripple {
      height: 0px !important;
    }

    textarea {
      resize: none !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      color: $utl-text-color-12 !important;
    }
  }
}

.onduty-acc {
  @extend .team-proj;

  .mat-expansion-panel {
    .mat-expansion-panel-header {
      height: 40px !important;

      .mat-content {
        justify-content: space-around;

        .mat-expansion-panel-header-description {
          flex-grow: 0;
        }
      }
    }
  }
}

.guarantor-detail {
  @extend .team-proj;

  .mat-expansion-panel-body {
    padding-left: 20px !important;
    padding-top: 0px !important;
  }

  .mat-content {
    overflow: visible;
  }
}

.team-proj {
  &.p-left-manage {
    .mat-expansion-panel {
      border-bottom: solid 1px $utl-grey-color-18;

      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 0, 25, 0, 25);
      }
    }
  }

  .mat-expansion-panel {
    border: #d0d0d0 1px solid;
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    border-bottom: solid 1px #d0d0d0;
    background: $teampabelbg;

    .mat-expansion-panel-header {
      border-radius: 4px;
      background: $teampabelbg;
      height: 54px;
      @include padding(left, top, right, bottom, 15, 0, 15, 0);
      font-family: $font-family;

      .mat-content {
        align-items: center;
      }
    }

    .mat-expanded {
      border-bottom: 1px solid $utl-grey-color-18;
      border-radius: 0;
      background: #f1f0f0 !important;

      .mat-expansion-indicator::after {
        color: rgb(39 44 62 / 80%) !important;
      }
    }

    h3 {
      font-size: $heading-extra-small-font-size;
      font-weight: 600;
    }
  }

  .mat-expansion-indicator::after {
    color: $utl-grey-color-18;
  }

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    display: flex;
    flex-grow: initial;
    margin-right: 5px;
    flex-basis: auto;
  }

  .mat-expansion-panel:not([class*="mat-elevation-z"]) {
    border-bottom: 0;

    &:last-child {
      border-bottom: solid 1px $utl-grey-color-18;
    }
  }

  .mat-expansion-panel-content {
    background-color: $utl-white;
  }

  .mat-expansion-panel-body {
    @include padding(left, top, right, bottom, 60, 18, 18, 20);
  }
}

.banklist {
  .mat-expansion-panel {
    @include border-radius(0px);

    .mat-expansion-indicator::after {
      color: rgba(39, 44, 62, 0.8) !important;
    }
  }

  .bank-detail {
    @extend .team-proj;

    .mat-expansion-panel {
      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 15, 20, 15, 20);
      }
    }
  }
}

.customslide {

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/


  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-thumb-container {
    top: -5px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 14px !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background-color: rgba(255, 255, 255, 0.54) !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle .mat-slide-toggle-ripple {
    display: none;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mdc-form-field>label {
    font-family: $font-family !important;
    font-size: $text-medium-size;
    font-weight: (map-get($font-weights, bold));
    color: rgba(40, 46, 61, 0.8);
    margin-bottom: 0;
  }
}

.settlementcard .col-4 {
  max-width: 40%;
  flex: 40% !important;
}




// pagination page css
.cls-payment-pagination {
  .p-paginator {
    @include border-pos(bottom, 0, solid, transparent);
    @include border-pos(left, 0, solid, transparent);
    @include border-pos(right, 0, solid, transparent);
    @include border-pos(top, 1px, solid, rgba(39, 44, 62, 0.2));
    @include border-radius(0px);
    @include background-color(#f7f7f7);
    color: rgba(39, 44, 62, 0.8);

    .p-paginator-first {
      @include border-radius(0px);
      height: 31px;
      min-width: 1.357rem;
      font-weight: map-get($font-weights, "bold");
      color: rgba(39, 44, 62, 0.8);

      &:not(.p-disabled):not(.p-highlight):hover {
        @include background-color(transparent);
      }

      &:focus {
        box-shadow: none;
      }
    }

    .p-paginator-prev {
      @extend .p-paginator-first;
    }

    .p-paginator-next {
      @extend .p-paginator-first;
    }

    .p-paginator-last {
      @extend .p-paginator-first;
    }

    .p-paginator-pages {
      .p-paginator-page {
        @extend .p-paginator-first;
        font-size: $text-small-size;
        font-weight: map-get($font-weights, "bold");

        &.p-highlight {
          @include background-color(transparent);
          @include border(0px, solid, transparent);
          color: #426eaf;
        }

        &:not(.p-highlight):hover {
          @include background-color(transparent);
          color: rgba(39, 44, 62, 0.8);
          font-weight: map-get($font-weights, "bold");
        }
      }
    }
  }
}


.innersection {
  .contents {
    @include padding(left, top, right, bottom, 31, 16, 31, 16);
  }

  span.newtag {
    background-color: $ripple-color;
    @include border-radius(20px);
    color: $utl-white;
    font-size: $text-medium-size;
    @include padding(left, top, right, bottom, 10, 5, 10, 5);
  }

  h2 {
    font-size: $heading-extra-small-font-size;
    color: $utl-text-color-12;
    line-height: 21px;
  }

  .clear {
    span {
      color: rgba(68, 74, 88, 0.5);
      font-size: $heading-large-font-size;
    }
  }

  p {
    color: $utl-text-color-12 !important;
    font-size: $text-medium-size !important;
  }

  .btn-success {
    @include padding(left, top, right, bottom, 12, 12, 13, 12);
  }
}

.myapps-hightlight {
  .p-dialog-content {
    padding: 10px !important;
  }
}

.myapps-hightlight.p-dialog-mask.p-component-overlay.p-dialog-mask-scrollblocker.p-dialog-left {
  z-index: 1 !important;
}

// kyc input css

.cls-backarrow {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      flex-direction: row-reverse;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-suffix {
        @include padding(left, top, right, bottom, 0, 0, 8, 0);

        .mat-icon {
          width: 24px;
        }
      }
    }
  }
}

// upcoming join icon fix css

.cls-iconfix {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {

      .mat-mdc-form-field-infix {
        display: flex;
        align-items: center;

        .mat-icon {
          background-repeat: no-repeat;
          display: inline-block;
          fill: currentColor;
          height: 15px;
          width: 21px;
          font-size: 15px;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }
    }
  }
}

.cls-check {
  color: #4a9e86;
}

.cls-error {
  color: #b44f3b;
}

.cls-na {
  color: #c73232;
}

// cls verify input css

.cls-verifyfeild {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    margin: 0;

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        padding: 7px 0 14px;
      }
    }
  }
}

.task-details .p-dialog .p-dialog-content .btnclose {
  top: 10px;
  right: 29px;
  height: 36px;
  width: 36px;
  text-align: center;
  border-radius: 50%;
  padding-top: 6px;

  i {
    font-size: 24px;
  }

  &:hover {
    border-color: #f1f1f1;
    background: #f1f1f1;
    color: #212121;
  }
}

.projemenucls {
  .mat-mdc-menu-item-submenu-trigger::after {
    top: 36%;
    right: 16px;
    border: solid $utl-text-color-12;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}

// css for job discription
.cls-discription {
  ul {
    list-style: initial;

    li {
      margin-bottom: 10px;
    }
  }

  .ql-editor p {
    margin-bottom: 1rem;
  }
}

//splitbtn css

.p-splitbutton.p-button-outlined>.p-button {
  @include background-color;
  color: $utl-text-color-12;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
  font-weight: map-get($font-weights, "semi-bold");
}

.p-button.p-button-outlined:enabled:hover {
  @include background-color;
  color: #00bfa5;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
  font-weight: map-get($font-weights, "semi-bold");
}

.p-button.p-button-outlined:enabled:active {
  @include background-color;
  color: #00bfa5;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
  font-weight: map-get($font-weights, "semi-bold");
}

.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success>.p-button.p-button-outlined,
.p-splitbutton.p-button-success>.p-button.p-button-outlined {
  @include background-color(#fbfbfb);
  color: #00bfa5;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
}

.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success>.p-button:enabled:hover,
.p-splitbutton.p-button-success>.p-button:enabled:hover {
  @include background-color(#fbfbfb);
  color: #00bfa5;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
}

.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success>.p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success>.p-button.p-button-outlined:enabled:active {
  @include background-color(#fbfbfb);
  color: #00bfa5;
  border: 0.5px solid rgba(39, 44, 62, 0.4);
}

.p-splitbutton.p-button-success>.p-button:enabled:focus {
  box-shadow: none;
}

.tasklist {
  .mat-mdc-table {
    thead {
      position: sticky;
      top: 0;
      z-index: 99999;
    }

    tr.mdc-data-table__header-row {
      height: 36px !important;
    }
  }
}

.mat-mdc-menu-panel.space_menu {
  min-height: 0 !important;
}

.searchbar_shift {
  position: fixed;
  top: 90px;
  right: 3%;

  .p-inputtext:enabled:focus {
    box-shadow: none;
    border: 0;
  }
}

.tab1 {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    height: calc(100vh - 199px);
  }
}

.inner-cards-tab {

  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    height: auto !important;
  }
}

mat-form-field.cls-input.comments-div.project.mat-mdc-form-field.ng-invalid {
  border-bottom: 1px solid #a8aab1;
}

.viewtask {

  .mdc-notched-outline__trailing,
  .mdc-notched-outline__leading {
    border-color: rgba(0, 0, 0, 0.12) !important;
  }

  .task_name {

    .mdc-notched-outline__trailing,
    .mdc-notched-outline__leading {
      border-color: rgba(0, 0, 0, 0) !important;
    }

    &:hover {

      .mdc-notched-outline__trailing,
      .mdc-notched-outline__leading {
        border-color: rgba(0, 0, 0, 0.12) !important;
      }
    }
  }

  .mdc-text-field--focused .mdc-notched-outline__leading,
  .mdc-text-field--focused .mdc-notched-outline__notch,
  .mdc-text-field--focused .mdc-notched-outline__trailing {
    border-width: 1px !important;
    border-color: rgb(39, 44, 62, 35%) !important;
  }

  .mat-mdc-form-field-error {
    span {
      font-size: 75%;
    }
  }

  .p-inputtext:enabled:hover {
    border-color: #fff !important;
  }

  .addname {
    flex-flow: row;
    transition: all 0.2s;
    border-radius: 4px !important;
    border: 1px solid transparent;
    @include padding(left, top, right, bottom, 2, 0, 0, 2);

    &:hover {
      // border-color: $utl-theme-color  !important;
      border: 1px solid;
      border-color: rgb(39 44 62 / 35%) !important;
      background: rgb(39 44 62 / 2%);
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0 0;
    padding-bottom: 0px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  label,
  .mat-mdc-input-element,
  .mat-select-value-text {
    font-size: $text-name !important;
    opacity: 1;
    line-height: normal !important;
    font-weight: map-get($font-weights, "medium") !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-mdc-input-element,
  .mat-select-value-text {
    opacity: 1 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0px 0 8px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-prefix .mat-icon,
  .mat-form-field-suffix .mat-icon {
    width: auto;
    height: auto !important;
    line-height: 0 !important;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: rgb(39 44 62 / 35%) !important;
    background: rgb(39 44 62 / 2%);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
  .mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
    border-width: 1px !important;
  }
}

.datepick {

  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-mdc-icon-button,
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
    width: auto !important;
    padding-right: 6px;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mat-mdc-button .mat-button-focus-overlay,
  .mat-mdc-icon-button .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}

.task_name {

  // .mat-form-field-appearance-outline .mat-form-field-wrapper {
  //    padding-bottom: 2px;
  // }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-mdc-form-field .mdc-text-field--no-label .mat-mdc-form-field-flex .mat-mdc-form-field-infix,
  .mat-mdc-floating-label .mdc-text-field--no-label .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding: 2px 6px 2px !important;
    min-height: auto;
  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-flex {
    padding: 0 0.75em 0 3px;
  }

  // .mat-form-field-flex {
  //   padding-left: 0.25rem !important;
  // }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    border-color: transparent;
    padding: 0 0 0 0px;

  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
    &:hover {
      border-color: rgba(39, 44, 62, 0.35) !important;
    }

  }

  .mat-mdc-input-element {
    // font-size: $heading-h2 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}


// textarea css for settlement

.cls-notetxt {
  @include padding(left, top, right, bottom, 16, 11, 16, 11);
  @include border-radius;
  @include border(1px, solid, rgba(39, 44, 62, 0.2));
  @include background-color;
}

.Confidentialmsg {
  .inner {
    @include border-radius(5px);
    border: solid 1px #eff0f3;
    background-color: #fbfbfd;
    @include padding(left, top, right, bottom, 11, 16, 11, 16);

    p {
      @extend h3;
      white-space: pre-line;
      word-break: break-word;
    }
  }

  h3 {
    color: $utl-text-color-12 !important;
    font-size: $tabs-text !important;
  }
}

// exit interview acc css

.cls-interviewacc {
  .mat-expansion-panel {
    @include border(1px, solid, rgba(151, 151, 151, 0.5));

    .mat-expansion-panel-header {
      height: auto;

      .mat-content {
        display: flex;
        flex: 1;
        // flex-direction: column;
        overflow: hidden;
        align-items: flex-start;
        flex-flow: column-reverse;
        @include padding(left, top, right, bottom, 0, 10, 0, 10);
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
      }
    }
  }
}

// PII popup css

.cls-poptxtbox {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border-pos(top, 0px, solid, transparent);
      }
    }
  }
}

// payroll setting css
.cls-payrollfield {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {
        display: flex;
      }
    }
  }
}

.milestone {
  .mat-expansion-panel {
    border:0;
    border-radius: 0 !important;
    .mat-expanded 
    {
      background-color: transparent !important;
    }
    .mat-expansion-panel-header
    {
      border-top:0;
      border-radius: 0;
      padding-left: 0;
    }
    .mat-expansion-panel-body {
      @include padding(left, top, right, bottom, 0, 18, 0, 20 !important);
    }
  }
  
}

// design for new and beta
.NEW {
  font-family: $font-family;
  font-size: $text-small-size !important;
  font-weight: map-get($font-weights, "medium");
  background-color: $newtabbg;
  height: 15px;
  line-height: 15px;
  @include border-radius(2px);
  border: solid 1px $rednew;
  color: $utl-white;
  text-transform: uppercase;
  @include padding(left, top, right, bottom, 3, 0, 3, 0);
}

.BETA {
  @extend .NEW;
}

// accordion chechin

.cls-empaccord {
  .mat-expansion-panel {
    .mat-expansion-panel-header {
      .mat-content {
        .mat-expansion-panel-header-title {
          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }
  }
}

// checklist css

._tasks-list {
  ul {
    li {
      .cls-done {
        .p-button-icon {
          @include background-color(#149579);
          @include border-radius(100%);
          color: $utl-white;
          height: 10px;
          width: 10px;
          font-size: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
        }
      }

      .cls-pending {
        margin-left: 10px;

        .p-button-icon {
          @include background-color(#00b0e6);
          @include border-radius(100%);
          color: $utl-white;
          height: 10px;
          width: 10px;
          font-size: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
          margin-top: 4px;
        }
      }
    }
  }
}

.greentoggle {

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgb(0 191 165 / 54%);
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: $utl-theme-color;
  }

  .mat-mdc-slide-toggle.mat-checked .mat-ripple-element {
    background-color: $utl-theme-color;
  }

  .mat-mdc-slide-toggle {
    display: flex;

    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-content {
      font-size: $text-name !important;
      font-weight: map-get($font-weights, "normal");
      font-family: $font-family ;
      color: $utl-text-color-12;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
    .mat-slide-toggle-label {
      flex-direction: row-reverse;
      justify-content: space-between;
      width: 100%;

    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.heightfix .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 6px !important;
}

.main_card .cls-customSearch:hover img {
  opacity: 1;
}

.cls-prog {
  &mat-progress-bar {
    @include border-radius($radius: 4px);
    height: 8px;

    /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
    .mat-progress-bar-fill {
      &:after {
        @include border-radius($radius: 40px);
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-buffer {
    @include background-color(rgb(161, 200, 234));
  }

  /* TODO(mdc-migration): The following rule targets internal classes of progress-bar that may no longer apply for the MDC version.*/
  .mat-progress-bar-fill {
    &:after {
      @include background-color($utl-theme-color);
    }
  }

}

.Manage-profilepic {

  .thumb-ext {
    img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      // z-index: 9;
      position: relative;
    }

    .upload-link {
      .p-fileupload {
        background-color: transparent !important;
      }
    }
  }
}

.fixed-head {
  thead {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 999 !important;
    background: $utl-white;

    .tasknamehead {
      z-index: 9999 !important;
    }
  }
}

// upload file css

.cls-linkbox {
  @include border-radius;
  @include border(1px, solid, $utl-text-color-12);
  @include background-color(rgba(0, 191, 165, 0));
  display: inline-flex;
  align-items: center;
  justify-content: center;


  .cls-txtlbl {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights , 'semi-bold');
    color: rgba(65, 65, 65, 0.8);
    @include padding(left, top, right, bottom, 12, 4, 11, 4);
    cursor: pointer;
  }

  .cls-linkbtn {
    @include padding(left, top, right, bottom, 7, 0, 7, 3);
    @include margin(left, top, right, bottom, 0, 0, 2, 0);
    @include border(0px, solid, transparent);
    @include background-color(rgba(229, 222, 222, 0.2));
    @include border-radius(3px);
    cursor: pointer;

    &:hover {
      @include background-color(rgba(65, 65, 65, 0.1));
    }
  }
}

.timesheet-table {

  .mat-mdc-select,
  input.mat-mdc-input-element {
    @include border-radius(4px);

    &:hover {
      background-color: $selecthovercolor;
    }
  }
}

.templateblank {
  .cls-imgsrc {
    margin-top: 5%;
  }
}

.custom-cal-timesheet .p-datepicker table td>span.p-highlight {
  color: $utl-white;
  background: $calhighlight;
}

.custom-cal-timesheet .p-datepicker-today>span.p-highlight {
  border: 1px solid #95420a;
  background: $calhighlight;
}

.custom-cal-timesheet {
  .p-datepicker-header {
    @include border-radius(4px);
    background-color: #f4f6f9 !important;

    .p-datepicker-title {
      font-size: $heading-small-font-size;
      font-weight: map-get($font-weights, "medium");
    }
  }

  .p-datepicker {
    border: 0;
    color: #989db2;
    max-width: 100%;

    td {
      padding: 4px 8px;


    }

    table td>span {
      width: 32px;
      height: 32px;
      @include border-radius(13px);
      font-size: $tabs-text;
      border: 1px solid transparent;
      color: #6f778b;
      font-weight: map-get($font-weights, "medium");

      .p-highlight {
        background: $calhighlight !important;
      }

    }
  }
}

// investment check-box css

.cls-checkinvst {
  .mat-mdc-checkbox {

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-layout {

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-inner-container {
        @include margin(left, top, right, bottom, 0, 0, 11, 0);
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-label {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights , 'medium');
        color: $utl-text-color-12;
      }
    }
  }

  .cls-box {
    display: flex;
    align-items: center;

    .mat-mdc-checkbox {

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mat-checkbox-layout {
        white-space: normal;

        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
        .mat-checkbox-label {
          white-space: normal;
        }
      }
    }
  }
}

// add candidate smart search css
.cls-candidatedropdown {
  width: 100%;

  .mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      @include padding(left, top, right, bottom, 10, 0, 10, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-flex {

        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
          @include padding(left, top, right, bottom, 0, 9, 0, 9);
          @include border-pos(top, 0px, solid, transparent);
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-value {
        span {
          font-weight: map-get($font-weights, "bold");
          color: $utl-theme-color;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-arrow {
          border-color: $utl-theme-color !important;
        }
      }

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

._review-wrapper2 {
  .bulk-grid .cls-border {
    margin: 0 30px !important;
  }

  .reset {
    margin-right: 30px;
  }

  .mat-mdc-card {
    border-top: 0;
    margin-top: -24px !important;

    border-radius: 0px 0px 4px 4px;

    .status {
      margin-top: 15px !important;
      box-shadow: none;
      @include border-radius(4px);

      .btn-h-27 {
        height: 27px;
        line-height: 12px;
      }

      h4 {
        font-size: $tabs-text;
      }
    }
  }
}

perfect-scrollbar.innrpage-timesheet .main-div {
  max-width: 97% !important;
  margin-top: 0px !important;
}

perfect-scrollbar.innrpage-timesheet {
  .timesheet-form-wrappper {
    background: #f4f6f9;
    @include padding(left, top, right, bottom, 24, 24, 24, 24);
  }

  .calender-container {
    left: 18%;
    top: 8%;
  }
}

.timesheetarchive {
  .addbg {
    background: #f4f6f9 !important;
  }
}

// .shadow-none.timesheet {
//   overflow-x: scroll;
//   max-height:calc(100vh - 209px);
// }

// payslip show more css

.cls-contshowmore {
  width: 75%;

  .subtitle {
    width: 100% !important;
  }
}

// tertiary button

.cls-tertiary {

  @include border-radius;
  @include border(1px, solid, rgb(39 44 62 / 40%));
  @include background-color(#fbfbfb);
  font-family: $font-family;
  font-weight: map-get($font-weights , 'semi-bold');
  line-height: 1.9;
  color: $utl-text-color-12;

  &:disabled {
    opacity: .2;
    cursor: not-allowed !important;
  }

}


.cls-analyticstbl {

  .p-datatable-scrollable-footer-box {
    margin-right: 8px !important;
    padding: 0 !important;
  }
}

// prime-ng primerytable css
.cls-primertbl {
  .p-datatable-scrollable-header-box {
    padding-right: 8px !important;
  }

  // @include border(1px, solid, #ddd);
  .p-datatable {
    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-view {
        .p-datatable-scrollable-header {
          .p-datatable-scrollable-header-box {
            .p-datatable-scrollable-header-table {
              .p-datatable-thead {
                tr {
                  th {
                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights, "bold");
                    line-height: 1.58;
                    color: rgba(39, 44, 62, 0.8);
                    @include background-color(#fcfcfc);
                    @include border(1px, solid, #ddd);
                    border-width: 1px 0 1px 0;

                    &:first-child {

                      // width: 70px !important;
                      @include border-pos(left, 1px, solid, #ddd);

                    }

                    &:last-child {
                      @include border-pos(right, 1px, solid, #ddd);
                    }
                  }

                }
              }
            }

          }
        }

        .p-datatable-scrollable-body {
          .p-datatable-tbody {
            tr {
              td {
                font-family: $font-family;
                font-size: $text-medium-size;
                font-weight: map-get($font-weights, "medium");
                line-height: 1.33;
                color: $utl-text-color-12;
                @include background-color;
                @include border(1px, solid, #ddd);
                border-width: 1px 0px 1px 0px;

                &:first-child {

                  // width: 70px !important;
                  @include border-pos(left, 1px, solid, #ddd);

                }

                &:last-child {
                  @include border-pos(right, 1px, solid, #ddd);
                }
              }

              &:first-child {
                td {
                  @include border-pos(top, 0px, solid, #ddd);
                }
              }
            }
          }

          &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
          }

          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 6px #ddd;
            border-radius: 6px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 6px;
            box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(0, 0, 0, 0.2);
            opacity: 0.9;
          }
        }

        .p-datatable-scrollable-footer {
          @include background-color(#f4f4f4);
          @include border-pos(bottom, 1px, solid, #f4f4f4);

          .p-datatable-scrollable-footer-box {
            .p-datatable-scrollable-footer-table {
              .p-datatable-tfoot {
                tr {
                  @include border(1px, solid, #ddd);
                  border-width: 1px 1px 1px 1px;

                  td {
                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights, "bold");
                    line-height: 1.33;
                    color: $utl-text-color-12;
                    @include background-color;
                    @include border(0px, solid, #ddd);

                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


//prime-ng p-table secondary table css
.cls-secondarytbl {

  // @include border(1px, solid, #ddd);
  .p-datatable {
    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-view {
        .p-datatable-scrollable-header {
          .p-datatable-scrollable-header-box {
            .p-datatable-scrollable-header-table {
              .p-datatable-thead {
                tr {
                  th {
                    font-family: $font-family;
                    font-size: $text-medium-size;
                    font-weight: map-get($font-weights, "regular");
                    line-height: 1.58;
                    color: $utl-text-color-12;
                    @include background-color;
                    @include border(1px, solid, #ddd);
                    border-width: 1px;

                    &:first-child {

                      width: 70px !important;

                    }
                  }

                }
              }
            }

          }
        }

        .p-datatable-scrollable-body {
          .p-datatable-tbody {
            tr {
              td {
                font-family: $font-family;
                font-size: $text-medium-size;
                font-weight: map-get($font-weights, "medium");
                line-height: 1.58;
                color: $utl-text-color-12;
                @include background-color;
                @include border(1px, solid, #ddd);
                border-width: 1px 0px 1px 0px;

                &:first-child {

                  width: 70px !important;
                  @include border-pos(left, 1px, solid, #ddd);

                }

                &:last-child {
                  @include border-pos(right, 1px, solid, #ddd);
                }
              }

              &:first-child {
                td {
                  @include border-pos(top, 0px, solid, #ddd);
                }
              }
            }
          }
        }
      }
    }
  }
}

//common primery table css
.cls-tbl-primery {
  width: 100%;

  thead {
    tr {
      th {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.58;
        color: rgba(39, 44, 62, 0.8);
        @include background-color(#fcfcfc);
        @include border(1px, solid, #ddd);
        border-width: 1px 0 1px 0;
        @include padding(left, top, right, bottom, 15, 7, 15, 7);

        &:first-child {

          @include border-pos(left, 1px, solid, #ddd);

        }

        &:last-child {
          @include border-pos(right, 1px, solid, #ddd);
        }
      }

    }
  }

  tbody {
    tr {
      td {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "medium");
        line-height: 1.33;
        color: $utl-text-color-12;
        @include background-color;
        @include border(1px, solid, #ddd);
        border-width: 1px 0px 1px 0px;
        @include padding(left, top, right, bottom, 15, 8, 15, 8);
        vertical-align: top;

        &:first-child {

          @include border-pos(left, 1px, solid, #ddd);

        }

        &:last-child {
          @include border-pos(right, 1px, solid, #ddd);
        }
      }

      &:first-child {
        td {
          @include border-pos(top, 0px, solid, #ddd);
        }
      }
    }
  }

  &.shift-rotation-table {
    tbody {
      td {
        @include padding(left, top, right, bottom, 12, 8, 12, 8);
      }
    }
  }
}


.innersec-project {
  table {
    border: 1px solid $utl-grey-color-18;
    table-layout: fixed;

    .blankstateheight {
      height: calc(100vh - 268px);
    }


    .Blank-stat {
      top: 10%;
    }

    th {
      padding-left: 7px;
    }

    .alllabel {
      padding-left: 7px;
    }
  }

  .fixed-head .headers {
    border-top: 0.1px solid $utl-grey-color-10;
  }
}


.menulisleft {
  span {

    a {
      background: transparent;
      @include margin(left, top, right, bottom, 0, 0, 0, 10);
      @include border-radius(4px);
      height: 42px;
      line-height: 25px;
      @include padding(left, top, right, bottom, 15, 8, 15, 8);
      color: $utl-text-color-12;
      font-size: $text-medium-size;
      font-weight: map-get($font-weights, "bold");
      min-width: 180px;
      justify-content: space-between;
      display: flex;
      border: 0;

      span {
        pointer-events: none;
      }

      &:hover {

        color: $utl-white;
        font-weight: 700;
        opacity: 1;
      }
    }
  }
}

.usersname:hover .mat-mdc-menu-item a {
  font-weight: 700 !important;
}



.usersname {
  @include padding(left, top, right, bottom, 26, 0, 0, 0);
  border: solid 1px transparent;
  @include border-radius(4px);
  font-family: $font-family;
  color: $utl-text-color-12;
  font-size: $tabs-text;
  font-weight: map-get($font-weights, "medium");

  img {
    // @include padding(left, top, right, bottom, 3, 3, 3, 3);
    // border: solid 1px $utl-text-color-12;
    @include border-radius(100%);
  }

  &:hover {

    border: solid 1px rgba(39, 44, 62, 0.03);
    background-color: rgba(39, 44, 62, 0.03);

    span {
      pointer-events: none;

    }
  }

}

.btnclosemenu {
  @include abs-pos($top: 4px, $right: 14px, $bottom: initial, $left: initial);
  @include border-radius(4px);
  z-index: 999999;
}


// analytic dashboard css


.counter-container {

  height: calc(100% - 15px);

  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version.*/
  .mat-mdc-card,
  .mdc-card,
  .mat-mdc-card-outlined,
  .mdc-card--outlined {

    @include border-radius;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include margin(left, top, right, bottom, 0, 0, 15, 15);
    // min-height: 254px;
    font-family: $font-family;
    height: calc(100% - 0px);

    .mat-mdc-card-header {
      @include padding(left, top, right, bottom, 0, 12, 0, 12);
      display: flex;
      align-items: center;
      justify-content: space-between;


      .mat-mdc-card-header-text {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        display: none;
      }

      .cls-heading {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights , 'bold');
        @include margin(left, top, right, bottom, 13, 0, 0, 0);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // width: 100%;
      }

      span {
        img {
          width: 17px;
          opacity: .8;
        }
      }

    }

    .mat-mdc-card-content {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      @include padding(left, top, right, bottom, 15, 0, 15, 0);
      height: calc(100% - 77px);
      position: relative;

      .cls-bodycnt {
        width: 100%;

        .cls-count {
          font-family: $font-family;
          font-size: 4vw;
          font-weight: map-get($font-weights , 'bold');
          line-height: 1;
          @include margin(left, top, right, bottom, 0, 24, 0, 0);
        }

        .cls-legend {
          @extend .cls-count;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights , 'medium');
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }



      .cls-contenttxt {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights , 'medium');
        color: $utl-text-color-12;
      }
    }
  }
}

// widget search css
.cls-wizsearch {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {
      align-items: end;

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-prefix {
        .mat-icon {
          font-size: 21px;
          color: rgba(39, 44, 62, 0.4);
        }
      }
    }
  }

}


.npm-feedback {

  .br-default .br-unit:hover:nth-child(3n) {
    &:after {
      background-image: url(/assets/images/star-hover3.svg) !important;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  .br-default .br-unit:hover:nth-child(4n) {
    &:after {
      background-image: url(/assets/images/star-hover4.svg) !important;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  .br-default .br-unit:hover:nth-child(5n) {
    &:after {
      background-image: url(/assets/images/star-hover5.svg) !important;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }

  }

  .br-default .br-unit:hover:nth-child(1n) {
    &:after {
      background: url(/assets/images/star-hover1.svg) no-repeat center;
      background-size: 100%;
    }

  }

  .br-default .br-unit:hover:nth-child(2n) {
    &:after {
      background: url(/assets/images/star-hover2.svg) no-repeat center;
      background-size: 100%;
    }

  }
}

.npm-feedback {
  .br-default .br-selected {
    &:after {
      // transform: scale(1.1);
      -webkit-transition: all 1s ease-in-out;
      transition: all 1s ease-in-out;
    }
  }

  .br-default .br-selected:nth-child(3n) {
    &:after {
      background: url(/assets/images/star-hover3.svg) !important;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .br-default .br-selected:nth-child(4n) {
    &:after {
      background-image: url(/assets/images/star-hover4.svg) !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }

  .br-default .br-selected:nth-child(5n) {
    &:after {
      background-image: url(/assets/images/star-hover5.svg) !important;
      background-size: 100% !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
    }
  }

  .br-default .br-selected:nth-child(1n) {

    &:after {
      background: url(/assets/images/star-hover1.svg) no-repeat center;
      background-size: 100%;
    }

  }

  .br-default .br-selected:nth-child(2n) {
    &:after {
      background: url(/assets/images/star-hover2.svg) no-repeat center;
      background-size: 100%;
    }

  }

}

bar-rating.greenstar {
  .br-default .br-selected:nth-child(1n):after {

    background-image: url(/assets/images/green1.svg) !important;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .br-default .br-selected:nth-child(2n):after {

    background-image: url(/assets/images/green2.svg) !important;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }

  .br-default .br-selected:nth-child(3n):after {

    background-image: url(/assets/images/star-hover3.svg) !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .br-default .br-selected:nth-child(4n):after {

    background-image: url(/assets/images/star-hover4.svg) !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }

  .br-default .br-selected:nth-child(5n):after {

    background-image: url(/assets/images/star-hover5.svg) !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
  }
}

.bg-selected {
  background: $utl-grey-color-5;
  border-bottom: 1px solid $subtaskbg !important;
  border-top: 1px solid $subtaskbg !important;

  .cls-boxdetail {
    font-style: italic;
  }

  .del {
    opacity: 1;
  }

  .inputtext {
    border-top: 1px solid $bordersub;
  }

}




// grey search bar center
// .button-search {
//   button {
//     border: 0;
//     @include border-radius(4px);
//     height: 32px;
//     font-weight: map-get($font-weights, "medium");
//     background-color: $utl-grey-color-17 !important;
//     color: rgba(39, 44, 62, 0.6);
//     font-size: $tabs-text;
//     border-left: 0;


//     figure {
//       max-height: 34px;

//       width: 34px;
//       min-height: 34px;

//     }


//   }
// }


// planned leave radio button css

.cls-leavepublic {
  .mat-mdc-radio-button {


    .mat-mdc-radio-label {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
    }
  }
}

// skill css

.cls_skilchip {
  @include margin(left, top, right, bottom, 0, 24, 0, 16);

  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  mat-chip-list {

    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    .mat-chip-list-wrapper {
      .mat-mdc-standard-chip {
        @include padding(left, top, right, bottom, 15, 3, 8, 3);
        @include margin(left, top, right, bottom, 0, 0, 12, 12);
        font-size: $text-medium-size;
        font-weight: map-get($font-weights , 'medium');
        line-height: 1.67;
        color: rgba(0, 0, 0, 0.65);
        @include border-radius(2px);
        @include border(1px, solid, #d9d9d9);
        @include background-color(#fafafa);
        position: relative;
      }

      .primary-class {
        position: relative;

        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        .mat-chip-ripple {
          @include border-pos(left, 10px, solid, transparent);
          @include border-pos(right, 10px, solid, transparent);
          @include border-pos(bottom, 10px, solid, #4599df);
          height: 0;
          width: 0;
          transform: rotate(-45deg);
          left: -6px;
          top: -1.5px;
        }
      }
    }
  }
}

app-blank-state-one.global-blank {
  img {
    width: 100% !important;
  }

  .main-container-box {
    width: 80%;
  }
}

// add candidate acc css

.cls-addcandidateacc {
  @include border(1px, solid, #d0d0d0);
  @include border-radius;
  display: block;

  .mat-expansion-panel {

    &:last-child {
      border-bottom: 0px solid transparent !important;
    }

    &.mat-expansion-panel-spacing {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
    }

    .mat-expansion-panel-header {
      @include background-color(#fcfcfc);
      @include border-pos(bottom, 1px, solid, #d0d0d0);
      @include border-radius(0px !important);
      @include padding(left, top, right, bottom, 16, 0, 16, 0);

      &.mat-expanded {
        height: 48px;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 16, 0, 16, 16);
      }
    }
  }
}


// analatyic dashboard radio button css

.cls-reportbox {
  .mat-mdc-radio-group {
    width: 100%;

    .mat-mdc-radio-button {
      width: 100%;

      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
      .mat-radio-label {
        white-space: initial;
        display: flex;
        align-items: initial;

        /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
        .mat-radio-label-content {
          padding-left: 14px;
        }
      }
    }
  }
}

textarea.nps-textarea.mat-mdc-input-element {
  @include margin(left, top, right, bottom, 0, 6, 0, 0);
}

// upload css

.clsupload-link {


  @include padding(left, top, right, bottom, 15, 7, 11, 6);
  @include margin(left, top, right, bottom, 0, 0, 7, 0);
  @include border-radius;
  // @include border(1px, solid, rgba(65, 65, 65, 0.8));
  // @include background-color(rgba(0, 191, 165, 0));
  max-width: 145px;
  display: flex;
  align-items: center;

  .cls-filebox {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: $font-family;
    font-size: $text-medium-size;
    font-weight: map-get($font-weights , 'semi-bold');
    text-align: center;
    // color: rgba(65, 65, 65, 0.8);
    width: 100%;
  }

  .material-icons {
    font-size: 16px;
    cursor: pointer;
    font-weight: map-get($font-weights , 'bold');
    margin-left: 5px;
  }

}

.cls-upload {
  @include padding(left, top, right, bottom, 8.3, 7, 10, 6);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  @include border-radius;
  @include border(1px, solid, #e8e8e8);
  @include background-color(#fbfbfb);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: $font-family;
  font-size: $text-small-size !important;
  font-weight: map-get($font-weights , 'semi-bold');
  line-height: 1.9;
  color: $utl-text-color-12;

  img {
    width: 11px;
    @include margin(left, top, right, bottom, 0, 0, 7.3, 0);
  }

}

// end upload css
.checkboxstyle {

  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    margin-bottom: 5px !important;

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-label {
      color: #4d546c !important;
      font-weight: map-get($font-weights, "semi-bold") !important;
    }
  }
}

.greenbwsbtn .p-button-label {
  color: $utl-theme-color !important;
}

.contract {
  .cls-notebox {
    font-size: $labeltxt;
    font-weight: map-get($font-weights, "medium");
    color: $utl-text-color-12 ;

    span {
      font-weight: map-get($font-weights, "semi-bold");
      color: #ab481d;
    }
  }
}

.p-colorpicker-preview {
  width: 173px;
  height: 64px;
}

/* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
.project-health .mat-select-value {
  overflow: visible !important;
}

.proj-health {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    display: flex;
    align-items: center;
    padding: 10px 0 !important;
  }
}


// profile badges css

.cls-profilebadges {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    @include border-pos(bottom, 1px, solid, #f5f5f5);
    @include padding(left, top, right, bottom, 0, 8, 0, 8);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .mat-expansion-panel-header {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      // @include border-pos(bottom, 1px, solid, #f5f5f5);

      align-items: flex-start;
      height: 64px;

      .mat-content {

        .mat-expansion-panel-header-title {
          color: #000;

          figure {
            @include margin(left, top, right, bottom, 0, 7, 5, 0);
          }

          ._user-avatar {
            position: relative;

            figure {
              @include margin(left, top, right, bottom, 0, 7, 5, 0);
            }

            .cls-point {
              top: -3px;
              right: 3px;
              bottom: 0;
              left: 0;
              position: absolute;
              z-index: 9;
              font-size: 13px;
              color: #ffffff;
              font-weight: 700;
            }
          }

        }
      }

      .mat-expansion-indicator {
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 0, 8, 0, 8);
      }
    }

    &:last-of-type {
      @include border-pos(bottom, 0, solid, transparent);
    }
  }
}

.cls-skillspanel {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    @include border-pos(bottom, 1px, solid, #f5f5f5);
    @include padding(left, top, right, bottom, 0, 8, 0, 0);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .mat-expansion-panel-header {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      // @include border-pos(bottom, 1px, solid, #f5f5f5);

      align-items: flex-start;
      height: 56px;

      .mat-content {

        .mat-expansion-panel-header-title {
          color: #000;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          figure {
            @include margin(left, top, right, bottom, 0, 7, 5, 0);
          }

          ._user-avatar {
            position: relative;

            figure {
              @include margin(left, top, right, bottom, 0, 7, 5, 0);
            }

            .cls-point {
              top: -3px;
              right: 3px;
              bottom: 0;
              left: 0;
              position: absolute;
              z-index: 9;
              font-size: 13px;
              color: #ffffff;
              font-weight: 700;
            }
          }

        }
      }

      .mat-expansion-indicator {
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 0, 8, 0, 8);
      }
    }

    &:last-of-type {
      @include border-pos(bottom, 0, solid, transparent);
    }
  }
}

.cls-goalspanel {
  .mat-expansion-panel:not([class*=mat-elevation-z]) {
    @include border-pos(bottom, 1px, solid, #f5f5f5);
    @include padding(left, top, right, bottom, 0, 8, 0, 0);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .mat-expansion-panel-header {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      height: auto;

      .mat-content {

        .mat-expansion-panel-header-title {
          color: #000;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          figure {
            @include margin(left, top, right, bottom, 0, 7, 5, 0);
          }

          ._user-avatar {
            position: relative;

            figure {
              @include margin(left, top, right, bottom, 0, 7, 5, 0);
            }

            .cls-point {
              top: -3px;
              right: 3px;
              bottom: 0;
              left: 0;
              position: absolute;
              z-index: 9;
              font-size: 13px;
              color: #ffffff;
              font-weight: 700;
            }
          }

        }
      }

      .mat-expansion-indicator {
        width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: none;
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @include padding(left, top, right, bottom, 20, 0, 0, 0);

        .goal-panel-body {
          @include margin(left, top, right, bottom, 0, 0, 0, 9);

          .cls-headtxt {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights , 'regular');
            color: $utl-text-color-1;
            line-height: 1.5;
          }

          .cls-goalper,
          .cls-value {
            @extend .cls-headtxt;
            font-weight: map-get($font-weights , 'medium');
            @include padding(left, top, right, bottom, 0, 0, 4, 0);
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }
        }
      }
    }

    &:last-of-type {
      @include border-pos(bottom, 0, solid, transparent);
    }
  }
}

.cls-chipsbox {
  .cls-chipsbox {
    display: flex;
    flex-wrap: wrap;

    .cls-chipset {
      @include padding(left, top, right, bottom, 16, 8, 16, 8);
      @include margin(left, top, right, bottom, 0, 0, 8, 8);
      @include border-radius(17px);
      @include border(1px, solid, #000);
      @include background-color;
      font-family: $font-family;
      font-size: $text-medium-size;
      font-weight: map-get($font-weights , bold);
      line-height: 1.5;
      color: $utl-text-color-1;

      &.active {
        @include background-color(#56bca6);
        @include border(1px, solid, #56bca6);
      }
    }
  }
}


// social profile leave textarea css

.cls-socialbg {
  .cls-card {
    .cls-leaveplaceholderbox {
      .cls-textbox {
        @include padding(left, top, right, bottom, 8, 8, 8, 8);
        @include border-radius;
        @include border(1px, solid, #c7c7c7);
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, 'regular');
        line-height: 1.5;
        color: $utl-text-color-12;
        resize: inherit;

      }
    }
  }
}

.bg-color-analytics {
  .rightside {
    @include margin(left, top, right, bottom, 0, 0, 45, 0 !important);
  }
}

// social goal css

.cls-goalpanel {
  .mat-accordion {
    .mat-expansion-panel {
      @include border-pos(bottom, 0px, solid, transparent);

      .mat-expansion-panel-header {
        height: auto;
        @include padding(left, top, right, bottom, 0, 0, 0, 4);
        font-size: inherit;
        color: $utl-text-color-1;
        line-height: 1.5;

        .mat-content {
          .mat-expansion-panel-header-title {
            .cls-arrow {
              font-size: 16px;
              color: rgba(0, 0, 0, .5);
              @include margin(left, top, right, bottom, 5, 0, 5, 0);

            }
          }
        }

        .mat-expansion-indicator {
          display: none;
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          .goal-panel-body {
            @include margin(left, top, right, bottom, 0, 0, 0, 9);

            .cls-headtxt {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights , 'regular');
              color: $utl-text-color-1;
              line-height: 1.5;
            }

            .cls-goalper,
            .cls-value {
              @extend .cls-headtxt;
              font-weight: map-get($font-weights , 'medium');
              @include padding(left, top, right, bottom, 0, 0, 4, 0);
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
            }
          }
        }
      }
    }
  }
}

.cls-gnltxt {

  .p-chips {
    .p-inputtext {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border-radius;
      @include border(1px, solid, #c7c7c7);
      font-family: $font-family;
      font-size: $text-medium-size;
      font-weight: map-get($font-weights, 'regular');
      line-height: 1.5;
      color: $utl-text-color-12;

      .p-chips-token {
        @include padding(left, top, right, bottom, 6, 3, 6, 3);
        @include margin(left, top, right, bottom, 0, 0, 8, 0);
        @include background-color($utl-text-color-16);
        @include border-radius(2px);

        .p-chips-token-label {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          color: #424242;
        }

        .p-chips-token-icon {
          color: #424242;
        }
      }
    }
  }

}

.career-portl-form {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline {
      background-color: #8d8d8d !important;
      height: 0.7px;
    }
  }
}

.checboxfix {

  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    left: 0;
  }
}

.scrollissuefix {
  .ps__thumb-y {
    top: auto !important;
    height: 100% !important;
    bottom: 0 !important;
  }
}

// toggel button css
.mat-mdc-slide-toggle {
  &.mat-success {
    &.mat-checked {

      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
      .mat-slide-toggle-label {

        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
        .mat-slide-toggle-bar {
          background-color: rgba(40, 190, 189, .42);

          /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
          .mat-slide-toggle-thumb-container {

            /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
            .mat-slide-toggle-thumb {
              background-color: #00bfa5;
            }
          }
        }
      }
    }
  }

  /* TODO(mdc-migration): The following rule targets internal classes of slide-toggle that may no longer apply for the MDC version.*/
  .mat-slide-toggle-thumb {
    transition: all 80ms linear;
    transition-property: background-color;
    transition-delay: 50ms;
  }
}

.visiblespin {
  .spinner-container {
    z-index: 99;
    top: calc(100vh - 370px) !important;
    height: 28% !important;
    background-color: $utl-white;

    .spinner-inner {
      z-index: 99 !important;
      width: 100%;
      text-align: center;
    }
  }
}

.addvariable-tbl {
  @extend .scrollfixtbl;

  .p-datatable-scrollable-body {
    min-height: auto !important;
    max-height: calc(100vh - 290px) !important;
  }
}

.scrollfixtbl {
  .p-l-10 {
    padding-left: 10px !important;
  }

  &.scrollnew {
    .p-datatable-wrapper {
      @extend .p-datatable-scrollable-body;
      min-height: max-content !important;

      &::-webkit-scrollbar:horizontal {
        height: 8px;
      }
    }
  }

  &.height-teams {
    p-table {
      &.exp-recovery {
        .p-datatable {
          .p-paginator {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
          }

          .p-datatable-wrapper {
            max-height: calc(100vh - 40vh) !important;
          }
        }
      }

      .p-datatable {
        .p-paginator {
          @include padding(left, top, right, bottom, 0, 0, 0, 10);
        }

        .p-datatable-wrapper {
          .p-datatable-table {
            border-color: transparent !important;
            border-collapse: initial;
          }

          @extend .p-datatable-scrollable-body;
          max-height: calc(100vh - 44vh) !important;
          min-height: max-content !important;
          border: 1px solid #D1D1D1;

          .p-datatable-thead tr th,
          .p-datatable-tbody tr td {
            span {
              width: 108px;
            }
          }

        }

      }
    }

  }

  .p-datatable-scrollable-body {
    min-height: 390px;
    max-height: 390px;
    @extend ._custom-scroll;

    &::-webkit-scrollbar-thumb {
      background-color: $utl-grey-color-23;

      &:hover {
        background-color: $utl-grey-color-23;
      }
    }
  }
}

.main-sidebar-social .p-fileupload.p-fileupload-basic.p-component {
  height: 80px;

  span.p-button.p-component.p-fileupload-choose.p-ripple {
    height: 80px;
  }
}

.fromprofile span.cls-prodsubname b {
  font-weight: map-get($font-weights, "semi-bold") !important;
}

// market place galleria

.cls-galleria {
  display: block;
  @include margin(left, top, right, bottom, 0, 0, 0, 32);

  p-galleriacontent {
    .custom-galleria {
      &.p-galleria {
        &.fullscreen {
          display: flex;
          flex-direction: column;

          .p-galleria-content {
            flex-grow: 1;
            justify-content: center;

            p-galleriaitem {
              .p-galleria-item-wrapper {
                .p-galleria-item-container {
                  .p-galleria-item {
                    max-height: 100%;
                    justify-content: center;
                    max-width: 100%;
                    @include background-color(transparent);
                    @include border(0px, solid, transparent);

                    img {
                      width: 1024px;
                      max-width: 100%;
                      max-height: 100%;
                      display: block
                    }

                  }
                }
              }
            }

          }
        }
      }

      .p-galleria-content {
        position: relative;

        p-galleriaitem {
          .p-galleria-item-wrapper {
            .p-galleria-item-container {
              .p-galleria-item {
                height: 415px;
                justify-content: center;
                width: 100%;
                @include background-color(#191919);
                @include border(1px, solid, #d9d9d9);

                img {
                  width: 1024px;
                  max-width: 100%;
                  max-height: 100%;
                  display: block
                }

              }
            }
          }
        }

        p-galleriathumbnails {
          .p-galleria-thumbnail-wrapper {
            .p-galleria-thumbnail-container {
              background: rgba(209, 209, 209, .4);
              padding: 0.5rem 0.25rem;
            }
          }
        }
      }

      .p-galleria-footer {
        p-galleriaitemslot {
          .custom-galleria-footer {
            @include background-color;
            @include border(1px, solid, #ccc);
            color: $utl-text-color-1;

            .p-button {
              color: $utl-text-color-1;
            }
          }
        }
      }

      .p-galleria-thumbnail-wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
      }

      .p-galleria-thumbnail-items-container {
        height: 65px;
        width: 100%;
      }

      .custom-galleria-footer {
        display: flex;
        align-items: center;
        background-color: #00bfa5;
        color: #ffffff;

        >button {
          background-color: transparent;
          color: #ffffff;
          border: 0 none;
          border-radius: 0;
          margin: .2rem 0;

          &.fullscreen-button {
            margin-left: auto;
          }

          &:hover {
            background-color: rgba(255, 255, 255, 0.733);
          }
        }
      }

      .title-container {
        >span {
          font-size: .9rem;
          padding-left: .829rem;

          &.title {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.global-blank {
  .img-src {
    max-width: 80% !important;
  }

  .inner-container-box {
    @include margin(left, top, right, bottom, 0, 30, 0, 0);
  }

  .Blank-stat-text {
    font-size: $heading-large-font-size;
  }
}

.noclickfile {
  .file-name {
    pointer-events: none;
  }

  .p-button-label {
    color: $utl-theme-color;
  }
}

//color picker focus/hover color change
.cls-cpaccordion {
  .p-colorpicker {
    .p-inputtext:enabled {
      &:focus {
        box-shadow: none;
      }

      &:hover {
        border-width: 2px;
      }
    }
  }
}



.emp-cal .mat-mdc-form-field.calenderBox {
  min-width: 100px;
}

// attendance calendar box css
.cls-calenderbox.emp-cal {
  @include padding(left, top, right, bottom, 8, 4, 8, 4);
}

.btn-emp-cal {
  .btn-outline-success {
    font-size: $text-small-size-new;
  }
}

.cls-calenderbox {
  // @include border(1px, solid, $utl-theme-color);
  @include border-radius;
  @include padding(left, top, right, bottom, 8, 6, 8, 6);
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-mdc-form-field {
    &.calenderBox {
      min-width: 153px;
      width: 100%;


      .mat-mdc-text-field-wrapper {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);


        .mat-mdc-form-field-flex {


          .mat-mdc-form-field-infix {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include border-pos(top, 0, solid, transparent);

            .mat-mdc-select {
              color: $utl-theme-color;


              .mat-select-trigger {


                .mat-select-value {


                  .mat-select-value-text {
                    font-size: $text-default-size;
                    color: $utl-text-color-12;
                    font-weight: map-get($font-weights , semi-bold);

                  }
                }


                .mat-select-arrow-wrapper {


                  .mat-select-arrow {
                    color: $utl-text-color-12;
                    border-color: $utl-text-color-12;
                    margin: -3px 4px 0;
                  }
                }
              }
            }
          }
        }

        .mdc-line-ripple {
          &::after {
            border-bottom-color: transparent;
            border-width: 0;
          }

          &::before {
            border-bottom-color: transparent;
            border-width: 0;
          }
        }


      }

    }
  }
}

// material dropdown css

.cls-selectopt {
  .mat-mdc-form-field {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
      .mat-select-arrow-wrapper {

        /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
        .mat-select-arrow {
          margin: -3px 4px 0;
        }
      }
    }
  }
}

.cls-notebox-new {
  span {
    font-weight: map-get($font-weights, "semi-bold") !important;
    font-size: $labeltxt;
    &.font-normal
    {
      font-weight: map-get($font-weights, "regular") !important;
    }
  }

  p {
    font-weight: map-get($font-weights, "medium");
    font-size: $labeltxt;
  }
}




// dbw menu css

.cls-submenuitem {
  .cls-menuitems {
    display: flex;
    align-items: center;
    justify-content: start;
    font-family: $font-family;
    font-weight: map-get($font-weights , 'medium') !important;
    font-size: $text-default-size;
    line-height: 17px !important;
    color: $utl-text-color-12;

    img {
      @include margin(left, top, right, bottom, 0, 0, 10, 0);
      width: 14px;
      opacity: .8;
    }
  }
}


.cls-analiticsearchbox {
  @include border-radius;
  @include border(1px, solid, #c7c2c2);
  @include background-color;
  display: flex;
  align-items: center;
  @include padding(left, top, right, bottom, 3, 3, 3, 3);

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 20px;
  }

  .p-float-label {
    height: 38px;
  }

  img {
    @include margin(left, top, right, bottom, 5, 0, 5, 0);
    width: 20px;
  }

  input {
    @include border(0px, solid, transparent);
    width: 100%;
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    color: rgba(27, 36, 54, 0.5);
    height: 28px;
  }

  .cls-clearbtn {
    font-family: $font-family;
    font-size: $text-medium-size;
    line-height: 15px;
    font-weight: map-get($font-weights, "medium");
    color: $utl-white;
    @include background-color(#5A5E6F);
    @include border-radius(3px);
    @include padding(left, top, right, bottom, 6, 4, 6, 4);
    border: 0;
    @include margin(left, top, right, bottom, 0, 0, 3, 0);
    cursor: pointer;
  }
}


// widget popup css

.cls-widgetpopup {
  .counter-container {
    height: 100%;

    .mat-mdc-card {
      box-shadow: none !important;
    }
  }
}

.cls-blacklistsubtxt {
  .subtitle {
    width: 100% !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

}

.markatt-textarea {
  @extend .viewtask;

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: #D9D9D9;

  }

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-appearance-outline .mat-form-field-infix {
    @include padding(left, top, right, bottom, 0, 3, 0, 20);
  }
}

.new-social {
  textarea#chattext {
    height: 69px;
    border-color: $utl-grey-color-8;
    color: $utl-grey-color-8;
    font-weight: map-get($font-weights, "normal");
    @include margin(left, top, right, bottom, 9, 0, 0, 0);
    width: calc(100% - 23px);
  }

  app-hrone-chat-input {
    .image-upload {
      @include margin(left, top, right, bottom, 0, 3, 8, 0);
    }
  }
}

// All search class

.cls-listtxt {
  &.mat-mdc-list-base {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include margin(left, top, right, bottom, 0, 16, -16, 0);

    .mat-mdc-list-item {
      height: auto;
      font-size: inherit;
      @include padding(left, top, right, bottom, 8, 5, 8, 5);
      @include margin(left, top, right, bottom, 0, 0, 0, 8);
      cursor: pointer;

      &:hover,
      &.text-active {
        @include background-color(#E0E0E0);


        .mdc-list-item__content {
          .mdc-list-item__primary-text {
            font-weight: map-get($font-weights , 'bold');
          }

        }
      }


      .mdc-list-item__content {
        .mdc-list-item__primary-text {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          font-weight: map-get($font-weights , 'regular');
          font-size: $text-default-size;
          line-height: 20px;
          letter-spacing: 0.04em;
          color: $utl-text-color-1;
        }

      }
    }
  }
}

// social profile images css

.cls-socialbg {
  .cls-card {
    .Manage-profilepic {
      .cls-imgbox {
        .upload-link {
          .p-fileupload {
            .p-button {
              @include background-color(transparent);
              @include border(0px, solid, transparent);
              height: 104px;
              width: 104px;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}

canvas.confetti-canvas {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

// report save view oum input css

.UOM-chip-list {

  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {

    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-flex {

      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
      .mat-form-field-infix {

        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        .mat-chip-list {

          /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
          /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
          .mat-chip-list-wrapper {
            .mat-mdc-chip-input {
              width: auto;
              @include margin(left, top, right, bottom, 0, 0, 0, 2);
            }
          }
        }
      }
    }
  }
}

.btn-refresh {
  @include background-color;
  @include border(1px, solid, rgba(39, 44, 62, 0.4));
  @include border-radius;
  height: 33px;
  cursor: pointer;
  @include padding(left, top, right, bottom, 9, 6.5, 9, 6);
  @include margin(left, top, right, bottom, 16, 0, 0, 0);

  img {
    opacity: .8;
    width: 18px;
    vertical-align: super;
  }
}

.customscrl-new::-webkit-scrollbar {
  height: 10px !important;
}

.customscrl-new::-webkit-scrollbar-thumb {
  background-color: $utl-grey-color-23;
}

.travel-plan {
  @extend .analytics_scroll;
  max-height: calc(100vh - 227px) !important;
  height: auto !important;
  table {
    border-collapse: separate;
    border-spacing: 0;
  }

  tbody tr td {
    vertical-align: middle;
    padding: 0 15px;
  }
}
.cv-shared
{
  @extend .travel-plan ;
  max-height: calc(100vh - 210px);
  height: auto !important;
  tbody tr td {
    padding: 8px 15px;
  }
}

.analytics_scroll {
  thead {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  height:calc(100vh - 165px);
  overflow: auto;
}

.recentimport {
  height: calc(100vh - 220px);
  overflow: auto;
  margin-top: 20px;
  width: 100%;

  table {
    .text-red {
      @include color(#0DA200);
    }

    .text-green {
      @include color(#FE1024);
    }

    .text-green {
      @include color(#FFFF00);
    }

    .text-green {
      @include color(#0000FF);
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 99999;

      th {
        @include background-color($utl-grey-color-14);
        @include padding(left, top, right, bottom, 15, 0, 15, 0);
        height: 32px;

        &:first-child {
          position: sticky;
          z-index: 999999;
          left: -1px;
          border: 1px solid #ddd;
          border-right: 0 !important;
        }
      }
    }

    .headcol {
      position: sticky;
      left: 0;
      z-index: 999;
      top: 0;
    }

    tbody {
      tr {
        td {
          position: sticky;
          left: 0;
        }
      }
    }
  }

}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.customdropdwn .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px;
}

.hide-header {
  app-fcc-engage .engage-cards-container {
    position: static;
    width: 100%;
    margin: auto;
  }
}

.custom-validation {
  .mat-mdc-form-field-error {
    font-size: 75%;

    span {
      color: #f44336 !important;
      font-size: 100%;
      font-family: $font-family !important;
      @include margin(left, top, right, bottom, 0, 3, 0, 0);
      display: block;
      font-weight: 400;
    }
  }
}

.visible-modal {
  display: flex !important;

  .p-dialog {
    margin: auto !important;
  }
}



.fulltxtcheckbox {


  .mat-mdc-checkbox-layout {
    align-items: start !important;
  }


  .mat-mdc-checkbox-label {
    white-space: normal !important;
    line-height: 17px !important;
  }
}

// leave chips box css
.cls-chipsbox {
  .mat-chip-list-wrapper {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    font-size: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .mat-standard-chip {
      @include background-color(#F0F0F0 !important);
      @include border(0.5px, solid, #D9D9D9);
      @include border-radius;
      font-family: $font-family;
      font-weight: map-get($font-weights , regular);
      font-size: $text-small-size;
      line-height: 18px;
      color: $utl-text-color-1;
      padding: 0 4px !important;
      @include margin(left, top, right, bottom, 0, 0, 4, 4);
      min-height: 20px;
    }
  }
}

app-bgv-verification {
  position: relative;
}

.fixed-bottom-footer {
  position: sticky;
  @include padding(left, top, right, bottom, 32, 17, 32, 17);
}

//  css for pi-ellipsis-v

.pi-ellipsis-v {
  font-size: 12px;
  font-weight: 700;
}

// p-table css of loan

.loan-tbl {
  .p-datatable-table {
    table-layout: fixed;
  }
}


.formula-block {
  .mat-mdc-standard-chip.mdc-evolution-chip--with-primary-graphic {
    .mdc-evolution-chip__action--primary {
      padding-right: 5px !important;
    }
  }
}

//hrdesk policy chip
.helpdesk-chip {
  &.mdc-evolution-chip-set {
    .mat-mdc-chip {
      .mdc-evolution-chip__cell {
        .mdc-evolution-chip__action {
          .mdc-evolution-chip__text-label {
            white-space: normal;
            font-size: 0.757em;
          }
        }
      }
    }
  }
}

// hr search clear 

.cls-clearbox {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}


.create-widget {
  @include border-radius(4px !important);
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 25%) !important;
  @include margin(left, top, right, bottom, 3, 15, 3, 15);

  .mat-mdc-card-title,
  .mat-card-title {
    margin-bottom: 15px;
    // background-color: rgba(39, 44, 62, 0.06);
    @include padding(left, top, right, bottom, 12, 5, 12, 5);
    @include border-radius(4px);
    // color: $utl-text-color-12;
    font-size: $labeltxt;
    font-weight: map-get($font-weights, "bold");
  }

  .mat-expansion-panel-body {
    .mat-mdc-card {
      .mat-mdc-card-header {


        .mat-mdc-card-header-text {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }
    }
  }
}

.cls-analyticstbl {


  .p-datatable-scrollable-footer-box {
    margin-right: 8px !important;
    padding: 0 !important;
  }
}

// welcome border css

.cls-welcomeheader {
  ._user-snippet {
    border: none !important;
  }
}

// ar/od/sl balance txt
.balance {
  @include padding(left, top, right, bottom, 8, 8, 8, 8);
  letter-spacing: 0.3px;
}

// custom rating in 360 feedback 
.avgrating {
  i {
    font-size: 22px;
    color: #F79009;
    @include padding(left, top, right, bottom, 0, 0, 8, 0);
  }
}

//footnotes -62020
.purposenotebox {
  @include border-radius;
  border: 1px solid rgba(17, 22, 32, 0.20);
  @include background-color (rgba(17, 22, 32, 0.02));
  @include padding(left, top, right, bottom, 12, 12, 12, 12);
  max-width: 36vw;
  min-width: 50%;

  .bluecolor {
    @include color(#5386E4);
  }
}

// css horizontal stepper

.cls-horizontalstepper {
  font-family: $font-family;

  .mat-horizontal-stepper-wrapper {
    font-family: $font-family;

    .mat-horizontal-stepper-header-container {
      margin: 0 32px;

      .mat-horizontal-stepper-header {

        .mat-step-label {
          font-size: $text-default-size;

          .mat-step-text-label {
            white-space: initial;
            overflow: visible;
            text-overflow: initial;
          }
        }

        .mat-step-label-selected {
          font-size: $text-default-size;
        }


      }
    }

    .mat-horizontal-content-container {
      padding: 0;
      // margin-top: -72px;
    }
  }
}

@media (max-width: 800px) {
  .cls-otpform {
    .cls-companyname {
      top: 36px !important;
    }

    .cls-formbox {
      .cls-logobox {
        left: 41% !important;
      }

      .custome-header-bg {
        margin-bottom: 0 !important;
      }
    }
  }

  .btn-outline-white {
    display: none;
  }
}

// global search input css
.empSearch,
.cls-globalserch {
  .cls-globalsearch {
    width: 100%;

    &.p-autocomplete {
      .p-inputtext {
        @include background-color;
        @include border-radius;
        @include border(0px, solid, transparent);
        @include padding(left, top, right, bottom, 16, 12, 16, 12);
        width: 100%;
        font-family: $font-family;
        font-weight: map-get($font-weights , 'medium');
        font-size: $text-medium-size;
        line-height: 15px;
        letter-spacing: 0.04em;
        color: #272C3E;

        &:focus {
          box-shadow: none;
        }
      }
    }

    &.p-autocomplete-multiple {
      min-width: 304px;

      .p-autocomplete-multiple-container {
        font-size: inherit;

        .p-autocomplete-input-token {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          input {
            font-family: $font-family;
            font-weight: map-get($font-weights , 'medium');
            font-size: $text-medium-size;
            line-height: 15px;
            letter-spacing: 0.04em;
            color: #272C3E;
          }
        }

        &.p-focus {
          box-shadow: none;
        }
      }
    }
  }
}

.home-emplsearch {
  .cls-globalsearch {
    width: 100%;

    &.p-autocomplete {
      .p-inputtext {
        @include background-color(transparent);
        @include border-radius;
        @include border(0px, solid, transparent);
        @include padding(left, top, right, bottom, 16, 12, 16, 12);
        width: 100%;
        font-family: $font-family;
        font-weight: map-get($font-weights , 'medium');
        font-size: $text-medium-size;
        line-height: 15px;
        letter-spacing: 0.04em;
        color: #272C3E;

        &:focus {
          box-shadow: none;
          border-bottom: 0;
        }
      }
    }


  }

  .cls-globalserch {
    &.p-autocomplete {
      .p-inputtext {
        @include background-color(transparent);
        border-radius: 0px 0px 0px 0px;
        @include border(0px, solid, transparent);
        @include padding(left, top, right, bottom, 16, 12, 16, 12);
        width: 100%;
        font-family: $font-family;
        font-weight: map-get($font-weights , 'medium');
        font-size: $text-medium-size;
        line-height: 15px;
        letter-spacing: 0.04em;
        color: #272C3E;

        &:focus {
          box-shadow: none;
        }
      }
    }

  }
}

.cls-searchbox {
  display: flex;

  .cls-globalsearch {
    &.p-autocomplete-multiple {


      .p-autocomplete-multiple-container {

        @include background-color(transparent);
        border-radius: 0px 0px 0px 0px;
        @include border(0px, solid, transparent);
        @include padding(left, top, right, bottom, 16, 10, 16, 12);
        min-width: 100%;
        height: 40px;
        overflow-y: auto;
        gap: 0.2rem;

        .p-autocomplete-token {
          .p-autocomplete-token-label {
            font-size: $text-small-size;
          }

          .p-autocomplete-token-icon {
            font-size: .7rem;
          }
        }

        .p-autocomplete-input-token {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          input {
            @include background-color(transparent);

            font-family: $font-family;
            font-weight: map-get($font-weights , 'medium');
            font-size: $text-medium-size;


            line-height: 20px;
            letter-spacing: 0.04em;
            color: #272C3E;
          }
        }

        &.p-focus {
          box-shadow: none;
        }
      }
    }
  }
}

// global search home overlay panel
.cls-overlaypanel {
  &.p-overlaypanel {
    margin-top: 0 !important;
    left: 228px !important;
    top: 42px !important;
    right: 0;
    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.16);
    @include border(1px, solid, #C7C9CD);
    @include border-radius;

    &:before,
    &:after {
      content: "";
      border-width: 0px;
      position: fixed;
      bottom: revert;
      left: auto;
    }

    .p-overlaypanel-content {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
    }


    .cls-topsearch {
      .clssearch-container {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        border: 0 !important;
      }
    }

    .p-overlaypanel-close {
      display: none;
    }
  }
}

// sidemenu overlay panel
.cls-sidepanel {
  &.p-overlaypanel {
    margin-top: 0 !important;
    left: -1px !important;
    top: 0px !important;
    transform: none !important;
    z-index: inherit !important;
    right: 0;

    box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.16);
    @include border(1px, solid, #C7C9CD);
    @include border-radius;

    &:before,
    &:after {
      content: "";
      border-width: 0px;
      position: fixed;
      bottom: revert;
      left: auto;
    }

    .p-overlaypanel-content {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
    }


    .cls-topsearch {
      .clssearch-container {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        border: 0 !important;
      }
    }

    .p-overlaypanel-close {
      display: none;
    }
  }
}

.cls-newsplitbtn {
  .p-splitbutton {
    height: 40px;
    @include border(1px, solid, #586AC6);
    @include border-radius;

    .p-button {
      @include background-color(transparent);
      @include border-radius;
      @include border(0px, solid, transparent);
      color: #586AC6;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        @include background-color(#586AC6);
        color: $utl-white;
        @include border-radius(0px);
      }
    }

    .p-menu {
      width: 100%;
    }
  }
}

// cls-sidecheckbox css
.cls-sidecheckbox {
  &.mat-mdc-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        margin-right: 0px !important;
        margin-left: 5px !important;
      }
    }
  }

}

//hr desk report tab css

.cls-report {
  @include background-color;
  @include padding(left, top, right, bottom, 0, 16, 0, 0);
  width: 100%;
  border-top: 1px solid #CBCBCB;
  margin-top: -1px;

  .mat-tab-header {
    @include border-pos(bottom, 0px, solid, transparent);

    .mat-tab-label-container {
      .mat-tab-list {
        .mat-tab-labels {
          .mat-tab-label {
            height: 41px;
            @include padding(left, top, right, bottom, 16, 0, 16, 0);
            cursor: pointer;
            box-sizing: border-box;
            opacity: 1;
            min-width: 0;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            position: relative;
            @include border-pos(top, 1px, solid, #c7c9cd);
            @include border-pos(right, 1px, solid, #c7c9cd);
            @include border-pos(left, 1px, solid, #c7c9cd);
            @include border-pos(bottom, 1px, solid, #c7c9cd);
            @include background-color(#f9f9f9);

            .mat-tab-label-content {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "semi-bold");
              color: rgba(39, 44, 62, 0.6);
            }

            &:first-child {
              border-top-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }
          }

          .mat-tab-label-active {
            @include background-color;
            @include border-pos(bottom, 0px, solid, transparent);
          }
        }

        .mat-ink-bar {
          @include background-color(transparent !important);
        }
      }
    }
  }

  .mat-tab-body-wrapper {
    @include border(1px, solid, #c7c9cd);
    @include padding(left, top, right, bottom, 31, 31, 31, 31);
    @include margin(left, top, right, bottom, 0, -1, 0, 0);
  }
}

// global search tab css
.cls-anchglobal-tab {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      @include margin(left, top, right, bottom, 40, 0, 40, 0);

      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mat-mdc-tab.mdc-tab {
              height: 40px;
            }
          }
        }
      }
    }

    .mat-mdc-tab-body-wrapper {
      @include padding(left, top, right, bottom, 40, 0, 40, 0);


      &.mat-tab-body-active {
        .mat-tab-body-content {
          .cls-reporttab-container {
            .active {
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include background-color;
              @include border-pos(top, 1px, solid, #CBCBCB);
              @include border-pos(left, 1px, solid, #CBCBCB);
              @include border-pos(right, 1px, solid, #CBCBCB);

              @include border-pos(bottom, 0px, solid, transparent);

              &:first-child {
                border-top-left-radius: 4px;
              }

              &:nth-child(4n -1) {
                border-top-right-radius: 4px;
              }

              .cls-tab1 {
                font-family: $font-family;
                font-weight: map-get($font-weights , 'regular');
                font-size: $text-default-size;

                letter-spacing: 0.015em;
                @include padding(left, top, right, bottom, 12, 0, 12, 0);
                width: 260px;
                cursor: pointer;
                height: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-block;
                line-height: 40px;

              }

              .material-icons {
                font-size: 14px;
                @include margin(left, top, right, bottom, 0, 0, 12, 0);
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}

// matmenu icon changes

.cls-menuiconchg {
  &.mat-mdc-raised-button {
    flex-direction: row-reverse;

    .mat-icon {
      @include margin(left, top, right, bottom, 8, 0, 0, 0);
    }
  }
}

.cls-searchview {
  height: 40px;
  line-height: 40px;
  width: 175px;

  .mat-mdc-form-field {
    padding: 0 10px;

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding: 0;
          min-height: auto;
        }
      }
    }

    .mdc-line-ripple {
      &::after {
        border-bottom: 0;

      }

      &::before {
        border-bottom: 0;
      }
    }
  }
}

// OU search chips css 



.cls-searchchips {
  width: 85%;

  .mdc-evolution-chip-set__chips {
    .mat-mdc-chip {
      max-width: 95%;
    }
  }

  .mdc-evolution-chip__cell--primary {
    width: 96% !important;
  }
}

.matchip-more {


  .mdc-evolution-chip-set__chips {
    .mat-mdc-chip {
      max-width: 100%;
    }
  }

  .mdc-evolution-chip__cell--primary {
    width: 100% !important;
  }
}

// image/pdf viewer common css
.ng2-pdf-viewer-container {
  @extend ._custom-scroll;
  @extend .custom-new;
  overflow-x: hidden !important;
  overflow-y: scroll;
}

.profile-avatar {
  img {
    width: 40px;
    height: 40px;
  }
}

.cls-showmr {

  .subtitle {
    margin-bottom: 16px !important;
  }

}

// hide pop-out from document-viewer
.responsive-container {
  position: relative;
  padding-bottom: 63.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.responsive-container iframe,
.responsive-container object,
.responsive-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hidden {
  width: 40px;
  height: 52px;
  position: absolute;
  background-color: #FFF;
  right: 14px;
  top: 14px;
}

// setting css of setup part

.icon {
  transition: ease-in-out all 0.1s;
  @include border-radius();
  width: max-content;
  z-index: 9;
  @include padding(left, top, right, bottom, 7, 8, 7, 8);
  height: 30px;
  cursor: pointer;
}