@import "../../theme/main.scss";

.btn-outline-tertiary {
  border: solid 1px rgba(39, 44, 62, 0.4);
  background-color: $utl-grey-color-28;
  font-size: $labeltxt;
  font-weight: map-get($font-weights , 'semi-bold');
  @include padding(left, top, right, bottom, 4, 4, 4, 4);
  color: $utl-text-color-12;

  .clsblank-img {
    width: 20px;
    height: 20px;
    color: $utl-text-color-12;
  }

  &.backbtn {
    @include padding(left, top, right, bottom, 8, 8, 8, 8);
    box-shadow: 0px 0px 4px 0px rgba(111, 111, 111, 0.25);
    line-height: 8px;
    text-transform: none;
  }
}

.btn-tertiary {
  min-width: 96px;
  @include background-color($utl-grey-color-28);
  font-weight: map-get($font-weights, "semi-bold");
  @include border(1px, solid, #c5c5c5);
  font-size: $text-name;
  height: 32px;
}

.primary-no-fill {
  @include border(1px, solid, $utl-theme-color);
  color: map-get($heading-colors, "utl-theme-color");
  font-weight: map-get($font-weights, "semi-bold");
  @include background-color(transparent);
  font-size: 0.978em;
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;

  span.icon {
    font-size: 1.94em;
    @include margin(left, top, right, bottom, 0, 0, 10, 0);
    font-weight: map-get($font-weights, "medium");
  }
}

.btn-line-red {
  border-color: #f06292;
  color: #f06292;
  min-width: 120px;
}

.btn-red {
  border-color: #c73232;
  color: #ffffff;
  background-color: #c73232;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-h-40 {
  height: 40px;
}

.btn-min-width {
  min-width: 168px;
}

.s-min-width {
  min-width: 94px;
}

.single-cta {
  width: 15.714em;
}

.missed-btn {
  @include background-color($utl-text-color-11);

  &:hover {
    @include background-color($utl-grey-color-15);
  }
}

.paired-cta {
  .paired-cta-inner {
    button {
      font-size: $text-medium-size;
    }

    .btn-ignore {
      @include border(1px, solid, #e8e8e8);
      @include background-color(#fbfbfb);
      color: $utl-text-color-7;
    }

    .btn-wrap {
      max-width: 50%;
      flex: 0 0 calc(50% - 7px);

    }
  }
}


// Default Button Styling
.btn-default {
  @include border(1px, solid, rgba(26, 26, 26, 0.25));
  min-width: 96px;
  @include background-color($utl-white !important);
  color: $utl-text-color-7;

  &:hover {
    box-shadow: none;
  }
}

.btn-success,
.btn-success.disabled {

  background: #02563D;
  border: none;
  box-shadow: none;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  color: $utl-white;
  line-height: initial;
}

.btn-h-32 {
  height: 32px;
}

.analtyics-icon-container {
  @include background-color;
  @include border(1px, solid, $border-green-box);
  @include border-radius;
  @include padding(left, top, right, bottom, 16, 12, 16, 12);
  font-size: $text-name;
  font-weight: map-get($font-weights , semi-bold);
  cursor: pointer;
  height: 40px;
  @include color($utl-text-color-12);
  white-space: nowrap;
  line-height: initial;

  &:hover {
    @include background-color(rgba(18, 196, 172, .03));
  }
}


.btn-login,
.btn-login.disabled {
  border: none;
  box-shadow: none;
  -webkit-transition: 0.2s ease-in;
  -o-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  line-height: initial;
}
// purple outlined btns
.btn-purpul {
  @include background-color;
  @include border(1px, solid, $utl-blue-19);
  color: $utl-blue-19;
  display: flex;
  align-items: center;

  &:hover {
    @include background-color($utl-blue-19);
    color: $utl-white;
  }
}