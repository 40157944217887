.ui-autocomplete {
    width: auto;
    zoom: 1;
    cursor: pointer;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
    display: inline-block;
}

.ui-autocomplete .ui-autocomplete-dropdown {
    height: 100%;
    width: 2em;
    margin-right: 0;
    vertical-align: top;
}

.ui-autocomplete .ui-autocomplete-input {
    padding-right: 1.5em;
}

.ui-autocomplete-loader {
    position: absolute;
    right: .25em !important;
    top: 50%;
    margin-top: -.4em;
}

.ui-autocomplete-query {
    font-weight: bold;
}

.ui-autocomplete .ui-autocomplete-panel {
    min-width: 100%;
}

.ui-autocomplete-panel {
    position: absolute;
    overflow: auto;
}

.ui-autocomplete-panel .ui-autocomplete-list {
    padding: 0.4em;
    border: 0 none;
}

.ui-autocomplete-panel .ui-autocomplete-list-item {
    border: 0 none;
    cursor: pointer;
    font-weight: normal;
    margin: 1px 0;
    padding: 0.186em 0.313em;
    text-align: left;
}

.ui-autocomplete .ui-button-icon-only,
.ui-autocomplete .ui-button-icon-only:enabled:hover,
.ui-autocomplete .ui-button-icon-only:enabled:focus,
.ui-autocomplete .ui-button-icon-only:enabled:active {
    border-left: 0 none;
}

/* Multiple Selection */
.ui-autocomplete-multiple-container {
    display: inline-block;
    vertical-align: middle;
}

.ui-autocomplete-multiple-container.ui-inputtext {
    clear: left;
    cursor: text;
    list-style-type: none;
    margin: 0;
    overflow: hidden;
    padding: 0 1.5em 0 .25em;
}

.ui-autocomplete-token {
    cursor: default;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    padding: .125em .5em;
    white-space: nowrap;
    position: relative;
    margin-right: .125em;
    border: 0 none;
    font-size: .9em;
}

.ui-autocomplete-token-label {
    display: block;
    margin-right: 2em;
}

.ui-autocomplete-token-icon {
    margin-top: -.5em;
    position: absolute;
    right: 0.2em;
    top: 50%;
    cursor: pointer;
}

.ui-autocomplete-input-token {
    display: inline-block;
    vertical-align: middle;
    list-style-type: none;
    margin: 0 0 0 .125em;
    padding: .25em .25em .25em 0;
}

.ui-autocomplete-input-token input {
    border: 0 none;
    width: 10em;
    outline: medium none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.ui-autocomplete-dd .ui-autocomplete-loader {
    right: 0.25em;
}

.ui-autocomplete-dd input.ui-corner-all ,
.ui-autocomplete-dd .ui-autocomplete-multiple-container.ui-corner-all {
     -moz-border-radius-topright: 0px; 
     -webkit-border-top-right-radius: 0px;
     border-top-right-radius: 0px;
     -moz-border-radius-bottomright: 0px;
     -webkit-border-bottom-right-radius: 0px;
     border-bottom-right-radius: 0px;
 }
 
.ui-autocomplete-dd .ui-autocomplete-dropdown.ui-corner-all {
     -moz-border-radius-topleft: 0px; 
     -webkit-border-top-left-radius: 0px;
     border-top-left-radius: 0px;
     -moz-border-radius-bottomleft: 0px;
     -webkit-border-bottom-left-radius: 0px;
     border-bottom-left-radius: 0px;
}

/** AutoComplete **/
.ui-fluid p-autocomplete,
.ui-fluid .ui-autocomplete,
.ui-fluid .ui-autocomplete-input {
    width: 100%;
}

.ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-autocomplete-input,
.ui-fluid .ui-autocomplete.ui-autocomplete-dd .ui-autocomplete-multiple-container {
    width: 100%;
    padding: 0 !important;
    border-bottom: solid 2px #c8cdd1;
}

.ui-fluid .ui-autocomplete .ui-autocomplete-dropdown.ui-button {
    width: 2em;
}
.ui-state-highlight {
    border-color: #156090;
    background: #186ba0;
    color: #FFFFFF;
}