
// badges wrappers
.badge-form-wrapper {
    @include border-radius(4px);
    @include padding(left, top, right, bottom, 24, 24, 24, 16);
    @include margin(left, top, right, bottom, 0, 0, 0, 35);
  
    ._blue-point-badge {
      position: relative;
    
   
    }
  
    p {
      // color: map-get($heading-colors, "utl-theme-color");
      font-size: $text-default-size;
      position: relative;
      
    }
  }