@import "../../theme/main.scss";

.mainipl {
  .p-dialog {
    display: flex;
    align-items: center;
    align-self: flex-end;
  }
}

.quickhack {
  .p-dialog {
    max-height: 90% !important;
  }

  .p-dialog-content {
    min-height: 540px !important;
    overflow: hidden;

    padding: 15px !important;

    .dialog-wrapper {
      padding: 5px 15px !important;

      .p-carousel-item {
        height: 540px !important;
      }
    }

    .btnclose {
      top: 16px !important;
    }
  }

  .header-title {
    font-size: 1.428em;
    color: #3b3f50;
  }
}

.cls-letter.misc .p-dialog .p-dialog-header {
  padding-bottom: 0 !important;
}

.organization-wise.miscletter {
  perfect-scrollbar {
    padding: 0px !important;
  }
}

// salary dialog pop css

.salary-dialog {
  border: none;
  box-shadow: none;
  overflow: hidden;

  &.p-dialog-mask {
    .p-dialog {
      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {

        overflow: auto;
        position: relative;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        .dialog-wrapper {
          @include padding(left, top, right, bottom, 32, 24, 32, 0);

          h2 {
            font-size: $heading-s-medium-font-size;
            color: $utl-text-color-11;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          .cls-hdtxt {
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            color: $utl-text-color-11;
            line-height: 1.43;
            display: inline-block;
            @include margin(left, top, right, bottom, 0, 0, 0, 6);
          }

          .cls-contbox {
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            color: $utl-text-color-12;
            line-height: 1.43;
            display: inline-block;
            @include margin(left, top, right, bottom, 0, 0, 0, 8);

            span {
              font-weight: map-get($font-weights, "regular");
            }
          }

          .cls-spacebox {
            @include margin(left, top, right, bottom, 0, 24, 0, 0);

            .alert-text {
              font-size: $text-default-size;
              font-weight: map-get($font-weights, "semi-bold");
              color: $utl-text-color-11;
              line-height: 1.43;
              display: inline-block;
            }

            .sub-mgm-text {
              @extend .alert-text;
            }

            .btnclose {
              @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

              i,
              em {
                font-size: 30px;
              }
            }
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

// Dialog Global Styling=====================
.p-dialog {
  border: none;
  box-shadow: none;
  overflow: hidden;

  .p-dialog-header {
    display: none;
    @include padding(left, top, right, bottom, 32, 24, 32, 0);
    @include background-color;
    @include border-pos(bottom, 0, solid, transparent);

    .p-dialog-header-icons {
      display: none;
    }
  }

  .p-dialog-content {
    // min-height: 185px;
    overflow: auto;
    position: relative;
    @include padding(left, top, right, bottom, 32, 24, 32, 24);

    p {
      font-size: $heading-extra-small-font-size;
      color: $utl-text-color-11;
      font-weight: map-get($font-weights, "medium");
    }

    .btnclose {
      @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

      i,
      em {
        font-size: 30px;
      }
    }
  }

  .p-dialog-footer {
    @include padding(left, top, right, bottom, 32, 16, 32, 16);

    p-footer {
      padding: 0;

      button {
        height: 40px;
        @include margin(left, top, right, bottom, 16, 0, 0, 0);
      }
    }
  }
}

// GlobalSearch Available Seach Dialog****************

._globalSearcDialog {
  .p-dialog-content {
    padding-bottom: 0;

    .p-dialogHeader {
      h2 {
        font-size: $heading-small-font-size;
        font-weight: map-get($font-weights, "medium");
      }
    }

    .btnclose {
      background: $utl-white;
      width: 32px;
      height: 32px;
      @include border-radius(64px);
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        font-size: 24px;
      }
    }
  }
}

//   Moodbot Dialog
.moodbotDialog {
  .p-dialog-content {
    padding-bottom: 8px;
  }

  .p-dialog-footer {
    text-align: center;
    border-top: none;
    padding-bottom: 24px;
    padding-top: 0;

    p-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// salary pop css
.regisnaton-pop {
  @extend .hrone-dialog;

  .header-title {
    font-size: 20px !important;
    color: #4d546c !important;
    font-weight: map-get($font-weights, "bold") !important;
  }

  p {
    color: #4d546c !important;
    font-size: $tabs-text !important;
    opacity: 1 !important;
  }
}

.hrone-dialog {
  border: none;
  box-shadow: none;
  overflow: hidden;

  &.p-dialog-mask {
    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      min-height: 100px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .dialog-wrapper {
        @include padding(left, top, right, bottom, 32, 24, 32, 0);

        h2 {
          font-size: $heading-s-medium-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "semi-bold");
        }

        p {
          font-size: $heading-extra-small-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "medium");
        }

        .message-text {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-grey-color-6;
        }

        .btnclose {
          @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

          i,
          em {
            font-size: 30px;
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

.confirmed-dialog {
  @extend .hrone-dialog;
  max-width: auto;
}

/***************** Expanse dropdown css ********************/

.cls-expensematmenu {
  @include border-radius(2px);
  @include border(0px, solid, transparent);
  outline: 0;
  @include padding(left, top, right, bottom, 0, 13, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  left: 0;
  position: absolute;
  top: 100% !important;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
  z-index: 999;

  .p-dialog-mask {
    position: initial;

    &:after,
    &:before {
      bottom: 100%;
      left: 81%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #ffffff;
      border-width: 9px;
      margin-left: -9px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #ece7e7;
      border-width: 10px;
      margin-left: -10px;
    }

    .p-dialog {
      overflow: visible;

      .p-dialog-content {
        overflow: visible;
        @include padding(left, top, right, bottom, 16, 24, 16, 16);
      }
    }
  }
}

//expense payment

.cls-expensepaymentbox {
  @include border-radius(2px);
  @include border(0px, solid, transparent);
  outline: 0;
  @include padding(left, top, right, bottom, 0, 13, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  left: 10%;
  position: absolute;
  top: 100%;
  z-index: 9;

  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);

  .p-dialog-mask {
    position: initial;

    &:after,
    &:before {
      bottom: 100%;
      left: 95.4%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: #ffffff;
      border-width: 9px;
      margin-left: -9px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #ece7e7;
      border-width: 10px;
      margin-left: -10px;
    }

    .cls-popuptitle {
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, semi-bold);
      line-height: 2.14;
      color: $utl-text-color-11;
    }

    .full-width {
      border: 4px solid #f5f7fa;
      height: 0;
    }

    .p-dialog-content {
      overflow: auto;
      height: 300px;
    }

    .cls-submit {
      height: 40px;
      @include border-radius;
      @include background-color($utl-theme-color);
      @include border(1px, solid, $utl-theme-color);
      @include padding(left, top, right, bottom, 15, 0, 15, 0);
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "semi-bold");
      text-align: center;
      color: $utl-white;
    }
  }
}

//on behalf employee search

.employee-search {
  &.p-dialog {
    .p-dialog-header {
      display: block;
      min-height: 50px;
      @include background-color;
      color: #495057;
      @include border(0px, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }

      a.btnclose {
        position: absolute;
        right: 28px;
        top: 25px;

        i,
        em {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }
  }
}

// global search filter

.p-dialog-mask {
  .cls-search-popup {
    position: relative;

    .p-dialog-content {
      @include padding(left, top, right, bottom, 17, 25, 17, 25);

      .cls-title {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
        @include padding(left, top, right, bottom, 15, 0, 0, 0);
      }

      .btnclose {
        position: absolute;
        right: 28px;
      }

      hr {
        height: 1px;
        @include border(0px, solid, transparent);
        @include border-radius(1px);
        background-color: #D1D1D1;
        @include margin(left, top, right, bottom, 17, 0, 17, 16);
      }

      h3 {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 2.14;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
        @include padding(left, top, right, bottom, 15, 0, 0, 0);
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
    }
  }
}

.dialog-wrapper {
  @include padding(left, top, right, bottom, 8, 10, 8, 0);

  .header-title {
    font-size: $heading-s-medium-font-size;
    color: $utl-text-color-11;
    line-height: 1.4;
    font-weight: map-get($font-weights, "semi-bold");
  }

  .cls-spacebox {
    @include margin(left, top, right, bottom, 0, 8, 0, 30);

    .alert-text {
      font-size: $text-semimedium-size;
      font-weight: map-get($font-weights, "semi-bold");
      color: $utl-text-color-11;
      letter-spacing: 0.2px;
      display: inline-block;
    }

    .message-text {
      font-size: $heading-extra-small-font-size;
      color: $utl-text-color-11;
      @include opacity(0.7);
      line-height: 1.4;
      font-weight: map-get($font-weights, "medium");
    }

    .imp-msg {
      font-size: $heading-extra-small-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      color: $utl-text-color-11;
    }
  }

  .dialog-footer {
    @include border-pos(top, 1px, solid, rgba(0, 0, 0, 0.16));
    @include margin(left, top, right, bottom, -28, 0, -28, 0);
    @include padding(left, top, right, bottom, 32, 16, 32, 0);

    .btn-cancel {
      @include background-color(transparent);
      min-width: 94px;
      @include border(1px, solid, rgba(26, 26, 26, 0.25));
    }

    .btn-success {
      min-width: 94px;
    }
  }
}

// Attendance popup css

.p-dialog {

  &.ReportDialogpopup {
    &.customreport {
      .p-dialog-header {
        @include padding(left, top, right, bottom, 34, 18, 34, 0);
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 34, 0, 34, 8);
        overflow-y: scroll;
        min-height: 185px;
      }

      .p-dialog-footer {
        @include padding(left, top, right, bottom, 0, 15, 0, 15);
      }
    }

    .p-dialog-header {
      @include padding(left, top, right, bottom, 34, 36, 34, 0);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      display: block;
      @include border(0px, solid, transparent);

      // padding: 36px 34px 0px 34px;
      // margin-bottom: 16.72px;
      .p-dialog-header-icons {
        display: none;
      }
    }



    .p-dialog-footer {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .clsfooter {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        display: flex;
        align-items: center;

        button {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        .clsbtn-gray {
          width: 50%;
        }

        .clsbtn-green {
          width: 50%;
        }
      }
    }
  }
}

// confirmation dialogbox
.p-dialog-mask {
  .p-dialog {
    &.p-confirm-dialog {
      max-width: 410px;
      width: 100%;
      border-radius: 4px;

      .p-dialog-header {
        @include border-pos(bottom, 0px, solid, transparent);
        @include background-color(transparent);
        @include padding(left, top, right, bottom, 24, 0, 24, 0);
        height: 48px;
        display: flex;

        .p-dialog-title {
          font-family: $font-family;
          font-weight: map-get($font-weights, "regular");
          font-size: $heading-small-font-size;
          line-height: 73px;
        }

        .p-dialog-header-icons {
          display: none;
        }
      }

      .p-dialog-content {
        overflow: auto;
        position: relative;
        @include padding(left, top, right, bottom, 24, 16, 24, 16);
        @include border-pos(bottom, 0px, solid, transparent);
        @include background-color(transparent);
        align-items: flex-start;

        .p-confirm-dialog-message {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }

      .p-dialog-footer {
        @include padding(left, top, right, bottom, 20, 14, 20, 14);
        @include border-pos(top, 0px, solid, transparent);
        @include background-color(transparent);
        display: flex;
        justify-content: flex-end;

        .p-button {

          @include padding(left, top, right, bottom, 16, 8, 16, 8);

          &:nth-child(1) {
            @include margin(left, top, right, bottom, 0, 0, 8, 0);
          }
        }
      }
    }
  }
}

// RightDialog dialogbox

.RightDialog {
  &.p-dialog-mask {
    justify-content: flex-end;
    @include padding(left, top, right, bottom, 0, 0, 20, 0);

    .p-dialog {
      top: -34px;

      .p-dialog-header {
        display: block;
        @include border(0px, solid, transparent);
        @include background-color;
        @include padding(left, top, right, bottom, 0, 15, 16, 0);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .p-dialog-header-icons {
          display: block;
        }
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 16, 15, 16, 24);

        .RightDialog-footer {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          @include margin(left, top, right, bottom, 0, 24, 0, 0);

          button {
            height: 40px;
          }
        }
      }

      .p-dialog-footer {
        display: none;
      }
    }
  }
}

// salary ytd

.salaryYTD {
  &.p-dialog-mask {
    .p-dialog {
      .p-dialog-header {
        @include background-color;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border-pos(bottom, 0, dotted, transparent);
        display: block;

        .cls-pricebox {
          @include padding(left, top, right, bottom, 0, 2, 0, 2);
        }

        .p-dialog-title {
          @include border-pos(bottom, 1px, dotted, #ccc);
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 25, 15, 25, 0);
          display: block;
        }
      }

      .p-dialog-header-icons {
        display: none;
      }

      .p-dialog-content {
        min-height: 200px;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

      }

      .p-dialog-footer {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border(0, dotted, transparent);
        text-align: center;
        min-height: auto;

        .clsbottom-btn {
          display: block;
          @include padding(left, top, right, bottom, 18, 18, 18, 18);
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          @include background-color(transparent);
          @include border(0, solid, transparent);
          text-align: center;
          width: 100%;
          @include shadow($level: 23);
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "medium");
          text-transform: uppercase;
          position: relative;
          height: 56px;
          cursor: pointer;
        }
      }
    }
  }
}

//*************** cls-carouseldilogbox **************

.cls-carouseldilogbox {
  &.p-dialog-mask {
    .p-dialog {
      .p-dialog-header {
        display: block;
        @include padding(left, top, right, bottom, 32, 24, 32, 0);
        @include background-color;
        @include border-pos(bottom, 0, solid, transparent);

        .cls-heading {
          font-size: 20px;
          font-weight: map-get($font-weights, "semi-bold");
          color: $utl-text-color-11;
          display: inline-block;

          &.date {
            @include color($utl-text-color-1);
            font-size: $heading-20-size;
          }
        }

        .cls-reportCode {
          @include border-radius(3px);
          // @include background-color($utl-grey-color-6);
          font-family: $font-family;
          font-size: $text-midum-small-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.33;
          color: $utl-white;
          @include padding(left, top, right, bottom, 8, 2, 8, 2);
          @include margin(left, top, right, bottom, 16, 0, 0, 0);

          &.newdes {
            @include border-radius(4px);
            // @include background-color(#707789);
            height: 24px;
            font-size: $text-name;
            line-height: 20px;
          }
        }

        .cls-exceptional {
          @include border-radius;
          @include background-color($utl-red-24);
          font-family: $font-family;
          font-size: $text-midum-small-size;
          font-weight: map-get($font-weights, "medium");
          color: $utl-white;
          min-width: 76px;
          // height: 16px;
          @include padding(left, top, right, bottom, 5, 1, 5, 1);
          @include margin(left, top, right, bottom, 10, 0, 0, 0);
          display: inline-block;
          text-align: center;
          line-height: 16px;
          vertical-align: text-top;
          height: 24px;
          line-height: 22px;
        }

        .btnclose {
          float: right;

          .material-icons {
            font-size: $heading-extra-font-size;
          }
        }
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        overflow: hidden;

        .cls-carousel {
          perfect-scrollbar {
            @include padding(left, top, right, bottom, 32, 0, 32, 24);
          }
        }
      }

      p-footer {
        @include padding(left, top, right, bottom, 32, 24, 32, 24);
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
      }

      .p-dialog-footer {
        display: none;
      }
    }
  }
}

// SALARY DIAOLOGBOX
.cls-salarypopup {
  &.p-dialog-mask {
    .p-dialog {
      .p-dialog-header {
        display: block;
        @include padding(left, top, right, bottom, 32, 24, 32, 0);
        @include background-color;
        @include border-pos(bottom, 0, solid, transparent);

        .pending-review-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;

          h3 {
            color: $secondary-text-color;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "bold");
          }
        }
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 32, 0, 32, 24);
        overflow: hidden;

        .cls-gnltext {
          font-size: $heading-extra-small-font-size;
          line-height: 1.75;
          display: block;
          color: rgba(75, 84, 110, 0.7);
          @include margin-em(left, top, right, bottom, 0, 1.071, 0, 1.428);
          font-weight: map-get($font-weights, "medium");
        }

        .cls-disclamer {
          list-style: disc;
          @include padding-em(left, top, right, bottom, 1.571, 0, 0, 0);
          @include margin-em(left, top, right, bottom, 0, 1.071, 0, 0);

          li {
            font-size: $heading-extra-small-font-size;
            font-weight: map-get($font-weights, "medium");
            color: $utl-text-color-11;
            line-height: 1.75;
          }
        }
      }
    }
  }
}

// clearance dialogpopup
.cls-clearancepopup {
  &.p-dialog-mask {
    .p-dialog {
      overflow: visible;

      .p-dialog-header {
        display: block;
        @include padding(left, top, right, bottom, 32, 24, 32, 0);
        @include background-color;
        @include border-pos(bottom, 0, solid, transparent);

        .pending-review-wrapper {
          display: flex;
          justify-content: space-between;
          width: 100%;
          font-size: 14px;

          h3 {
            color: $secondary-text-color;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "bold");
          }
        }
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 32, 0, 32, 24);
        overflow: visible;


        .cls-gnltext {
          font-size: $heading-extra-small-font-size;
          line-height: 1.75;
          display: block;
          color: rgba(75, 84, 110, 0.7);
          @include margin-em(left, top, right, bottom, 0, 1.071, 0, 1.428);
          font-weight: map-get($font-weights, "medium");
        }

        .cls-disclamer {
          list-style: disc;
          @include padding-em(left, top, right, bottom, 1.571, 0, 0, 0);
          @include margin-em(left, top, right, bottom, 0, 1.071, 0, 0);

          li {
            font-size: $heading-extra-small-font-size;
            font-weight: map-get($font-weights, "medium");
            color: $utl-text-color-11;
            line-height: 1.75;
          }
        }
      }
    }
  }
}

// bulk process popup
.bulkpopup-conf {
  &.p-dialog-mask {
    .p-dialog {
      max-width: 600px;
      min-height: 256px;
      width: 100%;

      .p-dialog-content {
        @include padding-em(left, top, right, bottom, 1.5, 1, 1.5, 1);
        overflow: hidden;
      }
    }
  }
}

// cls-quickhack

.cls-quickhack {
  &.p-dialog-mask {
    .p-dialog {
      max-width: 600px;
      min-height: 256px;
      width: 100%;

      .p-dialog-header {
        display: block;

        .cls-headtitle {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
        }
      }

      .p-dialog-content {
        @include padding-em(left, top, right, bottom, 1.5, 1, 1.5, 1);
        overflow: hidden;
      }
    }
  }
}

// cls-quickhack

.cls-quickhack {
  &.p-dialog-mask {
    .p-dialog {
      max-width: 600px;
      min-height: 256px;
      width: 100%;

      .p-dialog-header {
        display: block;

        .cls-headtitle {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
        }
      }

      .p-dialog-content {
        @include padding-em(left, top, right, bottom, 1.5, 1, 1.5, 1);
        overflow: hidden;
      }
    }
  }
}

// reminder popup css
.Reminderpopup {
  &.p-dialog-mask {
    .p-dialog {
      max-width: 450px;
      min-height: 256px;
      width: 100%;

      .p-dialog-header {
        display: block;
        @include padding(left, top, right, bottom, 40, 40, 40, 0);
        @include background-color;
        @include border-pos(bottom, 0, solid, transparent);
        font-size: initial;

        .p-dialog-title {
          font-size: $heading-small-font-size;
          font-weight: map-get($font-weights, "bold");
        }

        .p-dialog-header-icons {
          display: none;
        }
      }

      .p-dialog-content {
        @include padding(left, top, right, bottom, 40, 20, 40, 40);
        overflow: hidden;
      }
    }
  }
}

// letter dialog box

.viewdilog.avatar-preview {
  @include padding-em(left, top, right, bottom, 0, 0, 0, 0);

  &.p-dialog-mask {
    .p-dialog {
      max-width: 100%;
      max-height: 100%;

      width: 100%;

      .p-dialog-content {
        @include padding-em(left, top, right, bottom, 0, 0, 0, 10);
        overflow: hidden;
      }
    }
  }

  .btnclose {
    right: 32px;

    i,
    em {
      font-size: 24px;
    }
  }
}

/************ QR-Code box css ***************/

.QR_code {
  .p-dialog {
    @include background-color($utl-text-color-11);
    @include border(1px, solid, $utl-text-color-11);

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 18, 12, 18, 0);
      @include background-color($utl-text-color-11);
      @include border-pos(bottom, 0, solid, transparent);
      font-size: initial;

      .p-dialog-title {
        font-size: $heading-small-font-size;
        font-weight: map-get($font-weights, "bold");
      }

      .p-dialog-header-icons {
        display: none;
      }

      .btnclose {
        float: right;
        color: $utl-white;
      }
    }

    .p-dialog-content {
      @include padding-em(left, top, right, bottom, 1.5, 0, 1.5, 2);
      @include background-color($utl-text-color-11);
      overflow: hidden;
    }
  }
}

// expense dialog box
.cls-expdilogbox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      @include padding(left, top, right, bottom, 32, 24, 32, 8);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);
      display: flex;

      .p-dialog-title {
        font-size: $heading-small-font-size;
        font-weight: map-get($font-weights, "bold");
      }

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      overflow: hidden;
      min-height: 100%;

      .holds-the-iframe {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.43;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }
    }
  }
}

/************* small dialog box ***************/

.small-dilog {
  .p-dialog {
    min-width: 200px;
    max-width: 685px;
    @include border-radius(6px);

    .p-dialog-titlebar {
      display: none;
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 35, 25, 35, 25);
      text-align: center;
      overflow: hidden;
      min-height: 100%;

      .header-title {
        font-size: $heading-large-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        color: $utl-text-color-11;
        line-height: 1.75;
      }

      .message-text {
        font-size: $heading-small-font-size;
        font-weight: map-get($font-weights, "medium");
        color: rgba(26, 35, 53, 0.8);
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .alert-text {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "medium");
        color: #ec3976;
      }
    }
  }
}

// preview dialog box

.previewdilog {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;

      .btnclose {
        position: absolute;
        right: 24px;
        top: 24px;
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 35, 15, 35, 24);
      text-align: center;
      overflow: auto;
      min-height: 100%;
    }
  }
}

// helpdesk confirm box
.helpdesk-confbox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      .btnclose {
        position: absolute;
        right: 32px;
        top: 32px;
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      overflow: auto;
      min-height: 100%;
    }
  }
}

.Canned-confbox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      .btnclose {
        position: absolute;
        right: 32px;
        top: 32px;
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      overflow: auto;
      min-height: 100%;
    }

    .cls-btnbox {
      @include border-pos(top, 1px, solid, #c8c8c8);
      @include padding(left, top, right, bottom, 26, 20, 26, 5);
      @include margin(left, top, right, bottom, -32, 15, -32, 0);
    }

    .p-dialog-footer {
      display: none;
    }
  }
}

//settlement popup
.cls-dilogbox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;

      .header-title {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "bold");
        line-height: 1.5;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      // .p-dialog-title {
      //   width: 100%;
      // }
    }
  }
}

.cls-cfs {
  .p-dialog {
    .p-dialog-header {
      display: flex;

      .p-dialog-title {
        width: 100%;

        p-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .header-title {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            color: $utl-text-color-11;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          .btnclose {

            i,
            em {
              font-size: 30px;
            }
          }
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 0, 32, 33);

      .dialog-content {
        .cls-subtxt {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.43;
          @include padding(left, top, right, bottom, 0, 24, 0, 18);
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        .cls-processbox {
          @include border-radius;
          @include border(1px, solid, $utl-grey-color-32);
          @include background-color;
          @include margin(left, top, right, bottom, 0, 56, 0, 7);
          @include padding(left, top, right, bottom, 24, 32, 24, 24);
          min-height: 96px;
          text-align: center;

          h3 {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "regular");
            color: $utl-text-color-7;
          }

          .cls-prsbar {
            .ui-progressbar {
              @include background-color(rgba(0, 191, 165, 0.25));
              @include border(0px, solid, transparent);

              .ui-progressbar-value {
                @include background-color($utl-theme-color);
              }
            }
          }
        }

        .cls-empcont {
          .mat-mdc-radio-group {
            .mat-mdc-radio-button {
              @include margin(left, top, right, bottom, 0, 0, 45, 0);

              /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
              .mat-radio-label-content {
                @extend .cls-subtxt;
                font-weight: map-get($font-weights, "regular");
                color: $utl-text-color-12;
                @include padding(left, top, right, bottom, 8, 0, 0, 0);
              }
            }
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .btn-cancel {
        @include background-color(transparent);
        min-width: 94px;
        @include border(1px, solid, rgba(26, 26, 26, 0.25));
      }

      .btn-cancel:hover,
      .btn-cancel.disabled:hover {
        box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12),
          0 8px 10px -5px rgba(92, 92, 92, 0.2);
        @include background-color(transparent);
        @include border(1px, solid, rgba(26, 26, 26, 0.25));
      }
    }
  }
}

.cls-salary-structure {
  .hrone-dialog {
    .p-dialog {
      width: 533px;
    }
  }
}

// letter generation popup

.cls-letter {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 54, 46, 54, 36);

      .btnclose {
        position: absolute;
        right: 50px;
        top: 38px;

        i,
        em {
          font-size: 36px;
        }
      }
    }

    .p-dialog-content {
      overflow: auto;
      min-height: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      perfect-scrollbar {
        @include padding(left, top, right, bottom, 54, 0, 54, 16);
      }
    }

    .p-dialog-footer {
      display: none;
    }
  }
}

/************ survey dialog box *************/
.cls-survey {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: flex;
      @include padding(left, top, right, bottom, 0, 28, 0, 0);

      .p-dialog-title {
        font-size: inherit;

        .cls-surveytitle {
          font-family: $font-family;
          font-size: $heading-large-font-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.5;
          letter-spacing: -0.1px;
          color: $utl-text-color-1;
          @include margin(left, top, right, bottom, 0, 0, 0, 24);
          @include padding(left, top, right, bottom, 70, 0, 70, 0);

          span {
            @extend .cls-surveybody;
            display: block;
          }
        }

        .btnclose {
          position: absolute;
          right: 28px;
          top: 28px;

          i,
          em {
            font-size: 30px;
          }
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 70, 28, 70, 32);
      overflow: auto;
      min-height: 100%;

      .cls-surveybody {
        .cls-surveyshowbox {
          min-height: 114px;
          @include padding(left, top, right, bottom, 16, 16, 16, 16);
          @include border-radius(3px);
          @include background-color(rgba(24, 33, 41, 0.06));

          img {
            width: 46px;
            @include margin(left, top, right, bottom, 0, 0, 17, 0);
          }

          h3 {
            font-family: $font-family;
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            color: $utl-text-color-2;
            @include margin(left, top, right, bottom, 0, 0, 0, 4);
          }

          .cls-txt {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "regular");
            color: $utl-text-color-2;
            line-height: 1.67;
            white-space: break-spaces;
          }

          a {
            @extend .cls-txt;
            font-weight: map-get($font-weights, "medium");
            color: $utl-theme-color;
            display: block;
            text-decoration: underline;
          }
        }

        .cls-showmoretxt {
          @extend .cls-surveyshowbox;
          height: auto;
          background-color: rgba(24, 33, 41, 0.03);
        }
      }
    }
  }

  .p-dialog-footer {
    @include padding(left, top, right, bottom, 70, 26, 70, 26);
    text-align: center;
    @include border-pos(top, 0px, solid, transparent);
  }
}

.cls-search-popup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 17, 25, 17, 25);

      .cls-title {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
        @include padding(left, top, right, bottom, 15, 0, 0, 0);
      }

      .btnclose {
        position: absolute;
        right: 28px;
      }

      hr {
        height: 1px;
        @include border(0px, solid, transparent);
        @include border-radius(1px);
        background-color: #D1D1D1;
        @include margin(left, top, right, bottom, 17, 0, 17, 16);
      }

      h3 {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 2.14;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
        @include padding(left, top, right, bottom, 15, 0, 0, 0);
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
    }
  }
}

// setting payrol popup
.biometric-popup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 21, 21, 40, 24);
      min-height: 100%;
      @include background-color(#4b546e);
      @include border(1px, solid, #4b546e);

      .heading-text {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        color: $utl-white;
        @include padding(left, top, right, bottom, 11, 0, 0, 0);
      }

      .cls-comtext {
        font-family: $font-family;
        font-size: $heading-extra-small-font-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.75;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

// salary slip dialogbox

.max-width-dialog {
  .p-dialog {
    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .credit-transfer-wrapper {
        .dialog-header {
          h3 {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            @include margin(left, top, right, bottom, 0, 0, 0, 48);
          }
        }
      }
    }
  }
}

// salary slip dialogbox

.max-width-dialog {
  .p-dialog {
    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .credit-transfer-wrapper {
        .dialog-header {
          h3 {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            @include margin(left, top, right, bottom, 0, 0, 0, 48);
          }
        }
      }
    }
  }
}

//settlementpopup

.settlementpopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: flex;
    }
  }
}

.crsfix {
  .p-dialog {
    .p-dialog-header {
      display: flex !important;

      .p-dialog-title {
        width: 100%;
      }
    }
  }
}

//Chat dialogbox

.FullDialog {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;
    max-width: 800px;
    width: 100%;

    .p-dialog-header {
      display: none;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      min-height: 100%;
      overflow: hidden;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);
    }
  }
}

// rating popup
.rate-popup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;
    max-width: 800px;
    width: 100%;

    .p-dialog-header {
      display: none;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      min-height: 100%;
      overflow: hidden;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .btnclose {
        top: 15px;
      }
    }
  }
}

.p-dialog {
  .p-dialog-content {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #eee;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 6px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.2);
      opacity: 0.9;
    }
  }
}

//attandence popup
.AttDialogpopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      min-height: 100%;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      p-footer {
        padding: 0;
        display: flex;

        button {
          height: 40px;
          width: 50%;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }
    }
  }
}

//****************** Leave popup ****************

.cls-leavepopup {
  @include border-radius(2px);
  @include border(0px, solid, transparent);
  outline: 0;
  @include padding(left, top, right, bottom, 0, 13, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 0);
  left: 6%;
  position: absolute;
  top: 20%;
  z-index: 9;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);

  .p-dialog-mask {
    position: initial;

    .p-dialog {
      overflow: visible;

      .p-dialog-titlebar {
        display: none;
      }

      &:after,
      &:before {
        bottom: 100%;
        right: 2%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
      }

      &:after {
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #ffffff;
        border-width: 9px;
        margin-left: -9px;
      }

      &:before {
        border-color: rgba(194, 225, 245, 0);
        border-bottom-color: #ece7e7;
        border-width: 10px;
        margin-left: -10px;
      }

      .p-dialog-content {
        overflow: visible;
        @include padding(left, top, right, bottom, 24, 24, 24, 34);

        h5 {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 2.14;
          color: $utl-text-color-11;
        }
      }

      .cls-exp-calaender {
        width: 100%;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include margin(left, top, right, bottom, 0, 9, 0, 0);
        display: block;

        .p-calendar {
          width: 100%;

          .p-inputtext {
            font-size: $text-default-size;
            width: calc(100% - 38px);
            color: $primary-text-color;
            font-weight: map-get($font-weights, "medium");
          }

          .time-selection {
            label {
              margin: 0;
              font-size: 11px;
            }

            p-calendar {
              input {
                width: 100%;
                @include background-color(transparent);
                @include border-radius(0);
                padding: 0px 0 3px !important;
                border-bottom: 1px solid #e9edf2;
              }
            }
          }

          .p-calendar-button {
            background: transparent;
            border-bottom: 1px solid #e9edf2 !important;

            &:hover {
              background: transparent;
            }

            .p-button-text {
              padding: 0.5em;
            }
          }
        }

        .p-datepicker {
          .p-datepicker-group {
            .p-datepicker-header {
              background: transparent;
            }

            .p-datepicker-title {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "semi-bold");
            }

            .p-datepicker-calendar-container {
              .p-datepicker-calendar {
                thead {
                  tr {
                    th {
                      @include background-color($utl-grey-color-5);
                      font-family: $font-family;
                      font-size: $text-medium-size;
                      font-weight: map-get($font-weights, "semi-bold");
                    }
                  }
                }

                tbody {
                  tr {
                    td {
                      font-family: $font-family;
                      font-size: $text-size-11;
                      font-weight: map-get($font-weights, "medium");

                      a {
                        &:hover {
                          @include background-color($utl-grey-color-5);
                          color: $utl-text-color-14;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .cls-search-btn {
        height: 40px;
        @include border-radius;
        @include background-color($utl-theme-color);
        @include border(1px, solid, $utl-theme-color);
        @include padding(left, top, right, bottom, 15, 0, 15, 0);
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        text-align: center;
        color: $utl-white;
        cursor: pointer;
      }

      .cls-reset-btn {
        height: 40px;
        @include border-radius;
        @include background-color;
        @include border(1px, solid, $primary-text-color);
        @include padding(left, top, right, bottom, 15, 0, 15, 0);
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        text-align: center;
        color: $primary-text-color;
        cursor: pointer;
      }
    }
  }
}


//****************** dashboardleave popup ****************
.team-leave-comp {
  p-dialog {
    @extend .cls-leavepopup;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    top: 71%;
    left: 0;
  }
}

.cls-dashboardleave {
  @extend .cls-leavepopup;
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  top: 71%;
  left: 7%;
}



// attandence popup css
.statuspopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      h3 {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .btnclose {
        i {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      min-height: 140px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 5, 32, 24);

      .cls-contxt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: #28314b;
        @include margin(left, top, right, bottom, 0, 0, 0, 28);
      }
    }

    .p-dialog-footer {
      p-footer {
        button {
          height: 40px;
          width: 30%;
        }
      }
    }
  }
}

//enterprise wall popup

.wish-dialog {
  .p-dialog {
    .p-dialog-header {
      display: block;
      min-height: 50px;
      @include border(0px, solid, transparent);

      .p-dialog-title {
        width: 100%;

        p-header {
          h3 {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }
        }
      }

      .p-dialog-header-icons {
        display: none;
      }

      a.btnclose {
        position: absolute;
        right: 28px;
        top: 25px;

        i,
        em {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }

    .p-dialog-content {
      min-height: 140px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      .cls-content {
        .cls-contxt {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.71;
          @include margin(left, top, right, bottom, 0, 0, 0, 10);
        }

        .cls-pastxt {
          font-size: $heading-extra-small-font-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.75;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 13, 0, 16);
        }

        .cls-eye {
          position: absolute;
          right: 0;
          top: 12px;
          z-index: 99;
          cursor: pointer;
          height: 21px;
          width: 21px;
        }
      }

      .cls-subtxt {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.43;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 16, 0, 6);
      }

      .cls-ntc {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        @include padding(left, top, right, bottom, 16, 0, 0, 0);
        list-style: disc;

        li {
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 2;
          color: #42495a;
        }
      }
    }
  }
}

//increment-dialog
.increment-dialog {
  .p-dialog {
    overflow: visible;

    .p-dialog-header {
      display: block;
      min-height: 50px;
      @include background-color;
      color: #495057;
      @include border(0px, solid, transparent);

      .p-dialog-title {
        width: 100%;

        p-header {
          h3 {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            color: $utl-text-color-11;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          p {
            font-family: $font-family;
            font-size: $heading-extra-small-font-size;
            font-weight: map-get($font-weights, "medium");
            line-height: 1.75;
            color: rgba(75, 84, 110, 0.7);
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }
        }
      }

      .p-dialog-header-icons {
        display: none;
      }

      a.btnclose {
        position: absolute;
        right: 28px;
        top: 25px;
        display: none;

        i,
        em {
          font-size: 30px;
          font-weight: 500;
        }
      }
    }

    .p-dialog-content {
      min-height: 100px;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .cls-content {
        .cls-contxt {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.71;
          color: #28314b;
          @include margin(left, top, right, bottom, 0, 0, 0, 10);
        }
      }
    }
  }
}

// dounet popup design

.widget-dialog {
  &.p-dialog-mask {
    .p-dialog-header {
      display: block;

      .p-dialog-title {
        p-header {
          h3 {
            font-family: $font-family;
            font-size: $heading-s-medium-font-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            color: $utl-text-color-11;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          .btnclose {
            .material-icons {
              font-size: $heading-large-font-size;
              font-weight: map-get($font-weights, "semi-bold");
            }
          }
        }
      }
    }

    .p-dialog-content {
      min-height: 100px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 18, 32, 24);
    }
  }
}

// success-dialog

.success-dialog {
  .p-dialog {
    @include border-radius;
    @include shadow($level: 19);
    @include background-color;
    position: relative;

    .p-dialog-content {
      height: 100%;
      overflow: hidden;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      .cls-suscontent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;

        img {
          width: 126px;
          @include margin(left, top, right, bottom, 0, 5, 0, 0);
        }

        h4 {
          font-family: $font-family;
          font-size: $heading-extra-small-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.75;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 40, 0, 0);
        }
      }
    }
  }
}

//Licence-popup

.cls-Licence-popup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;

      .cls-title {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 0, 0, 8);
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
      }

      .btnclose {
        position: absolute;
        right: 28px;

        .material-icons {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 0, 32, 24);
      min-height: auto;

      .cls-subtxt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: $primary-text-color;
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
    }
  }
}

// product tour popup css
.hrpost_one {
  .p-dialog {
    position: absolute;
    overflow: visible;
    left: 15%;
    top: 35%;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid #1b2436;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }

    .p-dialog-content {
      @include background-color($secondary-text-color);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .row_header {
        @include padding(left, top, right, bottom, 15, 16, 15, 14);

        .btnclose {
          @include abs-pos($top: 15px, $right: 16px, $bottom: initial, $left: initial);

          i,
          em {
            font-size: 16px;
            color: $utl-white;
          }
        }

        .cls-heading {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "semi-bold");
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          line-height: 1.14;
          color: $utl-white;
        }
      }

      .row_subheader {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "semi-bold");
        @include padding(left, top, right, bottom, 15, 0, 15, 16);
        line-height: 1.33;
        color: #b5bbc5;

        span {
          color: $utl-theme-color;
        }
      }

      .row_body {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "regular");
        @include padding(left, top, right, bottom, 15, 0, 15, 24);
        line-height: 1.33;
        color: #b5bbc5;
      }

      .row_footer {
        @include padding(left, top, right, bottom, 15, 0, 15, 18);
        display: flex;

        .cls-prevbtn {
          height: 40px;
          width: 172px;
          @include margin(left, top, right, bottom, 0, 0, 8, 0);
        }

        .cls-nextbtn {
          height: 40px;
          width: 172px;
          @include margin(left, top, right, bottom, 8, 0, 0, 0);
        }
      }

      .cls-subfooter {
        height: 37px;
        @include background-color(#212b3f);
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include padding(left, top, right, bottom, 15, 0, 15, 0);

        .cls-nrmtxt {
          font-family: $font-family;
          font-size: $text-small-size;
          font-weight: map-get($font-weights, "regular");
          color: $utl-white;
          @include margin(left, top, right, bottom, 0, 0, 10, 0);
        }

        a {
          @extend .cls-nrmtxt;
          color: $utl-theme-color;
          font-weight: map-get($font-weights, "bold");
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }
  }
}

.home {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: auto;
    top: 9.5%;
    left: 7%;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: auto;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid #1b2436;
      left: -20px;
      top: 10%;
    }
  }
}

.hrpost_two {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 35.5%;
    top: 4%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #1b2436;
      border-right: 10px solid transparent;
      left: auto;
      top: 10%;
    }
  }
}

.hrpost_three {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 4%;
    top: 52%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid #1b2436;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
}

.hrbadge_one {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    top: 38%;
    left: 18%;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid #1b2436;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
}

.hrbadge_two {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 35.5%;
    top: 4%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #1b2436;
      border-right: 10px solid transparent;
      left: auto;
      top: 10%;
    }
  }
}

.hrreward_one {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    top: 38%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 50%;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid #1b2436;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
    }
  }
}

.hrreward_two {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 35.5%;
    top: 4%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #1b2436;
      border-right: 10px solid transparent;
      left: auto;
      top: 10%;
    }
  }
}

.attendence_one {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 36%;
    top: 31%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #1b2436;
      border-right: 10px solid transparent;
      left: auto;
      top: 10%;
    }
  }
}

.attendence_two {
  @extend .hrpost_one;

  .p-dialog {
    position: absolute;
    overflow: visible;
    right: 36%;
    top: 58%;
    left: auto;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -20px;
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #1b2436;
      border-right: 10px solid transparent;
      left: auto;
      top: 10%;
    }
  }
}

//  prediction

.cls-predic {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 9);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      h3 {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        color: $utl-text-color-11;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .btnclose {

        i,
        em {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }

      .p-dialog-header-icons {
        display: none;
      }
    }

    .p-dialog-content {
      min-height: 140px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 5, 32, 24);

      .dialog-wrapper {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        .cls-overdue {
          font-family: $font-family;
          font-size: $heading-small-font-size;
          font-weight: map-get($font-weights, "medium");
          line-height: 1.5;
          color: $utl-secondary-red;
          @include margin(left, top, right, bottom, 0, 0, 0, 8);

          img {
            width: 24px;
          }
        }

        .header-title1 {
          font-family: $font-family;
          font-size: $heading-extra-small-font-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.75;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        .header-title {
          font-family: $font-family;
          font-size: $heading-extra-small-font-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.75;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 30, 0, 0, 0);
        }

        .cls-contxt {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.71;
          color: #28314b;
          @include margin(left, top, right, bottom, 0, 0, 0, 28);

          .border-box {
            border: 1px solid #d7d7d7;
          }
        }
      }
    }

    .p-dialog-footer {
      display: none;

      p-footer {
        button {
          height: 40px;
          width: 30%;
        }
      }
    }
  }
}

// delegate popup css
.delegatepopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }

      .p-dialog-title {
        h4 {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        .btnclose {

          i,
          em {
            font-weight: map-get($font-weights, "semi-bold");
            font-size: 30px;
          }
        }
      }
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 16, 32, 24);

      .cls-txt {
        font-size: $text-default-size;
        color: $utl-grey-color-6;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.43;
        @include margin(left, top, right, bottom, 0, 0, 0, 32);
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

// sub popup
.cls-hrbala {
  border: none;
  box-shadow: none;
  overflow: visible;

  .p-dialog {
    overflow: visible;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      min-height: 100%;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .dialog-wrapper {
        @include padding(left, top, right, bottom, 32, 24, 32, 0);

        h2 {
          font-size: $heading-s-medium-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "semi-bold");
        }

        p {
          font-size: $heading-extra-small-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "medium");
        }

        .message-text {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-grey-color-6;
        }

        .btnclose {
          @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

          i,
          em {
            font-size: 30px;
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

// dialog cls-tips css

.cls-tips {
  .p-dialog {
    max-height: 90% !important;
  }

  .p-dialog-content {
    min-height: 640px !important;
    overflow: hidden;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);

    .dialog-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .dialog-content {
        p-carousel {
          .p-carousel {
            .p-carousel-content {
              .p-carousel-container {
                .p-carousel-prev {
                  left: -5px;
                }

                .p-carousel-next {
                  right: -5px;
                }

                .p-carousel-items-content {
                  @include margin(left, top, right, bottom, 0, 0, 0, 0);

                  .p-carousel-items-container {
                    .p-carousel-item {
                      height: 640px;
                      @include padding(left, top, right, bottom, 0, 0, 0, 0);

                      .cls-frbox {
                        width: 496px;

                        .cls-subtxt {
                          font-family: $font-family;
                          font-size: $text-medium-size;
                          font-weight: map-get($font-weights, "regular");
                          line-height: 1.33;
                          color: rgba(59, 63, 80, 60%);
                          @include margin(left, top, right, bottom, 32, 0, 32, 32);
                        }

                        .cls-subsectxt {
                          @extend .cls-subtxt;
                          font-size: $text-default-size;
                          line-height: 1.71;
                          color: #3b3f50;
                          @include margin(left, top, right, bottom, 32, 0, 32, 0);
                        }
                      }

                      .cls-secbox {
                        @include padding(left, top, right, bottom, 32, 156, 32, 156);
                        width: 640px;
                        @include background-color(rgba(0, 191, 165, 0.1));
                        height: 100vh;

                        .imgcol {
                          max-width: 600px;
                          width: 100%;

                          img {
                            width: 100%;
                            max-width: 600px;
                            min-width: 580px;
                            object-fit: fill;
                            border-radius: 8.5px;
                            box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//css acknowledge

.cls-ack {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;

      .header-title {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        color: $utl-text-color-11;
      }

      a {
        position: absolute;
        right: 28px;

        .material-icons {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 0, 32, 24);
      min-height: auto;

      .cls-contenttxt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.45;
        color: #6f778b;
        @include margin(left, top, right, bottom, 0, 16, 0, 10);
      }

      h5.cls-txt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.45;
        color: #6f778b;
        @include margin(left, top, right, bottom, 0, 16, 0, 10);
      }

      .cls-sign {
        text-align: center;
        @include margin(left, top, right, bottom, 0, 28, 0, 0);

        .cls-signature {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");

          color: $utl-grey-color-6;
          line-height: 1.5;
          @include margin(left, top, right, bottom, 0, 0, 0, 6);
        }

        .cls-signtxt {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-grey-color-6;
          @include margin(left, top, right, bottom, 0, 0, 0, 6);
        }

        .cls-anch {
          font-family: $font-family;
          font-size: $text-size-11;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-theme-color;
          text-decoration: underline;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
    }
  }
}

.video {
  .p-dialog-content {
    min-height: 378px !important;
    overflow: hidden;

    .btnclose {
      top: 13px;
    }
  }
}

// add punch dialog

.cls-addpnh {
  border: none;
  box-shadow: none;
  overflow: hidden;

  &.height-auto {
    .p-dialog-content {
      min-height: auto !important;
    }
  }

  .p-dialog {
    .p-dialog-header {
      display: block;

      h2 {
        font-size: $heading-s-medium-font-size;
        color: $utl-text-color-11;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }
    }

    .p-dialog-content {
      min-height: 300px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);

      .cls-contxt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: #28314b;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .cls-contxt_remarks {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: #28314b;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .cls-datebox {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        color: $utl-text-color-11;
        line-height: 1.43;
        display: inline-block;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }
    }
  }
}

.cls-intimatebox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }

      h2 {
        font-weight: map-get($font-weights, "semi-bold");
      }
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      p {
        font-size: $heading-extra-small-font-size;
        color: $utl-text-color-11;
        font-weight: map-get($font-weights, "medium");
      }

      img {
        width: 150px;
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      button {
        height: 40px;
        @include margin(left, top, right, bottom, 16, 0, 0, 0);
      }
    }
  }
}

//css helpdeskpopup
.cls-helpdeskpopup {
  border: none;
  box-shadow: none;
  overflow: visible;

  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;
    width: 433px;

    .p-dialog-header {
      display: none;
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .dialog-wrapper {
        @include padding(left, top, right, bottom, 32, 24, 32, 0);

        h2 {
          font-size: $heading-s-medium-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "semi-bold");
        }

        p {
          font-size: $heading-extra-small-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "medium");
        }

        .message-text {
          font-size: $text-default-size;
          font-weight: map-get($font-weights, "regular");
          line-height: 1.43;
          color: $utl-grey-color-6;
        }

        .btnclose {
          @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

          i,
          em {
            font-size: 30px;
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

.task-details {
  height: calc(100% - 0px);

  .p-dialog {
    max-height: 100%;
    border-radius: 0;
  }

  .p-dialog-content {
    min-height: 100vh;
    padding: 0;
    overflow-y: scroll !important;
    overflow: hidden;
  }
}

.task-details-new {
  height: calc(100% - 0px);

  .p-l-60 {
    padding-left: 55px !important;
  }

  .headersec {
    .innersec:nth-child(1n + 1) {
      padding-left: 28px !important;
    }
  }

  .innersec {
    padding-left: 28px !important;
  }

  .w-11 {
    width: 11% !important;
  }

  .tagcol {
    margin-left: 12px;
  }

  .p-dialog {
    max-height: 100%;
    border-radius: 0;

    .btnclose {
      top: 10px;
      right: 29px;
      height: 36px;
      width: 36px;
      text-align: center;
      border-radius: 50%;
      padding-top: 6px;

      i,
      em {
        font-size: 24px;
      }

      &:hover {
        border-color: #f1f1f1;
        background: #f1f1f1;
        color: #212121;
      }
    }
  }

  .p-dialog-content {
    padding: 0;
    overflow-y: scroll !important;
    overflow: hidden;
    margin: 24px 0px;
  }

}

// completed popup css

.cls-completed {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;
    text-align: center;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 50, 32, 10);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }

      h2 {
        font-size: $heading-small-font-size-30;
        font-weight: map-get($font-weights , 'bold');
      }
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 60);

      p {
        font-size: $text-default-size;
        color: $utl-text-color-11;
        font-weight: map-get($font-weights, "regular");
        @include margin(left, top, right, bottom, 0, 0, 0, 36);
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);


      button {
        height: 40px;
        @include margin(left, top, right, bottom, 16, 0, 0, 0);
      }

    }
  }
}

.task-details {
  height: calc(100% - 0px);

  .p-dialog {
    max-height: 100%;
    border-radius: 0;
  }

  .p-dialog-content {
    min-height: 100vh;
    padding: 0;
    overflow-y: scroll !important;
    overflow: hidden;
  }
}

// PII popup css
.cls-PIIpopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;

    .p-dialog-header {
      display: block;

      .p-dialog-title {
        font-size: inherit;

        .cls-heading {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
          @include border-pos(bottom, 8px, solid, #f5f7fa);
          @include padding(left, top, right, bottom, 0, 0, 0, 16);
        }
      }

      a {
        position: absolute;
        right: 28px;

        .material-icons {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
      min-height: auto;

      .cls-lbltxt {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.67;
        color: #6f778b;
        @include margin(left, top, right, bottom, 0, 0, 0, 3);
        display: block;
      }

      .cls-txt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "medium");
        line-height: 1.45;
        color: #262e3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        border: 0;
      }

      .cls-dic {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "regular");
        color: #272c3e;

        a {
          font-weight: map-get($font-weights, "medium");
          text-decoration: underline;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);
    }
  }
}

// alert popup
.cls-alert {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: hidden;


    .p-dialog-header {
      display: block;

      .p-dialog-title {
        font-size: inherit;

        .heading {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          color: $utl-text-color-11;
          @include border-pos(bottom, 8px, solid, #f5f7fa);
          @include padding(left, top, right, bottom, 0, 0, 0, 16);
        }

        .cls-heading {
          @include padding(left, top, right, bottom, 8, 6, 8, 6);
          @include border-radius;
          @include background-color(#c73232);
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "bold");
          line-height: 1.5;
          color: $utl-white;
          display: inline-flex;
          align-items: center;
          // @include border-pos(bottom, 8px, solid, #f5f7fa);
          // @include padding(left, top, right, bottom, 0, 0, 0, 0);

          .material-icons {
            vertical-align: middle;
            font-size: 14px;
            margin-right: 5px;
          }
        }

        h2 {
          font-size: $heading-s-medium-font-size;
          color: $utl-text-color-11;
          font-weight: map-get($font-weights, "semi-bold");
          line-height: 1.5;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }

        p {
          font-size: $text-default-size;
          font-weight: 300;
        }
      }

      a {
        position: absolute;
        right: 28px;

        .material-icons {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 16, 32, 24);
      min-height: auto;

      p {
        font-size: $heading-extra-small-font-size;
        color: #4d546c;
        font-weight: map-get($font-weights, "semi-bold");
      }

      .cls-lbltxt {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.67;
        color: #6f778b;
        @include margin(left, top, right, bottom, 0, 0, 0, 3);
      }

      .cls-txt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.45;
        color: #262e3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .cls-dic {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights, "regular");
        color: #272c3e;

        a {
          font-weight: map-get($font-weights, "medium");
          color: #00bfa5;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

    }

  }
}

// ekyc login popup
.cls-loginbox {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 0, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .p-dialog-header-icons {
        display: none;
      }

      h2 {
        font-weight: map-get($font-weights, "semi-bold");
      }
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: visible;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 24);

      p {
        font-size: $heading-extra-small-font-size;
        color: $utl-text-color-11;
        font-weight: map-get($font-weights, "medium");
      }

      img {
        width: 150px;
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      button {
        height: 40px;
        @include margin(left, top, right, bottom, 16, 0, 0, 0);
      }
    }
  }
}

// cls alert popup

.cls-alertpopup {
  border: none;
  box-shadow: none;
  overflow: hidden;

  .p-dialog {
    .p-dialog-header {
      display: block;

      .cls-alert {

        @include padding(left, top, right, bottom, 8, 6, 8, 6);
        @include border-radius;
        @include background-color(#c73232);
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights , 'bold');
        color: $utl-white;

        .material-icons {
          font-size: 16px;
          vertical-align: sub;
          @include margin(left, top, right, bottom, 0, 0, 4, 0);
        }
      }

      h2 {
        font-size: $heading-small-font-size;
        color: $utl-text-color-11;
        font-weight: map-get($font-weights, "semi-bold");
        line-height: 1.5;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      p {
        font-size: $text-default-size;
        font-weight: 300;
      }
    }

    .p-dialog-content {
      min-height: 100px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 24, 32, 0);

      .cls-contxt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: #28314b;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      .cls-contxt_remarks {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        line-height: 1.71;
        color: #28314b;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .cls-datebox {
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        color: $utl-text-color-11;
        line-height: 1.43;
        display: inline-block;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }
    }
  }
}

// feedbackpop

.cls-feedbackpop {
  &.p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 0, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);
      height: 34px;
    }

    .p-dialog-content {
      min-height: 185px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      .cls-headtxt {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'semi-bold');
        color: #4d546c;
        @include margin(left, top, right, bottom, 0, 21, 0, 19);
      }

      .cls-txt {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights , 'medium');
        color: #4d546c;
        @include margin(left, top, right, bottom, 0, 19, 0, 0);
        @include padding(left, top, right, bottom, 13, 15, 19, 17);
        @include border-radius;
        @include border(1px, solid, #d9d9d9);
        @include background-color(rgba(217, 217, 217, 0.08));

        .material-icons {
          @include margin(left, top, right, bottom, 0, 0, 5, 0);
        }

      }


    }


  }
}

// social profile popup

.cls-socialpopup {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;
    width: 500px;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 24, 32, 16);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      h2 {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'semi-bold');
        color: #4d546c;
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

    }

    .p-dialog-content {
      min-height: 70px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 16);

      .message-text {
        font-family: $font-family;
        font-size: $heading-extra-small-font-size;
        font-weight: map-get($font-weights , 'medium');
        color: #4d546c;
        @include padding(left, top, right, bottom, 0, 0, 0, 3);
      }


    }


  }
}

.timehsheet {
  h2 {
    font-size: $heading-s-medium-font-size !important;
    font-weight: 600 !important;
    line-height: 23px;
    color: $utl-text-color-12;
  }

  .p-dialog-footer {
    border-top: 0;
  }
}

// analyticbox

.cls-analyticbox {
  &.downloadpage {
    .p-dialog {
      .p-dialog-header {
        @include padding(left, top, right, bottom, 32, 10, 32, 10);
      }
    }
  }

  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 30, 32, 21);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .cls-headtxt {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'bold');
        line-height: 1;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-content {
      min-height: 88px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      .cls-headtxt {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'bold');
        line-height: 1;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .cls-subheadtxt {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights , 'regular');
        line-height: 15px;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 8, 0, 16);
      }

    }
  }
}

.custom-hronesearch {
  .p-dialog {
    width: 91%;
    height: 93%;
    z-index: 1001;
    max-height: 100%;
    @include margin(left, top, right, bottom, 6, 0, 0, 45);

    .btnclose {
      top: 8px;
      @include padding(left, top, right, bottom, 5, 4, 5, 4);
      @include border-radius(100%);

      img {
        width: 14px;
      }

      &:hover {
        @include background-color($utl-grey-color-26);
      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 0, 6, 0, 60);
      overflow: hidden !important;
    }

  }

}

.custom-hronesearch.p-dialog-mask.p-component-overlay {
  background-color: rgba(68, 68, 68, 0.8) !important;

}

// cls add candidate

.cls-addcandidate {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;
    position: relative;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 30, 32, 6);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .cls-heading {
        font-family: $font-family;
        font-size: 20px;
        font-weight: map-get($font-weights , 'semi-bold');
        line-height: 1;
        color: #4d546c;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-content {
      min-height: auto;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 32);

      .cls-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .cls-box {
          @include border-radius;
          @include border(1px, solid, #d9d9d9);
          @include background-color(#fafafa);
          @include padding(left, top, right, bottom, 17, 20, 17, 20);
          @include margin(left, top, right, bottom, 0, 0, 16, 0);
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights , 'medium');
          text-align: center;
          color: #4d546c;
          cursor: pointer;
          flex-basis: 48%;
          flex-grow: 0;

          &:last-child {
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          &.active {
            @include border(1px, solid, #00bfa5);
          }
        }
      }

    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 16, 16, 16, 16);
    }
  }
}

// subscribe dialog

.cls-subscribe {
  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 30, 32, 0);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .cls-heading {
        font-family: $font-family;
        font-size: $heading-extra-small-font-size;
        font-weight: map-get($font-weights , 'bold');
        line-height: 1;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 19);
      }

      .cls-wrapper {
        text-align: center;

        .cls-subtxt {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights , 'regular');
          color: #272c3e;
          @include margin(left, top, right, bottom, 0, 0, 0, 34);
        }

        .cls-subtxt-n {
          @extend .cls-subtxt;
          font-weight: map-get($font-weights , 'bold');
          @include margin(left, top, right, bottom, 0, 0, 0, 25);
        }
      }

    }

    .p-dialog-content {
      min-height: 50px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      .cls-roundbox {

        @include padding(left, top, right, bottom, 16, 16, 15, 5);
        @include margin(left, top, right, bottom, 0, 0, 0, 8);
        @include border-radius;
        @include border(1px, solid, #d0d0d0);
      }

      .cls-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .cls-box {
          @include border-radius;
          @include border(1px, solid, #d9d9d9);
          @include background-color(#fafafa);
          @include padding(left, top, right, bottom, 17, 20, 17, 20);
          @include margin(left, top, right, bottom, 0, 0, 16, 0);
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights , 'medium');
          text-align: center;
          color: #4d546c;

          &:last-child {
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          &.active {
            @include border(1px, solid, #00bfa5);
          }
        }
      }

    }

  }
}

.deleteall-dialog {
  @extend .hrone-dialog;

  .p-dialog-content {
    min-height: auto !important;

    .message-text {
      color: #4d546c !important;
      opacity: 1 !important;
    }
  }

  &.p-dialog-mask {
    .p-dialog-footer {
      @include padding(left, top, right, bottom, 16, 16, 16, 16);

      button {
        flex-basis: 25%;
        flex-grow: 0;
      }
    }
  }
}


// AllSearch Available Seach Dialog****************

.cls-allSearcDialog {
  .p-dialog {
    @include background-color(#F4F6FA);
    @include border-radius;

    .p-dialog-header {
      display: flex;
      @include background-color(#F4F6FA);
      @include padding(left, top, right, bottom, 24, 24, 24, 0);

      .p-dialog-title {
        width: 100%;

        .cls-headerbox {
          display: flex;
          width: 100%;
          @include border-pos(bottom, 1px, solid, rgba(185, 185, 185, .4));
          @include padding(left, top, right, bottom, 0, 0, 0, 4);

          .p-dialogHeader {
            width: 95%;
            display: flex;
            align-items: center;

            h2 {
              font-family: $font-family;
              color: $utl-text-color-12;
              font-size: $heading-extra-small-font-size;
              font-weight: map-get($font-weights, "bold");
              line-height: 20px;
              letter-spacing: 0.04em;

            }
          }

          .btnclose {
            background: $utl-white;
            width: 32px;
            height: 32px;
            @include border-radius(64px);
            display: flex;
            justify-content: center;
            align-items: center;

            em {
              font-size: 24px;
            }
          }
        }

        .cls-searchbox {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 99, 0, 0, 0);
          @include border-pos(bottom, 1px, solid, #9098A8);
          display: flex;
          align-items: center;

          img {
            width: 16px;
            @include margin(left, top, right, bottom, 0, 0, 4, 0);
          }

          input {
            @include border(0px, solid, transparent);
            @include background-color(transparent);
          }
        }
      }
    }


    .p-dialog-content {

      @include padding(left, top, right, bottom, 24, 0, 24, 24);
      @include background-color(#F4F6FA);



    }
  }
}

// send bulk pass
.bulkpop-pop {
  @extend .regisnaton-pop;

  .p-dialog-header {
    display: flex !important;

    .p-dialog-title {
      width: 100%;
    }
  }

  .header-title {
    font-weight: map-get($font-weights, "semi-bold") !important;
  }

  .p-dialog-content {
    min-height: auto !important;

    p {
      @include padding(left, top, right, bottom, 32, 32, 32, 32);
    }

    .notecls {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      font-weight: map-get($font-weights, "medium");
      font-size: $labeltxt;
      color: $utl-text-color-12;
    }

  }
}

// bgv
.inittaite-bgv {
  @extend .hrone-dialog;

  .message-txt {
    font-size: $heading-h2;
    @include color($bgvgreycolortext);
  }

  .header {
    @include color($bgvgreycolortext);
    font-size: $heading-20-size;
  }

  .detailbox {
    @include background-color(rgba(244, 246, 249, 0.2));
    border: 1px solid #E7E7E7;
    @include border-radius;
    @include padding(left, top, right, bottom, 24, 16, 24, 16);

    .topmar {
      @include abs-pos($top: 18px, $right: 24px, $bottom: initial, $left: initial);
    }

    ul {
      li {
        font-size: $text-name;
        @include color($bgvgreycolortext);
        @include margin(left, top, right, bottom, 0, 0, 0, 5);

        &:last-child {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
        }
      }
    }
  }
}

// newsletter 
.cls-newsletter {
  .p-dialog {
    .p-dialog-header {
      @include padding(left, top, right, bottom, 16, 10, 16, 0);
    }
  }
}


// subscription-popup css

.cls-subscription-popup {
  .p-dialog {
    text-align: center;

    .p-dialog-header {
      display: block;

      .p-dialog-title {
        .cls-title {
          font-family: $font-family;
          font-size: $heading-s-medium-font-size;
          color: #4D546C;
          font-weight: 600;
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

        }
      }
    }

    .p-dialog-content {
      img {
        margin-bottom: 16px;
        max-width: 60%;
      }

      .cls-subtxt {
        font-family: $font-family;
        font-size: $text-default-size;
        color: #4D546C;
        font-weight: 500;
        margin: 0;

        .cls-mail {
          font-weight: 700;
          color: #00BFA5;
          text-decoration: underline;
          white-space: nowrap;
        }
      }
    }

    .p-dialog-footer {
      text-align: center;
    }
  }
}

// ess lock pop up design
.ess-lock {
  &.p-component-overlay {
    background-color: transparent;
  }

  &.p-component-overlay-enter {
    animation: none;
  }

  .p-dialog {
    position: fixed;
    bottom: 6px;
    @include border-radius;
    max-height: initial;

    h3 {
      font-size: $heading-h2;
      font-weight: map-get($font-weights, "semi-bold");
      color: $utl-white;
    }

    .cls-contxt {
      color: $utl-white;
      font-size: $text-name;
    }

    .p-dialog-header {
      @include background-color(#13172E);
      color: $utl-white;
      display: block;
      @include padding(left, top, right, bottom, 24, 24, 24, 0);
    }

    .seemorebtn {
      font-size: $tabs-text;
      color: #93B3F2;
    }

    .p-dialog-footer {
      @extend .p-dialog-header;
      @include padding(left, top, right, bottom, 24, 0, 24, 16);
      border: 0;

    }

    .side-footer {
      .btn {
        @include padding(left, top, right, bottom, 24, 12, 24, 12);
        @include border-radius(2px);
        min-height: 41px;
        line-height: initial;
      }

      .btn-white {
        @include background-color(transparent);
        border: 1px solid #FFF;
      }
    }

    .p-dialog-content {
      min-height: auto;
      @include padding(left, top, right, bottom, 24, 8, 24, 8);
      @include background-color(#13172E);
    }

    &.p-dialog-mask {
      align-items: flex-end;
    }
  }

}

.ess-attendance {
  &.p-dialog-header {
    display: block;
  }

  &.wish-dialog {
    .p-dialog {
      .p-dialog-content {
        min-height: auto;
        @include padding(left, top, right, bottom, 24, 0, 24, 16);
      }
    }
  }

  h4 {
    font-size: $heading-20-size;
    color: $bgvgreycolortext;
    font-weight: map-get($font-weights, "bold");
  }

  .cls-contxt-text {
    color: $bgvgreycolortext;
    font-size: $tabs-text;
  }

  .btn-cancel,
  .p-dialog-footer {
    border-color: #E7E7E7;
  }
}

// reports popup


.cls-newreport {
  padding: 0;

  &.downloadpage {
    .p-dialog {
      .p-dialog-header {
        @include padding(left, top, right, bottom, 32, 10, 32, 10);
      }
    }
  }

  .p-dialog {
    border: none;
    box-shadow: none;
    overflow: visible;
    max-height: 100%;

    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 32, 30, 32, 21);
      @include background-color;
      @include border-pos(bottom, 0, solid, transparent);

      .cls-headtxt {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'bold');
        line-height: 1;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .btnclose {
        @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

        i,
        em {
          font-size: 30px;
        }
      }
    }

    .p-dialog-content {
      min-height: 88px;
      overflow: auto;
      position: relative;
      @include padding(left, top, right, bottom, 32, 0, 32, 24);

      .cls-headtxt {
        font-family: $font-family;
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights , 'bold');
        line-height: 1;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);
      }

      .cls-subheadtxt {
        font-family: $font-family;
        font-size: $text-medium-size;
        font-weight: map-get($font-weights , 'regular');
        line-height: 15px;
        color: #272c3e;
        @include margin(left, top, right, bottom, 0, 8, 0, 16);
      }

    }
  }
}


.cls-p-Automated {
  .p-dialog {
    .p-dialog-header {
      display: block;
      @include padding(left, top, right, bottom, 16, 16, 16, 0);

      .cls-tlt {
        font-weight: 400;
        font-size: $text-medium-size;

      }

      .cls-subtlt {
        font-weight: 700;
        font-size: $heading-extra-small-font-size;

      }
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 16, 0, 16, 16);
      min-height: 500px;


      .dialog-wrapper {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
      }

    }

  }
}

.cls-iplpopup {
  &.p-dialog-mask {
    .p-dialog {
      .p-dialog-header {
        display: none;
      }

      .p-dialog-content {
        min-height: 185px;
        overflow: auto;
        position: relative;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        .dialog-wrapper {
          @include padding(left, top, right, bottom, 32, 24, 32, 24);

          h2 {
            font-size: $heading-s-medium-font-size;
            color: $utl-text-color-11;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: 1.5;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
          }

          .cls-hdtxt {
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            color: $utl-text-color-11;
            line-height: 1.43;
            display: inline-block;
            @include margin(left, top, right, bottom, 0, 0, 0, 6);
          }

          .cls-contbox {
            font-size: $text-default-size;
            font-weight: map-get($font-weights, "semi-bold");
            color: $utl-text-color-12;
            line-height: 1.43;
            display: inline-block;
            @include margin(left, top, right, bottom, 0, 0, 0, 8);

            span {
              font-weight: map-get($font-weights, "regular");
            }
          }

          .cls-spacebox {
            @include margin(left, top, right, bottom, 0, 24, 0, 0);

            .alert-text {
              font-size: $text-default-size;
              font-weight: map-get($font-weights, "semi-bold");
              color: $utl-text-color-11;
              line-height: 1.43;
              display: inline-block;
            }

            .sub-mgm-text {
              @extend .alert-text;
            }

            .btnclose {
              @include abs-pos($top: 24px, $right: 24px, $bottom: initial, $left: initial);

              i,
              em {
                font-size: 30px;
              }
            }
          }
        }
      }
    }

    .p-dialog-footer {
      @include padding(left, top, right, bottom, 32, 16, 32, 16);

      p-footer {
        padding: 0;

        button {
          height: 40px;
          @include margin(left, top, right, bottom, 16, 0, 0, 0);
        }
      }
    }
  }
}

@media screen and (min-width: 1440px) {

  .cls-p-Automated {
    .p-dialog {


      .p-dialog-content {

        min-height: 800px;




      }

    }
  }



}

// upcoming box css

.cls-upcomingbox {
  .p-dialog {
    .p-dialog-header {
      display: block;
    }

    .p-dialog-content {
      @include padding(left, top, right, bottom, 32, 20, 32, 12);

    }

    .p-dialog-footer {
      border-top: 0px !important;
      @include padding(left, top, right, bottom, 32, 0, 32, 16);
    }
  }
}

/***************** Expanse dropdown css ********************/
@media (max-width: 767px) {
  .cls-expensematmenu {
    .p-dialog-mask {
      position: fixed;

    }
  }
}