@import './_variables';
@import './_functions';

// Main Body==============
// @mixin body-typo($font-family, $body-font-size, $bg-color, $default-color) {
//     font-family: $font-family;
//     font-size: $body-font-size;
//     background-color: $bg-color;
//     color: $default-color
// }
// ************Uneecops Title/Heading***********************//
@mixin heading-line-height() {
  line-height: map-get($heading-line-height, "heading-lh");
}

// h1-font---32px--
@mixin h-extra-large($heading-extra-font-size, $font-family) {
  font-size: $heading-extra-font-size;
  font-family: $font-family;
  color: map-get($heading-colors, "dark-color");
  font-weight: map-get($font-weights, "semi-bold");
  @include heading-line-height;
}

// h2-font---24px--
@mixin h-large($heading-large-font-size, $font-family) {
  font-size: $heading-large-font-size;
  font-family: $font-family;
  color: map-get($heading-colors, "semi-color");
  font-weight: map-get($font-weights, "medium");
  @include heading-line-height;
}

// h3-font---22px--
@mixin h-medium($heading-medium-font-size, $font-family) {
  font-size: $heading-medium-font-size;
  font-family: $font-family;
  color: map-get($heading-colors, "light-color");
  font-weight: map-get($font-weights, "medium");
  @include heading-line-height;
}

// h4-font---18px--
@mixin h-small($heading-small-font-size, $font-family) {
  font-size: $heading-small-font-size;
  font-family: $font-family;
  color: map-get($heading-colors, "light-color");
  font-weight: map-get($font-weights, "regular");
  @include heading-line-height;
}

// h5-font---16px--
@mixin h-extra-small($heading-extra-small-font-size, $font-family) {
  font-size: $heading-extra-small-font-size;
  font-family: $font-family;
  color: map-get($heading-colors, "light-color");
  font-weight: map-get($font-weights, "regular");
  @include heading-line-height;
}

// 14-font---14px--
@mixin t-14-font-style() {
  font-size: $text-default-size;
  font-family: $font-family;
  color: map-get($heading-colors, "semi-color");
  @include heading-line-height;
}

// 12-font---12px--
@mixin t-12-font-style() {
  font-size: $text-medium-size;
  font-family: $font-family;
  color: map-get($heading-colors, "dark-color");
  @include heading-line-height;
}

// 10-font---10px--
@mixin t-10-font-style() {
  font-size: $text-small-size;
  font-family: $font-family;
  color: map-get($heading-colors, "dark-color");
  @include heading-line-height;
}




// ************Space Utilities***********************//
// Margin--
@mixin margin($positionL, $positionT, $positionR, $positionB, $value1, $value2, $value3, $value4) {
  margin-#{$positionL}: $value1 + px;
  margin-#{$positionT}: $value2 + px;
  margin-#{$positionR}: $value3 + px;
  margin-#{$positionB}: $value4 + px;
}

@mixin margin-em($positionL, $positionT, $positionR, $positionB, $value1, $value2, $value3, $value4) {
  margin-#{$positionL}: $value1 + em;
  margin-#{$positionT}: $value2 + em;
  margin-#{$positionR}: $value3 + em;
  margin-#{$positionB}: $value4 + em;
}

@mixin margin-pos($pos, $value) {
  margin-#{$pos}: &value + px;
}

// padding--
@mixin padding($positionL, $positionT, $positionR, $positionB, $value1, $value2, $value3, $value4) {
  padding-#{$positionL}: $value1 + px;
  padding-#{$positionT}: $value2 + px;
  padding-#{$positionR}: $value3 + px;
  padding-#{$positionB}: $value4 + px;
}

@mixin padding-em($positionL, $positionT, $positionR, $positionB, $value1, $value2, $value3, $value4) {
  padding-#{$positionL}: $value1 + em;
  padding-#{$positionT}: $value2 + em;
  padding-#{$positionR}: $value3 + em;
  padding-#{$positionB}: $value4 + em;
}

// ******************Box-Shadow********************//
@mixin shadow($level: 1, $background: black) {
  @if $level==1 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15)
  }

  @else if $level==2 {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.23)
  }

  @else if $level==3 {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15)
  }

  @else if $level==4 {
    box-shadow: 0 1px 7px 0 rgba(0, 0, 0, 0.5);
  }

  @else if $level==5 {
    box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.15);
  }

  @else if $level==6 {
    box-shadow: 0px 4px 2px -1px rgba(0, 0, 0, 0.06);
  }

  @else if $level==7 {
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.13);
  }

  @else if $level==8 {
    box-shadow: 0 2px 4px 0 rgba(48, 56, 97, 0.2);
  }

  @else if $level==9 {
    box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.2);
  }

  @else if $level==10 {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  }

  @else if $level==11 {
    box-shadow: 0 5px 10px -10px rgba(48, 56, 97, 0.2);
  }

  @else if $level==12 {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  }

  @else if $level==13 {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }

  @else if $level==14 {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  }

  @else if $level==15 {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.14);
  }

  @else if $level==16 {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  }

  @else if $level==17 {
    box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.15);
  }

  @else if $level==18 {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.09);
  }

  @else if $level==19 {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  }

  @else if $level==20 {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.11)
  }

  @else if $level==21 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  }

  @else if $level==22 {
    box-shadow: 0 0 4px 0 rgba(48, 56, 97, 0.2);
  }

  @else if $level==23 {
    box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
  }

  @else if $level==24 {
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.2);
  }
  @else if $level==25 {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  @else if $level==26 {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  }
  @else if $level==27 {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
}

// ******************Box-Sizing********************//
@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model; // Safari <= 5
  -moz-box-sizing: $box-model; // Firefox <= 19
  box-sizing: $box-model;
}

// *************Border**************
@mixin border($width, $style, $color) {
  border: $width $style $color;
}

@mixin border-pos($pos, $width, $style, $color) {
  border-#{$pos}: $width $style $color;
}

// ******************Border-Radius********************//
@mixin border-radius($radius: 4px) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

// ******************Border-Radius for individual corner********************//
@mixin border-radius-pos($topleft, $topright, $bottomright, $bottomleft) {
  border-top-left-radius: $topleft;
  border-top-right-radius: $topright;
  border-bottom-right-radius: $bottomright;
  border-bottom-left-radius: $bottomleft;
  -webkit-border-top-left-radius: $topleft;
  -webkit-border-top-right-radius: $topright;
  -webkit-border-bottom-right-radius: $bottomright;
  -webkit-border-bottom-left-radius: $bottomleft;
  -moz-border-radius-topleft: $topleft;
  -moz-border-radius-topright: $topright;
  -moz-border-radius-bottomright: $bottomright;
  -moz-border-radius-bottomleft: $bottomleft;
}

// ******************Opacity********************//
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

// **********Text Overflow*******************
@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// *************Absolute Positioned**************
@mixin abs-pos ($top: auto, $right: auto, $bottom: auto, $left: auto) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: absolute;
  z-index: 9;
}

// *************Background-image**************
@mixin background($position:0 0, $repeat: no-repeat) {
  background: {
    position: $position;
    repeat: $repeat;
    color: $utl-white;
  }
}

@mixin background-color($color: #ffffff) {
  background-color: $color;
}
@mixin color($color:$utl-text-color-12) {
 color: $color;
}
@mixin font-size($text-default-size) {
  font-size: $font-size;
 }
/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
  overflow: hidden;
  position: relative;
  line-height: $lineHeight;
  max-height: $lineHeight * $lineCount;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;

  &:before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &:after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $bgColor;
  }
}
form label,
label {
  font-size: inherit;
}





// scrollbar
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 20px;
  
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: $foreground-color;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}


