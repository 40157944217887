/*******************
  Buttons
  ******************/

.btn {
	padding: 7px 20px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 600;
	border-radius: 4px;
	text-transform: uppercase;
}

.btn-group label {
	color: #ffffff;
	margin-bottom: 0px;
}

.btn-group label.btn-secondary {
	color: #67757c;
}

.btn-lg {
	padding: .75rem 1.5rem;
	font-size: 1.25rem;
}

.btn-md {
	padding: 12px 55px;
	font-size: 16px;
}

.btn-circle {
	border-radius: 100% !important;
	width: 40px;
	height: 40px;
	padding: 10px;
}

.btn-circle.btn-sm {
	width: 35px;
	height: 35px;
	padding: 8px 10px;
	font-size: 14px;
}

.btn-circle.btn-lg {
	width: 50px;
	height: 50px;
	padding: 14px 15px;
	font-size: 18px;
	line-height: 23px;
}

.btn-circle.btn-xl {
	width: 70px;
	height: 70px;
	padding: 14px 15px;
	font-size: 24px;
}

.btn-sm {
	padding: .25rem .5rem;
	font-size: 12px;
}

.btn-xs {
	padding: .25rem .5rem;
	font-size: 10px;
}

.button-list button,
.button-list a {
	margin: 5px 12px 5px 0;
}

.btn-outline {
	color: inherit;
	background-color: transparent;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
}

.btn-radius {
	border-radius: 4px !important;
}

.btn-rounded {
	border-radius: 60px;
	padding: 7px 18px;
}

.btn-rounded.btn-lg {
	padding: .75rem 1.5rem;
}

.btn-rounded.btn-sm {
	padding: .25rem .5rem;
	font-size: 12px;
}

.btn-rounded.btn-xs {
	padding: .25rem .5rem;
	font-size: 10px;
}

.btn-rounded.btn-md {
	padding: 12px 35px;
	font-size: 16px;
}

.btn-secondary,
.btn-secondary.disabled {
	-webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	border: 1px solid;
}

.btn-secondary:hover,
.btn-secondary.disabled:hover {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	/* color: #ffffff; */
	color: inherit;
}

.btn-secondary.active,
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary.disabled.active,
.btn-secondary.disabled:active,
.btn-secondary.disabled:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	/* color: #ffffff; */
	background: #263238;
	border-color: #263238;
	color: inherit;
}

.btn-secondary.disabled:hover,
.btn-secondary.disabled:focus,
.btn-secondary.disabled:active {
	color: #67757c !important;
	background-color: #ffffff;
	border-color: #cccccc;
	cursor: default;
}

.btn-primary,
.btn-primary.disabled {
	background: #745af2;
	border: 1px solid #745af2;
	-webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	color: #fff;
}

.btn-primary:hover,
.btn-primary.disabled:hover {
	background: #745af2;
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	border: 1px solid #745af2;
	color: #fff;
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary.disabled.active,
.btn-primary.disabled:active,
.btn-primary.disabled:focus {
	background: #6352ce;
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	border-color: transparent;
	color: #fff;
}






.btn-blue,
.btn-blue.disabled {
	background: #5c6bc0 !important;
	border: 1px solid #5c6bc0 !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	color: #fff;
}

.btn-blue:hover,
.btn-blue.disabled:hover {
	background: #5c6bc0 !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	border: 1px solid #5c6bc0 !important;
	color: #fff;
}

.btn-blue.active,
.btn-blue:active,
.btn-blue:focus,
.btn-blue.disabled.active,
.btn-blue.disabled:active,
.btn-blue.disabled:focus {
	background: #192ea2 !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	border-color: transparent;
	color: #fff;
}



.btn-themecolor,
.btn-themecolor.disabled {
	background: #398bf7 !important;
	color: #ffffff;
	border: 1px solid #398bf7 !important;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
	background: #398bf7 !important;
	opacity: 0.7;
	border: 1px solid #398bf7 !important;
}


/*********** btn-dark *****************/
.btn-dark,
.btn-dark.disabled {
	background: #4d556a !important;
	border: 1px solid #4d556a !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	color: #fff !important;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
	color: #fff !important;
	background-color: #4d556a !important;
	border-color: #4d556a !important;
}

.btn-dark:hover,
.btn-dark.disabled:hover {
	background: #4d556a !important;
	color: #fff !important;
	border: 1px solid #4d556a !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 80, 217, 0.2);
	box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(255, 80, 217, 0.2);
}

.btn-dark.active,
.btn-dark:active,
.btn-dark:focus,
.btn-dark.disabled.active,
.btn-dark.disabled:active,
.btn-dark.disabled:focus {
	background: #4d556a;
	-webkit-box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2) !important;
	box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.12), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2) !important;
	border-color: transparent;
	color: #fff !important;
}

.btn.btn-dark.btn-bold:focus {
	box-shadow: none !important;
}

.btn-dark:not([disabled]):not(.disabled).active,
.btn-dark:not([disabled]):not(.disabled):active,
.show>.btn-dark.dropdown-toggle,
.btn-dark:not([disabled]):not(.disabled).active,
.btn-dark:not([disabled]):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
	box-shadow: none;
}











.btn-warning,
.btn-warning.disabled {
	-webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
	box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}

.btn-warning:hover,
.btn-warning.disabled:hover {
	-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:focus,
.btn-warning.disabled.active,
.btn-warning.disabled:active,
.btn-warning.disabled:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	border-color: transparent;
}

.btn-danger,
.btn-danger.disabled {

	-webkit-box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
	box-shadow: 0 2px 2px 0 rgba(239, 83, 80, 0.14), 0 3px 1px -2px rgba(239, 83, 80, 0.2), 0 1px 5px 0 rgba(239, 83, 80, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	height: 40px;
}

.btn-danger:hover,
.btn-danger.disabled:hover {
	-webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:focus,
.btn-danger.disabled.active,
.btn-danger.disabled:active,
.btn-danger.disabled:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	border-color: transparent;
}

.btn-inverse,
.btn-inverse.disabled {
	background: #2f3d4a;
	border: 1px solid #2f3d4a;
	color: #ffffff;
}

.btn-inverse:hover,
.btn-inverse.disabled:hover {
	background: #2f3d4a;
	opacity: 0.7;
	color: #ffffff;
	border: 1px solid #2f3d4a;
}

.btn-inverse.active,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse.disabled.active,
.btn-inverse.disabled:active,
.btn-inverse.disabled:focus {
	background: #232a37;
	color: #ffffff;
}

.btn-red,
.btn-red.disabled {
	background: #fb3a3a;
	border: 1px solid #fb3a3a;
	color: #ffffff;
}

.btn-red:hover,
.btn-red.disabled:hover {
	opacity: 0.7;
	border: 1px solid #fb3a3a;
	background: #fb3a3a;
}

.btn-red.active,
.btn-red:active,
.btn-red:focus,
.btn-red.disabled.active,
.btn-red.disabled:active,
.btn-red.disabled:focus {
	background: #e6294b;
}



.btn-outline-secondary {
	background-color: #ffffff;
	-webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	border-color: #cccccc;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
}

.btn-outline-secondary.active,
.btn-outline-secondary:active,
.btn-outline-secondary:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
}

.btn-outline-primary {
	color: #745af2;
	background-color: #ffffff;
	border-color: #745af2;
	-webkit-box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	box-shadow: 0 2px 2px 0 rgba(116, 96, 238, 0.14), 0 3px 1px -2px rgba(116, 96, 238, 0.2), 0 1px 5px 0 rgba(116, 96, 238, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary.focus {
	background: #745af2;
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	color: #ffffff;
	border-color: #745af2;
}

.btn-outline-primary.active,
.btn-outline-primary:active,
.btn-outline-primary:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	box-shadow: 0 14px 26px -12px rgba(116, 96, 238, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(116, 96, 238, 0.2);
	background-color: #6352ce;
	border-color: #6352ce;
}

.btn-outline-success {
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}

.btn-outline-success-two {
	color: #ffffff !important;
	background-color: #00bfa5 !important;
	border-color: #00bfa5 !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}

.btn-outline-success:hover {
	border: 1px solid;
}

.btn-outline-success.active {
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	background-color: #00bfa5 !important;
	border-color: #00bfa5 !important;
	border: 1px solid;
}

.btn-outline-success:focus {
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}

.btn-outline-info {
	color: #398bf7;
	background-color: transparent;
	border-color: #398bf7;
	-webkit-box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	box-shadow: 0 2px 2px 0 rgba(66, 165, 245, 0.14), 0 3px 1px -2px rgba(66, 165, 245, 0.2), 0 1px 5px 0 rgba(66, 165, 245, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	border: 1px solid;
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info.focus {
	background: #398bf7;
	border-color: #398bf7;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
	box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
	border: 1px solid;
}

.btn-outline-info.active,
.btn-outline-info:active,
.btn-outline-info:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
	box-shadow: 0 14px 26px -12px rgba(23, 105, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(23, 105, 255, 0.2);
	background-color: #028ee1;
	border-color: #028ee1;
	border: 1px solid;
}

.btn-outline-warning {
	color: #ffc107;
	background-color: transparent;
	border-color: #ffc107;
	-webkit-box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
	box-shadow: 0 2px 2px 0 rgba(248, 194, 0, 0.14), 0 3px 1px -2px rgba(248, 194, 0, 0.2), 0 1px 5px 0 rgba(248, 194, 0, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	border: 1px solid;
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning.focus {
	background: #ffc107;
	border-color: #ffc107;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	border: 1px solid;
}

.btn-outline-warning.active,
.btn-outline-warning:active,
.btn-outline-warning:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	box-shadow: 0 14px 26px -12px rgba(248, 194, 0, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(248, 194, 0, 0.2);
	background-color: #e9ab2e;
	border-color: #e9ab2e;
	border: 1px solid;
}

.btn-outline-danger {
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	border: 1px solid;
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger.focus {
	border: 1px solid;
}

.btn-outline-danger.active,
.btn-outline-danger:active,
.btn-outline-danger:focus {
	border: 1px solid;
}

.btn-outline-red {
	color: #fb3a3a;
	background-color: transparent;
	border-color: #fb3a3a;
	border: 1px solid;
}

.btn-outline-red:hover,
.btn-outline-red:focus,
.btn-outline-red.focus {
	background: #fb3a3a;
	border-color: #fb3a3a;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	border: 1px solid;
}

.btn-outline-red.active,
.btn-outline-red:active,
.btn-outline-red:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	box-shadow: 0 14px 26px -12px rgba(239, 83, 80, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(239, 83, 80, 0.2);
	background-color: #d61f1f;
	border: 1px solid;
}

.btn-outline-inverse {
	color: #2f3d4a;
	background-color: transparent;
	border-color: #2f3d4a;
	border: 1px solid;
}

.btn-outline-inverse:hover,
.btn-outline-inverse:focus,
.btn-outline-inverse.focus {
	background: #2f3d4a;
	border-color: #2f3d4a;
	color: #ffffff;
	border: 1px solid;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus,
.show>.btn-primary.dropdown-toggle {
	background-color: #6352ce;
	border: 1px solid #6352ce;

}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success.focus:active,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover,
.btn-success.focus,
.btn-success:focus,
.show>.btn-success.dropdown-toggle {
	/* background-color: #04b381; */
	/* border: 1px solid #04b381; */
}



.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning.focus:active,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover,
.btn-warning.focus,
.btn-warning:focus,
.show>.btn-warning.dropdown-toggle {
	background-color: #e9ab2e;
	border: 1px solid #e9ab2e;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger.focus:active,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover,
.btn-danger.focus,
.btn-danger:focus,
.show>.btn-danger.dropdown-toggle {
	background-color: #e6294b;
	border: 1px solid #e6294b;
}

.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.btn-inverse.focus,
.btn-inverse:active,
.btn-inverse:focus,
.btn-inverse:hover,
.open>.dropdown-toggle.btn-inverse {
	background-color: #232a37;
	border: 1px solid #232a37;
}

.btn-secondary.active.focus,
.btn-secondary.active:focus,
.btn-secondary.active:hover,
.btn-secondary.focus:active,
.btn-secondary:active:focus,
.btn-secondary:active:hover,
.open>.dropdown-toggle.btn-secondary.focus,
.open>.dropdown-toggle.btn-secondary:focus,
.open>.dropdown-toggle.btn-secondary:hover,
.btn-secondary.focus,
.btn-secondary:focus,
.show>.btn-secondary.dropdown-toggle {
	color: #ffffff;
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.focus,
.btn-red:active,
.btn-red:focus,
.btn-red:hover,
.open>.dropdown-toggle.btn-red {
	background-color: #d61f1f;
	border: 1px solid #d61f1f;
	color: #ffffff;
}

.button-box .btn {
	margin: 0 8px 8px 0px;
}

.btn-label {
	background: rgba(0, 0, 0, 0.05);
	display: inline-block;
	margin: -6px 12px -6px -14px;
	padding: 7px 15px;
}

.btn-facebook {
	color: #ffffff;
	background-color: #3b5998;
}

.btn-facebook:hover {
	color: #ffffff;
}

.btn-twitter {
	color: #ffffff;
	background-color: #55acee;
}

.btn-twitter:hover {
	color: #ffffff;
}

.btn-linkedin {
	color: #ffffff;
	background-color: #007bb6;
}

.btn-linkedin:hover {
	color: #ffffff;
}

.btn-dribbble {
	color: #ffffff;
	background-color: #ea4c89;
}

.btn-dribbble:hover {
	color: #ffffff;
}

.btn-googleplus {
	color: #ffffff;
	background-color: #dd4b39;
}

.btn-googleplus:hover {
	color: #ffffff;
}

.btn-instagram {
	color: #ffffff;
	background-color: #3f729b;
}

.btn-pinterest {
	color: #ffffff;
	background-color: #cb2027;
}

.btn-dropbox {
	color: #ffffff;
	background-color: #007ee5;
}

.btn-flickr {
	color: #ffffff;
	background-color: #ff0084;
}

.btn-tumblr {
	color: #ffffff;
	background-color: #32506d;
}

.btn-skype {
	color: #ffffff;
	background-color: #00aff0;
}

.btn-youtube {
	color: #ffffff;
	background-color: #bb0000;
}

.btn-github {
	color: #ffffff;
	background-color: #171515;
}


/*==============================================================
   Buttons page
   ============================================================== */

.button-group .btn {
	margin-bottom: 5px;
	margin-right: 5px;
}

.no-button-group .btn {
	margin-bottom: 5px;
	margin-right: 0px;
}

.btn .text-active {
	display: none;
}

.btn.active .text-active {
	display: inline-block;
}

.btn.active .text {
	display: none;
}

.dragable-custom-btn .fixed:hover,
.dragable-custom-btn .fixed:active,
.dragable-custom-btn .fixed:focus {
	background: #fff;
	color: inherit !important;
	cursor: default;
	border-color: #cccccc;
	box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
}

.btn-secondary:not([disabled]):not(.disabled).active,
.btn-secondary:not([disabled]):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
	background: inherit;
	color: inherit !important;
	cursor: default;
	border-color: inherit;
	box-shadow: none;
}


.show-more:hover,
.btn-default:hover {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: transparent !important;
	border: solid 1px #1b2436;
}

.btn-default:hover,
.btn-default.disabled:hover {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: transparent !important;
	border: solid 1px #1b2436;
}

.btn-default.active,
.btn-default:active,
.btn-default:focus,
.btn-default.disabled.active,
.btn-default.disabled:active,
.btn-default.disabled:focus {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: transparent !important;
	border: solid 1px #1b2436;
}

.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active,
.show>.btn-default.dropdown-toggle {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: transparent !important;
	border: solid 1px #1b2436;
	line-height: initial;
}

.btn-gray {
	background: #fbfbfb;
	border: solid 1px #e8e8e8;
	color: inherit !important;
}

.show-more:hover,
.btn-default:hover {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: #fbfbfb;
	border: solid 1px #e8e8e8;
}

.btn-gray:hover,
.btn-gray.disabled:hover {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: #fbfbfb;
	border: solid 1px #e8e8e8;
}

.btn-gray.active,
.btn-gray:active,
.btn-gray:focus,
.btn-gray.disabled.active,
.btn-gray.disabled:active,
.btn-gray.disabled:focus {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: #fbfbfb;
	border: solid 1px #e8e8e8;
}

.btn-gray:not(:disabled):not(.disabled).active,
.btn-gray:not(:disabled):not(.disabled):active,
.show>.btn-gray.dropdown-toggle {
	box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
	background-color: #fbfbfb;
	border: solid 1px #e8e8e8;
}



.floating-bar-btn {
	border: solid 0.5px rgba(38, 46, 62, .2);
	padding: 10.5px 20px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 600 !important;
	background: #fafafa;
	color: #262e3e;
}

.btn-normal {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	background-color: #ffffff;
	color: #2d3547;
	border-radius: 0 !important;
	font-size: 14px;
	border: 1px solid #2d3547;
}

.btn-normal:hover {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	color: #2d3547;
	background-color: #ffffff;

}

.btn-line-success {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	background-color: transparent;
	color: #1ebfa5;
	border-radius: 0 !important;
	font-size: 14px;
	border: 1px solid #1ebfa5;
}

.btn-line-success:hover {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	color: #2d3547;
	background-color: transparent;

}

.btn-light-green {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	background-color: #1ebfa5;
	color: #ffffff;	
	font-size: 14px;
	border: 1px solid #1ebfa5;
}

.btn-light-green:hover,
.btn-light-green:focus {
	box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
	color: #ffffff;
	background-color: #1ebfa5;

}

.btn-link {
	color: inherit;
	background-color: transparent;
	border: none;
}

.btn-block {
	display: block;
	width: 100%;
}


.btn-outline-pink {
	color: #ef7ab4 !important;
	background-color: transparent !important;
	border-color: #ef7ab4 !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}

.btn-outline-pink:hover,
.btn-outline-pink:focus,
.btn-outline-pink.focus {
	background: #ef7ab4 !important;
	border-color: #ef7ab4 !important;
	color: #ffffff !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	border: 1px solid;
}

.btn-outline-pink.active,
.btn-outline-pink:active,
.btn-outline-pink:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	background-color: #f599c6 !important;
	border-color: #f599c6 !important;
	border: 1px solid;
}

/* btn green css */


.btn-green,
.btn-green.disabled {
	background: #479388 !important;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	border: 1px solid #479388 !important;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}

.btn-green:hover,
.btn-green.disabled:hover {
	background: #5fb9ac !important;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	border: 1px solid #5fb9ac !important;
}

.btn-green.active,
.btn-green:active,
.btn-green:focus,
.btn-green.disabled.active,
.btn-green.disabled:active,
.btn-green.disabled:focus {
	background: #479388 !important;
	color: #ffffff;
	-webkit-box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	box-shadow: 0 14px 26px -12px rgb(27 58 11 / 42%), 0 4px 23px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(248 194 0 / 20%);
	border-color: transparent;
}




.btn-sec-default,
.btn-sec-default.disabled {
	-webkit-box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	box-shadow: 0 2px 2px 0 rgba(169, 169, 169, 0.14), 0 3px 1px -2px rgba(169, 169, 169, 0.2), 0 1px 5px 0 rgba(169, 169, 169, 0.12);
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
	background-color: #fbfbfb;
	color: #272c3e;
	border-color:#e8e8e8;
	border: 1px solid;
	text-transform: inherit;
}

.btn-sec-default:hover,
.btn-sec-default.disabled:hover {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	color: #12c4ac;
	background: #fff;
	border-color: #12c4ac;
	/* color: inherit; */
}

.btn-sec-default.active,
.btn-sec-default:active,
.btn-sec-default:focus,
.btn-sec-default.disabled.active,
.btn-sec-default.disabled:active,
.btn-sec-default.disabled:focus {
	-webkit-box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	box-shadow: 0 14px 26px -12px rgba(169, 169, 169, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(169, 169, 169, 0.2);
	color: #12c4ac;
	background: #fff;
	border-color: #12c4ac;
	/* color: inherit; */
}

.btn-sec-default.disabled:hover,
.btn-sec-default.disabled:focus,
.btn-sec-default.disabled:active {
	color: #67757c !important;
	background-color: #ffffff;
	border-color: #cccccc;
	cursor: default;
}




.btn-light-primary-green,
.btn-light-primary-green.disabled {
	background: #e8fbf8 !important;
	color: #00bfa5;
	/* -webkit-box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%);
	box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%); */
	border: 1px solid #e8fbf8 !important;
	-webkit-transition: 0.2s ease-in;
	-o-transition: 0.2s ease-in;
	transition: 0.2s ease-in;
}

.btn-light-primary-green:hover,
.btn-light-primary-green.disabled:hover {
	background: #cefcf5 !important;
	color: #00bfa5;
	/* -webkit-box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%);
	box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%); */
	border: 1px solid #cefcf5 !important;
}

.btn-light-primary-green.active,
.btn-light-primary-green:active,
.btn-light-primary-green:focus,
.btn-light-primary-green.disabled.active,
.btn-light-primary-green.disabled:active,
.btn-light-primary-green.disabled:focus {
	background: #cefcf5 !important;
	color: #00bfa5;
	/* -webkit-box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%);
	box-shadow: 0 14px 26px -12px rgb(232 251 248 / 36%), 0 4px 23px 0 rgb(232 251 248 / 67%), 0 8px 10px -5px rgb(149 145 133 / 20%); */
	border-color: transparent;
}





.btn-outline-login {
	color: #02563D !important;
	background-color: transparent !important;
	border-color: #02563D !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}



.btn-outline-login:hover {
	background: #02563D !important;
	border-color: #02563D !important;
	color: #ffffff !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	border: 1px solid;
}

.btn-outline-login.active {
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	background-color: #02563D !important;
	border-color: #02563D !important;
	border: 1px solid;
}

.btn-outline-login:focus {
	color: #02563D !important;
	background-color: transparent !important;
	border-color: #02563D !important;
	-webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
	-webkit-transition: 0.2s ease-in !important;
	-o-transition: 0.2s ease-in !important;
	transition: 0.2s ease-in !important;
	border: 1px solid !important;
}




.btn-login:hover,
.btn-login.disabled:hover {
	background: rgba(2, 86, 61, .8) !important;
	-webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	/* border: 1px solid #02563D; */
	color: #fff !important;
}

.btn-login.active,
.btn-login:active,
.btn-login:focus,
.btn-login.disabled.active,
.btn-login.disabled:active,
.btn-login.disabled:focus {
	background: rgba(2, 86, 61, .8) !important;
	/* -webkit-box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
	box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2); */
	border-color: transparent;
}

.btn-login:not(:disabled):not(.disabled).active,
.btn-login:not(:disabled):not(.disabled):active,
.show>.btn-login.dropdown-toggle {
	color: #fff;
	background-color: rgba(2, 86, 61, .8) !important;
	border-color: rgba(2, 86, 61, .8) !important;
}