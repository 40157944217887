@import './variables';
@import './mixins';
@import './components';
@import './content';
@import './globalClass';

@each $name, $glyph in $theme-mapper {
    $current-theme : map-get($theme-mapper, $name);

    body {
        &.#{$name} {

            @keyframes ui-progress-spinner-color {

                100%,
                0% {
                    stroke: map-get($current-theme, theme-primary) !important;
                }
            }

            .crossIcon-fileUpload {
                background-color: map-get($current-theme, theme-error);
                border-radius: 100%;
                width: 20px;
                height: 20px;
                display: inline-block;

                i {
                    color: map-get($current-theme, theme-white)
                }

            }

            .pallete-container {
                mat-icon {
                    color: map-get($current-theme, theme-white)
                }
            }

            // global search-enterprise
            .search-enterprise {
                mat-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mat-mdc-form-field-flex {
                            .mat-mdc-form-field-infix {
                                input {
                                    border: 1px solid map-get($current-theme, theme-borderFocus);

                                    &::placeholder {
                                        color: map-get($current-theme, theme-contentPrimary)
                                    }
                                }
                            }
                        }

                        .mdc-line-ripple {

                            &::after,
                            &::before {
                                border-bottom: none !important;
                            }
                        }
                    }
                }
            }

            // spinner
            .spinner-loader {
                color: map-get($current-theme, theme-primary)
            }


            // card outer line
            .card {
                .card-body {
                    .card-title {
                        &::before {
                            border-left: 2px solid map-get($current-theme, theme-primary) !important;
                        }
                    }

                    .card-title-arc {
                        &::before {
                            border-left: 2px solid map-get($current-theme, theme-error) !important;
                        }

                        .clssubtext {
                            color: map-get($current-theme, theme-error);
                        }
                    }
                }
            }

            .notif-content {
                a {
                    color: map-get($current-theme, theme-primary);
                }
            }

            // globalClasses
            .font-60 {
                font-size: 60px;
            }



            //blank state text color
            .blank-state-color {
                color: map-get($current-theme, theme-contentDisabled);
            }


            .filters-global-float-btn {
                .filter-icon {
                    background-color: map-get($current-theme, theme-primary);

                    i {
                        color: map-get($current-theme, theme-white);
                        font-size: 26px;
                    }
                }
            }

            .menulisleft {
                span {
                    a {

                        &.cls-hover,
                        &.recent {
                            background-color: map-get($current-theme, theme-primary);
                            border: 1px solid map-get($current-theme, theme-primary);
                            color: map-get($current-theme, theme-white);
                        }

                        &:hover {
                            background-color: map-get($current-theme, theme-primary);
                            border: 1px solid map-get($current-theme, theme-primary);
                            color: map-get($current-theme, theme-white);
                        }
                    }

                }
            }


            // ribbons inbox background color 
            .bg-ribbons-color {
                background-color: map-get($current-theme, theme-primary);
            }

            // ribbons border color 
            .bg-ribbons-color-border {
                border-color: transparent map-get($current-theme, theme-primary) transparent transparent;
                _border-color: map-get($current-theme, theme-primary) #000000 #000000 #000000;
            }

            .attendance-spacing {
                background-color: map-get($current-theme, theme-backgroundPrimary);
                border-left: 1px solid map-get($current-theme, theme-borderOpaque);
            }

            // sidebarTheme
            .badge-form-wrapper {
                border: 1.5px dashed map-get($current-theme, theme-borderOpaque);
                background-color: map-get($current-theme, theme-backgroundSecondary);
            }





            .apps-card {
                background-color: map-get($current-theme, theme-backgroundPrimary);
               

                .addhover {
                    .cls-text {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                    &:hover {
                  
                        background-color:  map-get($current-theme, theme-backgroundSecondary);
                   
                      }
                }
            }


            .showAll {
                color: map-get($current-theme, theme-primary);
                cursor: pointer;
            }

            .home-sidenav {
                background-color: map-get($current-theme, theme-backgroundPrimary) !important;
            }

            .BETA {
                background-color: map-get($current-theme, theme-primary);
                border: 1px solid map-get($current-theme, theme-primary);
            }

            .mat-drawer-side {
                z-index: 10 !important;
            }

            .mat-drawer-content {
                z-index: 10 !important;
                background-color: map-get($current-theme, theme-backgroundSecondary);
            }

            // topbar icon
            .marketIcon,
            .Bell_noti,
            .pin-tack,
            .knowledgeBase,
            .payslip {
                color: map-get($current-theme, theme-white);
                font-size: 16px;
            }

            // chat icon
            .chat-icon {
                background-color: map-get($current-theme, theme-primary);
                border-radius: 100px;
                width: 56px;
                height: 56px;
                @include flexBox(center, center);

                .fa-message-captions {
                    color: map-get($current-theme, theme-white);
                    font-size: 24px;
                }
            }

            // shimmer 
            .bg-shimm {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                .shimmer-wrapper {
                    background-color: map-get($current-theme, theme-backgroundSecondary);
                }
            }

            //global




            // topbar 
            .top-navbar {
                background-color: map-get($current-theme, theme-primary);
                box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);

                .navbar-brand {
                    color: map-get($current-theme, theme-white);
                }

                .orgIcon {
                    background-color: map-get($current-theme, theme-white);
                    width: 32px;
                    height: 32px;
                    border-radius: 4px;
                    @include flexBox(center, center);
                }

                .button-search {
                    button {
                        border: 0;
                        border-radius: 4px;
                        height: 32px;
                        font-weight: 400;
                        font-size: 12px;
                        border-left: 0;
                        background-color: map-get($current-theme, theme-white);
                    }

                    figure {
                        max-height: 34px;
                        width: 34px;
                        min-height: 34px;
                    }
                }
            }

            //header
            .main-header {
                background-color: map-get($current-theme, theme-backgroundHeader);

                .btn-secondary-new {
                    background-color: map-get($current-theme, theme-secondaryButton);
                    color: map-get($current-theme, theme-SecondaryButtonText);

                    &:hover {
                        background-color: map-get($current-theme, theme-secondaryHover);
                        color: map-get($current-theme, theme-secondaryHoverText);
                    }
                }

                .customise {
                    .icon {
                        background-color: map-get($current-theme, theme-secondaryButton);
                        color: map-get($current-theme, theme-SecondaryButtonText);

                        &:hover {
                            background-color: map-get($current-theme, theme-secondaryHover);
                            color: map-get($current-theme, theme-secondaryHoverText);
                        }
                    }
                }

                .engage-cards-container {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    @include box-shadow(1px, 4px, 12px, map-get($current-theme, theme-box-shadow));

                    .engage-card {
                        .engage-icon-container {
                            .fa-light {
                                color: map-get($current-theme, theme-primary);
                            }
                        }

                        .subtxt {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }
                    }

                    .fa-ellipsis-vertical {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            // L0 menu
            .side-mini-panel {
                background-color: map-get($current-theme, theme-backgroundSecondary);
                box-shadow: inset -4px 0px 5px 0px map-get($current-theme, theme-menu-shadow);

                .mini-nav {
                    margin: 0px;
                    padding: 10px 0 0px 0;
                    display: inline-block;
                    height: 100%;



                    .menu-item,
                    .apps__menu {
                        list-style: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        margin-bottom: 4px;
                        flex-direction: column;

                        .menu-link {
                            width: 56px;
                            height: 44px;
                            border-radius: 0;
                            @include flexBox(center, center);
                            flex-direction: column;
                            padding: 6px 3px 4px;

                            i {
                                color: map-get($current-theme, theme-contentTertiary);
                                font-size: 16px;
                            }

                            &:hover {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                            }
                        }

                        .menu-link-dot {
                            width: 56px;
                            height: auto;
                            border-radius: 0;
                            @include flexBox(center, center);
                            flex-direction: column;
                            padding: 5px 0px;

                            i {
                                color: map-get($current-theme, theme-contentTertiary);
                                font-size: 16px;
                            }

                            &:hover {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                            }
                        }

                        &.selected,
                        &.selectedbtn {
                            .menu-link {
                                border-left: 2px solid map-get($current-theme , theme-primary);

                                .cls-hover {
                                    color: map-get($current-theme, theme-primary);
                                }

                                i {
                                    color: map-get($current-theme, theme-primary);
                                }
                            }
                        }
                    }
                }

                .AI-menu {
                    position: absolute;
                    bottom: 127px;

                    .menu-link {
                        width: 56px;
                        height: 40px !important;
                        flex-direction: column;
                        padding: 4px 3px !important;
                        border-left: 2px solid map-get($current-theme , theme-orange);

                        i {
                            color: map-get($current-theme, theme-orange) !important;
                        }


                    }

                    &.selected,
                    &.selectedbtn {
                        .menu-link {

                            .cls-hover {
                                color: map-get($current-theme, theme-orange);
                            }

                            i {
                                color: map-get($current-theme, theme-orange);
                            }
                        }
                    }
                }

                .AI-nomenu{
                    position: absolute;
                    bottom: 88px;

                    .menu-link {
                        width: 56px;
                        height: 40px !important;
                        flex-direction: column;
                        padding: 4px 3px !important;
                        border-left: 2px solid map-get($current-theme , theme-orange);

                        i {
                            color: map-get($current-theme, theme-orange) !important;
                        }


                    }

                    &.selected,
                    &.selectedbtn {
                        .menu-link {

                            .cls-hover {
                                color: map-get($current-theme, theme-orange);
                            }

                            i {
                                color: map-get($current-theme, theme-orange);
                            }
                        }
                    }
                }
            }

            .cls-100 {
             
                padding: 10px 0;
                &:hover{
                    background-color:  map-get($current-theme, theme-backgroundSecondary);
                    color: map-get($current-theme, theme-primary);
                    border-radius: 6px;
                  }
               
              }
          

            .grid-container {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                .main-title {
                    h3 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }


                .white-bg-card {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    &.attenCalendar {
                        background-color: map-get($current-theme, theme-backgroundPrimary);

                        .calender-indications {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .feed-menuIcon {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .new-calender {
                            .p-datepicker {
                                background-color: map-get($current-theme, theme-backgroundPrimary);

                                .p-datepicker-header {
                                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                                    .p-datepicker-title {

                                        .p-datepicker-month,
                                        .p-datepicker-year {
                                            color: map-get($current-theme, theme-contentPrimary) !important;
                                        }
                                    }

                                    .p-datepicker-prev,
                                    .p-datepicker-next {
                                        background-color: transparent !important;

                                        .p-datepicker-prev-icon,
                                        .p-datepicker-next-icon {
                                            color: map-get($current-theme, theme-contentSecondary);
                                        }

                                    }
                                }

                                .p-datepicker-calendar-container {
                                    table {
                                        &.p-datepicker-calendar {
                                            thead {
                                                tr {
                                                    th {
                                                        span {
                                                            color: map-get($current-theme, theme-contentPrimary) !important;
                                                        }

                                                    }

                                                }
                                            }

                                            tbody {
                                                tr {
                                                    td {

                                                        span {
                                                            .primaryText {
                                                                color: map-get($current-theme, theme-contentPrimary) !important;
                                                            }
                                                        }

                                                        &.p-datepicker-today {
                                                            span {
                                                                .primaryText {
                                                                    color: map-get($current-theme, theme-primary) !important;
                                                                }
                                                            }
                                                        }

                                                        &.p-datepicker-other-month {
                                                            span {

                                                                .primaryText,
                                                                .cls-wall-calendar {
                                                                    color: map-get($current-theme, theme-contentTertiary) !important;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .colored-headings {
                    .card-text {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .card-subtxt {
                        color: map-get($current-theme, theme-contentSecondary);
                    }

                    .mat-expansion-panel-header {
                        .mat-expansion-indicator {
                            &::after {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }

                .card-img-container {
                    .acc-icon {
                        background-color: map-get($current-theme, theme-secondaryAccent);
                        color: map-get($current-theme, theme-secondary);
                    }
                }



                hr {
                    border-top: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                }

                .common-txt,
                .bigtxt {
                    color: map-get($current-theme, theme-contentSecondary);
                    font-size: 14px;
                }


            }

            // refer
            .colored-headings {
                &.referral-container {
                    .refer-btn {
                        border: 1px solid map-get($current-theme, theme-primaryButton);
                        color: map-get($current-theme, theme-primaryButton);
                        background-color: transparent;

                        &:hover {
                            border: 1px solid map-get($current-theme, theme-primaryButton);
                            background-color: map-get($current-theme, theme-primaryButton);
                            color: map-get($current-theme, theme-white);

                        }
                    }
                }
            }

            //celebrations
            .todays-celebration-card {
                .todays-celebration-card-body {
                    .message-container {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            .outline-green-btn {
                color: map-get($current-theme, theme-secondary);

                &:hover {
                    color: map-get($current-theme, theme-secondary);
                    background-color: map-get($current-theme, theme-secondaryAccent);
                }
            }

            .outline-lightgreen-btn {
                color: map-get($current-theme, theme-primary);

                &:hover {
                    color: map-get($current-theme, theme-primary);
                    background-color: map-get($current-theme, theme-primaryAccent);
                }
            }

            // team plan
            .message-container {

                .employeeName,
                .name-length,
                .feed-userName {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .leavename {
                    color: map-get($current-theme, theme-contentSecondary);
                }

                .leaveExplain {
                    background-color: map-get($current-theme, theme-backgroundTertiary);

                    .fa-calendar-o,
                    .leaveNumber,
                    span {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            //feed 
            .interaction-panel {
                &.greenbg {
                    background-color: map-get($current-theme, theme-secondaryAccent);

                    .subtxt,
                    .fa-hands-clapping {
                        color: map-get($current-theme, theme-contentTertiary);
                    }
                }
            }

            .feed-container {
                .over-the-background {
                    .fa-bars-filter {
                        color: map-get($current-theme, theme-contentSecondary);
                    }
                }

                .feed-cards-list {
                    .feed-menuIcon {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            .colored-headings {
                .message-container {
                    .endorse {
                        background-color: map-get($current-theme, theme-backgroundTertiary) !important;
                        color: map-get($current-theme, theme-contentPrimary) !important;
                    }

                    .skill {
                        color: map-get($current-theme, theme-contentPrimary) !important;
                    }
                }
            }

            // pending task
            .wall-emp-search {
                .wall-emp-right {
                    &.unread-msg {
                        p {
                            color: map-get($current-theme, theme-contentSecondary);

                            span {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // leave container
            ._Cardspace {
                .leaves-container {
                    .leaveBalance {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .leaveName {
                        color: map-get($current-theme, theme-contentSecondary);
                    }
                }
            }

            // initiative-block
            .initiative-block {
                .wall-emp-search {
                    .initiativesCount {
                        color: map-get($current-theme, theme-contentPrimary);
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                    }

                    .initiativesName {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            // yesterday attendance
            .inTime,
            .outTime {
                color: map-get($current-theme, theme-contentPrimary);
                background-color: map-get($current-theme, theme-backgroundSecondary);
            }

            //L! inbox 
            .cls-othermenuscroll {
                background-color: map-get($current-theme, theme-backgroundPrimary);
            }

            .sidebarmenu {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                .sidebar-menu-inbox {
                    .mainInbox-menu {
                        margin-bottom: 12px;
                    }

                    .submenu-1 {
                        @include flexBox(center, space-between);

                        .fa-angle-down {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .cls-menubox {
                            color: map-get($current-theme, theme-contentPrimary);
                            padding-left: 10px;
                            @include flexBox(center, center);

                            .count {
                                color: map-get($current-theme, theme-primary);
                                font-weight: bold;
                            }
                        }
                    }

                }

                .sub-menu {
                    .cls-innermenu {
                        a {
                            display: flex;
                            align-self: center;

                            &.active {
                                color: map-get($current-theme, theme-primary);
                                background-color: map-get($current-theme, theme-primaryAccent);

                                .all-card {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;
                                }

                                .inbox-card-name {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;

                                }
                            }

                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);

                                .inbox-card-name {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }

                                .inbox-card-count {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: 600;
                                }
                            }

                            .inbox-card-name {
                                color: map-get($current-theme, theme-contentPrimary);
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                width: 80%;
                                display: inline-block;
                            }

                            .inbox-card-count {
                                color: map-get($current-theme, theme-primary);
                                font-weight: bold;
                            }

                            .all-card {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    .border-search-box {
                        text-align: center;
                        color: map-get($current-theme, theme-backgroundPrimary);

                        input {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }
                    }
                }

                .cls-insidebox {
                    .cls-cross {
                        border: 1px solid map-get($current-theme, theme-contentSecondary);
                        width: 16px;
                        height: 16px;
                        border-radius: 100px;
                        background-color: transparent;
                        @include flexBox(center, center);

                        i {
                            color: map-get($current-theme, theme-contentSecondary);
                            cursor: pointer;
                        }
                    }

                    .cls-inside {
                        background-color: map-get($current-theme, theme-backgroundSecondary);

                        .cls-title {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .cls-hrline {
                            background-color: map-get($current-theme, theme-borderOpaque);
                        }

                        .cls-txt {
                            color: map-get($current-theme, theme-contentTertiary);

                            span {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // request L1
            .sidebar-menu-outer-req {
                .reqBtnWrap {
                    border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);
                }

                .cls-outermenu {
                    .submenu-1 {
                        color: map-get($current-theme, theme-contentPrimary);
                        display: block;
                        padding-left: 12px;

                        &.active-submenu-1,
                        &:hover {
                            background-color: map-get($current-theme, theme-primaryAccent);
                            color: map-get($current-theme, theme-primary);
                            font-weight: bold;
                        }
                    }
                }
            }

            // L3 menu
            .inbox_left {
                box-shadow: -4px 0px 5px 0px map-get($current-theme, theme-menu-shadow);
                z-index: 10;
                background-color: map-get($current-theme, theme-backgroundPrimary);
                border-right: 1px solid map-get($current-theme, theme-borderOpaque);

                .inbox-tab {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    border-color: map-get($current-theme, theme-borderOpaque);
                    border-style: solid;
                    border-width: 0 0 1px 0;

                    span,
                    .search {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .showAllNewMessage {
                        background-color: map-get($current-theme, theme-backgroundTertiary);
                        color: map-get($current-theme, theme-contentPrimary);
                        border-radius: 4px;
                    }

                    .cls-archivebox {
                        .fa-box-archive {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .search {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .employee_search {
                        background-color: map-get($current-theme, theme-backgroundTertiary);
                        color: map-get($current-theme, theme-contentPrimary);
                        width: 70%;
                        height: 34px;

                        &::-webkit-input-placeholder {
                            color: map-get($current-theme, theme-contentTertiary);
                            font-size: 10px;
                        }
                    }

                    .cross {
                        top: 11px;
                    }
                }

                .tab-card {
                    border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                    .tab-card-body {
                        background-color: map-get($current-theme, theme-backgroundPrimary);

                        .tab-card-title {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .tab-card-subtitle {
                            color: map-get($current-theme, theme-contentSecondary);
                        }

                        .tab-card-time {
                            color: map-get($current-theme, theme-contentTertiary);
                        }

                        .tab-card-text {
                            .tab-card-Category {
                                color: map-get($current-theme, theme-primary);
                            }

                            .tab-card-confirm {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            .tab-card-duedate {
                                color: map-get($current-theme, theme-error);

                                .fa-circle-exclamation {
                                    color: map-get($current-theme, theme-error);
                                    font-size: 10px;
                                }
                            }
                        }

                        &:hover {
                            background-color: map-get($current-theme, theme-primaryAccent);
                        }

                        &.active {
                            background-color: map-get($current-theme, theme-primaryAccent);
                        }
                    }
                }

            }


            // inbox-outlet
            .workflow_base {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                .cls-line {
                    background-color: map-get($current-theme, theme-backgroundTertiary);
                }

                .workflow_base_title1 {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .workflow_base_box1 {
                    &::before {
                        background-color: map-get($current-theme, theme-backgroundTertiary);
                    }

                    .workflow_base-heading {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .workflow_base-contentbox {
                        border: 1px solid map-get($current-theme, theme-backgroundTertiary);

                        .workflow_base-heading {
                            color: map-get($current-theme, theme-contentPrimary);

                        }

                        .workflow_base-dim,
                        .workflow_base-text {
                            color: map-get($current-theme, theme-contentSecondary);
                        }

                        .cls-reminderbtn {
                            color: map-get($current-theme, theme-primary) !important;

                            &:hover {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                            }
                        }
                    }

                    .workflow_base-contentbox1 {
                        border: 1px solid map-get($current-theme, theme-backgroundTertiary);

                        .cls-reminderbtn {
                            color: map-get($current-theme, theme-primary) !important;

                            &:hover {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                            }
                        }
                    }
                }

                .workflow_base_top {
                    background-color: map-get($current-theme, theme-primary);
                    color: map-get($current-theme, theme-white);

                    &.pending-Workflow {
                        background-color: map-get($current-theme, theme-backgroundTertiary);
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    &.error-Workflow {
                        background-color: map-get($current-theme, theme-error);
                        color: map-get($current-theme, theme-white);
                    }
                }
            }

            .inbox-outlet,
            .insight-inbox-content {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                .inbox-top-panel {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    .cls-titletop,
                    .cls-subtitle {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .inboxHeading {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .inbox-div {
                        .title-text {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }

                        .sys-text {
                            span {
                                color: map-get($current-theme, theme-contentSecondary) !important;
                            }
                        }

                        .cls-subhdtext {
                            color: map-get($current-theme, theme-contentSecondary) !important;
                        }
                    }


                }


                .loan_inbox {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    .loan_inbox_top {
                        background-color: map-get($current-theme, theme-primary);
                        color: map-get($current-theme, theme-white);

                        &.overdue,
                        &.missed-to-repond {
                            background-color: map-get($current-theme, theme-error);
                            color: map-get($current-theme, theme-white);
                        }

                        &.undostate {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .card-title {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .m-attendance-inner {
                        &._user-snippet {
                            border: 1px solid map-get($current-theme, theme-borderOpaque);

                            hr {
                                border: 1px solid map-get($current-theme, theme-borderOpaque);
                            }

                            ._snippet-inner {
                                ._user-detail {
                                    h5 {
                                        color: map-get($current-theme, theme-contentPrimary);

                                        span {
                                            color: map-get($current-theme, theme-contentSecondary);
                                        }
                                    }

                                    .Profile_des {
                                        color: map-get($current-theme, theme-contentSecondary);
                                    }
                                }
                            }
                        }

                        ._customize_tabs {
                            ._customize_tabs-inner {
                                .mat-accordion {
                                    .mat-expansion-panel {
                                        background-color: map-get($current-theme, theme-backgroundPrimary);

                                        &:not([class*="mat-elevation-z"]) {
                                            border: 1px solid map-get($current-theme, theme-borderOpaque);
                                        }

                                        .mat-expansion-panel-header {
                                            .mat-content {
                                                .mat-expansion-panel-header-title {
                                                    color: map-get($current-theme, theme-contentPrimary);
                                                }
                                            }
                                        }

                                        .mat-expansion-panel-content {
                                            .mat-expansion-panel-body {
                                                .cls-hrline {
                                                    background-color: map-get($current-theme, theme-contentTertiary);
                                                }

                                                .cls-prtobj,
                                                .cls-lbldoc,
                                                .cls-value {
                                                    color: map-get($current-theme, theme-contentSecondary);
                                                }

                                                .cls-goalper {
                                                    color: map-get($current-theme, theme-contentPrimary);
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                }

            }

            .loan_inbox {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                .loan_inbox_top {
                    background-color: map-get($current-theme, theme-primary);
                    color: map-get($current-theme, theme-white);

                    &.overdue,
                    &.missed-to-repond {
                        background-color: map-get($current-theme, theme-error);
                        color: map-get($current-theme, theme-white);
                    }
                }

                .card-title {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .m-attendance-inner {
                    &._user-snippet {
                        border: 1px solid map-get($current-theme, theme-borderOpaque);

                        hr {
                            border: 1px solid map-get($current-theme, theme-borderOpaque);
                        }

                        ._snippet-inner {
                            ._user-detail {
                                h5 {
                                    color: map-get($current-theme, theme-contentPrimary);

                                    span {
                                        color: map-get($current-theme, theme-contentSecondary);
                                    }
                                }

                                .Profile_des {
                                    color: map-get($current-theme, theme-contentSecondary);
                                }
                            }
                        }
                    }

                    ._customize_tabs {
                        ._customize_tabs-inner {
                            .mat-accordion {
                                .mat-expansion-panel {
                                    background-color: map-get($current-theme, theme-backgroundPrimary);

                                    &:not([class*="mat-elevation-z"]) {
                                        border: 1px solid map-get($current-theme, theme-borderOpaque);
                                    }

                                    .mat-expansion-panel-header {
                                        .mat-content {
                                            .mat-expansion-panel-header-title {
                                                color: map-get($current-theme, theme-contentPrimary);
                                            }
                                        }
                                    }

                                    .mat-expansion-panel-content {
                                        .mat-expansion-panel-body {
                                            .cls-hrline {
                                                background-color: map-get($current-theme, theme-contentTertiary);
                                            }

                                            .cls-prtobj,
                                            .cls-lbldoc,
                                            .cls-value {
                                                color: map-get($current-theme, theme-contentSecondary);
                                            }

                                            .cls-goalper {
                                                color: map-get($current-theme, theme-contentPrimary);
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }

            }

            // hrdesk
            .hrDesk-content {
                border-left: 1px solid map-get($current-theme, theme-borderOpaque);
            }

            // profile
            .side-menu,
            .sidebarmenu {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                //hrdesk
                .sidebar-menu-setup {
                    li {
                        .cls-menu1 {
                            padding-left: 12px;
                            cursor: pointer;

                            &.active-submenu-1,
                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);

                                .cls-submenu1 {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;
                                }
                            }

                            .cls-submenu1 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    &.nestedMenu {
                        li {
                            .cls-menu1 {
                                font-weight: 400;
                                color: map-get($current-theme, theme-contentPrimary);

                                &.active-submenu-1,
                                &:hover {
                                    background-color: transparent;
                                    font-weight: 600;
                                }
                            }

                            .sub-menu {
                                li {
                                    padding-left: 0 !important;

                                    .cls-menu2 {
                                        color: map-get($current-theme, theme-contentPrimary);
                                        padding-left: 18px !important;

                                        &.active,
                                        &:hover {
                                            background-color: map-get($current-theme, theme-primaryAccent);
                                            color: map-get($current-theme, theme-primary);
                                            font-weight: bold;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .sidebar-menu-req {
                    .reqBack {
                        color: map-get($current-theme, theme-contentPrimary);
                        padding-left: 12px;
                    }

                    .sub-menu {
                        li {
                            .cls-subtask {
                                padding-left: 12px;

                                a {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }

                                &.active,
                                &:hover {
                                    background-color: map-get($current-theme, theme-primaryAccent);
                                    font-weight: bold;

                                    a {
                                        color: map-get($current-theme, theme-primary);
                                    }
                                }
                            }
                        }
                    }
                }

                .nav-devider {
                    background-color: map-get($current-theme, theme-backgroundTertiary);
                }

                .cls-moduleSearch {
                    background-color: transparent;
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                    color: map-get($current-theme, theme-contentPrimary);
                    border-radius: 4px;

                    input {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                .cls-bluebtn {
                    background-color: map-get($current-theme, theme-primary);
                    border: 1px solid map-get($current-theme, theme-primary);
                    color: map-get($current-theme, theme-white);
                }

                .sidebar-menu-profile {
                    list-style: none;

                    li {
                        .menuItem {
                            .cls-submenu {
                                color: map-get($current-theme, theme-contentPrimary);
                                padding-left: 12px;
                            }

                            &.active,
                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);

                                .cls-submenu {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;
                                }
                            }
                        }

                    }
                }

                //new

                .menus {

                    .sidebar-menu-newprofile {
                        .module_list {
                            .search-title {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            .menuItem {
                                @include flexBox(center, unset);
                                width: 100%;
                                padding: 0 14px;

                                .cls-submenu,
                                .cls-newmenu {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }

                                &.active,
                                &:hover {
                                    background-color: map-get($current-theme, theme-primaryAccent);

                                    .cls-submenu,
                                    .cls-newmenu {
                                        color: map-get($current-theme, theme-primary);
                                        font-weight: bold;
                                    }
                                }

                                .cls-fav {
                                    &.text-gray {
                                        color: map-get($current-theme, theme-contentSecondary);
                                    }

                                    &.text-red {
                                        color: map-get($current-theme, theme-primary);
                                    }
                                }
                            }

                            .cls-viewallbtn {
                                background-color: map-get($current-theme, theme-primary);
                                color: map-get($current-theme, theme-white);
                                border: 1.5px solid map-get($current-theme, theme-primary);

                                &:hover {
                                    border: 1.5px solid map-get($current-theme, theme-primary);
                                    background-color: transparent;
                                    color: map-get($current-theme, theme-primary);
                                }
                            }

                            .nestedItem {
                                padding-left: 8px;

                                &.text-fav {
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;

                                    i {
                                        color: map-get($current-theme, theme-contentPrimary);
                                        font-size: 12px;
                                        transform: rotate(180deg);
                                    }
                                }
                            }

                            .submenu {
                                li {
                                    .menuItem {
                                        @include flexBox(center, unset);
                                        width: 100%;
                                        padding: 0 14px;

                                        .cls-submenu {
                                            color: map-get($current-theme, theme-contentPrimary);
                                        }

                                        &.active,
                                        &:hover {
                                            background-color: map-get($current-theme, theme-primaryAccent);

                                            .cls-submenu {
                                                color: map-get($current-theme, theme-primary);
                                                font-weight: bold;
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }
            }


            // request L3 content
            .request_header_base {
                background-color: map-get($current-theme, theme-backgroundPrimary);
            }

            .inbox-top-panel {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                .flyer-with-snippet {
                    .header-title {
                        color: map-get($current-theme, theme-contentPrimary);

                        &::before {
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                        }
                    }
                }
            }



            // hrDesk
            .hrDesk-content {
                .clssearch-container {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                }

                .clsinputtext {
                    color: map-get($current-theme, theme-contentPrimary);
                }
            }

            .quickhackscard {
                .cls-box {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    .quickhackinnercard {
                        &:hover {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }

                        color: map-get($current-theme, theme-contentSecondary);

                        .quickhackimg {
                            background-color: map-get($current-theme, theme-backgroundSecondary);
                            border-radius: 6px;
                            @include flexBox(center, center);

                            i {
                                font-size: 20px;
                                color: map-get($current-theme, theme-contentSecondary);
                            }
                        }

                        .quickhackcarddetails {
                            h4 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            p,
                            .cls-wordtxt {
                                color: map-get($current-theme, theme-contentSecondary);
                            }
                        }
                    }
                }

            }

            // report
            .report-wrapper {
                .clsReport {
                    .header-section {
                        background-color: map-get($current-theme, theme-backgroundPrimary);

                        .left {
                            i {
                                color: map-get($current-theme, theme-primary);
                            }

                            h2 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }

                        .right {

                            .search,
                            .favourite {
                                color: map-get($current-theme, theme-contentPrimary);

                                &.activeIcon {
                                    i {
                                        color: map-get($current-theme, theme-primary);
                                    }

                                }
                            }

                            .clsrep-search {
                                button {
                                    i {
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }

                                }

                            }

                            .report-search-box {
                                .search-box {
                                    .search-box-inner {
                                        .fa-magnifying-glass {
                                            position: absolute;
                                            top: 40%;
                                            left: 20px;
                                        }
                                    }
                                }
                            }
                        }

                    }

                }

                .clsSlidebar {
                    &.report-content-area {
                        ul {
                            li {
                                a {
                                    background-color: map-get($current-theme, theme-backgroundPrimary);

                                    &.active,
                                    &:hover {
                                        background-color: map-get($current-theme, theme-primary);

                                        .card-title-length,
                                        .report-no {
                                            color: map-get($current-theme, theme-white);
                                        }
                                    }

                                    .card-title-length,
                                    .report-no {
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }
                                }
                            }
                        }
                    }
                }

            }

            .product_map {
                background-image: linear-gradient(to bottom, map-get($current-theme, theme-backgroundSecondary) 0%, rgba(251, 251, 251, 0) 93%);

                .cdk-drag {
                    &:hover {
                        @extend .bgPrimaryAccent;
                    }
                }
            }

            .report-card-wrapper {
                .report-card-wrapper-inner {
                    .report-card {
                        .report-card-inner {
                            background-color: map-get($current-theme, theme-backgroundPrimary);

                            h3 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            a {
                                p {
                                    color: map-get($current-theme, theme-contentSecondary);
                                }
                            }
                        }
                    }
                }
            }

            // insights
            .hrdesk-wrapper {
                &.insights {
                    h2 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .cls-leftside {
                        .pendingtaskfilter {
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                        }

                        .pendingtasksbox {
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                            @include box-shadow(1px, 4px, 12px, map-get($current-theme, theme-menu-shadow));

                            .pendingtaskupperbox {
                                border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                                p {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }

                                .cls-archivebox {
                                    background-color: map-get($current-theme, theme-backgroundTertiary);
                                    color: map-get($current-theme, theme-primary);

                                    i {
                                        color: map-get($current-theme, theme-primary);
                                    }

                                    &:hover {
                                        background-color: map-get($current-theme, theme-primary);
                                        color: map-get($current-theme, theme-white);

                                        i {
                                            color: map-get($current-theme, theme-white);
                                        }
                                    }
                                }
                            }

                            .pendingtaskinnerbox {
                                .component-slider-wrapper {
                                    p {
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }

                                    .component-slider-wrapper-inner {
                                        p-carousel {
                                            .p-carousel-content {
                                                background-color: map-get($current-theme, theme-backgroundPrimary);

                                                .p-carousel-item {
                                                    .insight-wrapbox {
                                                        .component-content {
                                                            box-shadow: inset 0 0 3px map-get($current-theme, theme-menu-shadow);

                                                            &:hover {
                                                                background-color: map-get($current-theme, theme-primaryAccent);
                                                            }

                                                            .insights-dot {
                                                                i {
                                                                    color: map-get($current-theme, theme-primary);
                                                                }

                                                                p {
                                                                    color: map-get($current-theme, theme-contentPrimary);
                                                                }
                                                            }

                                                            .cardCountNo {
                                                                color: map-get($current-theme, theme-contentPrimary);
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }

                    .cls-rightside {
                        .cls-blank {
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                        }

                        .createfirstcanvastop {
                            border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                            h5 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // insight inbox 

            .rpanel-title1 {
                background-color: map-get($current-theme, theme-backgroundPrimary);
                border-bottom: 2px solid map-get($current-theme, theme-borderOpaque);

                h3,
                .material-icons {
                    color: map-get($current-theme, theme-contentPrimary);
                }
            }

            .insightInboxWrapper {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                .cls-leftside {
                    .insightinbox-tab {
                        background-color: map-get($current-theme, theme-backgroundPrimary);
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        span,
                        .search {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .tab-card {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        .tab-card-body {
                            background-color: map-get($current-theme, theme-backgroundPrimary);

                            .tab-card-title {
                                color: map-get($current-theme, theme-contentPrimary);
                                margin-bottom: 8px;
                            }

                            .tab-card-subtitle {
                                color: map-get($current-theme, theme-contentSecondary);
                            }

                            .tab-card-time {
                                color: map-get($current-theme, theme-contentTertiary);
                            }

                            .tab-card-text {
                                .tab-card-Category {
                                    color: map-get($current-theme, theme-primary);
                                }

                                .tab-card-confirm {
                                    color: map-get($current-theme, theme-contentPrimary);
                                    font-size: 10px;
                                }

                                .tab-card-duedate {
                                    color: map-get($current-theme, theme-error);

                                    .fa-circle-exclamation {
                                        color: map-get($current-theme, theme-error);
                                        font-size: 10px;
                                    }
                                }
                            }

                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);
                            }

                            &.active {
                                background-color: map-get($current-theme, theme-primaryAccent);
                            }
                        }
                    }
                }

                .insight-inbox-content {
                    border-left: 1px solid map-get($current-theme, theme-borderOpaque);
                }
            }

            //mainReports
            .clsmagangereport {
                background-color: map-get($current-theme, theme-backgroundPrimary);
            }

            .clsmainreport,
            ._flyer-header {
                .Header-panel {
                    background-color: map-get($current-theme, theme-primary);

                    .clstransportreport {
                        .clsreportimg {
                            i {
                                color: map-get($current-theme, theme-white);
                            }
                        }
                    }

                }

                .clssavedreports {
                    background-color: map-get($current-theme, theme-backgroundHeader);
                }
            }

            .reportWrapper,
            .clssavedreports {
                .leftside-panel {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    .clsfiltertext {
                        color: map-get($current-theme, theme-primary);
                    }

                    .clssidemenu {
                        li {

                            &.active,
                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);

                                a {
                                    color: map-get($current-theme, theme-primary);

                                    .mandatoryField {
                                        color: map-get($current-theme, theme-primary);
                                    }
                                }
                            }

                            a {
                                color: map-get($current-theme, theme-contentPrimary);

                                .mandatoryField {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }

                .middleside-panel {
                    background-color: map-get($current-theme, theme-backgroundSecondary);
                    border-left: 1px solid map-get($current-theme, theme-borderOpaque);

                    .clsPanelHeading {
                        color: map-get($current-theme, theme-contentPrimary);
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);
                    }
                }

                .rightside-panel {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    @include box-shadow(1px, 4px, 12px, map-get($current-theme, theme-menu-shadow));

                    .globheading_filter {
                        .clstextheading {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .clsopensidebar {
                            a {
                                color: map-get($current-theme, theme-primary);
                            }
                        }
                    }

                    .filter-footer {
                        .clsreporttemplate {
                            background-color: map-get($current-theme, theme-backgroundSecondary);

                            .clsreportbottom {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }

                .rightside-increasepanel {
                    .filter-footer {
                        .clsreporttemplate {
                            background-color: map-get($current-theme, theme-backgroundSecondary);

                            .clsreportbottom {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // hrdesk action
            .hrDeskAction {
                .p-sidebar-content {
                    .flyer-content {
                        .recentactionssearch {
                            h5 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }

                        .container-coulmn {
                            h3 {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            .inner-list {
                                .clsmoduletext {
                                    color: map-get($current-theme, theme-contentPrimary);

                                    &:hover {
                                        color: map-get($current-theme, theme-primary);
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // hrdesk import
            .importFlyer {
                .recentactionssearch {
                    h5 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                .container-coulmn {
                    h3 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .inner-list {
                        .clsmoduletext {
                            color: map-get($current-theme, theme-contentPrimary);

                            &:hover {
                                color: map-get($current-theme, theme-primary);
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            // hrdesk globalSearch
            ._globalSearcDialog {
                box-shadow: 0px 0px 5px 0px map-get($current-theme, theme-menu-shadow);

                .p-dialog-content {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    h2 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .dialogContentInner {
                        .available-search-modal {
                            .card-columns {
                                li {
                                    .module-heading {
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }

                                    .list-link {
                                        color: map-get($current-theme, theme-contentPrimary);

                                        &:hover {
                                            color: map-get($current-theme, theme-primary);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            //hr analytics
            .cls-analytic-dashboard {
                .reportActionBtn {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                    border-radius: 4px;
                    height: 32px;
                    width: 32px;
                    margin-left: 8px;
                    @include flexBox(center, center);
                    cursor: pointer;

                    i {
                        color: map-get($current-theme, theme-contentSecondary);
                    }

                    &:hover {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                        border: 1px solid map-get($current-theme, theme-borderOpaque);

                        i {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }
                }
            }

            .counter-container {
                .mdc-card {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    border: 2px solid map-get($current-theme, theme-backgroundPrimary);
                    @include box-shadow(1px, 4px, 12px, map-get($current-theme, theme-menu-shadow));

                    .mat-mdc-card-header {
                        background-color: map-get($current-theme, theme-backgroundPrimary);
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);
                        color: map-get($current-theme, theme-contentPrimary);

                        .cls-heading {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .mat-mdc-card-content {
                        .cls-bodycnt {

                            .cls-count,
                            .cls-nortxt {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // team


            // widget count
            .team-box {
                background-color: map-get($current-theme, theme-backgroundSecondary) !important;

                .cls-space {
                    h5 {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    i {
                        color: map-get($current-theme, theme-primary);
                    }
                }
            }

            .cls-team {
                .clstopbar {
                    .clstexttop {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                .cls-teambox {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    .cls-header {
                        h5 {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .wiz-btn {
                            color: map-get($current-theme, theme-primary);
                        }
                    }
                }

                .team-contentbox {
                    hr {
                        background-color: map-get($current-theme, theme-borderOpaque);
                    }
                }

                .cls-teamcard {
                    .cls-empdetail {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    .pos-and-com {
                        .cls-emp-det1 {
                            color: map-get($current-theme, theme-contentSecondary);
                        }
                    }

                    &:hover {
                        background-color: map-get($current-theme, theme-primaryAccent);
                    }
                }
            }


            // hrPOST
            .cls-notebox {
                background-color: map-get($current-theme, theme-backgroundTertiary);
                border: 1px solid map-get($current-theme, theme-backgroundTertiary);
                color: map-get($current-theme, theme-contentPrimary);

                &.warningnote {
                    background-color: rgba(247, 144, 9, 0.05);
                    border: 1px solid map-get($current-theme, theme-warning);
                }

                &.warningnotedarkbg {
                    background-color: rgba(255, 245, 215, 1);
                }

                p {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .help {
                    color: map-get($current-theme, theme-error);
                }
            }

            .clsfilter-box {
                .cls-search-filter {
                    background-color: map-get($current-theme, theme-backgroundTertiary);
                    border: 1px solid map-get($current-theme, theme-backgroundTertiary);
                    color: map-get($current-theme, theme-contentPrimary);

                    &:hover,
                    &:focus {
                        background-color: map-get($current-theme, theme-primaryAccent);
                        border: 1px solid map-get($current-theme, theme-primaryAccent);
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

            }


            // notification
            .vertical-stepper-wrapper {
                .vertical-stepper {
                    .user-request {
                        &._card1hrv_5 {
                            background-color: map-get($current-theme, theme-primaryAccent);

                            &::after {
                                background-color: map-get($current-theme, theme-primaryAccent);
                            }
                        }

                    }
                }

                &::after {
                    background-color: map-get($current-theme, theme-borderOpaque);
                }
            }

            // pin-sidebar
            .cls-pinnedmenu {
                .mat-mdc-tab-header {
                    .mat-mdc-tab-label-container {
                        .mat-mdc-tab-list {
                            .mat-mdc-tab-labels {
                                background-color: map-get($current-theme, theme-backgroundSecondary);
                            }
                        }
                    }
                }
            }


            // marketPlace tooltip
            .transition-wrapper {
                background-color: map-get($current-theme, theme-primaryAccent);
                border-bottom: 2px solid map-get($current-theme, theme-primary);
                box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.24);

                &::after {
                    border-bottom: 7px solid map-get($current-theme, theme-primaryAccent);
                }

                .transition-info {
                    color: map-get($current-theme, theme-contentPrimary);
                }
            }
            //setup tooltip color

            div#showPinPopOver.transition-wrapper-popover 
            {
                &::after 
                {
                    border-color: transparent map-get($current-theme, theme-primaryAccent) transparent transparent;
                }
               
            }
            // socialProfile
            .cls-socialbg {
                background-color: map-get($current-theme, theme-backgroundHeader);
            }

            // progressbar
            .cls-grouping {
                mat-progress-bar {
                    .mdc-linear-progress__bar {
                        &.mdc-linear-progress__primary-bar {
                            top: 0px;
                            left: 0;
                        }

                    }
                }
            }

            mat-progress-bar {
                .mdc-linear-progress__bar {
                    &.mdc-linear-progress__primary-bar {
                        top: -2px;
                        left: 0;
                    }

                }
            }

            // jobop-flyer
            .jobop-flyer {
                ._uBirthList {
                    .wish-card {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        ._user-details {
                            ._u-desg {
                                h3 {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }
            }

            // drag reorder
            .cdk-drop-list {
                .cdk-drag {
                    color: map-get($current-theme, theme-contentPrimary);



                    &:hover {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                    }
                }
            }


            // wizarad left bar
            aw-wizard-navigation-bar {
                &.vertical {
                    background-color: map-get($current-theme, theme-backgroundSecondary);

                    ul {
                        li {
                            a {
                                .label {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }
            }

            .clswizleft {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                h3 {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .btnclose {
                    background-color: map-get($current-theme, theme-backgroundSecondary);

                    .material-icons {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    &:hover {
                        background-color: map-get($current-theme, theme-backgroundTertiary);
                    }
                }
            }

            // clsglobal-search
            .clsglobal-search {
                .clssearch-container {
                    .empSearch {
                        &.clssearch-module {
                            p-autocomplete {
                                .p-autocomplete {
                                    .p-autocomplete-input {
                                        background: map-get($current-theme, theme-backgroundSecondary);
                                        border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                                        color: map-get($current-theme, theme-contentPrimary);

                                        &:focus,
                                        &:hover {
                                            border: 1px solid map-get($current-theme, theme-borderFocus) !important;
                                        }
                                    }
                                }
                            }
                        }

                        .clssearch-emp {
                            p-autocomplete {
                                .p-autocomplete {
                                    .p-autocomplete-multiple-container {
                                        background: map-get($current-theme, theme-backgroundSecondary) !important;
                                        border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                                        width: 90.5%;
                                        gap: 0;

                                        .p-autocomplete-token {
                                            background: map-get($current-theme, theme-backgroundTertiary) !important;
                                            color: map-get($current-theme, theme-contentPrimary) !important;
                                            // width: 46%;


                                            .p-autocomplete-token-label {
                                                white-space: nowrap;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                width: 100%;
                                                display: inline-block;

                                            }
                                        }

                                        .p-autocomplete-input-token {
                                            background: map-get($current-theme, theme-backgroundSecondary);
                                            padding-right: 1px !important;

                                            input {
                                                background: transparent !important;
                                                border: 1px solid transparent !important;
                                                color: map-get($current-theme, theme-contentPrimary);

                                                &:focus,
                                                &:hover {
                                                    border: 1px solid map-get($current-theme, theme-borderFocus) !important;

                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }



                        .cls-secbtn {
                            border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                        }
                    }
                }
            }


            .cls-searchbox {
                .cls-globalsearch {
                    &.p-autocomplete-multiple {
                        .p-autocomplete-multiple-container {
                            .p-autocomplete-token {
                                background: map-get($current-theme, theme-backgroundPrimary);
                                border: 1px solid map-get($current-theme, theme-borderOpaque);
                                flex: inherit;
                                color: map-get($current-theme , theme-contentPrimary);

                            }
                        }
                    }
                }
            }


            // typeManager hrdesk filter popup
            .typeManager {
                &.hrDeskSearch {
                    p-autocomplete {
                        .p-autocomplete {
                            input {
                                color: map-get($current-theme, theme-contentPrimary);
                                font-size: 12px;

                                &::placeholder {
                                    font-size: 12px;
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }
            }


            //hrdesk accordion
            .cls-chekinaccordion {
                mat-accordion {
                    &.cls-empaccord {
                        .mat-expansion-panel {
                            .mat-expansion-panel-header {
                                &.mat-expanded {
                                    padding-top: 16px;
                                }

                                border: none !important;
                            }
                        }
                    }
                }
            }

            .clscard-search {
                ._cls-Leftpanel {
                    background: map-get($current-theme, theme-backgroundPrimary);

                    .clsfilter-box {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        .clsfilter {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .search-card-wrapper {
                        .card {
                            background: map-get($current-theme, theme-backgroundSecondary);

                            .card-body {
                                ._user-snippet {
                                    &::after {
                                        background-color: map-get($current-theme, theme-primary) !important;
                                    }


                                }

                                .completed-status {
                                    color: map-get($current-theme, theme-primary);
                                }

                                .completed-status {
                                    span {
                                        color: map-get($current-theme, theme-primary);
                                    }
                                }

                                .pending-status {
                                    color: map-get($current-theme, theme-warning);
                                }

                                .pending-status {
                                    span {
                                        color: map-get($current-theme, theme-warning);
                                    }
                                }

                                // * {
                                //     color: map-get($current-theme, theme-contentPrimary);
                                // }
                                ._snippet-inner {
                                    &::after {
                                        background-color: map-get($current-theme, theme-primary);
                                    }
                                }
                            }

                        }
                    }
                }
            }

            ._cls-Leftpanel {
                background: map-get($current-theme, theme-backgroundPrimary);

                .clsfilter-box {
                    border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                    .clsfilter {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                .search-card-wrapper {
                    .card {
                        background: map-get($current-theme, theme-backgroundSecondary);

                        .card-body {
                            ._user-snippet {
                                &::after {
                                    background-color: map-get($current-theme, theme-primary) !important;
                                }


                            }

                            .completed-status {
                                color: map-get($current-theme, theme-primary);
                            }

                            .completed-status {
                                span {
                                    color: map-get($current-theme, theme-primary);
                                }
                            }

                            .pending-status {
                                color: map-get($current-theme, theme-warning);
                            }

                            .pending-status {
                                span {
                                    color: map-get($current-theme, theme-warning);
                                }
                            }

                            // * {
                            //     color: map-get($current-theme, theme-contentPrimary);
                            // }
                            ._snippet-inner {
                                &::after {
                                    background-color: map-get($current-theme, theme-primary);
                                }
                            }
                        }

                    }
                }
            }

            // newtab header page
            #payroll-header {
                .top-navbar {
                    border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                    background: map-get($current-theme, theme-primary);
                }
            }

            .process-salary-content {
                background: map-get($current-theme, theme-backgroundPrimary);

                .cls-processbtn {
                    border: 1px solid map-get($current-theme, theme-primary) !important;
                }

                .clsmonth-selector {
                    .clsbutton {
                        background: map-get($current-theme, theme-primary);
                    }

                    .clsmonth,
                    .clsanc_button {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                app-salary-process-steps-card {
                    .card-border {
                        border: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                        .cls-rightbr {
                            border-right: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                            // * {
                            //     color: map-get($current-theme, theme-contentPrimary) !important;
                            // }
                        }
                    }
                }
            }

            // filtertext
            .clsfilter-box {
                .clsfilter {
                    span {
                        color: map-get($current-theme, theme-contentPrimary) !important
                    }
                }
            }

            // table count
            .codeseriesdatatable {
                .custom-check-box {
                    .bg-white {
                        background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                        mat-select {
                            background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                        }
                    }
                }
            }

            .clsshw {
                span {
                    color: map-get($current-theme, theme-contentPrimary) !important
                }
            }

            .table-count {
                mat-select {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                    border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                }
            }

            .recurring-wrapper {
                ul {
                    li {
                        &.selected-box {
                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                            border-color: map-get($current-theme, theme-primary) !important;
                        }
                    }
                }
            }

            .clsalias {
                &.active {
                    background-color: map-get($current-theme, theme-primary) !important;
                    color: map-get($current-theme, theme-white) !important
                }
            }



            .round-input {
                &.wishTextarea {
                    .mat-mdc-form-field {
                        .mat-mdc-text-field-wrapper {
                            .mat-mdc-form-field-flex {
                                .mat-mdc-form-field-infix {
                                    border: none !important;

                                    textarea {
                                        margin: 0;
                                        padding: 0 !important;
                                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                                    }
                                }
                            }

                            .mdc-line-ripple {
                                &::after {
                                    border: none !important
                                }

                                &::before {
                                    border-bottom: none !important;
                                }
                            }
                        }
                    }
                }

                .mat-mdc-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mat-mdc-form-field-flex {
                            .mat-mdc-form-field-infix {
                                border: none !important;

                                textarea {
                                    margin: 0;
                                    padding: 0 !important;
                                    border: none !important
                                }
                            }
                        }

                        .mdc-line-ripple {
                            &::after {
                                border: none !important
                            }

                            &::before {
                                border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                            }
                        }
                    }
                }
            }

            // leave
            .cls-lvcard {
                .cls-firsthalf {
                    border: 1px solid map-get($current-theme, theme-primary);
                    background: linear-gradient(-45deg, map-get($current-theme, theme-backgroundPrimary) 49%, map-get($current-theme, theme-backgroundPrimary)51%, map-get($current-theme, theme-primary) 51%);
                }

                .cls-secondhalf {
                    border: 1px solid map-get($current-theme, theme-primary);
                    background: linear-gradient(-45deg, map-get($current-theme, theme-primary) 49%, map-get($current-theme, theme-backgroundPrimary) 51%);
                }
            }

            .onboardingDash {
                &.alerts-page-list {
                    ul {
                        li {

                            &:hover,
                            &.active {
                                a {
                                    .moduleName {
                                        color: map-get($current-theme, theme-primary) !important;
                                    }
                                }

                                background-color: map-get($current-theme, theme-primaryAccent);

                                i.fa-chevron-right {
                                    color: map-get($current-theme, theme-primary) !important;
                                }
                            }
                        }
                    }
                }
            }

            .scrollable-tabs {
                ul {
                    li {
                        button {
                            color: map-get($current-theme, theme-contentSecondary) !important;

                            &.active {
                                color: map-get($current-theme, theme-contentPrimary) !important;

                                &::after {
                                    border-bottom: 2px solid map-get($current-theme, theme-primary);
                                }
                            }
                        }
                    }
                }
            }

            .hint {
                b {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            .cls-badge-setting {
                #TG-BTN {
                    &.switch {
                        input {
                            &:checked {
                                &+.slider {
                                    background-color: map-get($current-theme, theme-primaryAccent);

                                    &::before {
                                        background-color: map-get($current-theme, theme-primary);
                                    }
                                }
                            }
                        }

                        .slider {
                            background-color: map-get($current-theme, theme-backgroundDisabled);

                            &::before {
                                background-color: map-get($current-theme, theme-contentDisabled);
                            }

                            span {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            .cls-chattext {
                a {
                    color: map-get($current-theme, theme-primary)
                }
            }

            // p-organizationchart 
            p-organizationchart {
                .p-organizationchart {
                    .p-organizationchart-table {
                        tr {
                            td {
                                background-color: map-get($current-theme, theme-backgroundPrimary);
                            }
                        }
                    }
                }
            }

            // salary dashboard 7step
            .cls-salary-box {
                .card-title {
                    &::before {
                        background-color: map-get($current-theme, theme-primary);
                    }
                }
            }

            // aatachment button on request
            #Reimfileupload {
                p-fileupload {
                    .p-fileupload-choose {
                        .p-button-label {
                            color: map-get($current-theme, theme-primaryLink);
                        }
                    }
                }
            }

            .comp-block-container {
                .cls-roundedbox {
                    &::after {
                        background-color: map-get($current-theme, theme-primary);
                    }
                }
            }

            // 
            .badgeSidebar {
                &.scrollable-tabs {
                    ul {
                        li {
                            &.active {
                                &::after {
                                    border-bottom: 2px solid map-get($current-theme, theme-borderFocus);
                                }
                            }
                        }
                    }
                }
            }

            // sticky inbox header
            .iconsWrapper {

                a,
                button {
                    width: max-content;
                    margin-right: 12px;

                    @include flexBox(center, center);

                    i {
                        color: map-get($current-theme, theme-contentPrimary);
                        font-size: 20px;
                    }

                    span {
                        margin-left: 5px;
                        font-size: 0;

                    }

                    &:hover {
                        transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);

                        span {
                            font-size: 12px;
                        }
                    }

                }

            }
        }
    }
}

// -------- Responsive css  -------- 
@media only screen and (min-width: 768px) and (max-width: 1180px) and (orientation: landscape) {
    .res-mxw-landscape {
        max-width: 100% !important;
    }
    .res-w-landscape {
        width: 100% !important;
    }
    .res-mx-landscape-0{
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
@media only screen and (max-width: 991px) and (orientation: landscape) {
    .mdc-menu-surface{
        &.mat-mdc-select-panel{
            max-height: 160px !important;
        }
    }
}
@media only screen and (max-width: 991px) {
    .height-change 
    {
        .max-width20 {
            max-width: 90px !important;
        }
    }
    .mat-sidenav-container {
        .home-sidenav {
            &.mat-drawer-side {
                position: fixed;
                top: 0;
                left: 0;
                width: 90%;
                max-width: 260px;
                z-index: 11 !important;
                box-shadow: 0px 0px 0px 731px rgba(0, 0, 0, 0.6);
            }

            .sidebarmenu {
                max-width: 100%;

                .sidebar-menu-inbox {
                    padding-top: 16px;

                    .res-inbox-go-back {
                        padding: 0 10px 16px;
                    }

                    .sub-menu {
                        .cls-innermenu {
                            a {
                                &.active {
                                    background-color: transparent;
                                    color: initial;

                                    .all-card,
                                    .inbox-card-name {
                                        color: initial;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .res-icons-wrapper {
            &.fixheader {
                left: 0;
            }
        }

        .cls-requestwf {
            width: calc(100% - 32px);
        }

        .res-request-container {

            .cls-mainbox,
            .cls-attendancespace,
            .cls-editgoal,
            .request_header_base {
                width: calc(100% - 32px);
                margin: 16px auto;
                padding: 16px;
            }

            .cls-attendancespace {
                padding: 0;
            }

            .cls-blank {
                font-size: 24px !important;
            }
        }

        .res-request-memu {
            .cls-outermenu {
                margin-bottom: 8px;

                .submenu-1 {
                    color: initial !important;
                    background-color: transparent !important;
                    font-size: 14px !important;
                    font-weight: 600 !important;

                    &.active-submenu-1 {
                        color: initial !important;
                        background-color: transparent !important;
                        font-size: 14px !important;
                        font-weight: 600 !important;
                    }
                }
            }
        }

        .res-feed-container {
            .mobiletabs {
                &>.mat-mdc-tab-body-wrapper {
                    width: calc(100% - 32px);
                    margin: 0px auto;

                    app-widget-cantainer-right {
                        app-wcr-calendar {
                            .seedetail {
                                font-size: 16px;
                            }

                            .attenCalendar {
                                padding-right: 8px;
                                padding-left: 8px;
                            }
                        }

                        .common-chip,
                        .white-bg-card {
                            margin-left: 0 !important;
                            margin-right: 0 !important;
                        }
                    }

                }
            }
        }

        .res-social-wall {
            margin: 8px 0 0;
        }

        .new-calender {
            .p-calendar {
                .p-datepicker {
                    .p-datepicker-group-container {
                        .p-datepicker-group {
                            .p-datepicker-calendar-container {
                                table {
                                    tbody {
                                        tr {
                                            td {
                                                span {
                                                    width: auto !important;
                                                    height: auto;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .cls-wall-calendar {
                    height: 32px !important;
                    width: 32px !important;
                }
            }
        }
    }

    .p-sidebar {
        .p-sidebar-header {
            ._flyer-header {
                padding-right: 16px;
                padding-left: 16px;
            }
        }

        .p-sidebar-content {
            padding-right: 16px;
            padding-left: 16px;
        }

        .p-sidebar-footer {
            .flyer-footer {
                padding-right: 16px;
                padding-left: 16px;

                .flyer-footer-inner {
                    button {
                        flex-basis: auto;
                        max-width: initial;
                    }
                }
            }
        }
    }

    .sideBarPrimeng,
    .shw-rside-40 {
        width: 100% !important;

        &.shw-rside-40,
        &.shw-rside-95,
        &.shw-rside-90,
        &.shw-rside-60,
        &.shw-rside-50,
        &.shw-rside-68,
        &.shw-rside-75,
        &.shw-rside-30,
        &.shw-rside-20 {
            width: 100% !important;
        }

        .new_profile_scrollClass {
            height: calc(100vh - 180px);
        }
    }

    .p-component-overlay {
        .p-dialog {
            max-width: calc(100% - 8px) !important;
            width: calc(100% - 8px) !important;
            max-height: calc(100% - 32px) !important;
        }
    }

    .res-badge-sidebar {
        .res-suggested-msg {
            margin-left: 0;
            margin-top: 24px;
            margin-bottom: 24px !important;
        }
    }

    .inbox-outlet {
        .inbox-top-panel {
            padding: 16px !important;
        }

        .loan_inbox {
            max-width: 100% !important;
            padding-left: 16px !important;
            padding-right: 16px !important;
            width: 100% !important;

            ._user-snippet {
                &.center-card {
                    width: 100% !important;
                }

                ._snippet-inner,
                ._res-details,
                ._emp-hr-list-view,
                ._review-card,
                .clsinbox-padding {
                    padding-left: 16px !important;
                    padding-right: 16px !important;
                }

                hr {
                    width: calc(100% - 32px) !important;
                }

                hr {
                    &.res-hr-100 {
                        width: 100% !important;
                    }
                }
            }

            .m-attendance-inner {
                .cls-onetoonebox {
                    padding-left: 16px !important;
                    padding-right: 16px !important;
                }
            }
        }
    }

    .res-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .res-pl-0 {
        padding-left: 0 !important;
    }

    .res-pr-0 {
        padding-right: 0 !important;
    }

    .res-px-16 {
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .res-mx-16 {
        margin-left: 16px !important;
        margin-right: 16px !important;
    }

    .res-mx-0 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    
    .res-mx-b-16 {
        margin-bottom: 16px !important;
    }
    .res-mb-8 {
        margin-bottom: 8px !important;
    }

    .res-mx-t-16 {
        margin-top: 16px !important;
    }

    .res-mx-b-24 {
        margin-bottom: 24px !important;
    }

    .res-mx-t-24 {
        margin-top: 24px !important;
    }

    .res-mx-l-15 {
        margin-left: 15px !important;
    }

    .res-mx-r-15 {
        margin-right: 15px !important;
    }
    .res-mx-r-5 {
        margin-right: 5px !important;
    }
    .responsive-captcha
    {
        zoom: 0.9;
    }
    .floating-bar
    {
        bottom:0;
        button
        {
            margin-bottom: 10px;
        }
    }
    .gridlist-new {
        &.custom-col {
          min-width: 50% !important;
          flex: 0 0 50% !important;
        }
      }
    .sm-no-ellipsis {
        text-overflow: initial !important;
        white-space: initial !important;
        overflow: initial !important;
    }

    mat-checkbox {
        &.mat-mdc-checkbox {
            .mdc-form-field {
                label {
                    text-overflow: initial;
                    white-space: initial;
                    overflow: initial;
                }
            }
        }
    }

    mat-radio-button {
        .mdc-form-field {
            label {
                text-overflow: initial !important;
                white-space: initial !important;
                overflow: initial !important;
            }
        }
    }

    .res-maxw-100 {
        max-width: 100% !important;
    }

    .res-nomination-sidebar {
        margin-left: -16px;
        margin-right: -16px;
    }

    .p-component-overlay {
        .p-dialog {

            .p-dialog-content,
            .p-dialog-header,
            .p-dialog-footer {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    p-carousel {
        .p-carousel-content {
            .p-carousel-container {
                .p-carousel-prev {
                    left: -16px;
                }

                .p-carousel-next {
                    right: -16px;
                }
            }
        }
    }

    .res-w-smmd-100 {
        width: 100% !important;
    }

    .res-h-smmd-auto {
        height: auto !important;
    }
    .hrone-dialog{
        &.snap {
            .p-dialog{
                right: unset;
            }
        }
    }
    .p-sidebar{
        &.wizard-sidebar {
            .p-sidebar-content{
                overflow-y: auto;
            }
        }
    }
    .res-container-py-0{
        .container-fluid{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
   .res-salary-ytd {
    .Blank-stat-text{
        font-size: 32px;
    }
   }
   .res-smmd-50{
    width: 50% !important;
   }
   .res-overflow-auto{
    overflow: auto;
   }
   .internal-recruiter-drop-down {
       .p-autocomplete {
           .p-autocomplete-panel {
               min-width: 300px !important;
           }
       }
   }
}

@media only screen and (max-width: 767px) {
    
    .height-change 
    {
        .max-width20 {
            max-width: 90px !important;
        }
    }
    .sidebar-filter-new
    {
        background-color: transparent !important;
    }
    .share-iconlist-copy {
        .mat-mdc-button {
          width: auto !important;
          margin-right: 2px;
         
        }
        i
        {
          font-size: 12px !important;
        }
    }    
    .heightflyer-candidate, .bulkpage
    {
        height: auto !important;
        
        .p-fileupload-content {
            height: auto !important;
            max-height: fit-content;
        }
    }
    .referal-post-job
    {
        .initiated{
            font-size: 8px !important;
        }
    }
    .res-addcandidate-wrapper
    {
        height: calc(100vh - 217px);
    }
    .cls-extaddcand {
        &.fromext
        {
            padding-top: 55px !important;
            padding-bottom: 50px;
        }
        
    }
    .responsive-footer
    {
        &.footer-external
        {
            margin: 0 !important;
            position: sticky;
            bottom: 30px;
            border-top: 1px solid rgba(0, 0, 0, .1);
            background-color: #fff;
            box-shadow: 0 -4px 12px #f4f4f440;
            height: auto !important;
            padding: 8px 16px !important;
            z-index: 9;
        }
        
    }
    .inbox-outlet {
        .loan_inbox {
            ._user-snippet {
                &._user-snippet-2 {
                    ._snippet-inner {
                        display: block !important;

                        .snippet-cta {
                            margin-top: 12px;

                            &> {
                                .text-right {
                                    text-align: left !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .res-job-opening-req {
        .job-form-wrappper {
            .job-detail-wrapper {
                max-width: 90%;
            }
        }
    }

    .res-w-50 {
        width: 50% !important;
    }

    .res-w-100 {
        width: 100% !important;
    }

    .res-flex-1 {
        flex-grow: 1;
    }

    .res-h-auto {
        height: auto !important;
    }
    .res-h-auto-200 {
        height: 200px !important;
    }
    .res-maxw-auto
    {
        max-width: max-content !important;
    }
    .res-maxh-auto {
        max-height: max-content !important;
        min-height: max-content !important;
    }
    .showscroll-mob
    {
        .p-sidebar-content 
        {
            overflow: auto !important;
        }
       &.overx-remove
       {
        .p-sidebar-content 
        {
            overflow-x: hidden !important;
        }
       }
    }
    .res-float-none {
        float: initial !important;
    }

    .res-pos-static {
        position: static !important;
    }

    .res-flex-sm-column {
        flex-direction: column;
    }
    .res-flex-reverse-col
    {
        flex-direction: column-reverse;
    }

    .res-container-p-0 {
        .container-fluid {
            padding: 0;
        }
    }

    .res-px-b-16 {
        padding-bottom: 16px !important;
    }

    .res-px-t-16 {
        padding-top: 16px !important;
    }

    .res-px-r-16 {
        padding-right: 16px !important;
    }

    .res-px-l-16 {
        padding-left: 16px !important;
    }

    .res-px-r-15 {
        padding-right: 15px !important;
    }

    .res-px-l-15 {
        padding-left: 15px !important;
    }

    .res-pb-0 {
        padding-bottom: 0 !important;
    }

    .res-pt-0 {
        padding-top: 0 !important;
    }

    .res-mx-t-0 {
        margin-top: 0px !important;
    }

    .res-mx-b-0 {
        margin-bottom: 0px !important;
    }
    .card-min20{
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }
    .res-flyer-scrollbar{
        height: calc(100vh - 162px) !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .res-img-blank
    {
        width: 30% !important;
    }
    .right-section
    {
        .res-mx-b-16 {
            margin-bottom: 0 !important;
        }
    }
    .res-flyer-scrollbar{
        height: calc(100vh - 162px) !important;
    }
    .mat-sidenav-container {
        .home-sidenav {
            &.mat-drawer-side {
                width: 50%;
                max-width: 50%;
            }
        }
    }
    .res-px-5 {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }
    .res-font-8
    {
        font-size: 8px !important;
    }
    .grid-container {
        padding-left: 16px !important;
        padding-right: 16px !important;
        grid-template-columns: 45% 55% !important;
    }

    .res-job-opening-req {
        .job-form-wrappper {
            .job-detail-wrapper {
                max-width: 100%;
            }
        }
    }
}
@media only screen and (max-width: 575px) {
    .height-change 
    {
        .max-width20 {
            max-width: 66px !important;
        }
    }
    .card-min20{
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
    .res-expense-sortby{
        min-width: 100% !important;
        margin-left: 0 !important;
    }
}