@import '_variables';
@import '_mixins';
@import '_functions';


small,
span,
b {
  font-size: 100%;
}

// Heading Typography--------------
// h1-font---32px--

.h-extra-large-font {
  @include h-extra-large($heading-extra-font-size, $font-family);
  @include margin(left, top, right, bottom, 0, 0, 0, 0)
}

// h2-font---24px--

.h-large-font {
  @include h-large($heading-large-font-size, $font-family);
  @include margin(left, top, right, bottom, 0, 0, 0, 0)
}

// h3-font---22px--

.h-medium-font {
  @include h-medium($heading-medium-font-size, $font-family);
  @include margin(left, top, right, bottom, 0, 0, 0, 0)
}

// h4-font---18px--

.h-small-font {
  @include h-small($heading-small-font-size, $font-family);
  @include margin(left, top, right, bottom, 0, 0, 0, 0)
}

// h5-font---16px--

.h-extra-small-font {
  @include h-extra-small($heading-extra-small-font-size, $font-family);
  @include margin(left, top, right, bottom, 0, 0, 0, 0)
}

// Heading Typography--------------

.t-14-font {
  @include t-14-font-style;
}

// form label,
// label {
//   font-size: inherit;
// }
