@import "../../theme/main.scss";

/*************** Calendar css *****************/
.att-calendar {
  .p-calendar {
    width: 100%;

    .p-datepicker {
      @include padding(left, top, right, bottom, 20, 23, 20, 5);
      @include background;
      @include border(0px, solid, transparent);
      @include border-radius(0);
      font-size: inherit;

      .p-datepicker-group-container {
        width: 100%;

        .p-datepicker-group {
          .p-datepicker-header {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include background;
            margin: 0;
            border-bottom: 0 none;

            .p-datepicker-prev,
            .p-datepicker-next {
              font-family: $font-family;
              font-size: $text-default-size;
              color: $utl-text-color-5;
              height: auto;
              width: auto;

              &:focus {
                box-shadow: none;
              }
            }

            .p-datepicker-title {

              .p-datepicker-month,
              .p-datepicker-year {
                font-family: $font-family;
                font-size: $text-default-size;
                color: $utl-text-color-5;
                font-weight: map-get($font-weights, 'medium');
                line-height: 1;
              }
            }
          }

          .p-datepicker-calendar-container {
            table {
              @include margin(left, top, right, bottom, 0, 16, 0, 16);
              font-size: inherit;

              thead {
                tr {
                  th {
                    @include padding(left, top, right, bottom, 5, 0, 5, 10);
                    text-align: center;

                    span {
                      font-family: $font-family;
                      font-size: $text-size-11;
                      font-weight: map-get($font-weights, 'semi-bold');
                      text-align: center;
                      color: #555878;
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    @include padding(left, top, right, bottom, 5, 10, 5, 0);

                    span {
                      font-family: $font-family;
                      font-size: $text-size-11;
                      font-weight: map-get($font-weights, 'medium');
                      text-align: center;
                      color: #555878;
                      border-radius: 100%;
                      height: 25px;
                      width: 25px;
                      @include border(0, solid, transparent);

                      .cls-half-absent {
                        @include border(1px, solid, blue);
                        border-radius: 100%;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }

                      .cls-full-absent {
                        @extend .cls-half-absent;
                        @include border(1px, solid, #ff5252);
                      }

                      .cls-normal-text {
                        @extend .cls-half-absent;
                        @include border(0px, solid, transparent);
                        @include background-color(transparent);

                        :hover {
                          @include background-color(#ffc107);
                          color: #ffffff;
                        }
                      }

                      &.p-highlight {
                        border-radius: 100%;
                        height: 25px;
                        width: 25px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: $utl-white;
                        @include background-color(#ffc107);

                        &:hover {
                          @include background-color(#ffc107);
                          color: $utl-white;
                        }
                      }
                    }

                    span {
                      &:not(.p-highlight):not(.p-disabled) {
                        &:hover {
                          @include background-color(#ffc107);
                          color: $utl-white;
                          border-radius: 100%;
                          height: 25px;
                          width: 25px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }

                    &.p-datepicker-today {
                      span {
                        color: #00c7b4;
                        @include background-color(transparent);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

/****************** OD Calendar css *********************/
.OD_calendar {
  .p-calendar {
    position: relative;
    display: inline-block;
    width: 100%;

    .p-datepicker-group-container {
      .p-datepicker-group {
        @include border(0px, solid, transparent);

        .p-datepicker-header {
          background: transparent;
          @include border(0px, solid, transparent);
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 0, 0, 0, 24);
          

          .p-datepicker-prev {
            right: 0em;
            top: 0;
            left: auto;
            border-color: $utl-grey-color-5;
            @include background-color($utl-grey-color-5);
            @include border-radius(50%);
            order: 2;
            width: 1.8em;
            height: 1.8em;

            &:hover {
              @include border(0px, solid, #c0c0c0);
              @include background-color(#eeeeee);
              color: $secondary-text-color;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .p-datepicker-next {
            right: .5em;
            top: 0;
            border-color: $utl-grey-color-5;
            @include background-color($utl-grey-color-5);
            @include border-radius(50%);
            order: 3;
            width: 1.8em;
            height: 1.8em;

            &:hover {
              @include border(0px, solid, #c0c0c0);
              @include background-color(#eeeeee);
              color: $secondary-text-color;
            }

            &:focus {
              box-shadow: none;
            }
          }

          .p-datepicker-title {
            @include margin(left, top, right, bottom, 16, 0, 0, 0);
            text-align: left;
            order: 1;
            width: 65%;

            // width: calc(100% - 77px);
            .p-datepicker-month,
            .p-datepicker-year {
              font-family: $font-family;
              font-weight: map-get($font-weights, 'medium');
              font-size: $heading-extra-small-font-size;
              letter-spacing: 0.63px;
              color: $secondary-text-color;
            }
          }
        }

        .p-datepicker-calendar-container {
          font-size: inherit;
          font-family: $font-family;

          .p-datepicker-calendar {
            font-size: inherit;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);

            thead {
              tr {
                th {
                  @include background-color(transparent);
                  @include padding(left, top, right, bottom, 0, 0, 0, 24);
                  text-align: center;

                  span {
                    font-family: $font-family;
                    font-size: $heading-extra-small-font-size;
                    font-weight: map-get($font-weights, 'regular');
                    line-height: 1.21;
                    color: #696f7b;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  @include background-color(transparent);
                  @include border-pos(bottom, 0, solid, transparent);
                  text-align: center;
                  @include padding(left, top, right, bottom, 0, 0, 0, 2);

                  span {
                    @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    @include background-color(transparent);
                    font-family: $font-family;
                    font-size: $text-default-size;
                    font-weight: map-get($font-weights, 'medium');
                    color: $secondary-text-color;
                    @include border(0px, solid, transparent);

                    &:focus {
                      box-shadow: none;
                    }

                    &.p-disabled {
                      .normal-day {
                        @include background-color(transparent);
                        cursor: default;

                        &:hover {
                          @include background-color(transparent);
                          cursor: default;
                        }
                      }
                    }

                    &.ui-state-default {
                      &:hover {
                        @include background-color(transparent);
                      }
                    }

                    .cls-half-absent {
                      // border: 1px dotted #ee6c65;
                      // @include border(1px, solid, blue);
                      border-radius: 100%;
                      height: 34px;
                      width: 34px;
                      color: $secondary-text-color;
                      line-height: 34px;
                      margin: 0 auto;
                      @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    }

                    .cls-full-absent {
                      // @include border(1px, solid, #ee6c65);
                      @extend .cls-half-absent;
                    }

                    .cls-normal-text {
                      border-radius: 100%;
                      height: 34px;
                      width: 34px;
                      color: $secondary-text-color;
                      line-height: 34px;
                      margin: 0 auto;
                      @include padding(left, top, right, bottom, 0, 0, 0, 0);

                      &:hover {
                        // @include background-color(rgba(0, 191, 165, 0.2));
                        // color: $utl-white;
                      }
                    }

                    &.ui-state-active {
                      .cls-normal-text {
                        @extend .cls-half-absent;
                        // @include background-color(rgba(0, 191, 165, 0.2));
                        // color: $utl-white;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .p-datepicker.p-datepicker-inline {
      display: inline-block;
      position: static;
      @include border(0px, solid, $utl-text-color-18);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      font-size: inherit;


    }
  }
}

.OD_calendar span.p-highlight {

  .cls-half-absent,
  .cls-normal-text,
  .cls-full-absent {
    @extend .cls-half-absent;
    @include background-color(rgba(0, 191, 165, 0.2));
    // color: $utl-white;
  }
}

.OD_calendar .p-datepicker-today span {
  @include border-radius(100%);
  height: 34px;
  width: 34px;
  color: $secondary-text-color;
  line-height: 34px;
  margin: 0 auto;
  @include padding(left, top, right, bottom, 0, 0, 0, 0);
  @include background-color(transparent);

  .cls-normal-text {
    @include border-radius(100%);
    height: 34px;
    width: 34px;
    color: $secondary-text-color;
    line-height: 34px;
    margin: 0 auto;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    // @include border(1px, solid, $utl-theme-color);
    @include background-color(transparent);
    color: $secondary-text-color;
  }
}

/********* att_calendar css ****************/
#att_calendar {
  .p-calendar {
    position: relative;
    display: inline-block;
    width: 100%;

    .p-datepicker.p-datepicker-inline {
      display: inline-block;
      position: static;
      @include border(0px, solid, transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .p-datepicker-group-container {
        .p-datepicker-group {
          @include border(0px, solid, transparent);

          .p-datepicker-header {
            background: transparent;
            @include border(0px, solid, transparent);
            font-weight: map-get($font-weights, "medium");
            font-size: $heading-small-font-size;
            letter-spacing: 0.73px;
            color: $secondary-text-color;
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include margin(left, top, right, bottom, 0, 0, 0, 30);

            .p-datepicker-prev {
              right: 0em;
              top: 0;
              left: auto;
              border-color: $utl-grey-color-5;
              @include background-color($utl-grey-color-5);
              @include border-radius(50%);
              order: 2;
              width: 1.8em;
              height: 1.8em;

              &:hover {
                @include border(0px, solid, #c0c0c0);
                @include background-color(#eeeeee);
                color: $secondary-text-color;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .p-datepicker-next {
              right: 1.5em;
              top: 0;
              border-color: $utl-grey-color-5;
              @include background-color($utl-grey-color-5);
              @include border-radius(50%);
              order: 3;
              width: 1.8em;
              height: 1.8em;

              &:hover {
                @include border(0px, solid, #c0c0c0);
                @include background-color(#eeeeee);
                color: $secondary-text-color;
              }

              &:focus {
                box-shadow: none;
              }
            }

            .p-datepicker-title {
              @include margin(left, top, right, bottom, 16, 0, 0, 0);
              text-align: left;
              order: 1;
              width: 65%;
            }
          }

          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              font-size: inherit;

              thead {
                tr {
                  th {
                    @include padding(left, top, right, bottom, 5, 0, 5, 10);
                    text-align: center;

                    span {
                      @include background-color(transparent);
                      font-family: $font-family;
                      font-size: $heading-extra-small-font-size;
                      font-weight: map-get($font-weights, "regular");
                      line-height: 1.21;
                      text-align: center;
                      color: rgba(105, 111, 123, 0.65);
                      @include padding(left, top, right, bottom, 0, 0, 0, 22);
                    }
                  }
                }
              }

              tbody {
                tr {
                  td {
                    @include padding(left, top, right, bottom, 5, 0, 5, 0);

                    span {
                      @include background-color(transparent);
                      font-family: $font-family;
                      font-size: $text-default-size;
                      font-weight: map-get($font-weights, "medium");
                      @include border-pos(bottom, 0, solid, transparent);
                      text-align: center;
                      color: $secondary-text-color;
                      @include padding(left, top, right, bottom, 0, 0, 0, 5);
                      cursor: default;
                    }

                    .cls-wall-calendar {
                      border-radius: 100%;
                      height: 34px;
                      width: 34px;
                      line-height: 34px;
                      margin: 0 auto;
                      @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    }

                    // .cls-full-absent {
                    //   border: 1px solid #ee6c65;
                    //   @extend .cls-half-absent;
                    // }
                    // .cls-wall-calendar  {
                    //   background-color: transparent !important;
                    //   @extend .cls-half-absent;
                    //   color: $secondary-text-color;
                    // }
                    &.p-datepicker-other-month {
                      .p-disabled {
                        .cls-wall-calendar {
                          @include background-color(transparent !important);
                          background: none !important;
                          border: 0px !important;
                          border-radius: 100%;
                          height: 34px;
                          width: 34px;
                          line-height: 34px;
                          margin: 0 auto;
                          @include padding(left, top, right, bottom, 0, 0, 0, 0);
                        }
                      }
                    }

                    &.p-datepicker-other-month {
                      @extend .p-datepicker-other-month;
                    }

                    &.p-datepicker-today {
                      span {
                        &:focus {
                          box-shadow: none;
                        }

                        .primaryText {
                          color: $utl-green-12;
                        }
                      }
                    }

                    &.p-datepicker-today {
                      border-color: transparent !important;
                      background: transparent !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//prime ng calender -- timesheet request page
p-calendar.pcal-input {
  .p-button {
    width: 17px !important;
    padding: 0 !important;
    background: transparent;
    color: rgba(39, 44, 62, 0.8);
    border-left: 0;
    border-color: $selecthovercolor;

    &:hover {
      background: transparent;
      border-color: $selecthovercolor;
    }

    &:focus {
      box-shadow: none;
    }

    span {
      color: rgba(39, 44, 62, 0.8);
      @include border-radius(4px);

      &:hover {
        background-color: $selecthovercolor;
      }
    }
  }

  .p-inputtext {
    padding: 2px 0px;
    border: 1px solid $selecthovercolor;
    border-right: 0;

    &:hover {
      border-color: $selecthovercolor;
      background: #f3f3f3;
    }

    &:focus {
      border-color: $selecthovercolor;
      box-shadow: none;
    }
  }
}

//custom calender timesheet
.calboxdesign {
  .p-datepicker {
    max-width: 380px !important;
    background: #ffffff;
    @include border-radius(4px);
    box-shadow: 0 0 27px 0 rgba(144, 157, 182, 0.4);
    border: solid 1px #c7d1e4 !important;
    top: auto !important;
    left: 0px;
    bottom: 0 !important;
    //   .p-datepicker-group {
    //     margin-top: 20px;
    // }
  }
}

.backdrop {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: transparent;
  top: 0;
  left: 0;
}

.calender-container {
  position: fixed;
  z-index: 100;
  max-width: 380px;
  top: 8%;
  bottom: auto;
  left: 31%;
  background: #ffffff;
  @include border-radius(4px);
  box-shadow: 0 0 27px 0 rgba(144, 157, 182, 0.4);
  border: solid 1px #c7d1e4;

  .radiobtns {
    @include border-radius(4px 0px 0px 4px);
    background-color: #f4f6f9;
    @include padding(left, top, right, bottom, 20, 18, 20, 13);
    border-bottom: solid 1px #dbdbdb;
  }

  .footer-btm {
    border-top: solid 1px #dbdbdb;
    @include padding(left, top, right, bottom, 20, 15, 20, 15);
  }
}

@media only screen and (max-width: 1280px) {
  #att_calendar .p-calendar .p-datepicker.p-datepicker-inline {
    width: 100%;
  }

  .new-calender .p-calendar .p-datepicker.p-datepicker-inline {
    width: 100%;
  }

  #att_calendar .p-calendar .p-datepicker.p-datepicker-inline .p-datepicker-group-container .p-datepicker-group .p-datepicker-calendar-container .p-datepicker-calendar {
    table-layout: fixed;
  }

  .new-calender .p-calendar .p-datepicker.p-datepicker-inline .p-datepicker-group-container .p-datepicker-group .p-datepicker-calendar-container .p-datepicker-calendar {
    table-layout: fixed;
  }
}

@media only screen and (max-width: 1024px) {
  .cal-resp {
    flex-wrap: wrap;
  }

  .new-calender {
    .p-calendar {
      .cls-wall-calendar {
        height: 25px !important;
        width: 25px !important;
      }

      .p-datepicker {
        .p-datepicker-group-container {
          .p-datepicker-group {
            .p-datepicker-calendar-container {
              table {
                tbody {
                  tr {
                    td {
                      span {
                        width: 25px !important;
                        width: 25px !important;
                        line-height: 25px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// new social wall calerder design
#att_calendar {
  &.new-calender {
    .p-calendar {
      .p-datepicker {
        overflow: hidden;

        .p-datepicker-group-container {
          .p-datepicker-calendar {
            table-layout: fixed;
          }

          .p-datepicker-group {
            width: 100%;

            .p-datepicker-header {
              @include background;
              margin: auto;
              border-bottom: 0 none;
              width: max-content;

              .p-datepicker-prev,
              .p-datepicker-next {
                font-family: $font-family;
                font-size: $tabs-text;
                font-weight: map-get($font-weights, 'normal');
                color: $subtext;
                @include margin(left, top, right, bottom, 10, 0, 10, 0);
                left: 0;
                order: 1;
                right: 0;
                border-color: transparent;
                @include background-color();

                &:focus {
                  box-shadow: none;
                }

                .pi {
                  font-size: $tabs-text;
                  font-weight: map-get($font-weights, 'normal');
                  color: $subtext;
                }
              }

              .p-datepicker-title {

                .p-datepicker-month,
                .p-datepicker-year {
                  font-family: $font-family;
                  color: $darkcolor-celebration;
                  font-size: $tabs-text;
                  font-weight: map-get($font-weights, 'semi-bold');
                  line-height: 1;
                  @include margin(left, top, right, bottom, 0, 0, 5, 0);
                  width: auto;
                }
              }
            }

            .p-datepicker-calendar-container {
              .p-datepicker-calendar {
                tbody {
                  tr {
                    td {
                      @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


// Expense calendar
.cls-exp-calaender {
  span.p-calendar {
    width: 100%;
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    @include margin(left, top, right, bottom, 0, 6, 0, 0);
    display: block;

    &.p-calendar-w-btn {
      width: 100%;

      .p-inputtext {
        font-size: $text-default-size;
        font-family: $font-family;
        width: calc(100% - 38px);
        color: $primary-text-color;
        font-weight: map-get($font-weights, "medium");
        @include border-pos(bottom, 1px, solid, #e9edf2);
        @include border-pos(top, 0px, solid, transparent);
        @include border-pos(right, 0px, solid, transparent);
        @include border-pos(left, 0px, solid, transparent);
        @include padding(left, top, right, bottom, 0, 5, 0, 5);
        @include border-radius(0px);

        &:focus {
          box-shadow: none;
        }
      }

      .p-button {
        background: transparent;
        @include border-pos(bottom, 1px, solid, #e9edf2);
        @include border-pos(top, 0px, solid, transparent);
        @include border-pos(right, 0px, solid, transparent);
        @include border-pos(left, 0px, solid, transparent);
        color: #000;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          background: transparent;
        }

        .p-button-text {
          padding: 0.5em;
        }
      }
    }

    .p-datepicker {
      padding: 0.5rem;

      .p-datepicker-group {
        .p-datepicker-header {
          background: transparent;
          padding: 0;

          .p-datepicker-prev,
          .p-datepicker-next {
            height: auto;
            width: auto;
          }
        }

        .p-datepicker-title {

          .p-datepicker-month,
          .p-datepicker-year {
            font-family: $font-family;
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, "semi-bold");
            line-height: normal;
          }
        }

        .p-datepicker-calendar-container {
          .p-datepicker-calendar {
            margin: 0.857rem 0 0;

            thead {
              tr {
                th {
                  @include background-color($utl-grey-color-5);
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, "semi-bold");
                  text-align: center;
                }
              }
            }

            tbody {
              tr {
                td {
                  font-family: $font-family;
                  font-size: $text-size-11;
                  font-weight: map-get($font-weights, "medium");

                  span {
                    height: auto;
                    width: auto;

                    &.p-highlight {
                      @include background-color($utl-theme-color);
                    }

                    &:hover {
                      @include background-color($utl-grey-color-5);
                      color: $utl-text-color-14;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}