

/*******************
  /*General Elements
  *******************/
a.link:hover,
a.link:focus {
  color: #398bf7 !important;
}

.bg-theme {
  background-color: #398bf7 !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #398bf7;
  border-color: #398bf7;
}

.right-sidebar .rpanel-title {
  background: #fff;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
  border-left: 4px solid #398bf7;
}

.text-themecolor {
  color: #398bf7 !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #398bf7;
  color: #398bf7;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #398bf7;
}

.sidebar-menu > li.active > a {
  color: #00bfa5;
}

.sidebar-menu > li > a:hover {
  color: #00bfa5;
}

.btm__Profile {
  position: fixed;
    bottom: 26px;
    width: 60px;   
    text-align: center;
    left: 0;
}



.profile-picture {

  bottom: 0;

  display: block;
  padding: 1%;
}

.profile-picture .profile-pic {
  width: 30px;
  border-radius: 100%;
}

