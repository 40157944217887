@import "../../theme/main.scss";

._applicability {
  @include margin-em(left, top, right, bottom, 0, 0, 0, 0);
  display: flex;

  .form-left-bg {
    @include background-color($utl-grey-color-5);
    @include padding-em(left, top, right, bottom, 1.071, 0, 1.071, 0);
    @include margin-em(left, top, right, bottom, 0, 0, 2.285, 0);
    
    width: 27%;

    .cls-appmainheading {
      font-size: $text-size-11;
      font-weight: (map-get($font-weights, semi-bold));
      color: $primary-text-color;
      @include padding-em(left, top, right, bottom, 2.857, 1.214, 0, 0.428);
      @include margin-em(left, top, right, bottom, 0, 0, 0, 0);
    }

    .cls-subtext {
      font-size: $text-small-size;
      font-weight: (map-get($font-weights, medium));
      color: $primary-text-color;
      @include padding-em(left, top, right, bottom, 3, 0, 0, 1.285);
      @include margin-em(left, top, right, bottom, 0, 0, 0, 0);
    }

    .cls-btspace {
      @include padding-em(left, top, right, bottom, 0, 0, 0, 0);
    }

    .vertical-radio-list {
      @include padding-em(left, top, right, bottom, 0, 1.214, 0, 0);

      perfect-scrollbar {
        height: calc(100vh - 242px);
      }

      .mat-mdc-radio-group {
        display: flex;
        flex-direction: column;

        .mat-mdc-radio-button {
          @include padding-em(left, top, right, bottom, 0, 0, 0, 1.428);
          /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
          label.mat-radio-label {
            @include padding-em(left, top, right, bottom, 0, 0, 0, 0);
            @include margin-em(left, top, right, bottom, 0, 0, 0, 0);
            /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
            .mat-radio-label-content {
              @include padding-em(left, top, right, bottom, 0.857, 0, 0, 0);
              font-size: $text-medium-size;
              font-weight: (map-get($font-weights, medium));
              color: $utl-text-color-12;
            }
          }
        }
      }
    }
  }

  .form-rightside {
    width: calc(100% - 30.5%);

    perfect-scrollbar {
      height: calc(100vh - 162px);
      @include padding-em(left, top, right, bottom, 0, 0, 1.428, 0);

    }

    .cls-divcheckbox {
      @include border-pos(bottom, 1px, solid, rgba(151, 151, 151, .33));
      @include padding-em(left, top, right, bottom, 1.19, 0.9, 1.19, 0.9);
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height:64px;

      .mat-mdc-checkbox {

        @include padding-em(left, top, right, bottom, 0, 0, 0, 0);
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        label.mat-checkbox-layout {
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
          .mat-checkbox-inner-container {
            margin-right: 0.428em;
          }
          /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
          .mat-checkbox-label {
            font-size: $text-medium-size;
            font-weight: (map-get($font-weights, medium));
            color: $primary-text-color;
          }
        }
      }

    }

    .cls-checkboxs {
      display: flex;
      flex-wrap: wrap;

      .cls-boxcontainer {
        flex: 0 0 25%;
        max-width: 25%;
        @include padding-em(left, top, right, bottom, 1.071, 0.857, 0, 0);
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-inner-container {
          margin-right: 0.428em;
        }
        /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
        .mat-checkbox-label {
          font-size: $text-medium-size;
          font-weight: (map-get($font-weights, medium));
          color: $primary-text-color;
        }
      }
    }

    .cls-autocomplete {
      @include padding-em(left, top, right, bottom, 0, 1.85, 0, 0);

      .ui-autocomplete-input-token {
        width: 100%;
      }
    }
  }
}
