@import "../../theme/main.scss";

.mdc-evolution-chip-set,
.mat-mdc-chip-listbox,
.mat-mdc-chip-set {
  .mdc-evolution-chip-set__chips {
    display: flex;
    align-items: center;
    font-family: $font-family !important;

    .mat-mdc-chip,
    .mat-mdc-chip-option,
    .mat-primary,
    .mdc-evolution-chip,
    .mdc-evolution-chip--with-trailing-action,
    .mat-mdc-standard-chip,
    .mat-mdc-chip-with-trailing-icon,
    .mdc-evolution-chip--filter,
    .mdc-evolution-chip--selectable,
    .mdc-evolution-chip--selecting,
    .mdc-evolution-chip--with-primary-graphic {
      @include padding(left, top, right, bottom, 15, 3, 8, 3);

  
      color: $secondary-text-color;
      min-height: 28px;
      height: auto;
      line-height: normal;
      

      .mdc-evolution-chip__cell,
      .mdc-evolution-chip__cell--primary {

        .mdc-evolution-chip__action,
        .mat-mdc-chip-action,
        .mdc-evolution-chip__action--primary {
          overflow: hidden;
          padding-left: 0;


          .mdc-evolution-chip__graphic,
          .mat-mdc-chip-graphic {
            display: none;
          }
        }
      }

      .mdc-evolution-chip__cell,
      .mdc-evolution-chip__cell--trailing {
        .mat-icon {
          color: $secondary-text-color;
          padding-right: 0;
        }

      }

      .mat-mdc-chip-action-label {
        overflow: hidden;
        font-family: $font-family;
        font-size: $text-small-size;
        font-weight: 500;
        // white-space: inherit;
      }
    }

    .mat-mdc-chip-selected {

      .mdc-evolution-chip__cell,
      .mdc-evolution-chip__cell--primary {

        .mdc-evolution-chip__action,
        .mat-mdc-chip-action,
        .mdc-evolution-chip__action--primary {

          .mdc-evolution-chip__graphic,
          .mat-mdc-chip-graphic {
            display: none;
          }

          .mdc-evolution-chip__text-label {
            color: $secondary-text-color;

          }

        }
      }

      .mdc-evolution-chip__cell,
      .mdc-evolution-chip__cell--trailing {
        .mat-icon {
          color: $secondary-text-color;
          padding-right: 0;
        }

      }
    }
  }
}

// Chat css
.chat-chips-wrapper {
  @include border-radius(4px);
  @include border(1px, solid, #ececec);
  @include padding(left, top, right, bottom, 8, 8, 0, 0);
  @include margin(left, top, right, bottom, 0, 0, 0, 20);

  mat-chip-list {
    .mat-chip-list-wrapper {
      mat-chip {
        @include border-radius(2px);
        @include background-color($utl-text-color-16);
        border: none;
        @include margin(left, top, right, bottom, 0, 0, 8, 8);
        color: #848484;
      }
    }
  }
}


.filter-wrapper {
  .clsfilter-box {
    @include border-pos(top, 1px, solid, rgba(216, 216, 216, 0.40));
    @include border-pos(bottom, 1px, solid, rgba(216, 216, 216, 0.40));
    @include padding(left, top, right, bottom, 0, 12, 0, 6);
    width: 100%;

    .clsfilter {
      width: 110px;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      span {
        font-size: $text-medium-size;
        color: map-get($heading-colors, 'dark-color');
        font-weight: map-get($font-weights, 'regular');
      }

    }

    .salaryFilter-chip {
      .clschip {
        width: auto;
      }
    }

    .clschip {
      width: calc(100% - 110px);

      a {
        position: relative;

      }
    }
  }
}

.moodbotDialog {
  .userComment {
    margin-top: -20px;
  }

  .mdc-evolution-chip-set__chips {
    justify-content: center;
    margin: 0 !important;

    .mdc-evolution-chip__action--primary {
      padding-right: 0 !important;
    }

    .mdc-evolution-chip--with-primary-graphic {
      font-size: $heading-h2;
      cursor: pointer;
      @include color($secondary-text-color);
      @include padding(left, top, right, bottom, 8, 3, 8, 3);
      @include margin(left, top, right, bottom, 0, 0, 5, 5);
      min-height: 28px;

      &.mdc-evolution-chip--selected {
        @include background-color(#1a2335 !important);
        opacity: 1 !important;

        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {

            .mdc-evolution-chip__graphic,


            .mdc-evolution-chip__text-label {
              color: $utl-white;

            }

          }
        }


      }
    }
  }

}


.customchips {
  .mat-mdc-standard-chip:hover::after {
    opacity: 0;
  }

  .mat-mdc-standard-chip:focus::after {
    opacity: 0;
  }

  .mat-mdc-chip {
    @include background-color($utl-grey-color-28);
    border: 1px solid $utl-text-color-12 !important;
    cursor: pointer;
    color: $utl-text-color-12;
    height: auto;
    line-height: 0;
    @include border-radius(20px);
    font-size: $tabs-text;
    font-weight: map-get($font-weights, 'semi-bold');
    margin: 6px 6px 0 0 !important;
    padding: 3px 12px !important;


    &:hover {
      border: 1px solid $utl-theme-color !important;
      //font-weight: map-get($font-weights, 'bold') !important;
      background: $utl-theme-color !important;
    }

    .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-chip-disabled):active {
      box-shadow: none !important;
    }
  }

}

.customchips {
  .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
    border: 1px solid $utl-theme-color !important;
    // font-weight: map-get($font-weights, 'bold') !important;
    background: $utl-theme-color !important;
    color: $utl-text-color-12;
  }
}

.cls-npschips {
  &.mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-chip-selected {

        @include border(1px, solid, $utl-theme-color !important);

        @include background-color($utl-theme-color !important);

        .mdc-evolution-chip__cell {
          .mdc-evolution-chip__action {
            .mdc-evolution-chip__text-label {
              color: #fff !important;
            }
          }
        }
      }

      .mat-mdc-chip {
        @include background-color($utl-grey-color-28);
        border: 1px solid $utl-text-color-12;
        cursor: pointer;
        height: auto;
        line-height: 28px;
        @include border-radius(20px);
        margin: 6px 6px 0 0;
        padding: 3px 12px;

        &:hover {
          @include border(1px, solid, $utl-theme-color !important);

          @include background-color($utl-theme-color !important);

        }

        .mdc-evolution-chip__cell {
          .mdc-evolution-chip__action {
            padding: 0;

            .mdc-evolution-chip__text-label {
              color: $utl-text-color-12;
              font-size: $tabs-text;
              font-weight: map-get($font-weights, 'bold');

              &:hover {
                font-weight: map-get($font-weights, 'bold');
                color: #fff !important;
              }
            }
          }
        }

      }

    }
  }


}

// core values chips

.corevalues {
  &.mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-chip {
        @include border-radius();
        @include padding(left, top, right, bottom, 4, 4, 4, 4);
        height: 20px;
        border: 0;
        box-shadow: none;
        cursor: pointer;

        .mdc-evolution-chip__action--primary {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
        }

        .mdc-evolution-chip__cell--primary {
          .mdc-evolution-chip__action {
            .mdc-evolution-chip__text-label {
              font-size: $text-small-size-new !important;
              color: $utl-text-color-1;
              cursor: pointer;

            }
          }
        }

        cursor: pointer;

        &:after {
          background: transparent;
        }
      }

      .active-core-chip {
        @include background-color($primaryiconbg !important);

        .mdc-evolution-chip__text-label {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }

      .inactive-core-chip {
        background-color: $utl-grey-color-32 !important;

        .mdc-evolution-chip__text-label {
          font-weight: map-get($font-weights, "medium");
        }
      }
    }
  }
}


// team chip css
.team-chip {
  &.mdc-evolution-chip-set {
    display: flex;

    .mdc-evolution-chip-set__chips {
      @include padding(left, top, right, bottom, 16, 0, 16, 24);
      margin: 0;

      .mat-mdc-chip {
        @include padding(left, top, right, bottom, 14.2, 6, 13.8, 6);
        @include margin(left, top, right, bottom, 0, 0, 16, 16);
        @include border-radius(16px);
        // @include background-color(#e3e8f1);
        // @include border(0px, solid, transparent);



        .mdc-evolution-chip__cell--primary {
          .mdc-evolution-chip__action {
            .mdc-evolution-chip__text-label {
              font-size:$badgetext;
              font-weight: map-get($font-weights, "semi-bold");
              line-height: 1.67;
              // color: #424b5f;
            }
          }




          .mat-icon {
            display: none;
          }


        }

        &.chipSelected {
          // @include background-color($utl-theme-color);

          .mdc-evolution-chip__cell--primary {
            .mdc-evolution-chip__action {
              .mdc-evolution-chip__text-label {
                font-size:$badgetext;
                font-weight: map-get($font-weights, "semi-bold");
                line-height: 1.67;
                // color: $utl-white;
              }
            }

            .mat-icon {
              display: none;
            }


          }

        }
      }
    }


  }
}


// report chips css

.clschips-panel {
  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-chip {
        border-radius: 4px;
        margin: 0 5px 5px 0;

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {
            padding: 0;

            .mdc-evolution-chip__text-label,
            .mat-mdc-chip-action-label {
              display: flex;
              align-items: center;

              .mat-icon {
                font-size: 18px;
                height: auto;
                width: auto;

              }
            }
          }
        }
      }
    }
  }

  &.filterstyle {
    .material-icons {
      line-height: 0px;
    }

    .mdc-evolution-chip-set {
      .mdc-evolution-chip-set__chips {
        .mat-mdc-chip {
          @include margin(left, top, right, bottom, 0, 5, 8, 5);
          @include padding(left, top, right, bottom, 6, 3, 6, 3);
          @include background-color($utl-text-color-16);
          @include border-radius(2px);
          display: flex;
          align-items: center;
          color: $utl-text-color-23;
          border: 1px solid $utl-text-color-16;

          br {
            display: none;
          }

          mat-icon {
            opacity: 1;
            margin-left: 8px;
          }
        }
      }

      &.mainpage {
        .material-icons {
          line-height: 19px;
        }
      }
    }
  }
}

// Chips css


// search chips css
.clschip {

  .mdc-evolution-chip-set,
  .mat-mdc-chip-listbox,
  .mat-mdc-chip-set {
    .mdc-evolution-chip-set__chips {
      display: flex;
      align-items: center;
      font-family: $font-family !important;

      .mat-mdc-chip,
      .mat-mdc-chip-option,
      .mat-primary,
      .mdc-evolution-chip,
      .mdc-evolution-chip--with-trailing-action,
      .mat-mdc-standard-chip,
      .mat-mdc-chip-with-trailing-icon,
      .mdc-evolution-chip--filter,
      .mdc-evolution-chip--selectable,
      .mdc-evolution-chip--selecting,
      .mdc-evolution-chip--with-primary-graphic {
        @include padding(left, top, right, bottom, 15, 3, 8, 3);

    
        color: $secondary-text-color;
        min-height: 28px;
        height: auto;
        line-height: normal;
        
        @include margin(left, top, right, bottom, 0, 0, 5, 5);

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {

            .mdc-evolution-chip__graphic,
            .mat-mdc-chip-graphic {
              display: none;
            }
          }
        }

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--trailing {
          .mat-icon {
            color: $secondary-text-color;
            padding-right: 0;
          }

        }
      }

      .mat-mdc-chip-selected {

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {

            .mdc-evolution-chip__graphic,
            .mat-mdc-chip-graphic {
              display: none;
            }

            .mdc-evolution-chip__text-label,
            .mat-mdc-chip-action-label {
              color: $secondary-text-color;
            }

          }
        }

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--trailing {
          .mat-icon {
            color: $secondary-text-color;
            padding-right: 0;
          }

        }
      }
    }
  }
}


// helpdesk chips css

.chk-chip {
  &.mdc-evolution-chip-set {
    display: flex;
    padding: 24px 0 0 0;

    .mdc-evolution-chip-set__chips {
      margin-left: 0;
      .mat-mdc-chip {
        padding: 0;
        margin-bottom: 0;
        line-height: 20px;
        min-height: 28px;
        height: auto;

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {
          .mat-mdc-chip-action {
            .mat-mdc-chip-action-label {
              padding-left: 12px;
              display: flex;
              align-items: center;
              white-space: normal;

              .mat-icon {

                display: none;
              }

              .material-icons {
                font-size: 1.357em;
              }
            }
          }
        }

      }

      .chipSelected {
        background-color: #1875f0;

        .mdc-evolution-chip__cell--primary {
          .mat-mdc-chip-action {
            .mat-mdc-chip-action-label {
              padding-left: 12px;
              color: #fff;
              display: flex;
              align-items: center;
              white-space: normal;


              .mat-icon {
                height: 20px;
                width: 20px;
                line-height: 18px;
                display: block;
                padding: 0 20px 0 0;
              }

              .material-icons {
                font-size: 1.357em;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

// report chips css
#reportchips {
   .mdc-evolution-chip-set {
    &.cls-reportchips
      {
        &.start-top
        {
        align-items: flex-start;
      }
    }
  }
  
  .mdc-evolution-chip-set {
    font-size: inherit;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .mdc-evolution-chip-set__chips {
      margin-left: 0;

      .mat-mdc-chip {
        @include padding(left, top, right, bottom, 8, 3, 8, 3);
        @include margin(left, top, right, bottom, 0, 0, 5, 5);

        .mat-icon {
          color: $secondary-text-color;
          opacity: 1;
          margin-left: 8px;
        }

        .mdc-evolution-chip__cell {
          .mdc-evolution-chip__action {
            .mat-mdc-chip-action-label {
              white-space: normal;
            }
          }
        }
      }

    }
  }

}


.clsreport-label {
  &.mat-mdc-checkbox {
    display: flex;
  }
}


.cls-condflow {
  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      .mdc-form-field {
        margin: 0;
      }
    }
  }
}

// recuritment setup radio btn css
.cls-recutradiobtn {
  &.mat-mdc-radio-group {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .mat-mdc-radio-button {
      width: 50%;

      .mdc-form-field {
        align-items: flex-start;
        white-space: normal;

        label {
          white-space: normal;

        }
      }
    }
  }
}

.cls-carrerradio {
  .mat-mdc-radio-group {
    display: flex;

    .mat-mdc-radio-button {

      .mdc-form-field {
        align-items: flex-start;
        white-space: normal;

        label {
          white-space: normal;
          font-weight: 600;

          p {
            font-weight: 400;
            color: #6f778b;
            margin-left: 0px;
            margin-top: 9px;
            margin-right: 0px;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
}

// expense chips css


.time-office-data-list {

  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-chip {
        background-color: #6f778b;
        border-radius: 4px;
        padding-left: 6px;
        padding-top: 6px;
        padding-right: 6px;
        padding-bottom: 6px;
        min-height: 20px;
        // min-width: 56px;
        border: none;

        .mdc-evolution-chip__cell--primary {
          .mdc-evolution-chip__action {
            .mat-mdc-chip-action-label {
              color: #ffffff;
              font-size: 0.571em;
              line-height: 1;
              text-transform: uppercase;
              font-weight: 500;
              letter-spacing: 0.2px;

            }
          }
        }
      }
    }
  }

}

// goal chips css

.cls-krachips,
.cls-competency {
  &.mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mdc-evolution-chip {
        .mdc-evolution-chip__cell--primary {
          .mat-mdc-chip-action {
            .mdc-evolution-chip__text-label {
              overflow: visible;
              white-space: normal;
            }
          }
        }
      }
    }
  }
}