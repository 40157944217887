@import "_mixins";
@import "_typography";
@import "_functions";
@import "_variables";



@media screen and (max-width: 1920px) {
    body {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 1440px) {
    body {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 1366px) {
    body {
      font-size: 13.28px;
    }
  }
  
  @media screen and (max-width: 1280px) {
    body {
      font-size: 12.44px;
    }
  }
