@import "../../theme/main.scss";

.uploaded-multimedia {
  .uploaded-multimedia-inner {
    width: 100%;

    .video-oploaded {
      height: 316px;
    }

    .no-of-images {
      display: none;
    }

    iframe {
      width: 100%;
    }

    p-lightbox {
      div {
        display: flex;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        max-height: 360px;

        a {
          display: none;

          img {
            max-width: 100%;
            height: 100%;
            width: 100%;
            object-fit: contain;
            object-position: center;

          }

          &:first-child {
            display: block;
          }

          &:nth-child(2) {
            display: block;
          }

          &:nth-child(3) {
            display: block;
            position: relative;
          }
        }
      }
    }
  }

  .more-images {
    position: relative;

    .no-of-images {
      display: flex;
      @include background-color(rgba(40, 44, 63, 0.6));
      @include abs-pos (0, 0, initial, initial);
      width: 33%;
      height: 100%;
      color: $utl-white;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, 'semi-bold');
    }

    p-lightbox {
      div {
        max-height: 360px;

        a {
          &:nth-child(3) {
            &:after {
              content: "";
              @include abs-pos (0, initial, initial, 0);
              width: 100%;
              height: 100%;
            }
          }

          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
            object-position: center;
          }
        }
      }
    }
  }
}

.ui-lightbox-caption {
  display: block !important;
  position: fixed;
  top: 8px;
  right: 14px;
  @include background-color(transparent);
  background: initial;
  border: none;
  box-shadow: none;

  .pi {
    font-size: 0;
    background-image: url(#{$assetPath}/icon/cross.png);
    width: 24px;
    height: 24px;
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.ui-lightbox-content-wrapper {

  .ui-state-default {
    width: 48px;
    height: 48px;
    border-bottom: none;
    @include border-radius(100%);
    display: flex;
    align-items: center;
    justify-content: center;
    @include background-color($utl-text-color-4);
    @include opacity(1);

    .pi {
      color: #fff;
      font-size: 16px;
    }
  }
}

.ui-lightbox-nav-left {
  left: -64px;
}

.ui-lightbox-nav-right {
  right: -64px;
}

.common {
  box-sizing: $content-box;

}

.show-3 {
  .uploaded-multimedia-inner {
    p-lightbox {
      div.undefined {
        max-height: 212px;


        a {
          width: 33.33%;
          @extend .common;
          margin-left: 2px;

          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

.show-2 {
  .uploaded-multimedia-inner {
    p-lightbox {
      div.undefined {

        a {
          width: 50%;
          @extend .common;
          margin-left: 2px;

          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

.ui-lightbox-content {
  max-width: 90vw !important;
  max-height: 90vh !important;
}

.ui-lightbox-content img {
  max-width: 90vw !important;
  max-height: 90vh !important;
}
