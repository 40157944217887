@import 'main';

// custom-select-no-selection-text dropdown
.custom-select-label {
  .mat-mdc-select {
    display: flex;
    align-items: center;
    justify-content: center;

    .mat-mdc-select-arrow-wrapper {
      .mat-mdc-select-arrow {
        width: 18px;
      }
    }

    .mat-mdc-select-value {
      display: none;
    }
  }
}



.mat-mdc-radio-button,
.mat-select,
.mat-mdc-mdc-form-field,
.mat-mdc-radio-label,
.mat-mdc-tab-label,
.mat-mdc-tab-link {
  font-family: $font-family !important;
  font-size: inherit !important;
  color: #67757c !important;
}

//start  Css For input fields

.mat-mdc-form-field,
.mat-mdc-floating-label {
  font-family: $font-family !important;
  font-size: inherit !important;
  line-height: inherit;
  color: #67757c;
  width: 100%;
  letter-spacing: 0px !important;

  .mat-mdc-text-field-wrapper {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);


    .mat-mdc-form-field-focus-overlay {
      @include background-color(transparent);
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mdc-floating-label {
          font-family: $font-family;
          font-size: $text-medium-size;
          color: $utl-text-color-12;
          font-weight: map-get($font-weights , 'medium');

          .mdc-floating-label--required {
            display: initial;
          }
        }

        .mdc-text-field__input {
          font-family: $font-family;
          font-size: inherit;
          line-height: normal;
          color: $utl-text-color-12;
          font-weight: map-get($font-weights , 'medium');
        }

        .mat-mdc-form-field-textarea-control {
          @extend .mdc-text-field__input;
          margin: 5px 0 0;
          line-height: 1.5;
          clear: both;
        }

        .mat-mdc-select {
          @extend .mdc-text-field__input;

          .mat-mdc-select-trigger {
            .mat-mdc-select-arrow-wrapper {
              transform: translateY(0px);

            }

            .mat-mdc-select-value {
              .mat-mdc-select-value-text {
                font-size: inherit;

                // .mat-mdc-select-min-line {
                //   font-size: 78%;
                // }
              }
            }
          }

          &.mat-mdc-select-disabled {
            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                color: $utl-text-color-1;
              }
            }
          }
        }

      }

      .mat-mdc-floating-label {
        top: 28px;
        max-width: auto;

        .mdc-floating-label--required {
          color: #f44336;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        align-self: baseline;

        .mat-icon {
          padding: 0;
        }

        .mat-datepicker-toggle {
          .mat-mdc-icon-button {
            &.mat-mdc-button-base {
              width: 18px;
              height: 18px;
              padding: 0;
            }

            svg,
            img {
              width: 16px;
              height: 16px;
              vertical-align: baseline !important;
            }

            .mat-mdc-button-touch-target {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-family: $font-family;
    }

    &.mdc-text-field--filled {
      .mdc-floating-label--float-above {
        transform: translateY(-106%) scale(0.85);
        width: 118%;
      }
    }

    .mdc-floating-label {
      transform: translateY(0px);
    }

    &.mdc-text-field--disabled {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-select-disabled {
            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                .mat-mdc-select-placeholder {
                  font-size: 78%;
                  color: #000;
                }
              }
            }
          }
        }
      }
    }
  }

  .mdc-text-field--disabled.mdc-text-field--filled {
    @include background-color(transparent);


  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple:before {
    border-bottom-color: #e9edf2;

  }

  .mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
    border-bottom-color: #f44336;
  }

  .mdc-text-field--disabled .mdc-line-ripple:before {
    border-bottom-color: #e9edf2;


  }

  .mat-mdc-form-field-error {
    // font-size: 75%;
    // display: inline-flex;

    &.mat-mdc-form-field-bottom-align::before {
      display: inline;
    }
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    @include background-color(transparent);
  }

  .mdc-text-field--filled {
    .mdc-line-ripple {
      &:after {
        border-bottom-color: $utl-theme-color;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    &.mat-mdc-form-field-bottom-align {
      &::before {
        display: flex;
        line-height: inherit;

      }

      .mat-mdc-form-field-hint-wrapper,
      .mat-mdc-form-field-error-wrapper {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

      }
    }
  }

  &.full-width {
    width: 100%;
  }

  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    bottom: 6px;
  }

  .mdc-text-field--disabled {
    .mdc-line-ripple {
      &:before {
        border-bottom-color: rgba(0, 0, 0, 0.4);
        border-bottom-style: dashed;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align:before {
    font-family: $font-family;
    font-size: 71%;
    margin-top: -4px;
    // height: auto;
    line-height: initial;
  }

  .mdc-text-field--no-label {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-top: 24px !important;
        padding-bottom: 8px !important;
        width: 100%;
      }
    }
  }

  &.mat-focused {
    &.mat-primary {
      .mat-mdc-select-arrow {
        color: rgba(0, 0, 0, .54) !important;
      }
    }
  }
}

.mat-mdc-form-field,
.mat-form-field-disabled {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        width: 100%;
      }
    }
  }
}


.cdk-overlay-pane {

  .mat-mdc-select-panel,
  .mdc-menu-surface,
  .mdc-menu-surface--open,
  .mat-primary {
    font-family: inherit;
    font-size: initial;

    .mat-mdc-option,
    .mdc-list-item,
    .mdc-list-item--selected,
    .mat-mdc-option-active {

      font-family: $font-family;
      font-size: $text-medium-size;
      line-height: 36px;
      min-height: 36px;

      .mdc-list-item__primary-text {
        font-size: $text-medium-size;
        color: #1b2436;
        font-weight: 500;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;

        .mat-select-search-inner,
        .mat-typography,
        .mat-datepicker-content,
        .mat-tab-header,
        .mat-select-search-inner-multiple,
        .mat-select-search-inner-toggle-all {
          .mat-select-search-toggle-all-checkbox {
            padding-left: 17px;
          }
        }

        ngx-mat-select-search {
          .mat-select-search-inner {
            .mat-select-search-clear {
              right: 12px;
              top: 6px;

              &.mat-mdc-icon-button {
                background-color: transparent;

                height: auto;
                width: auto;

                &.mat-mdc-button-base {
                  height: auto;
                  width: auto;
                  padding: 0;
                }

                .mat-mdc-button-persistent-ripple {
                  background-color: transparent;
                  height: auto;


                }

                .mat-mdc-button-touch-target {
                  height: auto;
                  width: auto;
                }
              }
            }
          }
        }
      }

      .mat-pseudo-checkbox,
      .mat-mdc-option-pseudo-checkbox,
      .mat-pseudo-checkbox-checked {
        &.mat-pseudo-checkbox-full {
          background-color: transparent;

          &::after {
            color: $utl-white;
          }
        }

        &.mat-pseudo-checkbox-checked {
          background-color: $utl-theme-color;

          &::after {
            color: $utl-white;
          }
        }

        &.mat-pseudo-checkbox-minimal {
          background-color: transparent;

          &::after {
            color: $utl-theme-color;
          }

        }
      }


    }



  }
}

// textarea css 
.cls-textarea {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0 !important;
          min-height: auto;
        }
      }
    }
  }
}

// dashboard custom select css

#cls-selectdashboard {
  .mat-mdc-text-field-wrapper {

    .mdc-line-ripple,
    .mdc-line-ripple--deactivating {

      &::after,
      &::before {
        border-color: transparent;
      }
    }
  }
}

//start  analytic select css
.cls-analytic-select {
  width: 100% !important;
  font-family: $font-family !important;

  .mat-mdc-text-field-wrapper,
  .mdc-text-field,
  .mdc-text-field--filled,
  .mdc-text-field--no-label,
  .mdc-text-field--focused {
    width: 100%;
    padding: 0 10px;
    @include background-color;
    @include border-radius;
    height: 33px;

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        min-height: 33px;

        .mat-mdc-select,
        .mat-mdc-select-disabled {
          height: 33px;
          line-height: 33px;

          .mat-mdc-select-trigger {
            .mat-mdc-select-value {
              .mat-mdc-select-value-text {
                font-family: $font-family;
                font-size: $text-medium-size;
                color: #272c3e !important;
                font-weight: 700;
              }
            }
          }

          .mat-mdc-select-arrow-wrapper {
            .mat-mdc-select-arrow {
              color: $utl-text-color-1 !important;
            }
          }
        }
      }

    }

    .mdc-line-ripple,
    .mdc-line-ripple--active {
      &::before {
        border-bottom-width: 0;
        border-bottom-color: transparent;
      }

      &::after {
        border-bottom-width: 0;
        border-bottom-color: transparent;
      }
    }

  }

  .mat-mdc-form-field-subscript-wrapper,
  .mat-mdc-form-field-bottom-align {
    display: none;
  }


}

.cdk-overlay-pane {

  .mat-mdc-select-panel,
  .mdc-menu-surface,
  .mdc-menu-surface--open,
  .mat-primary {

    .mat-mdc-option,
    .mdc-list-item {
      &.cls-dashboardmenu {

        .mdc-list-item__primary-text {
          padding-right: 0px;
          width: 90%;

          .mdc-list-item--selected,
          .mat-mdc-option-active {
            padding-right: 0px;
          }


        }

        .mat-icon {
          margin-right: 0;
        }

        .mat-pseudo-checkbox-minimal {
          margin-left: 0;

        }
      }
    }
  }
}

.cls-fltdropdown {
  .mat-mdc-form-field {


    .mat-mdc-text-field-wrapper,
    .mdc-text-field,
    .mdc-text-field--filled,
    .mdc-text-field--no-label {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);


      .mat-mdc-form-field-flex {
        height: 40px;
        @include border-radius-pos(4px, 0, 0, 4px);
        @include border(1px, solid, rgba(157, 161, 168, 0.3));
        border-right: none;
        @include background-color(#f7f7f7);
        max-width: 117px;
        width: 100%;
        min-width: 117px;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);


        .mat-mdc-form-field-infix {

          padding-top: 6px !important;
          padding-left: 8px;
          padding-right: 8px;
          @include border-pos(top, 0px, solid, transparent);
          min-height: auto;

          .mat-mdc-select,
          .mat-mdc-select-disabled {

            .mat-select-trigger {


              .mat-select-value {


                .mat-select-value-text {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, "regular");
                  line-height: 1.33;
                  color: $primary-text-color;
                }
              }
            }
          }
        }
      }

      .mdc-line-ripple,
      .mdc-line-ripple--active {
        &::before {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }

        &::after {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }
      }

      .mdc-line-ripple,
      .mdc-line-ripple--deactivating {

        &::after,
        &::before {
          border-color: transparent;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align {
      display: none;
    }
  }


}

.cls-newfltdropdown {
  .mat-mdc-form-field {


    .mat-mdc-text-field-wrapper,
    .mdc-text-field,
    .mdc-text-field--filled,
    .mdc-text-field--no-label {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);


      .mat-mdc-form-field-flex {
        height: 40px;
        @include border-radius;
        @include border(1px, solid, #D1D1D1);

        @include background-color;
        max-width: 117px;
        width: 100%;
        min-width: 117px;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);


        .mat-mdc-form-field-infix {

          padding-top: 6px !important;
          padding-left: 8px;
          padding-right: 8px;
          @include border-pos(top, 0px, solid, transparent);
          min-height: auto;

          .mat-mdc-select,
          .mat-mdc-select-disabled {

            .mat-select-trigger {


              .mat-select-value {


                .mat-select-value-text {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, "regular");
                  line-height: 1.33;
                  color: $primary-text-color;
                }
              }
            }
          }
        }
      }

      .mdc-line-ripple,
      .mdc-line-ripple--active {
        &::before {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }

        &::after {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }
      }

      .mdc-line-ripple,
      .mdc-line-ripple--deactivating {

        &::after,
        &::before {
          border-color: transparent;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align {
      display: none;
    }
  }


}

//end  analytic select css

// start attandance calander dropdown css 
.cls-attandanceselect {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      width: 100%;
      padding: 0;
      // @include background-color;
      @include border-radius;
      height: auto;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          min-height: auto;

          .mat-mdc-select,
          .mat-mdc-select-disabled {
            height: auto;

            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                .mat-mdc-select-value-text {
                  font-family: $font-family;
                  font-size: $text-medium-size;
                  color: #272c3e !important;
                  font-weight: 700;
                }
              }
            }

            .mat-mdc-select-arrow-wrapper {
              .mat-mdc-select-arrow {
                color: $utl-text-color-1 !important;
              }
            }
          }
        }

      }

      .mdc-line-ripple,
      .mdc-line-ripple--active {
        &::before {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }

        &::after {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }
      }

      .mdc-line-ripple,
      .mdc-line-ripple--deactivating {

        &::after,
        &::before {
          border-color: transparent;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper,
    .mat-mdc-form-field-bottom-align {
      display: none;
    }
  }
}

// end attandance calander dropdown css 

// End  Css For input fields
// date picker css
.cdk-overlay-pane,
.mat-datepicker-popup {

  .mat-datepicker-content,
  .mat-primary {
    .mat-datepicker-content-container {
      .mat-calendar {
        font-family: $font-family;
        font-size: inherit;
        min-height: 0px;
        height: 100%;

      }
    }
  }
}

.cdk-keyboard-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, .04) !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, .04) !important;
}

// date picker css

// profile employee side menu css
#manage-list {
  &.mat-mdc-list[dense] {
    .mat-mdc-list-item {
      height: 34px;

      .mdc-list-item__content {
        font-size: inherit;

        .mdc-list-item__primary-text {
          display: block;
          font-family: $font-family;
          width: 100%;
          letter-spacing: 0.6px;
          color: $utl-text-color-12;
          font-weight: map-get($font-weights , 'medium');
          font-size: $text-default-size;
          line-height: initial;
          white-space: normal;

          a.active {
            font-weight: map-get($font-weights , 'semi-bold');
            color: $utl-theme-color;
          }
        }
      }
    }
  }
}


// Start Css snackbar fix

.cdk-overlay-pane {

  .mdc-snackbar,
  .mat-mdc-snack-bar-container,
  .mdc-snackbar--open {
    @include border-radius;
    max-width: 33vw;
    min-width: 344px;
    font-family: $font-family;
    font-weight: 500;


    .mdc-snackbar__surface {
      @include background-color(transparent);

      .mat-mdc-snack-bar-label {
        .mat-mdc-simple-snack-bar {
          .mat-mdc-snack-bar-label {
            font-family: $font-family;
            font-size: 12px;
            line-height: 1.5;
            word-break: break-word;
            padding-right: 5px;
          }

          .mat-mdc-snack-bar-actions,
          .mdc-snackbar__actions {
            color: $utl-white;
            margin-left: 15px;

            .mat-mdc-snack-bar-action,
            .mdc-snackbar__action,
            .mdc-button,
            .mat-mdc-button,
            .mat-unthemed,
            .mat-mdc-button-base {
              color: $utl-white;
              font-family: $font-family;

              &:hover {
                background-color: rgba(0, 0, 0, .11);
              }

            }
          }
        }
      }
    }
  }

  .mdc-snackbar {
    &.snackbar-validation {
      background: #9078d6 !important;
      color: #ffffff !important;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 8px #eeeded;
    -webkit-box-shadow: inset 0 0 8px #eeeded;
    -webkit-border-radius: 8px;
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #eeeded;
    // box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
    // -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.5);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #7e7a7e;
    // box-shadow: inset 0 0 8px #eeeded;
    // -webkit-box-shadow: inset 0 0 8px #eeeded;
  }

  ::-webkit-scrollbar-thumb:window-inactive {
    -webkit-border-radius: 8px;
    border-radius: 8px;
    background: #7e7a7e;
  }


}

// End Css snackbar fix




//mat menu

/************** calendar dropdon menu css *******************/

.mat-mdc-menu-panel {

  min-height: 48px;

  .mat-mdc-menu-content {

    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");

    a {
      font-family: $font-family;
      font-size: $text-medium-size;
      font-weight: map-get($font-weights, "regular");
    }

    .cls-menutitle {
      @include padding(left, top, right, bottom, 16, 5, 16, 5);
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "semi-bold");
    }

    .mat-mdc-menu-item {
      .mdc-list-item__primary-text {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");
        height: 35px;
        line-height: 35px;
      }

    }
  }

}

// End mat menu css


// start checkbox & radio button css
mat-checkbox {
  &.mat-mdc-checkbox {

    font-family: inherit;

    .mdc-form-field {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      .mdc-checkbox {
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        padding: 0;
        margin: 0 8px 0 0;

        .mat-mdc-checkbox-touch-target {
          position: absolute;
          top: 45%;
          height: 16px;
          left: 45%;
          width: 16px;
          transform: translate(-45%, -45%);
        }

        .mdc-checkbox__background {
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border: 2px solid rgba(0, 0, 0, .54);
        }

        .mdc-checkbox__native-control {
          left: -9999px;
          top: 0;
          right: 0;
          height: 16px;
          width: 16px;
        }

        .mdc-checkbox__ripple {
          opacity: 0;
        }

        .mdc-checkbox__native-control:not([disabled]):focus~.mdc-checkbox__ripple {
          opacity: 0;
          background: transparent;
        }
      }

      label {
        font-family: $font-family;
        margin: 0 0 0 0;
        padding: 0 0 0 4px;
        font-size: $text-medium-size !important;
        font-weight: 500;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
      }

      .mdc-radio--disabled+label {
        font-family: $font-family;
        margin: 0 0 0 0;
        padding: 0 0 0 4px;
        font-size: $text-medium-size !important;

        font-weight: 500;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
      }
    }


    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {

      border-color: #1dbda4 !important;
      background-color: #1dbda4 !important;
    }
  }
}

.text-length-set {
  .mdc-form-field {
    margin-bottom: 0;

  }
}

.clsallcheck,
.clsallcheck-multiple {
  &.mat-mdc-checkbox {
    .mdc-form-field {


      .mdc-checkbox {

        .mdc-checkbox__background {
          left: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border: 2px solid rgba(251, 251, 251, 0.54) !important;
        }

      }


    }


  }
}

.cls-exp-chk {
  mat-checkbox {
    .mdc-form-field {
      margin-bottom: 4px;
    }
  }
}

// report checkbox

.report-chkbox,
.text-length-set {
  &.mat-mdc-checkbox {
    display: initial;
  }
}


.mat-mdc-radio-group {
  .cls-reportrediobtn {
    width: 25%;
    display: inline-flex;
    flex-wrap: wrap;

    .mdc-form-field {
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      label {
        div {
          text-overflow: ellipsis;
          width: 89%;
          overflow: hidden;
          white-space: nowrap;
          display: block;
        }
      }
    }
  }

  .cusbtn {
    .mdc-form-field {
      align-items: self-start;

      label {
        white-space: normal;
        display: inline-block;
      }
    }
  }
}

// asset checkbox
.card {
  .card-body {
    .product_img {
      ._user-avatar {
        .cls-prodchk {
          position: absolute;
          left: 14px;
          top: 14px;
          background-color: #ffffff;
          z-index: 9;
          height: 28px;
          width: 28px;
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: center;

          .mdc-form-field {
            display: flex;
            align-items: center;
            justify-content: center;

            .mdc-checkbox {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}

// radio button css
.mat-mdc-radio-button {
  display: inline-block;



  .mdc-form-field {
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-family: $font-family;
    font-size: inherit;

    .mdc-radio {
      height: 20px;
      width: 20px;
      padding: 0 4px 0 0;

      .mat-mdc-radio-touch-target {
        height: 20px;
        width: 20px;
      }

      .mdc-radio__native-control {
        top: 0;
        right: 0;
        left: 0;
        width: 20px;
        height: 20px;
      }

      .mdc-radio__background {
        height: 20px;
        width: 20px;

        .mdc-radio__outer-circle {
          border-color: rgba(0, 0, 0, 0.15) !important;
        }

        &::before {
          background-color: transparent;
        }
      }

      // .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
      //   border-color: #00bfa5 !important;
      // }

      // .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
      //   border-color: #00bfa5;
      // }

      // &:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
      //   border-color: #00bfa5;
      // }

      // &:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
      //   border-color: #00bfa5;
      // }

      // &:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
      //   border-color: #00bfa5;
      // }

      // &:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle {
      //   border-color: #00bfa5;
      // }
    }

    label {
      font-family: $font-family;
      margin: 0 0 0 0;
      padding: 0 0 0 4px;
      // font-size: $text-medium-size;
      color: $utl-text-color-12;
      font-weight: 500;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .mdc-radio--disabled+label {
      font-family: $font-family;
      margin: 0 0 0 0;
      padding: 0 0 0 4px;
      // font-size: $text-medium-size;
      color: $utl-text-color-12 !important;
      font-weight: 500;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }
  }

}


.projectradiobtn {
  border: 1px solid #eae3e3;
  @include padding(left, top, right, bottom, 2, 2, 2, 2);

  .mat-mdc-radio-group {

    .cls-radio {
      text-align: center;
      @include padding(left, top, right, bottom, 10, 22, 10, 22);
      cursor: pointer;
      min-width: 48%;

      .mdc-form-field {
        margin: 0;

        .mdc-radio {
          display: none;
        }

        label {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "medium");
          color: $utl-text-color-12;
          cursor: pointer;
        }
      }

    }

    .mat-mdc-radio-checked {
      background: $utl-theme-color;

      .mdc-form-field {
        .mdc-radio {
          display: none;
        }

        label {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "medium");
          color: $utl-white;
        }
      }

    }
  }
}

// end checkbox css

// start tab css
.cls-newtab {
  background-color: $utl-white;
  padding: 0px 20px 0px 32px;
  z-index: 10;

  margin-bottom: 0;
  border-bottom: 0;
  position: fixed;
  width: calc(100% - 228px);
  border-top: 1px solid #f4f6f9;
}

.cls-dynamic-tab {

  padding: 0px 20px 0px 32px;
  z-index: 10;

  margin-bottom: 0;
  border-bottom: 0;
  width: 100%;
  border-top: 1px solid #f4f6f9;
}

.mat-mdc-tab-nav-bar,
.mat-mdc-tab-header,
.nav-material-tab,
.mat-mdc-tab-header-pagination-controls-enabled,
.mat-mdc-tab-nav-bar-stretch-tabs,
.mat-primary,
.confirmation_filter {
  .mat-mdc-tab-link-container {
    .mat-mdc-tab-list {
      .mat-mdc-tab-links {

        .mdc-tab,
        .mat-mdc-tab-link,
        .mat-mdc-focus-indicator {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights , regular);
          color: $secondary-text-color;
          min-width: auto;
          padding-right: 0;
          padding-left: 0;
          margin-left: 24px;
          margin-right: 24px;
          flex-grow: 0;
          letter-spacing: normal;
          line-height: 55px;

          &:first-child {
            margin-left: 0;
          }

          &.mdc-tab--active,
          .mdc-tab-indicator--active {
            font-weight: map-get($font-weights , semi-bold);
          }

          .mdc-tab__ripple {
            &::before {
              background-color: transparent;
            }
          }

          .mdc-tab__content {
            .mdc-tab__text-label {
              color: $secondary-text-color;
            }
          }

          .mdc-tab-indicator {

            .mdc-tab-indicator__content,
            .mdc-tab-indicator__content--underline {
              border-color: $secondary-text-color;
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {

    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        overflow: hidden;
      }
    }
  }

  .mat-mdc-tab-header-pagination {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding-left: 0;
  }

  .mat-mdc-tab-header-pagination-disabled {
    box-shadow: none;
  }
}

.mat-mdc-tab-group {
  &.uom_filter {
    .mat-mdc-tab-body-wrapper {
      @include background-color;
    }
  }
}

.nav-material-tab {
  .mat-mdc-tab-link-container {
    .mat-mdc-tab-list {
      .mat-mdc-tab-links {

        .mdc-tab {
          font-family: $font-family;
          font-size: $text-default-size;
          font-weight: map-get($font-weights , regular);
          color: $secondary-text-color;
          min-width: auto;
          padding-right: 0;
          padding-left: 0;
          margin-left: 24px;
          margin-right: 24px;
          flex-grow: 0;
          letter-spacing: normal;
          line-height: 55px;
          height: 55px;

          &:first-child {
            margin-left: 0;
          }

          &.mdc-tab--active,
          .mdc-tab-indicator--active {
            font-weight: map-get($font-weights , semi-bold);
          }

          .mdc-tab__ripple {
            &::before {
              background-color: transparent;
            }
          }

          .mdc-tab__content {
            .mdc-tab__text-label {
              color: $secondary-text-color;
            }
          }

          .mdc-tab-indicator {

            .mdc-tab-indicator__content,
            .mdc-tab-indicator__content--underline {
              border-color: $secondary-text-color;
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        overflow: hidden;
      }
    }
  }

  .mat-mdc-tab-header-pagination {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding-left: 0;
  }

  .mat-mdc-tab-header-pagination-disabled {
    box-shadow: none;
  }
}

.nav-material-container {
  padding: 69px 22px 20px 17px;
}


.Matfilter {
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {
            font-family: $font-family;
            font-size: $text-default-size;
            // min-width: 160px;
            letter-spacing: normal;

            .mdc-tab__ripple {
              &::before {
                background-color: transparent;
              }
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                font-family: $font-family;
                font-size: 14px;
                color: $secondary-text-color;
                font-weight: 400;
              }
            }
          }

          .mdc-tab--active,
          .mdc-tab-indicator--active {
            .mdc-tab__content {
              .mdc-tab__text-label {
                font-weight: map-get($font-weights , semi-bold);
              }
            }

            .mdc-tab-indicator {

              .mdc-tab-indicator__content,
              .mdc-tab-indicator__content--underline {
                border-color: $secondary-text-color;
              }
            }
          }
        }
      }
    }
  }
}


.mat-mdc-tab-group,
.confirmation_filter,
.mat-primary,
.mat-mdc-tab-group-stretch-tabs {
  .mat-mdc-tab-header {
    border-bottom: 1px solid rgba(0, 0, 0, .12);

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {
            font-family: $font-family;
            font-size: $text-default-size;
            flex-grow: 0;
            // min-width: 160px;
            letter-spacing: normal;

            .mdc-tab__ripple {
              &::before {
                background-color: transparent;
              }
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                font-family: $font-family;
                font-size: 14px;
                color: $secondary-text-color;
                font-weight: 500;
              }
            }
          }

          .mdc-tab--active,
          .mdc-tab-indicator--active {
            .mdc-tab__content {
              .mdc-tab__text-label {
                font-weight: map-get($font-weights , semi-bold);
              }
            }

            .mdc-tab-indicator {

              .mdc-tab-indicator__content,
              .mdc-tab-indicator__content--underline {
                border-color: $secondary-text-color;
              }
            }
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    width: 100%;
  }
}


.expense-content {

  .mat-mdc-tab-group {
    &.dynamicwidth {
      .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
          flex-grow: .35;
        }
      }
    }

    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        flex-grow: 1;

        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              flex-grow: 1;
              letter-spacing: normal;
            }
          }
        }
      }
    }
  }
}

//custom performance tab css


/************** performance graph tab ******************/
#performance-gph {
  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    position: absolute;
    right: 26px;
    top: 26px;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {
          @include border-radius;
          @include border(1px, solid, $utl-blue-15);
          display: inline-flex;

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {

            height: 25px;
            @include border-pos(right, 1px, solid, $utl-blue-15);
            padding: 0 15px;
            opacity: 1;
            cursor: pointer;
            min-width: 75px;
            color: $utl-blue-15;

            &:last-child {
              @include border-pos(right, 0px, solid, $utl-blue-15);
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                color: $utl-blue-15;
                font-size: $text-medium-size;
                font-weight: (map-get($font-weights, medium));
              }
            }


            &.mdc-tab--active,
            .mdc-tab-indicator--active {
              @include background-color($utl-blue-15);


              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: $utl-white;
                  font-size: $text-medium-size;
                  font-weight: (map-get($font-weights, medium));
                }
              }

              .mdc-tab-indicator {

                .mdc-tab-indicator__content,
                .mdc-tab-indicator__content--underline {
                  border-color: transparent;
                }
              }
            }

          }


        }


      }
    }
  }
}

#timeoffice-gph {
  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, -28, 0, 0);
    float: right;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {
          @include border-radius;
          @include border(1px, solid, $utl-blue-15);
          display: inline-flex;

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {

            height: 30px;
            line-height: 30px;
            @include border-pos(right, 1px, solid, $utl-blue-15);
            padding: 0 20px;
            opacity: 1;
            cursor: pointer;
            min-width: 75px;
            color: $utl-blue-15;

            &:last-child {
              @include border-pos(right, 0px, solid, $utl-blue-15);
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                color: $utl-blue-15;
                font-size: $text-default-size;
                font-weight: (map-get($font-weights, medium));
              }
            }


            &.mdc-tab--active,
            .mdc-tab-indicator--active {
              @include background-color($utl-blue-15);


              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: $utl-white;
                  font-size: $text-default-size;
                  font-weight: (map-get($font-weights, medium));
                }
              }

              .mdc-tab-indicator {

                .mdc-tab-indicator__content,
                .mdc-tab-indicator__content--underline {
                  border-color: transparent;
                }
              }
            }

          }


        }


      }
    }
  }
}


#engage-gph {
  .mat-mdc-tab-header {
    border-bottom: 0;
    @include margin(left, top, right, bottom, 0, -37, 30, 0);
    float: right;

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {
          @include border-radius;
          // @include border(1px, solid, $utl-blue-15);
          display: inline-flex;

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {

            height: 39px;
            line-height: 39px;
            // @include border-pos(right, 1px, solid, $utl-blue-15);
            padding: 0;
            opacity: 1;
            cursor: pointer;
            min-width: 75px;
            // color: $utl-blue-15;

            &:last-child {
              // @include border-pos(right, 0px, solid, $utl-blue-15);
            }

            .mdc-tab__content {
              .mdc-tab__text-label {
                // color: $utl-blue-15;
                font-size: $text-medium-size;
                font-weight: (map-get($font-weights, semi-bold));
              }
            }


            &.mdc-tab--active,
            .mdc-tab-indicator--active {
              // @include background-color($utl-blue-15);


              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: $utl-white !important;
                  font-size: $text-medium-size;
                  font-weight: (map-get($font-weights, bold));
                }
              }

              .mdc-tab-indicator {

                .mdc-tab-indicator__content,
                .mdc-tab-indicator__content--underline {
                  border-color: transparent;
                }
              }
            }

          }

        }


      }
    }
  }
}




.clearnce-snippet {
  padding: 24px 30px 16px !important;
}

#performance-gph.mat-mdc-tab-group.mat-primary .mat-ink-bar,
#performance-gph.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: transparent !important;
}

#garph-tab {
  .mat-mdc-tab-header {
    border-bottom: 0;
    @include padding(left, top, right, bottom, 0, 23, 0, 23);

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {
          display: flex;
          justify-content: flex-end;
          padding-right: 20px;

          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {

            height: 39px;
            line-height: 39px;
            @include border(1px, solid, rgba(151, 151, 151, 0.28));
            padding: 0 10px;
            opacity: 1;
            cursor: pointer;
            min-width: 75px;
            color: $utl-blue-15;



            .mdc-tab__content {
              .mdc-tab__text-label {
                color: $secondary-text-color;
                font-size: $text-default-size;
                font-weight: (map-get($font-weights, regular));
              }
            }


            &.mdc-tab--active,
            .mdc-tab-indicator--active {
              @include background-color(#f1f1f1);


              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: $secondary-text-color;
                  font-size: $text-default-size;
                  font-weight: (map-get($font-weights, semi-bold));
                }
              }

              .mdc-tab-indicator {

                .mdc-tab-indicator__content,
                .mdc-tab-indicator__content--underline {
                  border-color: transparent;
                }
              }
            }

          }

        }


      }
    }
  }
}


.cls-recruitmentgraph {
  .mat-mdc-tab-header {
    border-bottom: 0;
    @include padding(left, top, right, bottom, 0, 0, 0, 30);

    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {
          display: flex;
          justify-content: flex-end;


          .mdc-tab,
          .mat-mdc-tab,
          .mat-mdc-focus-indicator {

            height: 39px;
            line-height: 39px;
            @include border(1px, solid, rgba(151, 151, 151, 0.28));
            padding: 0 10px;
            opacity: 1;
            cursor: pointer;
            min-width: 75px;
            color: $utl-blue-15;



            .mdc-tab__content {
              .mdc-tab__text-label {
                color: $secondary-text-color;
                font-size: $text-default-size;
                font-weight: (map-get($font-weights, regular));
              }
            }


            &.mdc-tab--active,
            .mdc-tab-indicator--active {
              @include background-color(#f1f1f1);


              .mdc-tab__content {
                .mdc-tab__text-label {
                  color: $secondary-text-color;
                  font-size: $text-default-size;
                  font-weight: (map-get($font-weights, semi-bold));
                }
              }

              .mdc-tab-indicator {

                .mdc-tab-indicator__content,
                .mdc-tab-indicator__content--underline {
                  border-color: transparent;
                }
              }
            }

          }

        }


      }
    }
  }
}




.mat-menubutton {
  border: 0;
  background: transparent;
  padding: 0 0 0 38px;
  cursor: pointer;
  margin-top: -20px;
}

.cls-hrops {

  .mat-mdc-tab-group,
  .confirmation_filter,
  .mat-primary,
  .mat-mdc-tab-group-stretch-tabs {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {

            .mdc-tab,
            .mat-mdc-tab,
            .mat-mdc-focus-indicator {
              font-family: $font-family;
              font-size: $text-default-size;
              flex-grow: 0;
              // min-width: 160px;
              height: 58px;
              margin-left: 27px;

            }
          }
        }
      }
    }
  }

}

.cls-holdrop {

  .mat-mdc-form-field,
  .mat-mdc-form-field-type-mat-select,
  .mat-form-field-appearance-fill,
  .mat-primary {

    .mat-mdc-text-field-wrapper,
    .mdc-text-field,
    .mdc-text-field--filled,
    .mdc-text-field--no-label {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .mat-mdc-form-field-flex {


        .mat-mdc-form-field-infix {
          padding-top: 2px !important;
          padding-bottom: 0 !important;
          margin-left: 5px;
          min-height: auto;

          .mat-mdc-select {

            .mat-mdc-select-trigger {


              .mat-mdc-select-value {
                width: 70px;
                max-width: 100%;


                .mat-mdc-select-value-text {
                  font-family: $font-family;
                  font-size: $heading-large-font-size;
                  font-weight: map-get($font-weights, "light");
                  letter-spacing: normal;
                  color: $utl-text-color-2;
                }
              }
            }
          }
        }
      }





      .mdc-line-ripple,
      .mdc-line-ripple--active {
        &::after {
          border-bottom-color: transparent;
          border-bottom-width: 0;
        }

        &::before {
          border-bottom-color: transparent !important;
        }


      }

    }
  }
}


//  bgv tab css

.cls-bgvtab {
  width: 100%;

  .mat-mdc-tab-header {
    @include border-pos(bottom, 0px, solid, transparent);

    .mat-mdc-tab-label-container {

      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {

          .mat-mdc-tab {
            height: 41px;
            @include padding(left, top, right, bottom, 16, 0, 16, 0);
            cursor: pointer;
            box-sizing: border-box;
            opacity: 1;
            min-width: 0;
            text-align: center;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            position: relative;
            @include border-pos(top, 1px, solid, #c7c9cd);
            @include border-pos(right, 1px, solid, #c7c9cd);
            @include border-pos(left, 1px, solid, #c7c9cd);
            @include border-pos(bottom, 1px, solid, #c7c9cd);
            @include background-color(#f9f9f9);

            .mdc-tab__content {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, "semi-bold");
              color: rgba(39, 44, 62, 0.6);
            }

            &:first-child {
              border-top-left-radius: 4px;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }
          }

          .mdc-tab--active {
            @include background-color;
            @include border-pos(bottom, 0px, solid, transparent);

            .mdc-tab-indicator {

              .mdc-tab-indicator__content,
              .mdc-tab-indicator__content--underline {
                border-color: transparent;
              }
            }
          }
        }


      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    @include border(1px, solid, #c7c9cd);
    @include background-color;
    @include padding(left, top, right, bottom, 31, 31, 31, 31);
    @include margin(left, top, right, bottom, 0, -1, 0, 0);
  }
}

// end tab css



// dashboard input css

#clsdashboardselect {
  border-radius: 2px;
  border: solid 0.5px #a1a4ac;
  background-color: #ffffff;
  padding: 3px 10px;
  width: calc(100% - 105px);
  margin-left: 20px;

  .mat-mdc-text-field-wrapper {
    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    height: 28px;


    .mat-mdc-form-field-focus-overlay {
      @include background-color(transparent);
    }

    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        width: 100%;
        padding-top: 3px !important;
        padding-bottom: 0px !important;
        min-height: 28px;

        .mdc-floating-label {
          font-family: $font-family;
          font-size: $text-medium-size;
          color: $utl-text-color-1;
          font-weight: map-get($font-weights , 'medium');
        }

        .mdc-text-field__input {
          font-family: $font-family;
          // font-size: $text-medium-size;
          // line-height: normal;
          color: $utl-text-color-1;
          font-weight: map-get($font-weights , 'medium');
        }

        .mat-mdc-select {
          @extend .mdc-text-field__input;

          .mat-mdc-select-trigger {
            .mat-mdc-select-arrow-wrapper {
              transform: translateY(0px);

            }
          }

          &.mat-mdc-select-disabled {
            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                @extend .mdc-text-field__input;
              }
            }
          }
        }

      }

      .mat-mdc-floating-label {
        top: 28px;

        .mdc-floating-label--required {
          color: #f44336;
        }
      }

      .mat-mdc-form-field-icon-prefix,
      .mat-mdc-form-field-icon-suffix {
        align-self: baseline;

        .mat-icon {
          padding: 0;
        }

        .mat-datepicker-toggle {
          .mat-mdc-icon-button {
            &.mat-mdc-button-base {
              width: 18px;
              height: 18px;
              padding: 0;
            }

            svg,
            img {
              width: 16px;
              height: 16px;
              vertical-align: baseline !important;
            }

            .mat-mdc-button-touch-target {
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
      font-family: $font-family;
    }

    &.mdc-text-field--filled {
      .mdc-floating-label--float-above {
        transform: translateY(-120%) scale(0.85);
      }
    }

    .mdc-line-ripple::before,
    .mdc-line-ripple::after {
      bottom: 0px;
      border-bottom-color: transparent !important;
    }

  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}



//start custom search Home

.cls-custom-search {
  display: flex;
  align-items: center;
  justify-content: center;

  .mat-mdc-text-field-wrapper,
  .mdc-text-field,
  .mdc-text-field--filled,
  .mdc-text-field--no-label {
    width: 50%;
    height: 34px;

    .mat-mdc-form-field-flex {

      .mat-mdc-form-field-infix {
        padding-top: 2px !important;

        .mat-mdc-input-element,
        .mat-mdc-form-field-input-control,
        .mdc-text-field__input {
          @include margin(left, top, right, bottom, 0, 0, 15, 0);
          @include padding(left, top, right, bottom, 16, 0, 0, 0);
          @include background-color(#f9f9f9);
          border-radius: 4px;
          height: 31px;
          font-weight: map-get($font-weights, "semi-bold");

          &::placeholder {
            font-size: $text-medium-size;
            font-style: italic;
            font-weight: map-get($font-weights, "medium");
          }
        }
      }
    }
  }

  .mdc-line-ripple,
  .mdc-line-ripple--active,
  .mdc-line-ripple--deactivating {
    &::after {
      border-bottom-color: transparent !important;
    }

    &::before {
      border-bottom-color: transparent !important;
    }
  }
}

//end custom search Home



// Start Candidate Detail dropdown css


.custome-status {
  .status-inner {


    .mat-mdc-form-field,
    .mat-mdc-form-field-type-mat-select,
    .mat-form-field-appearance-fill,
    .mat-primary {
      width: 85px;

      .mat-mdc-text-field-wrapper,
      .mdc-text-field,
      .mdc-text-field--filled,
      .mdc-text-field--no-label {
        padding: 0 10px;

        .mat-mdc-form-field-flex {
          .mat-mdc-form-field-infix {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            min-height: auto;

            .mat-mdc-select {
              .mat-mdc-select-trigger {
                .mat-mdc-select-value {
                  .mat-mdc-select-value-text {
                    color: $utl-theme-color;
                    font-weight: 700;
                  }
                }
              }
            }

            .mat-mdc-select-arrow-wrapper {
              .mat-mdc-select-arrow {
                color: $utl-theme-color;
              }
            }
          }
        }

        .mdc-line-ripple,
        .mdc-line-ripple--active {
          &::after {
            border-bottom-color: transparent;
          }

          &::before {
            border-bottom-color: transparent;
          }
        }

      }

      .mat-mdc-form-field-subscript-wrapper,
      .mat-mdc-form-field-bottom-align {
        display: none;
      }
    }
  }
}



// End Candidate Detail dropdown css


// start attandance calander mat-expansion css

#cls-clockpanel {
  &.mat-accordion {
    .mat-expansion-panel {
      margin-bottom: 16px;
      box-shadow: none;
      background-color: transparent;

      &::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        border-top: 0px solid #d0d0d0;
        z-index: 999;

        &:first-child {
          display: none;
        }
      }

      .mat-expansion-panel-header {
        padding: 0 15px 0 0;
        font-family: $font-family;
        height: 40px;

        &:hover {
          background-color: transparent;
        }

        .mat-content {
          .mat-expansion-panel-header-title {
            color: rgba(0, 0, 0);
            font-weight: 700;
            font-size: 16px;
            position: relative;
            line-height: 2;
            display: flex;
            align-items: center;
            justify-content: space-between;


          }
        }

        .mat-expansion-indicator {
          margin: 0;
        }
      }

      .mat-expansion-panel-content {
        font-family: $font-family;

        .mat-expansion-panel-body {
          padding: 0;
          margin: 6px 0 16px;
        }
      }
    }
  }
}

#cls-homesearch-mat {
  &.mat-accordion {
    .mat-expansion-panel {
      margin: 0px;
      box-shadow: none;
      border-bottom: 0;



      .mat-expansion-panel-header {
        font-family: $font-family;
        padding: 0px 18px 0 18px;
        height: 71px;


        &::before {
          content: " ";
          position: absolute;
          width: 100%;
          height: 1px;
          bottom: 0px;
          left: 0;

          z-index: 999;

          &:first-child {
            display: none;
          }
        }

        &:hover {
          background-color: transparent;
        }

        .mat-content {
          .mat-expansion-panel-header-title {
            font-size: $text-default-size;
            font-weight: 600;
            font-family: $font-family;
            text-transform: uppercase;
            line-height: 2;
            display: flex;
            align-items: center;


          }
        }
      }

      .mat-expansion-panel-content {
        font-family: $font-family;

        .mat-expansion-panel-body {
          padding: 10px 0 16px;
        }
      }
    }
  }

  #cls-innermat-acc {
    &.mat-accordion {
      .mat-expansion-panel {

        box-shadow: none;


        .mat-expansion-panel-header {
          margin: 14px 10px 10px 14px;
          padding: 13px 17px 13px 13px;
          border-radius: 4px 4px 0 0;
          border: 1px solid rgba(0, 0, 0, 0.07);
          font-family: $font-family;


          .mat-content {
            .mat-expansion-panel-header-title {
              font-weight: 600;
              font-size: $text-default-size;
              position: relative;
              line-height: 2;
              display: flex;
              align-items: center;
              justify-content: space-between;
              text-transform: capitalize;

            }
          }
        }

        .mat-expansion-panel-content {
          font-family: $font-family;

          .mat-expansion-panel-body {
            padding: 0 0 5px;
          }
        }
      }
    }
  }
}

.feedback-panel-acc {
  &.mat-accordion {
    .mat-expansion-panel {
      margin-bottom: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: none;
      background-color: transparent;

      &::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: 0px;
        left: 0;
        border-top: 0px solid #d0d0d0;
        z-index: 999;

        &:first-child {
          display: none;
        }
      }

      .mat-expansion-panel-header {
        padding: 0 15px 0 0;
        font-family: $font-family;

        &:hover {
          background-color: transparent;
        }


      }

      .mat-expansion-panel-content {
        font-family: $font-family;

        .mat-expansion-panel-body {
          padding: 0 0 5px;
        }
      }
    }
  }
}

// end attandance calander mat-expansion css

// CTC checkbox style

.myCtcStatCheck {
  .mdc-form-field {
    .mdc-checkbox {
      display: none;

    }

    label {
      font-family: $font-family;
      font-size: $text-default-size;
      color: $utl-text-color-12;
      font-weight: 600;
      padding: 0;
    }
  }
}

.checkboxlist {
  &.myCtcStatCheck {
    .mdc-checkbox {
      display: block;

    }

    label {
      font-size: $text-default-size;
      color: $utl-text-color-12;
      font-weight: 500;
      padding: 0 12px 0 0;
    }
  }
}


.mat-mdc-checkbox {
  &.cls-chkbox {
    .mdc-form-field {
      font-size: inherit;

      label {
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "bold");
        color: $utl-text-color-2;
        padding: 0;
      }
    }
  }
}

// end CTC checkbox style

// mat drawer content css
.mat-drawer-content {
  font-family: $font-family;
  color: $utl-text-color-12 ;
  font-weight: 500;


}

.mat-drawer-container {
  background-color: #f4f6f9 !important;
}

// mat drawer content caa




/* start toggle btn css */
.mat-button-toggle-group,
.mat-button-toggle-group-appearance-standard {
  &.slider-btn {
    border: 0;
    padding-left: 35px;

    .mat-button-toggle,
    .mat-button-toggle-appearance-standard {
      background: transparent;
      margin-right: 24px;




      .mat-button-toggle-button,
      .mat-focus-indicator {
        font-family: $font-family;
        font-size: 1em;
        font-weight: 600;
        color: $secondary-text-color;

        .mat-button-toggle-label-content {
          padding: 0;
          line-height: 58px;
        }
      }

    }

    .mat-button-toggle-checked {
      border-bottom: 2px solid #000;
    }

  }

  .mat-button-toggle+.mat-button-toggle {
    border-left: 0 !important;
  }
}


//Team toggle btn css
.cls-togglebtn {
  &.asset-selection {
    .mat-button-toggle {
      .mat-button-toggle-button {
        .mat-button-toggle-label-content {
          line-height: 32px;
          font-size: 1em !important;
        }
      }

      &.mat-button-toggle-checked {
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            color: $utl-white !important;
            background-color: #039be5;
          }
        }
      }
    }
  }

  .mat-button-toggle-group {
    @include border(1px, solid, #039be5);

    .mat-button-toggle {
      height: 32px;

      .mat-button-toggle-button {
        .mat-button-toggle-label-content {
          line-height: 32px;
          font-size: $text-medium-size;
        }
      }

      &.mat-button-toggle-checked {
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            color: $utl-white;
            background-color: #039be5;
          }
        }
      }
    }
  }
}

.cls-teamtogglebtn {

  .mat-button-toggle-group {
    @include border(1px, solid, #039be5);


    .mat-button-toggle {
      height: 32px;

      .mat-button-toggle-button {
        .mat-button-toggle-label-content {
          line-height: 32px;
          font-size: $text-medium-size;
        }
      }

      &.mat-button-toggle-checked {
        .mat-button-toggle-button {
          .mat-button-toggle-label-content {
            color: $utl-white;
            background-color: #039be5;
          }
        }
      }
    }
  }
}

// end toggle button css

#panel-acc .mat-expansion-panel-body {
  padding: 25px 0 16px;
  border-top: solid 1px rgba(151, 151, 151, 0.18);
}

#panel-acc .mat-expansion-panel-header {
  font-family: $font-family !important;
  font-size: 1.071em;
  font-weight: 400;
}

#panel-acc .mat-expansion-indicator {
  border: 1px solid #dedede;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  text-align: center;
  position: absolute;
  right: 12px;
  /* top       : 74px;  */
  background: rgb(255, 255, 255);
  /* Old browsers */
  background: -moz-linear-gradient(to bottom,
      rgb(255, 255, 255) 0%,
      rgb(239, 239, 239) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(to bottom,
      rgb(255, 255, 255) 0%,
      rgb(239, 239, 239) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,
      rgb(255, 255, 255) 0%,
      rgb(239, 239, 239) 100%)
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /*filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e1e1e1', GradientType=0);*/
    /* IE6-9 */
}

#panel-acc .mat-expansion-indicator::after {
  margin-top: -4px;
}

#panel-acc .mat-expansion-panel-header {
  padding: 0 6px !important;
}




// goal tab css
.cls-goalkrabox {
  .mat-mdc-tab-header {
    .mat-mdc-tab-label-container {
      .mat-mdc-tab-list {
        .mat-mdc-tab-labels {
          .mdc-tab {
            flex-grow: 1;
          }
        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        font-family: $font-family;
        font-size: inherit;

        .cls-bodybox {
          @include padding-em(left, top, right, bottom, 2.142, 2.142, 2.142, 1.142);

          .cls-innerbox {
            .kra-collapsible {
              .mat-expansion-panel {
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background-color: transparent !important;
}



// access right chips css
.Rectdiv,
.clsright-box {

  .mdc-evolution-chip-set,
  .mat-mdc-chip-listbox,
  .mat-mdc-chip-set {
    .mdc-evolution-chip-set__chips {
      display: flex;
      align-items: center;

      .mat-mdc-chip,
      .mat-mdc-chip-option,
      .mat-primary,
      .mdc-evolution-chip,
      .mdc-evolution-chip--with-trailing-action,
      .mat-mdc-standard-chip,
      .mat-mdc-chip-with-trailing-icon,
      .mdc-evolution-chip--filter,
      .mdc-evolution-chip--selectable,
      .mdc-evolution-chip--selecting,
      .mdc-evolution-chip--with-primary-graphic {
        @include padding(left, top, right, bottom, 15, 3, 8, 3);
        font-family: $font-family;
        font-size: initial;
        @include border(1px, solid, $primary-text-color);
        color: $secondary-text-color;
        min-height: 28px;
        @include border-radius;
        line-height: normal;
        @include background-color;

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {

            .mdc-evolution-chip__graphic,
            .mat-mdc-chip-graphic {
              display: none;
            }
          }
        }

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--trailing {
          .mat-icon {
            color: $secondary-text-color;
            padding-right: 0;
          }

        }
      }

      .mat-mdc-chip-selected {

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--primary {

          .mdc-evolution-chip__action,
          .mat-mdc-chip-action,
          .mdc-evolution-chip__action--primary {

            .mdc-evolution-chip__graphic,
            .mat-mdc-chip-graphic {
              display: none;
            }

            .mdc-evolution-chip__text-label,
            .mat-mdc-chip-action-label {
              color: $secondary-text-color;
            }

          }
        }

        .mdc-evolution-chip__cell,
        .mdc-evolution-chip__cell--trailing {
          .mat-icon {
            color: $secondary-text-color;
            padding-right: 0;
          }

        }
      }
    }
  }
}


// leave chips box css

.cls-leavebox-container {

  .mdc-evolution-chip-set,
  .cls-chipsbox {


    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .mdc-evolution-chip-set__chips {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);

      .mat-mdc-chip {
        @include background-color(#F0F0F0);
        @include border(0.5px, solid, #D9D9D9);
        @include border-radius;
        padding: 0 4px !important;
        @include margin(left, top, right, bottom, 0, 0, 4, 4);
        height: 20px;
        min-height: auto;
        font-size: inherit;

        .mdc-evolution-chip__cell--primary {
          .mdc-evolution-chip__action {
            .mdc-evolution-chip__text-label {

              font-family: $font-family;
              font-weight: map-get($font-weights , regular);
              font-size: $text-small-size;
              line-height: 18px;
              color: $utl-text-color-1;

            }
          }
        }
      }

    }

  }
}

.cls-leavepublic {
  .mat-mdc-radio-button {
    .mdc-form-field {
      margin-bottom: 0;
    }
  }
}


.expense-content {
  .mat-tab-group {
    .mat-tab-header {
      .mat-tab-label-container {
        .mat-tab-list {
          .mat-tab-labels {
            .mat-tab-label {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}


// holiday calendar dropdown css

.cls-holidaydp {
  @include margin (left, top, right, bottom, 5, -20, 0, 0);
  width: 30%;

  .mat-mdc-form-field {

    .mat-mdc-text-field-wrapper {

      .mat-mdc-form-field-flex {

        .mat-mdc-form-field-infix {


          .mat-mdc-select {

            .mat-mdc-select-trigger {

              .mat-mdc-select-value {

                .mat-mdc-select-value-text {
                  font-size: $heading-small-font-size;
                  font-weight: map-get($font-weights , 'light');
                }
              }
            }
          }
        }
      }
    }
  }
}

// holiday setop table checkbox css

.clsoth_text {
  .mdc-form-field {
    margin-bottom: 0;
  }
}

.cls-divcheckbox {
  .mat-mdc-checkbox {
    .mdc-form-field {
      margin-bottom: 0;
    }
  }
}


//report dropdown css

.report-select {
  margin-top: -20px;

  .mat-mdc-text-field-wrapper {

    .mat-mdc-form-field-flex {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border(0px, solid, transparent);

      .mat-mdc-form-field-infix {
        @include padding(left, top, right, bottom, 0, 18, 0, 0);
        @include border-pos(top, 0px, solid, transparent);

        .mat-mdc-select {
          min-width: 150px;

          .mat-mdc-select-trigger {

            .mat-mdc-select-value {
              max-width: 100%;
              @include padding(left, top, right, bottom, 0, 0, 10, 0);

              .mat-mdc-select-value-text {
                font-size: $heading-s-medium-font-size;
                // color: $utl-white;
                font-weight: map-get($font-weights, "bold");
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                width: 100%;
                display: block;

                .mat-mdc-select-min-line {

                  font-size: 100%;

                }
              }
            }

            .mat-mdc-select-arrow-wrapper {
              transform: translateY(-4%);

              .mat-mdc-select-arrow {
                // color: $utl-white !important;
              }
            }
          }
        }
      }
    }

    .mdc-line-ripple {
      &::before {
        border-bottom-color: transparent;
        border-bottom-width: 0 !important;
      }

      &::after {
        border-bottom-color: transparent;
        border-bottom-width: 0 !important;
      }
    }
  }

}

// filter checkbox css

.Matfilter,
.checkbox {
  .mat-mdc-checkbox {
    margin-bottom: 4px;
  }

}

// setting input field css
.clsretirement {
  margin-top: -20px;
  margin-bottom: 20px;
}

// pagination page css
.cls-pagination {
  &.mat-mdc-paginator {
    .mat-mdc-paginator-outer-container {
      .mat-mdc-paginator-container {
        .mat-mdc-paginator-page-size {
          .mat-mdc-form-field {
            .mat-mdc-text-field-wrapper {
              .mat-mdc-form-field-flex {
                .mat-mdc-form-field-infix {
                  padding: 8px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.mat-mdc-paginator {
  .mat-mdc-paginator-outer-container {
    .mat-mdc-paginator-container {
      .mat-mdc-paginator-page-size {
        .mat-mdc-form-field {
          .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
              .mat-mdc-form-field-infix {
                padding: 8px !important;
              }
            }
          }
        }
      }
    }
  }
}

.cls-matbox {
  &.mat-mdc-radio-button {
    display: block;
    margin-bottom: 8px;
  }
}

// UOM select css
.cdk-overlay-container {
  .cdk-overlay-connected-position-bounding-box {
    .cdk-overlay-pane {
      .cls-uomdropdown {

        &.mat-mdc-select-panel {
          .mat-mdc-option {
            &.mdc-list-item {
              .mat-pseudo-checkbox {
                display: none;
              }
            }
          }

        }
      }
    }
  }
}

// search box css
.cls-searchbox {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      background-color: whitesmoke;
      padding: 0 10px;
      height: 42px;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          min-height: 42px;
          height: 42px;
          padding: 8px 0 !important;

          .mdc-floating-label {
            top: 14px;
          }

        }

        .mat-mdc-form-field-text-prefix {
          line-height: 35px;

          .mat-icon {
            height: auto;
            font-size: inherit;
            line-height: inherit;
            vertical-align: middle;
          }
        }
      }

      .mdc-line-ripple {
        &::before {
          bottom: 0;
        }

        &::after {
          bottom: 0;
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      &::before {
        margin-top: 0;
        height: auto;
      }
    }
  }
}


.cls-marketplacesidebar {
  &.mat-accordion {
    .mat-expansion-panel {
      .mat-expansion-panel-header {
        border: 0px;
        height: 29px;
        font-family: $font-family;
        font-size: $text-default-size;
        font-weight: 700;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0;
        }
      }
    }
  }
}

// global search checkbox css
.emp-mgnt-select {
  &.mat-mdc-select {
    .mat-mdc-select-trigger {
      .mat-mdc-select-value {
        display: none;
      }

      .mat-mdc-select-arrow-wrapper {
        .mat-mdc-select-arrow {
          width: 30px;
        }
      }
    }
  }
}

// mat-expansion panel css for goal checkin , company and team
._customize_tabs {

  .cls-chekinaccordion {
    .mat-accordion {
      .mat-expansion-panel:not([class*="mat-elevation-z"]) {
        box-shadow: none;
        // border: solid 0.5px #c8cdd1;
        @include margin(left, top, right, bottom, 0, 0, 0, 16);

        .mat-expansion-panel-header {
          @include padding(left, top, right, bottom, 16, 0, 16, 0);

          .mat-content {
            .mat-expansion-panel-header-title {
              font-family: $font-family;
              font-size: $text-default-size;
              font-weight: map-get($font-weights, 'semi-bold');
              line-height: 1.57;

              display: flex;
              align-items: center;

              img {
                width: 16px;
                height: 16px;
                @include margin(left, top, right, bottom, 0, 0, 5, 0);
              }
            }
          }
        }

        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            @include padding(left, top, right, bottom, 16, 16, 16, 16);

            .cls-prtobj {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, 'regular');
              line-height: 1.67;
            }

            .cls-lbldoc {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, 'regular');
              line-height: 1.58;
            }

            .cls-goalper {
              font-family: $font-family;
              font-size: $text-medium-size;
              font-weight: map-get($font-weights, 'medium');
              line-height: 1.67;
            }

            .cls-value {
              @extend .cls-goalper;
              font-weight: map-get($font-weights, 'regular');
              line-height: 1.58;
            }

            .cls-hrline {
              height: 8px;
              @include margin(left, top, right, bottom, 0, 0, 0, 0);
              @include border(0px, solid, transparent);
              width: 100%;
            }
          }
        }
      }

    }
  }
}

.clsfilter-box {
  .clschip {
    .mdc-evolution-chip-set {
      .mdc-evolution-chip-set__chips {
        margin-left: 0;
      }
    }
  }
}

// dispense loan flyer 

#requestloan {
  .mat-expansion-panel {
    .mat-expansion-indicator {
      padding: 2px 10px 6px;

      &:after {
        color: #fff;
      }
    }

    .mat-expanded {
      .mat-expansion-indicator {
        // background-color: #161717;
        padding: 2px 10px 6px;

        &:after {
          // color: #fff;
        }
      }
    }
  }

}

// process salary (salary dashboard) month selector
.mat-mdc-menu-panel {
  .mdc-list {
    .mat-mdc-menu-item {
      &.selectedMonth {
        background: #4b546e;

        .mdc-list-item__primary-text {
          color: #fff !important;
        }

        &:hover {
          background: #4b546e;

          .mdc-list-item__primary-text {
            color: #fff !important;
          }
        }
      }
    }
  }
}

// login pages css

#login-register {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mdc-text-field__input {
            font-weight: 500;
            letter-spacing: 1px;
            font-family: $font-family;
            font-size: $text-default-size;
            line-height: initial;
          }
        }
      }
    }
  }
}

// global search home

.cls-newselect {
  @include border(1px, solid, #d0d0d0);
  @include background-color(#fafafa);
  @include border-radius;
  display: flex;
  height: 40px;
  align-items: center;
  @include padding(left, top, right, bottom, 10, 0, 5, 0);
  @include margin(left, top, right, bottom, 0, 0, 16, 0);

  .cls-selectbox {
    @include border-pos(left, 1px, solid, #d0d0d0);
    @include padding(left, top, right, bottom, 5, 5, 0, 0);

    .mat-mdc-form-field {


      .mat-mdc-text-field-wrapper {
        @include padding(left, top, right, bottom, 0, 0, 0, 5);


        .mat-mdc-form-field-flex {

          .mat-mdc-form-field-infix {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            width: auto;
            min-height: auto;

            .mdc-floating-label {
              display: none;
            }

            .mat-mdc-form-field-label-wrapper {
              @include padding(left, top, right, bottom, 0, 0, 0, 0);
            }

            .mat-mdc-select {
              .mat-mdc-select-trigger {
                .mat-mdc-select-value {
                  display: none;
                }
              }
            }
          }
        }

        .mdc-line-ripple {
          &::after {
            border-bottom-width: 0px;
          }

          &::before {
            border-bottom-width: 0px;
          }

        }

      }

      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}

// attandance textarea css

.cls-round {
  .mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        padding: 0 !important;

        .mdc-text-field__input {
          border: 1px solid #cecece;
          border-radius: 4px;
          padding: 10px 15px;
        }
      }
    }

    .mdc-line-ripple {
      &::after {
        border-bottom-width: 0px;
      }
    }
  }
}

.cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane {
    .mat-mdc-select-panel {
      &.expense-select {
        max-height: 228px;
      }

      &.no-extrawidth {
        min-width: calc(100% + 0px);
        padding-top: 8px;
        padding-bottom: 0;
      }

      &.selectDropdownWidthCustom,
      &.cls-uomdropdown {
        min-width: calc(100% + 40px);
        width: 100%;
        margin-left: -10px;

        &.viewBig {

          min-width: calc(100% + 80px);

        }

        &.proj-dropdwn {
          min-width: calc(100% + 200px);
        }

        &.performBig {

          min-width: calc(100% + 130px);
        }

        &.dashboardbig {
          min-width: calc(100% + 160px);
          margin-left: -40px;
        }

        &.reviewBig {

          min-width: calc(100% + 90px);
          margin-left: -40px;
        }

        &.badgeSearch {
          min-width: calc(100% + 50px);
          margin-left: -20px;
        }

        &.cls-hrsearchdropdown {
          min-width: calc(100% + 150px);
        }

        &.cls-normaldropdown {
          min-width: 100%;
          width: 100%;
          margin-left: 0;
        }
      }
    }

  }
}


// OU checkbox css

.active {
  .mat-mdc-checkbox {
    margin-bottom: 4px;

    .mdc-form-field {
      label {
        color: #00bfa5;
      }
    }
  }
}

.cls-ouchk {
  .mat-mdc-checkbox {
    margin-bottom: 4px;
  }
}

// login page input css

.cls-logininput {
  &.mat-mdc-form-field {
    .mdc-text-field--filled {
      .mdc-line-ripple {
        &:after {
          border-bottom-color: #02563D !important;
        }
      }
    }

  }
}

.code-box {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          border: 1px solid #ccc;
          text-align: center;
          padding: 0 !important;
          min-height: 36px;
          min-width: 36px;
          margin-right: 6px;
          display: flex;
          align-items: center;
          justify-content: center;

          .mdc-text-field__input {
            text-align: center;
          }
        }
      }

      .mdc-line-ripple {
        &::before {
          bottom: 0;
          border-bottom-color: transparent;
          border-width: 0;
        }

        &::after {
          border-bottom-color: transparent;
          border-width: 0;
          bottom: 0;
          width: 85%;
        }
      }
    }
  }

}



// asset css
.cls-empassets {
  .cls-assetinvtab {
    &.mat-mdc-tab-group {
      .mat-mdc-tab-header {
        .mat-mdc-tab-label-container {
          .mat-mdc-tab-list {
            .mat-mdc-tab-labels {
              .mat-mdc-tab.mdc-tab {
                @include margin(left, top, right, bottom, 32, 0, 32, 0);
                @include padding(left, top, right, bottom, 0, 0, 0, 0);
                min-width: auto;
              }
            }
          }
        }
      }
    }

  }
}

// css for accordion in archive

.cls-accordbox {
  .mat-accordion {
    .mat-expansion-panel {
      &:last-of-type {
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
      }

      .mat-expansion-panel-header {
        // @include background-color(#f8f8f8);
        padding: 0 24px 0 10px;
        box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
        height: 38px;

        .mat-content {
          .mat-mdc-radio-button {


            .mdc-form-field {
              @include margin(left, top, right, bottom, 0, 0, 0, 0);


              label {
                font-size: inherit;

                .mat-expansion-panel-header-title {
                  font-size: $text-default-size;
                  font-weight: map-get($font-weights, "medium");
                  text-align: left;
                  color: $utl-text-color-12;
                }
              }
            }
          }
        }

        .mat-expansion-indicator {
          @include margin(left, top, right, bottom, 0, -3, 0, 0);
        }

        &.mat-expanded {
          height: 39px;

          .mat-content {
            .mat-mdc-radio-button {


              .mat-radio-label {
                @include margin(left, top, right, bottom, 0, 0, 0, 0);


                .mat-radio-label-content {
                  .mat-expansion-panel-header-title {
                    font-size: $text-default-size;
                    font-weight: map-get($font-weights, "semi-bold");
                    text-align: left;
                    color: $utl-text-color-12;
                  }
                }
              }
            }
          }

          .mat-expansion-indicator {
            @include margin(left, top, right, bottom, 0, 10, 0, 0);
          }
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          @include padding(left, top, right, bottom, 0, 0, 0, 16);

          .cls-bluktbl {
            width: 100%;
            table-layout: fixed;

            thead {
              th {
                font-family: $font-family;
                font-size: $text-small-size;
                font-weight: map-get($font-weights, bold);
                text-align: left;
                color: rgba(40, 46, 61, 0.8);
                @include padding(left, top, right, bottom, 0, 20, 3, 10);

                &:first-child {
                  @include padding(left, top, right, bottom, 38, 20, 3, 10);
                }
              }
            }

            tbody {
              tr {
                td {
                  font-family: $font-family;
                  font-size: $text-default-size;
                  font-weight: map-get($font-weights, regular);
                  text-align: left;
                  color: rgba(40, 46, 61, 0.8);
                  @include padding(left, top, right, bottom, 0, 2, 3, 2);

                  &:first-child {
                    @include padding(left, top, right, bottom, 38, 2, 3, 2);
                  }
                }
              }

              &:nth-child(odd) {
                @include background-color(#fafafa);
              }
            }
          }
        }
      }
    }
  }
}


.cls-accessright-accordion {
  &.mat-accordion {
    .mat-expansion-panel {
      border-radius: 0;

      .mat-expansion-panel-header {
        &.mat-expanded {
          height: 48px;
        }
      }
    }
  }
}

// expense table checkbox css

.cls-expense-table {
  mat-checkbox {
    &.mat-mdc-checkbox {
      display: inline-flex;
      font-family: inherit;
    }
  }
}

.list-header,
.comp-check,
.cls-checkinvst {
  mat-checkbox {
    &.mat-mdc-checkbox {
      display: inline-block;
      font-family: inherit;
    }
  }
}

.cls-leavetype,
.cls-chipsreport {
  mat-checkbox {
    &.mat-mdc-checkbox {
      display: inherit;
      font-family: inherit;
    }
  }
}

.cls-feildbox {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          .mat-icon {
            cursor: pointer;
            width: 20px;
            color: #00bfa5;
            font-size: 18px;
            height: fit-content;
          }
        }
      }
    }
  }
}

// inbox checkbox css

.inbox_left {
  .inbox-tab {
    .mat-mdc-checkbox {
      display: inline-block;
    }
  }
}

// assign holiday checkbox css
.cls-assign-holiday {
  .mat-mdc-checkbox {
    display: inline-block;
  }
}



// pagination css

.cls-pagination {
  &.mat-mdc-select {
    border: 1px solid #e9ecef;
    border-radius: 4px;
    min-height: 30px;
    width: 64px;
    padding: 4px 8px;
    font-size: 12px;
  }
}

// radio button css

.clstext-color {
  &.mat-mdc-radio-button {
    .mdc-form-field {
      label {
        color: #039be5;
      }
    }
  }
}

// investment declaration radio button css
.mat-mdc-radio-group {
  .cls-rbtn {
    &.mat-mdc-radio-button {
      .mdc-form-field {
        align-items: self-start;

        label {
          white-space: normal;
        }
      }
    }
  }
}

.cls-checkinvst {
  .cls-box {
    .mat-mdc-checkbox {
      .mdc-form-field {
        align-items: self-start;

        label {
          white-space: normal;
        }
      }
    }
  }
}

// for special case dropdowm select css

.cls-autolength {
  display: block;
  width: 64px;

  .cdk-overlay-pane {
    width: auto !important;
  }
}

// loan request page css accordion


#requestloan {
  &.mat-accordion {
    .mat-expansion-panel {
      border: 0;
      overflow: visible;
      background: transparent;

      .mat-expansion-panel-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px 30px 0 15px;
        border-radius: inherit;
        margin-top: 20px;
        margin-bottom: 20px;
        min-height: 48px;
        height: 100%;
        position: relative;
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          padding: 0px 0px 0px;
        }
      }
    }
  }
}


.readonlyDotted {
  &.mat-mdc-form-field {
    .mdc-text-field {
      .mdc-line-ripple {
        &:before {
          border-bottom-color: rgba(0, 0, 0, 0.4);
          border-bottom-style: dashed;
        }
      }
    }
  }
}

// goal tab css

.cls-goalkraboxtwotab {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              max-width: 50%;
              flex: 0 0 50%;
              opacity: 1;
            }
          }

        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        .cls-bodybox {
          @include padding-em(left, top, right, bottom, 2.142, 2.142, 2.142, 1.142);

          .cls-innerbox {
            @include margin-em(left, top, right, bottom, 0, 0, 0, 1.214);
          }
        }
      }
    }
  }
}

.cls-goalkrabox-threetab {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              max-width: 33%;
              flex: 0 0 33%;
              opacity: 1;
            }
          }

        }
      }
    }
  }

  .mat-mdc-tab-body-wrapper {
    .mat-mdc-tab-body {
      .mat-mdc-tab-body-content {
        .cls-bodybox {
          @include padding-em(left, top, right, bottom, 2.142, 2.142, 2.142, 1.142);

          .cls-innerbox {
            @include margin-em(left, top, right, bottom, 0, 0, 0, 1.214);
          }
        }
      }
    }
  }
}

/************ Goal dropdown csss *******************/
#cls-selectgoal {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-select {
            .mat-mdc-select-value {
              .mat-mdc-select-value-text {
                .mat-mdc-select-min-line {
                  font-family: $font-family;
                  font-size: $text-small-size;
                  font-weight: map-get($font-weights, "regular");
                  color: $utl-text-color-2;
                }
              }
            }
          }
        }
      }

      .mdc-line-ripple {
        &::after {
          border-bottom-color: transparent;
        }

        &::before {
          border-bottom-color: transparent;
        }
      }
    }
  }
}


// signature tab css
.cls-signtab {
  .mat-mdc-tab-header {


    .mat-mdc-tab-label-container {


      .mat-mdc-tab-list {

        .mat-mdc-tab-labels {

          .mdc-tab {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include margin(left, top, right, bottom, 32, 0, 0, 0);
            min-width: 100px;
          }
        }
      }
    }
  }


  .mat-mdc-tab-body-wrapper {
    @include padding(left, top, right, bottom, 32, 24, 32, 0);
  }
}

.feedback-panel {
  .mat-accordion {
    position: relative;
    display: block;

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        padding: 0;
      }
    }

  }
}

.singleline {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {

          display: flex;

        }
      }
    }
  }
}

.status {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mat-mdc-select {
            .mat-mdc-select-trigger {
              .mat-mdc-select-value {
                .mat-mdc-select-value-text {
                  font-size: 80%;
                }
              }
            }
          }

        }
      }
    }
  }
}

// analytics dashboard css
.cdk-overlay-pane {
  .mdc-menu-surface {
    .cls-dashboardmenu {
      &.mat-mdc-option {
        .mdc-list-item__primary-text {
          width: 100%;
        }
      }

      mat-pseudo-checkbox {
        display: none;
      }
    }
  }
}

.cls-reportbox {
  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      .mdc-form-field {
        display: flex;
        align-items: flex-start;

      }

      label {
        white-space: normal;
      }
    }
  }
}

// goal sheet checkbox css

.cls-goalsheet {
  .mat-mdc-checkbox {
    display: inherit;
  }
}


// card mat-menu 
.mat-mdc-menu-panel {
  .mat-mdc-menu-content {
    .inbox-menu {
      &.mat-mdc-menu-item {
        .mdc-list-item__primary-text {
          font-family: $font-family;
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, "regular");
          height: 35px;
          line-height: 35px;
        }
      }
    }
  }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #1b2436 !important;
}

.cls-filter {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {


      .mat-mdc-tab-label-container {


        .mat-mdc-tab-list {


          .mat-mdc-tab-labels {


            .mdc-tab {
              font-family: $font-family;
              font-size: $text-default-size;
              font-weight: map-get($font-weights, "regular");
              text-align: center;
              color: rgba(27, 36, 54, 0.7);
              flex-grow: 1;
              height: 58px;
              opacity: 1;


              &.mdc-tab--active {
                font-weight: map-get($font-weights, "bold");
                color: $secondary-text-color;
              }
            }
          }
        }
      }
    }
  }

}

.cls-payradio {
  &.mat-mdc-radio-group {

    .cls-label,
    .applicability-list {
      font-family: $font-family;
      font-size: $text-default-size;
      font-weight: map-get($font-weights, "regular");
      line-height: 1.43;
      max-width: 33.33%;
      width: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 4);

      .mat-mdc-radio-button {
        .mdc-form-field {
          margin-bottom: 0;
        }
      }
    }
  }

}

.mat-mdc-radio-group {
  .applicability-list {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.43;
    @include padding(left, top, right, bottom, 0, 0, 0, 22);

    .mat-mdc-radio-button {
      .mdc-form-field {
        margin-bottom: 0;
      }
    }
  }
}

// ou css

.vertical-radio-list {
  .mat-mdc-checkbox {
    display: block;
    margin-bottom: 8px;
  }
}

.text-wrap-compt {
  &.mat-mdc-checkbox {
    display: block
  }
}

//increment calculator css

.incrementcalcselbox {
  @include border(1px, solid, $utl-grey-color);
  @include padding(left, top, right, bottom, 15, 8, 15, 8);
  @include border-radius(2px);
  height: 40px;
  max-width: 160px;

  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      height: 35px;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          min-height: 35px;

          .mat-mdc-select {
            .mat-select-trigger {
              .mat-select-value {
                .mat-select-value-text {
                  span {
                    font-size: $text-default-size;
                    color: $utl-text-color-4;
                  }
                }
              }
            }
          }
        }
      }

      .mdc-line-ripple {
        &::after {
          border-color: transparent;
          border: 0;
        }

        &::before {
          border-color: transparent;
          bottom: 0;
          border: 0;
        }

      }
    }

  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

// dynamic-form css

#dynamic-form {
  .mat-mdc-checkbox {
    margin-bottom: 8px;

    .mdc-form-field {
      font-family: $font-family;
      font-size: initial;
      // align-items: inherit;

      .mdc-checkbox {
        margin-top: 2px;
      }

      label {
        white-space: normal;
        font-size: 12px;
      }
    }
  }

  .mat-mdc-radio-group {
    .mat-mdc-radio-button {
      .mdc-form-field {
        font-family: $font-family;
        font-size: initial;
        align-items: inherit;

        label {
          white-space: normal;
          font-size: 12px;
        }
      }
    }
  }
}

// ijp chip css

.cls-ijpchip {
  &.mat-mdc-chip {
    @include border-radius;
    @include background-color(#4e8daa !important);
    height: 20px;
    line-height: 20px;
    @include padding(left, top, right, bottom, 5, 0, 5, 0);

    .mdc-evolution-chip__cell {
      .mdc-evolution-chip__action {
        padding: 0;

        .mdc-evolution-chip__graphic {
          display: none;
        }

        .mdc-evolution-chip__text-label {
          font-family: $font-family;
          font-size: $text-small-size;
          font-weight: map-get($font-weights, "medium");
          text-align: center;
          color: $utl-white;

        }
      }
    }
  }

  &.mat-mdc-chip-selected {
    @extend .mat-mdc-chip;
  }
}


.mdc-evolution-chip__cell--trailing {
  margin-left: 3px;
}


// prequisites css

.cls-prequisites-acc {
  &.mat-accordion {
    .mat-expansion-panel {
      border-bottom: 0;

      .mat-expansion-panel-header {
        font-family: $font-family;
        height: 48px;
        border-radius: 0;
        border-top: 0;

        .mat-content {
          .mat-expansion-panel-header-title {
            .mat-mdc-checkbox {
              margin-top: -2px;
            }
          }
        }
      }
    }
  }
}

// Goal tab css

.cls-goattabmain {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mdc-tab {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

/************** pulse textarea css ****************/
.round-input {
  &.mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          @include border-radius;
          @include border(1px, solid, #c8cdd1);

          .mat-mdc-form-field-textarea-control {
            height: 105px;
            @include padding(left, top, right, bottom, 20, 5, 20, 5);
            resize: none;
            white-space: pre-line;
            margin: 0;

            &::-webkit-scrollbar {
              width: 8px;
            }

            &::-webkit-scrollbar-track {
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
              border-radius: 6px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 6px;
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
              cursor: pointer;
            }

            &::-webkit-scrollbar-thumb:hover {
              background: #e3e3e3;
            }
          }
        }
      }

      .mdc-line-ripple,
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }

  
}


.select-long-panel {
  &.mat-mdc-select-panel {
    max-height: calc(100vh - 50px) !important;
  }
}

// candidate page  css
.candidate-detail-wrapper {
  .candidate-filter-wrapper {
    .cadidate-canditure {
      height: 100%;

      .mat-mdc-tab-group {
        height: 100%;

        .mat-mdc-tab-header {
          .mat-mdc-tab-label-container {
            .mat-mdc-tab-list {
              .mat-mdc-tab-labels {
                padding: 0 22px;

                .mdc-tab {
                  flex-grow: 1;
                }
              }
            }
          }
        }

        .mat-tab-body-wrapper {
          height: 100%;
        }
      }
    }
  }
}

// accordion kpi css

.cls-kpiacc {
  &.mat-accordion {
    .mat-expansion-panel {
      // border-bottom: 1px solid #ccc;
      border-top: 0;
      margin: 0 10px;

      .mat-expansion-panel-header {
        border: 0;
        padding: 24px 8px 16px 0px;
        font-family: $font-family;
        font-size: inherit;
        font-weight: inherit;
      }

      .mat-expansion-panel-content {
        font-family: $font-family;
        font-size: inherit;
        font-weight: inherit;

        .mat-expansion-panel-body {
          padding: 0;

        }
      }
    }
  }
}

// badges AI css

.cls-footerbox {
  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 10px !important;
          padding-bottom: 0 !important;

          .mat-mdc-input-element {
            resize: none;
          }

          .mat-mdc-select {
            .mat-select-trigger {
              .mat-select-value {
                .mat-select-value-text {
                  span {
                    font-size: $text-default-size;
                    color: $utl-text-color-4;
                  }
                }
              }
            }
          }
        }
      }

      .mdc-line-ripple {
        &::before {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }

        &::after {
          border-bottom-width: 0;
          border-bottom-color: transparent;
        }
      }

      .mat-mdc-form-field-icon-suffix {
        align-self: center;
      }
    }
  }
}

// investment chips design

.cls-newchipbox {
  .mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-standard-chip {
        .mdc-evolution-chip__cell--primary {
          .mdc-evolution-chip__action {

            .mdc-evolution-chip__text-label,
            .mat-mdc-chip-action-label {
              display: flex;
              align-items: center;

              .mat-icon {
                height: auto;
                width: auto;
                font-size: 14px;
                margin-left: 4px;

              }
            }
          }
        }
      }
    }
  }
}

// hack tab css

.cls-hacktab {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
          .mat-mdc-tab-labels {
            .mat-mdc-tab {
              height: 40px;
            }
          }
        }
      }
    }

  }
}


// mat table row css

.mat-mdc-table {

  .mdc-data-table__header-row,
  .mdc-data-table__row {
    height: 40px !important;

    th,
    td {

      padding: 0.5rem 1rem !important;
    }
  }
}

.scim-container {
  .mat-mdc-table {

    th,
    td {
      font-family: $font-family !important;
    }
  }
}

.cls-search-reportbox {
  box-shadow: none !important;

}


// dashboard goal radio btn and select css 

.mat-goalradiobtn {
  .mat-mdc-radio-button {
    .mdc-form-field {
      margin-bottom: 0;

      .mdc-radio {
        height: 16px;
        width: 16px;

        .mat-mdc-radio-touch-target {
          height: 16px;
          width: 16px;
        }

        .mdc-radio__native-control {
          height: 16px;
          width: 16px;
        }

        .mdc-radio__background {
          height: 16px;
          width: 16px;

          .mdc-radio__inner-circle {
            border-width: 8px;
          }
        }
      }
    }
  }
}


.cls-teamgoalselect {
  display: block;
  width: 32%;

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          .mdc-floating-label {
            top: 24px;
          }

          .mat-mdc-select {
            @include background-color;
            padding: 0 5px 0 5px;
          }
        }
      }
    }
  }
}


// Role  card  css

.cls-cardbox {
  &.mat-mdc-card {
    padding: 16px;
    box-shadow: none;

    .mat-mdc-card-content {
      padding: 0;
    }
  }
}

.cls-errorbox {
  &.mat-mdc-card {
    padding: 16px;
    box-shadow: none;

    .mat-mdc-card-content {
      padding: 0;
    }
  }
}

// material table css

.cls-mattbl {
  &.mat-mdc-table {
    border: 1px solid #D1D1D1;
  }
}

.mat-mdc-menu-panel {
  &.download-file {
    min-width: 145px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    @include border-radius;
    outline: 0;
    @include padding(left, top, right, bottom, 0, 8, 0, 8);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 9;
    @include background-color;


    .mat-mdc-menu-content {

      div {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-weight: map-get($font-weights, 'medium');
        cursor: pointer;
        color: #272c3e;
        @include padding(left, top, right, bottom, 16, 0, 16, 0);
        white-space: nowrap;
        // @include border-pos(bottom, 1px, solid, #eaeaea);

        &:last-child {
          @include border-pos(bottom, 0px, solid, #eaeaea);
        }

        &:hover {
          @include background-color(rgba(0, 0, 0, .04));
        }
      }
    }
  }
}

/// IPL Panel css
.cls-crickpanel {
  .mat-expansion-panel-header {
    .mat-content {
      .mat-expansion-panel-header-title {
        margin-right: 0;
      }
    }
  }
}

// inside accordin css

.component-slider-wrapper {
  .mat-accordion {
    .mat-expansion-panel {
      border-bottom: 1px solid #D1D1D1;
      border-radius: 0;

      .mat-expansion-panel-header {
        font-family: $font-family;
        border-top: 0;
        border-bottom: 0 !important;
        padding: 0 8px 0 0;

        &.mat-expanded {
          height: 48px;
        }

        .mat-content {
          width: 100%;

          div {
            .mat-expansion-panel-header-title {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .mat-expansion-panel-header-description {
                justify-content: end;
              }
            }
          }
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          margin-top: 0px;
          padding: 0 0px 16px 0px;
        }
      }
    }
  }
}


// Role  card  css

.cls-cardbox {
  &.mat-mdc-card {
    padding: 16px;
    box-shadow: none;

    .mat-mdc-card-content {
      padding: 0;
    }
  }
}

.cls-errorbox {
  &.mat-mdc-card {
    padding: 16px;
    box-shadow: none;

    .mat-mdc-card-content {
      padding: 0;
    }
  }
}

// material table css

.cls-mattbl {
  &.mat-mdc-table {
    border: 1px solid #D1D1D1;
  }
}

.mat-mdc-menu-panel {
  &.download-file {
    min-width: 145px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(100vh - 48px);
    @include border-radius;
    outline: 0;
    @include padding(left, top, right, bottom, 0, 8, 0, 8);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, .2), 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12);
    position: absolute;
    right: 0;
    top: 20px;
    z-index: 9;
    @include background-color;


    .mat-mdc-menu-content {

      div {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-weight: map-get($font-weights, 'medium');
        cursor: pointer;
        color: #272c3e;
        @include padding(left, top, right, bottom, 16, 0, 16, 0);
        white-space: nowrap;
        // @include border-pos(bottom, 1px, solid, #eaeaea);

        &:last-child {
          @include border-pos(bottom, 0px, solid, #eaeaea);
        }

        &:hover {
          @include background-color(rgba(0, 0, 0, .04));
        }
      }
    }
  }
}

/// IPL Panel css
.cls-crickpanel {
  .mat-expansion-panel-header {
    .mat-content {
      .mat-expansion-panel-header-title {
        margin-right: 0;
      }
    }
  }
}

// inside accordin css

.component-slider-wrapper {
  .mat-accordion {
    .mat-expansion-panel {
      border-bottom: 1px solid #D1D1D1;
      border-radius: 0;

      .mat-expansion-panel-header {
        font-family: $font-family;
        border-top: 0;
        border-bottom: 0 !important;
        padding: 0 8px 0 0;

        &.mat-expanded {
          height: 48px;
        }

        .mat-content {
          width: 100%;

          div {
            .mat-expansion-panel-header-title {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .mat-expansion-panel-header-description {
                justify-content: end;
              }
            }
          }
        }
      }

      .mat-expansion-panel-content {
        .mat-expansion-panel-body {
          margin-top: 0px;
          padding: 0 0px 16px 0px;
        }
      }
    }
  }
}

// employee popup box css

.cls-gridbox {
  display: flex;

  .cls-grid {
    border: 1px solid #D1D1D1;
    border-radius: 4px;
    padding: 12px;
    position: relative;
    min-width: 144px;
    margin-right: 16px;

    .mat-mdc-radio-button {
      position: absolute;
      right: 0;
      top: 8px;
    }
  }

  .cls-cFirst {
    @extend .cls-grid;
  }
}

.cdk-overlay-pane {
  .cls-matmenubox {
    &.mat-mdc-menu-panel {
      max-width: 100%;
      background-color: #fff !important;

      .mat-mdc-menu-content {
        padding: 0;

        .cls-spacebox {
          padding: 8px;

          .cls-emobox {
            .mdc-list-item {
              height: auto;
              padding: 0 12px;
              line-height: normal;
              text-align: center;

              &:hover {
                background-color: transparent !important;
              }

              .mdc-list-item__primary-text {
                font-size: 24px;
                height: 30px;
                width: 30px;
                line-height: normal;
                overflow: visible;

                &:hover {
                  transform: scale(1.6);
                  transition: all 0.6s linear;
                  background-color: #f6f6f6;
                  border-radius: 4px;
                }
              }

              .mat-mdc-menu-ripple {
                display: none;

              }

              &.cdk-program-focused:not([disabled]) {
                background: none;
              }
            }
          }
        }
      }
    }
  }
}

// leave carry forward chips css

.cls-leavechips {
  &.mdc-evolution-chip-set {
    .mdc-evolution-chip-set__chips {
      .mat-mdc-standard-chip {

        padding: 0px 4px;
      }
    }
  }
}

// Event radio css

.cls-eventradio {
  .mat-mdc-radio-button {
    .mdc-form-field {
      label {
        cursor: pointer;
      }
    }
  }
}

// variable tab css

.cls-variabletab {
  &.mat-mdc-tab-group {
    .mat-mdc-tab-header {
      margin: 0 30px;
    }
  }
}

