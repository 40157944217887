@import "../../theme/main.scss";

// textarea{
//     resize: none;
// }
.engage-form{
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-form-field-underline{
        @include background-color($utl-grey-color-12 !important);
    }
    .autoCompleteOne{
        input{
            @include border-pos(bottom, 1px, solid, $utl-grey-color-12);
        }
    }
}


._hrone-form{
    ._hrone-form-group{
        mat-form-field{
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-wrapper{
                /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
                .mat-form-field-underline{
                    @include background-color($utl-grey-color-12 !important);
                }
            }
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-label{
                color: map-get($heading-colors, 'utl-grey-color-6') !important;
                font-size: $text-medium-size;
            }
            input,textarea,mat-select{
                font-size: $text-default-size;
                color: map-get($heading-colors, 'light-color');
                line-height: 20px;
            }
        }
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-can-float.mat-form-field-should-float{
            /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
            .mat-form-field-label{
                transform: translateY(-1.28125em) scale(1) perspective(0px) translateZ(.001px) !important;
            }
        }
    }
}

.border-search-box{
    position: relative;
    figure{
        @include abs-pos (10px, initial, initial, 12px); 
        width: 22px;
        img{
            width: 100%;
        }
    }
    .search-input{
        @include border(1px, solid, #e5e5e5);
        @include border-radius;
        @include padding(left, top, right, bottom, 45, 6, 20, 6);
    }
}
