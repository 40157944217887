@import "../../theme/main.scss";

// P-Tooltip global Styling======================
.candidate-page {
  max-width: 600px !important;
}

.extended-tooltip {
  max-width: 800px !important;
  margin-left: 30px !important;
}

.loan-tooltip {
  max-width: 230px !important;
  @extend .p-tooltip;
}

.sharebadge-tooltip {
  @extend .p-tooltip;
}

.sharebadge-tooltip.p-tooltip-top {
  left: 52% !important;
  bottom: 17% !important;
  top: auto !important;

}

.disable-tooltip {
  @extend .p-tooltip;
  font-size: 14px !important;

  &.p-tooltip-top {
    top: 40% !important;
    left: 0 !important;
    right: 0 !important;
    margin: auto !important;
    width: max-content !important;
  }
}

.canvas-tooltip.p-tooltip-left {
  top: 70% !important;
}

.p-tooltip {
  .p-tooltip-text {
    background: #1b2436;
    @include padding(left, top, right, bottom, 8, 8, 8, 8);
    font-size: 9px;
    letter-spacing: 0.4px;
    font-weight: 400;
  }
}

.p-tooltip.p-tooltip-right {
  .p-tooltip-arrow {
    // border-right-color: map-get($heading-colors, "dark-color");
    top: 45%;
    left: -5px;
    border-width: 7px 7px 7px 2px;
  }
}

.p-tooltip.p-tooltip-top {
  .p-tooltip-arrow {
    // border-top-color: map-get($heading-colors, "dark-color");
    border-width: 7px 7px 0px;
    margin-left: -7px;
    bottom: -2px;
  }
}

// P-Tooltip Individual Module Styling======================
// ********Sidebar Menu************
.sidePane {
  .p-tooltip-text {
    background: #586ac6;
    font-size: 11px;
  }
}

.candidatelist {
  .p-tooltip-text {
    background: #586ac6;
    font-size: 11px;

    &::before {
      background: #586ac6;
    }
  }
}

.sidePane.p-tooltip-right {
  .p-tooltip-arrow {
    // border-right-color: #586ac6;
    font-size: 11px;
  }
}

.sidePane.p-tooltip-top {
  .p-tooltip-arrow {
    // border-top-color: #586ac6;
    font-size: 11px;
  }
}

._globalSearchTooltip {
  .p-tooltip-text {
    background: $utl-theme-color;
    font-size: 11px;
  }
}

._globalSearchTooltip.p-tooltip-top {
  .p-tooltip-arrow {
    // border-top-color: $utl-theme-color;
  }
}

/*************** search tool tip css **************************/
.search-tooltip.p-tooltip.p-tooltip-top {
  color: #5a6574;

  .p-tooltip-arrow {
    // border-top-color: #5a6574;
  }

  .p-tooltip-text {
    background-color: #5a6574;
    border-radius: 2px;
    font-size: 9px;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

/********** tooltip css ****************/
.exceptional-tooltip {
  .p-tooltip-arrow {
    color: $utl-red-24;
    // border-right-color: #f46f19;
  }

  .p-tooltip-text {
    @include background-color($utl-red-24);
    @include border-radius(2px);
    font-size: $text-midum-small-size;
    @include padding(left, top, right, bottom, 8, 8, 8, 8);
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

.violation-tooltip {
  .p-tooltip-arrow {
    color: $utl-primary-red;
    // border-right-color: #fa4a5b;
  }

  .p-tooltip-text {
    @include background-color($utl-primary-red);
    @include border-radius(2px);
    font-size: $text-midum-small-size;
    @include padding(left, top, right, bottom, 8, 8, 8, 8);
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
}

// Performance tooltip
.cls-dif {
  .p-tooltip-text {
    @include border-radius(2px);
    font-size: $text-midum-small-size;
    @include padding(left, top, right, bottom, 8, 8, 8, 8);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    min-width: 300px;
    width: 100%;
    max-width: 550px;
  }
}

.teampagedet {
  @extend .projecttooltip;

  .p-tooltip-text {
    text-transform: none !important;
  }
}

.taskdesc {
  max-width: 560px !important;
  @extend .teampagedet;

  .p-tooltip-text {
    text-transform: none !important;
  }
}

.projecttooltip {
  word-break: keep-all;
  white-space: pre-line;
  max-width: 260px;

  .p-tooltip-text {
    @include border-radius(2px);
    @include padding(left, top, right, bottom, 10, 9, 10, 9);
    box-shadow: 0 3px 13px 0 rgba(148, 148, 148, 0.5);
    border: solid 1px $utl-grey-color-4 !important;
    background-color: $utl-white;
    color: $utl-text-color-20 !important;
    font-weight: 600;
    font-size: $text-small-size;
    text-transform: uppercase;
    word-break: keep-all;
    white-space: normal;

    &::before {
      background: transparent;
    }
  }

  .p-tooltip-arrow {
    // border-top-color: #fff !important;
    // font-size: 14px;
    // bottom:10;
    // top:auto;
    display: none;
  }
}

.taskname-view {
  max-width: 560px !important;
  @extend .projecttooltip;

  .p-tooltip-text {
    text-transform: none !important;
    font-size: $text-name !important;
  }
}

//custom tooltip
.transition-wrapper {
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    position: absolute;
    top: -6px;
    right: 2%;
  }

  @include padding(left, top, right, bottom, 18, 20, 18, 20);
  position: absolute;
  flex-direction: column;
  top: 42px;
  right: 0%;
  z-index: 999;
  width: 21vw;
  transform: translate(-62%, 0);
  @include border-radius(2px);

  .transition-info {
    letter-spacing: 0.16px;
  }

  .btn-group-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-success {
      letter-spacing: 0.16px;
      height: 34px;
      @include padding(left, top, right, bottom, 16, 8, 16, 8);
      @include border-radius(4px);
    }

    .btn-link {
      color: #78a9ff;
      cursor: pointer;
    }
  }
}


//custom tooltip for team
.cls-team-transition-wrapper {
  &:after {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #393939;
    content: "";
    position: absolute;
    top: -6px;
    left: 10%;
  }

  @include padding(left, top, right, bottom, 18, 20, 18, 20);
  position: absolute;
  flex-direction: column;
  top: 52px;
  right: -50%;
  background-color: #393939;
  z-index: 999;
  width: 21vw;
  transform: translate(-62%, 0);
  @include border-radius(2px);

  .transition-info {
    color: #fff;
    letter-spacing: 0.16px;
  }

  .btn-group-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-success {
      letter-spacing: 0.16px;
      height: 34px;
      @include padding(left, top, right, bottom, 16, 8, 16, 8);
      @include border-radius(4px);
    }

    .btn-link {
      color: #78a9ff;
      cursor: pointer;
    }
  }
}

.cus_tooltip {
  position: relative;

  .cls-pinitem {
    @include abs-pos(0px, 0, 0, 72px);
  }

  &:hover {
    .custom_tooltip {
      opacity: 1;
    }
  }

  .custom_tooltip {
    opacity: 0;
    width: 25vw;
    @extend .transition-wrapper;
    @include abs-pos(26px, 0, 6px, 17px);
    transform: none;
    @include padding(left, top, right, bottom, 17, 14, 17, 9);
    min-height: 74px;
    @include background-color(#1d2435);
    transition: ease-in-out all 0.1s;

    .transition-info {
      font-size: $text-medium-size;
    }

    &:after {
      @include abs-pos(-6px, 0, 0, 59px);
      border-bottom-color: #1d2435;
    }
  }

  .btn-success {
    @include padding(left, top, right, bottom, 8, 7, 8, 7);
    font-size: $text-size-11;
    @include background-color(#56bca6);
  }
}