@import "../../theme/main.scss";

.clswizleft {
  max-width: 228px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  @include padding(left, top, right, bottom, 20, 26, 6, 24);

}

// custom report sidebar

  aw-wizard {
    &.full-h-100 {
    aw-wizard-navigation-bar {

      &.vertical {
        height: 100vh;
      }
    }
  }
}

aw-wizard {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .wizard-steps {
    display: flex;
    min-width: calc(100% - 280px);
    width: 80%;
    height: 100%;
    flex-direction: column;

    aw-wizard-step,
    aw-wizard-completion-step {
      position: relative;
    }
  }

  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 92px);
      overflow: auto;
      max-width: 228px;
      width: 100%;
      font-size: inherit;

      &.small {
        ul {
          &.steps-indicator {
            @include padding(left, top, right, bottom, 33, 0, 5, 5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            list-style: none;
            @include margin(left, top, right, bottom, 0, 0, 0, 0);

            li {

              &:not(:last-child) {
                @include padding(left, top, right, bottom, 0, 0, 0, 17);
              }

              a {
                .label {
                  font-family: $font-family;
                  line-height: 42px;
                  font-size: $text-default-size;
                  font-weight: map-get($font-weights, 'semi-bold');
                  transition: 0.25s;
                  letter-spacing: -0.21px;
                  text-align: left;
                  text-transform: inherit;
                  @include padding(left, top, right, bottom, 28, 0, 0, 0);
                  text-overflow: ellipsis;
                  width: 100%;
                  overflow: hidden;
                  white-space: nowrap;
                  display: block;

                }

                .step-indicator {
                  top: 0;
                  left: -13px;
                  position: absolute;
                  width: 42px;
                  height: 42px;
                  text-align: center;
                  vertical-align: middle;
                  line-height: 42px;
                  transition: 0.25s;
                  border-radius: 100%;
                  font-weight: bold;
                  font-size: 14px;
                }
              }

              &:not(:last-child) {
                &:after {
                  display: none;
                }
              }

              &.current {
                .step-indicator {
                  line-height: 38px;
                }
              }

              &.navigable {
                &.done {
                  a {
                    &:hover {
                      .step-indicator {
                        @extend .step-indicator;
                        @include background-color($utl-theme-color);
                      }
                    }
                  }
                }

                &.optional {
                  a {
                    &:hover {
                      .step-indicator {
                        @extend .step-indicator;

                      }
                    }
                  }
                }
              }

              &.current {
                &.editing {
                  &.done {
                    .step-indicator {
                      @include background-color;
                      @include border(2px, solid, $utl-theme-color);
                      line-height: 38px;
                      color: $utl-text-color-21;
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
  }

  .wiz-bottom {
    .btn {
      height: 40px;
    }
  }
}

#iin-wiz {

  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 170px);

      &.small {
        ul {
          &.steps-indicator {
            @include padding(left, top, right, bottom, 33, 15, 5, 5);


          }
        }
      }
    }
  }
}

#iin-wiz-flyer {

  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 122px);

      &.small {
        ul {
          &.steps-indicator {
            @include padding(left, top, right, bottom, 33, 15, 5, 5);


          }
        }
      }
    }
  }
}

// announcement-wiz css

#announcement-wiz {
  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 157px);

    }
  }
}

// Expense wiz

#exp-wiz {
  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 153px);

    }
  }
}

// settlement wiz
#settlement-wiz {
  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 94px);

    }
  }
}

// location wiz
#locationwiz {
  aw-wizard-navigation-bar {

    &.vertical {
      height: calc(100vh - 103px);

    }
  }
}

//statury wiz css

.taxdeductionwiz {
  .wizard-steps {
    width: 100%;
  }
}


aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.optional .step-indicator {
  @include background-color(inherit);
}

// kyc wiz
.cls-kycwiz {
  .vertical {
    aw-wizard-navigation-bar {
      &.vertical {
        height: calc(100vh - 44px);
        // @include background-color;
        overflow: auto;
        max-width: 226px;
        width: 100%;
        font-size: inherit;

        &.small {
          &.small {
            ul {
              &.steps-indicator {
                @include padding(left, top, right, bottom, 31, 72, 5, 5);
                display: flex;
                flex-direction: column;
                justify-content: center;
                list-style: none;
                @include margin(left, top, right, bottom, 0, 0, 0, 0);


              }


            }
          }
        }
      }
    }

    .wizard-steps {
      display: flex;
      // min-width: 621px;
      // width: 44%;
      // height: calc(100vh - 42px);
      flex-direction: column;

      @include padding(left, top, right, bottom, 54, 74, 90, 24);
      // box-shadow: 0 12px 12px 0 rgba(191, 191, 191, 0.15);
      @include background-color;

      aw-wizard-step,
      aw-wizard-completion-step {
        position: relative;
      }
    }
  }
}