@import "_dialogbox";
@import "_p-tooltip"; //P-Tooltip
@import "_p-Autocomplete"; //P-Dialog
@import "_p-carousel"; //P-Dialog
@import "_p-table"; //P-table
@import "_wizard";
@import "main";
@import "_calendar";
@import "_file-upload";
@import "./primeng/pSideBar";
@import "./primeng/pCalendar";


// P-Rating global Styling======================
.p-rating {
  .p-rating-icon {
    @include margin(left, top, right, bottom, 8, 0, 0, 0);
    font-size: 12px;
  }

}

.p-component {
  font-family: $font-family;
  font-size: inherit;
  font-weight: inherit;
}


// document table css

/************* custom table css ***********/
p-table {
  .p-datatable {
    &.customTable {

      table {
        thead {
          tr>th {
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            font-family: $font-family;
            @include padding(left, top, right, bottom, 15, 10, 15, 10);
            font-size: $text-medium-size;
            font-weight: map-get($font-weights, 'medium');
            text-align: left;
            @include border(0px, solid, transparent);
          }

        }

        tbody {
          tr>td {
            @extend th;
          }
        }
      }


    }


    p-paginator {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .p-paginator {

        @include border(0px, solid, transparent);
        @include margin(left, top, right, bottom, 0, 10, 0, 0);
        @include border-radius;

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {

          &:focus {
            box-shadow: none;
          }
        }

        .p-paginator-pages {
          .p-paginator-page {
            &:focus {
              box-shadow: none;
            }
          }
        }
      }
    }

  }
}

/************* custom table css ***********/
p-table {
  &.no-padding {
    .p-datatable-scrollable-wrapper {
      .p-datatable-scrollable-view {
        .p-datatable-scrollable-header-box {
          padding-right: 0 !important;


        }
      }
    }

    .p-datatable {
      thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          @include padding(left, top, right, bottom, 15, 10, 15, 10);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'medium');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;
          }
        }

      }


      tbody {
        tr>td {
          @extend th;
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;

            a {
              .pi {
                font-size: $text-size-11;
              }
            }
          }
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}

p-table {

  &.customTable {
    .p-datatable {
      thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          @include padding(left, top, right, bottom, 15, 10, 15, 10);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'medium');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;
          }
        }

      }

      tbody {
        tr>td {
          @extend th;
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;

            a {
              .pi {
                font-size: $text-size-11;
              }
            }
          }
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}

// dynamic rating css

.rating-dynamic {
  .p-rating {
    .p-rating-icon {
      @include margin(left, top, right, bottom, 3, 0, 0, 0);
      font-size: 18px;
      text-shadow: none;
      color: #222;

      &:focus {
        box-shadow: none;
      }
    }

    .p-rating-icon.pi-star {
      color: #222;
    }

    &:not(.p-disabled):not(.p-readonly) {
      .p-rating-icon {
        &:hover {
          color: #007ad9;
        }
      }
    }

  }

}

// initiative-inbox rating css
/************* start rating css *******************/

.cls-feedbackRating {
  @include margin(left, top, right, bottom, 0, 5, 0, 0);
  display: block;
  font-size: initial;

  .p-rating {
    span {
      @include margin(left, top, right, bottom, 12, 0, 0, 0);

      &:first-child {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
      }

      &.p-rating-icon {
        font-size: $heading-medium-font-size;

        &:focus {
          box-shadow: none;
        }

        &:hover {
          transform: scale(1.3);

        }

        .pi.pi-star-fill {
          font-size: $heading-medium-font-size;
        }

      }

      &.p-rating-icon.pi.pi-star {
        font-size: $heading-medium-font-size;
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.p-state-disabled {
  span {
    &.p-rating-icon.pi.pi-star-o {
      font-size: $heading-extra-font-size;
      color: #ced7db;
      transition: all 0.2s ease-in-out;
      cursor: initial;

      &::before {
        content: "\e937" !important;
        cursor: initial;
      }

      &:focus {
        box-shadow: none;
      }

      &:hover {
        font-size: $heading-extra-font-size;
        transition: all 0.2s ease-in-out;
        cursor: initial !important;
        transform: none;
      }
    }

    .p-rating-icon.pi.pi-star {
      font-size: $heading-extra-font-size;
      cursor: initial;

      &:focus {
        box-shadow: none;
      }
    }
  }
}


// .cls-feedbackRating{
//   .p-rating {
//     .p-rating-icon {
//       @include margin(left, top, right, bottom, 3, 0, 0, 0);
//       font-size: 18px;
//       text-shadow: none;
//       color: #222;
//       &:focus{
//         box-shadow: none;
//       }
//     }

//     .p-rating-icon.pi-star {
//       color: #222;
//     }

//     &:not(.p-disabled):not(.p-readonly) {
//       .p-rating-icon {
//         &:hover {
//           color: #007ad9;
//         }
//       }
//     }

//   }
// }

// switch input css


.clscolorchange {
  .p-inputswitch {
    width: 42px;
    height: 20px;

    &.p-inputswitch-checked {
      &:not(.ui-state-disabled) {
        &:hover {
          .p-inputswitch-slider {
            @include background-color(#95ece8);
          }
        }

      }
    }

    .p-inputswitch-slider {
      box-shadow: none;

      &:before {
        @include background-color;
        width: 15px;
        height: 15px;
        margin-top: -8px;
      }
    }

    &.p-focus {
      .p-inputswitch-slider {
        box-shadow: none;
      }
    }
  }
}

// canvas css

.canvascreatetop {

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-infix {
    border: 0px;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  span.mat-select-value-text>span {
    font-size: $text-default-size;
    font-weight: map-get($font-weights, 'semi-bold');
    color: #272c3e;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-underline {
    display: none;
  }

  /*TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version.*/
  .mat-select-arrow {
    border-bottom: 2px solid #000 !important;
    border-right: 2px solid #000 !important;
    padding: 2.4px;
    margin: 0px 0px 5px 0;
  }
}



//review tab css

.review-tab {
  .mat-mdc-tab-header {

    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {

      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {
        @include margin(left, top, right, bottom, 35, 0, 35, 0);

        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {

          /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            font-size: $text-default-size;
            font-weight: map-get($font-weights, 'semi-bold');

            /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            &.mat-tab-label-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// onduty bulk css

.onduty_filter {
  @include background-color;
  @include border-radius;
  // @include border(1px, solid, rgba(151, 151, 151, 0.5));
  @include margin(left, top, right, bottom, 16, 0, 16, 0);

  .mat-mdc-tab-header {

    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
    .mat-tab-label-container {
      @include margin(left, top, right, bottom, 16, 0, 16, 0);

      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-list {

        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
        .mat-tab-labels {

          /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
          .mat-tab-label {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            @include margin(left, top, right, bottom, 0, 0, 24, 0);
            height: 60px;

            /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
            &.mat-tab-label-active {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-body-wrapper {
    .mat-mdc-tab-body {

      /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
      .mat-tab-body-content {
        .ondutyTable {
          @include border-radius;
          @include border(1px, solid, rgba(151, 151, 151, 0.5));
          @include margin(left, top, right, bottom, 16, 25, 16, 0);


          .customTable {

            @include background-color;


            .p-datatable {

              table {

                font-size: initial;

                .p-datatable-thead {
                  tr {
                    th {
                      @include padding(left, top, right, bottom, 15, 15, 15, 15);

                      span {
                        white-space: nowrap;
                        font-size: $text-medium-size;
                        font-weight: map-get($font-weights, semi-bold);
                      }
                    }
                  }
                }

                .p-datatable-tbody {
                  tr {
                    td {
                      @include padding(left, top, right, bottom, 15, 15, 15, 15);

                      span {
                        font-size: $text-medium-size;
                        font-weight: map-get($font-weights, regular);
                        color: $utl-text-color-4;
                        line-height: 2;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        width: 100%;
                        overflow: hidden;
                        display: inline;

                      }

                      &:nth-child(2) {
                        span {
                          font-weight: map-get($font-weights, bold);
                        }
                      }

                      .cls-subtable {
                        @include border-radius;
                        @include border(1px, solid, rgba(151, 151, 151, 0.5));

                        .cls-subtbl {
                          @include background-color;


                          .p-datatable {

                            table {

                              font-size: initial;

                              .p-datatable-thead {
                                tr {
                                  th {
                                    @include background-color;

                                    @include border-pos(bottom, 1px, solid, #d0d0d0);
                                    @include padding(left, top, right, bottom, 15, 15, 15, 15);

                                    span {
                                      white-space: nowrap;
                                      font-size: $text-medium-size;
                                      font-weight: map-get($font-weights, semi-bold);
                                      color: $utl-text-color-4;
                                    }
                                  }
                                }
                              }

                              .p-datatable-tbody {
                                tr {
                                  td {

                                    @include border-pos(bottom, 1px, solid, #d0d0d0);
                                    @include padding(left, top, right, bottom, 15, 15, 15, 15);


                                    span {
                                      font-size: $text-medium-size;
                                      font-weight: map-get($font-weights, regular);
                                      color: $utl-text-color-4;
                                      line-height: 2;
                                      white-space: nowrap;
                                      text-overflow: ellipsis;
                                      width: 100%;
                                      overflow: hidden;
                                      display: inline;

                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    &:nth-child(odd) {
                      @include background-color(#fafafa);
                    }

                    &:nth-child(even) {
                      @include background-color;
                    }
                  }
                }
              }

            }
          }
        }
      }
    }
  }
}


//loan table css

/************* custom table css ***********/
p-table {

  &.loan-tbl {
    .p-datatable {
      thead {
        tr>th {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          font-family: $font-family;
          @include padding(left, top, right, bottom, 15, 10, 15, 10);
          @include background-color($utl-grey-color-14);
          font-size: $text-medium-size;
          font-weight: map-get($font-weights, 'semi-bold');
          text-align: left;
          color: $utl-text-color-4;
          @include border(0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;
          }
        }

      }

      tbody {
        tr>td {
          @extend th;
          @include border-pos(bottom, 1px, solid, $utl-grey-color-14);
          font-weight: map-get($font-weights, 'medium');
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);

          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: block;
            width: 100%;

            a {
              .pi {
                font-size: $text-size-11;
              }
            }
          }
        }

        tr {
          &:nth-child(even) {
            @include background-color(#fafafb);
          }
        }
      }
    }
  }
}

// bgv switch box css
.cls-switch {
  .p-inputswitch {
    width: 30px;
    height: 16.4px;

    &.p-inputswitch-checked {
      &:not(.ui-state-disabled) {
        &:hover {
          .p-inputswitch-slider {
            @include background-color(#95ece8);
          }
        }

        .p-inputswitch-slider {
          @include background-color(#00bfa5);

          &:before {
            @include background-color;
            left: -6px;
          }
        }
      }
    }

    .p-inputswitch-slider {
      @include background-color(#cccccc);
      box-shadow: none;

      &:before {
        @include background-color;
        width: 12px;
        height: 12px;
        margin-top: -6px;
      }
    }

    &.p-focus {
      .p-inputswitch-slider {
        box-shadow: none;
      }
    }
  }
}


// without sidebar heading  ****    inerview feedback- > candidate-details 
._flyer-header {
  &.header {
    width: 100%;
    @include padding(left, top, right, bottom, 32, 16, 32, 16);

    .heading {
      font-weight: map-get($font-weights, "bold");
      position: relative;

      &:after {
        content: "";
        @include abs-pos(initial, initial, -8px, 0);
        width: 34px;
        height: 2px;
        @include background-color($utl-text-color-12);
      }
    }

    ._f-h_cta {
      .clear-all {
        font-size: $text-small-size;
        color: #5a6888;

        img {
          width: 100%;
        }
      }
    }

    .btnclose {
      position: initial;
      box-shadow: none;

      i {
        font-size: $heading-extra-font-size;
        width: initial;
        height: initial;
      }

      em {
        font-size: $heading-extra-font-size;
        width: initial;
        height: initial;
      }
    }

    .notify-no {
      @include border-radius($radius: 100px);
      @include background-color($utl-red-14);
      color: $utl-white;
      min-width: 19px;
      height: 19px;
      font-size: $text-midum-small-size;
      padding: 0px 4px;
      font-weight: 500;
      line-height: 1.2;
    }
  }
}

// global search tab button css
.cls-multibutton {
  .p-button {

    height: 40px;
    width: 40px;
    color: #000;
    font-size: 18px;

    &:hover,
    &.active {
      color: $utl-white;

    }

    &:focus {
      box-shadow: none;
    }
  }
}

.cls-updatebox {
  .p-carousel {
    .p-carousel-content {

      width: 100% !important;

      .p-carousel-container {

        .p-carousel-prev,
        .p-carousel-next {
          display: none;
        }

        .p-carousel-items-content {
          width: 100% !important;
          margin: 0 !important;

          .p-carousel-items-container {



            .p-carousel-item,
            .p-carousel-item-active {
              width: 100%;
              flex: 1 0 100% !important;
              padding: 0;
            }
          }
        }
      }
    }
  }
}

.cls-sliderbox {
  .p-carousel {
    .p-carousel-content {

      width: 100% !important;

      .p-carousel-container {

        .p-carousel-prev,
        .p-carousel-next {
          display: block;
          position: absolute;
          top: 105%;
          background: transparent;

          &:hover {
            background: transparent;
          }
        }
        .p-carousel-prev{
          left: 70px;
          height: auto;
          transform: none;
        }
        .p-carousel-next{
          right: 60px;
          height: auto;
        }
        .p-carousel-items-content {
          width: 100% !important;
          margin: 0 !important;

          .p-carousel-items-container {



            .p-carousel-item,
            .p-carousel-item-active {
              width: 100%;
              flex: 1 0 100% !important;
              padding: 0;
            }
          }
        }

        .p-link {
          margin-top: 0;
          span {
            display: block;
            font-size: .8rem;
          }
          &:enabled{
            &:hover{
              color: #02563D;
            }
          }
          &:hover{
            background-color: transparent !important;
          }
        }
      }
    }

    .p-carousel-indicators {
      display: flex;
      overflow: hidden;
      padding: 8px 0px 0px;
      flex: 0 0 24px;
      width: 27%;
      max-width: 27%;
      margin: 0 auto;

      .p-carousel-indicator {

        .p-link {
          width: .5rem;
          border-radius: 100%;

        }

        &.p-highlight {
          .p-link {
            order: 4;
            width: 1rem;
            border-radius: 4px;
          }
        }
      }

      li:nth-child(n+5) {
        display: none;
      }
    }
  }
}

.no-border-autocomplete {
  .cls-errorbox {
    height: 0;
  }
}

// Event table css
p-table {
  &.cls-eventtable {
    .p-datatable {
      .p-datatable-wrapper {
        .p-datatable-table {
          border: 0 !important;
          table-layout: fixed;

          .p-datatable-thead {
            tr {
              th {
                background-color: transparent !important;
                background: none;
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-bottom: 1px solid #D1D1D1;
                font-size: 14px;
                font-weight: 400;
                color: #5d5d5d;
              }
            }
          }

          .p-datatable-tbody {
            tr {
              td {
                background-color: transparent !important;
                background: none;
                border-top: 0;
                border-right: 0;
                border-left: 0;
                border-bottom: 1px solid #D1D1D1 !important;
              }
            }
          }
        }
      }
    }
  }
}

.cls-newtbl {
  .p-datatable {
    .p-datatable-wrapper {
      table {
        table-layout: fixed;
      }
    }
  }
}
.cls-splitbtn
{
    &.widthextd
    {
        p-tieredmenu .p-tieredmenu-overlay 
        {
            min-width: calc(100% + 166px);
            transform-origin: right top !important;
            left: auto !important;
            right: 0 !important;
             margin-top: 2px;
           
        }
        }
        &.fullwidth
        {
          p-tieredmenu .p-tieredmenu-overlay 
          {
            min-width: calc(100% + 166px);
          }
        }
        &.halfwidth
        {
          p-tieredmenu .p-tieredmenu-overlay 
          {
            min-width: calc(100% + 56px);
          }
        }
 }
