// library components 


// no-border-autocomplete  --  for autocomplete input without bg and border (parent) 
// no-select-border  -- for select button with splitbutton (parent)


@each $name, $glyph in $theme-mapper {
    $current-theme : map-get($theme-mapper, $name);

    body {
        &.#{$name} {


            // caraousel indicators
            .p-carousel-indicators {
                .p-carousel-indicator {
                    &.p-highlight {
                        .p-link {
                            background-color: map-get($current-theme, theme-primary);
                        }
                    }

                    .p-link {
                        border: 1px solid map-get($current-theme, theme-primary);
                    }
                }
            }


            .p-sidebar {
                border: 1px solid map-get($current-theme, theme-borderOpaque);

                .p-sidebar-header {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    ._flyer-header {
                        .heading {
                            color: map-get($current-theme, theme-contentPrimary);

                            &:after {
                                background-color: map-get($current-theme, theme-contentPrimary);
                            }
                        }

                        .btnclose {
                            background: transparent;

                            .material-icons {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }

                .p-sidebar-content {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    ._flyer-header {
                        .heading {
                            color: map-get($current-theme, theme-contentPrimary);

                            &:after {
                                background-color: map-get($current-theme, theme-contentPrimary);
                            }
                        }

                        .btnclose {
                            background: transparent;

                            .material-icons {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    .flyer-footer {
                        background-color: map-get($current-theme, theme-backgroundPrimary);
                        border-top: 1px solid map-get($current-theme, theme-borderOpaque)
                    }
                }

                .p-sidebar-footer {
                    .flyer-footer {
                        border-top: 1px solid map-get($current-theme, theme-borderOpaque);
                        background-color: map-get($current-theme, theme-backgroundPrimary);
                    }

                }

                .flyer-footer {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    border-top: 1px solid map-get($current-theme, theme-borderOpaque);
                }

                &.content-bg-Secondary {
                    .p-sidebar-content {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                    }
                }
            }

            .p-sidebar {
                &.bg-gray {
                    .p-sidebar-content {
                        background-color: map-get($current-theme, theme-backgroundSecondary) !important;

                        .loan_inbox {
                            background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                        }
                    }
                }

                &.allGray {

                    .p-sidebar-content,
                    .p-sidebar-header {
                        background-color: map-get($current-theme, theme-backgroundSecondary) !important;
                    }
                }
            }

            .p-flyer-nobg {
                &.p-sidebar {
                    border: 1px solid map-get($current-theme, theme-borderOpaque);

                    .p-sidebar-header {
                        background-color: map-get($current-theme, theme-backgroundPrimary);

                    }

                    .p-sidebar-content {
                        background-color: transparent !important;


                    }

                }
            }

            // ptooltip
            .p-tooltip {
                &.p-tooltip-top {
                    .p-tooltip-arrow {
                        border-top-color: map-get($current-theme, theme-primaryAccent);
                    }
                }

                &.p-tooltip-right {
                    .p-tooltip-arrow {
                        border-right-color: map-get($current-theme, theme-primaryAccent);

                    }
                }

                &.p-tooltip-left {
                    .p-tooltip-arrow {
                        border-left-color: map-get($current-theme, theme-primaryAccent);

                    }
                }

                &.p-tooltip-bottom {
                    .p-tooltip-arrow {
                        border-bottom-color: map-get($current-theme, theme-primaryAccent);

                    }
                }

                .p-tooltip-text {
                    background-color: map-get($current-theme, theme-primaryAccent);
                    color: map-get($current-theme, theme-primary);
                    font-weight: 600;
                }
            }

            // sidebar tooltip css
            .oneai-container {
                position: relative;
                display: inline-block;

                .icon-wrapper {
                    img {
                        cursor: pointer;
                        transition: transform 0.3s ease;

                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .tooltip {
                    display: none;
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translateY(-50%);

                    border-radius: 4px;

                    padding: 10px;
                    z-index: 10;
                    white-space: nowrap;
                    transition: opacity 0.3s ease, visibility 0.3s ease;
                    opacity: 0;
                    visibility: hidden;
                    margin-left: 2px;






                }

                &:hover {
                    .tooltip {
                        display: block;
                        opacity: 1;
                        visibility: visible;
                        background-color: map-get($current-theme, theme-primaryAccent);
                        color: map-get($current-theme, theme-primary);
                        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

                        .tooltip-arrow {
                            &::before {
                                content: '';
                                position: absolute;
                                top: 50%;
                                left: -7px;
                                transform: translateY(-50%);
                                width: 0;
                                height: 0;
                                border-top: 7px solid transparent;
                                border-bottom: 7px solid transparent;
                                border-right: 7px solid map-get($current-theme, theme-primaryAccent);
                                /* Adjust arrow color */
                            }
                        }

                        .tooltip-content {

                            font-weight: 600;

                            .contentIcon {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }


            // radiio group
            .mat-mdc-radio-group {
                .mat-mdc-radio-button {
                    .mdc-form-field {
                        label {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }

                        .mdc-radio {
                            .mdc-radio__background {
                                .mdc-radio__outer-circle {
                                    border-color: map-get($current-theme, theme-borderOpaque) !important;
                                }
                            }
                        }
                    }

                    &.mat-mdc-radio-checked {
                        .mdc-form-field {
                            .mdc-radio {
                                .mdc-radio__background {

                                    .mdc-radio__outer-circle,
                                    .mdc-radio__inner-circle {
                                        border-color: map-get($current-theme, theme-primary) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // radio 
            .mat-mdc-radio-button {
                .mdc-form-field {
                    .mdc-radio {
                        .mdc-radio__background {

                            .mdc-radio__outer-circle,
                            .mdc-radio__inner-circle {
                                border-color: map-get($current-theme, theme-borderOpaque) !important;
                            }
                        }
                    }
                }

                &.mat-mdc-radio-checked {
                    .mdc-form-field {
                        .mdc-radio {
                            .mdc-radio__background {

                                .mdc-radio__outer-circle,
                                .mdc-radio__inner-circle {
                                    border-color: map-get($current-theme, theme-primary) !important;
                                }
                            }
                        }
                    }
                }
            }

            // checkbox

            .mat-mdc-checkbox {
                &.theme-color-inner {
                    .mdc-form-field {
                        .mdc-checkbox {
                            .mdc-checkbox__background {
                                border: 2px solid transparent !important;
                            }
                        }
                    }
                }

                &.mat-mdc-checkbox-checked {
                    .mdc-form-field {
                        .mdc-checkbox {
                            .mdc-checkbox__background {
                                border: 2px solid map-get($current-theme, theme-primary) !important;
                                background-color: map-get($current-theme, theme-primary) !important;
                            }
                        }

                        label {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }
                    }

                    &.mdc-checkbox--disabled {
                        .mdc-checkbox {
                            opacity: 0.5;

                            .mdc-checkbox__background {
                                border: 2px solid map-get($current-theme, theme-primary) !important;
                                background-color: map-get($current-theme, theme-primary) !important;
                            }
                        }
                    }
                }

                &.mdc-checkbox--disabled {
                    .mdc-checkbox {
                        opacity: 0.5;

                        .mdc-checkbox__background {
                            border: 2px solid map-get($current-theme, theme-contentDisabled) !important;
                            background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                        }
                    }
                }

                .mdc-form-field {
                    label {
                        color: map-get($current-theme, theme-contentPrimary) !important;
                        font-size: 14px !important;
                        font-weight: 400 !important;
                    }

                    .mdc-checkbox {

                        .mdc-checkbox__background {
                            border: 2px solid map-get($current-theme, theme-borderOpaque) !important;
                        }
                    }
                }
            }

            // p-autocomplete
            .p-autocomplete {

                .p-autocomplete-loader,
                .pi-spin {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            p-autocomplete {
                .p-autocomplete {

                    .p-autocomplete-loader,
                    .pi-spin {
                        color: map-get($current-theme, theme-primary) !important;
                    }

                    input {
                        // background: map-get($current-theme, theme-backgroundSecondary);
                        // border: 1px solid map-get($current-theme, theme-borderOpaque);

                        &.p-autocomplete-input {
                            color: map-get($current-theme, theme-contentPrimary);
                            border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                            &:focus {
                                border-bottom: 1px solid map-get($current-theme, theme-borderFocus);
                            }
                        }

                    }

                    p-overlay {
                        .p-overlay {
                            .p-autocomplete-panel {
                                // &::-webkit-scrollbar-track {
                                //     background-color: map-get($current-theme, theme-box-shadow);
                                // }

                                // &::-webkit-scrollbar {
                                //     background-color: map-get($current-theme, theme-box-shadow);
                                // }

                                // &::-webkit-scrollbar-thumb {
                                //     background-color: map-get($current-theme, theme-backgroundTertiary);
                                // }

                                background-color: map-get($current-theme, theme-backgroundPrimary);

                                .p-autocomplete-items {
                                    .p-autocomplete-item {
                                        color: map-get($current-theme, theme-contentPrimary);

                                        &:hover,
                                        &.p-highlight {
                                            background-color: map-get($current-theme, theme-backgroundTertiary);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // &.ng-dirty {
                //     .p-autocomplete {
                //         input {
                //             &.p-autocomplete-input {
                //                 border-bottom: 1px solid map-get($current-theme,theme-error);
                //             }
                //         }
                //     }
                // }
                +.cls-enterlabel,
                +.mandatory-label {
                    color: map-get($current-theme, theme-contentSecondary) !important;
                }
            }

            .validation-error {
                color: map-get($current-theme, theme-error);
            }

            .badge-form-wrapper {
                p-autocomplete {
                    .p-autocomplete {
                        input {
                            border-width: 0 0 1px 0;
                        }
                    }
                }
            }

            .no-border-autocomplete {
                p-autocomplete {
                    .p-autocomplete {
                        input {
                            background-color: transparent !important;
                            border-width: 0 0 1px 0 !important;
                        }
                    }
                }
            }

            .autoComplete-multiple,
            .cls-autocomplete,
            .no-border-autocomplete {
                p-autocomplete {
                    .p-autocomplete {
                        .p-autocomplete-multiple-container {
                            background: transparent !important;
                            border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                            .p-autocomplete-token {
                                background: transparent !important;
                                color: map-get($current-theme, theme-contentPrimary) !important;
                                display: flex;

                                .p-autocomplete-token-label,
                                .p-autocomplete-token-icon {
                                    background: map-get($current-theme, theme-backgroundSecondary) !important;
                                    color: map-get($current-theme, theme-contentPrimary) !important;
                                    height: 24px;
                                    padding: 2px 4px;
                                    line-height: 20px;
                                    margin: 0 !important;
                                }
                            }

                            .p-autocomplete-input-token {
                                input {
                                    background: transparent !important;
                                    border: none !important;
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }
            }

            // global multiple autocomplete
            .autoCompleteOne,
            .autoCompleteTwo {
                &.p-autocomplete {
                    .p-autocomplete-multiple-container {
                        background: transparent !important;
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 0.5rem;

                        .p-autocomplete-token {
                            background: map-get($current-theme, theme-backgroundSecondary) !important;
                            color: map-get($current-theme, theme-contentPrimary) !important;
                            display: inline-flex;
                            align-items: center;
                           



                            .p-autocomplete-token-label,
                            .p-autocomplete-token-icon {

                                color: map-get($current-theme, theme-contentPrimary) !important;
                              

                            }
                        }

                        .p-autocomplete-input-token {
                            input {
                                background: transparent !important;
                                border: none !important;
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    .p-autocomplete-multiple {
                        .p-autocomplete-multiple-container {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                            border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                            .p-autocomplete-token {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                            }
                        }
                    }
                }
            }

            .bottom-border-autocomplete {
                p-autocomplete {
                    .p-autocomplete {
                        .p-autocomplete-multiple-container {
                            background: transparent !important;
                            border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                            .p-autocomplete-token {
                               
                                color: map-get($current-theme, theme-contentPrimary) !important;
                                height: 24px;
                                padding: 2px 4px;
                                line-height: 20px;
                                color: map-get($current-theme, theme-contentPrimary) !important;
                                display: flex;
                               overflow: hidden;
                                flex: inherit;

                                .p-autocomplete-token-label {
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    width: 100%;
                                    margin-right: 10px;
                                }

                                .p-autocomplete-token-icon {

                                    margin: 0 !important;
                                }
                            }

                            .p-autocomplete-input-token {
                                input {
                                    background: transparent !important;
                                    border: none !important;
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }
                        }
                    }
                }
            }

            .noBorderAutoComplete {
                p-autocomplete {
                    .p-autocomplete {
                        input {
                            background: transparent !important;
                            border: none !important;
                        }
                    }

                    &.autoCompleteTwo {
                        .p-autocomplete-multiple-container {
                            .p-autocomplete-token {
                                background: map-get($current-theme, theme-backgroundTertiary);
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }

            }

            // textarea input select

            mat-form-field {
                .mat-mdc-text-field-wrapper {
                    .mat-mdc-form-field-flex {
                        .mat-mdc-form-field-infix {
                            .mat-mdc-floating-label {
                                mat-label {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }
                            }

                            .mat-mdc-input-element {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }

                        .mat-mdc-form-field-icon-suffix {
                            .mat-datepicker-toggle {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    .mdc-line-ripple {

                        &::after,
                        &::before {
                            border-bottom-color: map-get($current-theme, theme-borderOpaque);
                            border-bottom-width: 1px;
                        }

                        &.mdc-line-ripple--active {

                            &::after,
                            &::before {
                                border-bottom-color: map-get($current-theme, theme-borderFocus);
                            }
                        }
                    }
                }

                // error case
                &.mat-form-field-invalid {
                    .mat-mdc-text-field-wrapper {
                        .mdc-line-ripple {

                            &::after,
                            &::before {
                                border-bottom-color: map-get($current-theme, theme-error) !important;
                            }
                        }
                    }

                    .mat-mdc-form-field-subscript-wrapper {
                        .mat-mdc-form-field-error-wrapper {
                            .mat-mdc-form-field-error {
                                color: map-get($current-theme, theme-error) !important;

                                span {
                                    color: map-get($current-theme, theme-error) !important;
                                }
                            }
                        }
                    }

                }
            }

            .status-inner,
            .cls-fltdropdown,
            .no-select-border {
                mat-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mdc-line-ripple {

                            &::after,
                            &::before {
                                border: none !important;
                            }
                        }
                    }
                }
            }



            // mat-select dropdowm
            .mat-mdc-select-panel-above,
            .cdk-overlay-pane {
                .mat-mdc-select-panel {
                    background-color: map-get($current-theme, theme-backgroundSecondary) !important;

                    // &::-webkit-scrollbar-track {
                    //     background-color: map-get($current-theme, theme-box-shadow) !important;
                    // }

                    // &::-webkit-scrollbar {
                    //     background-color: map-get($current-theme, theme-box-shadow) !important;
                    // }

                    // &::-webkit-scrollbar-thumb {
                    //     background-color: map-get($current-theme, theme-backgroundTertiary) !important;
                    // }

                    .mat-mdc-option {
                        .mat-pseudo-checkbox {
                            color: map-get($current-theme, theme-borderOpaque) !important;
                        }

                        &.mdc-list-item--selected {
                            .mdc-list-item__primary-text {
                                color: map-get($current-theme, theme-contentPrimary) !important;
                            }

                            .mat-pseudo-checkbox {
                                color: map-get($current-theme, theme-borderOpaque) !important;
                                background-color: map-get($current-theme, theme-primary) !important;

                                &::after {
                                    color: map-get($current-theme, theme-white) !important;
                                }
                            }
                        }

                        &.contains-mat-select-search {
                            background-color: map-get($current-theme, theme-backgroundPrimary);

                            ngx-mat-select-search {
                                .mat-select-search-inner {
                                    background-color: map-get($current-theme, theme-backgroundPrimary);

                                    input {
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }

                                    .mat-select-search-clear {
                                        .mat-icon {
                                            color: map-get($current-theme, theme-contentPrimary);
                                        }
                                    }
                                }
                            }
                        }

                        .mdc-list-item__primary-text {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        &:hover {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }
                    }
                }
            }

            // mat-select
            mat-select {
                .mat-mdc-select-trigger {
                    .mat-mdc-select-value {
                        color: map-get($current-theme, theme-contentPrimary);

                        .mat-mdc-select-value-text {
                            .mat-mdc-select-min-line {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                        }
                    }

                    .mat-mdc-select-arrow-wrapper {
                        .mat-mdc-select-arrow {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }
                    }
                }
            }


            // wizard count
            aw-wizard {
                aw-wizard-navigation-bar {
                    .steps-indicator {
                        li {
                            .step-indicator {
                                background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                                border: 2px solid map-get($current-theme, theme-borderOpaque) !important;
                                color: map-get($current-theme, theme-contentPrimary) !important;
                            }

                            &.current {
                                .step-indicator {
                                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                                    border: 2px solid map-get($current-theme, theme-primary) !important;
                                    color: map-get($current-theme, theme-contentPrimary) !important;
                                }
                            }

                            &.done {
                                .step-indicator {
                                    background-color: map-get($current-theme, theme-primary) !important;
                                    border: 2px solid map-get($current-theme, theme-primary) !important;
                                    color: map-get($current-theme, theme-white) !important;
                                }
                            }

                        }


                    }


                }
            }

            // mat-tab
            .mat-mdc-tab-group {

                // ou filter expense advance 
                &.vertical-tab {
                    display: flex;
                    flex-direction: row !important;
                    min-height: calc(100vh - 184px);

                    .mat-mdc-tab-header {
                        min-width: 163px;
                        padding-top: 20px;
                        margin-right: 16px;
                        border-right: 1px solid map-get($current-theme, theme-borderOpaque);
                        border-bottom: 0 !important;

                        .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab {
                            justify-content: flex-start;
                            text-align: left;
                            padding: 0 5px !important;
                            border-bottom: 0 !important;
                            height: 36px;
                            margin-bottom: 5px;

                            &.mdc-tab--active {
                                border-bottom: 0 !important;
                                background-color: map-get($current-theme, theme-backgroundSecondary);
                            }
                        }
                    }

                    .mat-mdc-tab-labels {
                        display: flex;
                        flex-direction: column !important;
                    }
                }

                .mat-mdc-tab-header {
                    border-bottom: 0.5px solid map-get($current-theme, theme-borderOpaque) !important;

                    .mat-mdc-tab-label-container {
                        .mat-mdc-tab-list {
                            .mat-mdc-tab-labels {
                                .mdc-tab {
                                    border-bottom: 2px solid map-get($current-theme, theme-borderOpaque) !important;
                                    padding: 0 16px !important;

                                    .mdc-tab__content {
                                        .mdc-tab__text-label {
                                            color: map-get($current-theme, theme-contentTertiary) !important;
                                        }
                                    }

                                    .mdc-tab-indicator {
                                        .mdc-tab-indicator__content {
                                            border: none;
                                        }
                                    }

                                    &.mdc-tab--active {
                                        border-bottom: 2px solid map-get($current-theme, theme-borderFocus) !important;

                                        .mdc-tab__content {
                                            .mdc-tab__text-label {
                                                color: map-get($current-theme, theme-contentPrimary) !important;
                                                font-weight: 600 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            // mat-menu
            .mat-mdc-menu-panel {
                background-color: map-get($current-theme, theme-backgroundSecondary) !important;

                .mat-mdc-menu-item {
                    .mdc-list-item__primary-text {
                        color: map-get($current-theme, theme-contentPrimary);
                    }

                    &:hover {
                        background-color: map-get($current-theme, theme-backgroundTertiary) !important;

                        .mdc-list-item__primary-text {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    &.active {
                        background-color: map-get($current-theme, theme-backgroundTertiary) !important;


                        .mdc-list-item__primary-text {
                            color: map-get($current-theme, theme-contentPrimary);
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                        }
                    }
                }
            }


            // snackbar
            .mdc-snackbar {
                &.snackbar-error {
                    background-color: map-get($current-theme, theme-error) !important;
                    color: map-get($current-theme, theme-white) !important;
                }

                &.snackbar-success {
                    background-color: map-get($current-theme, theme-positive) !important;
                    color: map-get($current-theme, theme-white) !important;
                }

                // &.snackbar-validation {
                //     background-color: map-get($current-theme,theme-primary) !important;
                //     color: map-get($current-theme,theme-white) !important;
                // }
            }

            // p-selectbutton 
            p-selectbutton {
                .p-selectbutton {
                    border: 1px solid map-get($current-theme, theme-primary);

                    .p-button {
                        color: map-get($current-theme, theme-primary);

                        &:hover {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                        }

                        &:focus {
                            box-shadow: none !important;
                        }

                        &.p-highlight {
                            color: map-get($current-theme, theme-white);
                            border-color: map-get($current-theme, theme-primary);
                            background-color: map-get($current-theme, theme-primary);

                            &:hover {
                                color: map-get($current-theme, theme-primaryAccent) !important;
                                background-color: map-get($current-theme, theme-primary) !important;
                            }
                        }

                    }
                }
            }


            // p-fileupload
            p-fileupload {
                .p-fileupload {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;


                    // .p-fileupload-content {
                    //     border: 2px dashed map-get($current-theme, theme-borderOpaque) !important;
                    // }
                }
            }

            .post-update {
                .dropfile-msg {
                    background-color: map-get($current-theme, theme-backgroundTertiary) !important;

                    p {
                        color: map-get($current-theme, theme-contentPrimary) !important;

                        b {
                            color: map-get($current-theme, theme-primaryLink) !important;
                        }
                    }
                }
            }


            // mat-progress-bar
            mat-progress-bar {

                &.insight-prgress-bar,
                &.page-prgress-bar {
                    &.mat-mdc-progress-bar {
                        .mdc-linear-progress__buffer-bar {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }

                        .mdc-linear-progress__buffer-dots {
                            background-color: map-get($current-theme, theme-primary);
                        }

                        .mdc-linear-progress__primary-bar {
                            .mdc-linear-progress__bar-inner {
                                background-color: transparent !important;
                                border-color: transparent !important;
                            }
                        }

                        // .mdc-linear-progress__secondary-bar {
                        //     .mdc-linear-progress__bar-inner {
                        //         border-color: map-get($current-theme, theme-primary) !important;
                        //     }
                        // }
                    }
                }

                // .mdc-linear-progress__bar {
                //     &.mdc-linear-progress__primary-bar {
                //         .mdc-linear-progress__bar-inner {
                //             border-color: map-get($current-theme, theme-positive) !important;
                //         }
                //     }
                // }
            }

            // p-dialog 
            .p-dialog {
                @include box-shadow(0px, 0px, 1px, 1px map-get($current-theme, theme-menu-shadow));

                .p-dialog-header {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                    * {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }

                .p-dialog-content {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                    .cls-content {
                        .cls-contxt {
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }

                    .p-confirm-dialog-message {
                        color: map-get($current-theme, theme-contentPrimary);
                        font-size: 14px;
                    }
                }

                .p-dialog-footer {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                    border-top: 1px solid map-get($current-theme, theme-borderOpaque);

                    .p-button {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                        border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                        color: map-get($current-theme, theme-contentPrimary);

                        .p-button-icon {
                            display: none;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }

                    .p-confirm-dialog-reject {
                        background-color: transparent !important;
                        border: none !important;

                        &:hover {
                            background-color: map-get($current-theme, theme-backgroundSecondary) !important;
                        }
                    }

                    .p-confirm-dialog-accept {
                        background-color: map-get($current-theme, theme-primary) !important;
                        border: 1px solid map-get($current-theme, theme-primary) !important;
                        color: map-get($current-theme, theme-primaryButtonText);

                        &:hover {
                            background-color: map-get($current-theme, theme-primaryHover);
                        }

                    }
                }
            }


            // chips

            mat-chip-listbox {
                .mdc-evolution-chip-set__chips {
                    mat-chip-option {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                        border: 1px solid map-get($current-theme, theme-borderOpaque);

                        &.grey-bg-chip {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }

                        .mdc-evolution-chip__cell {
                            .mat-mdc-chip-remove {
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            .mat-mdc-chip-action {
                                .mdc-evolution-chip__text-label {
                                    color: map-get($current-theme, theme-contentPrimary);
                                    // font-size: 12px !important;
                                }
                            }
                        }

                    }
                }

                &.bigTxtChip {
                    .mdc-evolution-chip-set__chips {
                        .mat-mdc-chip-option {
                            .mdc-evolution-chip__cell {
                                .mdc-evolution-chip__action {
                                    .mdc-evolution-chip__text-label {
                                        font-size: 10px !important;
                                    }
                                }
                            }
                        }
                    }
                }

                // candidate list page new - tags chips styling
                &.candidatetag {
                    .mdc-evolution-chip-set__chips {

                        mat-chip-option,
                        mat-chip {
                            @extend .bgPrimaryAccent;
                            border: 1px solid transparent !important;
                            border-radius: 24px;
                            padding: 0px 8px;
                            min-height: 24px;
                        }

                        .mat-mdc-chip-option {
                            .mdc-evolution-chip__cell {
                                .mdc-evolution-chip__action {
                                    .mdc-evolution-chip__text-label {
                                        font-size: 10px !important;
                                        @extend .text-success;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .moodbotChips {
                .mdc-evolution-chip-set__chips {
                    mat-chip-option {
                        &.mdc-evolution-chip--selected {
                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                            color: map-get($current-theme, theme-contentPrimary) !important;

                            .mdc-evolution-chip__action {
                                &:focus {
                                    background-color: none;
                                }
                            }
                        }
                    }
                }
            }

            // inside chips css

            .cls-insidechips {
                &.mdc-evolution-chip-set {
                    .mdc-evolution-chip-set__chips {
                        mat-chip-option {
                            background-color: map-get($current-theme , theme-backgroundTertiary);

                            .mdc-evolution-chip__cell--primary {
                                width: 98%;
                            }
                        }
                    }
                }
            }

            .chipradius {
                &.mdc-evolution-chip-set {
                    .mdc-evolution-chip-set__chips {
                        max-width: 20%;
                        padding-right: 30px;

                        mat-chip-option {
                            border-radius: 4px;
                            background-color: map-get($current-theme , theme-backgroundPrimary);

                            .mdc-evolution-chip__cell--primary {
                                width: 98%;
                            }
                        }
                    }
                }
            }

            // material datepicker
            .mat-datepicker-content {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                .mat-calendar {
                    .mat-calendar-header {
                        .mat-calendar-controls {
                            .mat-calendar-period-button {
                                color: map-get($current-theme, theme-contentPrimary);

                                svg {
                                    fill: map-get($current-theme, theme-contentPrimary);
                                }
                            }

                            .mat-calendar-previous-button,
                            .mat-calendar-next-button {
                                &::after {
                                    color: map-get($current-theme, theme-contentPrimary);
                                }

                                &:disabled {
                                    &::after {
                                        color: map-get($current-theme, theme-contentDisabled);
                                    }
                                }
                            }
                        }
                    }

                    .mat-calendar-content {

                        mat-month-view,
                        mat-multi-year-view,
                        mat-year-view {
                            .mat-calendar-table {
                                .mat-calendar-table-header {
                                    tr {
                                        th {
                                            background-color: transparent !important;
                                            color: map-get($current-theme, theme-contentPrimary) !important;

                                            &.mat-calendar-table-header-divider {
                                                &::after {
                                                    background-color: map-get($current-theme, theme-backgroundPrimary);
                                                }
                                            }
                                        }
                                    }
                                }

                                .mat-calendar-body {

                                    tr {
                                        td {
                                            background-color: transparent !important;
                                            border: none !important;

                                            &.mat-calendar-body-label {
                                                // opacity: 0;
                                                color: map-get($current-theme, theme-contentPrimary);
                                            }

                                            // &.mat-calendar-body-label[colspan="7"],
                                            // &.mat-calendar-body-label[colspan="4"] {
                                            //     display: none;
                                            // }

                                            &.mat-calendar-body-cell-container {
                                                .mat-calendar-body-cell {
                                                    &.mat-calendar-body-disabled {
                                                        .mat-calendar-body-cell-content {
                                                            color: map-get($current-theme, theme-contentDisabled) !important;
                                                        }
                                                    }

                                                    .mat-calendar-body-cell-content {
                                                        color: map-get($current-theme, theme-contentPrimary) !important;

                                                        &.mat-calendar-body-selected {
                                                            background-color: map-get($current-theme, theme-primary) !important;
                                                            color: map-get($current-theme, theme-white) !important;

                                                            &:hover {
                                                                background-color: map-get($current-theme, theme-primary) !important;
                                                            }
                                                        }

                                                        &.mat-calendar-body-today {
                                                            border-color: map-get($current-theme, theme-borderOpaque) !important;
                                                        }

                                                        &:hover {
                                                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                                                        }
                                                    }

                                                }
                                            }
                                        }

                                        &:nth-child(even) {
                                            td {
                                                background-color: transparent !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }


            // p-inputswitch
            p-inputswitch {
                .p-inputswitch {
                    &.p-inputswitch-checked {
                        .p-inputswitch-slider {
                            background-color: map-get($current-theme, theme-primary) !important;
                        }
                    }

                    &.p-disabled {
                        .p-inputswitch-slider {
                            // background-color: map-get($current-theme, theme-backgroundDisabled) !important;

                            &::before {
                                background-color: map-get($current-theme, theme-contentDisabled) !important;
                            }
                        }
                    }

                    .p-inputswitch-slider {
                        background-color: map-get($current-theme, theme-backgroundTertiary) !important;
                    }
                }
            }

            // mat-expansion-panel

            mat-accordion {
                mat-expansion-panel {
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    mat-expansion-panel-header {
                        border-top: 1px solid map-get($current-theme, theme-borderOpaque);
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        .mat-expansion-panel-header-title {
                            color: map-get($current-theme, theme-contentPrimary) !important;
                        }

                        .mat-expansion-indicator {
                            margin-top: -6px;

                            &::after {
                                color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }

                    .mat-expansion-panel-content {
                        .mat-expansion-panel-body {

                            // hrdesk nested accordion
                            .cls-bb {
                                mat-accordion {
                                    &#cls-innermat-acc {
                                        .mat-expansion-panel {
                                            mat-expansion-panel-header {
                                                background-color: map-get($current-theme, theme-backgroundSecondary);

                                                .mat-content {
                                                    .mat-expansion-panel-header-title {
                                                        .cls-keyheading {
                                                            color: map-get($current-theme, theme-contentPrimary);
                                                        }

                                                    }
                                                }

                                                .mat-expansion-indicator {
                                                    &::after {
                                                        color: map-get($current-theme, theme-contentPrimary);
                                                    }
                                                }

                                                &:hover {
                                                    background-color: map-get($current-theme, theme-backgroundTertiary) !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &#cls-clockpanel {
                    mat-expansion-panel {
                        border-bottom: none !important;

                        mat-expansion-panel-header {
                            border: none !important;
                        }
                    }
                }

                &.no-border-accordion {
                    mat-expansion-panel {
                        mat-expansion-panel-header {
                            border-top: none;
                            border-bottom: none;
                        }
                    }
                }
            }

            // dispense loan flyer 

            #requestloan {
                .mat-expansion-panel {
                    .mat-expansion-panel-header {
                        border: 0 solid transparent;
                    }

                    .mat-expansion-indicator {
                        background-color: map-get($current-theme, theme-primary);

                        &:after {
                            color: map-get($current-theme, theme-white);
                        }
                    }

                    .mat-expanded {
                        .mat-expansion-indicator {
                            background-color: map-get($current-theme, theme-backgroundSecondary);


                            &:after {
                                color: map-get($current-theme, theme-white);
                            }
                        }
                    }
                }

            }

            // p-table
            p-table {
                .p-datatable {
                    .p-datatable-wrapper {
                        .p-datatable-table {
                            border: 1px solid map-get($current-theme, theme-borderOpaque);

                            .p-datatable-thead {
                                tr {
                                    th {
                                        background-color: map-get($current-theme, theme-backgroundSecondary);
                                        color: map-get($current-theme, theme-contentPrimary);
                                    }
                                }
                            }

                            .p-datatable-tbody {
                                tr {
                                    td {
                                        background-color: map-get($current-theme, theme-backgroundPrimary);
                                        color: map-get($current-theme, theme-contentPrimary);
                                        border-bottom: none;

                                        mat-form-field {
                                            .mat-mdc-text-field-wrapper {
                                                .mat-mdc-form-field-flex {
                                                    .mat-mdc-form-field-infix {
                                                        input {
                                                            background-color: map-get($current-theme, theme-primaryAccent);
                                                            color: map-get($current-theme, theme-contentPrimary);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    &:nth-child(even) {
                                        td {
                                            background-color: map-get($current-theme, theme-backgroundSecondary);
                                        }

                                    }

                                }
                            }
                        }
                    }
                }
            }

            p-paginator {
                .p-paginator {
                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                    button {
                        &.p-paginator-element {
                            border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
                            color: map-get($current-theme, theme-contentPrimary) !important;

                            &.p-highlight {
                                background-color: map-get($current-theme, theme-primary) !important;
                                border-color: map-get($current-theme, theme-primary) !important;
                                color: map-get($current-theme, theme-white) !important;
                            }
                        }
                    }
                }
            }

            // custom table 
            table,
            .cls-tasktbl {
                thead {
                    tr {
                        th {
                            background-color: map-get($current-theme, theme-backgroundSecondary);
                            color: map-get($current-theme, theme-contentPrimary);
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                            color: map-get($current-theme, theme-contentPrimary);
                            // border-width: 0px 1px 0px 1px !important;
                            // border-style: solid !important;
                            // border-color : map-get($current-theme, theme-borderOpaque) !important;

                            input {
                                background-color: map-get($current-theme, theme-primaryAccent);
                                color: map-get($current-theme, theme-contentPrimary);
                            }

                            mat-form-field {
                                .mat-mdc-text-field-wrapper {
                                    .mat-mdc-form-field-flex {
                                        .mat-mdc-form-field-infix {
                                            input {
                                                background-color: map-get($current-theme, theme-primaryAccent);
                                                color: map-get($current-theme, theme-contentPrimary);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(even) {
                            td {
                                background-color: map-get($current-theme, theme-backgroundSecondary);
                            }

                        }

                    }
                }
            }

            // p-calendar
            p-calendar {
                &.cls-team-calaender {
                    .p-calendar {
                        .p-datepicker {
                            left: -50px !important;

                            .p-datepicker-group-container {
                                .p-datepicker-group {
                                    .p-datepicker-header {
                                        color: map-get($current-theme, theme-contentPrimary) !important;
                                        font-size: 16px;
                                    }

                                    .p-datepicker-calendar-container {
                                        table {
                                            &.p-datepicker-calendar {

                                                tbody {
                                                    tr {
                                                        td {
                                                            span {
                                                                padding: 2px;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                            }
                        }
                    }
                }
            }

            p-calendar {

                // homescreen calendar
                &.cls-team-calaender,
                &.new-calender,
                &.att-calendar {
                    .p-calendar {
                        .p-datepicker {
                            .p-datepicker-group-container {
                                .p-datepicker-calendar-container {
                                    table {
                                        &.p-datepicker-calendar {
                                            thead {
                                                tr {
                                                    th {
                                                        background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                                                        span {
                                                            color: map-get($current-theme, theme-contentPrimary) !important;
                                                        }
                                                    }
                                                }
                                            }

                                            tbody {
                                                tr {
                                                    td {
                                                        border: none !important;
                                                        background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                                                        span {
                                                            color: map-get($current-theme, theme-contentPrimary) !important;
                                                        }

                                                        &.p-datepicker-today {
                                                            span {
                                                                .cls-normal-text {
                                                                    color: map-get($current-theme, theme-primary);
                                                                    background-color: transparent;
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &:nth-child(even) {
                                                        td {
                                                            border: none !important;
                                                            background-color: map-get($current-theme, theme-backgroundPrimary) !important;

                                                            span {
                                                                color: map-get($current-theme, theme-contentPrimary) !important;
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // on duty calander css


            .cls-cldbod {


                .cls-tattext {

                    .cls-to {

                        border: 1px solid map-get($current-theme, theme-primary);

                    }


                }
            }


            //p-rating 
            p-rating {
                .p-rating {
                    .p-rating-icon {
                        &.pi-star-fill {

                            &:hover,
                            &::before {
                                color: map-get($current-theme, theme-warning) !important;
                            }
                        }

                        &:hover,
                        &::before {
                            color: map-get($current-theme, theme-warning) !important;
                        }

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }
            }

            // mat-button-toggle-group 
            mat-button-toggle-group {
                border: 1px solid map-get($current-theme, theme-primary) !important;

                mat-button-toggle {
                    &.mat-button-toggle-checked {
                        .mat-button-toggle-label-content {
                            background-color: map-get($current-theme, theme-primary) !important;
                            color: map-get($current-theme, theme-white) !important;
                        }
                    }

                    .mat-button-toggle-label-content {
                        color: map-get($current-theme, theme-primary) !important;
                        background-color: transparent !important;
                    }
                }
            }

            // splitbutton
            p-splitbutton {
                &.outline {
                
                        .p-splitbutton-defaultbutton,
                        .p-splitbutton-menubutton,
                        .p-splitbutton {
                            background-color: transparent !important;
                            color: map-get($current-theme, theme-primary) !important;
                            
                            &:hover,
                            &:focus {
                                background-color: map-get($current-theme, theme-primaryHover) !important;
                                color: map-get($current-theme, theme-white) !important;
                                .p-button-icon {
                                    color: map-get($current-theme, theme-white) !important;
                                }
                            }
                        }
                
                        .p-splitbutton-menubutton {
                            border-left-color: transparent !important;
                
                            .p-button-icon {
                                font-size: 12px;
                            }
                        }
                
                    }
                .p-splitbutton-defaultbutton,
                .p-splitbutton-menubutton,
                .p-splitbutton {
                    background-color: map-get($current-theme, theme-primaryButton) !important;
                    color: map-get($current-theme, theme-primaryButtonText) !important;
                    border-color: map-get($current-theme, theme-primaryButton) !important;

                    &:hover,
                    &:focus {
                        background-color: map-get($current-theme, theme-primaryHover) !important;
                        color: map-get($current-theme, theme-primaryHoverText) !important;
                        border-color: map-get($current-theme, theme-primaryHover) !important;
                        box-shadow: none !important;
                    }
                }
            }

            // p-progressSpinner
            p-progressSpinner {
                .p-progress-spinner-circle {
                    stroke: map-get($current-theme, theme-primary) !important;

                }
            }

            // cus-attacher
            .cus-attacher {
                p-fileupload {
                    .p-fileupload {
                        .p-button {
                            &:focus {
                                box-shadow: none !important;
                            }
                        }

                        .p-button-label {
                            color: map-get($current-theme, theme-primary) !important;

                        }
                    }
                }
            }

            p-fileupload {
                &.document-uploader {
                    &.docUpload {
                        .p-fileupload-advanced {
                            .p-fileupload-buttonbar {
                                .p-fileupload-choose {
                                    .p-button-label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            mat-select {
                &.selectThemeMenu {
                    .mat-mdc-select-trigger {
                        .mat-mdc-select-value {
                            .mat-mdc-select-value-text {
                                .mat-mdc-select-min-line {
                                    color: map-get($current-theme, theme-primary) !important;
                                }
                            }
                        }

                        .mat-mdc-select-arrow-wrapper {
                            .mat-mdc-select-arrow {
                                svg {
                                    fill: map-get($current-theme, theme-primary) !important;
                                }
                            }
                        }
                    }
                }
            }

            .search-card-wrapper {
                .card {
                    .card-body {
                        ._user-snippet {
                            &:after {

                                background-color: map-get($current-theme , theme-primary);
                            }
                        }

                    }
                }

                .btn-bg-tertiary {
                    background-color: map-get($current-theme , theme-backgroundTertiary);
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .btn-bg-positive {
                    background-color: map-get($current-theme , theme-successBackground);
                    color: map-get($current-theme, theme-positive);
                }
            }

            //report box css

            .create-widget {

                .mat-mdc-card-title,
                .mat-card-title {
                    background-color: map-get($current-theme , theme-backgroundTertiary);
                    color: map-get($current-theme , theme-contentPrimary);

                }

            }

            //   interview schedule 
            .interviewer-box {
                mat-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mdc-line-ripple {
                            &::before {
                                border-bottom-color: map-get($current-theme , theme-borderOpaque) !important;
                            }
                        }
                    }
                }
            }

            // otp css


            .cls-otpgen {
                border-top: 8px solid map-get($current-theme , theme-primary);

                .reset-password {
                    .forgot-password {
                        a {
                            color: map-get($current-theme, theme-primary);
                        }
                    }

                    .clsfgt-title {

                        color: map-get($current-theme , theme-contentPrimary);

                    }

                }
            }

            .cls-otpform {

                border-top: 233px solid map-get($current-theme , theme-primary);

            }


            .cls-tblcustom {
                table-layout: fixed;
                margin-bottom: 0;

                thead {
                    tr {
                        th {
                            background-color: map-get($current-theme , theme-backgroundSecondary);
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }
                }

                tbody {
                    tr {
                        td {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }
                }
            }

            // new select box css
            .cls-newboxfield {
                &.mat-mdc-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mat-mdc-form-field-flex {
                            .mat-mdc-form-field-infix {
                                padding: 2px 8px !important;
                                min-height: auto;
                            }
                        }

                        .mdc-line-ripple {
                            &::after {
                                display: none;
                            }

                            &::before {
                                display: none;
                            }

                        }
                    }

                    .mat-mdc-form-field-subscript-wrapper {
                        display: none;
                    }
                }
            }

            // Request search mat inbox css

            .leavetyp {
                .cls-inboxdate {
                    &.mat-mdc-form-field {
                        .mat-mdc-text-field-wrapper {
                            .mat-mdc-form-field-flex {
                                .mat-mdc-form-field-infix {
                                    .mdc-floating-label {
                                        color: map-get($current-theme, theme-contentTertiary);
                                        font-weight: 500;
                                    }

                                    .mat-mdc-input-element {
                                        color: map-get($current-theme, theme-contentTertiary);
                                    }

                                    .mat-mdc-select {
                                        .mat-mdc-select-trigger {
                                            .mat-mdc-select-value {
                                                color: map-get($current-theme, theme-contentTertiary);
                                                font-weight: 500;
                                            }

                                            .mat-mdc-select-arrow-wrapper {
                                                .mat-mdc-select-arrow {
                                                    color: map-get($current-theme, theme-contentTertiary);
                                                }
                                            }
                                        }
                                    }
                                }

                                .mat-mdc-form-field-icon-suffix {
                                    .mat-datepicker-toggle {
                                        color: map-get($current-theme, theme-contentTertiary);
                                    }
                                }
                            }

                            .mdc-line-ripple {
                                &::before {
                                    border-bottom-color: map-get($current-theme, theme-borderOpaque);
                                }

                                &::after {
                                    border-bottom-color: map-get($current-theme, theme-borderOpaque);
                                }

                            }
                        }
                    }
                }
            }

            // Goal setup hover css

            .cls-hoverbox {
                padding: 12px 12px 1px;

                &:hover {
                    background-color: map-get($current-theme, theme-backgroundSecondary);

                }
            }

        }
    }
}