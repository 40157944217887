@import "./pSideContent";

.scrollbar {
  @include scrollbars(8px, #999, #fff);
  overflow-y: auto;
  overflow-x: hidden;

  &.scroll-color-change {
    @include scrollbars(8px, #aaa, #eef1f4);
  }
}

// existing css
// Sidebar global Styling======================
.p-sidebar-close {
  display: none !important;
}

.p-sidebar-content {
  height: 100vh;
}

// update sidebar css
.p-sidebar {
  width: calc(100% - 60%);
  padding: 0;

  .p-sidebar-header {
    padding: 0;

    ._flyer-header {
      width: 100%;
      @include padding(left, top, right, bottom, 30, 16, 30, 14);

      .heading {
        font-weight: map-get($font-weights, "bold");
        position: relative;

        &:after {
          content: "";
          @include abs-pos(initial, initial, -8px, 0);
          width: 34px;
          height: 2px;
          @include background-color($utl-text-color-12);
        }
      }

      ._f-h_cta {
        .clear-all {
          font-size: $text-small-size;
          color: #5a6888;

          img {
            width: 100%;
          }
        }
      }

      .btnclose {
        position: initial;
        box-shadow: none;

        i {
          font-size: $heading-extra-font-size;
          width: initial;
          height: initial;
        }

        em {
          font-size: $heading-extra-font-size;
          width: initial;
          height: initial;
        }
      }

      .notify-no {
        @include border-radius($radius: 100px);
        @include background-color($utl-red-14);
        color: $utl-white;
        min-width: 19px;
        height: 19px;
        font-size: $text-midum-small-size;
        padding: 0px 4px;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  }

  .p-sidebar-content {
    @include padding(left, top, right, bottom, 30, 0, 30, 16);
    @include scrollbars(8px, #999, #fff);
  }

  .p-sidebar-footer {
    padding: 0;

    .flyer-footer {
      @include padding(left, top, right, bottom, 30, 16, 30, 16);
      @include shadow(5);
      @include background-color;
      width: 100%;
      z-index: 99;

      .flyer-footer-inner {
        button {
          flex: 0 0 47%;
          max-width: 47%;
          @include padding(left, top, right, bottom, 18, 7, 18, 7);
        }

        .btn-default {
          @include border(1px, solid, #e8e8e8 !important);
          @include background-color($color: #fbfbfb !important);
          height: 40px;
        }
      }

      button {
        height: 2.5rem;
      }
    }
  }

  &.wizard-sidebar {
    .p-sidebar-content {
      padding: 0;
      overflow: hidden;

    }

    // expense new policy
    &.expense {
      aw-wizard-step {
        position: static;
      }

      .footer-end {
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        background: #fff;
        z-index: 1101;
        box-shadow: 0 -1px 3px 0 rgba(0, 0, 0, 0.15);
        padding: 16px 32px;

        button {
          height: 2.5rem;
        }
      }
    }
  }

  &.isProfile {
    .p-sidebar-content {
      padding: 0;
    }
  }

  &.filter {
    .p-sidebar-header {
      ._flyer-header {
        padding: 0;
      }

      .btnclose {
        background: #fff;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        z-index: 9;
        height: 40px;
        width: 40px;
        right: 36px;
        top: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .p-sidebar-content {
      padding: 0;
    }
  }

  &.content-p0 {
    .p-sidebar-content {
      padding: 0;
    }
  }

  &.overflow-hidden {
    .p-sidebar-content {
      overflow: hidden;
    }
  }

  &.overflow-visible {
    .p-sidebar-content {
      overflow: visible;
    }
  }

  &.custom-flyer {

    .p-sidebar-header,
    .p-sidebar-footer {
      display: none;
    }

    .p-sidebar-content {
      padding: 0;

      ._flyer-header {
        width: 100%;
        @include padding(left, top, right, bottom, 30, 16, 30, 16);

        .heading {
          font-weight: map-get($font-weights, "bold");
          position: relative;

          &:after {
            content: "";
            @include abs-pos(initial, initial, -8px, 0);
            width: 34px;
            height: 2px;
            @include background-color($utl-text-color-12);
          }
        }

        ._f-h_cta {
          .clear-all {
            font-size: $text-small-size;
            color: #5a6888;

            img {
              width: 100%;
            }
          }
        }

        .btnclose {
          position: initial;
          box-shadow: none;

          i {
            font-size: $heading-extra-font-size;
            width: initial;
            height: initial;
          }

          em {
            font-size: $heading-extra-font-size;
            width: initial;
            height: initial;
          }
        }

        .notify-no {
          @include border-radius($radius: 100px);
          @include background-color($utl-red-14);
          color: $utl-white;
          min-width: 19px;
          height: 19px;
          font-size: $text-midum-small-size;
          padding: 0px 4px;
          font-weight: 500;
          line-height: 1.2;
        }
      }

      .flyer-footer {
        @include padding(left, top, right, bottom, 30, 16, 30, 16);
        @include shadow(5);
        width: 100%;
        z-index: 99;

        .flyer-footer-inner {
          button {
            flex: 0 0 47%;
            max-width: 47%;
            @include padding(left, top, right, bottom, 18, 7, 18, 7);
          }

          .btn-default {
            @include border(1px, solid, #e8e8e8 !important);
            @include background-color($color: #fbfbfb !important);
            height: 40px;
          }
        }

        button {
          height: 2.5rem;
        }
      }
    }

  }

  &.post-flyer {
    .flyer-footer {
      @include padding(left, top, right, bottom, 30, 16, 30, 16);
      @include shadow(5);
      @include background-color;
      width: 100%;
      z-index: 99;
      position: absolute;
      bottom: 0;
    }
  }

  &.cls-surveysidebr {

   

    .p-sidebar-content {
      padding: 0;
      overflow: initial;

      perfect-scrollbar {
        padding: 0 32px;
      }
    }
  }
  &.wizard-sidebar-visible {
    .p-sidebar-content {
      padding: 0;
      overflow: inherit;

    }
  }
}

// split button css

p-tieredmenu{
  &.p-element{
    .p-tieredmenu-overlay{
      width: 100%;
    }
  }
}