@import "../../theme/main.scss";


// Start new css for progress

.mat-mdc-progress-bar {
    &.mat-primary {

        border-radius: 4px;

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                background-color: #e6e6e6;
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-radius: 4px;
            }
        }

    }
}


.mat-mdc-progress-bar.mat-warm {


    border-radius: 4px;

    .mdc-linear-progress__buffer {
        .mdc-linear-progress__buffer-bar {
            background-color: #ffcdd2;
        }


    }

    .mdc-linear-progress__bar,
    .mdc-linear-progress__primary-bar {
        .mdc-linear-progress__bar-inner {
            border-top-width: 8px;
            border-color: #ff0c3e;
            border-radius: 4px;
        }
    }


}

.mat-mdc-progress-bar.mat-green {


    border-radius: 4px;
    height: 8px;

    .mdc-linear-progress__buffer {
        .mdc-linear-progress__buffer-bar {
            background-color: #DEF2EB;
        }


    }

    .mdc-linear-progress__bar,
    .mdc-linear-progress__primary-bar {
        .mdc-linear-progress__bar-inner {
            border-top-width: 8px;
            border-radius: 4px;
        }
    }


}

.mat-mdc-progress-bar {
    &.campaign-fund {
        height: 4px;
        border-radius: 4px;

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                background-color: #DEF2EB;
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-radius: 4px;
            }
        }
    }

}



.mat-mdc-progress-bar {

    border-radius: 4px;

    &.charm {
        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                background-color: #fff0ca;
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: #eeb934;
                border-radius: 4px;
            }
        }
    }
}

.progress-bar-line {
    .mat-mdc-progress-bar {
        height: 8px;
    }
}

#KRA-bar {
    .p-progressbar {
        @include border-radius;
        @include background-color(rgba(238, 185, 52, 0.25));
        @include border(0, solid, transparent);

        .p-progressbar-value {
            @include border-radius;
            // @include background-color(#eeb934);
        }

        .p-progressbar-label {
            display: none !important;
        }
    }
}

//End  new css for progress
// profile Bar============
.mat-mdc-progress-bar {
    &.light-blue {

        border-radius: 4px;

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                @include background-color(rgb(161, 200, 234));
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: $utl-blue-2;
                border-radius: 4px;
            }
        }

    }
}




.mat-mdc-progress-bar {
    @include border-radius($radius: 4px);
    height: 8px;

    .mat-progress-bar-fill {
        &:after {
            @include border-radius($radius: 40px);
        }
    }
}

// profile Bar============


// company goal
.mat-mdc-progress-bar {
    &.blue {
        height: 4px;
        margin-top: 11px;

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                @include background-color(#e6e6e6);
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: $utl-theme-color;
                border-radius: 4px;
            }
        }

    }
}

// Give a badge Bar============
.mat-mdc-progress-bar {
    &._badge-progress {

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                @include background-color(#fff0ca);
            }


        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: $utl-badge-fill-color;
                border-radius: 4px;
            }
        }

    }
}

// progress-bar-with-label
.progress-bar-line {
    .label-wrapper {
        @include margin(left, top, right, bottom, 0, 0, 0, 5);
        font-family: $font-family;

        small,
        b {
            font-weight: map-get($font-weights, "medium");
            font-size: 10px;

        }

        small {
            color: map-get($heading-colors, 'utl-grey-color-6');

        }

        b {
            line-height: 19px;
            color: $utl-text-color-12;
        }
    }

    .mat-mdc-progress-bar {
        &.mat-warn {

            .mdc-linear-progress__buffer {
                .mdc-linear-progress__buffer-bar {
                    @include background-color($utl-secondary-red);
                }


            }

            .mdc-linear-progress__bar,
            .mdc-linear-progress__primary-bar {
                .mdc-linear-progress__bar-inner {
                    border-top-width: 8px;
                    border-color: $utl-secondary-red;
                    border-radius: 4px;
                }
            }

        }
    }
}

// start a campaignr============

section.main-header {
    .mat-mdc-progress-bar {
        &.campaign-fund {
            height: 4px;


            .mdc-linear-progress__buffer {
                .mdc-linear-progress__buffer-bar {
                    @include background-color(#DEF2EB);
                }


            }

            .mdc-linear-progress__bar,
            .mdc-linear-progress__primary-bar {
                .mdc-linear-progress__bar-inner {
                    border-top-width: 8px;
                    border-color: #6CE9A6;
                    border-radius: 4px;
                }
            }
        }
    }
}

.mat-mdc-progress-bar {
    &.campaign-fund {
        height: 8px;
        &.lessheight
        {
            height: 2px;
        }

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                @include background-color(#D9D9D9);
            }
        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: $progress-campaign;
                border-radius: 4px;
                left: 0;
                right: 0;
                margin: auto;
            }
        }
    }
}
// custom error progress
.mat-mdc-progress-bar {
    &.error-loader {
        height: 8px;
        &.lessheight
        {
            height: 2px;
        }

        .mdc-linear-progress__buffer {
            .mdc-linear-progress__buffer-bar {
                @include background-color(#D9D9D9);
            }
        }

        .mdc-linear-progress__bar,
        .mdc-linear-progress__primary-bar {
            .mdc-linear-progress__bar-inner {
                border-top-width: 8px;
                border-color: $utl-secondary-red;
                border-radius: 4px;
            }
        }
    }
}