$font-family: 'Public Sans', sans-serif;
// =================================Uneecops fonts===============
$size-14: 14px;
// Heading*********
$heading-extreme-lagre-font-size: 3.428em; //=========48px
$heading-extra-lagre-font-size: 2.857em; //=========40px
$heading-lagre-font: 2.571em; //=========36px
$heading-medium-font: 2.428em; //=========34px
$heading-extra-font-size: 2.285em; //=========32px
$heading-small-font-size-30: 2em; //=========30px
$heading-large-font-size: 1.714em; //=========24px
$heading-medium-font-size: 1.571em; //=========22px
$heading-s-medium-font-size: 1.428em; //=========20px
$heading-small-font-size: 1.285em; //=========18px
$heading-small-font-size-normal: 1.178em; //=========16.5px
$heading-extra-small-font-size: 1.142em; //=========16px
// Body-text**********
$text-default--normal: 1.092em; //===========15.3px
$text-default-size: 1em; //===========14px
$text-semimedium-size: 0.928em; //=========13px
$text-medium-size: 0.857em; //=========12px
$text-size-11: 0.785em; //=========11px
$text-small-size: 0.714em; //=========10px
$text-midum-small-size: 0.642em; //=========9px
$text-extra-small-size: 0.571em; //=========8px
// ===============================Uneecops colors===============
// Green shades**********
$utl-theme-color: #00bfa5;
$utl-green-2: #54c5b4;
$utl-green-3: #84a955;
$utl-green-4: #7ac943;
$utl-green-5: #12b995;
$utl-green-6: #b4ec51;
$utl-green-7: #429321;
$utl-green-8: #00e1d1;
$utl-green-9: #27cbcc;
$utl-green-10: #a7ffeb;
$utl-green-11: #388e3c;
$utl-green-12: #00c7b4;
$utl-green-13: #439421;
$utl-green-14: #54ab5d;
$utl-green-15: #1ebfa5;
$utl-green-16: #23ad8e;
$utl-green-17: #009688;
$utl-green-18:#039855;
// Black shades**********
$primary-text-color: #28314b;
$secondary-text-color: #1b2436;
$title-black-color: #1a2335;
$utl-text-color-1: #000000;
$utl-text-color-2: #282c3f;
$utl-text-color-3: #434a59;
$utl-text-color-4: #262e3e;
$utl-text-color-5: #333333;
$utl-text-color-6: #31394a;
$utl-text-color-7: #2d3547;
$utl-text-color-8: #4a4548;
$utl-text-color-9: #19171a;
$utl-text-color-10: #121730;
$utl-text-color-11: #4b546e;
$utl-text-color-12: #272c3e;
$utl-text-color-13: #27314a;
$utl-text-color-14: #38404e;
$utl-text-color-15: #686e81;
$utl-text-color-16: #e0e0e0;
$utl-text-color-17: #4c5260;
$utl-text-color-18: #cbcbcb;
$utl-text-color-19: #5f6572;
$utl-text-color-20: #2f3d4a;
$utl-text-color-21: #22283b;
$utl-text-color-22: #242d3e;
$utl-text-color-23: #424242;
// Grey shades**********
$utl-grey-color: #a1a4ac;
$utl-grey-color-1: #aeb8cb;
$utl-grey-color-2: #bebebe;
$utl-grey-color-3: #767676;
$utl-grey-color-4: #aeb2bc;
$utl-grey-color-5: #f5f7fa;
$utl-grey-color-6: #6f778b;
$utl-grey-color-7: #f5f5f5;
$utl-grey-color-8: #979797;
$utl-grey-color-9: #fafafa;
$utl-grey-color-10: #dddddd;
$utl-grey-color-11: #f8f8f9;
$utl-grey-color-12: #c8cdd1;
$utl-grey-color-13: #a5b1bd;
$utl-grey-color-14: #eceff1;
$utl-grey-color-15: #848892;
// $utl-grey-color-15: #d0d0d0;
$utl-grey-color-16: #dadce1;
$utl-grey-color-17: #f0f2f4;
$utl-grey-color-18: #d0d0d0;
$utl-grey-color-19: #eaf0f7;
$utl-grey-color-20: #97a4ba;
$utl-grey-color-21: #a3a7b2;
$utl-grey-color-22: #f1f1f1;
$utl-grey-color-23: #c6c7c8;
$utl-grey-color-24: #f2f2f2;
$utl-grey-color-25: #dee5ef;
$utl-grey-color-26: #e9edf2;
$utl-grey-color-27: #dfdfdf;
$utl-grey-color-28: #fbfbfb;
$utl-grey-color-29: #fff7f7;
$utl-grey-color-30: #f1effd;
$utl-grey-color-31: #f8f8f8;
$utl-grey-color-32: #ebebeb;
$utl-grey-color-33: #9e9e9e;
$utl-grey-color-34: #cdcdcd;
$utl-grey-color-35: #656c79;
$utl-grey-color-36: #e5e5e5;
$utl-grey-color-37: #bebfc5;
$utl-grey-color-38: #a2acb7;
$utl-grey-color-39: #afb3bb;
$utl-grey-color-40: #F9F9F9;
// Red shades**********
$utl-primary-red: #fa4a5b;
$utl-secondary-red: #ff0c3e;
$utl-red-1: #e40071;
$utl-red-2: #e40071;
$utl-red-3: #e53935;
$utl-red-4: #ed1c24;
$utl-red-5: #ff1d25;
$utl-red-6: #dd1c4b;
$utl-red-7: #b21d48;
$utl-red-8: #b55a5a;
$utl-red-9: #f0a899;
$utl-red-10: #f1b0a0;
$utl-red-11: #db2721;
$utl-red-12: #ff4133;
$utl-red-13: #e98e82;
$utl-red-14: #ff5252;
$utl-red-14: #ff5929;
$utl-red-15: #eb5f7c;
$utl-red-16: #c1272d;
$utl-red-17: #ef5350;
$utl-red-18: #ce0b24;
$utl-red-19: #821102;
$utl-red-20: #ff1700;
$utl-red-21: #e43e3e;
$utl-red-22: #e2827e;
$utl-red-23: #f6aad0;
$utl-red-24: #f46f19;
$utl-red-25: #fc3030;
$utl-red-26: #dc4444;
$utl-red-27: #f8d7d7;
$utl-red-28: #ee6c65;
$utl-red-29: #ce3929;
$utl-red-30: #c33535;
$utl-red-31: #F79009;
$utl-red-32: #D92D20;
// Blue shades**********
$utl-blue-1: #5b69c3;
$utl-blue-2: #1e88e5;
$utl-blue-3: #93cae2;
$utl-blue-4: #e4ebed;
$utl-blue-5: #06aae9;
$utl-blue-6: #d1e3ee;
$utl-blue-7: #a5d2f0;
$utl-blue-8: #48a3e0;
$utl-blue-9: #2f3676;
$utl-blue-10: #191847;
$utl-blue-11: #4ac2e2;
$utl-blue-12: #094184;
$utl-blue-13: #28314f80;
$utl-blue-14: #007afc;
$utl-blue-15: #039be5;
$utl-blue-16: #0a4486;
$utl-blue-17: #3491f8;
$utl-blue-18: #586bc6;
$utl-blue-19: #586ac6;
// Yellow shades**********
$utl-yellow-1: #ffc108;
$utl-yellow-2: #f5a623;
$utl-yellow-3: #fbcc34;
$utl-yellow-4: #ffbf41;
$utl-yellow-5: #ffca00;
$utl-yellow-6: #ffac33;
$utl-yellow-7: #deb20a;
$utl-yellow-8: #d2a077;
$utl-yellow-9: #f3d2a2;
$utl-yellow-10: #fff8e8;
$utl-yellow-11: #feffe9;
$utl-yellow-12: #f9b84a;
$utl-yellow-13: #ffc107;
$utl-badge-fill-color: #eeb934;
// purpel*********
$utl-purpel-default: #854cc5;
$utl-purpel-1: #5c6bc0;
// White**********
$utl-white: #ffffff;
$ripple-color:#20b6d7;
$rednew:#e95f5f;
$bluebeta:#5c6abf;
$newtabbg:#c73232;
// ===============================Box-sizing===============
$border-box: border-box;
$content-box: content-box;
$assetPath: "/assets/images";
// ============== loader-css ==============
$ph-bg: #fff !default;
$ph-color: #ececec !default;
$ph-border-radius: 2px !default;
$ph-gutter: 30px !default;
$ph-spacer: 15px !default;
$ph-avatar-border-radius: 50% !default;
$teampabelbg:#fcfcfc;
// ===============================Project management font values conversion into vw==============
//project cards
$heading-h2: 1rem; //=========16px
$text-name: 0.75rem; //=========12px
$taskdet:0.83rem; // =========13.28px
$badgetext: 0.6875rem; //=========11px
$tabs-text:0.875rem; //====== 14px
$heading-small-header: 1.125rem; //=========18px
$labeltxt:0.75rem; // ======== 12px
$colorlbl: 0.6875rem; //=========11px
 $text-small-size-new: 0.625rem; //=========10px
 $heading-20-size: 1.25rem; //=========20px
// variables for timesheet page
$selecthovercolor:#f3f3f3;
$iconcolor:rgba(39, 44, 62, 0.8);
$hovercolor:#d96533;
$calhighlight:#ff8533;
//nps color variables
$ratinggreen: #00bf4c;
$ratingred: #d14b4b;
$subtaskbg:#e4e4e4;
$bordersub:#f4f4f4;
//career portal green color
$careerpreviewgreen:#56bda6;
// bgv variables
$bgvgreycolortext:#4D546C;
$commonbgcolor:#F4F6F9;
// designation page - setup - org
$headtext:#292C3E;
$headtextlight:#707789;
//new social wall variables
$lightgreenbg:#caf8f1;
$greentext:#00937d;
$lightpurple:#d9defb;
$lightblue:#c7ebfd;
$darkcolor-celebration:#161616;
$seemorebtn:#0671e0;
$responsiveheadingtexts:clamp($text-default-size,2vw,1rem);
$nametitle:1.75rem;
$subtext:#525252;
$subtext-new:#747474;
$border-right-yellow:#FFDC78;
$border-green-box:#12C4AC;
$progress-campaign:#42BE65;
$outline-green-btn:#009C86;
$light-purple-bg:#F5E1FF;
$darkpurpletxt:#8C5AA0;
$lightgreen-comment:#DEF2EB;
$purple-ijp:#EBEFFD;
$purple-ijp-txt:#1E4AE5;
$green-refer:#E9FCEE;
$green-refer-txt:#118973;
$blankstate-color:#A1A4AC;
$welcome-postbg:#FFF0BE;
$welcome-posttxt:#806E3C;
$bigfont28:1.75rem;
$rewardbadgebg:#FFFAF0;
$rewardbadgebrdr:#806E3C;
$subcolor:#1C1B1F;
$secondarycolornew:#E96F59;
$primarycolornew:#41826E;
$text-dark-icon:#454545;
$primaryiconcolor:#E65F46;
$primaryiconbg:#FCEFEC;
$iconbg-left:#E1FAF6;
$iconcolor-left:#00553C;
$subtxt-acc:#A2A2A2;
$grey-icon-bg:#F6F6F6;
$green-new-dark:#02563D;
// new caleder design
$greencalenderbg:#A7E2BB;
$orangecalenderbg:#F24912;
$cardtextbottom:#5d5d5d;
//customisation color list
//-------------bg coloroptions//
$headerbgcolor1: #00bfa5;
$headerbgcolor2: #a84242;
$headerbgcolor3: #443e9a;
$headerbgcolor4: #6f93f0;
$headerbgcolor5: #dead4d;
$headerbgcolor6: #6c61b4;
$headerbgcolor7: #d26090;
$headerbgcolor8: #d28960;
$headerbgcolor9: #007abf;
$headerbgcolor10: #6eaf7d;
$headerbgcolor11: #08415c;
$headerbgcolor12: #b2675e;
$headerbgcolor13: #a63a50;
//-------------icon coloroptions//
$iconcolor1: #00553c;
$iconcolor2: #e48e3e;
$iconcolor3: #a84242;
$iconcolor4: #e74faa;
$iconcolor5: #e0664b;
$iconcolor6: #e65f46;
$iconcolor7: #3f95ca;
$iconcolor8: #e65f46;
$iconcolor9: #66101f;
$iconcolor10: #e43675;
$iconcolor11: #e93d3c;
// $iconcolor12: #e93d3c;
$iconcolor12: #644536;
$iconcolor13: #e1820e;
//-------------icon bgoptions//
$iconbg1: #e1faf6;
$iconbg2: #FFECDB;
$iconbg3: #FBE0DF;
$iconbg4: #FFECF7;
$iconbg5: #F9E7E3;
$iconbg6: #FCEFEC;
$iconbg7: #DAEEFA;
$iconbg8: #FADFDA;
$iconbg9: #FFD3D6;
$iconbg10: #FFD6F8;
$iconbg11: #FFD8D8;
$iconbg12: #FFEADF;
$iconbg13: #FFEEDA;

//-------------comment bgoptions//
// add custom background color //
 $custombgcolor: $headerbgcolor1 $headerbgcolor2 $headerbgcolor3 $headerbgcolor4 $headerbgcolor5 $headerbgcolor6 $headerbgcolor7 $headerbgcolor8 $headerbgcolor9 $headerbgcolor10 $headerbgcolor11 $headerbgcolor12 $headerbgcolor13;

$iconcolors: (
    #00bfa5: $iconcolor1,
    #a84242: $iconcolor2,
    #443e9a: $iconcolor3,
    #6f93f0: $iconcolor4,
    #dead4d: $iconcolor5,
    #6c61b4: $iconcolor6,
    #d26090: $iconcolor7,
    #d28960: $iconcolor8,
    #007abf: $iconcolor9,
    #6eaf7d: $iconcolor10,
    #08415c: $iconcolor11,
    #b2675e: $iconcolor12,
    #a63a50: $iconcolor13
);
$iconbgcolors: (
    #00bfa5: $iconbg1,
    #a84242: $iconbg2,
    #443e9a: $iconbg3,
    #6f93f0: $iconbg4,
    #dead4d: $iconbg5,
    #6c61b4: $iconbg6,
    #d26090: $iconbg7,
    #d28960: $iconbg8,
    #007abf: $iconbg9,
    #6eaf7d: $iconbg10,
    #08415c: $iconbg11,
    #b2675e: $iconbg12,
    #a63a50: $iconbg13
);