@each $name, $glyph in $theme-mapper {
    $current-theme : map-get($theme-mapper, $name);

    body {
        &.#{$name} {

            // backgroundClasses

            .bgPrimaryAccent {
                background-color: map-get($current-theme, theme-primaryAccent) !important;
            }

            .accent-border-color {
                border-color: rgba(map-get($current-theme, theme-positive), 0.15);
            }

            .bgSecondaryAccent {
                background-color: map-get($current-theme, theme-secondaryAccent) !important;
            }

            .backgroundTertiary {
                background-color: map-get($current-theme, theme-backgroundTertiary);
            }

            .bg-tertiary {
                background-color: map-get($current-theme, theme-contentTertiary);
            }

            .bg-infoBackground {
                background-color: map-get($current-theme, theme-infoBackground);
            }

            .bg-theme-info {
                background-color: map-get($current-theme, theme-info);
            }

            .bg-infoBackgroundhover {
                &:hover {
                    background-color: rgba(0, 0, 0, 0.11);
                }
            }

            .bg-accent-color {
                background-color: map-get($current-theme, theme-primaryAccent);
            }

            .bg-primary-color {
                background-color: map-get($current-theme, theme-backgroundPrimary);
            }

            .bg-violet-color {
                background-color: map-get($current-theme , theme-backgroundViolet);
            }

            .bg-Secondary {
                background-color: map-get($current-theme, theme-backgroundSecondary) !important;
            }

            .bg-Secondary-hover {
                &:hover {
                    background-color: map-get($current-theme, theme-backgroundSecondary);
                }
            }

            .bgThemePrimary {
                background-color: map-get($current-theme, theme-primary) !important;
            }

            .bgError {
                background-color: map-get($current-theme, theme-error);

            }

            .error-background {
                background-color: map-get($current-theme , theme-backgrounderror) !important;
            }

            .error-border-color {
                border-color: rgba(map-get($current-theme, theme-error), 0.15);
            }

            // note warning
            .warningnote {
                background-color: map-get($current-theme, theme-warningBackground) !important;
            }

            .warningnote-border-color {
                border-color: rgba(map-get($current-theme, theme-warning), 0.15);
            }

            // light green color
            .success-background {
                background-color: map-get($current-theme , theme-successBackground);
            }

            .bgPositive {
                background-color: map-get($current-theme, theme-positive) !important;
            }

            .bgWarning {
                background-color: map-get($current-theme, theme-warning);
            }

            .bgthemewarning {
                background-color: map-get($current-theme, theme-warningBackground);
            }

            // bg disabled state
            .bg-disabled {
                background-color: map-get($current-theme, theme-backgroundDisabled);
            }

            .color-disabled {
                color: map-get($current-theme, theme-backgroundDisabled);
            }

            .icon-accent {
                color: map-get($current-theme, theme-primaryAccent) !important;
            }

            .border-focus {
                border-color: map-get($current-theme, theme-primaryFocus);
            }

            // text color classes
            .hyperlink {
                color: map-get($current-theme, theme-primaryLink);

                &:hover {
                    color: map-get($current-theme, theme-primaryLinkHover);
                }
            }

            .Txt-orange {
                color: map-get($current-theme , theme-orange);
            }

            .whiteTxt {
                color: map-get($current-theme, theme-white);
            }

            .warningTxt {
                color: map-get($current-theme, theme-warning) !important;
            }

            .positiveTxt {
                color: map-get($current-theme, theme-positive);
            }

            .infotxt {
                color: map-get($current-theme, theme-info);
            }

            .errorTxt {
                color: map-get($current-theme, theme-error);

                &:hover {
                    color: map-get($current-theme, theme-error);
                }
            }

            .primaryTxt {
                color: map-get($current-theme, theme-primary) !important;

                &:hover {
                    color: map-get($current-theme, theme-primary);
                }
            }

            .secondaryTxt {
                color: map-get($current-theme, theme-secondary);
            }

            .content-secondary {
                color: map-get($current-theme, theme-contentSecondary);
            }

            .content-tertiary {
                color: map-get($current-theme, theme-contentTertiary);
            }

            .content-violet {
                color: map-get($current-theme , theme-violet);
            }

            .list-disc {
                list-style: disc;
                &.removefirst
                {
                    li:first-child
                    {
                        list-style-type: none;
                    }
                }
            }

            .contentPrimary,
            mat-select-trigger {
                color: map-get($current-theme, theme-contentPrimary);
            }

            .seemorebtn,
            .seedetail,
            .reorderLink {
                color: map-get($current-theme, theme-primaryLink) !important;

                &:hover {
                    color: map-get($current-theme, theme-primaryLinkHover) !important;
                }
            }

            .text-success {
                color: map-get($current-theme, theme-primary) !important;
            }

            .seemorebtn {
                color: map-get($current-theme, theme-primaryLink);
            }

            .hoverTxtPrimary {
                &:hover {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            .clsreq {
                @extend .warningTxt;
            }

            .cls-anchor {
                color: map-get($current-theme, theme-contentPrimary) !important;

                &:hover {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            .cls-contentOpaque {
                color: map-get($current-theme, theme-contentOpaque) !important;
            }

            .bg-contentOpaque {
                background-color: map-get($current-theme, theme-contentOpaque) !important;
            }

            // borderClasses
            .warningBorder {
                border: 1px solid map-get($current-theme, theme-warning);
            }

            .border-warningBorder-btm {
                border-bottom: 3px solid map-get($current-theme, theme-warning) !important;
            }

            .warningBorder-left {
                border-left: 1px solid map-get($current-theme, theme-warning);
            }

            .warningBorder-2left {
                border-left: 2px solid map-get($current-theme, theme-warning);
            }

            .warningBorder-3left {
                border-left: 3px solid map-get($current-theme, theme-warning);
            }

            .positiveBorder {
                border: 1px solid map-get($current-theme, theme-positive);
                &.imp
                {
                    border: 1px solid map-get($current-theme, theme-positive !important);
                }
            }

            .positiveBorder-left {
                border-left: 1px solid map-get($current-theme, theme-positive);
                &.border-3
                {
                    border-width: 3px;
                }
            }

            // greyborder
            .greyBorder {
                border: 1px solid map-get($current-theme, theme-backgroundSecondary);
            }

            .errorBorder {
                border: 1px solid map-get($current-theme, theme-error);

                &.borderwidth3 {
                    border-left: 3px solid map-get($current-theme, theme-error);
                }
               
            }

            .border-error-btm {
                border-bottom: 3px solid map-get($current-theme, theme-error) !important;
            }

            .primaryHoverBorder {
                &:hover {
                    border: 2px solid map-get($current-theme, theme-primary);
                }
            }

            .primaryHoverBorder-thin {
                &:hover {
                    border-color: map-get($current-theme, theme-primary);
                }
            }

            .primaryBorder {
                border: 1px solid map-get($current-theme, theme-primary);
            }

            .primaryBorder2 {
                border: 2px solid map-get($current-theme, theme-primary);
            }

            .primaryBorder_left {
                border-left: 1px solid map-get($current-theme, theme-primary);
            }

            .primaryBorder_top {
                border-top: 1px solid map-get($current-theme, theme-primary);
            }

            .primaryBorder_right {
                border-right: 1px solid map-get($current-theme, theme-primary);
            }

            .secondaryBorder_right4 {
                border-right: 4px solid map-get($current-theme, theme-secondary) !important;
            }

            .secondaryBorder_bottom {
                border-bottom: 2px solid map-get($current-theme, theme-secondary);
            }

            .secondaryBorder {
                border: 1px solid map-get($current-theme, theme-secondary);
            }

            .secondaryBordertop {
                border-top: 1px solid map-get($current-theme, theme-secondary);
            }

            .primaryBorder_bottom {
                border-bottom: 1px solid map-get($current-theme, theme-primary);

                &.border-width3 {
                    border-bottom-width: 3px !important;
                }
            }

            .primaryBorder_left {
                border-left: 1px solid map-get($current-theme, theme-primary);


            }

            .border-op-hover-primary {
                border: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                &:hover {
                    border: 1px solid map-get($current-theme, theme-primary) !important;
                }
            }

            .icon-hover {
                &:hover {
                    i {
                        color: map-get($current-theme, theme-primary) !important;
                    }
                }
            }

            .border-opaque {
                border: 1px solid map-get($current-theme, theme-borderOpaque) !important;
            }

            .border-white-color {
                border-color: map-get($current-theme, theme-white);
            }

            .border-selected {
                border: 1px solid map-get($current-theme, theme-borderOpaque);

                &:hover {
                    border-color: map-get($current-theme, theme-contentPrimary !important);
                }
            }

            .border-info {
                border: 1px solid map-get($current-theme, theme-info) !important;
            }

            .border-info-left {
                border-left: 1px solid map-get($current-theme, theme-info) !important;

                &.border-3 {
                    border-left-width: 3px !important;
                }
            }

            .border-info-left-2 {
                border-left: 2px solid map-get($current-theme, theme-info) !important;
            }

            .border-info-left-3 {
                border-left: 3px solid map-get($current-theme, theme-info) !important;
            }

            .border-info-right {
                border-right: 1px solid map-get($current-theme, theme-info) !important;
            }

            .border-info-top {
                border-top: 1px solid map-get($current-theme, theme-info) !important;
            }

            .border-info-bottom {
                border-bottom: 1px solid map-get($current-theme, theme-info) !important;
            }

            .border-opaque_left {
                border-left: 1px solid map-get($current-theme, theme-borderOpaque);
            }

            .border-opaque_top {
                border-top: 1px solid map-get($current-theme, theme-borderOpaque);
            }

            .border-opaque_right {
                border-right: 1px solid map-get($current-theme, theme-borderOpaque);
            }

            .border-opaque_bottom {
                border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;
            }



            // button classes
            .btn-secondary {
                background-color: map-get($current-theme, theme-secondaryButton) !important;
                color: map-get($current-theme, theme-SecondaryButtonText);
                border-color: map-get($current-theme, theme-secondaryButton);

                &:hover {
                    background-color: map-get($current-theme, theme-secondaryHover) !important;
                    color: map-get($current-theme, theme-secondaryHoverText);
                    border-color: map-get($current-theme, theme-secondaryHover);
                }
            }

            // secondary btn text only
            .secondarybtn-txt {
                color: map-get($current-theme, theme-secondaryHover);
            }

            .btn-tertiarynew {
                background-color: map-get($current-theme, theme-contentTertiary);
                @extend .whiteTxt;
                border-color: map-get($current-theme, theme-borderOpaque);
            }

            .btn-success-on-hover {

                &:hover,
                &:focus {
                    background-color: map-get($current-theme, theme-primaryButton) !important;
                    color: map-get($current-theme, theme-primaryButtonText) !important;
                    border-color: map-get($current-theme, theme-primaryButton) !important;

                }
            }

            .btn-success {
                background-color: map-get($current-theme, theme-primaryButton) !important;
                color: map-get($current-theme, theme-primaryButtonText) !important;
                border-color: map-get($current-theme, theme-primaryButton) !important;

                &:hover,
                &:focus {
                    background-color: map-get($current-theme, theme-primaryHover) !important;
                    color: map-get($current-theme, theme-primaryHoverText) !important;
                    border-color: map-get($current-theme, theme-primaryHover) !important;
                }
            }

            .btn-outline-success {
                background-color: transparent;
                color: map-get($current-theme, theme-primary);
                border: 1px solid map-get($current-theme, theme-primary) !important;

                &.whitebgbtn {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                }

                &:hover {
                    background-color: map-get($current-theme, theme-primary);
                    color: map-get($current-theme, theme-white);
                    border: 1px solid map-get($current-theme, theme-primary) !important;
                }
            }


            .btn-outline-secondary {
                background-color: transparent !important;
                color: map-get($current-theme, theme-secondary) !important;
                border: 1px solid map-get($current-theme, theme-secondary) !important;

                &:hover {
                    background-color: map-get($current-theme, theme-secondary) !important;
                    color: map-get($current-theme, theme-white) !important;
                    border: 1px solid map-get($current-theme, theme-secondary) !important;
                }
            }

            .button-outline-white {
                background-color: transparent !important;
                color: map-get($current-theme, theme-white) !important;
                border: 1px solid map-get($current-theme, theme-white) !important;

                &:hover {
                    background-color: transparent !important;
                    color: map-get($current-theme, theme-white) !important;
                    border: 1px solid map-get($current-theme, theme-white) !important;
                }
            }

            .btn-warning {
                background-color: map-get($current-theme, theme-warning);
                color: map-get($current-theme, theme-white);
                border: 1px solid map-get($current-theme, theme-warning);
            }

            .btn-danger {
                border: 1px solid map-get($current-theme, theme-error);
                background-color: map-get($current-theme, theme-error);
                color: map-get($current-theme, theme-white);

                &:hover {
                    border: 1px solid map-get($current-theme, theme-error);
                    background-color: map-get($current-theme, theme-error);
                    color: map-get($current-theme, theme-white);
                }
            }

            .btn-outline-danger {
                background-color: transparent;
                color: map-get($current-theme, theme-error);
                border: 1px solid map-get($current-theme, theme-error);

                i {
                    color: map-get($current-theme, theme-error);
                }

                &:hover {
                    background-color: map-get($current-theme, theme-error);
                    color: map-get($current-theme, theme-white);
                    border: 1px solid map-get($current-theme, theme-error);

                    i {
                        color: map-get($current-theme, theme-white);
                    }
                }
            }


            // Icon classes
            .primaryIcon {
                color: map-get($current-theme, theme-primary) !important;
            }

            .contentIcon {
                color: map-get($current-theme, theme-contentPrimary) !important;
            }

            //blank state text color
            .blank-state-color {
                color: map-get($current-theme, theme-contentDisabled);

                &.blankhigh {
                    color: map-get($current-theme, theme-contentDisabled) !important;
                }
            }

            .errorIcon {
                color: map-get($current-theme, theme-error) !important;
            }



            // cxo-floatingbutton
            .cxo-floatingbtn {
                @extend .bgThemePrimary;
            }

            // datepicker css
            .cls-cldbod {
                .p-datepicker {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    border: none;

                    .p-datepicker-group-container {
                        .p-datepicker-group {
                            .p-datepicker-calendar-container {
                                .p-datepicker-calendar {
                                    tbody {
                                        tr {
                                            td {
                                                background-color: transparent;
                                                color: map-get($current-theme, theme-contentPrimary);
                                                border-bottom: 0 solid transparent;

                                                span {

                                                    &.p-highlight {
                                                        background-color: transparent;

                                                        .cls-normal-text {
                                                            background-color: map-get($current-theme, theme-primary);
                                                            border: 1px solid map-get($current-theme, theme-primary);
                                                            color: map-get($current-theme, theme-white);
                                                        }

                                                        .cls-full-absent {
                                                            background-color: map-get($current-theme, theme-primary);
                                                            color: map-get($current-theme, theme-white);
                                                        }
                                                    }

                                                    .cls-full-absent {
                                                        border: 1px solid map-get($current-theme, theme-error);
                                                    }

                                                    .cls-half-absent {
                                                        border: 1px solid map-get($current-theme, theme-info)
                                                    }

                                                    .cls-normal-text {
                                                        .normal-day {
                                                            border: 1px solid map-get($current-theme, theme-primary);
                                                        }
                                                    }

                                                    &:hover {
                                                        div {
                                                            background-color: map-get($current-theme, theme-primaryAccent);
                                                        }

                                                    }
                                                }

                                                &:hover {
                                                    span {
                                                        border-radius: 100%;
                                                    }
                                                }

                                                &.p-datepicker-today {
                                                    span {
                                                        .cls-normal-text {
                                                            color: map-get($current-theme, theme-primary);
                                                            background-color: transparent;
                                                        }
                                                    }
                                                }
                                            }

                                            &:nth-child(even) {
                                                td {
                                                    background-color: transparent !important;
                                                }

                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .p-datepicker {
                background-color: map-get($current-theme, theme-backgroundSecondary);
                border: 1px solid map-get($current-theme, theme-borderOpaque);

                .p-datepicker-group-container {
                    .p-datepicker-group {
                        .p-datepicker-calendar-container {
                            .p-datepicker-calendar {
                                tbody {
                                    tr {
                                        td {
                                            background-color: transparent;
                                            color: map-get($current-theme, theme-contentPrimary);
                                            border-bottom: 0 solid transparent;

                                            span {
                                                &.p-highlight {
                                                    background-color: map-get($current-theme, theme-primary);
                                                }
                                            }


                                        }

                                        &:nth-child(even) {
                                            td {
                                                background-color: transparent !important;
                                            }

                                        }

                                    }
                                }
                            }
                        }
                    }
                }

            }

            //  global search

            // global search

            #Global-action {
                span.autoCompleteTwo {
                    .p-autocomplete-multiple-container {
                        border: 1px solid map-get($current-theme, theme-borderOpaque);
                        background-color: map-get($current-theme, theme-backgroundTertiary);


                    }
                }
            }

            // blue-ribbon-card css

            ._blue-ribbon-card {

                border: 1px solid map-get($current-theme, theme-borderOpaque);

                ._blue-ribbon {
                    background-color: map-get($current-theme, theme-primary);
                    color: map-get($current-theme, theme-white);


                    .triangle-top-left {
                        border-color: transparent map-get($current-theme, theme-primary) transparent transparent;
                    }
                }

            }



            .time-office-data-list {

                .blue-card-header {
                    border-bottom: dotted 1px rgba(151, 151, 151, 0.37);


                }
            }

            // access right accordion

            .cls-accessright-accordion {
                &.mat-accordion {
                    .mat-expansion-panel {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        .mat-expansion-panel-header {
                            border-bottom: 0px solid transparent;
                            border-top: 0px solid transparent;

                        }
                    }
                }
            }

            // paygroup calc css
            .btn-tab {
                border: 1px solid map-get($current-theme, theme-primary);
                background: transparent;

            }

            .selected-btn-tab {
                background-color: map-get($current-theme, theme-primary);
                color: map-get($current-theme, whiteTxt);
            }

            .formula-block,
            .keypad-container,
            .clsgreenborder {
                border: 1px solid map-get($current-theme, theme-primary);


            }

            .clscolorbg {
                background-color: map-get($current-theme, theme-primary);
                border: 1px solid map-get($current-theme, theme-primary);
                color: map-get($current-theme , theme-white);
            }

            // settlement process card css
            .set-unlock {

                border: 1px solid map-get($current-theme, theme-primary);
                background-color: map-get($current-theme, theme-backgroundSecondary);

            }

            .check-icon {
                background-color: map-get($current-theme, theme-primary);
            }

            // salary structure table css
            /*************** P-table css *********************/
            .SalaryGrid {
                border: 1px solid rgba(151, 151, 151, 0.5);

                &.p-datatable {
                    .p-datatable-wrapper {
                        table {
                            thead {
                                tr {
                                    th {

                                        background-color: #f5f5f5;

                                        border: 0px solid transparent;

                                    }
                                }
                            }

                            tbody {
                                tr {

                                    td {

                                        background-color: #fff !important;

                                        border-bottom: 1px solid rgba(151, 151, 151, 0.5) !important;
                                        border-top: 0px solid transparent;
                                        border-left: 0px solid transparent;
                                        border-right: 0px solid transparent;

                                        .cls-salaryinput {
                                            height: 32px;
                                            border-left: 1px solid rgba(151, 151, 151, 0.5);
                                            border-top: 0px solid transparent;
                                            border-right: 0px solid transparent;
                                            border-bottom: 0px solid transparent;
                                            background-color: #fff;
                                            position: relative;

                                            &.ctcSpecialAllow {
                                                .mat-mdc-text-field-wrapper {
                                                    .mat-mdc-form-field-infix {
                                                        input {
                                                            color: rgba(0, 0, 0, .38);
                                                        }
                                                    }
                                                }
                                            }

                                            .mat-mdc-text-field-wrapper {
                                                border-radius: 0 !important;

                                                .mat-mdc-form-field-infix {
                                                    padding: 0 !important;
                                                    min-height: auto;

                                                    input {

                                                        background-color: #fff;

                                                        &.mat-mdc-input-element {

                                                            border-left: 0.5px solid rgba(151, 151, 151, 0.5);
                                                            background-color: #fff;
                                                            vertical-align: middle;
                                                            box-sizing: border-box;
                                                            border-top: 0 solid transparent;
                                                            border-right: 0 solid transparent;
                                                            border-bottom: 0 solid transparent;
                                                            height: 32px;

                                                            &:focus {
                                                                border-color: #00bfa5;
                                                            }

                                                            &:disabled {
                                                                color: rgba(0, 0, 0, .38);
                                                                background-color: #f8f8f9;
                                                            }
                                                        }

                                                        &.cls-fbpInput {
                                                            color: #fff;
                                                            background-color: #5cc0a5;
                                                        }
                                                    }
                                                }

                                                .mdc-line-ripple {
                                                    display: none;
                                                }
                                            }

                                            .mat-mdc-form-field-subscript-wrapper {
                                                position: absolute;
                                                bottom: 16px;
                                                left: 4px;
                                                margin-top: 0px;
                                                line-height: 20px;
                                                width: 90%;
                                                text-align: initial;

                                                &::before {
                                                    height: auto;
                                                }
                                            }

                                            &.mat-form-field-disabled {
                                                .mat-mdc-text-field-wrapper {
                                                    border-radius: 0;

                                                    .mat-mdc-form-field-flex {
                                                        .mat-mdc-form-field-infix {
                                                            padding: 0 !important;

                                                            input {
                                                                background-color: #f8f8f9;

                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }


                                    }


                                    &:nth-child(even) {
                                        td {

                                            background-color: transparent !important;

                                        }
                                    }

                                    &:last-child>td {
                                        border-bottom: 0px solid transparent !important;
                                    }
                                }

                                &tr:nth-child(even) {
                                    background-color: transparent !important;
                                }
                            }
                        }
                    }
                }


            }

            // salary ytd table css
            .SalaryYTDcustomTable {
                &.p-datatable {
                    .p-datatable-scrollable-wrapper {
                        .p-datatable-scrollable-view {
                            .p-datatable-scrollable-header {
                                padding-right: 17px;

                                .p-datatable-scrollable-header-box {
                                    table {
                                        thead {
                                            tr {
                                                th {

                                                    background-color: #4d5ebb !important;

                                                    border-bottom: 0 solid transparent;
                                                    border-top: 1px solid #e2e2e2;
                                                    border-left: 1px solid #e2e2e2;
                                                    border-right: 1px solid #e2e2e2;

                                                }
                                            }
                                        }

                                        .p-datatable-scrollable-body {
                                            tbody {
                                                tr {
                                                    td {

                                                        border-bottom: 1px solid #e2e2e2 !important;
                                                        border-top: 0 solid transparent;
                                                        border-left: 1px solid #e2e2e2;
                                                        border-right: 1px solid #e2e2e2;

                                                        &:nth-child(even) {
                                                            background-color: transparent !important;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cls-bottable {

                border: 1px solid rgba(151, 151, 151, 0.5) !important;

                thead {
                    tr {
                        th {

                            background-color: #f5f5f5 !important;

                            color: #262e3e !important;
                            border-top: 0 !important;
                            border-left: 0 !important;
                            border-right: 0 !important;
                            border-bottom: 0px solid rgba(151, 151, 151, 0.5) !important;
                        }

                    }
                }

                tbody {
                    tr {
                        td {
                            color: #262e3e;
                            border-bottom: 1px solid rgba(151, 151, 151, 0.5) !important;
                            border-top: 0 !important;
                            border-left: 0 !important;
                            border-right: 0 !important;
                            background-color: #fff !important;

                            &:nth-child(even) {
                                background-color: transparent !important;
                            }

                            .cls-salaryinput {
                                height: 32px;
                                border-left: 1px solid rgba(151, 151, 151, 0.5);
                                border-top: 0px solid transparent;
                                border-right: 0px solid transparent;
                                border-bottom: 0px solid transparent;
                                background-color: #fff;

                                &.mat-mdc-form-field {
                                    .mat-mdc-text-field-wrapper {
                                        border-radius: 0 !important;

                                        .mat-mdc-form-field-flex {
                                            .mat-mdc-form-field-infix {
                                                padding: 0 !important;
                                                min-height: auto;

                                                input.mat-mdc-input-element {
                                                    background-color: #fff !important;

                                                    &:disabled {
                                                        background-color: #f8f8f9 !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }

                                .mat-mdc-text-field-wrapper {


                                    .mat-mdc-form-field-infix {


                                        input {

                                            background-color: #fff;

                                            &.mat-mdc-input-element {

                                                border-left: 0.5px solid rgba(151, 151, 151, 0.5);
                                                background-color: #fff;
                                                vertical-align: middle;
                                                box-sizing: border-box;
                                                border-top: 0 solid transparent;
                                                border-right: 0 solid transparent;
                                                border-bottom: 0 solid transparent;
                                                height: 32px;

                                                &:focus {
                                                    border-color: #00bfa5;
                                                }

                                                &:disabled {
                                                    color: rgba(0, 0, 0, .38);
                                                    background-color: #f8f8f9;
                                                }
                                            }

                                            &.cls-fbpInput {
                                                color: #fff;
                                                background-color: #5cc0a5;
                                            }
                                        }
                                    }

                                    .mdc-line-ripple {
                                        display: none;
                                    }
                                }

                                // .mat-mdc-form-field-subscript-wrapper {
                                //     display: none !important
                                // }

                                &.mat-form-field-disabled {
                                    .mat-mdc-text-field-wrapper {
                                        border-radius: 0;

                                        .mat-mdc-form-field-flex {
                                            .mat-mdc-form-field-infix {
                                                padding: 0 !important;

                                                input {
                                                    background-color: #f8f8f9;

                                                }
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                }
            }

            // onboarding header css
            .Head-onboarding {
                ._user-snippet {
                    border: 0 solid transparent;
                }
            }

            ._tasks-list {
                ul {
                    li {
                        .sr-no {
                            span.text-uppercase {
                                @extend .bgThemePrimary;
                            }
                        }
                    }
                }
            }

            // team search css
            .cls-orgsearchbox {
                width: calc(100% - 188px);
                position: relative;

                .cls-org-search {

                    @extend .border-opaque;

                    i.fa-sharp {
                        color: map-get($current-theme, theme-primary);
                    }

                    .auto-One {


                        .p-inputtext {
                            border: 0px solid transparent;
                            background-color: transparent;


                        }

                    }
                }
            }

            // report dropdown css

            .report-select {


                .mat-mdc-text-field-wrapper {

                    .mat-mdc-form-field-flex {

                        border: 0px solid transparent;

                        .mat-mdc-form-field-infix {

                            border-top: 0px solid transparent;

                            .mat-mdc-select {


                                .mat-mdc-select-trigger {

                                    .mat-mdc-select-value {

                                        .mat-mdc-select-value-text {

                                            color: map-get($current-theme, theme-white);


                                            .mat-mdc-select-min-line {
                                                color: map-get($current-theme, theme-white);
                                                font-size: 100%;

                                            }
                                        }
                                    }

                                    .mat-mdc-select-arrow-wrapper {

                                        .mat-mdc-select-arrow {
                                            color: map-get($current-theme, theme-white) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // chartjs-tooltip css
            #chartjs-tooltip {
                table {
                    thead {
                        tr {
                            th {
                                background-color: transparent;
                                color: map-get($current-theme , theme-white);
                            }
                        }
                    }

                    tbody {
                        tr {
                            td {
                                background-color: transparent;
                                border-bottom: 0px solid transparent;
                                color: map-get($current-theme , theme-white);
                            }
                        }
                    }
                }
            }

            //mat menu dropdown css
            .mat-menu-box-1 {
                .mat-mdc-menu-content {
                    .add-item {
                        color: map-get($current-theme , theme-primary);
                        background-color: map-get($current-theme , theme-backgroundTertiary);

                    }
                }
            }

            // report view mat css
            .create-widget {
                &.mat-mdc-card {
                    .mat-mdc-card-content {
                        .mat-accordion {
                            .mat-expansion-panel {
                                border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                            }
                        }
                    }
                }
            }

            // job opening box css
            .job-type-wrapper {
                .job-box {
                    &:hover {
                        border: 1px solid map-get($current-theme, theme-primary);
                    }
                }
            }

            // team chip css
            .team-chip {
                &.mdc-evolution-chip-set {


                    .mdc-evolution-chip-set__chips {

                        .mat-mdc-chip {

                            background-color: map-get($current-theme , theme-backgroundTertiary);
                            border: 0px solid transparent;



                            .mdc-evolution-chip__cell--primary {
                                .mdc-evolution-chip__action {
                                    .mdc-evolution-chip__text-label {
                                        color: #424b5f;
                                    }
                                }
                            }

                            &.mat-mdc-chip-selected {
                                background-color: map-get($current-theme , theme-primary) !important;

                                .mdc-evolution-chip__cell--primary {
                                    .mdc-evolution-chip__action {
                                        .mdc-evolution-chip__text-label {

                                            color: map-get($current-theme , theme-white) !important;
                                        }
                                    }



                                }

                            }
                        }


                    }


                }
            }

            // team chat icon css
            .tbl-box {
                .mat-menubutton {
                    border: 2px solid map-get($current-theme, theme-primary);
                }

                table {

                    thead {
                        tr {
                            background-color: transparent;

                            th {

                                color: map-get($current-theme, theme-primary);
                                background-color: transparent;
                            }
                        }
                    }

                    tbody {
                        tr {
                            background-color: transparent;

                            td {
                                background-color: transparent;
                                border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                                .cls-empdetail {

                                    color: map-get($current-theme, theme-primary);



                                }

                                .pos-and-com {

                                    .cls-emp-det1 {
                                        color: map-get($current-theme, theme-primaryHover);

                                    }



                                }
                            }

                            &:hover {
                                background-color: map-get($current-theme, theme-primaryAccent);
                            }
                        }
                    }

                }
            }




            // job opening tab css

            .action-popup {

                .action-pop-up-content {
                    &.candidatepage {

                        .mat-mdc-tab-group .mat-mdc-tab-header .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels {
                            .mat-mdc-tab {
                                margin-bottom: 0;
                                height: 32px;
                                border-right: 0px;
                                min-width: auto;
                                @extend .border-radius4;

                                &.mat-mdc-tab-disabled {
                                    .mdc-tab__text-label {
                                        color: map-get($current-theme, theme-contentDisabled) !important;
                                    }
                                }

                                .mdc-tab__content {
                                    .mdc-tab__text-label {
                                        color: map-get($current-theme , theme-contentPrimary);
                                    }

                                }

                                &.mdc-tab--active {
                                    @extend .bgPrimaryAccent;
                                    border: 1px solid map-get($current-theme, theme-primary) !important;

                                    .mdc-tab__content {
                                        .mdc-tab__text-label {
                                            color: map-get($current-theme , theme-primary) !important;
                                            font-weight: 700 !important;
                                        }
                                    }

                                    &:hover {
                                        background-color: map-get($current-theme , theme-primary);
                                    }
                                }
                            }
                        }
                    }

                    .mat-mdc-tab-group {
                        .mat-mdc-tab-header {
                            .mat-mdc-tab-label-container {
                                .mat-mdc-tab-list {
                                    .mat-ink-bar {
                                        display: none;
                                    }

                                    .mat-mdc-tab-labels {
                                        border: 1px solid map-get($current-theme , theme-primary);

                                        .mat-mdc-tab {
                                            border-bottom: 0 solid transparent !important;
                                            border-right: 1.5px solid map-get($current-theme , theme-primary);

                                            .mdc-tab__content {
                                                .mdc-tab__text-label {
                                                    color: map-get($current-theme , theme-contentPrimary);
                                                }
                                            }

                                            &.mdc-tab--active {
                                                background-color: map-get($current-theme , theme-primary);

                                                .mdc-tab__content {
                                                    .mdc-tab__text-label {
                                                        color: map-get($current-theme , theme-white) !important;
                                                    }
                                                }
                                            }
                                        }


                                    }
                                }
                            }
                        }
                    }

                }
            }

            // cxoTabs
            .cxoTabs {
                #engage-gph {
                    &.mat-mdc-tab-group {
                        .mat-mdc-tab-label-container {
                            .mat-mdc-tab-list {
                                .mat-ink-bar {
                                    display: none;
                                }

                                .mat-mdc-tab-labels {
                                    border: 1px solid map-get($current-theme , theme-primary);

                                    .mat-mdc-tab {
                                        border-bottom: 0 solid transparent !important;

                                        .mdc-tab__content {
                                            .mdc-tab__text-label {
                                                color: map-get($current-theme , theme-contentPrimary);
                                            }
                                        }

                                        &.mdc-tab--active {
                                            background-color: map-get($current-theme , theme-primary);

                                            .mdc-tab__content {
                                                .mdc-tab__text-label {
                                                    color: map-get($current-theme , theme-white) !important;
                                                }
                                            }
                                        }
                                    }


                                }
                            }
                        }
                    }
                }

            }

            // moodbotDialog box css
            .moodbotDialog {
                .p-dialog-content {
                    .moodbotChips {
                        .mdc-evolution-chip-set {
                            .mdc-evolution-chip-set__chips {
                                .mat-mdc-chip {
                                    &.mdc-evolution-chip--selected {
                                        background-color: map-get($current-theme , theme-primary) !important;

                                        .mdc-evolution-chip__cell--primary {
                                            .mdc-evolution-chip__action {
                                                .mdc-evolution-chip__text-label {
                                                    color: map-get($current-theme , theme-white) !important;
                                                }
                                            }
                                        }
                                    }

                                }
                            }
                        }
                    }
                }

                .p-dialog-footer {

                    border-top: none !important;

                }
            }

            /*************** OTP Code box css ***************/
            .cls-otpbox {
                &.mat-mdc-form-field {
                    border: 1px solid map-get($current-theme , theme-borderOpaque);
                    background-color: map-get($current-theme , theme-backgroundPrimary);

                }
            }

            // interview chips css
            .interviewer-box {
                .interview-feedback {
                    .autoCompleteOne {
                        &.p-autocomplete {
                            .p-autocomplete-multiple-container {
                                .p-autocomplete-token {

                                    .p-autocomplete-token-label {
                                        background-color: #e0e0e0 !important;
                                    }

                                    .p-autocomplete-token-icon {
                                        background-color: #e0e0e0 !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // assign to team flyer css

            .cls-team-flyer {
                .border-search-box {

                    // border: solid 1.5px #e5e5e5;
                    border-bottom: 1px solid map-get($current-theme , theme-borderOpaque);

                    .search-input {
                        border: 1px solid map-get($current-theme , theme-borderOpaque);

                    }


                }

                ._user-snippet {
                    border-bottom: 1px solid map-get($current-theme , theme-borderOpaque);

                    border-left: 0;
                    border-right: 0;
                    border-top: 0;

                }

                ._user-detail {


                    span {

                        color: map-get($current-theme , theme-contentPrimary);

                    }

                }
            }

            // attandance calander css
            .cls-calenderbox {
                border: 1px solid map-get($current-theme , theme-primary);
            }

            // team goal css
            .sidebar {

                .cls-sidebox {
                    background-color: map-get($current-theme , theme-backgroundPrimary);
                    border: 1px solid map-get($current-theme , theme-borderOpaque);

                }
            }

            .cls-teamgoaldb {

                .card {
                    background-color: map-get($current-theme , theme-backgroundPrimary);
                    border: 1px solid map-get($current-theme , theme-borderOpaque);

                    .cls-hrline {
                        border-bottom: 1px solid map-get($current-theme , theme-borderOpaque);
                    }

                }
            }

            .cls-kpiacc {
                &.mat-accordion {
                    .mat-expansion-panel {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                    }
                }
            }

            // marketplace sidemenu css
            .cls-sidebarbox {

                .cls-sidemenu {

                    color: #4f4f4f;


                    &.active {

                        color: map-get($current-theme , theme-contentPrimary);
                        background-color: map-get($current-theme , theme-backgroundTertiary);



                        &::before {

                            border-left: 4px solid map-get($current-theme , theme-primary);
                        }
                    }

                }


                // .activeOption{
                //     background-color: #4f4f4f;
                //     font-weight: 500;
                // }
            }

            // upload css 

            .clsupload-link {
                @extend .border-opaque;
                background-color: map-get($current-theme , backgroundTertiary);
            }

            .border-none {
                border: 0 !important;
            }

            // Rounded corners common 4px
            .border-radius4 {
                border-radius: 4px;
            }

            .border-radius8 {
                border-radius: 8px;
            }

            // max content width
            .max-width {
                width: max-content !important;
            }

            // multibtn css
            .cls-multibtn {

                .p-button {
                    height: 40px;
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                    background-color: map-get($current-theme , theme-backgroundPrimary);

                    color: map-get($current-theme , contentPrimary);

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            // global search tab button css
            .cls-multibutton {
                .p-button {
                    background-color: map-get($current-theme , theme-backgroundPrimary);
                    border: 1px solid map-get($current-theme , theme-borderOpaque);
                    color: map-get($current-theme , contentPrimary);





                    &:hover,
                    &.active {
                        background-color: map-get($current-theme , theme-primary);
                        border: 1px solid map-get($current-theme , theme-primary);

                    }

                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .cls-reporttab-container {
                .cls-reporttab {
                    &.active {
                        border-bottom: 2px solid map-get($current-theme , theme-contentPrimary);

                    }
                }

            }

            // counter popup
            .cls-countertopbtn {
                background-color: map-get($current-theme , theme-secondaryAccent);
                border-left: 1px solid map-get($current-theme , theme-secondary);
                border-right: 1px solid map-get($current-theme , theme-secondary);
                border-bottom: 1px solid map-get($current-theme , theme-secondary);
                color: map-get($current-theme , theme-secondary);
            }

            .cls-topbtn {
                background-color: map-get($current-theme , theme-primaryAccent);
                border-left: 1px solid map-get($current-theme , theme-borderFocus);
                border-right: 1px solid map-get($current-theme , theme-borderFocus);
                border-bottom: 1px solid map-get($current-theme , theme-borderFocus);
                color: map-get($current-theme , theme-primary);
            }

            .list-type {
                li {
                    &.selected {
                        color: map-get($current-theme , theme-primary);
                    }
                }
            }

            .rpt-dialog {
                .cls-bgcolor {
                    background-color: map-get($current-theme, theme-primaryAccent);

                }

                &:hover {
                    .cls-bgcolor {
                        background-color: map-get($current-theme, theme-primaryAccent);

                    }
                }
            }

            // checkbox css

            [type="checkbox"].filled-in:checked+label:after {

                border: 2px solid map-get($current-theme, theme-primary);
                background-color: map-get($current-theme, theme-primary) !important;

            }

            // chip default
            .chip-new{
                @include border-radius(24px);
                max-width: fit-content;

              }
              .font-family-all
              {
                font-family: $font-family;
              }
              // project page
              .projectborder
              {
                .mat-mdc-tab-header
                {
                    border:0 !important;
                }
                .mat-mdc-tab-body-content
                {
                    @extend .border-opaque;
                    @include border-radius(4px);
                }
              }
        }
    }
}