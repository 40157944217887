/************side-mini-panel************/

.side-mini-panel {
  width: 60px;
  position: fixed;
  left: 0px;
  height: 100%;
  top: 42px;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  right: 0;
  text-align: center;
  z-index: 2;
}

.sidebarmenu {
  padding: 0px !important;
  position: relative;
  height: 100%;
  max-width: 168px;
  top: 0px !important;
  width: 100%;

}


.side-mini-panel ul.mini-nav > li.selected h3 {
  color: #f4f6f9;
}



/************New sidebar menu************/

.sidebar-menu {
  list-style: none;
  margin: 0px;
  padding: 25px 0 12px 12px;
  height: 100%;
  position: relative;
}

.sidebar-menu > li {
  position: relative;
  padding: 0;
  display: block;

}

.sidebar-menu > li.active > a {
  color: #00bfa5;
}

.sidebar-menu > li > .submenu-1 {
  
  display: inline-block;
  font-size: 11px;
  font-weight: 400;
  color: #bdc7da !important;
  letter-spacing: 0.3px;
  width: 100%;
}
.sidebar-menu > li > .submenu-1 i {
  display: inline-block;
  background-image: url(/assets/images/ArrowRight.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  width: 9px;
  height: 24px; 
  margin-right: 10px;
  float: right;
  transition: all ease 0.3s;
}
.sidebar-menu > li > .submenu-1 > span {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 100%;
  line-height: 24px;
  
}


.sidebar-menu > li > a.active-submenu-1 span i {
  background-image: url(/assets/images/ArrowRight.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  width: 9px;
  height: 24px; 
  transform: rotate(90deg);
  
}

.sidebar-menu > li > .submenu-1:hover {
  color: #fff;
}

/************This is for submenu************/

.sidebar-menu li.active .sub-menu.in {
  display: block;
}

.sidebar-menu .sub-menu > li > a {
  color: #bdc7da;
}

.sidebar-menu .sub-menu > li > a:hover,
.sidebar-menu .sub-menu > li > a.active {
  color: #00bfa5;
  font-weight: 500;
}

.sidebar-menu > li > .sub-menu {
  margin: 0;
 
}

.sidebar-menu li > a > .fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  /* margin-top: 3px; */
}

.sidebar-menu li > a > .mdi-chevron-left {
  -webkit-transform: rotate(180deg) translate(11px, -95%);
  -ms-transform: rotate(180deg) translate(11px, -95%);
  -o-transform: rotate(180deg) translate(11px, -95%);
  transform: rotate(180deg) translate(11px, -95%);
  -webkit-transform-origin: top;
  -ms-transform-origin: top;
  -o-transform-origin: top;
  transform-origin: top;
  -webkit-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
 
}

.sidebar-menu li.active > a > .mdi-chevron-left::before {
  -webkit-transform: rotate(270deg) translate(-80%, 0);
  -ms-transform: rotate(270deg) translate(-80%, 0);
  -o-transform: rotate(270deg) translate(-80%, 0);
  transform: rotate(270deg) translate(-80%, 0);
}
.sidebar-menu li > a.active > .mdi-chevron-left::before {
  -webkit-transform: rotate(90deg) translate(0%, 0);
  -ms-transform: rotate(90deg) translate(0%, 0);
  -o-transform: rotate(90deg) translate(0%, 0);
  transform: rotate(90deg) translate(0%, 0);
}
.sidebar-menu .sub-menu {
  position: relative;
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu .sub-menu > li {
  
  padding-left: 8px;
  display: block;
}

.sidebar-menu .sub-menu > li > a {
 
  display: inline-block;
  font-size: 11px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  line-height: 22px;

}

.db {
  display: block;
}

.home-container {
  position: absolute !important;
  top: 42px;
  bottom: 0;
  left: 60px;
  right: 0;
  height: auto;
}

.hide-home-container {
  position: absolute !important;
  top: 42px;
  bottom: 0;
  left:  0;
  right: 0;
  height: auto;
  width: 100%;
}

.home-sidenav {
  width: 168px;
  height: 100%;
  background-color: #262e3e !important;
}

.category-close-icon {
  font-size: 20px;
}


.submenu-hide {
  overflow: hidden;
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.submenu-show {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  max-height: 500px;
}

/************This is for toggle************/

.rmv-sidebarmenu #togglebtn {
  display: none !important;
}

.togglediv {
  position: absolute;
  margin-left: 143px;
  top: 17px;
  z-index: 10;
  opacity: 1;
}



.togglediv #togglebtn i {
  font-size: 16px;
  color: #67757c;
  margin-top: 5px;
}


.Logout {
  background-image: url(../images/Sidebarmenu-Icon/Logout.png);
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
}


.sidebar-menu > .menu.active {
  border-bottom: 1px solid #344059;
}


/************ This is for label ************/

.sidebar-menu .label {
  position: absolute;
  right: 15px;
  padding: 5px 12px 4px;
}



.boxed .side-mini-panel {
  position: absolute;
}

.fix-sidebar.boxed .side-mini-panel {
  position: fixed;
  left: auto;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
  .home-container {
    left: 60px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .home-container {
    left: 60px;
  }
}
