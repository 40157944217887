
@media print {
  .counter-container mat-card .mat-mdc-card-content
  {
    height: calc(100% - 50%) !important;
  }
  // .counter-container mat-card
  // {
  //   page-break-before: always !important;
    
  // }
  // .breakpage
  // { page-break-before: always !important;

  // }
 .no-break
  {
    page-break-before: always !important;
    display: block !important;
  }
  p-calendar#att_calendar
  {
    zoom: 0.8;
  }
  .wall-right-inner {
    display: block !important;
}
  .cls-text
  {
    width: 22% !important;
    padding-left: 5px !important;
  }
  .leave-wrap{
    page-break-after: always !important;
    page-break-before: always !important;
}

  app-analytic-dashboard-home
  {
    .mat-mdc-card>:first-child, .mat-mdc-card-content>:first-child {
      margin-top: auto !important;
  }
  .cls-bodycnt{
    margin-top: 0 !important;
  }
  }
  
  .grid {
    
    grid-template-rows: repeat(auto-fit,  minmax(25%, 1fr)) !important;
    grid-template-columns: repeat(auto-fit, minmax(25%, 1fr)) !important;
      
}
 .cls-legend,.cls-count
{
  font-size: 4.167vw !important;
}
p-chart
{

  div
  {
    width: auto !important;
    height: auto !important;
  }
  .chartjs-render-monitor
  {
    width: 100% !important;
    height: 100%  !important;
  }
}
  .enterprise-wrapper .wall-left  perfect-scrollbar .scroll-inner {
    padding-left: 10px;
    padding-right: 10px;
    
}
  .enterprise-wrapper{
     max-height: max-content !important; 
    overflow: hidden;
    overflow-y: scroll;
    
}
body.p-overflow-hidden .enterprise-wrapper {
  max-height: 0 !important;
}
 .mat-drawer-container
{
  overflow: visible !important;
 
}
.mat-drawer-content
{
  overflow: visible !important;
}
  html, body {
    height:100% !important; 
    margin: 0cm !important; 
    padding: 0cm !important;
    overflow: hidden;
    width: 100% !important;
  }
  .loan_inbox._user-snippet ._user-detail {
    width: 100% !important;
  }

  .enterprise-wrapper {
    // max-height: 100% !important;
    // height: 100%;
    page-break-after: always !important;
    page-break-before: always !important;
  }

  // perfect-scrollbar.ps {
  //   overflow-y: scroll !important;
  //   page-break-after: always;
  //   page-break-before: always;
  //   height: auto !important;

  // }

  .wall-emp-center.unread-msg p {
    font-size: $text-name  !important;
  }


  // .print-height-adjust {

  //   height: 100vh !important;
  //   overflow: visible !important;
  // }

  // .inbox_left {
  //   height: 100% !important;

  // }

  .apps__menu {
    bottom: 100px !important;
  }

  .topbar .top-navbar .navbar-header {

    width: 20%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  html {
      width:100%; 
    //   height:100vh;
    page-break-after: always;
    page-break-before: always;
    -webkit-print-color-adjust: exact !important;
    page-break-inside: avoid;
    // height:100% !important;
  }
  .cdk-drag.widget-container
  {
    height: 100%;
    page-break-inside: avoid;
    page-break-before: always;
  }
  
  @page {

     //size: portrait;
    orientation: portrait;
     size: A3 !important;
    page-break-after: always;
    //page-break-inside: avoid;
   // page-break-before: always;
    margin: 0px !important;
    padding: 0 !important;
    //size: 7in 9.25in;
  }

  body {
     margin: 0px !important;
    page-break-inside: avoid;
    page-break-after: always;
    page-break-before: always;

  }

  // performance review
  .Analytics-icon,
  .delegate-icon,
  .archive-icon,
  .viewworkflow-icon,
  .emp-drictory-icon,
  .cxo-floatingbtn,
  .send-back-icon {
    background-size: cover !important;
  }
  ._card1hrv_5{
    page-break-inside: avoid;
    page-break-after: always;
    margin-top: 20px;
}
.initiative-block .common-chip {
  padding: 10px 0px 0 0 !important;
}
.mat-accordion .mat-expansion-panel
{
  page-break-inside: avoid;
  page-break-after: always;
}
.inbox-outlet{
  width: 100% !important;
  position: relative;
  margin-left: 0% !important;
}
  .inbox_left, 
  .topbar.headerDiv, 
  .side-mini-panel, 
  .sidebarmenu, 
  .home-main .inbox-top-panel, 
  .sideBarPrimeng.p-sidebar .insightInboxWrapper .inbox-top-panel,
  .home-sidenav, 
  .res-back-btn,
  .sideBarPrimeng.p-sidebar .btnclose,
  .insights-header,
  .insightInboxWrapper .insights-left
  {
    display: none !important;
  }
  .mat-sidenav-content.home-main{
    height: auto;
    overflow: visible;
  }
  .home-container{
    left: 0 !important;
    top: 0 !important;
  }

  .mat-drawer-content.mat-sidenav-content.home-main{
    margin-left: 0 !important;
  }
  .inbox-outlet .loan_inbox ._user-snippet._user-snippet-2 ._snippet-inner {
    display: flex !important;
  } 
  .sideBarPrimeng.p-sidebar{
    width: 100% !important;
  }
  body.p-overflow-hidden{
    overflow: visible !important;
    .main-app-container{
      display: none !important;
    }
  }
  .p-sidebar-mask.p-component-overlay{
    background-color: #fff;
    animation: none;
  }
  .sideBarPrimeng.p-sidebar.p-sidebar-active{
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    border: none !important;
    perfect-scrollbar, 
    .p-sidebar-content{
      height: auto !important;
      overflow: visible;
      .sidebar-height{
        height: auto !important;
        ._user-snippet {
          &._user-snippet-2{
            margin-bottom: 16px;
          }
        }
      }
      .insightInboxWrapper{
        .insight-inbox-content{
          border: none !important;
        }
      }
    }
  } 
  .home-main {
    .inbox-outlet {
      .loan_inbox{
        page-break-inside: avoid;
        margin-top: 0;
        padding-top: 10px;
        .loan_inbox_top{
          position: static !important;
          margin-bottom: 10px !important;
        }
        .clsmargin-top{
          margin-top: 10px !important;
        }
      }
    }
  }   
}