/*******************
  Global Styles
  *******************/
  @import url(../scss/icons/simple-line-icons/css/simple-line-icons.css);
  @import url(../scss/icons/themify-icons/themify-icons.css);
  @import url(../css/spinners.css);
  @import url(../css/animate.css);
  @import url(../css/button.css);
  @import url(../css/grid.css);
  @import url(../css/Sidebar.css);
  @import url(../scss/icons/material-design-iconic-font/css/materialdesignicons.min.css);
  @import url(../css/bootstrap-custom.css);
  
  /* body {
    &.bg-color-#6f93f0 {
        .btn-success {
            background-color: #6f93f0  !important;
        }
    }
  } */
  
  
  * {
    outline: none;
  }
  
  
  
  body {
     font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    line-height: 1.43;
    color: #484848;
    background-color: #fff;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @media screen and (max-width: 1920px) {
    body {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 1440px) {
    body {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 1366px) {
    body {
      font-size: 13.28px;
    }
  }
  
  @media screen and (max-width: 1280px) {
    body {
      font-size: 12.44px;
    }
  }
  
  @media screen and (max-width: 767px) {
  
  
    .cls-logobox {
      left: 20px !important;
    }
  
    .cls-docbox {
      padding-left: 20px !important;
  
      padding-right: 20px !important;
  
    }
  
    .document-uploader .ui-fileupload-choose {
      left: auto !important;
    }
  
    .cls-doctbl table .ui-table-thead>tr>th {
      padding-left: 5px !important;
      padding-top: 10px !important;
      padding-right: 5px !important;
      padding-bottom: 10px !important;
    }
  
    .cls-doctbl table .ui-table-tbody>tr>td {
      padding-left: 5px !important;
      padding-top: 8px !important;
      padding-right: 5px !important;
      padding-bottom: 8px !important;
    }
  
    .footer-external {
      padding-left: 20px !important;
  
      padding-right: 20px !important;
    }
  
    .add-candidate-wrapper {
      padding-left: 20px !important;
  
      padding-right: 20px !important;
    }
  
  
    .reset-password {
      max-width: 544px !important;
      width: 100% !important;
      padding-left: 20px !important;
  
      padding-right: 20px !important;
      margin-top: 40% !important;
  
    }
  
    .cls-drop .mat-mdc-form-field {
      width: 130px !important;
    }
  
    .cls-input {
      width: 100% !important;
    }
  
    .sideBarPrimeng {
      width: 100% !important;
    }
  
    .mat-mdc-checkbox .mat-mdc-checkbox-layout {
  
      width: 100% !important;
    }
  
    .cls-edu .col-md-6,
    .cls-edu .col-md-4,
    .cls-exp .col-md-6,
    .cls-exp .col-md-4 {
      padding: 0 !important;
    }
  
    .carrer-body {
      padding: 0 20px;
    }
  
    .job-detail-wrapper .w-30 {
      width: 45% !important;
    }
  
    .cls-action {
      width: 33% !important;
    }
  
    .holds-the-iframe {
  
      padding: 0 20px !important;
    }
  
    .newdiv {
      padding: 0 20px !important;
    }
  }
  
  
  
  
  html {
    position: relative;
    min-height: 100%;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  a:hover,
  a:focus {
    text-decoration: none;
  }
  
  a.link {
    color: #455a64;
  }
  
  a.link:hover,
  a.link:focus {
    color: #398bf7;
  }
  
  .img-rounded {
    border-radius: 4px !important;
  }
  
  
  /********************** word-break ********************/
  
  .w-break {
    word-break: break-all;
  }
  
  .nwrap {
    white-space: nowrap;
  }
  
  .break-word {
    word-break: break-word;
  }
  
  /******************* Headings *******************/
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #455a64;
    font-family: 'Public Sans', sans-serif;
    font-weight: 400;
  }
  
  h1 {
    line-height: 40px;
    font-size: 36px;
  }
  
  h2 {
    line-height: 36px;
    font-size: 24px;
  }
  
  h3 {
    line-height: 24px;
    font-size: 16px;
    color: #242a3c;
  }
  
  h4 {
    line-height: 22px;
    font-size: 16px;
  }
  
  h5 {
    line-height: 18px;
    font-size: 14px;
    font-weight: 400;
  }
  
  h6 {
    line-height: 16px;
    font-size: 12px;
    font-weight: 400;
  }
  
  .l-h16 {
    line-height: 16px !important;
  }
  .l-h20 {
    line-height: 20px;
  }
  
  .l-h24 {
    line-height: 24px;
  }
  
  .l-h28 {
    line-height: 28px;
  }
  
  .l-h32 {
    line-height: 32px;
  }
  
  .l-h36 {
    line-height: 36px;
  }
  
  .l-h40 {
    line-height: 40px;
  }
  
  .l-h46 {
    line-height: 46px;
  }
  
  .l-h50 {
    line-height: 50px;
  }
  
  .display-5 {
    font-size: 3rem;
  }
  
  .display-6 {
    font-size: 36px;
  }
  
  .box {
    border-radius: 4px;
    padding: 10px;
  }
  
  .dl {
    display: inline-block;
  }
  
  .db {
    display: block !important;
  }
  
  .no-wrap td,
  .no-wrap th {
    white-space: nowrap;
  }
  
  .capitalize {
    text-transform: capitalize;
  }
  
  
  /*******************
                Blockquote
                *******************/
  
  blockquote {
    border-left: 5px solid #398bf7;
    border: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px;
  }
  
  .clear {
    clear: both;
  }
  
  ol li {
    margin: 5px 0;
  }
  
  
  /*******************
                Paddings
                *******************/
  
  .p-0 {
    padding: 0px;
  }
  
  .p-all5 {
    padding: 5px;
  }
  
  .p-10 {
    padding: 10px;
  }
  
  .p-15 {
    padding: 15px;
  }
  
  .p-16 {
    padding: 16px;
  }
  
  .p-20 {
    padding: 20px;
  }

  .p-25 {
    padding: 25px;
  }
  
  .p-30 {
    padding: 30px;
  }
  
  .p-l-0 {
    padding-left: 0px !important;
  }
  
  .p-l-5 {
    padding-left: 5px !important;
  }
  
  .p-l-10 {
    padding-left: 10px;
  }
  
  .p-l-15 {
    padding-left: 15px;
  }
  
  .p-l-20 {
    padding-left: 20px;
  }
  
  .p-l-25 {
    padding-left: 25px !important;
  }
  
  .p-l-30 {
    padding-left: 30px;
  }
  
  .p-l-35 {
    padding-left: 35px;
  }
  
  .p-l-40 {
    padding-left: 40px;
  }
  
  .p-r-0 {
    padding-right: 0px !important;
  }
  
  .p-r-5 {
    padding-right: 5px !important;
  }
  
  .p-r-10 {
    padding-right: 10px;
  }
  
  .p-r-15 {
    padding-right: 15px;
  }
  
  .p-r-20 {
    padding-right: 20px;
  }
  
  .p-r-25 {
    padding-right: 25px !important;
  }
  
  .p-r-24 {
    padding-right: 24px;
  }
  
  .p-r-30 {
    padding-right: 30px;
  }
  
  .p-r-35 {
    padding-right: 35px;
  }
  
  .p-r-36 {
    padding-right: 36px;
  }
  
  .p-r-40 {
    padding-right: 40px;
  }
  
  .p-t-0 {
    padding-top: 0px !important;
  }
  
  .p-t-7 {
    padding-top: 7px;
  }
  
  .p-t-10 {
    padding-top: 10px;
  }
  
  .p-t-15 {
    padding-top: 15px;
  }
  
  .p-t-20 {
    padding-top: 20px;
  }
  
  .p-t-25 {
    padding-top: 25px;
  }
  
  .p-t-30 {
    padding-top: 30px;
  }
  
  .p-t-40 {
    padding-top: 40px;
  }
  
  .p-t-50 {
    padding-top: 50px;
  }
  
  .p-b-0 {
    padding-bottom: 0px !important;
  }
  
  .p-b-5 {
    padding-bottom: 5px;
  }
  
  .p-b-10 {
    padding-bottom: 10px !important;
  }
  
  .p-b-15 {
    padding-bottom: 15px;
  }
  
  .p-b-20 {
    padding-bottom: 20px;
  }
  
  .p-b-25 {
    padding-bottom: 25px;
  }
  
  .p-b-30 {
    padding-bottom: 30px;
  }
  
  .p-b-35 {
    padding-bottom: 35px;
  }
  
  .p-b-40 {
    padding-bottom: 40px;
  }
  
  .p-b-45 {
    padding-bottom: 45px;
  }
  
  .p-b-100 {
    padding-bottom: 100px;
  }
  
  
  /*******************
                Margin
                *******************/
  .m-auto {
    margin: auto;
  }
  
  .m-0 {
    margin: 0px;
  }
  
  .m-l-0 {
    margin-left: 0px;
  }
  
  .m-l-5 {
    margin-left: 5px;
  }
  
  .m-l-10 {
    margin-left: 10px !important;
  }
  
  .m-l-15 {
    margin-left: 15px;
  }
  
  .m-l-20 {
    margin-left: 20px;
  }
  
  .m-l-25 {
    margin-left: 25px;
  }
  
  .m-l-30 {
    margin-left: 30px;
  }
  
  .m-l-35 {
    margin-left: 35px;
  }
  
  .m-l-40 {
    margin-left: 40px;
  }
  
  .m-r-0 {
    margin-right: 0px !important;
  }
  
  .m-r-3 {
    margin-right: 3px;
  }
  
  .m-r-5 {
    margin-right: 5px;
  }
  
  .m-r-10 {
    margin-right: 10px;
  }
  
  .m-r-15 {
    margin-right: 15px;
  }
  
  .m-r-20 {
    margin-right: 20px;
  }
  
  .m-r-25 {
    margin-right: 25px;
  }
  
  .m-r-30 {
    margin-right: 30px;
  }
  
  .mr-32 {
    margin-right: 32px;
  }
  
  .m-r-35 {
    margin-right: 35px;
  }
  
  .m-r-40 {
    margin-right: 40px;
  }
  
  .m-t-0 {
    margin-top: 0px;
  }
  
  .m-t-5 {
    margin-top: 5px;
  }
  
  .m-t-10 {
    margin-top: 10px;
  }
  
  .m-t-14 {
    margin-top: 14px;
  }
  
  .m-t-15 {
    margin-top: 15px;
  }
  
  .m-t-18 {
    margin-top: 18px;
  }
  
  .m-t-20 {
    margin-top: 20px;
  }
  
  .m-t-25 {
    margin-top: 25px;
  }
  
  .m-t-30 {
    margin-top: 30px !important;
  }
  
  .mt-32 {
    margin-top: 32px;
  }
  
  .m-t-40 {
    margin-top: 40px !important;
  }
  
  .m-tn-5 {
    margin-top: -5px;
  }
  
  .m-tn-7 {
    margin-top: -7px;
  }
  .m-tn-8 {
    margin-top: -8px;
  }
  
  .m-tn-10 {
    margin-top: -10px;
  }
  
  .m-tn-15 {
    margin-top: -15px !important;
  }
  
  .m-tn-20 {
    margin-top: -20px !important;
  }
  
  .m-tn-25 {
    margin-top: -25px;
  }
  
  .m-tn-30 {
    margin-top: -30px;
  }
  
  .m-tn-40 {
    margin-top: -40px;
  }
  
  .m-b-0 {
    margin-bottom: 0px;
  }
  
  .m-b-5 {
    margin-bottom: 5px;
  }
  
  .m-b-10 {
    margin-bottom: 10px !important;
  }
  
  .m-b-15 {
    margin-bottom: 15px;
  }
  
  .m-b-20 {
    margin-bottom: 20px;
  }
  
  .m-b-25 {
    margin-bottom: 25px;
  }
  
  .m-b-30 {
    margin-bottom: 30px !important;
  }
  
  .m-b-40 {
    margin-bottom: 40px;
  }
  .m-b-70 {
    margin-bottom: 70px;
  }
  
  .m-bn-5 {
    margin-bottom: -5px;
  }
  
  .m-bn-10 {
    margin-bottom: -10px;
  }
  
  .m-bn-15 {
    margin-bottom: -15px;
  }
  
  .m-bn-20 {
    margin-bottom: -20px;
  }
  
  .m-bn-30 {
    margin-bottom: -30px;
  }
  
  .m-bn-40 {
    margin-bottom: -40px;
  }
  
  .m-ln-5 {
    margin-left: -5px;
  }
  
  .m-ln-10 {
    margin-left: -10px;
  }
  
  .m-ln-15 {
    margin-left: -15px;
  }
  
  .m-ln-20 {
    margin-left: -20px;
  }
  
  .m-ln-25 {
    margin-left: -25px;
  }
  
  .m-ln-30 {
    margin-left: -30px;
  }
  
  .m-ln-35 {
    margin-left: -35px;
  }
  
  .m-ln-40 {
    margin-left: -40px;
  }
  
  
  .m-rn-5 {
    margin-right: -5px;
  }
  
  .m-rn-10 {
    margin-right: -10px;
  }
  
  .m-rn-15 {
    margin-right: -15px;
  }
  
  .m-rn-20 {
    margin-right: -20px;
  }
  
  .m-rn-25 {
    margin-right: -25px;
  }
  
  .m-rn-30 {
    margin-right: -30px;
  }
  
  .m-rn-35 {
    margin-right: -35px;
  }
  
  .m-rn-40 {
    margin-right: -40px;
  }
  
  
  /*******************
                vertical alignment
                *******************/
  
  .vt {
    vertical-align: top;
  }
  
  .vm {
    vertical-align: middle;
  }
  
  .vb {
    vertical-align: bottom;
  }
  
  
  /*******************
                Opacity
                *******************/
  
  .op-5 {
    opacity: 0.5;
  }
  
  .op-3 {
    opacity: 0.3;
  }
  
  
  /*******************
                font weight
                *******************/
  
  .font-bold {
    font-weight: 700 !important;
  }
  
  .font-semibold {
    font-weight: 600 !important;
  }
  
  .font-normal {
    font-weight: 400 !important;
  }
  
  .font-light {
    font-weight: 300 !important;
  }
  
  .font-medium {
    font-weight: 500 !important;
  }
  
  .font-40 {
    font-size: 40px !important;
  }
  
  .font-30 {
    font-size: 30px !important;
  }
  
  .font-26 {
    font-size: 26px !important;
  }
  
  .font-24 {
    font-size: 24px !important;
  }
  
  .font-22 {
    font-size: 22px !important;
  }
  
  .font-20 {
    font-size: 20px !important;
  }
  
  .font-18 {
    font-size: 18px !important;
  }
  
  .font-16 {
    font-size: 16px !important;
  }
  
  .font-14 {
    font-size: 14px !important;
  }
  
  .font-13 {
    font-size: 13px !important;
  }
  
  .font-12 {
    font-size: 12px !important;
  }
  
  .font-11 {
    font-size: 11px !important;
  }
  
  .font-10 {
    font-size: 10px !important;
  }
  
  .font-9 {
    font-size: 9px !important;
  }
  .font-8 {
    font-size: 8px !important;
  }
  
  .font-17 {
    font-size: 17px;
  }
  
 
  

  /*******************
                Border
                *******************/
  
  .b-0 {
    border: none;
  }
  
  .b-r {
    border-right: 1px solid rgba(151, 151, 151, .33);
  }
  
  .b-l {
    border-left: 1px solid rgba(151, 151, 151, .33);
  }
  
  .b-b {
    border-bottom: 1px solid rgba(151, 151, 151, .33) !important;
  }
  
  .b-t {
    border-top: 1px solid rgba(151, 151, 151, .33);
  }
  
  .b-all {
    border: 1px solid rgba(151, 151, 151, .33);
  }
  
  .b-b-grey {
    border-bottom: 1px solid #f2f2f2;
  }
  
  .border-box {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    width: 100%;
    border-radius: 2px;
    /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px rgba(151, 151, 151, 0.5); */
    background-color: #ffffff;
    margin-bottom: 30px;
  }
  
  
  /*******************
                Thumb size
                *******************/
  
  .thumb-sm {
    height: 32px;
    width: 32px;
  }
  
  .thumb-md {
    height: 40px;
    width: 40px;
  }
  
  .thumb-lg {
    height: 88px;
    width: 88px;
  }
  
  .thumb-ext {
    height: 120px;
    width: 120px;
  }
  
  .hide {
    display: none;
  }
  
  .img-circle {
    border-radius: 100%;
  }
  
  .radius {
    border-radius: 4px;
  }
  
  
  /*******************
                Text Colors
                *******************/
  
  .text-white {
    color: #ffffff !important;
  }
  
  .text-danger {
    color: #ff001f !important;
  }
  
  .text-muted {
    color: #99abb4 !important;
  }
  
  .text-warning {
    color: #ffb22b !important;
  }
  
  .text-success {
    color: #00bfa5 !important;
  }
  
  .text-info {
    color: #398bf7 !important;
  }
  
  .text-inverse {
    color: #2f3d4a !important;
  }
  
  .text-blue {
    color: #02bec9 !important;
  }
  
  .text-purple {
    color: #7460ee !important;
  }
  
  .text-primary {
    color: #745af2 !important;
  }
  
  .text-megna {
    color: #56c0d8 !important;
  }
  
  .text-dark {
    color: #67757c !important;
  }
  
  .text-themecolor {
    color: #398bf7 !important;
  }
  
  .text-blackcolor {
    color: #000 !important;
  }
  
  .text-setting {
    color: #f06292 !important;
  }
  
  
  /*******************
                Background Colors
                *******************/
  
  .bg-gray {
    background-color: #f4f6f9 !important;
  }
  
  .bg-primary {
    background-color: #745af2;
  }
  
  .bg-success {
    background-color: #00bfa5;
  }
  
  .bg-info {
    background-color: #398bf7;
  }
  
  .bg-warning {
    background-color: #ffb22b;
  }
  
  .bg-danger {
    background-color: #ff5252;
  }
  
  .bg-megna {
    background-color: #56c0d8;
  }
  
  .bg-theme {
    background-color: #398bf7;
  }
  
  .bg-inverse {
    background-color: #2f3d4a;
  }
  
  .bg-purple {
    background-color: #7460ee;
  }
  
  .bg-light-part {
    background-color: rgba(0, 0, 0, 0.02);
  }
  
  .bg-light-primary {
    background-color: #f1effd;
  }
  
  .bg-light-success {
    background-color: #e8fdeb;
  }
  
  .bg-light-info {
    background-color: #cfecfe;
  }
  
  .bg-light-extra {
    background-color: #ebf3f5;
  }
  
  .bg-light-warning {
    background-color: #fff8ec;
  }
  
  .bg-light-danger {
    background-color: #f9e7eb;
  }
  
  .bg-light-inverse {
    background-color: #f6f6f6;
  }
  
  .bg-light {
    background-color: #e9edf2;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  .bg-bluewhite {
    background-color: #f5f7fa;
  }
  
  
  /*******************
                Rounds
                *******************/
  
  .round {
    line-height: 48px;
    color: #ffffff;
    width: 50px;
    height: 50px;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    border-radius: 100%;
    background: #398bf7;
  }
  
  .round img {
    border-radius: 100%;
  }
  
  .round-lg {
    line-height: 65px;
    width: 60px;
    height: 60px;
    font-size: 30px;
  }
  
  .round.round-info {
    background: #398bf7;
  }
  
  .round.round-warning {
    background: #ffb22b;
  }
  
  .round.round-danger {
    background: #ff5252;
  }
  
  .round.round-success {
    background: #00bfa5;
  }
  
  .round.round-primary {
    background: #745af2;
  }
  
  
  /*******************
                Labels
                *******************/
  
  .label {
    padding: 3px 10px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 400;
    border-radius: 4px;
    font-size: 75%;
  }
  
  .label-rounded {
    border-radius: 60px;
  }
  
  .label-custom {
    background-color: #56c0d8;
  }
  
  .label-success {
    background-color: #00bfa5;
  }
  
  .label-info {
    background-color: #398bf7;
  }
  
  .label-warning {
    background-color: #ffb22b;
  }
  
  .label-danger {
    background-color: #ff5252;
  }
  
  .label-megna {
    background-color: #56c0d8;
  }
  
  .label-primary {
    background-color: #745af2;
  }
  
  .label-purple {
    background-color: #7460ee;
  }
  
  .label-red {
    background-color: #fb3a3a;
  }
  
  .label-inverse {
    background-color: #2f3d4a;
  }
  
  .label-default {
    background-color: #e9edf2;
  }
  
  .label-white {
    background-color: #ffffff;
  }
  
  .label-light-success {
    background-color: #e8fdeb;
    color: #00bfa5;
  }
  
  .label-light-info {
    background-color: #cfecfe;
    color: #398bf7;
  }
  
  .label-light-warning {
    background-color: #fff8ec;
    color: #ffb22b;
  }
  
  .label-light-danger {
    background-color: #f9e7eb;
    color: #ef5350;
  }
  
  .label-light-megna {
    background-color: #e0f2f4;
    color: #56c0d8;
  }
  
  .label-light-primary {
    background-color: #f1effd;
    color: #745af2;
  }
  
  .label-light-inverse {
    background-color: #f6f6f6;
    color: #2f3d4a;
  }
  
  
  /*******************
                 Badge
                ******************/
  
  .badge {
    font-weight: 400;
  }
  
  .badge-xs {
    font-size: 9px;
  }
  
  .badge-xs,
  .badge-sm {
    -webkit-transform: translate(0, -2px);
    -ms-transform: translate(0, -2px);
    -o-transform: translate(0, -2px);
    transform: translate(0, -2px);
  }
  
  .badge-success {
    background-color: #00bfa5;
  }
  
  .badge-info {
    background-color: #398bf7;
  }
  
  .badge-primary {
    background-color: #745af2;
  }
  
  .badge-warning {
    background-color: #ffb22b;
    color: #ffffff;
  }
  
  .badge-danger {
    background-color: #ff5252;
  }
  
  .badge-purple {
    background-color: #7460ee;
  }
  
  .badge-red {
    background-color: #fb3a3a;
  }
  
  .badge-inverse {
    background-color: #2f3d4a;
  }
  
  
  /*******************
                List-style-none
                ******************/
  
  ul.list-style-none {
    margin: 0px;
    padding: 0px;
  }
  
  ul.list-style-none li {
    list-style: none;
  }
  
  ul.list-style-none li a {
    color: #67757c;
    padding: 8px 0px;
    display: block;
    text-decoration: none;
  }
  
  ul.list-style-none li a:hover {
    color: #398bf7;
  }
  
  
  /*******************
                dropdown-item
                ******************/
  
  .dropdown-item {
    padding: 8px 1rem;
    color: #67757c;
  }
  
  
  /*******************
                Layouts
                ******************/
  
  .card-body {
    padding: 20px;
  }
  
  .card-no-border .card {
    border: 0px;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .card-no-border .shadow-none {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .card-outline-danger,
  .card-outline-info,
  .card-outline-warning,
  .card-outline-success,
  .card-outline-primary {
    background: #ffffff;
  }
  
  .card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
  }
  
  
  /*******************/
  
  
  /*single column*/
  
  
  /*******************/
  
  .single-column .left-sidebar {
    display: none;
  }
  
  .single-column .page-wrapper {
    margin-left: 0px;
  }
  
  .fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
  }
  
  
  /************This is for submenu************/
  
  .menu-title {
    padding: 12px 15px;
    display: block;
    margin: 0px;
    font-weight: 600;
    font-size: 12px;
    line-height: 40px;
  }
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  
  /*==============================================================
                 For all pages
                 ============================================================== */
  
  #main-wrapper {
    width: 100%;
    overflow: hidden;
  }
  
  .boxed #main-wrapper {
    width: 100%;
    max-width: 1300px;
    position: relative;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  }
  
  .boxed #main-wrapper .sidebar-footer {
    position: absolute;
  }
  
  .boxed #main-wrapper .topbar {
    max-width: 1300px;
  }
  
  .boxed #main-wrapper .footer {
    display: none;
  }
  
  .page-wrapper {
    background: #f4f6f9;
    padding-bottom: 60px;
    position: relative;
  }
  
  
  /* .container-fluid {
              padding: 25px 25px;
            } */
  
  .card {
    margin-bottom: 30px;
  }
  
  .card .card-subtitle {
    font-weight: 300;
    margin-bottom: 15px;
    color: #99abb4;
  }
  
  .card .card-title,
  .card-title-arc {
    position: relative;
  }
  
  
  /*******************
                 Topbar
                *******************/
  
  .fix-header .topbar {
    position: fixed;
    width: 100%;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.05);
  }
  
  .fix-header .page-wrapper {
    padding-top: 40px;
  }
  
  .fix-header .right-side-panel {
    top: 148px;
    height: calc(100% - 148px);
  }
  
  .topbar {
    position: relative;
    z-index: 50;
  }
  
  .topbar .navbar-collapse {
    padding: 0 10px;
    display: block !important;
  }
  
  .topbar .top-navbar {
    min-height: 42px;
    padding: 0px;
    height: 42px;
    margin-bottom: 0;
    position: relative;
    z-index: 9;
  }
  
  .topbar .top-navbar .dropdown-toggle::after {
    display: none;
  }
  
  .topbar .top-navbar .navbar-header {
    line-height: 42px;
    height: 42px;
  }
  
  .topbar .top-navbar .navbar-header .navbar-brand {
    /* margin-right: 0px;
            padding-bottom: 0px;
            padding-top: 0px; */
    font-size: 12px;
    font-weight: 600;
    padding-left: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    height: 42px;
    /* line-height: 27px;
          padding-top: 10px; */
  }
  
  .topbar .top-navbar .navbar-header .navbar-brand .light-logo {
    display: none;
  }
  
  .topbar .top-navbar .navbar-header .navbar-brand b {
    line-height: 40px;
    display: inline-block;
    width: 68px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .topbar .top-navbar .navbar-header .navbar-brand span {
    padding-left: 14px;
  }
  
  .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
    padding-left: 15px;
    padding-right: 30px;
    font-size: 12px;
    line-height: 26px;
  }
  
  .topbar .top-navbar .navbar-nav>.nav-item>span {
    line-height: 65px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    padding: 0 10px;
  }
  
  .navbar-light .navbar-nav .nav-link {
    color: rgba(27, 36, 54, 0.5);
  }
  
  .nav-link .ti-search {
    font-size: 14px;
  }
  
  .topbar .top-navbar .navbar-nav>.nav-item.show {
    background: rgba(0, 0, 0, 0.05);
  }
  
  .topbar .profile-pic {
    width: 30px;
    border-radius: 100%;
  }
  
  .topbar .dropdown-menu {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 3px 12px rgba(0, 0, 0, 0.05);
    border-color: rgba(120, 130, 140, 0.13);
  }
  
  .topbar .dropdown-menu .dropdown-item {
    padding: 7px 1.5rem;
  }
  
  .topbar ul.dropdown-user {
    padding: 0px;
    min-width: 270px;
  }
  
  .topbar ul.dropdown-user li {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  
  .topbar ul.dropdown-user li.divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: rgba(120, 130, 140, 0.13);
  }
  
  .topbar ul.dropdown-user li .dw-user-box {
    padding: 10px 15px;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-img {
    width: 70px;
    display: inline-block;
    vertical-align: top;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-img img {
    width: 100%;
    border-radius: 5px;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-text {
    display: inline-block;
    padding-left: 10px;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-text h4 {
    margin: 0px;
    font-size: 15px;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-text p {
    margin-bottom: 2px;
    font-size: 12px;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-text .btn {
    color: #ffffff;
    padding: 5px 10px;
    display: inline-block;
  }
  
  .topbar ul.dropdown-user li .dw-user-box .u-text .btn:hover {
    background: #e6294b;
  }
  
  .topbar ul.dropdown-user li a {
    padding: 9px 15px;
    display: block;
    color: #67757c;
  }
  
  .topbar ul.dropdown-user li a:hover {
    background: #e9edf2;
    color: #398bf7;
    text-decoration: none;
  }
  
  .search-box .app-search {
    position: absolute;
    margin: 0px;
    display: block;
    z-index: 110;
    width: 100%;
    top: -1px;
    -webkit-box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    left: 0px;
  }
  
  .search-box .app-search input {
    width: 100%;
    /* padding: 25px 40px 25px 20px; */
    border-radius: 0px;
    font-size: 17px;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
  }
  
  .search-box .app-search input:focus {
    border-color: #ffffff;
  }
  
  .search-box .app-search .srh-btn {
    position: absolute;
    top: 23px;
    cursor: pointer;
    background: #ffffff;
    width: 15px;
    height: 15px;
    right: 20px;
    font-size: 14px;
  }
  
  
  /******** Report Search ***************/
  
  .report-search-box .app-report-search {
    position: absolute;
    margin: 0px;
    display: block;
    z-index: 110;
    width: 100%;
    top: 74px;
    -webkit-box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0px;
  }
  
  .report-search-box .app-report-search input {
    width: 100%;
    height: 60px;
    border-radius: 0px;
    font-size: 17px;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
    padding: 0 105px;
  }
  
  .report-search-box .app-report-search input:focus {
    border-color: #ffffff;
  }
  
  .report-search-box .app-report-search .srh-btn {
    position: absolute;
    top: 20px;
    cursor: pointer;
    background: #ffffff;
    width: 20px;
    height: 20px;
    right: 35px;
    font-size: 24px;
  }
  
  
  /******** favorite Search ***************/
  
  .favorite-search-box .app-favorite-search {
    position: absolute;
    margin: 0px;
    display: block;
    z-index: 110;
    width: 100%;
    top: 76px;
    -webkit-box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
    display: none;
    left: 0px;
  }
  
  .favorite-search-box .app-favorite-search input {
    width: 100%;
    height: 60px;
    border-radius: 0px;
    font-size: 17px;
    -webkit-transition: 0.5s ease-in;
    -o-transition: 0.5s ease-in;
    transition: 0.5s ease-in;
    padding: 0 105px;
  }
  
  .favorite-search-box .app-favorite-search input:focus {
    border-color: #ffffff;
  }
  
  .favorite-search-box .app-favorite-search .srh-btn {
    position: absolute;
    top: 20px;
    cursor: pointer;
    background: #ffffff;
    width: 20px;
    height: 20px;
    right: 35px;
    font-size: 24px;
  }
  
  .mini-sidebar .top-navbar .navbar-header {
    width: 70px;
    text-align: center;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding-left: 0px;
  }
  
  .logo-center .top-navbar .navbar-header {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  
  
  /*******************
                Notify
                *******************/
  
  .notify {
    position: relative;
    top: -28px;
    right: -7px;
  }
  
  .notify .heartbit {
    position: absolute;
    top: -20px;
    right: -4px;
    height: 25px;
    width: 25px;
    z-index: 10;
    border: 5px solid #ff5252;
    border-radius: 70px;
    animation: heartbit 1s ease-out;
    -moz-animation: heartbit 1s ease-out;
    -moz-animation-iteration-count: infinite;
    -o-animation: heartbit 1s ease-out;
    -o-animation-iteration-count: infinite;
    -webkit-animation: heartbit 1s ease-out;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
  }
  
  .notify .point {
    width: 6px;
    height: 6px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: #ff5252;
    position: absolute;
    right: 6px;
    top: -10px;
  }
  
  @keyframes heartbit {
    0% {
      -moz-transform: scale(0);
      opacity: 0;
    }
  
    25% {
      -moz-transform: scale(0.1);
      opacity: 0.1;
    }
  
    50% {
      -moz-transform: scale(0.5);
      opacity: 0.3;
    }
  
    75% {
      -moz-transform: scale(0.8);
      opacity: 0.5;
    }
  
    100% {
      -moz-transform: scale(1);
      opacity: 0;
    }
  }
  
  @-moz-keyframes heartbit {
    0% {
      -moz-transform: scale(0);
      opacity: 0;
    }
  
    25% {
      -moz-transform: scale(0.1);
      opacity: 0.1;
    }
  
    50% {
      -moz-transform: scale(0.5);
      opacity: 0.3;
    }
  
    75% {
      -moz-transform: scale(0.8);
      opacity: 0.5;
    }
  
    100% {
      -moz-transform: scale(1);
      opacity: 0;
    }
  }
  
  @-webkit-keyframes heartbit {
    0% {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  
    25% {
      -webkit-transform: scale(0.1);
      opacity: 0.1;
    }
  
    50% {
      -webkit-transform: scale(0.5);
      opacity: 0.3;
    }
  
    75% {
      -webkit-transform: scale(0.8);
      opacity: 0.5;
    }
  
    100% {
      -webkit-transform: scale(1);
      opacity: 0;
    }
  }
  
  
  /*******************
                 Horizontal-navabar
                *******************/
  
  .hdr-nav-bar .navbar {
    background: #ffffff;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding: 0 15px;
  }
  
  .hdr-nav-bar .navbar .navbar-nav>li {
    padding: 0 10px;
  }
  
  .hdr-nav-bar .navbar .navbar-nav>li>a {
    padding: 25px 5px;
    -webkit-transition: 0.2s ease-in;
    -o-transition: 0.2s ease-in;
    transition: 0.2s ease-in;
    border-bottom: 2px solid transparent;
  }
  
  .hdr-nav-bar .navbar .navbar-nav>li.active>a,
  .hdr-nav-bar .navbar .navbar-nav>li:hover>a,
  .hdr-nav-bar .navbar .navbar-nav>li>a:focus {
    border-color: #398bf7;
  }
  
  .hdr-nav-bar .navbar .navbar-nav .dropdown-toggle:after {
    display: none;
  }
  
  .hdr-nav-bar .navbar .navbar-brand {
    padding: 20px 5px;
  }
  
  .hdr-nav-bar .navbar .navbar-toggler {
    border: 0px;
    cursor: pointer;
  }
  
  .hdr-nav-bar .navbar .custom-select {
    border-radius: 60px;
    font-size: 14px;
    padding: 0.375rem 40px 0.375rem 18px;
  }
  
  .hdr-nav-bar .navbar .call-to-act {
    padding: 15px 0;
  }
  
  
  /*******************
                 Breadcrumb and page title
                *******************/
  
  .page-titles {
    background: #f4f6f9;
    margin-bottom: 20px;
    padding: 0px;
  }
  
  .page-titles h3 {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  .page-titles .breadcrumb {
    padding: 0px;
    margin-bottom: 0px;
    background: transparent;
    font-size: 14px;
  }
  
  .page-titles .breadcrumb li {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  
  .page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    content: "\e649";
    font-family: themify;
    color: #a6b7bf;
    font-size: 11px;
  }
  
  .page-titles .breadcrumb .breadcrumb-item.active {
    color: #99abb4;
  }
  
  .p-relative {
    position: relative;
  }
  
  .lstick {
    width: 2px;
    background: #398bf7;
    height: 30px;
    margin-left: -20px;
    margin-right: 18px;
    display: inline-block;
    vertical-align: middle;
  }
  
  
  /*******************
                 Right side toggle
                *******************/
  
  @-webkit-keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes rotate {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes rotate {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  .right-side-toggle {
    position: relative;
  }
  
  .right-side-toggle i {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1s;
    -moz-transition-property: -moz-transform;
    -moz-transition-duration: 1s;
    transition-property: -webkit-transform;
    -o-transition-property: transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-animation-name: rotate;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: rotate;
    -moz-animation-duration: 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    animation-name: rotate;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    top: 18px;
    left: 18px;
  }
  
  .right-sidebar {
    position: fixed;
    right: -95%;
    width: 95%;
    display: none;
    z-index: 1100;
    background: #ffffff;
    top: 0px;
    padding-bottom: 20px;
    height: 100%;
    -webkit-box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
    box-shadow: 5px 1px 40px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    z-index: 9999;
  }
  
  .right-sidebar .rpanel-title {
    display: block;
    padding: 24px 20px;
    color: #282c3f;
    text-transform: uppercase;
    font-size: 16px;
    background: #fff;
    line-height: 20px;
  }
  
  .rpanel-title {
    /* display: block;
          padding: 16px;
          color: #282c3f;
          text-transform: uppercase;
          font-size: 16px;
          background: #fff;
          font-weight: 600;
          line-height: 20px; */
    display: block;
    padding: 32px 0 0 0;
    color: #282c3f;
    /* text-transform: uppercase; */
    font-size: 16px;
    background: #fff;
    font-weight: 600;
    line-height: inherit;
    letter-spacing: .6px;
    height: 107px;
  }
  
  .shw-rside-60 .ui-sidebar-close,
  .shw-rside-50 .ui-sidebar-close,
  .shw-rside-68 .ui-sidebar-close,
  .shw-rside-75 .ui-sidebar-close,
  .shw-rside-90 .ui-sidebar-close,
  .shw-rside-95 .ui-sidebar-close,
  .shw-rside-100 .ui-sidebar-close,
  .shw-rside-30 .ui-sidebar-close,
  .shw-rside-40 .ui-sidebar-close {
    background: #fff;
    border-radius: 50%;
    -webkit-box-shadow: 0 10px 13px 0 #f0f0f0, 0 0 12px 0 #ececec;
    box-shadow: 0 10px 13px 0 #f0f0f0, 0 0 12px 0 #ececec;
    text-align: center;
    position: absolute;
    z-index: 9;
    top: 15px;
    right: 15px;
    height: 40px;
    width: 40px;
  }
  
  .ui-sidebar-close .pi {
    font-size: 24px;
    line-height: 40px;
  }
  
  .right-sidebar .rpanel-title span {
    float: right;
    cursor: pointer;
    font-size: 11px;
  }
  
  .right-sidebar .rpanel-title span:hover {
    color: #ffffff;
  }
  
  .right-sidebar .r-panel-body {
    padding: 20px 30px 0;
  }
  
  .right-sidebar .r-panel-body ul {
    margin: 0px;
    padding: 0px;
  }
  
  .right-sidebar .r-panel-body ul li {
    list-style: none;
    padding: 5px 0 0;
    margin-right: 54px;
  }
  
  .shw-rside {
    right: 0px;
    width: 240px;
    display: block;
  }
  
  .chatonline img {
    margin-right: 10px;
    float: left;
    width: 30px;
  }
  
  .chatonline li a {
    padding: 13px 0;
    float: left;
    width: 100%;
  }
  
  .chatonline li a span {
    color: #67757c;
  }
  
  .chatonline li a span small {
    display: block;
    font-size: 10px;
  }
  
  
  /*******************
                 Right side toggle
                *******************/
  
  ul#themecolors {
    display: block;
  }
  
  ul#themecolors li {
    display: inline-block;
  }
  
  ul#themecolors li:first-child {
    display: block;
  }
  
  ul#themecolors li a {
    width: 50px;
    height: 50px;
    display: inline-block;
    margin: 5px;
    color: transparent;
    position: relative;
  }
  
  ul#themecolors li a.working:before {
    content: "\f00c";
    font-family: "FontAwesome";
    font-size: 18px;
    line-height: 50px;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
    text-align: center;
  }
  
  .default-theme {
    background: #99abb4;
  }
  
  .green-theme {
    background: #00bfa5;
  }
  
  .yellow-theme {
    background: #ffb22b;
  }
  
  .red-theme {
    background: #ff5252;
  }
  
  .blue-theme {
    background: #398bf7;
  }
  
  .purple-theme {
    background: #7460ee;
  }
  
  .megna-theme {
    background: #56c0d8;
  }
  
  .default-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #99abb4), color-stop(99%, #99abb4));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #99abb4 23%, #99abb4 99%);
  }
  
  .green-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #00bfa5 23%, #00bfa5 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #00c292 23%, #00bfa5 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #00bfa5), color-stop(99%, #00bfa5));
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #00bfa5 23%, #00bfa5 99%);
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #00bfa5 23%, #00bfa5 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #00bfa5 23%, #00bfa5 99%);
  }
  
  .yellow-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ff5252 23%, #ff5252 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ff5252 23%, #ff5252 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #ef5350), color-stop(99%, #ef5350));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  }
  
  .blue-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #398bf7), color-stop(99%, #398bf7));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #398bf7 23%, #398bf7 99%);
  }
  
  .purple-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #7460ee), color-stop(99%, #7460ee));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #7460ee 23%, #7460ee 99%);
  }
  
  .megna-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #56c0d8), color-stop(99%, #56c0d8));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #56c0d8 23%, #56c0d8 99%);
  }
  
  .red-dark-theme {
    background: #263238;
    /* Old browsers */
    background: -moz-linear-gradient(left, #263238 0%, #263238 23%, #ff5252 23%, #ff5252 99%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #263238 0%, #263238 23%, #ff5252 23%, #ff5252 99%);
    /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, right top, from(#263238), color-stop(23%, #263238), color-stop(23%, #ef5350), color-stop(99%, #ef5350));
    background: -o-linear-gradient(left, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
    background: linear-gradient(to right, #263238 0%, #263238 23%, #ef5350 23%, #ef5350 99%);
  }
  
  
  /*******************
                 Footer
                *******************/
  
  .footer {
    bottom: 0;
    color: #67757c;
    left: 0px;
    padding: 17px 15px;
    position: absolute;
    right: 0;
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    background: #ffffff;
  }
  
  
  /*******************
                scrollbar
                *******************/
  
  .slimScrollBar {
    z-index: 10;
  }
  
  
  /*******************
                Megamenu
                ******************/
  
  .mega-dropdown {
    position: static;
    width: 100%;
  }
  
  .mega-dropdown .dropdown-menu {
    width: 100%;
    padding: 30px;
    margin-top: 0px;
  }
  
  .mega-dropdown ul {
    padding: 0px;
  }
  
  .mega-dropdown ul li {
    list-style: none;
  }
  
  .mega-dropdown .carousel-item .container {
    padding: 0px;
  }
  
  .mega-dropdown .nav-accordion .card {
    margin-bottom: 1px;
  }
  
  .mega-dropdown .nav-accordion .card-header {
    background: #ffffff;
  }
  
  .mega-dropdown .nav-accordion .card-header h5 {
    margin: 0px;
  }
  
  .mega-dropdown .nav-accordion .card-header h5 a {
    text-decoration: none;
    color: #67757c;
  }
  
  
  /*******************
                Wave Effects
                *******************/
  
  .waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
  }
  
  .waves-effect .waves-ripple {
    position: absolute;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.7s ease-out;
    -moz-transition: all 0.7s ease-out;
    -o-transition: all 0.7s ease-out;
    -ms-transition: all 0.7s ease-out;
    transition: all 0.7s ease-out;
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    -o-transition-property: -o-transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    -o-transition-property: transform, opacity;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    pointer-events: none;
  }
  
  .waves-effect.waves-light .waves-ripple {
    background-color: rgba(255, 255, 255, 0.45);
  }
  
  .waves-effect.waves-red .waves-ripple {
    background-color: rgba(244, 67, 54, 0.7);
  }
  
  .waves-effect.waves-yellow .waves-ripple {
    background-color: rgba(255, 235, 59, 0.7);
  }
  
  .waves-effect.waves-orange .waves-ripple {
    background-color: rgba(255, 152, 0, 0.7);
  }
  
  .waves-effect.waves-purple .waves-ripple {
    background-color: rgba(156, 39, 176, 0.7);
  }
  
  .waves-effect.waves-green .waves-ripple {
    background-color: rgba(76, 175, 80, 0.7);
  }
  
  .waves-effect.waves-teal .waves-ripple {
    background-color: rgba(0, 150, 136, 0.7);
  }
  
  .waves-notransition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
  }
  
  .waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
    /* -webkit-mask-image: none; */
  }
  
  .waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
  }
  
  .waves-input-wrapper .waves-button-input {
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
  }
  
  .waves-block {
    display: block;
  }
  
  
  /*******************
                List inline
                *******************/
  
  ul.list-inline li {
    display: inline-block;
    padding: 0 8px;
  }
  
  
  /*******************
                Other comon js for all pages
                *******************/
  
  .calendar-events {
    padding: 8px 10px;
    border: 1px solid #ffffff;
    cursor: move;
  }
  
  .calendar-events:hover {
    border: 1px dashed rgba(120, 130, 140, 0.13);
  }
  
  .calendar-events i {
    margin-right: 8px;
  }
  
  .single-column .side-mini-panel {
    display: none;
  }
  
  .single-column .page-wrapper {
    margin-left: 0px;
  }
  
  .profile-tab li a.nav-link,
  .customtab li a.nav-link {
    border: 0px;
    padding: 15px 20px;
    color: #67757c;
  }
  
  .profile-tab li a.nav-link.active,
  .customtab li a.nav-link.active {
    border-bottom: 2px solid #398bf7;
    color: #398bf7;
  }
  
  .profile-tab li a.nav-link:hover,
  .customtab li a.nav-link:hover {
    color: #398bf7;
  }
  
  .jqstooltip,
  .flotTip {
    width: auto;
    height: auto;
    background: #263238;
    color: #ffffff;
    padding: 5px 10px;
  }
  
  body .jqstooltip {
    border-color: transparent;
    border-radius: 60px;
  }
  
  
  /*******************
                Notification page Jquery toaster
                ******************/
  
  .jq-icon-info {
    background-color: #398bf7;
    color: #ffffff;
  }
  
  .jq-icon-success {
    background-color: #00bfa5;
    color: #ffffff;
  }
  
  .jq-icon-error {
    background-color: #ff5252;
    color: #ffffff;
  }
  
  .jq-icon-warning {
    background-color: #ffb22b;
    color: #ffffff;
  }
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  
  /*******************
                Pagination
                *******************/
  
  .pagination>li:first-child>a,
  .pagination>li:first-child>span {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  
  .pagination>li:last-child>a,
  .pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
  }
  
  .pagination>li>a,
  .pagination>li>span {
    color: #263238;
  }
  
  .pagination>li>a:hover,
  .pagination>li>span:hover,
  .pagination>li>a:focus,
  .pagination>li>span:focus {
    background-color: #e9edf2;
  }
  
  .pagination-split li {
    margin-left: 5px;
    float: left;
    /* display: inline-block; */
  }
  
  .pagination-split li:first-child {
    margin-left: 0;
  }
  
  .pagination-split li a {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  
  .pagination>.active>a,
  .pagination>.active>span,
  .pagination>.active>a:hover,
  .pagination>.active>span:hover,
  .pagination>.active>a:focus,
  .pagination>.active>span:focus {
    background-color: #398bf7;
    border-color: #398bf7;
  }
  
  .pager li>a,
  .pager li>span {
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    color: #263238;
  }
  
  
  /*******************
                Pagination
                ******************/
  
  .pagination-circle li.active a {
    background: #00bfa5;
  }
  
  .pagination-circle li a {
    width: 40px;
    height: 40px;
    background: #e9edf2;
    border: 0px;
    text-align: center;
    border-radius: 100%;
  }
  
  .pagination-circle li a:first-child,
  .pagination-circle li a:last-child {
    border-radius: 100%;
  }
  
  .pagination-circle li a:hover {
    background: #00bfa5;
    color: #ffffff;
  }
  
  .pagination-circle li.disabled a {
    background: #e9edf2;
    color: rgba(120, 130, 140, 0.13);
  }
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  
  /*******************
                Custom-select
                ******************/
  
  .custom-select {
    background: url("../images/custom-select.png") right 0.75rem center no-repeat;
  }
  
  .Arrow-select {
    background-image: url(../images/down.png);
    background-position: right 0;
    background-repeat: no-repeat;
  }
  
  
  /*******************
                textarea
                ******************/
  
  /* textarea {
    resize: none;
  } */
  
  
  /*******************
                Form-control
                ******************/
  
  .form-control {
    color: #28314b;
    min-height: 38px;
    display: initial;
    font-size: 14px;
  }
  
  .form-control-sm {
    min-height: 20px;
  }
  
  .form-control:disabled,
  .form-control[readonly] {
    opacity: 0.7;
  }
  
  .custom-control-input:focus~.custom-control-indicator {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .custom-control-input:checked~.custom-control-indicator {
    background-color: #00bfa5;
  }
  
  form label,
  label {
    /* margin-bottom: 0px; */
    font-size: 12px;
    color: #272c3e;
    font-weight: 500;
  }
  
  label .form-group {
    margin-bottom: 25px;
  }
  
  .form-horizontal label {
    margin-bottom: 0px;
    font-size: 12px;
    color: #6f778b;
  }
  
  .form-control-static {
    padding-top: 0px;
  }
  
  .form-bordered .form-group {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding-bottom: 20px;
  }
  
  
  /*******************
                Form Dropzone
                ******************/
  
  .dropzone {
    border: 1px dashed #b1b8bb;
  }
  
  .dropzone .dz-message {
    padding: 5% 0;
    margin: 0px;
  }
  
  
  /*******************
                Form Pickers
                ******************/
  
  .asColorPicker-dropdown {
    max-width: 260px;
  }
  
  .asColorPicker-trigger {
    position: absolute;
    top: 0;
    right: -35px;
    height: 38px;
    width: 37px;
    border: 0;
  }
  
  .asColorPicker-clear {
    display: none;
    position: absolute;
    top: 5px;
    right: 10px;
    text-decoration: none;
  }
  
  .daterangepicker td.active,
  .daterangepicker td.active:hover {
    background-color: #398bf7;
  }
  
  .datepicker table tr td.today,
  .datepicker table tr td.today.disabled,
  .datepicker table tr td.today.disabled:hover,
  .datepicker table tr td.today:hover {
    background: #398bf7;
    color: #ffffff;
  }
  
  .datepicker td,
  .datepicker th {
    padding: 5px 10px;
  }
  
  
  /*******************
                Form summernote
                ******************/
  
  .note-popover,
  .note-icon-caret {
    display: none;
  }
  
  .note-editor.note-frame {
    border: 1px solid #b1b8bb;
  }
  
  .note-editor.note-frame .panel-heading {
    padding: 6px 10px 10px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  
  .label {
    display: inline-block;
  }
  
  
  /*==============================================================
                Form addons page
                ============================================================== */
  
  
  /*Bootstrap select*/
  
  .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
  }
  
  .bootstrap-select .dropdown-menu li a {
    display: block;
    padding: 7px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #67757c;
    white-space: nowrap;
  }
  
  .bootstrap-select .dropdown-menu li a:hover,
  .bootstrap-select .dropdown-menu li a:focus {
    color: #398bf7;
    background: #e9edf2;
  }
  
  .bootstrap-select .show>.dropdown-menu {
    display: block;
  }
  
  .bootstrap-touchspin .input-group-btn-vertical>.btn {
    padding: 9px 10px;
  }
  
  .select2-container--default .select2-selection--single {
    border-color: #b1b8bb;
    height: 38px;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
  }
  
  .select2-container--default .select2-selection--multiple.select2-selection--alt .select2-selection__rendered {
    padding: 0;
  }
  
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 33px;
  }
  
  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    float: right;
    color: #ffffff;
    margin-right: 0px;
    margin-left: 4px;
  }
  
  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #398bf7;
    color: #ffffff;
    border-color: #398bf7;
  }
  
  .select2-container {
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    position: relative;
    vertical-align: middle;
    min-width: 100px;
    z-index: 9;
  }
  
  .input-form .btn {
    padding: 8px 12px;
  }
  
  
  /*==============================================================
                Form Material page
                 ============================================================== */
  
  
  /*Material inputs*/
  
  .form-material .form-group {
    overflow: hidden;
  }
  
  .form-material .form-control {
    background-color: transparent;
    background-position: center bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-size: 0 2px, 100% 1px;
    padding: 0;
    -webkit-transition: background 0s ease-out 0s;
    -o-transition: background 0s ease-out 0s;
    transition: background 0s ease-out 0s;
  }
  
  .form-material .form-control,
  .form-material .form-control.focus,
  .form-material .form-control:focus {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#e9edf2), to(#e9edf2));
    background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#e9edf2, #e9edf2);
    background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#e9edf2, #e9edf2);
    background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#e9edf2, #e9edf2);
    border: 0 none;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    float: none;
  }
  
  .form-material .form-control.focus,
  .form-material .form-control:focus {
    background-size: 100% 2px, 100% 1px;
    outline: 0 none;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  
  .form-control-line .form-group {
    overflow: hidden;
  }
  
  .form-control-line .form-control {
    border: 0px;
    border-radius: 0px;
    padding-left: 0px;
    border-bottom: 1px solid #f6f9ff;
  }
  
  .form-control-line .form-control:focus {
    border-bottom: 1px solid #398bf7;
  }
  
  
  /*******************
                Form validation error
                ******************/
  
  .error .help-block {
    color: #fb3a3a;
  }
  
  
  /*******************
                File Upload
                ******************/
  
  .fileupload {
    overflow: hidden;
    position: relative;
  }
  
  .fileupload input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  
  
  /*******************
                This is for the checkbox radio button and switch
                ******************/
  
  
  /* Radio Buttons
                   ========================================================================== */
  
  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  
  [type="radio"]:not(:checked)+label,
  [type="radio"]:checked+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    -webkit-transition: 0.28s ease;
    -o-transition: 0.28s ease;
    transition: 0.28s ease;
    /* webkit (konqueror) browsers */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  [type="radio"]+label:before,
  [type="radio"]+label:after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    -webkit-transition: 0.28s ease;
    -o-transition: 0.28s ease;
    transition: 0.28s ease;
  }
  
  
  /* Unchecked styles */
  
  [type="radio"]:not(:checked)+label:before,
  [type="radio"]:not(:checked)+label:after,
  [type="radio"]:checked+label:before,
  [type="radio"]:checked+label:after,
  [type="radio"].with-gap:checked+label:before,
  [type="radio"].with-gap:checked+label:after {
    border-radius: 50%;
  }
  
  [type="radio"]:not(:checked)+label:before,
  [type="radio"]:not(:checked)+label:after {
    border: 1px solid #b1b8bb;
  }
  
  [type="radio"]:not(:checked)+label:after {
    z-index: -1;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  
  
  /* Checked styles */
  
  [type="radio"]:checked+label:before {
    border: 2px solid transparent;
    /* -webkit-animation: ripple 0.2s linear forwards;
                  animation: ripple 0.2s linear forwards; */
  }
  
  [type="radio"]:checked+label:after,
  [type="radio"].with-gap:checked+label:before,
  [type="radio"].with-gap:checked+label:after {
    border: 2px solid #00bfa5;
  }
  
  [type="radio"]:checked+label:after,
  [type="radio"].with-gap:checked+label:after {
    background-color: #00bfa5;
    z-index: 0;
  }
  
  [type="radio"]:checked+label:after {
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
  
  
  /* Radio With gap */
  
  [type="radio"].with-gap:checked+label:after {
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }
  
  
  /* Focused styles */
  
  [type="radio"].tabbed:focus+label:before {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  
  /* Disabled Radio With gap */
  
  [type="radio"].with-gap:disabled:checked+label:before {
    border: 2px solid rgba(0, 0, 0, 0.26);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap:disabled:checked+label:after {
    border: none;
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  
  /* Disabled style */
  
  [type="radio"]:disabled:not(:checked)+label:before,
  [type="radio"]:disabled:checked+label:before {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.26);
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"]:disabled+label {
    color: rgba(0, 0, 0, 0.26);
  }
  
  [type="radio"]:disabled:not(:checked)+label:before {
    border-color: rgba(0, 0, 0, 0.26);
  }
  
  [type="radio"]:disabled:checked+label:after {
    background-color: rgba(0, 0, 0, 0.26);
    border-color: #bdbdbd;
  }
  
  
  /* Checkboxes
                   ========================================================================== */
  
  
  /* CUSTOM CSS CHECKBOXES */
  
  form p {
    margin-bottom: 10px;
    text-align: left;
  }
  
  form p:last-child {
    margin-bottom: 0;
    margin-top: 5px;
  }
  
  
  /* Remove default checkbox */
  
  [type="checkbox"]:not(:checked),
  [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
    opacity: 0;
  }
  
  
  /* [type="checkbox"] {
  
                } */
  
  [type="checkbox"]+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
  }
  
  [type="checkbox"]+label:before,
  [type="checkbox"]:not(.filled-in)+label:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    z-index: 0;
    border: 1px solid #b1b8bb;
    border-radius: 1px;
    margin-top: 2px;
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
  }
  
  [type="checkbox"]:not(.filled-in)+label:after {
    border: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
  
  [type="checkbox"]:not(:checked):disabled+label:before {
    border: none;
    background-color: rgba(0, 0, 0, 0.26);
  }
  
  [type="checkbox"].tabbed:focus+label:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    border: 0;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  [type="checkbox"]:checked+label:before {
    top: -4px;
    left: -5px;
    width: 12px;
    height: 22px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #00bfa5;
    border-bottom: 2px solid #00bfa5;
    -webkit-transform: rotate(40deg);
    -ms-transform: rotate(40deg);
    transform: rotate(40deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  [type="checkbox"]:checked:disabled+label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    border-bottom: 2px solid rgba(0, 0, 0, 0.26);
  }
  
  
  /* Indeterminate checkbox */
  
  [type="checkbox"]:indeterminate+label:before {
    top: -11px;
    left: -12px;
    width: 10px;
    height: 22px;
    border-top: none;
    border-left: none;
    border-right: 2px solid #26a69a;
    border-bottom: none;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  [type="checkbox"]:indeterminate:disabled+label:before {
    border-right: 2px solid rgba(0, 0, 0, 0.26);
    background-color: transparent;
  }
  
  [type="checkbox"].filled-in+label:after {
    border-radius: 2px;
  }
  
  [type="checkbox"].filled-in+label:before,
  [type="checkbox"].filled-in+label:after {
    content: " ";
    left: 0;
    position: absolute;
    /* .1s delay is for check animation */
    -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    -o-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s, height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
    z-index: 1;
  }
  
  [type="checkbox"].filled-in:not(:checked)+label:before {
    width: 0;
    height: 0;
    border: 3px solid transparent;
    left: 6px;
    top: 10px;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 20% 40%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  
  [type="checkbox"].filled-in:not(:checked)+label:after {
    height: 20px;
    width: 20px;
    background-color: transparent;
    border: 1px solid #b1b8bb;
    top: 0px;
    z-index: 0;
  }
  
  [type="checkbox"].filled-in:checked+label:before {
    top: 0;
    left: 1px;
    width: 8px;
    height: 13px;
    border-top: 2px solid transparent;
    border-left: 2px solid transparent;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    -webkit-transform: rotateZ(37deg);
    -ms-transform: rotate(37deg);
    transform: rotateZ(37deg);
    -webkit-transform-origin: 100% 100%;
    -ms-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  

  
  [type="checkbox"].filled-in.tabbed:focus+label:after {
    border-radius: 2px;
    border-color: #5a5a5a;
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  [type="checkbox"].filled-in.tabbed:checked:focus+label:after {
    border-radius: 2px;
    background-color: #00bfa5;
    border-color: #00bfa5;
  }
  
  [type="checkbox"].filled-in:disabled:not(:checked)+label:before {
    background-color: transparent;
    border: 2px solid transparent;
  }
  
  [type="checkbox"].filled-in:disabled:not(:checked)+label:after {
    border-color: transparent;
    background-color: #bdbdbd;
  }
  
  [type="checkbox"].filled-in:disabled:checked+label:before {
    background-color: transparent;
  }
  
  [type="checkbox"].filled-in:disabled:checked+label:after {
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }
  
  
  /* Switch
                   ========================================================================== */
  
  
  .switch label {
    cursor: pointer;
  }
  
  .switch label input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch label input[type="checkbox"]:checked+.lever {
    background-color: #84c7c1;
  }
  
  .switch label input[type="checkbox"]:checked+.lever:after {
    background-color: #26a69a;
    left: 24px;
  }
  
  .switch label .lever {
    content: " ";
    display: inline-block;
    position: relative;
    width: 40px;
    height: 15px;
    background-color: #818181;
    border-radius: 15px;
    -webkit-transition: background 0.3s ease;
    -o-transition: background 0.3s ease;
    transition: background 0.3s ease;
    vertical-align: middle;
    margin: 0 16px;
  }
  
  .switch label .lever:after {
    content: " ";
    position: absolute;
    display: inline-block;
    width: 21px;
    height: 21px;
    background-color: #f1f1f1;
    border-radius: 21px;
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
    left: -5px;
    top: -3px;
    -webkit-transition: left 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.1s ease;
    transition: left 0.3s ease, background 0.3s ease, -webkit-box-shadow 0.1s ease;
    -o-transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
    transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease, -webkit-box-shadow 0.1s ease;
  }
  
  input[type="checkbox"]:checked:not(:disabled)~.lever:active::after,
  input[type="checkbox"]:checked:not(:disabled).tabbed:focus~.lever::after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 166, 154, 0.1);
  }
  
  input[type="checkbox"]:not(:disabled)~.lever:active:after,
  input[type="checkbox"]:not(:disabled).tabbed:focus~.lever::after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.08);
  }
  
  .switch input[type="checkbox"][disabled]+.lever {
    cursor: default;
  }
  
  .switch label input[type="checkbox"][disabled]+.lever:after,
  .switch label input[type="checkbox"][disabled]:checked+.lever:after {
    background-color: #bdbdbd;
  }
  
  .scale-up {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px;
  }
  
  .scale-up-left {
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    display: inline-block;
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px;
  }
  
  .show>.scale-up {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: right 0px;
    -ms-transform-origin: right 0px;
    transform-origin: right 0px;
  }
  
  .show>.scale-up-left {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: left 0px;
    -ms-transform-origin: left 0px;
    transform-origin: left 0px;
  }
  
  
  /*panels*/
  
  
  /* .card {
                  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
                  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
                  border-radius: 0px;
                } */
  
  .well,
  pre {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  }
  
  .page-titles .justify-content-end:last-child .d-flex {
    margin-right: 10px;
  }
  
  .btn-circle.right-side-toggle {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 25px;
    z-index: 10;
  }
  
  
  /*Radio button*/
  
  @-webkit-keyframes ripple {
    0% {
      -webkit-box-shadow: 0px 0px 0px 1px transparent;
      box-shadow: 0px 0px 0px 1px transparent;
    }
  
    50% {
      -webkit-box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
  
    100% {
      -webkit-box-shadow: 0px 0px 0px 15px transparent;
      box-shadow: 0px 0px 0px 15px transparent;
    }
  }
  
  @keyframes ripple {
    0% {
      -webkit-box-shadow: 0px 0px 0px 1px transparent;
      box-shadow: 0px 0px 0px 1px transparent;
    }
  
    50% {
      -webkit-box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 0px 15px rgba(0, 0, 0, 0.1);
    }
  
    100% {
      -webkit-box-shadow: 0px 0px 0px 15px transparent;
      box-shadow: 0px 0px 0px 15px transparent;
    }
  }
  
  
  /*Floating label*/
  
  
  /*Bootstrap Select*/
  
  .bootstrap-select.btn-group .dropdown-menu {
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  }
  
  .demo-checkbox label,
  .demo-radio-button label {
    min-width: 200px;
    margin-bottom: 10px;
  }
  
  .demo-swtich .demo-switch-title,
  .demo-swtich .switch {
    width: 150px;
    margin-bottom: 10px;
    display: inline-block;
  }
  
  [type="checkbox"]+label {
    padding-left: 26px;
    height: 25px;
    line-height: 21px;
    font-weight: normal;
  }
  
  [type="checkbox"]:checked+label:before {
    top: -4px;
    left: -2px;
    width: 11px;
    height: 19px;
  }
  
  [type="checkbox"]:checked.chk-col-red+label:before {
    border-right: 2px solid #fb3a3a;
    border-bottom: 2px solid #fb3a3a;
  }
  
  [type="checkbox"]:checked.chk-col-pink+label:before {
    border-right: 2px solid #e91e63;
    border-bottom: 2px solid #e91e63;
  }
  
  [type="checkbox"]:checked.chk-col-purple+label:before {
    border-right: 2px solid #7460ee;
    border-bottom: 2px solid #7460ee;
  }
  
  [type="checkbox"]:checked.chk-col-deep-purple+label:before {
    border-right: 2px solid #673ab7;
    border-bottom: 2px solid #673ab7;
  }
  
  [type="checkbox"]:checked.chk-col-indigo+label:before {
    border-right: 2px solid #3f51b5;
    border-bottom: 2px solid #3f51b5;
  }
  
  [type="checkbox"]:checked.chk-col-blue+label:before {
    border-right: 2px solid #00bfa5;
    border-bottom: 2px solid #00bfa5;
  }
  
  [type="checkbox"]:checked.chk-col-light-blue+label:before {
    border-right: 2px solid #03a9f4;
    border-bottom: 2px solid #03a9f4;
  }
  
  [type="checkbox"]:checked.chk-col-cyan+label:before {
    border-right: 2px solid #00bcd4;
    border-bottom: 2px solid #00bcd4;
  }
  
  [type="checkbox"]:checked.chk-col-teal+label:before {
    border-right: 2px solid #009688;
    border-bottom: 2px solid #009688;
  }
  
  [type="checkbox"]:checked.chk-col-green+label:before {
    border-right: 2px solid #00bfa5;
    border-bottom: 2px solid #00bfa5;
  }
  
  [type="checkbox"]:checked.chk-col-light-green+label:before {
    border-right: 2px solid #8bc34a;
    border-bottom: 2px solid #8bc34a;
  }
  
  [type="checkbox"]:checked.chk-col-lime+label:before {
    border-right: 2px solid #cddc39;
    border-bottom: 2px solid #cddc39;
  }
  
  [type="checkbox"]:checked.chk-col-yellow+label:before {
    border-right: 2px solid #ffe821;
    border-bottom: 2px solid #ffe821;
  }
  
  [type="checkbox"]:checked.chk-col-amber+label:before {
    border-right: 2px solid #ffc107;
    border-bottom: 2px solid #ffc107;
  }
  
  [type="checkbox"]:checked.chk-col-orange+label:before {
    border-right: 2px solid #ff9800;
    border-bottom: 2px solid #ff9800;
  }
  
  [type="checkbox"]:checked.chk-col-deep-orange+label:before {
    border-right: 2px solid #ff5722;
    border-bottom: 2px solid #ff5722;
  }
  
  [type="checkbox"]:checked.chk-col-brown+label:before {
    border-right: 2px solid #795548;
    border-bottom: 2px solid #795548;
  }
  
  [type="checkbox"]:checked.chk-col-grey+label:before {
    border-right: 2px solid #9e9e9e;
    border-bottom: 2px solid #9e9e9e;
  }
  
  [type="checkbox"]:checked.chk-col-blue-grey+label:before {
    border-right: 2px solid #607d8b;
    border-bottom: 2px solid #607d8b;
  }
  
  [type="checkbox"]:checked.chk-col-black+label:before {
    border-right: 2px solid #000000;
    border-bottom: 2px solid #000000;
  }
  
  [type="checkbox"]:checked.chk-col-white+label:before {
    border-right: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
  }
  
  [type="checkbox"].filled-in:checked+label:after {
    top: 0;
    width: 20px;
    height: 20px;
    z-index: 0;
  }
  
  [type="checkbox"].filled-in:checked+label:before {
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-red+label:after {
    border: 2px solid #fb3a3a;
    background-color: #fb3a3a;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-pink+label:after {
    border: 2px solid #e91e63;
    background-color: #e91e63;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-purple+label:after {
    border: 2px solid #7460ee;
    background-color: #7460ee;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-deep-purple+label:after {
    border: 2px solid #673ab7;
    background-color: #673ab7;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-indigo+label:after {
    border: 2px solid #3f51b5;
    background-color: #3f51b5;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-blue+label:after {
    border: 2px solid #00bfa5;
    background-color: #00bfa5;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-light-blue+label:after {
    border: 2px solid #03a9f4;
    background-color: #03a9f4;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-cyan+label:after {
    border: 2px solid #00bcd4;
    background-color: #00bcd4;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-teal+label:after {
    border: 2px solid #009688;
    background-color: #009688;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-green+label:after {
    border: 2px solid #00bfa5;
    background-color: #00bfa5;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-light-green+label:after {
    border: 2px solid #8bc34a;
    background-color: #8bc34a;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-lime+label:after {
    border: 2px solid #cddc39;
    background-color: #cddc39;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-yellow+label:after {
    border: 2px solid #ffe821;
    background-color: #ffe821;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-amber+label:after {
    border: 2px solid #ffc107;
    background-color: #ffc107;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-orange+label:after {
    border: 2px solid #ff9800;
    background-color: #ff9800;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-deep-orange+label:after {
    border: 2px solid #ff5722;
    background-color: #ff5722;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-brown+label:after {
    border: 2px solid #795548;
    background-color: #795548;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-grey+label:after {
    border: 2px solid #9e9e9e;
    background-color: #9e9e9e;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-blue-grey+label:after {
    border: 2px solid #607d8b;
    background-color: #607d8b;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-black+label:after {
    border: 2px solid #000000;
    background-color: #000000;
  }
  
  [type="checkbox"].filled-in:checked.chk-col-white+label:after {
    border: 2px solid #ffffff;
    background-color: #ffffff;
  }
  
  [type="radio"]:not(:checked)+label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-weight: normal;
  }
  
  [type="radio"]:checked+label {
    padding-left: 26px;
    height: 25px;
    line-height: 25px;
    font-weight: normal;
  }
  
  [type="radio"].radio-col-red:checked+label:after {
    background-color: #fb3a3a;
    border-color: #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-pink:checked+label:after {
    background-color: #e91e63;
    border-color: #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-purple:checked+label:after {
    background-color: #7460ee;
    border-color: #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-deep-purple:checked+label:after {
    background-color: #673ab7;
    border-color: #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-indigo:checked+label:after {
    background-color: #3f51b5;
    border-color: #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-blue:checked+label:after {
    background-color: #00bfa5;
    border-color: #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-light-blue:checked+label:after {
    background-color: #03a9f4;
    border-color: #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-cyan:checked+label:after {
    background-color: #00bfa5;
    border-color: #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-teal:checked+label:after {
    background-color: #009688;
    border-color: #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-green:checked+label:after {
    background-color: #26c6da;
    border-color: #26c6da;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-light-green:checked+label:after {
    background-color: #8bc34a;
    border-color: #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-lime:checked+label:after {
    background-color: #cddc39;
    border-color: #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-yellow:checked+label:after {
    background-color: #ffe821;
    border-color: #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-amber:checked+label:after {
    background-color: #ffc107;
    border-color: #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-orange:checked+label:after {
    background-color: #ff9800;
    border-color: #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-deep-orange:checked+label:after {
    background-color: #ff5722;
    border-color: #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-brown:checked+label:after {
    background-color: #795548;
    border-color: #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-grey:checked+label:after {
    background-color: #9e9e9e;
    border-color: #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-blue-grey:checked+label:after {
    background-color: #607d8b;
    border-color: #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-black:checked+label:after {
    background-color: #000000;
    border-color: #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].radio-col-white:checked+label:after {
    background-color: #ffffff;
    border-color: #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-red:checked+label:before {
    border: 2px solid #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-red:checked+label:after {
    background-color: #fb3a3a;
    border: 2px solid #fb3a3a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-pink:checked+label:before {
    border: 2px solid #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-pink:checked+label:after {
    background-color: #e91e63;
    border: 2px solid #e91e63;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-purple:checked+label:before {
    border: 2px solid #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-purple:checked+label:after {
    background-color: #7460ee;
    border: 2px solid #7460ee;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-deep-purple:checked+label:before {
    border: 2px solid #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-deep-purple:checked+label:after {
    background-color: #673ab7;
    border: 2px solid #673ab7;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-indigo:checked+label:before {
    border: 2px solid #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-indigo:checked+label:after {
    background-color: #3f51b5;
    border: 2px solid #3f51b5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-blue:checked+label:before {
    border: 2px solid #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-blue:checked+label:after {
    background-color: #00bfa5;
    border: 2px solid #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-light-blue:checked+label:before {
    border: 2px solid #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-light-blue:checked+label:after {
    background-color: #03a9f4;
    border: 2px solid #03a9f4;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-cyan:checked+label:before {
    border: 2px solid #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-cyan:checked+label:after {
    background-color: #00bfa5;
    border: 2px solid #00bfa5;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-teal:checked+label:before {
    border: 2px solid #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-teal:checked+label:after {
    background-color: #009688;
    border: 2px solid #009688;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-green:checked+label:before {
    border: 2px solid #26c6da;
    /* -webkit-animation: ripple 0.2s linear forwards;
                  animation: ripple 0.2s linear forwards; */
  }
  
  [type="radio"].with-gap.radio-col-green:checked+label:after {
    background-color: #26c6da;
    border: 2px solid #26c6da;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-light-green:checked+label:before {
    border: 2px solid #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-light-green:checked+label:after {
    background-color: #8bc34a;
    border: 2px solid #8bc34a;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-lime:checked+label:before {
    border: 2px solid #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-lime:checked+label:after {
    background-color: #cddc39;
    border: 2px solid #cddc39;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-yellow:checked+label:before {
    border: 2px solid #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-yellow:checked+label:after {
    background-color: #ffe821;
    border: 2px solid #ffe821;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-amber:checked+label:before {
    border: 2px solid #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-amber:checked+label:after {
    background-color: #ffc107;
    border: 2px solid #ffc107;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-orange:checked+label:before {
    border: 2px solid #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-orange:checked+label:after {
    background-color: #ff9800;
    border: 2px solid #ff9800;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-deep-orange:checked+label:before {
    border: 2px solid #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-deep-orange:checked+label:after {
    background-color: #ff5722;
    border: 2px solid #ff5722;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-brown:checked+label:before {
    border: 2px solid #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-brown:checked+label:after {
    background-color: #795548;
    border: 2px solid #795548;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-grey:checked+label:before {
    border: 2px solid #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-grey:checked+label:after {
    background-color: #9e9e9e;
    border: 2px solid #9e9e9e;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-blue-grey:checked+label:before {
    border: 2px solid #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-blue-grey:checked+label:after {
    background-color: #607d8b;
    border: 2px solid #607d8b;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-black:checked+label:before {
    border: 2px solid #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-black:checked+label:after {
    background-color: #000000;
    border: 2px solid #000000;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-white:checked+label:before {
    border: 2px solid #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  [type="radio"].with-gap.radio-col-white:checked+label:after {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    -webkit-animation: ripple 0.2s linear forwards;
    animation: ripple 0.2s linear forwards;
  }
  
  .switch label {
    font-weight: normal;
    font-size: 13px;
  }
  
  .switch label .lever {
    margin: 0 14px;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-red:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(251, 58, 58, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-red {
    background-color: rgba(251, 58, 58, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-red:after {
    background-color: #fb3a3a;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-pink:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(233, 30, 99, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-pink {
    background-color: rgba(233, 30, 99, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-pink:after {
    background-color: #e91e63;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-purple:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(116, 96, 238, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-purple {
    background-color: rgba(116, 96, 238, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-purple:after {
    background-color: #7460ee;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-deep-purple:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(103, 58, 183, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-deep-purple {
    background-color: rgba(103, 58, 183, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-deep-purple:after {
    background-color: #673ab7;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-indigo:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(63, 81, 181, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-indigo {
    background-color: rgba(63, 81, 181, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-indigo:after {
    background-color: #3f51b5;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-blue:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(2, 190, 201, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-blue {
    background-color: rgba(2, 190, 201, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-blue:after {
    background-color: #00bfa5;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-light-blue:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(3, 169, 244, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-light-blue {
    background-color: rgba(3, 169, 244, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-light-blue:after {
    background-color: #03a9f4;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-cyan:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 188, 212, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-cyan {
    background-color: rgba(0, 188, 212, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-cyan:after {
    background-color: #00bcd4;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-teal:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 150, 136, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-teal {
    background-color: rgba(0, 150, 136, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-teal:after {
    background-color: #009688;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-green:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(38, 198, 218, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-green {
    background-color: rgba(38, 198, 218, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-green:after {
    background-color: #26c6da;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-light-green:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(139, 195, 74, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-light-green {
    background-color: rgba(139, 195, 74, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-light-green:after {
    background-color: #8bc34a;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-lime:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(205, 220, 57, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-lime {
    background-color: rgba(205, 220, 57, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-lime:after {
    background-color: #cddc39;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-yellow:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 232, 33, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-yellow {
    background-color: rgba(255, 232, 33, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-yellow:after {
    background-color: #ffe821;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-amber:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 193, 7, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-amber {
    background-color: rgba(255, 193, 7, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-amber:after {
    background-color: #ffc107;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-orange:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 152, 0, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-orange {
    background-color: rgba(255, 152, 0, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-orange:after {
    background-color: #ff9800;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-deep-orange:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 87, 34, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-deep-orange {
    background-color: rgba(255, 87, 34, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-deep-orange:after {
    background-color: #ff5722;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-brown:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(121, 85, 72, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-brown {
    background-color: rgba(121, 85, 72, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-brown:after {
    background-color: #795548;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-grey:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(158, 158, 158, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-grey {
    background-color: rgba(158, 158, 158, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-grey:after {
    background-color: #9e9e9e;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-blue-grey:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(96, 125, 139, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-blue-grey {
    background-color: rgba(96, 125, 139, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-blue-grey:after {
    background-color: #607d8b;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-black:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-black {
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-black:after {
    background-color: #000000;
  }
  
  .switch label input[type="checkbox"]:checked:not(:disabled)~.lever.switch-col-white:active:after {
    -webkit-box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
    box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(255, 255, 255, 0.1);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-white {
    background-color: rgba(255, 255, 255, 0.5);
  }
  
  .switch label input[type="checkbox"]:checked+.lever.switch-col-white:after {
    background-color: #ffffff;
  }
  
  
  /*Form Validation*/
  
  .help-block ul {
    padding: 0px;
    margin: 0px;
  }
  
  .help-block ul li {
    list-style: none;
  }
  
  .error .form-control {
    border-color: #ff5252;
  }
  
  .validate .form-control {
    border-color: #00bfa5;
  }
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  .nowrap {
    white-space: nowrap;
  }
  
  
  /*
                Template Name: Admin pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*Theme Colors*/
  
  
  /*bootstrap Color*/
  
  
  /*Light colors*/
  
  
  /*Normal Color*/
  
  
  /*Extra Variable*/
  
  
  /* perfect-scrollbar v0.7.1 */
  
  .ps {
    -ms-touch-action: auto;
    touch-action: auto;
    overflow: hidden;
    -ms-overflow-style: none;
  }
  
  @supports (-ms-overflow-style: none) {
    .ps {
      overflow: auto;
    }
  }
  
  @media screen and (-ms-high-contrast: active),
  (-ms-high-contrast: none) {
    .ps {
      overflow: auto;
    }
  }
  
  .ps.ps--active-x>.ps__scrollbar-x-rail,
  .ps.ps--active-y>.ps__scrollbar-y-rail {
    display: block;
    background-color: transparent;
  }
  
  .ps.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
    background-color: #eee;
    opacity: 0.9;
  }
  
  .ps.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    background-color: transparent;
    width: 8px;
  }
  
  .ps>.ps__scrollbar-x-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    bottom: 0px;
    /* there must be 'bottom' for ps__scrollbar-x-rail */
    height: 6px;
  }
  
  .ps>.ps__scrollbar-x-rail>.ps__scrollbar-x {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(255, 255, 255, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    bottom: 2px;
    /* there must be 'bottom' for ps__scrollbar-x */
    height: 6px;
  }
  
  .ps>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x,
  .ps>.ps__scrollbar-x-rail:active>.ps__scrollbar-x {
    height: 6px;
  }
  
  .ps>.ps__scrollbar-y-rail {
    display: none;
    position: absolute;
    /* please don't change 'position' */
    opacity: 0;
    right: 0;
    /* there must be 'right' for ps__scrollbar-y-rail */
    width: 6px;
  }
  
  .ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    position: absolute;
    /* please don't change 'position' */
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    right: 2px;
    /* there must be 'right' for ps__scrollbar-y */
    width: 6px;
  }
  
  .ps>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y,
  .ps>.ps__scrollbar-y-rail:active>.ps__scrollbar-y {
    width: 6px;
  }
  
  .ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail {
    opacity: 0.9;
  }
  
  .ps:hover.ps--in-scrolling.ps--x>.ps__scrollbar-x-rail>.ps__scrollbar-x {
    height: 6px;
  }
  
  .ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    width: 6px;
  }
  
  .ps:hover>.ps__scrollbar-x-rail,
  .ps:hover>.ps__scrollbar-y-rail {
    opacity: 0.6;
  }
  
  
  /* .ps:hover>.ps__scrollbar-x-rail:hover>.ps__scrollbar-x {}
  
                .ps:hover>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {} */
  
  #slimtest3.ps .ps__scrollbar-y-rail,
  .mini-nav.ps .ps__scrollbar-y-rail {
    left: 3px;
    right: auto;
  }
  
  .mini-nav.ps .sidebar-menu.ps .ps__scrollbar-y-rail {
    left: auto;
    right: 3px;
  }
  
  #slimtest4.ps>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    background-color: #398bf7;
  }
  
  #slimtest4.ps:hover.ps--in-scrolling.ps--y>.ps__scrollbar-y-rail>.ps__scrollbar-y {
    background-color: #398bf7;
  }
  
  #slimtest4.ps:hover>.ps__scrollbar-y-rail:hover>.ps__scrollbar-y {
    background-color: #398bf7;
  }
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  
  /*******************/
  
  
  /*User mail widgets*/
  
  
  /*******************/
  
  .topbar .top-navbar .mailbox {
    width: 300px;
  }
  
  .topbar .top-navbar .mailbox ul {
    padding: 0px;
  }
  
  .topbar .top-navbar .mailbox ul li {
    list-style: none;
  }
  
  .mailbox ul li .drop-title {
    font-weight: 500;
    padding: 11px 20px 15px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  
  .mailbox ul li .nav-link {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding-top: 15px;
  }
  
  .mailbox .message-center {
    height: 200px;
    overflow: auto;
    position: relative;
  }
  
  .mailbox .message-center a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px;
  }
  
  .mailbox .message-center a:hover {
    background: #e9edf2;
  }
  
  .mailbox .message-center a div {
    white-space: normal;
  }
  
  .mailbox .message-center a .user-img {
    width: 40px;
    position: relative;
    display: inline-block;
    margin: 0 10px 15px 0;
  }
  
  .mailbox .message-center a .user-img img {
    width: 100%;
  }
  
  .mailbox .message-center a .user-img .profile-status {
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 30px;
    position: absolute;
    top: 1px;
    width: 10px;
  }
  
  .mailbox .message-center a .user-img .online {
    background: #06d79c;
  }
  
  .mailbox .message-center a .user-img .busy {
    background: #ff5252;
  }
  
  .mailbox .message-center a .user-img .away {
    background: #ffb22b;
  }
  
  .mailbox .message-center a .user-img .offline {
    background: #ffb22b;
  }
  
  .mailbox .message-center a .mail-contnet {
    display: inline-block;
    width: 75%;
    vertical-align: middle;
  }
  
  .mailbox .message-center a .mail-contnet h5 {
    margin: 5px 0px 0;
  }
  
  .mailbox .message-center a .mail-contnet .mail-desc,
  .mailbox .message-center a .mail-contnet .time {
    font-size: 12px;
    display: block;
    margin: 1px 0;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #67757c;
    white-space: nowrap;
  }
  
  
  /*******************/
  
  
  /*Weather*/
  
  
  /*******************/
  
  .city-weather-days {
    margin: 0px;
  }
  
  .city-weather-days li {
    text-align: center;
    padding: 15px 0;
  }
  
  .city-weather-days li span {
    display: block;
    padding: 10px 0 0;
    color: #99abb4;
  }
  
  .city-weather-days li i {
    display: block;
    font-size: 20px;
    color: #398bf7;
  }
  
  .city-weather-days li h3 {
    font-weight: 300;
    margin-top: 5px;
  }
  
  
  /*Weather small*/
  
  .weather-small h1 {
    line-height: 30px;
  }
  
  .weather-small sup {
    font-size: 60%;
  }
  
  
  /*******************/
  
  
  /*Comment widgets*/
  
  
  /*******************/
  
  .comment-widgets {
    position: relative;
    margin-bottom: 10px;
  }
  
  .comment-widgets .comment-row {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    padding: 15px;
  }
  
  .comment-widgets .comment-row:last-child {
    border-bottom: 0px;
  }
  
  .comment-widgets .comment-row:hover,
  .comment-widgets .comment-row.active {
    background: rgba(0, 0, 0, 0.02);
  }
  
  .comment-text {
    padding: 15px 15px 15px 20px;
    width: 80%;
  }
  
  .comment-text:hover .comment-footer .action-icons,
  .comment-text.active .comment-footer .action-icons {
    visibility: visible;
  }
  
  .comment-text p {
    max-height: 50px;
    width: 100%;
    overflow: hidden;
  }
  
  .comment-footer .action-icons {
    visibility: hidden;
  }
  
  .comment-footer .action-icons a {
    padding-left: 7px;
    vertical-align: middle;
    color: #99abb4;
  }
  
  .comment-footer .action-icons a:hover,
  .comment-footer .action-icons a.active {
    color: #398bf7;
  }
  
  
  /*******************/
  
  
  /*To do widgets*/
  
  
  /*******************/
  
  .todo-list li {
    border: 0px;
    margin-bottom: 0px;
    padding: 20px 15px 15px 0px;
  }
  
  .todo-list li .checkbox {
    width: 100%;
  }
  
  .todo-list li .checkbox label {
    font-weight: 400;
    color: #455a64;
    width: 100%;
  }
  
  .todo-list li:last-child {
    border-bottom: 0px;
  }
  
  .todo-list li .assignedto {
    padding: 0px 0 0 27px;
    margin: 0px;
  }
  
  .todo-list li .assignedto li {
    list-style: none;
    padding: 0px;
    display: inline-block;
    border: 0px;
    margin-right: 2px;
  }
  
  .todo-list li .assignedto li img {
    width: 30px;
    border-radius: 100%;
  }
  
  .todo-list li .item-date {
    padding-left: 25px;
    font-size: 12px;
    display: inline-block;
  }
  
  .list-task .task-done span {
    text-decoration: line-through;
  }
  
  
  /*******************/
  
  
  /*Chat widget*/
  
  
  /*******************/
  
  .chat-list {
    margin: 0px;
    padding: 0px;
  }
  
  .chat-list li {
    list-style: none;
    margin-top: 30px;
  }
  
  .chat-list li .chat-img {
    display: inline-block;
    width: 45px;
    vertical-align: top;
  }
  
  .chat-list li .chat-img img {
    width: 45px;
    border-radius: 100%;
  }
  
  .chat-list li .chat-content {
    width: calc(100% - 140px);
    display: inline-block;
    padding-left: 15px;
  }
  
  .chat-list li .chat-content h5 {
    color: #99abb4;
  }
  
  .chat-list li .chat-content .box {
    display: inline-block;
    margin-bottom: 10px;
    color: #263238;
  }
  
  .chat-list li .chat-time {
    display: inline-block;
    text-align: right;
    width: 80px;
    font-size: 13px;
    color: #99abb4;
  }
  
  .chat-list li.odd .chat-content {
    text-align: right;
    width: calc(100% - 90px);
  }
  
  .chat-list li.odd .box {
    clear: both;
  }
  
  .chat-list li.odd+.odd {
    margin-top: 0px;
  }
  
  .chat-list li.reverse {
    text-align: right;
  }
  
  .chat-list li.reverse .chat-time {
    text-align: left;
  }
  
  .chat-list li.reverse .chat-content {
    padding-left: 0px;
    padding-right: 15px;
  }
  
  
  /*******************/
  
  
  /*Chat widget*/
  
  
  /*******************/
  
  .message-box ul li .drop-title {
    font-weight: 500;
    padding: 11px 20px 15px;
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
  }
  
  .message-box ul li .nav-link {
    border-top: 1px solid rgba(120, 130, 140, 0.13);
    padding-top: 15px;
  }
  
  .message-box .message-widget {
    position: relative;
  }
  
  .message-box .message-widget a {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    display: block;
    text-decoration: none;
    padding: 9px 15px;
  }
  
  .message-box .message-widget a:hover {
    background: #e9edf2;
  }
  
  .message-box .message-widget a:last-child {
    border-bottom: 0px;
  }
  
  .message-box .message-widget a div {
    white-space: normal;
  }
  
  .message-box .message-widget a .user-img {
    width: 45px;
    position: relative;
    display: inline-block;
    margin: 0 10px 15px 0;
  }
  
  .message-box .message-widget a .user-img img {
    width: 100%;
  }
  
  .message-box .message-widget a .user-img .profile-status {
    border: 2px solid #ffffff;
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    left: 33px;
    position: absolute;
    top: -1px;
    width: 10px;
  }
  
  .message-box .message-widget a .user-img .online {
    background: #00bfa5;
  }
  
  .message-box .message-widget a .user-img .busy {
    background: #ff5252;
  }
  
  .message-box .message-widget a .user-img .away {
    background: #ffb22b;
  }
  
  .message-box .message-widget a .user-img .offline {
    background: #ffb22b;
  }
  
  .message-box .message-widget a .mail-contnet {
    display: inline-block;
    width: 70%;
    vertical-align: middle;
  }
  
  .message-box .message-widget a .mail-contnet h5 {
    margin: 5px 0px 0;
  }
  
  .message-box .message-widget a .mail-contnet .mail-desc,
  .message-box .message-widget a .mail-contnet .time {
    font-size: 12px;
    display: block;
    margin: 1px 0;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #67757c;
    white-space: nowrap;
  }
  
  #savedialog .ui-dialog .ui-dialog-titlebar-close {
    display: none;
  }
  
  #savedialog .ui-dialog.ui-widget .ui-dialog-content {
    padding: 0px;
  }
  
  .leavesidebar {
    width: 40%;
  }
  
  #balancesidebartab .ui-tabview .ui-tabview-nav li a {
    width: 100%;
    padding: 21.5px;
  }
  
  #balancesidebartab .ui-tabview .ui-tabview-nav li.ui-tabview-selected a {
    color: #ffffff;
  }
  
  #balancesidebartab .ui-tabview .ui-tabview-nav>li.ui-state-default {
    width: 49.7%;
    background: #fff;
    /* box-shadow: 0px 0px 1px 1px #bdbdbd; */
    color: #333;
    font-size: 16px;
    text-align: center;
    border: 1px solid #e9edf2;
    border-radius: 0px;
    margin: 0;
  }
  
  #balancesidebartab .ui-tabview .ui-tabview-nav>li.ui-tabview-selected {
    width: 49.7%;
    background: #1ebfa5;
    /* box-shadow: 0px 0px 1px 1px #bdbdbd; */
    border: 1px solid #1ebfa5;
  }
  
  #visibility .mat-mdc-radio-button {
    display: block;
  }
  
  
  /*******************/
  
  
  /*Calendar widget*/
  
  
  /*******************/
  
  .calendar {
    float: left;
    margin-bottom: 0px;
  }
  
  .fc-view {
    margin-top: 30px;
  }
  
  .none-border .modal-footer {
    border-top: none;
  }
  
  .app-attendance-search .ui-autocomplete .ui-autocomplete-input {
    width: 100%;
    padding: 17px !important;
  }
  
  .test {
    height: 30px;
  }
  
  .ui-widget .fc-event {
    cursor: pointer;
  }
  
  .fc-event-container {
    text-align: center;
    /* display: block; */
  }
  
  .late {
    /* background: linear-gradient(red, #00bfa5 40%) ; */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2ac9d8+0,2ac9d8+0,2ac9d8+0,4db6ac+0,7ee1e8+0,2ac9d8+0,20b9ca+0,20b9ca+0,20b9ca+0,e53573+0,20b9ca+31,20b9ca+31,20b9ca+100&0.9+0,0.9+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    /* background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1MzU3MyIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICAgIDxzdG9wIG9mZnNldD0iMzElIiBzdG9wLWNvbG9yPSIjMjBiOWNhIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogICAgPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMjBiOWNhIiBzdG9wLW9wYWNpdHk9IjAuOSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+) ;
              background: -moz-linear-gradient(top, rgba(229,53,115,0.9) 0%, rgba(32,185,202,0.9) 31%, rgba(32,185,202,0.9) 100%)  ;
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(229,53,115,0.9)), color-stop(31%,rgba(32,185,202,0.9)), color-stop(100%,rgba(32,185,202,0.9)))  ;
              background: -webkit-linear-gradient(top, rgba(229,53,115,0.9) 0%,rgba(32,185,202,0.9) 31%,rgba(32,185,202,0.9) 100%)  ;
              background: -o-linear-gradient(top, rgba(229,53,115,0.9) 0%,rgba(32,185,202,0.9) 31%,rgba(32,185,202,0.9) 100%)  ;
              background: -ms-linear-gradient(top, rgba(229,53,115,0.9) 0%,rgba(32,185,202,0.9) 31%,rgba(32,185,202,0.9) 100%)  ;
              background: linear-gradient(to bottom, rgba(229,53,115,0.9) 0%,rgba(32,185,202,0.9) 31%,rgba(32,185,202,0.9) 100%)  ;
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6e53573', endColorstr='#e620b9ca',GradientType=0 )  ;  */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e53573+0,e53573+0,20b9ca+30 */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e53573+0,e53573+0,54ab5d+30 */
    background: rgb(229, 53, 115);
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2U1MzU3MyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZTUzNTczIiBzdG9wLW9wYWNpdHk9IjEiLz4KICAgIDxzdG9wIG9mZnNldD0iMzAlIiBzdG9wLWNvbG9yPSIjNTRhYjVkIiBzdG9wLW9wYWNpdHk9IjEiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==);
    background: -moz-linear-gradient(top, rgba(229, 53, 115, 1) 0%, rgba(229, 53, 115, 1) 0%, rgba(84, 171, 93, 1) 30%);
    /* FF3.6-15 */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(229, 53, 115, 1)), color-stop(0%, rgba(229, 53, 115, 1)), color-stop(30%, rgba(84, 171, 93, 1)));
    /* Chrome4-9,Safari4-5 */
    background: -webkit-linear-gradient(top, rgba(229, 53, 115, 1) 0%, rgba(229, 53, 115, 1) 0%, rgba(84, 171, 93, 1) 30%);
    /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(top, rgba(229, 53, 115, 1) 0%, rgba(229, 53, 115, 1) 0%, rgba(84, 171, 93, 1) 30%);
    /* Opera 11.10-11.50 */
    background: -ms-linear-gradient(top, rgba(229, 53, 115, 1) 0%, rgba(229, 53, 115, 1) 0%, rgba(84, 171, 93, 1) 30%);
    /* IE10 preview */
    background: linear-gradient(to bottom, rgba(229, 53, 115, 1) 0%, rgba(229, 53, 115, 1) 0%, rgba(84, 171, 93, 1) 30%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e53573', endColorstr='#54ab5d',GradientType=0 ) ;*/
    /* IE6-8 */
    /* padding: 5px 10px ;
                width: 54px;
                display: inline-block ; */
    border: 0px;
    border-radius: 0px;
    /* margin-top: -3px ; */
    font-size: 11px;
    font-weight: 600;
    /* line-height: 1.09; */
    color: #ffffff;
    /* background-color: #54ab5d ;
                box-shadow: 0 1px 2px 0 rgba(40, 44, 63, 0.2) ; */
    width: 30%;
    padding: 0;
    margin: 0;
  }
  
  .present {
    /* background: #00bfa5 ; */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2ac9d8+0,20b9ca+0,2ac9d8+0,4db6ac+0,7ee1e8+0,2ac9d8+0,2ac9d8+0,20b9ca+0,20b9ca+0,20b9ca+100,20b9ca+100,e53573+100&0.9+0,0.9+100 */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    /* background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzIwYjljYSIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICAgIDxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIwYjljYSIgc3RvcC1vcGFjaXR5PSIwLjkiLz4KICA8L2xpbmVhckdyYWRpZW50PgogIDxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjZ3JhZC11Y2dnLWdlbmVyYXRlZCkiIC8+Cjwvc3ZnPg==) ;
              background: -moz-linear-gradient(top, rgba(32,185,202,0.9) 0%, rgba(32,185,202,0.9) 100%) ;
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(32,185,202,0.9)), color-stop(100%,rgba(32,185,202,0.9))) ;
              background: -webkit-linear-gradient(top, rgba(32,185,202,0.9) 0%,rgba(32,185,202,0.9) 100%) ;
              background: -o-linear-gradient(top, rgba(32,185,202,0.9) 0%,rgba(32,185,202,0.9) 100%) ;
              background: -ms-linear-gradient(top, rgba(32,185,202,0.9) 0%,rgba(32,185,202,0.9) 100%) ;
              background: linear-gradient(to bottom, rgba(32,185,202,0.9) 0%,rgba(32,185,202,0.9) 100%) ;
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e620b9ca', endColorstr='#e620b9ca',GradientType=0 ) ; */
    /* padding: 5px 10px ; */
    border: 0px;
    border-radius: 0px;
    /* display: block ; */
    /* width: 100% ; */
    box-sizing: border-box;
    margin: 0px;
    /* margin-top: -3px ; */
    
    font-size: 11px;
    font-weight: 600;
    /* line-height: 1.09; */
    color: #ffffff;
    background-color: #54ab5d;
    box-shadow: 0 1px 2px 0 rgba(40, 44, 63, 0.2);
    width: 20%;
  }
  
  .presentlate {
    border: 0px;
    border-radius: 0px;
    box-sizing: border-box;
    margin: 0px;
    
    font-size: 11px;
    font-weight: 600;
    color: #ffffff;
    background-color: #54ab5d;
    box-shadow: 0 1px 2px 0 rgba(40, 44, 63, 0.2);
    width: 40%;
  }
  
  .fc-toolbar {
    margin-bottom: 5px;
    margin-top: 15px;
  }
  
  .fc-toolbar h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
  }
  
  .fc-day {
    background: #ffffff;
  }
  
  .fc-toolbar .fc-state-active,
  .fc-toolbar .ui-state-active,
  .fc-toolbar button:focus,
  .fc-toolbar button:hover,
  .fc-toolbar .ui-state-hover {
    z-index: 0;
  }
  
  .fc-widget-header {
    border: 0px;
  }
  
  .fc-widget-content {
    border-color: rgba(120, 130, 140, 0.13);
  }
  
  .fc th.fc-widget-header {
    color: #67757c;
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
    padding: 7px 0px;
    text-transform: uppercase;
  }
  
  .fc th.fc-sun,
  .fc th.fc-tue,
  .fc th.fc-thu,
  .fc th.fc-sat {
    background: #f2f7f8;
  }
  
  .fc th.fc-mon,
  .fc th.fc-wed,
  .fc th.fc-fri {
    background: #f2f7f8;
  }
  
  .fc-view {
    margin-top: 0px;
  }
  
  .fc-toolbar {
    margin: 0px;
    padding: 24px 0px;
  }
  
  .fc-button {
    background: #ffffff;
    border: 1px solid rgba(120, 130, 140, 0.13);
    color: #67757c;
    text-transform: capitalize;
  }
  
  .fc-button:hover {
    background: #e9edf2;
    opacity: 0.8;
  }
  
  .fc-text-arrow {
    font-family: inherit;
    font-size: 16px;
  }
  
  .fc-state-hover {
    background: #f5f5f5;
  }
  
  .fc-unthemed .fc-today {
    border: 1px solid #ff5252;
    background: #e9edf2;
  }
  
  .fc-state-highlight {
    background: #f0f0f0;
  }
  
  .fc-cell-overlay {
    background: #f0f0f0;
  }
  
  .fc-unthemed .fc-today {
    background: #ffffff;
  }
  
  .fc-event {
    border-radius: 0px;
    border: none;
    cursor: move;
    color: #ffffff;
    font-size: 13px;
    margin: 1px -1px 0 -1px;
    padding: 5px 5px;
    text-align: center;
    background: #398bf7;
  }
  
  .calendar-event {
    cursor: move;
    margin: 10px 5px 0 0;
    padding: 6px 10px;
    display: inline-block;
    color: #ffffff;
    min-width: 140px;
    text-align: center;
    background: #398bf7;
  }
  
  .calendar-event a {
    float: right;
    opacity: 0.6;
    font-size: 10px;
    margin: 4px 0 0 10px;
    color: #ffffff;
  }
  
  .fc-basic-view td.fc-week-number span {
    padding-right: 5px;
  }
  
  .fc-basic-view .fc-day-number {
    padding: 10px 15px;
    display: inline-block;
  }
  
  
  /*******************/
  
  
  /*Steam line widget*/
  
  
  /*******************/
  
  .steamline {
    position: relative;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
    margin-left: 20px;
  }
  
  .steamline .sl-left {
    float: left;
    margin-left: -20px;
    z-index: 1;
    width: 40px;
    line-height: 40px;
    text-align: center;
    height: 40px;
    border-radius: 100%;
    color: #ffffff;
    background: #263238;
    margin-right: 15px;
  }
  
  .steamline .sl-left img {
    max-width: 40px;
  }
  
  .steamline .sl-right {
    padding-left: 50px;
  }
  
  .steamline .sl-right .desc,
  .steamline .sl-right .inline-photos {
    margin-bottom: 30px;
  }
  
  .steamline .sl-item {
    border-bottom: 1px solid rgba(120, 130, 140, 0.13);
    margin: 20px 0;
  }
  
  .sl-date {
    font-size: 10px;
    color: #99abb4;
  }
  
  .time-item {
    border-color: rgba(120, 130, 140, 0.13);
    padding-bottom: 1px;
    position: relative;
  }
  
  .time-item:before {
    content: " ";
    display: table;
  }
  
  .time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: " ";
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
  }
  
  .time-item-item:after {
    content: " ";
    display: table;
  }
  
  .item-info {
    margin-bottom: 15px;
    margin-left: 15px;
  }
  
  .item-info p {
    margin-bottom: 10px;
  }
  
  
  /*******************/
  
  
  /*Feed widget*/
  
  
  /*******************/
  
  .feeds {
    margin: 0px;
    padding: 0px;
  }
  
  .feeds li {
    list-style: none;
    padding: 10px;
    display: block;
  }
  
  .feeds li:hover {
    background: #ebf3f5;
  }
  
  .feeds li>div {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    border-radius: 100%;
  }
  
  .feeds li>div i {
    line-height: 40px;
  }
  
  .feeds li span {
    float: right;
    width: auto;
    font-size: 12px;
  }
  
  
  /*******************/
  
  
  /*Vertical carousel*/
  
  
  /*******************/
  
  .vert .carousel-item-next.carousel-item-left,
  .vert .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  
  .vert .carousel-item-next,
  .vert .active.carousel-item-right {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  
  .vert .carousel-item-prev,
  .vert .active.carousel-item-left {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  
  
  /*******************/
  
  
  /*social-widgets*/
  
  
  /*******************/
  
  .social-widget .soc-header {
    padding: 15px;
    text-align: center;
    font-size: 36px;
    color: #fff;
  }
  
  .social-widget .soc-header.box-facebook {
    background: #3b5998;
  }
  
  .social-widget .soc-header.box-twitter {
    background: #00aced;
  }
  
  .social-widget .soc-header.box-google {
    background: #f86c6b;
  }
  
  .social-widget .soc-header.box-linkedin {
    background: #4875b4;
  }
  
  .social-widget .soc-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
  }
  
  .social-widget .soc-content div {
    padding: 10px;
  }
  
  .social-widget .soc-content div h3 {
    margin-bottom: 0px;
  }
  
  
  /*******************/
  
  
  /*social-profile-first*/
  
  
  /*******************/
  
  .social-profile-first {
    text-align: center;
    padding-top: 22%;
    margin-bottom: 96px;
  }
  
  .social-profile-first.bg-over {
    background: rgba(56, 83, 161, 0.7);
  }
  
  .social-profile-first .middle {
    vertical-align: middle;
  }
  
  
  /*******************/
  
  
  /*profile timeline widget*/
  
  
  /*******************/
  
  .profiletimeline {
    position: relative;
    padding-left: 40px;
    margin-right: 10px;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
    margin-left: 30px;
  }
  
  .profiletimeline .sl-left {
    float: left;
    margin-left: -60px;
    z-index: 1;
    margin-right: 15px;
  }
  
  .profiletimeline .sl-left img {
    max-width: 40px;
  }
  
  .profiletimeline .sl-item {
    margin-top: 8px;
    margin-bottom: 30px;
  }
  
  .profiletimeline .sl-date {
    font-size: 12px;
    color: #99abb4;
  }
  
  .profiletimeline .time-item {
    border-color: rgba(120, 130, 140, 0.13);
    padding-bottom: 1px;
    position: relative;
  }
  
  .profiletimeline .time-item:before {
    content: " ";
    display: table;
  }
  
  .profiletimeline .time-item:after {
    background-color: #ffffff;
    border-color: rgba(120, 130, 140, 0.13);
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    bottom: 0;
    content: " ";
    height: 14px;
    left: 0;
    margin-left: -8px;
    position: absolute;
    top: 5px;
    width: 14px;
  }
  
  .profiletimeline .time-item-item:after {
    content: " ";
    display: table;
  }
  
  .profiletimeline .item-info {
    margin-bottom: 15px;
    margin-left: 15px;
  }
  
  .profiletimeline .item-info p {
    margin-bottom: 10px;
  }
  
  
  /*little-profile*/
  
  .little-profile .pro-img {
    margin-bottom: 20px;
  }
  
  .little-profile .pro-img img {
    width: 128px;
    height: 128px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
  }
  
  .little-profile .soc-pro a {
    color: #99abb4;
  }
  
  .little-profile .soc-pro a:hover {
    color: #263238;
  }
  
  
  /*******************/
  
  
  /*States row*/
  
  
  /*******************/
  
  .stats-row {
    margin-bottom: 20px;
  }
  
  .stats-row .stat-item {
    display: inline-block;
    padding-right: 15px;
  }
  
  .stats-row .stat-item+.stat-item {
    padding-left: 15px;
    border-left: 1px solid rgba(120, 130, 140, 0.13);
  }
  
  
  /*******************/
  
  
  /*Guage chart*/
  
  
  /*******************/
  
  .gaugejs-box {
    position: relative;
    margin: 0 auto;
  }
  
  .gaugejs-box canvas.gaugejs {
    width: 100%;
    height: auto;
  }
  
  
  /*******************/
  
  
  /*country-state*/
  
  
  /*******************/
  
  .country-state {
    list-style: none;
    margin: 0;
    padding: 0 0 0 10px;
  }
  
  .country-state li {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .country-state h2 {
    margin-bottom: 0px;
    font-weight: 400;
  }
  
  
  /*contact widgets*/
  
  .contact-box {
    position: relative;
  }
  
  .contact-box .add-ct-btn {
    position: absolute;
    right: 4px;
    top: -46px;
  }
  
  .contact-box .contact-widget>a {
    padding: 15px 10px;
  }
  
  .contact-box .contact-widget>a .user-img {
    margin-bottom: 0px;
  }
  
  
  /*Blog widgets*/
  
  .blog-widget {
    margin-top: 30px;
  }
  
  .blog-widget .blog-image img {
    border-radius: 4px;
    margin-top: -45px;
    margin-bottom: 20px;
    -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  
  /*******************/
  
  
  /*Msg box*/
  
  
  /*******************/
  
  .msg-item {
    margin-bottom: 20px;
  }
  
  .msg-body {
    background: #ffffff;
    padding: 15px;
    font-size: 14px;
    position: relative;
  }
  
  .msg-body .dwn-aro {
    width: 0;
    position: absolute;
    bottom: -8px;
    height: 0;
    left: 10px;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #ffffff;
  }
  
  .m-pic img {
    width: 40px;
    border-radius: 100%;
  }
  
  
  /*******************/
  
  
  /*Activity widgets*/
  
  
  /*******************/
  
  .activity-box .date-devider {
    border-top: 2px solid rgba(120, 130, 140, 0.13);
    position: relative;
  }
  
  .activity-box .date-devider span {
    background: #e9edf2;
    padding: 5px 15px;
    border-radius: 60px;
    font-size: 14px;
    top: -15px;
    position: relative;
    margin-left: 20px;
  }
  
  .activity-box .activity-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
  }
  
  .activity-box .activity-item .image-list>a {
    margin-left: -15px;
    position: relative;
    vertical-align: middle;
  }
  
  .activity-box .activity-item .image-list>a:first-child,
  .activity-box .activity-item .image-list>a:last-child {
    margin-left: 0px;
  }
  
  .activity-box .activity-item .image-list>a:hover {
    z-index: 10;
  }
  
  .activity-box .activity-item .image-list>a:hover img {
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
  }
  
  
  /*
                Template Name: Admin Pro Admin
                Author: Wrappixel
                Email: niravjoshi87@gmail.com
                File: scss
                */
  
  @media (min-width: 1600px) {
  
    .col-xlg-1,
    .col-xlg-10,
    .col-xlg-11,
    .col-xlg-12,
    .col-xlg-2,
    .col-xlg-3,
    .col-xlg-4,
    .col-xlg-5,
    .col-xlg-6,
    .col-xlg-7,
    .col-xlg-8,
    .col-xlg-9 {
      float: left;
    }
  
    .col-xlg-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .col-xlg-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66666667%;
      -ms-flex: 0 0 91.66666667%;
      flex: 0 0 91.66666667%;
      max-width: 91.66666667%;
    }
  
    .col-xlg-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333333%;
      -ms-flex: 0 0 83.33333333%;
      flex: 0 0 83.33333333%;
      max-width: 83.33333333%;
    }
  
    .col-xlg-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
  
    .col-xlg-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66666667%;
      -ms-flex: 0 0 66.66666667%;
      flex: 0 0 66.66666667%;
      max-width: 66.66666667%;
    }
  
    .col-xlg-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333333%;
      -ms-flex: 0 0 58.33333333%;
      flex: 0 0 58.33333333%;
      max-width: 58.33333333%;
    }
  
    .col-xlg-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  
    .col-xlg-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66666667%;
      -ms-flex: 0 0 41.66666667%;
      flex: 0 0 41.66666667%;
      max-width: 41.66666667%;
    }
  
    .col-xlg-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333333%;
      -ms-flex: 0 0 33.33333333%;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
  
    .col-xlg-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
  
    .col-xlg-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66666667%;
      -ms-flex: 0 0 16.66666667%;
      flex: 0 0 16.66666667%;
      max-width: 16.66666667%;
    }
  
    .col-xlg-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333333%;
      -ms-flex: 0 0 8.33333333%;
      flex: 0 0 8.33333333%;
      max-width: 8.33333333%;
    }
  
    .col-xlg-pull-12 {
      right: 100%;
    }
  
    .col-xlg-pull-11 {
      right: 91.66666667%;
    }
  
    .col-xlg-pull-10 {
      right: 83.33333333%;
    }
  
    .col-xlg-pull-9 {
      right: 75%;
    }
  
    .col-xlg-pull-8 {
      right: 66.66666667%;
    }
  
    .col-xlg-pull-7 {
      right: 58.33333333%;
    }
  
    .col-xlg-pull-6 {
      right: 50%;
    }
  
    .col-xlg-pull-5 {
      right: 41.66666667%;
    }
  
    .col-xlg-pull-4 {
      right: 33.33333333%;
    }
  
    .col-xlg-pull-3 {
      right: 25%;
    }
  
    .col-xlg-pull-2 {
      right: 16.66666667%;
    }
  
    .col-xlg-pull-1 {
      right: 8.33333333%;
    }
  
    .col-xlg-pull-0 {
      right: auto;
    }
  
    .col-xlg-push-12 {
      left: 100%;
    }
  
    .col-xlg-push-11 {
      left: 91.66666667%;
    }
  
    .col-xlg-push-10 {
      left: 83.33333333%;
    }
  
    .col-xlg-push-9 {
      left: 75%;
    }
  
    .col-xlg-push-8 {
      left: 66.66666667%;
    }
  
    .col-xlg-push-7 {
      left: 58.33333333%;
    }
  
    .col-xlg-push-6 {
      left: 50%;
    }
  
    .col-xlg-push-5 {
      left: 41.66666667%;
    }
  
    .col-xlg-push-4 {
      left: 33.33333333%;
    }
  
    .col-xlg-push-3 {
      left: 25%;
    }
  
    .col-xlg-push-2 {
      left: 16.66666667%;
    }
  
    .col-xlg-push-1 {
      left: 8.33333333%;
    }
  
    .col-xlg-push-0 {
      left: auto;
    }
  
    .offset-xlg-12 {
      margin-left: 100%;
    }
  
    .offset-xlg-11 {
      margin-left: 91.66666667%;
    }
  
    .offset-xlg-10 {
      margin-left: 83.33333333%;
    }
  
    .offset-xlg-9 {
      margin-left: 75%;
    }
  
    .offset-xlg-8 {
      margin-left: 66.66666667%;
    }
  
    .offset-xlg-7 {
      margin-left: 58.33333333%;
    }
  
    .offset-xlg-6 {
      margin-left: 50%;
    }
  
    .offset-xlg-5 {
      margin-left: 41.66666667%;
    }
  
    .offset-xlg-4 {
      margin-left: 33.33333333%;
    }
  
    .offset-xlg-3 {
      margin-left: 25%;
    }
  
    .offset-xlg-2 {
      margin-left: 16.66666667%;
    }
  
    .offset-xlg-1 {
      margin-left: 8.33333333%;
    }
  
    .offset-xlg-0 {
      margin-left: 0;
    }
  }
  
  .col-xlg-1,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  
  /*-------------------*/
  
  
  /*Bootstrap 4 hack*/
  
  
  /*-------------------*/
  
  .bootstrap-touchspin .input-group-btn {
    -webkit-box-align: normal;
    -webkit-align-items: normal;
    -ms-flex-align: normal;
    align-items: flex-start;
  }
  
  .form-control-danger,
  .form-control-success,
  .form-control-warning {
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right 0.5625rem;
    -webkit-background-size: 1.125rem 1.125rem;
    background-size: 1.125rem 1.125rem;
  }
  
  .has-success .col-form-label,
  .has-success .custom-control,
  .has-success .form-check-label,
  .has-success .form-control-feedback,
  .has-success .form-control-label {
    color: #00bfa5;
  }
  
  .has-success .form-control-success {
    background-image: url(../images/icon/success.svg);
  }
  
  .has-success .form-control {
    border-color: #00bfa5;
  }
  
  .has-warning .col-form-label,
  .has-warning .custom-control,
  .has-warning .form-check-label,
  .has-warning .form-control-feedback,
  .has-warning .form-control-label {
    color: #ffb22b;
  }
  
  .has-warning .form-control-warning {
    background-image: url(../images/icon/warning.svg);
  }
  
  .has-warning .form-control {
    border-color: #ffb22b;
  }
  
  .has-danger .col-form-label,
  .has-danger .custom-control,
  .has-danger .form-check-label,
  .has-danger .form-control-feedback,
  .has-danger .form-control-label {
    color: #ff5252;
  }
  
  .has-danger .form-control-danger {
    background-image: url(../images/icon/danger.svg);
  }
  
  .has-danger .form-control {
    border-color: #ff5252;
  }
  
  .input-group-addon [type="radio"]:not(:checked),
  .input-group-addon [type="radio"]:checked,
  .input-group-addon [type="checkbox"]:not(:checked),
  .input-group-addon [type="checkbox"]:checked {
    position: initial;
    opacity: 1;
  }
  
  .invisible {
    visibility: hidden;
  }
  
  .hidden-xs-up {
    display: none;
  }
  
  @media (max-width: 575px) {
    .hidden-xs-down {
      display: none;
    }
  }
  
  @media (min-width: 576px) {
    .hidden-sm-up {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    .hidden-sm-down {
      display: none;
    }
    .desktopView{
      display: none !important;
    }
    .mobileView{
      display: block !important;
    }
    
  }
  
  @media (min-width: 768px) {
    .hidden-md-up {
      display: none;
    }
  }
  
  @media (max-width: 991px) {
    .hidden-md-down {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    .hidden-lg-up {
      display: none;
    }
  }
  
  @media (max-width: 1199px) {
    .hidden-lg-down {
      display: none;
    }
  }
  
  @media (min-width: 1200px) {
    .hidden-xl-up {
      display: none;
    }
  }
  
  .hidden-xl-down {
    display: none;
  }
  .card-inverse .card-blockquote,
  .card-inverse .card-footer,
  .card-inverse .card-header,
  .card-inverse .card-title {
    color: #ffffff;
  }
  
  @media (min-width: 1650px) {
    .widget-app-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
  
    .campaign {
      height: 365px;
    }
  
    .website-visitor {
      height: 445px;
    }
  
    .auto-height {
      min-height: 315px;
    }
  }
  
  @media (max-width: 1600px) {
    .website-visitor {
      height: 333px;
    }
  
    .auto-height {
      min-height: 240px;
    }
  }
  
  
  /*==============================================================
                 For Laptop & above all (1370px)
                 ============================================================== */
  
  @media (max-width: 1370px) {
    .widget-app-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  
    .website-visitor {
      height: 353px;
    }
  
    .auto-height {
      min-height: 186px;
    }
  }
  
  
  /*-- ==============================================================
                 Small Desktop & above all (1024px)
                 ============================================================== */
  
  @media (min-width: 1024px) {
    .page-wrapper {
      margin-left: 232px;
    }
  }
  
  @media (max-width: 1023px) {
    .page-wrapper {
      margin-left: 70px;
      -webkit-transition: 0.2s ease-in;
      -o-transition: 0.2s ease-in;
      transition: 0.2s ease-in;
    }
  
    .widget-app-columns {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  
    .inbox-center a {
      width: 200px;
    }
  
    .hdr-nav-bar .navbar .navbar-nav>li a {
      padding: 12px 15px;
      border-bottom: 0px solid transparent;
      border-left: 2px solid transparent;
    }
  
    .hdr-nav-bar .navbar .navbar-nav>li:hover a {
      padding: 12px 20px;
    }
  
    .hdr-nav-bar .call-to-act .custom-select {
      margin-bottom: 10px;
    }
  
    .d-flex {
      display: block;
    }
  
    .d-flex.no-block {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
    }
  }
  
  
  /*-- ==============================================================
                 Ipad & above all(768px)
                 ============================================================== */
  
  @media (min-width: 768px) {
    .navbar-header {
  
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
    }
  
    /* .navbar-header .navbar-brand {
              padding-top: 0px;
            } */
    /*This is for the breeadcrumd*/
    .page-titles .breadcrumb {
      float: right;
    }
  
    .card-group .card:first-child,
    .card-group .card:not(:first-child):not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.03);
    }
  
    .material-icon-list-demo .icons div {
      width: 33%;
      padding: 15px;
      display: inline-block;
      line-height: 40px;
    }
  
    .mini-sidebar .page-wrapper {
      margin-left: 232px;
    }
  
    .flex-wrap {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
    }
  }
  
  
  /*-- ==============================================================
                 Phone and below ipad(767px)
                 ============================================================== */
  
  @media (max-width: 767px) {
    .container-fluid {
      padding: 25px 15px 25px 15px;
    }
  
    /*Header*/
    .topbar {
      position: fixed;
      width: 100%;
    }
  
    .topbar .top-navbar {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      align-items: center;
      -webkit-align-items: center;
    }
  
    .topbar .top-navbar .navbar-collapse {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
    }
  
    .topbar .top-navbar .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
    }
  
    .topbar .top-navbar .navbar-nav>.nav-item.show {
      position: static;
    }
  
    .topbar .top-navbar .navbar-nav>.nav-item.show .dropdown-menu {
      width: 100%;
      margin-top: 0px;
    }
  
    .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  
    .topbar .top-navbar .navbar-nav .dropdown-menu {
      position: absolute;
    }
  
    .mega-dropdown .dropdown-menu {
      height: 480px;
      overflow: auto;
    }
  
    /*Sidebar and wrapper*/
    .mini-sidebar .page-wrapper {
      margin-left: 0px;
      padding-top: 70px;
    }
  
    .comment-text .comment-footer .action-icons {
      display: block;
      padding: 10px 0;
    }
  
    .vtabs .tabs-vertical {
      width: auto;
    }
  
    /*Footer*/
    .material-icon-list-demo .icons div {
      width: 100%;
    }
  
    .error-page .footer {
      position: fixed;
      bottom: 0px;
      z-index: 10;
    }
  
    .error-box {
      position: relative;
      padding-bottom: 60px;
    }
  
    .error-body {
      padding-top: 10%;
    }
  
    .error-body h1 {
      font-size: 100px;
      font-weight: 600;
      line-height: 100px;
    }
  
    .login-register {
      position: relative;
      overflow: hidden;
    }
  
    .login-box {
      width: 100%;
    }
  
    .login-sidebar {
      padding: 10% 0;
    }
  
    .login-sidebar .login-box {
      position: relative;
    }
  
    /*This is for chat page*/
    .chat-main-box .chat-left-aside {
      left: -250px;
      position: absolute;
      -webkit-transition: 0.5s ease-in;
      -o-transition: 0.5s ease-in;
      transition: 0.5s ease-in;
      background: #ffffff;
    }
  
    .chat-main-box .chat-left-aside.open-pnl {
      left: 0px;
    }
  
    .chat-main-box .chat-left-aside .open-panel {
      display: block;
    }
  
    .chat-main-box .chat-right-aside {
      width: 100%;
    }
  
    /*Timeline*/
    ul.timeline:before {
      left: 40px;
    }
  
    ul.timeline>li>.timeline-panel {
      width: calc(100% - 90px);
    }
  
    ul.timeline>li>.timeline-badge {
      top: 16px;
      left: 15px;
      margin-left: 0;
    }
  
    ul.timeline>li>.timeline-panel {
      float: right;
    }
  
    ul.timeline>li>.timeline-panel:before {
      right: auto;
      left: -15px;
      border-right-width: 15px;
      border-left-width: 0;
    }
  
    ul.timeline>li>.timeline-panel:after {
      right: auto;
      left: -14px;
      border-right-width: 14px;
      border-left-width: 0;
    }
  
    /*Contact app page*/
    .left-aside {
      width: 100%;
      position: relative;
      border: 0px;
    }
  
    .right-aside {
      margin-left: 0px;
    }
  
    .flex-wrap {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap;
    }
  
    .chat-list li .chat-content {
      width: calc(100% - 80px);
    }
  
    /*Calendar*/
    .fc-toolbar .fc-left,
    .fc-toolbar .fc-right,
    .fc-toolbar .fc-center {
      float: none;
      display: block;
      text-align: center;
      margin-top: 10px;
      overflow: hidden;
    }
  }
  
  .nav {
    /*display: flex;*/
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .static__tab {
    position: fixed;
    background: #fff;
    z-index: 9;
    width: 81%;
  }
  
  .nav-link {
    display: block;
    padding: 0;
    /* margin-right: 35px; */
  }
  
  .nav-link.disabled {
    color: #636c72;
    cursor: not-allowed;
  }
  
  .nav-tabs {
    border-bottom: 0px;
    padding: 0px;
    /* text-align: center; */
    /* display: block; */
    margin-bottom: 20px;
  }
  
  .nav-tabs .nav-item {
    /* -ms-flex-preferred-size: auto;
                  flex-basis: auto;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1; */
    text-align: center;
    margin-right: 55px;
  }
  
  .filters-global .nav-tabs .nav-item {
    /* -ms-flex-preferred-size: auto;
                  flex-basis: auto;
                  -webkit-box-flex: 1;
                  -ms-flex-positive: 1;
                  flex-grow: 1; */
    text-align: center;
    margin-right: 50px;
  }
  
  .navbar-nav {
    margin-bottom: 0;
  }
  
  .nav-tabs .nav-link {
    border: 0;
    color: #1b2436;
    font-weight: 400;
    transition: color 0.3s;
    position: relative;
    font-size: 14px;
  }
  
  .nav-tabs .nav-link:before {
    content: " ";
    height: 2px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -10px;
    background-color: #28304b;
    transform: scale(0);
    transition: all 0.3s;
  }
  
  
  /* .nav-tabs .nav-link:focus,
                .nav-tabs .nav-link:hover {
                  border-color: #f6f6f6 #f6f6f6 #eee;
                } */
  
  .nav-tabs .nav-link.disabled {
    color: #636c72;
    background-color: transparent;
    border-color: transparent;
  }
  
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    color: #1b2436;
    background-color: transparent;
    border-bottom: 2px solid #1b2436;
    font-weight: 600;
    /* border-color: #ddd #ddd transparent; */
  }
  
  .nav-tabs .dropdown-menu {
    margin-top: -2px;
  }
  
  .nav-pills .nav-link {
    border-radius: 2px;
  }
  
  .nav-pills .nav-item.show .nav-link,
  .nav-pills .nav-link.active {
    color: #fff;
    cursor: default;
    background-color: #2196f3;
  }
  
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    flex: 1 1 100%;
    text-align: center;
  }
  
  .tab-content>.tab-pane {
    display: none;
  }
  
  .tab-content>.active {
    display: block;
    margin-top: 80px;
  }
  
  .filters-global .tab-content>.active {
    display: block;
    margin-top: 0px;
  }
  
  .logo-field {
    width: 100%;
    /* max-height: 100px; */
    background-color: #ffffff;
    /* box-shadow: 0 1px 4px 0 rgba(173, 173, 173, 0.5);
              border: solid 0.5px #f2f2f2; */
    /* padding: 5px 15px;
      margin: 0 auto; */
    text-align: center;
    overflow: hidden;
    width: 150px;
    margin: auto;
  }
  
  .logo-field img {
    object-fit: cover;
    object-position: center;
    /* height: 100%; */
  }
  
  
  /* --------------------------------custome css -------------------------------*/
  .shw-rside-100 {
    right: 0px;
    /* width: 82.5%; */
    width: calc(100% - 60px) !important;
    display: block;
  }
  
  .shw-rside-95 {
    right: 0px;
    /* width: 82.5%; */
    width: calc(100% - 140px) !important;
    display: block;
  }
  
  .shw-rside-90 {
    right: 0px;
    /* width: 82.5%; */
    width: calc(100% - 218px) !important;
    display: block;
  }
  
  .shw-rside-60 {
    right: 0px;
    /* width: 60%; */
    width: calc(100% - 40%) !important;
    display: block;
  }
  
  .shw-rside-50 {
    right: 0px;
    /* width: 60%; */
    width: calc(100% - 47%) !important;
    display: block;
  }
  
  .shw-rside-68 {
    right: 0px;
    /* width: 68%; */
    width: calc(100% - 473px) !important;
    display: block;
  }
  
  .shw-rside-75 {
    right: 0px;
    /* width: 68%; */
    width: calc(100% - 360px) !important;
    display: block;
  }
  
  .shw-rside-40 {
    right: 0px;
    /* width: 35%; */
    width: calc(100% - 50.6%) !important;
    display: block;
  }
  
  .shw-rside-30 {
    right: 0px;
    /* width: 35%; */
    width: calc(100% - 62%) !important;
    display: block;
  }
  
  .shw-rside-20 {
    right: 0px;
    width: calc(100% - 70%) !important;
    display: block;
  }
  
  .shw-rside-30.theme-customize {
    width: calc(100% - 65%);
  }
  
  .shw-rside-60 {
    right: 0px;
    /* width: 60%; */
    width: calc(100% - 40%) !important;
    display: block;
  }
  
  
  
  /* .filters-global ul { display: inline-block;} */
  
  .filters-global .nav-tabs {
    border-bottom: solid #98a5bd 1px;
  }
  
  .filters-global ul li {
    padding: 5px 0 15px;
  }
  
  .filters-global .nav-tabs .nav-item.show .nav-link,
  .filters-global .nav-tabs .nav-link.active {
    color: #282c3f;
    border-bottom: 2px solid #282c3f;
    font-weight: 600;
  }
  
  .btn-purpul-sm {
    background-color: transparent;
    border: 1px solid #586ac6;
    color: #586ac6;
    border-radius: 0 !important;
    width: 100%;
    font-weight: 600 !important;
  }
  
  .btn-sm {
    padding: 0.25rem 1.5rem;
  }
  
  .btn-group-sm>.btn,
  .btn-sm {
    border-radius: 0rem;
  }
  
  .underline {
    text-decoration: underline !important;
  }
  
  .btn-bold {
    font-weight: 500;
  }
  
  .form-control:focus {
    box-shadow: none;
  }
  
  .min-h32 {
    min-height: 32px;
  }
  
  .min-h55 {
    min-height: 55px;
  }
  
  .min-h90 {
    min-height: 90px;
  }
  .min-h115{
    min-height: 115px;
  }
  .min-h35 {
    min-height: 340px;
  }
  
  .min-h20 {
    min-height: 185px;
  }
  
  .min-h122 {
    min-height: 122px;
  }
  
  .min-h130 {
    min-height: 130px;
  }
  
  .min-h475 {
    min-height: 550px;
  }
  
  .min-h500 {
    min-height: 445px;
  }
  
  .form-down-arrow {
    background-image: url(../images/down.png);
    background-position-x: 100%;
    background-repeat: no-repeat;
    background-position-y: 50%;
  }
  
  .g-h {
    position: relative;
    padding-bottom: 20px;
  }
  
  .filters-global-float-btn {
    position: fixed;
    top: 180px;
    right: 62px;
    padding: 0px;
    z-index: 10;
  }
  
  .filters-global-float-btn-emp {
    top: 120px;
  }
  
  .g-h:before {
    content: " ";
    position: absolute;
    width: 30px;
    height: 1px;
    bottom: 14px;
    left: 0;
    border-bottom: 2px solid #282c3f;
  }
  
  .floating-labels .form-control:focus {
    background: none;
  }
  
  .custome-modal {
    max-width: 364px;
    margin: 115px 19px 0 auto;
  }
  
  .custome-modal .modal-footer {
    border-top: none;
  }
  
  .card-title,
  .card-title-arc {
    font-size: 12px;
    font-weight: 500;
    position: relative;
    /* line-height: 20px; */
    letter-spacing: 0.3px;
  }
  
  .text-archive {
    color: #a8a8a8;
    font-size: 9px;
  }
  
  .custom-title .card-title::before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 85%;
    bottom: 5px;
    left: -1rem;
  }
  
  .custom-title .card-title-arc::before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 80%;
    bottom: 5px;
    left: -1rem;
  }
  
  .alert-card .custom-title .card-title::before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 29px;
    top: 0px;
    left: -1rem;
  }
  
  .alert-card .custom-title .card-title-arc::before {
    content: " ";
    position: absolute;
    width: 1px;
    height: 29px;
    top: 0px;
    left: -1rem;
    border-left: 2px solid #a8a8a8;
  }
  
  .emp_icon {
    background: url("../images/employee.svg") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .dept_icon {
    background: url("../images/dept_icon.png") no-repeat -1% 50%;
    padding-left: 22px;
  }
  
  .emp_group {
    background: url("../images/Emplyee_icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .salary_icon {
    background: url("../images/wallet-salary.svg") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .calendar-icon {
    background: url("../images/baseline_calendar_today_black_18dp.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .experience_icon {
    background: url("../images/briefcase-exp.svg") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .visibleonreports-icon {
    background: url("../images/visibleonreports-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .Due-icon {
    background: url("../images/missing-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .legalidentity-icon {
    background: url("../images/legalidentity-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .state-icon {
    background: url("../images/state-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .Branch-icon {
    background: url("../images/Branch.png") no-repeat 0 50%;
    padding-left: 22px;
    height: 16px;
  }
  
  
  /* .calendar-icon { background: url('assets/images/briefcase.svg') no-repeat 0 50%;
                    padding-left: 22px;
                   } */
  
  .city-icon {
    background: url("../images/city-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  
  }
  
  .branch-icon {
    background: url("../images/branch-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  
  }
  
  .region-icon {
    background: url("../images/region-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .country-icon {
    background: url("../images/country-icon.png") no-repeat 0 50%;
    padding-left: 22px;
  }
  
  .p-t-3 {
    padding-top: 5px;
  }
  
  .p-t-5 {
    padding-top: 5px;
  }
  
  .custom-card-p {
    padding: 14px;
  }
  
  .click-div {
    cursor: pointer;
  }
  
  .card-min-w200 {
    min-width: 200px;
  }
  
  .active-text {
    color: #01bba1;
    font-size: 9px;
    font-weight: 600;
  }
  
  .card-min20 {
    flex: 0 0 20%;
    max-width: 20%;
  }
  
  
  
  /* For Media Screen Only */
  
  @media screen and (max-width: 1920px) {
    nav.nav-material-tab.mat-mdc-tab-nav-bar {
      width: 100%;
    }
  }
  
  
  /* iPads (portrait and landscape) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .card-min20 {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
  
    .topbar .top-navbar .navbar-nav>.nav-item>.nav-link {
      padding-right: 26px;
    }
  
    .filters-global-float-btn {
      right: 52px;
    }
  }
  /* for dektop view */
  @media only screen and (min-device-width: 768px) and (max-device-width: 2000px) {
    .mobileView{
      display: none !important;
    }
  }
  
  /* iPads (landscape) ----------- */
  
  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  
    /* Styles */
    .nav-tabs .nav-item {
      margin-right: 15px;
    }
  
    .logo-field {
      width: 100.2px;
      height: 110px;
      padding: 26px 15px;
    }
  }
  
  
  /* Large screens ----------- */
  
  @media only screen and (min-width: 1824px) {
    .side-mini-panel {
      width: 88px;
    }
  
    .home-container {
      left: 88px;
    }
  
    .home-sidenav {
      width: 168px;
    }
  
    .sidebarmenu {
      max-width: 215px;
    }
  
    .btm__Profile {
      width: 88px !important;
    }
  
    
  
  /* 
    .Chat {
      background-image: url(../images/Sidebarmenu-Icon/chat@2x.png);
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
    }
  
    .side-mini-panel ul.mini-nav li.selected>a .Chat {
      background-image: url(../images/Sidebarmenu-Icon/chat_selected@2x.png);
      width: 44px;
      height: 44px;
      background-repeat: no-repeat;
    } */
  
    
  
    /* .side-mini-panel ul.mini-nav>li>a {
      padding: 10px 7px 6px 7px;
      font-size: 18px;
    } */
  
    .topbar .top-navbar .navbar-header .navbar-brand {
      font-size: 17px;
      font-weight: 500;
      padding-left: 25px;
      letter-spacing: -0.6px;
      line-height: 52px;
      padding-top: 0;
    }
  
    .topbar .top-navbar .navbar-header {
      line-height: 52px;
      height: 52px;
    }
  
    .topbar .top-navbar {
      max-height: 52px;
      height: 52px;
    }
  
  
  
    .inbox-outlet {
      width: calc(100% - 426px) !important;
      margin-left: 426px !important;
    }
  
    .inbox_left {
      max-width: 426px !important;
    }
  
    .side-mini-panel {
      top: 52px;
    }
  
    .home-container {
      top: 52px;
    }
  
    .shw-rside-90 {
      width: calc(100% - 313px);
    }
  
    .sidebar-menu>li>.submenu-1 {
      font-size: 14px;
      line-height: 38px !important;
    }
  
    .sidebar-menu>li>.submenu-1>span {
      line-height: 38px !important;
    }
  
    .sidebar-menu>li>.submenu-1 i {
      height: 38px !important;
    }
  
    .sidebar-menu .sub-menu>li>a {
      font-size: 13px;
      line-height: 30px;
    }
  }
  
  
  /* iPhone 5 ----------- */
  
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .filters-global-float-btn {
      top: 160px;
    }
  }
  
  @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  
    .filters-global-float-btn {
      top: 160px;
    }
  }
  
  
  /* iPhone 6 ----------- */
  
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  
  /* iPhone 6+ ----------- */
  
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 2) {
    .card-min20 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  
  .no-border {
    border: 0;
  }
  
  .ng-valid[required],
  .ng-valid.required {
    border-bottom: 1px solid #42a948;
    /* green */
  }
  
  .ng-invalid:not(form) {
    border-bottom: 1px solid #a94442;
    /* red */
  }
  
  
  /*-------- Generic Css ---------*/
  
  /* .h-17 {
    height: 17px;
  } */
  .mh-100{
    height: 100px;
  }
  .h-20 {
    height: 20px;
  }
  
  .h-25 {
    height: 25%;
  }
  
  .h-50 {
    height: 50%;
  }
  
  .h-75 {
    height: 75%;
  }
  
  .h-100 {
    height: 100%;
  }
  
  .mw-100 {
    max-width: 100%;
  }
  
  .mh-100 {
    max-height: 100%;
  }
  
  .max-w100 {
    max-width: 100%;
  }
  
  .max-w80 {
    max-width: 80%;
  }
  
  .max-w85 {
    max-width: 85%;
  }
  
  .max-w75 {
    max-width: 75%;
  }
  
  .max-w90 {
    max-width: 90%;
  }
  
  .max-w60 {
    max-width: 60%;
  }
  
  .max-w50 {
    max-width: 50%;
  }
  
  .max-w25 {
    max-width: 25%;
  }
  
  .max-w15 {
    max-width: 15%;
  }
  
  .max-w10 {
    max-width: 10%;
  }
  
  
  /* End Generic Css */
  
  .bottom-rightsidebar {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
    right: 0;
    height: 70px;
    line-height: 70px;
  }
  
  .Grid-float-btn {
    position: fixed;
    right: 32px;
    z-index: 9;
    top: 114px;
  }
  
  .swal2-show {
    margin-top: 8%;
    height: 275px;
    background-repeat: no-repeat;
  }
  
  .btn-file>input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    font-size: 23px;
    height: 100%;
    width: 100%;
    direction: ltr;
    cursor: pointer;
  }
  
  .btn2 {
    background: #a3a7b2;
    border: 0;
    font-size: 13px;
    color: #fff;
  }
  
  .demo-radio-button.form-group label {
    min-width: auto;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    margin-right: 1rem;
  }
  
  .fixed-hgt {
    position: relative;
    height: 55px;
  }
  
  .m-r15 {
    margin-right: -15px;
  }
  
  .m-l15 {
    margin-left: -15px;
  }
  
  .form-left-pannel {
    height: 100%;
  }
  
  .forms-left-bg {
    background: #f5f7fa;
    /* margin-bottom: 20px; */
    padding: 15px;
    height: 100%;
  }
  
  .form-left-bg {
    background: #f5f7fa;
  }
  
  .form-left-bg .left-form-icon {
    position: absolute;
    left: 16px;
    top: -60px;
  }
  
  .swal2-popup .swal2-title {
    color: #595959;
    font-size: 18px;
    text-align: left;
    font-weight: 600;
    text-transform: none;
    position: relative;
    margin: 1em 0 0.4em;
    padding: 0;
    display: block;
    word-wrap: break-word;
    bottom: 0px;
  }
  
  .swal2-popup .swal2-image {
    margin: 0px auto;
    max-width: 100%;
  }
  
  
  /* .swal2-contentwrapper {
                 position: absolute;
                 bottom: 0;
                 width: 100%;
                } */
  
  .swal2-popup {
    text-align: left;
  }
  
  .swal2-confirm {
    background-color: #00bfa5;
    border-left-color: #00bfa5;
    border-right-color: #00bfa5;
  }
  
  .swal2-popup .swal2-content {
    text-align: left;
    font-size: 11px;
  }
  
  .swal2-popup .swal2-buttonswrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    /* justify-content: left; */
    margin-top: 15px;
  }
  
  .swal2-container {
    z-index: 99999;
  }
  
  .custom-dark {
    background: #fff;
    color: #272c3e;
  }
  
  .custom-dark.active {
    color: #fff;
  }
  
  .custom-dark:hover {
    color: #fff;
    background-color: #32373b;
    border-color: #1d2124;
  }
  
  .custome-header-bg {
    line-height: 40px;
    background: #eceff1;
    padding: 7px 15px;
    /* display: flex;
          flex-direction: column;
          max-width: 500px; */
    width: 100%;
    /* float: left; */
    /* margin-bottom: 10px; */
  }
  
  .form-custome-header-bg {
    line-height: 40px;
    background: #eceff1;
    padding: 0 15px;
  }
  
  .maxw-60 {
    max-width: 60px;
  }
  
  .btn-plus {
    background: #00c7b4;
  }
  
  .custom-form-list ul {
    width: 100%;
    padding: 0;
  }
  
  .custom-form-list ul li {
    list-style: none;
  }
  
  .custom-form-list ul li a {
    display: block;
    line-height: 25px;
    color: #00c8b4;
  }
  
  .custom-form-list ul li a [class^="ti-"],
  [class*=" ti-"] {
    font-size: 10px;
    line-height: 25px;
  }
  
  .Rect-box {
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 12px;
    position: relative;
    font-size: 12px;
    
    margin-bottom: 20px;
  }
  
  .Rect-box div,
  .Rect-box h6 {
    font-size: 12px;
    
    margin-bottom: 0;
  }
  
  .Rect-box h5 {
    position: relative;
    font-size: 12px;
    
    margin-bottom: 38px;
  }
  
  .Rect-box h5::before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 1px;
    bottom: -2px;
    left: 0;
    border-bottom: 2px solid #282c3f;
  }
  
  .background-masker.content-third-end {
    left: 300px;
    top: 88px;
  }
  
  .mad-feild {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 11px;
    font-weight: 500;
  }
  
  .mad-feild::after {
    content: " ";
    position: absolute;
    width: 35px;
    height: 1px;
    top: -10px;
    left: 0;
    border-top: 2px solid #282c3f;
  }
  
  .maxw-60 {
    max-width: 60px;
  }
  
  .btn-plus {
    background: #00c7b4;
  }
  
  .custom-form-list ul {
    width: 100%;
  }
  
  .custom-form-list ul li a {
    display: block;
    line-height: 25px;
    color: #00c8b4;
  }
  
  .custom-form-list ul li a [class^="ti-"],
  [class*=" ti-"] {
    font-size: 10px;
    line-height: 25px;
  }
  
  .Rect-box {
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 12px;
    position: relative;
    font-size: 12px;
    
    margin-bottom: 20px;
  }
  
  .Rect-box div,
  .Rect-box h6 {
    font-size: 12px;
    
    margin-bottom: 0;
  }
  
  .Rect-box h5 {
    position: relative;
    font-size: 12px;
    
    margin-bottom: 38px;
  }
  
  .Rect-box h5::before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 1px;
    bottom: -2px;
    left: 0;
    border-bottom: 2px solid #282c3f;
  }
  
  .Blank-stat {
    width: 100%;
    height: 100%;
    padding: 20px 0 0;
    margin: auto;
    text-align: center;
  }
  
  .Blank-stat-text {
    font-size: 40px;
    font-weight: bold;
    color: rgba(162, 172, 183, 0.5);
    text-align: center;
  }
  
  .Blank-stat-lettertemp {
    width: 100%;
    height: 100%;
    /* padding: 20px 0 0; */
    margin: -140px auto 0;
    text-align: right;
  }
  
  
  /* ------------------------- animated data loader css---------------------------------- */
  
  @-webkit-keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
  
  
  /* .timeline-wrapper {
                  background-color: #e9eaed;
                color: #141823;
                padding: 20px;
                border: 1px solid #ccc;
                margin: 0 auto 1em;
  
                } */
  
  .timeline-item {
    background: #fff;
    border-radius: 3px;
    margin: 0;
    max-width: 108px;
    min-height: 117px;
  }
  
  .animated-background {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: #eeeeee;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
    background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;
    height: 85px;
    position: relative;
  }
  
  .background-masker {
    background: #fff;
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-animation: pulse 1s infinite ease-in-out;
    animation: pulse 1s infinite ease-in-out;
  }
  
  .no-outline {
    outline: none !important;
  }
  
  .outlined .background-masker {
    border: 1px solid #ddd;
  }
  
  .outlined:hover .background-masker {
    border: none;
  }
  
  .outlined:hover .background-masker:hover {
    border: 1px solid #ccc;
    z-index: 1;
  }
  
  .background-masker.header-top,
  .background-masker.header-bottom,
  .background-masker.subheader-bottom {
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
  }
  
  .background-masker.header-left,
  .background-masker.subheader-left,
  .background-masker.header-right,
  .background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px;
  }
  
  .background-masker.subheader-left {
    width: 70px;
  }
  
  .background-masker.header-bottom {
    top: 18px;
    height: 6px;
  }
  
  .background-masker.subheader-left,
  .background-masker.subheader-right {
    top: 24px;
    height: 10px;
  }
  
  .background-masker.header-right,
  .background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
  }
  
  .background-masker.subheader-right {
    left: 230px;
  }
  
  .background-masker.subheader-bottom {
    top: 34px;
    height: 10px;
  }
  
  .background-masker.content-top,
  .background-masker.content-second-line,
  .background-masker.content-third-line,
  .background-masker.content-second-end,
  .background-masker.content-third-end,
  .background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 11px;
  }
  
  .background-masker.content-top {
    height: 20px;
  }
  
  .background-masker.content-first-end,
  .background-masker.content-second-end,
  .background-masker.content-third-end {
    width: auto;
    left: 86px;
    right: 0;
    top: 60px;
    height: 8px;
  }
  
  .background-masker.content-second-line {
    top: 66px;
  }
  
  .background-masker.content-second-end {
    left: 86px;
    top: 74px;
  }
  
  .background-masker.content-third-line {
    top: 82px;
  }
  
  .background-masker.content-third-end {
    left: 300px;
    top: 88px;
  }
  
  .mad-feild {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 11px;
    font-weight: 500;
  }
  
  .mad-feild::after {
    content: " ";
    position: absolute;
    width: 35px;
    height: 1px;
    top: -10px;
    left: 0;
    border-top: 2px solid #282c3f;
  }
  
  .btn-dark {
    color: #fff;
  }
  
  .maxw-60 {
    max-width: 60px;
  }
  
  .btn-plus {
    background: #00c7b4;
  }
  
  .custom-form-list ul {
    width: 100%;
  }
  
  .custom-form-list ul li a {
    display: block;
    line-height: 25px;
    color: #00c8b4;
  }
  
  .custom-form-list ul li a [class^="ti-"],
  [class*=" ti-"] {
    font-size: 10px;
    line-height: 25px;
  }
  
  .Rect-box {
    border-radius: 4px;
    background-color: #f7f7f7;
    padding: 12px;
    position: relative;
    font-size: 12px;
    
    margin-bottom: 20px;
  }
  
  .Rect-box div,
  .Rect-box h6 {
    font-size: 12px;
    
    margin-bottom: 0;
  }
  
  .Rect-box h5 {
    position: relative;
    font-size: 12px;
    
    margin-bottom: 38px;
  }
  
  .Rect-box h5::before {
    content: " ";
    position: absolute;
    width: 20px;
    height: 1px;
    bottom: -2px;
    left: 0;
    border-bottom: 2px solid #282c3f;
  }
  
  
  /*Floating Label*/
  
  
  /*******************/
  
  .floating-labels .form-group {
    position: relative;
  }
  
  .floating-labels .form-control {
    padding: 10px 10px 10px 0;
    display: block;
    background: none;
    box-shadow: none;
    border: none;
    border-radius: 0px;
    border-bottom: 1px solid #e9edf2;
  }
  
  .floating-labels select.form-control>option {
    font-size: 14px;
  }
  
  .floating-labels .has-error .form-control {
    border-bottom: 1px solid #ef5350;
  }
  
  .floating-labels .has-warning .form-control {
    border-bottom: 1px solid #ffb22b;
  }
  
  .floating-labels .has-success .form-control {
    border-bottom: 1px solid #06d79c;
  }
  
  .floating-labels .form-control:focus {
    outline: none;
    border: none;
  }
  
  .floating-labels label {
    color: #67757c;
    position: absolute;
    cursor: auto;
    top: 10px;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  .floating-labels .focused label {
    top: -15px;
    font-size: 12px;
    color: #263238;
  }
  
  .floating-labels .bar {
    position: relative;
    display: block;
  }
  
  .floating-labels .bar:before,
  .floating-labels .bar:after {
    content: " ";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #398bf7;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  .floating-labels .bar:before {
    left: 50%;
  }
  
  .floating-labels .bar:after {
    right: 50%;
  }
  
  .floating-labels .form-control:focus~.bar:before,
  .floating-labels .form-control:focus~.bar:after {
    width: 50%;
  }
  
  .floating-labels .highlight {
    position: absolute;
    height: 60%;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
  }
  
  .floating-labels .input-lg~label,
  .floating-labels .input-lg {
    font-size: 24px;
  }
  
  .floating-labels .input-sm~label,
  .floating-labels .input-sm {
    font-size: 16px;
  }
  
  .has-warning .bar:before,
  .has-warning .bar:after {
    background: #ffb22b;
  }
  
  .has-success .bar:before,
  .has-success .bar:after {
    background: #06d79c;
  }
  
  .has-error .bar:before,
  .has-error .bar:after {
    background: #ef5350;
  }
  
  .has-warning .form-control:focus~label,
  .has-warning .form-control:valid~label {
    color: #ffb22b;
  }
  
  .has-success .form-control:focus~label,
  .has-success .form-control:valid~label {
    color: #06d79c;
  }
  
  .has-error .form-control:focus~label,
  .has-error .form-control:valid~label {
    color: #ef5350;
  }
  
  .has-feedback label~.t-0 {
    top: 0;
  }
  
  .form-group.error input,
  .form-group.error select,
  .form-group.error textarea {
    border: 1px solid #ef5350;
  }
  
  .form-group.validate input,
  .form-group.validate select,
  .form-group.validate textarea {
    border: 1px solid #06d79c;
  }
  
  .form-group.error .help-block ul {
    padding: 0px;
    color: #ef5350;
  }
  
  .form-group.error .help-block ul li {
    list-style: none;
  }
  
  .form-group.issue .help-block ul {
    padding: 0px;
    color: #ffb22b;
  }
  
  .form-group.issue .help-block ul li {
    list-style: none;
  }
  
  @keyframes placeHolderShimmer {
    0% {
      background-position: -468px 0;
    }
  
    100% {
      background-position: 468px 0;
    }
  }
  
  
  /* .timeline-wrapper {
                  background-color: #e9eaed;
                  color: #141823;
                  padding: 20px;
                  border: 1px solid #ccc;
                  margin: 0 auto 1em;
                } */
  
  .timeline-item {
    background: #fff;
    border-radius: 3px;
    margin: 0;
    max-width: 108px;
    min-height: 117px;
  }
  
  .animated-background {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeHolderShimmer;
    animation-name: placeHolderShimmer;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    /* background: #f6f7f8;
                  background: #eeeeee;
                  background: -webkit-gradient(linear, left top, right top, color-stop(8%, #eeeeee), color-stop(18%, #dddddd), color-stop(33%, #eeeeee));
                  background: -webkit-linear-gradient(left, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
                  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%); */
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eceff7+0,eceff7+100 */
    background: #eceff7;
    /* Old browsers */
    background: -moz-linear-gradient(left, #eceff7 0%, #eceff7 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(left, #eceff7 0%, #eceff7 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, #eceff7 0%, #eceff7 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eceff7', endColorstr='#eceff7', GradientType=1);
    /* IE6-9 */
    -webkit-background-size: 800px 104px;
    background-size: 800px 104px;
    height: 85px;
    position: relative;
  }
  
  .background-masker {
    background: #fff;
    position: absolute;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .outlined .background-masker {
    border: 1px solid #ddd;
  }
  
  .outlined:hover .background-masker {
    border: none;
  }
  
  .outlined:hover .background-masker:hover {
    border: 1px solid #ccc;
    z-index: 1;
  }
  
  .background-masker.header-top,
  .background-masker.header-bottom,
  .background-masker.subheader-bottom {
    top: 0;
    left: 0;
    right: 0;
    height: 8px;
  }
  
  .background-masker.header-left,
  .background-masker.subheader-left,
  .background-masker.header-right,
  .background-masker.subheader-right {
    top: 10px;
    left: 40px;
    height: 8px;
    width: 10px;
  }
  
  .background-masker.subheader-left {
    width: 70px;
  }
  
  .background-masker.header-bottom {
    top: 18px;
    height: 6px;
  }
  
  .background-masker.subheader-left,
  .background-masker.subheader-right {
    top: 24px;
    height: 10px;
  }
  
  .background-masker.header-right,
  .background-masker.subheader-right {
    width: auto;
    left: 300px;
    right: 0;
  }
  
  .background-masker.subheader-right {
    left: 230px;
  }
  
  .background-masker.subheader-bottom {
    top: 34px;
    height: 10px;
  }
  
  .background-masker.content-top,
  .background-masker.content-second-line,
  .background-masker.content-third-line,
  .background-masker.content-second-end,
  .background-masker.content-third-end,
  .background-masker.content-first-end {
    top: 40px;
    left: 0;
    right: 0;
    height: 11px;
  }
  
  .background-masker.content-top {
    height: 20px;
  }
  
  .background-masker.content-first-end,
  .background-masker.content-second-end,
  .background-masker.content-third-end {
    width: auto;
    left: 86px;
    right: 0;
    top: 60px;
    height: 8px;
  }
  
  .background-masker.content-second-line {
    top: 66px;
  }
  
  .background-masker.content-second-end {
    left: 86px;
    top: 74px;
  }
  
  .background-masker.content-third-line {
    top: 82px;
  }
  
  .background-masker.content-third-end {
    left: 300px;
    top: 88px;
  }
  
  .form-section2-field {
    background: #f5f6f7;
  }
  
  .form-removal-field-h {
    /* background: #eceff1; */
    /* display: flex;
          flex-direction: column;
          max-width: 295px;
          width: 100%; */
    /* margin-left: 13px; */
    /* margin-bottom: 20px; */
    height: 100%;
  }
  
  .form-removal-field {
    background: #f9f5f5;
    padding: 15px;
    height: 100%;
  }
  
  .form-first-box {
    width: 70%;
  }
  
  .form-remove-box {
    width: 30%;
    margin-left: 25px;
  }
  
  .tooltip {
    opacity: 1;
  }
  
  .tooltip-inner {
    min-width: 300px;
    background: #40475e;
    text-align: left;
    font-size: 12px;
    padding: 9px 13px;
  }
  
  .text-pink {
    color: #ff80ab;
  }
  
  .cursor-move {
    cursor: move;
    cursor: -webkit-grab;
  }
  
  #closeModalButton {
    cursor: pointer;
  }
  
  .disabled-cursor {
    cursor: not-allowed;
  }
  
  .card-title-length {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    /* white-space: pre; */
  }
  
  .Text-length {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
  }
  
  .break-word {
    width: 100%;
    overflow-wrap: break-word;
  }
  
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }
  
  #btnShowMore:hover {
    box-shadow: 0 14px 26px -12px rgba(119, 119, 119, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(92, 92, 92, 0.2);
  }
  
  .dragable-custom-btn button {
    width: 100%;
    display: inline-flex;
  }
  
  .alerts-page-list ul {
    display: block;
    padding: 16px 0 0 0;
    margin: 0;
    list-style-type: none;
  }
  
  .alerts-page-list ul li {
    margin: 0 0 14px;
    display: inline-block;
    width: 100%;
    line-height: 15px;
  }
  
  .alerts-page-list ul li a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 16px;
    
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    color: #262e3e;
    width: 100%;
  }
  
  .alerts-page-list ul li a [class^="ti-"],
  [class*=" ti-"] {
    font-size: 10px;
    line-height: 17px;
  }
  
  .alert-card {
    background: #fff;
    min-height: 150px;
    width: 100%;
    margin-bottom: 30px;
    margin-right: 0;
    float: left;
  }
  
  .select2-container .select2-search--inline {
    width: 100%;
  }
  
  .select2-container--default .select2-search--inline .select2-search__field {
    width: 100%;
  }
  
  a {
    cursor: pointer;
  }
  
  .insert-tag-bg {
    background: #f5f7f8;
  }
  
  .selected-tick {
    width: 24px;
    height: 24px;
    display: inline-block;
  }
  
  .cloud-form-icon {
    background: url(../images/cloud-forms-icon.png) no-repeat 3px 5px;
    padding-left: 20px;
    display: block;
  }
  
  .btn-process {
    color: #fff;
    background: #5c6bc0;
    border: 1px solid #5c6bc0;
    transition: 0.2s ease-in;
  }
  
  .btn-process:hover,
  .btn-process.disabled:hover {
    background: #5c6bc0;
    border: 1px solid #5c6bc0;
    box-shadow: 0 14px 26px -12px rgba(65, 84, 255, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(200, 80, 255, 0.2);
    color: #fff;
  }
  
  .text-transform-none {
    text-transform: none;
  }
  
  .alert-small-popup {
    margin-top: 16.3%;
    margin-left: 46%;
  }
  
  
  /* .shw-rside-68 {
                  right: 0px;
                  width: 68%;
                  display: block;
                } */
  
  .custom-modal-field {
    margin: 240px auto 0 52%;
  }
  
  .custom-modal {
    z-index: 99999;
  }
  
  .alphabet {
    list-style: none;
    margin: 0px 0 0 0;
    padding: 0;
  }
  
  .alphabet li {
    font-size: 14px;
    float: left;
    margin: 0px 20px 0 0;
    
  }
  
  .alphabet li a {
    cursor: pointer;
    color: #455a64;
    
  }
  
  .alphabet li a.active {
    cursor: pointer;
    color: #455a64;
    font-weight: 600;
    
  }
  
  .selected-list .c-btn {
    box-shadow: none;
  }
  
  .c-btn {
    background: transparent;
    border-bottom: 1px solid #eceff1;
    border-top: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    border-radius: 0px;
  }
  
  .pure-checkbox input[type="checkbox"]:checked+label:before {
    background: #00bfa5;
    border: 1px solid #00bfa5;
  }
  
  
  /* .pure-checkbox {
                  padding: 5px 10px 0;
                } */
  
  .pure-checkbox input[type="checkbox"]+label:before {
    border: 1px solid #b1b8bb;
    height: 20px;
    width: 20px;
    -webkit-transform: none;
    transform: none;
  }
  
  .pure-checkbox input[type="checkbox"]+label {
    padding-left: 2.5em;
  }
  
  .pure-checkbox input[type="checkbox"]+label:after {
    width: 10px;
    height: 4px;
    margin-top: -3px;
  }
  
  .arrow-up {
    border-left: 0px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 0px solid #fff;
  }
  
  .dropdown-list {
    padding-top: 0;
  }
  
  .selected-list .fa-angle-down,
  .selected-list .fa-angle-up {
    color: #ccc;
  }
  
  .pure-checkbox [type="checkbox"]+label {
    line-height: 30px;
  }
  
  .demo-radio-button [type="radio"]:not(:checked)+label,
  [type="radio"]:checked+label {
    font-size: 14px;
    color: #272c3e;
  }
  
  .select2-dropdown.select2-dropdown--alt .select2-results__option[aria-selected="true"]:before {
    right: 0.5rem;
  }
  
  .select2-dropdown.select2-dropdown--alt .select2-results__option {
    padding: 0.65rem 0.5rem 0.65rem 0.5rem;
  }
  
  .select2-container--classic.select2-container--open .select2-dropdown {
    border-color: #a1a4ac;
  }
  
  .clscalander input {
    border-bottom: 1px solid #ddd;
    border-top: 0px solid #ccc;
    border-left: 0px solid #ccc;
    border-right: 0px solid #ccc;
    width: 100%;
    color: #28314b;
  }
  
  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 3px);
  }
  
  .emp-mgnt-select {
    height: calc(2rem + 2px);
    min-height: 30px;
  }
  
  .grey-bg {
    background: #eceff1;
    border-radius: 2px;
  }
  
  .f-w-6 {
    font-weight: 600;
  }
  
  .ui-widget {
    font-family: inherit;
    font-size: 12px;
  }
  
  .width-50 {
    width: 50px !important;
  }
  
  .width-75 {
    width: 75px !important;
  }
  
  .width-100 {
    width: 100px !important;
  }
  
  .width-150 {
    width: 150px !important;
  }
  
  .width-200 {
    width: 200px !important;
  }
  
  .width-250 {
    width: 250px !important;
  }
  
  .width-300 {
    width: 300px !important;
  }
  
  .z-index {
    z-index: 99999;
  }
  
  .form-removal-field button {
    max-width: 150px;
    display: inline-flex;
  }
  
  .show>.btn-secondary.dropdown-toggle {
    background: inherit;
    color: inherit;
    cursor: default;
    border-color: inherit;
    box-shadow: none;
  }
  
  .ui-datepicker.ui-widget .ui-datepicker-calendar td a {
    padding: 3px;
  }
  
  .ui-datepicker-calendar .ui-state-highlight {
    background-image: none;
  }
  
  .ui-state-default {
    border: none;
    border-bottom: 1px solid #d6d6d6;
  }
  
  .ui-inputtext.ui-state-focus,
  .ui-inputtext:focus {
    box-shadow: none;
  }
  
  .clscalander input {
    padding: 10px 10px 10px 0;
  }
  
  .clscalander .ui-calendar {
    width: 100%;
  }
  
  .wiz-bottom {
    border-bottom: 0px solid #dedede;
    border-left: 0px solid #dedede;
    border-right: 0px solid #dedede;
    border-top: 1px solid #dedede;
    padding: 14px 20px;
    text-align: right;
  }
  
  .selected-list .c-list .c-token {
    background: #eee;
    color: #67757c;
  }
  
  .list-area {
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: 0px 0px 0px #959595;
  }
  
  .popupcard2.shw-rside-68 {
    box-shadow: 0px 1px 20px 800px rgba(0, 0, 0, 0.35);
  }
  
  .popupcard2.shw-rside-75 {
    box-shadow: 0px 1px 20px 800px rgba(0, 0, 0, 0.35);
  }
  
  .popupcard2.shw-rside-60 {
    box-shadow: 0px 1px 20px 800px rgba(0, 0, 0, 0.35);
  }
  
  .popupcard2.shw-rside-30 {
    box-shadow: 0px 1px 20px 1200px rgba(0, 0, 0, 0.35);
  }
  
  .popupcard2.shw-rside-90 {
    box-shadow: 0px 1px 20px 800px rgba(0, 0, 0, 0.35);
  }
  
  .spinner {
    z-index: 999999;
  }
  
  .spinner img {
    width: 50px;
    height: 50px;
    margin: 0 auto;
  }
  
  .la-ball-clip-rotate-multiple {
    color: #00bfa5;
  }
  
  
  /* Dynamic form Css */
  
  .ng-invalid:not(form) {
    border-bottom: 0px solid #a94442;
  }
  
  .example-form {
    min-width: 150px;
    width: 100%;
  }
  
  .example-full-width {
    width: 100%;
  }
  
  .ng-valid[required],
  .ng-valid.required {
    border: none;
  }
  
  .profile-ad-emp-pic {
    width: 30px;
    height: 30px;
  }
  
  .name-column-add {
    width: 300px;
  }
  
  .w-40 {
    width: 40px;
  }
  
  .disabled {
    pointer-events: none;
    color: grey;
  }
  
  .ng-invalid:not(form) {
    border-bottom: 0px solid #a94442;
  }
  
  .example-form {
    min-width: 150px;
    width: 100%;
  }
  
  .example-full-width {
    width: 100%;
  }
  
  .cdk-overlay-container {
    z-index: 99999 !important;
  }
  
  .ng-valid[required],
  .ng-valid.required {
    border: none;
  }
  
  .grey-bg {
    background-color: #f5f7fa;
  }
  
  .vertical-radio-list li {
    padding: 10px 0 10px;
  }
  
  .autoCompleteOne {
    width: 100%;
  }
  
  .autoCompleteOne .ui-widget.ui-button {
    border: none;
    background: transparent;
    color: #333;
    left: -25px;
    top: 14px;
  }
  
  .autoCompleteOne .ui-widget.ui-button span::before {
    display: none;
  }
  
  .autoCompleteOne .ui-widget.ui-button span {
    background: url(../images/custom-select.png) center center no-repeat;
  }
  
  .ui-state-default {
    border: none;
    border-bottom: 1px solid #e9edf2;
  }
  
  .ui-inputtext.ui-state-focus,
  .ui-inputtext:focus {
    box-shadow: none;
    outline: 0;
  }
  
  .ui-multiselect:not(.ui-state-disabled):hover {
    box-shadow: none;
    outline: 0;
  }
  
  .autoCompleteOne .ui-state-highlight {
    background-image: none;
  }
  
  .ui-tabview.ui-tabview-left>.ui-tabview-nav li {
    margin: 7px 0 7px 0;
  }
  
  
  /* .tabsetemailtemp .ui-state-default {} */
  
  .tabsetemailtemp ul {
    border-bottom: solid 1px #f4f5f8;
  }
  
  .tabsetemailtemp ul li.ui-tabview-selected span::before {
    content: " ";
    position: absolute;
    width: 78%;
    height: 1px;
    bottom: 0;
    left: 15px;
    border-bottom: 2px solid #1b2436;
  }
  
  .ui-tabview .ui-tabview-nav>li.ui-state-default {
    background: transparent;
  }
  
  .chipMaterial {
    /* max-width: 100px; */
    display: block;
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
  }
  
  
  /* .btn {border-radius: 4px ;} */
  
  
  /* .btn {border-radius: 4px ;} */
  
  .nav-devider {
    height: 1px;
    display: block;
    margin: 12px 0;
  }
  
  .filter-icon {
    height: 55px;
    width: 55px;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    background-repeat: no-repeat;
    padding: 0;
    border-radius: 100%;
    box-shadow: 0 6px 6px 0 rgba(38, 46, 62, 0.16), 0 0 6px 0 rgba(0, 0, 0, 0.12);
  }
  
  .btn.disabled,
  .btn:disabled {
    opacity: 0.3;
    cursor: initial;
  }
  
  button:focus {
    outline: 0;
  }
  
  
  /* .btn {border-radius: 4px ;} */
  
  .emp_prof::before {
    content: " ";
    position: absolute;
    width: 30px;
    height: 1px;
    bottom: 0;
    left: 0;
    border-bottom: 2px solid #1b2436;
  }
  
  .emp_prof {
    position: relative;
  }
  
  .profile-progressbar .progress {
    height: 2px;
    background-color: #c9ccd2;
    width: 88%;
  }
  
  .profile-progressbar .progress-bar {
    background-color: #272c3e;
  }
  
  .width-84 {
    width: 84px;
  }
  
  .autocomplete-custome .ui-inputtext {
    width: 100%;
  }
  
  .ui-button-text-icon-left .ui-button-text {
    padding: 0.6em 1em 0.25em 2.1em;
  }
  
  .text-uppercase {
    text-transform: uppercase;
  }
  
  .w100 {
    width: 100%;
  }
  
  .w100 input {
    width: 100%;
  }
  
  .boder-radius0 {
    border-radius: 0 !important;
  }
  
  .border-bottom-section {
    border-bottom: solid 5px #eceff1;
  }
  
  
  /* show delete icon of question's options on hover */
  
  .OptionDeleteIcon {
    visibility: hidden;
  }
  
  .OptionContainer:hover .OptionDeleteIcon {
    visibility: visible;
  }
  
  
  /* end */
  
  #Fileupload .ui-fileupload-buttonbar {
    display: none;
  }
  
  #Fileupload .ui-fileupload-content {
    padding: 0;
  }
  
  #Fileupload .ui-widget-content {
    border: 0;
  }
  
  .category-close-icon {
    color: #fff;
    margin-left: 1em;
    margin-top: 4px;
  }
  
  
  /******************* custom tab css ******************/
  
  
  /* .custom-tab-two {
            padding: 16px 0px 17px;
          } */
  
  .custom-tab ul li a {
    padding: 20px 0px;
  }
  
  .custom-tab>ul {
    background: #fff;
    padding: 0px 20px 0px 32px;
    position: fixed;
    z-index: 9;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .custom-tab-content {
    display: block;
    padding: 20px 37px 80px 20px;
    margin-top: 55px;
  }
  
  .active-custom-tab {
    color: #1b2436;
    background-color: transparent;
    border-bottom: 2px solid #1b2436 !important;
    font-weight: 600;
  }
  
  .mat-drawer.mat-drawer-side {
    z-index: 1;
    border: 0;
  }
  
  /* .mat-drawer-content::-webkit-scrollbar {
    width: 5px;
  }
  
  .mat-drawer-content::-webkit-scrollbar-thumb {
    background: #666;
    border-radius: 20px;
  }
  
  .mat-drawer-content::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 20px;
  } */
  
  
  /* New Wizard Css */
  
  .centered-content {
    overflow: hidden;
    position: relative;
    width: auto;
    padding: 0px;
    margin: 0;
    /* border: 1px solid #dedede; */
  }
  
  .centered-content .btncls {
    border-top: 1px solid #dedede;
    border-right: 0px solid #dedede;
    border-bottom: 0px solid #dedede;
    padding: 14px 20px;
    width: 100%;
    border-left: 0;
    text-align: right;
  }
  
  .shw-rside-100 .btnclose,
  .shw-rside-95 .btnclose,
  .shw-rside-90 .btnclose,
  .shw-rside-60 .btnclose,
  .shw-rside-68 .btnclose,
  .shw-rside-75 .btnclose,
  .shw-rside-40 .btnclose,
  .shw-rside-50 .btnclose,
  .shw-rside-30 .btnclose {
    border-radius: 50%;
    text-align: center;
    position: absolute;
    z-index: 9;
    height: 40px;
    width: 40px;
    right: 36px;
    top: 22px;
  }
  
  .shw-rside-90 .btnclose .material-icons,
  .shw-rside-60 .btnclose .material-icons,
  .shw-rside-68 .btnclose .material-icons,
  .shw-rside-50 .btnclose .material-icons,
  .shw-rside-75 .btnclose .material-icons,
  .shw-rside-40 .btnclose .material-icons,
  .shw-rside-30 .btnclose .material-icons {
    font-size: 24px;
    line-height: 40px;
  }
  
  /* .shw-rside-50 .btnclose:hover,
  .shw-rside-90 .btnclose:hover,
  .shw-rside-60 .btnclose:hover,
  .shw-rside-68 .btnclose:hover,
  .shw-rside-75 .btnclose:hover,
  .shw-rside-40 .btnclose:hover,
  .shw-rside-30 .btnclose:hover {
    border-color: #f1f1f1;
    background: #f1f1f1;
    color: #212121;
  } */
  
  
  /***************** Placeholder Css ****************************/
  
  .side-table {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 20px 15px 10px 15px;
    overflow: hidden;
    margin-bottom: 20px;
    /* background-color: #fff; */
    /* border: 1px solid #e6e6e6; */
    /* border-radius: 2px; */
    background-color: #fff;
  }
  
  .side-table,
  .side-table *,
  .side-table ::after,
  .side-table ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  .side-table::before {
    content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    -webkit-animation: phAnimation 0.8s linear infinite;
    animation: phAnimation 0.8s linear infinite;
    background: -webkit-gradient(linear, left top, right top, color-stop(46%, rgba(255, 255, 255, 0)), color-stop(50%, rgba(255, 255, 255, 0.35)), color-stop(54%, rgba(255, 255, 255, 0))) 50% 50%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
  }
  
  .side-table>* {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .side-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    /* margin-bottom: 7.5px; */
  }
  
  .side-row div {
    height: 10px;
    margin-bottom: 7.5px;
    background-color: #ced4da;
  }
  
  .side-row .big,
  .side-row.big div {
    height: 17px;
    margin-bottom: 15px;
  }
  
  .side-row .empty {
    background-color: rgba(255, 255, 255, 0);
  }
  
  .side-col-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
  }
  
  .side-col-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
  }
  
  .side-col-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  
  .side-col-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
  }
  
  .side-col-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
  }
  
  .side-col-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  
  .side-avatar {
    position: relative;
    width: 100%;
    min-width: 60px;
    background-color: #ced4da;
    margin-bottom: 15px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .side-avatar::before {
    content: " ";
    display: block;
    padding-top: 100%;
  }
  
  .side-picture {
    width: 100%;
    height: 120px;
    background-color: #ced4da;
    margin-bottom: 15px;
  }
  
  @-webkit-keyframes phAnimation {
    0% {
      -webkit-transform: translate3d(-30%, 0, 0);
      transform: translate3d(-30%, 0, 0);
    }
  
    100% {
      -webkit-transform: translate3d(30%, 0, 0);
      transform: translate3d(30%, 0, 0);
    }
  }
  
  @keyframes phAnimation {
    0% {
      -webkit-transform: translate3d(-30%, 0, 0);
      transform: translate3d(-30%, 0, 0);
    }
  
    100% {
      -webkit-transform: translate3d(30%, 0, 0);
      transform: translate3d(30%, 0, 0);
    }
  }
  
  
  /************************** End placeholder css *****************************/
  
  .Noborder-radius {
    border-radius: 0 !important;
  }
  
  
  /********************* Link button class ****************************/
  
  .Link_button {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    /* color: #5c6bc0; */
    color: #f06292;
    outline: none !important;
  }
  
  .Link_button:hover {
    /* color: #1a3aea; */
    color: #ea7fa4;
  }
  
  .cdk-overlay-pane {
    margin-bottom: 10px;
    max-width: 100vw !important;
  }
  
  .off {
    background-color: transparent;
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    color: rgba(75, 84, 110, 0.47);
    border: 0;
    text-transform: uppercase;
  }
  
  .absent {
    background-color: rgba(218, 19, 10, 0.8);
    border: 0px solid rgba(218, 19, 10, 0.8);
    border-radius: 0;
    width: 20%;
  }
  
  #leaveDetails tr th {
    font-weight: 600;
    color: #262e3e;
  }
  
  
  /********************* tooltip css ********************************/
  .p-tooltip {
    z-index: 99999 !important;
  }
  
  .p-tooltip .p-tooltip-arrow {
    color: #282c3f;
  }
  

  
  .p-tooltip .p-tooltip-text {
    background-color: #282c3f;
    border-radius: 2px;
    font-size: 9px;
    padding: 8px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  
  .cls-tooltip.p-tooltip .p-tooltip-text {
    font-size: 10px;
    width: 350px;
  }
  
  
  /********************* Menu tooltip css ********************************/
  
  .sidebartooltip.p-tooltip .p-tooltip-arrow {
    color: #5068c2;
  }
  
  .sidebartooltip.p-tooltip-right .p-tooltip-arrow {
    border-width: .50em;
    left: -9px;
   
    margin-top: -0.46em;
  }

  
  .sidebartooltip.p-tooltip .p-tooltip-text {
    background-color: #5068c2;
    border-radius: 2px;
    font-size: 11px;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  
  
  /********************* Leave tooltip css ********************************/
  
 
  .Leavetooltip.p-tooltip-right .p-tooltip-arrow {
    border-width: .50em;
    left: -9px;
    border-right-color: #8267b1;
    margin-top: -0.46em;
  }
  
  .Leavetooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #8267b1;
  }
  
  .Leavetooltip.p-tooltip .p-tooltip-text {
    background-color: #8267b1;
    border-radius: 2px;
    font-size: 12px;
    padding: 15px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width: 333px;
  }
  
  
  /*********************Profile tooltip css ********************************/
  
  .sidebartooltip-profile.p-tooltip {
    left: 4.3% !important;
  }
  
  .sidebartooltip-profile.p-tooltip .p-tooltip-arrow {
    color: #5068c2;
  }
  
  .sidebartooltip-profile.p-tooltip-right .p-tooltip-arrow {
    border-width: .50em;
    left: -9px;

    margin-top: -0.46em;
  }
  
  
  .sidebartooltip-profile.p-tooltip .p-tooltip-text {
    background-color: #5068c2;
    border-radius: 2px;
    font-size: 11px;
    padding: 10px;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  
  
  /*************** calander css ********************/
  
  .fc td,
  .fc th {
    vertical-align: bottom;
  }
  
  
  /* .fc-basic-view .fc-body .fc-row{
                  max-height: 100% ;
                  height: auto ;
                  min-height: auto ;
                } */
  
  .fc-row .fc-content-skeleton {
    padding-bottom: 0px;
  }
  
  .clswiz_title {
    margin-bottom: 20px;
  }
  
  
  /***************** List style css **********************/
  
  .list-imgstyle {
    /* padding: 0 0 0 15px; */
    padding: 0;
    margin: 0;
    list-style: none;
    /* list-style-image: url(/assets/images/square.png); */
  }
  
  .list-imgstyle li {
    margin-bottom: 20px;
  }
  
  
  /****************** payroll css *********************/
  
  .card-border {
    border: solid 1px rgba(151, 151, 151, 0.25);
  }
  
  .clssteps {
    border-radius: 2px;
    font-size: 10px;
    font-weight: 600;
    padding: 5px 10px;
  }
  
  .clsfirststep {
    padding-left: 15px;
    margin-top: 20px;
    float: left;
    width: 15%;
  }
  
  .clspay_title {
    font-size: 12px;
    font-weight: 600;
    color: #262e3e;
    margin-bottom: 0;
    line-height: normal;
  }
  
  .clspay_subtitle {
    font-size: 10px;
    font-weight: 600;
    font-style: italic;
    text-align: right;
    color: rgba(38, 46, 62);
  }
  
  .clsanch_btn {
    font-size: 10px;
    text-align: right;
    color: #0076ff !important;
    font-weight: 600;
    text-decoration: underline !important;
  }
  
  .clssecontsteps {
    padding-left: 10px;
    float: left;
    width: 85%;
    /* border-right: solid 1px rgba(151, 151, 151, 0.13); */
    padding-top: 14px;
  }
  
  .cls-rightbr {
    border-right: solid 1px rgba(151, 151, 151, 0.13);
  }
  
  
  .row-list li:first-child {
    display: inline;
    margin-left: 0px;
    font-size: 10px;
    color: #262e3e;
    font-weight: 600;
  }
  
  .row-list li {
    display: inline;
    margin-left: 35px;
    font-size: 10px;
    color: #262e3e;
    font-weight: 500;
    list-style-type: disc;
  }
  
  
  #selectpad .mat-mdc-form-field-infix {
    border-top: 0px;
  }
  
  
  /************ panels css ***********/
  
  .TOleft-panel {
    background-color: #fff;
    margin-right: 2px;
    margin-left: 15px;
    width: 20%;
    max-width: 20%;
  }
  
  .TOright-panel {
    background-color: #fff;
    padding-top: 20px;
    max-width: 77%;
    width: 77%;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .tooltip_menu {
    background: #282c3f;
    width: 227px;
    border-radius: 25% 0 0;
    position: absolute;
    right: 35px;
    bottom: -133px;
  }
  
  .form-section-allassesment {
    position: relative;
  }
  
  
  /************** snakbar **********************/
  
  .floating-bar {
    position: fixed;
    bottom: 7px;
    left: 0;
    width: fit-content;
    padding: 20px 5px;
    border-radius: 4px;
    background-color: #fafafa;
    border: solid 1px rgba(0, 0, 0, 0.13);
    color: #262e3e;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 99;
    width: fit-content;
  }
  
  
  
  
  
  .clsadd-btn {
    border-radius: 2px;
    border: 0;
    font-size: 12px;
    padding: 0px;
    height: 24px;
    width: 24px;
    cursor: pointer;
  }
  
  
  .clsdelete-btn {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    padding: 0;
    border: 0;
    cursor: pointer;
  }
  
  .clsdelete-btn i {
    font-size: 18px;
    line-height: 24px;
  }
  
  
  
  .manage-empl-ps-update {
    height: calc(100vh - 144px);
    /* padding-right: 20px; */
  }
  
  .manage-empl-ps-new {
    height: calc(100vh - 175px);
    /* padding-right: 20px; */
  }
  
  .manage-empl-ps-profile {
    height: calc(100vh - 134px);
    /* padding-right: 20px; */
  }
  
  .new_profile_scrollClass {
    height: calc(100vh - 140px);
  }
  
  .mandatoryField .mat-mdc-form-field-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  
  
  .mandatoryField .mat-mdc-checkbox-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  
  .mandatoryField .mandatory-label:after {
    content: '*';
    color: red;
    margin-left: 2px;
  }
  
  .btn-modify {
    border: 1px dotted rgba(0, 0, 0, 0.25);
    background-color: transparent;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    cursor: pointer;
    height: 60px;
    font-size: 11px !important;
    letter-spacing: 1.5px;
  }
  
  .clsdetails {
    position: relative;
    top: -40px;
    /* left: -14px; */
    display: flex;
    height: 25px;
  }
  
  .clsesidetail {
    position: relative;
    top: -40px;
    left: 0px;
    display: flex;
    height: 25px;
  }
  
  .clslwfdetail {
    position: relative;
    top: -40px;
    left: -14px;
    display: flex;
    height: 25px;
  }
  
  .clsclanderview {
    position: relative;
    top: -1px;
    left: 17px;
    height: 25px;
  }
  
  .clsbonusview {
    position: relative;
    top: -40px;
    left: 14px;
    height: 25px;
  }
  
  .clsgratuitycal {
    position: relative;
    top: -40px;
    display: flex;
    height: 25px;
  }
  
  .clsexpdetail {
    position: relative;
    top: -37px;
    left: -14px;
    display: flex;
    height: 25px;
  }
  
  .clstaxdetail {
    position: relative;
    top: -40px;
    /* left: -14px; */
    display: flex;
    height: 25px;
  }
  
  .clsgray-list {
    max-width: 144px;
    width: 100%;
    height: 100%;
  }
  
  .clsgray-right-view {
    width: calc(100% - 144px);
    padding-left: 20px;
  }
  
  .list-type {
    padding: 3px 0 0 3px;
    list-style: none;
  }
  
  .list-type li {
    font-size: 11px;
    padding: 0px 16px;
    color: #282c3f;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    font-weight: 600;
    min-height: 37px;
    line-height: 37px;
    height: 100%;
    cursor: pointer;
  }
  
  .list-type li.selected {
    background: #fff;
   
  }
  
  .clsreq {
    float: left;
    font-size: 12px;
    line-height: 32px;
    /* color: #ff6f00; */
  }
  
  
  /************ Inbox css *********************/
  
  /* .inbox-title {
    
    font-size: 11px;
    font-weight: 600;
    padding: 16px 0 9px 0px;
    color: #333333;
    display: inline-block;
    letter-spacing: .5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
  
  .inbox-div {
    padding: 0px 0 0px 0px;
    color: #333333;
    font-size: 10px;
    letter-spacing: 0.1px;
    display: flex;
  }
  
  .inbox-div1 {
    padding: 5px 0 5px 0px;
    color: #333333;
    position: relative;
    display: flex;
  }
  
  .title-text {
    
    font-size: 10px;
    font-weight: 500;
    color: #333333;
  }
  
  .inbox-time {
    
    font-size: 9px;
    font-weight: 500;
    line-height: 1.2;
    color: #2d3547;
    padding: 19px 16px 15px 0px;
    display: block;
    text-align: right;
    letter-spacing: 0.1px;
  }
  
  .inbox-view {
    
    font-size: 11px;
    font-weight: 600;
    padding-right: 60px;
    color: #00bfa5 !important;
    text-decoration: underline !important;
  }
  
  .inbox-view:hover {
    color: #73ded0 !important;
  }
  
  .inbox-top-panel {
    min-height: 79px;
    background-color: #ffffff;
    width: 100%;
  } */
  
  /* .tab-card {
    background-color: #2d3547;
    border-bottom: solid 2px rgba(255, 255, 255, .1);
  } */
  
  /* .tab-card .active {
    background-color: rgba(255, 255, 255, .12);
  } */
  
  /* .tab-card .active .tab-card-Category,
  .tab-card .active .tab-card-confirm {
    color: #a7ffeb;
  
  } */
  

  
  .tab-card-conf {
    cursor: pointer;
   
  }
  
  .tab-card-conf:hover {
    cursor: pointer;

  }
  
  
  
  .Profile_des {
    font-size: 9px;
    font-weight: 500;
    color: rgba(35, 49, 87, 0.8);
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
  }
  
  .profile_text h5 {
    
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 0px;
    color: #28314b;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
  
  .clear_text {
    
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
    color: #00bfa5;
  }
  
  .clear_detail {
    
    font-size: 11px;
    font-weight: 500;
    color: #28314b !important;
    text-decoration: underline !important;
  }
  
  .clscardwidth {
    width: 90%;
    margin: 0 auto;
  }
  
  .clsfeedbackwidth {
    width: 90%;
    margin: 0 auto;
  }
  
  .clear_box {
    margin-top: 18px;
    margin-right: 2px;
  }
  
  .clsmod {
    display: inline-block;
    width: 100%;
  }
  
  .clsleft-view {
    background-color: #f5f7fa;
    max-width: 228px;
    width: 100%;
    padding: 0px 0px 0px 17px;
  }
  
  .clsright-view {
    max-width: calc(100% - 228px);
    width: 100%;
    padding: 0 30px 0 25px;
  }
  
  .clstabview {
    padding: 0;
    list-style: none;
  }
  
  .clstabview li {
    min-height: 30px;
    line-height: 30px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 500;
    cursor: pointer;
    padding-right: 15px;
  }
  
  .clstabview li span {
    display: inline-block;
    width: 86%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .clstabview li.selected {
    color: #00bfa5;
  }
  
  .clsicon {
    display: inline-block;
    font-size: 13px;
    padding: 8px 15px 0 0;
    text-align: right;
  }
  
  
  /****************** workflow css *****************/
  
  #workflow-box .workflow_base {
    margin: 50px 0 0;
  }
  
  
  /************* Scroll height css *******************/
  
  .sidebar-height {
    height: calc(100vh - 186px);
    padding-right: 20px;
  }
  
  .sidebar-height-role {
    height: calc(100vh - 186px);
  }
  
  .sidebar-body-height {
    height: calc(100vh - 186px);
  }
  
  .clsstep-height {
    height: calc(100vh - 162px);
    padding-right: 20px;
  }
  
  hr {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .ui-widget-overlay {
    background-color: rgba(34, 40, 59, .8) !important;
    /* opacity: .80 !important;
    filter: Alpha(Opacity=80) !important; */
  }
  
  #confirmationflow .workflow_base {
    margin: 23px 0 18px 0;
    max-width: 100%;
  }
  
  #Workflowid .workflow_base {
    margin: 50px 0 0;
    max-width: 100%;
  }
  
  #separationflow .workflow_base {
    margin: 23px 4% 18px 0;
    max-width: 100%;
  }
  
  .avatar-container {
    margin: auto;
  }
  
  .avatar-container .avatar-content {
    overflow: hidden;
  }
  
  
  /***** Report perfect scroll css **************/
  
  .clsreport-menu {
    height: calc(100vh - 187px);
  }
  
  .clsreport-increasemenu {
    height: calc(100vh - 126px);
  }
  
  .cls-reportchips {
    height: calc(100vh - 376px);
  }
  
  .cls-increasereportchips {
    height: calc(100vh - 308px);
  }
  
  .cls-reportcontent {
    height: calc(100vh - 251px);
  }
  
  .cls-increportcontent {
    height: calc(100vh - 190px);
  }
  
  .rightside-panel {
    /* width: 364px; */
    height: calc(100vh - 139px);
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.34);
  }
  
  .rightside-increasepanel {
    height: calc(100vh - 78px);
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.34);
  }
  
  
  /****** End Report perfect scroll css ************/
  
  .holds-the-iframe iframe {
    border: 0;
  }
  
  .employee-search a.btnclose {
    position: absolute;
    right: 28px;
    top: 25px;
  }
  
  .previewdilog a.btnclose {
    position: absolute;
    right: 20px;
    top: 14px;
  }
  
  .previewdilog.ui-dialog .ui-dialog-titlebar-icon {
    display: none;
  }
  
  .previewdilog iframe {
    border: 0;
  }
  
  .scrollheight-TMO {
    height: calc(100vh - 162px);
    padding-right: 20px;
  }
  
  #loanworkflow .workflow_base {
    background-color: #ffffff;
    margin: 49px 0px 0;
    text-align: center;
    display: flex;
    min-height: 300px;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 90%;
  }
  
  .showmore {
    font-size: 11px;
    font-style: italic;
    font-weight: 500;
    text-decoration: underline !important;
    color: #00bfa5 !important;
  }
  
  .clsbottom-btn {
    display: block;
    padding: 18px;
    background: transparent;
    border: 0;
    text-align: center;
    width: 100%;
    box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
    font-size: 14px !important;
    text-transform: uppercase;
  }
  
  .clsbtn-gray {
    box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
    background-color: #ffffff;
    color: #000;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    border: 0;
  }
  
  .clsbtn-gray:hover {
    color: #000;
  }
  
  .clsbtn-green {
    box-shadow: 0 -1px 5px 0 rgba(212, 212, 212, 0.5);
    background-color: #00bfa5;
    color: #fff;
    font-weight: 500;
    font-size: 14px !important;
    text-transform: uppercase;
    border: 0;
  }
  
  .clsbtn-green:hover {
    color: #fff;
  }
  
  .previewdilog iframe .ndfHFb-c4YZDc.ndfHFb-c4YZDc-e1YmVc .ndfHFb-c4YZDc-bnBfGc {
    background-color: transparent;
    filter: alpha(opacity=100);
    opacity: 1;
  }
  
  .clsnametext .subtitle {
    font-size: 11px !important;
    font-weight: 400 !important;
    color: rgba(40, 49, 75, .8) !important;
    margin-bottom: 0 !important;
    width: 100% !important;
    line-height: 20px;
    position: relative;
    z-index: 1;
    margin-top: 0 !important;
    letter-spacing: normal !important;
  }
  
  .clsinner-html {
    height: 100%;
    width: 100%;
    padding: 20px 20px 0;
  
  }
  .clsinner-html.img-auto img
  {
      max-width: 100%;
      width:auto;
  }
  .clsinner-html h3 {
    font-size: 14px;
    letter-spacing: 0.6px;
  }
  
  .clsinner-html .ql-align-justify {
    font-size: 12px;
    text-align: justify;
  }
  
  .clsinner-html img {
    width: 100%;
  }
  
  .clsinner-html p {
    font-size: 12px;
    text-align: justify;
    line-height: 1.9;
    word-break: break-word;
  }
  
  .clsinner-html ol,
  .clsinner-html ul {
    padding: 0 0 0 18px;
    font-size: 11px;
    line-height: 1.9;
    list-style: disc;
  }
  
  .clsiframe iframe.youtube-player {
    width: 100%;
    padding: 0 20px;
  }
  
  .clsinner-html-inner {
    height: 100%;
    width: 100%;
  }
  
  .clsinner-html-inner h3 {
    font-size: 14px;
    letter-spacing: 0.6px;
    margin-bottom: 15px;
  }
  
  .clsinner-html-inner .ql-align-justify {
    font-size: 12px;
    text-align: justify;
    margin-bottom: 15px;
  }
  
  .clsinner-html-inner img {
    width: 100%;
  }
  
  .clsinner-html-inner p {
    font-size: 12px;
    text-align: justify;
    margin-bottom: 15px;
  }
  
  .clsinner-html-inner ol,
  .clsinner-html-inner ul {
    padding: 0 0 0 12px;
    font-size: 12px;
  }
  
  .clsinner-html-inner iframe.youtube-player {
    width: 100%;
  }
  
  #clshd-request .header-title {
    margin-left: 0 !important;
  }
  
  #Site-Captcha div {
    margin: auto;
  }
  
  .clsfilter-hr {
    background-color: #d8d8d8;
    height: 1px;
    width: 91%;
    margin: -10px 0 0 0;
    position: relative;
    left: 15px;
  }
  
  
  .readonlyDotted.mat-mdc-form-field-appearance-legacy .mat-mdc-form-field-underline {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .42) 0, rgba(0, 0, 0, .42) 33%, transparent 0);
    background-size: 4px 100%;
    background-repeat: repeat-x;
    background-position: 0;
  }
  
  .readonly[readonly] {
    opacity: 0.5;
  }
  
  .leaflet-control-attribution {
    display: none;
  }
  
  
  /*        btn css         */
  
  .cls-defaultbtn {
    padding: 7px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600 !important;
    
    border-radius: 4px 0px 0px 4px !important;
    text-transform: uppercase;
    background: transparent !important;
    border: solid 1px #1b2436 !important;
    color: inherit !important;
    width: 50%;
  }
  
  .cls-defaultbtn.active,
  .cls-defaultbtn.disabled.active,
  .cls-defaultbtn.disabled:active,
  .cls-defaultbtn.disabled:focus,
  .cls-defaultbtn.disabled:hover,
  .cls-defaultbtn:active,
  .cls-defaultbtn:focus,
  .cls-defaultbtn:hover,
  .cls-defaultbtn:not(:disabled):not(.disabled).active,
  .cls-defaultbtn:not(:disabled):not(.disabled):active,
  .show-more:hover,
  .show>.cls-defaultbtn.dropdown-toggle {
    box-shadow: rgba(119, 119, 119, 0.42) 0px 14px 26px -12px, rgba(0, 0, 0, 0.12) 0px 4px 23px 0px, rgba(92, 92, 92, 0.2) 0px 8px 10px -5px;
    background-color: transparent !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(27, 36, 54);
    border-image: initial;
  }
  
  .cls-sucessbtn {
    padding: 7px 20px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600 !important;
    border-radius: 0px 4px 4px 0px !important;
    background: #00bfa5 !important;
    border: 1px solid #00bfa5 !important;
    box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2), 0 1px 5px 0 rgba(40, 190, 189, 0.12);
    transition: 0.2s ease-in;
    color: #fff !important;
    width: 50%;
  }
  
  .cls-sucessbtn.disabled,
  .cls-sucessbtn:disabled,
  .cls-defaultbtn.disabled,
  .cls-defaultbtn:disabled {
    opacity: 0.3;
  }
  
  .cls-sucessbtn.disabled:hover,
  .cls-sucessbtn:hover {
    background: #00bfa5 !important;
    box-shadow: 0 14px 26px -12px rgba(40, 190, 189, 0.42), 0 4px 23px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(40, 190, 189, 0.2);
    border: 1px solid #00bfa5;
    color: #fff !important;
  }
  
  .validation-error {
    color: #f44336;
    font-size: 10px;
    font-weight: 400;
    margin-top: 2px;
  }
  
  
  /* Ajay Attachment with multiple files */
  
  .FileShareDialog {
    border-radius: 4px !important;
    box-shadow: none;
    background-color: #ffffff;
  }
  
  .FileShareDialog .fileshare-container .chat-dialog-header .chat-dialog-header-title {
    color: #000;
    font-size: 24px;
    font-weight: 800;
    line-height: 36px;
  }
  
  .chat-file-attach .p-fileupload-buttonbar {
    border: dashed 2px #cfcfcf;
    margin-bottom: 32px;
    height: 148px;
    border-radius: 5px;
    background: transparent;
  }
  
  .chat-file-attach .p-fileupload-buttonbar {
    padding: 0;
  }
  
  .chat-file-attach .p-fileupload-buttonbar .p-fileupload-choose {
    width: 100%;
    height: 100%;
    background: transparent !important;
    position: relative;
    z-index: 99;
    border: 0;
  }
  
  .chat-file-attach .p-fileupload-buttonbar .p-fileupload-choose .p-button-label {
    display: none;
  }
  
  .chat-file-attach .p-button-icon-left {
    display: none;
  }
  
  .dropfile-msg {
    position: absolute;
    top: 2px;
    left: 0;
    background: #f8f8f9;
    width: calc(100% - 4px);
    height: calc(148px - 4px);
    text-align: center;
    padding: 24px;
    z-index: 9;
    right: 0;
    margin: auto;
  }
  
  .chat-file-attach .p-fileupload-choose .p-button-text {
    opacity: 0;
  }
  
  .dropfile-msg p {
    font-size: 16px;
    font-weight: 500;
    color: #9e9e9e;
    line-height: 24px;
    margin: 0;
  }
  
  .dropfile-msg p b {
    display: block;
    color: #12b995;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-top: 22px;
  }
  
  .chat-file-attach .p-fileupload-content {
    border: 0;
    padding: 0;
  }
  
  .chat-file-attach .p-fileupload-content .p-progressbar {
    display: none;
  }
  
  .chat-file-attach .p-fileupload-content .p-fileupload-files {
    width: 100%;
  }
  
  .chat-file-attach .p-fileupload-content .p-fileupload-files .p-fileupload-row {
    display: flex;
    align-items: center;
    background: rgba(200, 205, 209, 0.25);
    overflow: hidden;
    border: solid 2px #cfcfcf;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 5px;
    justify-content: space-between;
  }
  
  .chat-file-attach .p-fileupload-row>div {
  
    padding: 0;
  
  }
  
  .chat-file-attach .p-fileupload-row>div:first-child {
    margin-right: 0.5rem;
    width: 45px;
    overflow: hidden;
    height: 24px;
  }
  
  .chat-file-attach .p-fileupload-row>div:first-child img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .chat-file-attach .p-fileupload-row>div:last-child {
    float: right;
    margin-bottom: 0;
  }
  
  .chat-file-attach .p-fileupload-row>div:last-child button {
    background-color: transparent;
    border: none !important;
    background-image: url("/assets/images/dlt-solid.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center top;
    height: 18px;
    width: 18px;
  
  }
  
  .chat-file-attach .p-fileupload-row>div:last-child button .p-button-icon {
    display: none;
  }
  
  /* .chat-file-attach .p-fileupload-row>div:last-child button:hover {
    background-color: transparent !important;
    background-image: url("/assets/images/dlt-solid.png") !important;
    background-size: 24px !important;
    background-repeat: no-repeat !important;
    background-position: center top !important;
  } */
  
  .chat-file-attach .p-fileupload-row>div:last-child button:focus {
    background-color: transparent !important;
  }
  
  .viewdilog {
    border: none;
  }
  
  .viewdilog a.btnclose {
    position: absolute;
    right: 34px;
    top: 25px;
  }
  
  .viewdilog .ui-dialog-content {
    padding: 0 !important;
    height: 100%;
    overflow: hidden;
  }
  
  .FileShareDialog .ui-dialog-footer {
    padding: 12px 40px;
  }
  
  .mx-height {
    max-height: 355px;
  }
  
  #container>.highcharts-container>.highcharts-root>.highcharts-credits {
    display: none !important;
  }
  
  .col-xs-15,
  .col-sm-15,
  .col-md-15,
  .col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col-xs-15 {
    width: 20%;
    float: left;
  }
  
  @media (min-width: 576px) {
    .col-xs-15 {
      width: 20%;
      float: left;
    }
  }
  
  @media (min-width: 768px) {
    .col-sm-15 {
      width: 20%;
      float: left;
    }
  }
  
  @media (min-width: 992px) {
    .col-md-15 {
      width: 20%;
      float: left;
    }
  }
  
  @media (min-width: 1200px) {
    .col-lg-15 {
      width: 20%;
      float: left;
    }
  }
  
 
  
  .hr-desk-team .ps-content {
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  
  
  
  /* Rating================ */
  .rateLast {
    margin-bottom: 10px;
    padding-left: 64px;
  }
  
  .rateLast .br {
    margin: 0 !important;
  }
  
  .rate-popup {
    border-radius: 3px !important;
    overflow: hidden;
  }
  
  .rate-popup p {
    font-size: 14px;
    color: #28314b;
    line-height: 23px;
    margin-bottom: 0;
    word-break: break-word;
  }
  
  .rate-popup p span {
    color: #00bfa5;
    font-weight: 600;
  }
  
  .rateLast .br-units {
    justify-content: flex-start !important;
  }
  
  
  .mix-up-form form {
    padding-top: 14px;
  }
  
  .mix-up-form form label {
    left: 0 !important;
    font-weight: 500;
  }
  
  .mix-up-form form input {
    padding-left: 0 !important;
  }
  
  .mix-up-form form p-calendar span {
    width: 100%;
  }
  
  .mix-up-form form p-calendar span input {
    width: 100%;
  }
  
  .km-hide-kommunicate-iframe {
    display: none !important
  }
  
  .km-iframe-closed {
    display: none !important;
  }
  
  
  .mx-height-32 .avatar-content {
    max-height: 32px;
  }
  
  
  .clsgraphrow .mat-mdc-tab-body-content {
    min-height: 263px;
  }
  
  .clsdetails-text {
    
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    color: #262e3e !important;
  
  }
  
  .clsdetails-text:hover {
    text-decoration: underline !important;
  }
  
  
  
  
  .mck-box-top {
    background: #1b2436 !important;
  }
  
  
  .avatar-preview {
    padding: 0 24px 24px;
  }
  
  .avatar-preview .ui-dialog-content {
    background: #eeeeee;
  }
  
  .avatar-preview .btnclose {
    right: 0;
  }
  
  
  /* .holiday-step4-content ul{
    list-style-type: disc;
    padding: 0 0 0 32px;
  } */
  
  
  
  .STATUS-TERMINATED,
  .STATUS-REJECTED {
    color: #ff001f !important;
  }
  
  .STATUS-PENDING,
  .STATUS-EXTENDED {
    color: #ffb22b !important;
  }
  
  .STATUS-CONFIRMED,
  .STATUS-COMPLETED {
    color: #00bfa5 !important;
  }
  
  .notif-content a {
    text-decoration: underline;
    font-size: 12px;
  }
  
  .shw-rside-57 {
  
    width: calc(100% - 57%) !important;
  }
  
  .readonly-editor .p-editor-container .p-editor-toolbar.ql-toolbar {
    display: none;
    border: none;
  }
  
  .readonly-editor .p-editor-container .p-editor-content.ql-snow {
    border: none;
  }
  
  .readonly-editor .p-editor-container .p-editor-content .ql-editor {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 20px;
  }
  
  .box-shadow-none {
    box-shadow: none !important;
  }