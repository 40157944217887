// global 

.p-calendar {
  .p-datepicker-header { 
    .p-datepicker-title {
      line-height: 1rem;
      .p-link {
        font-weight: 500;
        padding: 0;
        pointer-events: none;
        line-height: 0.73px;
      }
    }
    .p-datepicker-prev,
    .p-datepicker-next {
      .p-datepicker-prev-icon,
      .p-datepicker-next-icon {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
}


// Expense calendar
.cls-exp-calaender {
    span.p-calendar {
      width: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include margin(left, top, right, bottom, 0, 6, 0, 0);
      display: block;
  
      &.p-calendar-w-btn {
        width: 100%;
  
        .p-inputtext {
          font-size  : $text-default-size;
          font-family: $font-family;
          width      : calc(100% - 38px);
          color      : $primary-text-color;
          font-weight: map-get($font-weights, "medium");
          @include border-pos(bottom, 1px, solid, #e9edf2);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);
          @include padding(left, top, right, bottom, 0, 5, 0, 5);
          @include border-radius(0px);
  
          &:focus {
            box-shadow: none;
          }
        }
  
        .p-button {
          background: transparent;
          @include border-pos(bottom, 1px, solid, #e9edf2);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);
          color: #000;
  
          &:focus {
            box-shadow: none;
          }
  
          &:hover {
            background: transparent;
          }
  
          .p-button-text {
            padding: 0.5em;
          }
        }
      }
  
      .p-datepicker {
        .p-datepicker-group {
          .p-datepicker-header {
            background: transparent;
          }
  
          .p-datepicker-title {
            font-family: $font-family;
            font-size  : 14px;
            font-weight: map-get($font-weights, "semi-bold");
          }
  
          .p-datepicker-calendar-container {
            .p-datepicker-calendar {
              thead {
                tr {
                  th {
                    @include background-color($utl-grey-color-5);
                    font-family: $font-family;
                    font-size  : $text-medium-size;
                    font-weight: map-get($font-weights, "semi-bold");
                  }
                }
              }
  
              tbody {
                tr {
                  td {
                    font-family: $font-family;
                    font-size  : $text-size-11;
                    font-weight: map-get($font-weights, "medium");
  
                    span {
                      height: auto;
                      width : auto;
  
                      &:hover {
                        @include background-color($utl-grey-color-5);
                        color: $utl-text-color-14;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  

  // team leave calander css

  .cls-team-calaender{
&.p-element{
.p-calendar{
  .p-datepicker{
    .p-datepicker-group-container{
      .p-datepicker-calendar-container{
        .p-datepicker-calendar{
          thead{
            tr{
              th{
                font-size: 12px !important;
              }
            }
          }
          tbody{
            tr{
              td{
                font-size: 10px !important;
              }
            }
          }
        }
      }
    }
  }
}
}
  }