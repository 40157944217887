@mixin box-shadow($top, $left, $blur, $color) {
      -webkit-box-shadow: $top $left $blur $color;
      -moz-box-shadow: $top $left $blur $color;
      box-shadow: $top $left $blur $color;
}
  
@mixin flexBox($vAligh, $hAlign){
    display: flex;
    justify-content: $hAlign; 
    align-items: $vAligh; 
}


// spacing mixins
@mixin app-spacing($className, $styleName) {
	@for $i from 1 through 140 {
		#{$className + $i} {
			#{$styleName}: #{$i}px;
		}
	}
}

@include app-spacing('.p-l-', 'padding-left');
@include app-spacing('.p-r-', 'padding-right');
@include app-spacing('.p-t-', 'padding-top');
@include app-spacing('.p-b-', 'padding-bottom');
@include app-spacing('.p-a-', 'padding');

@include app-spacing('.m-l-', 'margin-left');
@include app-spacing('.m-r-', 'margin-right');
@include app-spacing('.m-t-', 'margin-top');
@include app-spacing('.m-b-', 'margin-bottom');
@include app-spacing('.m-a-', 'margin');