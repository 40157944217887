@import "../../theme/main.scss";

.clssearch-emp,
.clssearch-module {
  font-size: inherit;

  span.p-autocomplete {
    display: block;
    font-size: inherit;

    &.p-autocomplete-multiple {
      .p-inputtext {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        @include padding(left, top, right, bottom, 10, 0, 10, 0);
        min-height: 42px;
        height: auto;
        line-height: 40px;
        width: 100%;
        font-family: $font-family;
        font-size: $text-default-size;
        color: $secondary-text-color;
        width: 100%;
        font-weight: map-get($font-weights, "medium");
        flex-wrap: wrap;

        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }
        }
      }

      .p-autocomplete-multiple-container {
        box-shadow: none;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        font-size: inherit;
        flex-wrap: wrap;
        gap: 0;

        &:not(.p-disabled) {
          &.p-focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }

          &:hover {
            border-color: #e3e3e3;
          }
        }

        .p-autocomplete-input-token {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);

          input {
            font-family: $font-family;
            font-size: $text-default-size;
            color: $secondary-text-color;
            width: 100%;
            font-weight: map-get($font-weights, "medium");
            height: 40px;
            line-height: 40px;
            box-shadow: none;
            @include padding(left, top, right, bottom, 15, 5, 0, 5);
          }
        }
      }

      &:focus {
        box-shadow: none;
        border-color: #e3e3e3;
      }

      &:hover {
        border-color: #e3e3e3;
      }

      .p-autocomplete-token {
        @include margin(left, top, right, bottom, 5, 5, 0, 0);
        line-height: initial;
      }
    }

    .p-inputtext {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      @include padding(left, top, right, bottom, 10, 0, 10, 0);
      min-height: 42px;
      height: 100%;
      line-height: 42px;
      width: 100%;
      font-family: $font-family;
      font-size: $text-default-size;
      width: 100%;
      font-weight: map-get($font-weights, "medium");
      flex-wrap: wrap;

      &:enabled {
        &:focus {
          box-shadow: none;
          border-color: #e3e3e3;
        }
      }
    }

    .p-autocomplete-panel {
      .p-autocomplete-items {
        .p-autocomplete-item {
          white-space: normal;

          &.p-highlight {
            color: #333 !important;
            @include background-color(#eaeaea !important);
          }
        }
      }
    }

    .p-autocomplete-loader {
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      color: $utl-theme-color;
    }
  }
}

// Insight Auto Search
.cls-advfilter {
  .cls-searchbox {
    position: relative;

    .searchIcon {
      position: absolute;
      z-index: 9;
    }

    p-autoComplete {
      width: 100%;

      span {
        &.p-component {
          font-family: $font-family;
          font-size: inherit;
          width: 100%;

          input {
            height: 30px;
            background: #fff;
            padding-left: 30px;
            font-family: $font-family;
            font-size: $text-default-size;
            color: $secondary-text-color;
            width: 100%;
            @include border(0px, solid, transparent);

            &:enabled {
              &:focus {
                box-shadow: none;
              }
            }
          }
          .p-element{
            .p-overlay{
              width: 100%;
              .p-overlay-content{
                .p-autocomplete-panel{
                  .p-autocomplete-items{
                    .p-autocomplete-item{
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cls-advfilter {
  .cls-newsearchbox {
    position: relative;
    display: flex;
    align-items: center;

    .cls-search1 {
      display: flex;
      align-items: center;
      border: 1px solid #d1d1d1;
      border-radius: 4px 0px 0px 4px;
      height: 40px;
      @include background-color;
      padding: 0 0 0 15px;

      p-autoComplete {
        width: 100%;

        span {
          &.p-component {
            font-family: $font-family;
            font-size: inherit;
            width: 100%;

            input {
              height: 40px;
              min-width: 340px;
              @include background-color(transparent);
              font-family: $font-family;
              font-size: $text-default-size;
              color: $secondary-text-color;
              width: 100%;
              @include border(0px, solid, transparent !important);

              &:enabled {
                &:focus {
                  box-shadow: none;
                }
              }
            }
            .p-element{
              .p-overlay{
                width: 100%;
                .p-overlay-content{
                  .p-autocomplete-panel{
                    .p-autocomplete-items{
                      .p-autocomplete-item{
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .cls-search2 {
      display: flex;
      align-items: center;
      border: 1px solid #d1d1d1;
      border-radius: 0px 4px 4px 0px;
      height: 40px;
      @include background-color;
      padding: 0 0 0 15px;
      margin-right: 8px;

      p-autoComplete {
        width: 100%;

        span {
          &.p-component {
            font-family: $font-family;
            font-size: inherit;
            width: 100%;

            input {
              height: 40px;
              @include background-color(transparent);
              font-family: $font-family;
              font-size: $text-default-size;
              color: $secondary-text-color;
              width: 100%;
              @include border(0px, solid, transparent !important);

              &:enabled {
                &:focus {
                  box-shadow: none;
                }
              }
            }
            .p-element{
              .p-overlay{
                width: 100%;
                .p-overlay-content{
                  .p-autocomplete-panel{
                    .p-autocomplete-items{
                      .p-autocomplete-item{
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .cls-secbtn {
      height: 40px;
      width: 40px;
      @include background-color;
      @include border-radius;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

    }
  }
}

// Team
.teamFilter {
  height: 32px;
  @include background-color($utl-white);
  @include border-radius;
  display: flex;
  align-items: center;
  @include border(1.5px, solid, #e5e5e5);
  img {
    @include margin(left, top, right, bottom, 4, 0, 0, 0);
  }

  p-autoComplete {
    width: 100%;
     span {
      input {
        @include background-color;
        width: 100%;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border(0px, solid, transparent);
        font-size: $text-medium-size;

        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }
        }
      }
    }
  }
}

// Wall
.wall-emp-right {
  p-autoComplete {
    span {
      input {
        background-color: #fff;
        padding-left: 0 !important;
        border: none;
        width: 100%;
        font-size: $text-medium-size;

        &:enabled {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

// Goal and Initiative
.floatlabelAutocomplete {
  label {
    margin: 0;
    font-size: 10px !important;
  }

  p-autoComplete {
    span {
      input {
        background-color: #fff;
        padding-left: 0 !important;
        border: none;
        border-bottom: solid 1px #ddd;
        height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
      }
    }
  }
}

.p-float-label {
  display: flex;
  flex-flow: column-reverse;
  @include margin(left, top, right, bottom, 0, 0, 0, 0);

  p-autocomplete {
    span.autoCompleteOne {
      position: relative;

      input {
        font-family: $font-family;
        font-size: $text-medium-size;
        width: 100%;
        font-weight: map-get($font-weights, "regular");
        height: auto;
        @include border-pos(top, 0px, solid, transparent);
        @include border-pos(right, 0px, solid, transparent);
        @include border-pos(left, 0px, solid, transparent);
        box-shadow: none;
        @include background-color(transparent);
        @include padding(left, top, right, bottom, 0, 0, 0, 6);
        transition: all 0.2s;
        touch-action: manipulation;
        @include border-radius(0);

        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-autocomplete-panel {
      @include padding(left, top, right, bottom, 0, 0, 10, 0);
      width: 100%;

      .p-autocomplete-items {
        .p-autocomplete-item {
          white-space: normal;

          &.p-highlight {
            color: #333 !important;
            @include background-color(#eaeaea !important);
          }
        }
      }
    }

    .p-autocomplete-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 10px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar {
      width: 8px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  &:focus~label {
    top: -0.45rem !important;
    font-size: $text-size-11 !important;
    left: 0;
  }

  .p-inputwrapper-focus~label {
    top: -0.4rem !important;
    font-size: $text-size-11 !important;
    left: 0;
  }

  .p-inputwrapper-filled~label {
    top: -0.4rem !important;
    font-size: $text-medium-size !important;
    left: 0;
  }

  & label {
    left: 0;
    color: #000;
  }

  label {
    left: 0;
    color: #000;
  }
}

p-autocomplete {
  span.autoCompleteOne {
    position: relative;

    input {
      font-family: $font-family;
      font-size: $text-medium-size;
      color: $secondary-text-color;
      width: 100%;
      font-weight: map-get($font-weights, "medium");
      height: auto;
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(bottom, 1px, solid, #e3e3e3);
      box-shadow: none;
      @include background-color(transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 6);
      transition: all 0.2s;
      touch-action: manipulation;
      @include border-radius(0);

      &:enabled {
        &:focus {
          box-shadow: none;
          border-color: #e3e3e3;
        }
      }
    }

    p-overlay {
      .p-overlay.p-component {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
}

// common autocomplete css
.autoCompleteOne {
  &.p-autocomplete {
    .p-autocomplete-multiple-container {
      width: 100%;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border-pos(top, 0px, solid, transparent);
      @include border-pos(right, 0px, solid, transparent);
      @include border-pos(left, 0px, solid, transparent);
      @include border-pos(bottom, 1px, solid, $utl-grey-color-26);
      @include border-radius(0);
      @include background-color(transparent);
      flex-wrap: wrap;
  

      &:not(.p-disabled) {
        &.p-focus {
          box-shadow: none;
          border-color: $utl-grey-color-26;
        }

        &:hover {
          border-color: $utl-grey-color-26;
        }
      }

      .p-autocomplete-input-token {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);

        input {
          @include padding(left, top, right, bottom, 0, 0, 0, 5);
          @include border-pos(top, 0px, solid, transparent);
          @include border-pos(right, 0px, solid, transparent);
          @include border-pos(left, 0px, solid, transparent);
          @include border-pos(bottom, 0px, solid, $utl-grey-color-26);
          font-family: $font-family;
          font-size: 14px;
        }
      }

      .p-autocomplete-token {
        @include border-radius(2px);

        .p-autocomplete-token-label {
          font-family: $font-family;
          font-size: 12px;
          font-weight: map-get($font-weights, "regular");
        }

        .p-autocomplete-token-icon {
          font-size: 10px;
          cursor: pointer;
         padding-right: 5px;
        }
      }
    }

    .p-autocomplete-loader {
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      // color: $utl-theme-color;
    }
  }
}

//Expanse payment search
.cls-searchbox {
  .cls-innersearch {
    .auto-One {
      width: calc(100% - 50px);
      @include padding(left, top, right, bottom, 2, 0, 15, 0);

      .p-autocomplete-multiple-container {
        flex-wrap: wrap;
        gap: 0;

        .p-autocomplete-input-token {
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          input {
            font-family: $font-family;
            font-size: inherit;
          }
        }

        &.p-inputtext {
          @include border(0px, solid, transparent);
          width: 100%;
          font-size: inherit;
          @include padding(left, top, right, bottom, 5, 5, 5, 5);

          &:not(.p-disabled) {
            &.p-focus {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .cls-searchbtn {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, regular);
    text-align: center;
    @include border-radius;
    border-width: 1px;
    @include padding(left, top, right, bottom, 16, 10, 16, 10);
    @include margin(left, top, right, bottom, 9, 0, 0, 0);
    text-transform: uppercase;
    cursor: pointer;
  }
}

// Search employee

.teamautocomplete {
  &.p-autocomplete {
    width: 100%;
    @include background-color;
    @include border-radius(2px);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
    @include border(1px, solid, rgba(151, 151, 151, 0.27));
    @include margin(left, top, right, bottom, 0, 0, 0, 10);

    .p-inputtext {
      @include background-color(transparent);
      width: 100%;
      transition: 0.2s;
      @include border(0px, solid, transparent);
      @include padding(left, top, right, bottom, 10, 10, 10, 10);

      &:enabled {
        &:focus {
          box-shadow: none;
        }
      }
    }

    .p-autocomplete-panel {
      width: 100%;

      .p-autocomplete-items {
        .p-autocomplete-item {
          white-space: normal;
          word-break: break-all;

          &.p-highlight {
            color: #333 !important;
            @include background-color(#eaeaea !important);
          }
        }
      }
    }

    .p-autocomplete-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 10px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar {
      width: 8px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .p-autocomplete-loader {
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      // color: $utl-theme-color;
    }
  }
}

//candiadate search

.search-candidate,
.fix {
  .autoCompleteOne {
    &.p-autocomplete {
      .p-inputtext {
        width: 100%;
        @include padding(left, top, right, bottom, 40, 8, 20, 8);
        @include border(1px, solid, #e5e5e5);
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");

        &:hover {
          @include border(1px, solid, #e5e5e5);
        }

        &:focus {
          box-shadow: none;
        }
      }

      .p-autocomplete-loader {
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        // color: $utl-theme-color;
      }
    }
  }
}

.teamautocomplete .ui-autocomplete-items .ui-state-highlight {
  background-position: right 5px !important;
  padding: 8px;
}

// payment on hold
.app-attendance-search {
  .autoCompleteOne {
    &.p-autocomplete {
      .p-inputtext {
        width: 100%;
        @include padding(left, top, right, bottom, 15, 15, 15, 15);
        @include border(1px, solid, #e5e5e5);
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "regular");

        &:hover {
          @include border(1px, solid, #e5e5e5);
        }

        &:focus {
          box-shadow: none;
        }
      }

      .p-autocomplete-loader {
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        // color: $utl-theme-color;
      }
    }
  }

  .closeicon {
    position: absolute;
    right: 50px;
    margin-top: 15px;
  }
}

// multi auto completer

.autoCompleteOne {
  &.p-autocomplete {
    .p-autocomplete-multiple-container {
      flex-wrap: wrap;
     

      .p-autocomplete-token {
        display: flex;
        align-items: center;
       

        .p-autocomplete-token-label {
          font-family: $font-family;
          font-size: 12px;
          font-weight: map-get($font-weights, "medium");
        
        }

        .p-autocomplete-token-icon {
          // color: #424242;
        }
      }
    }

    .p-autocomplete-panel {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      width: 100%;
      max-height: 140px !important;
      min-width: 100%;
      overflow-y: scroll;

      .p-autocomplete-list-item {
        @include padding(left, top, right, bottom, 10, 10, 20, 10);
        @include border-pos(bottom, 1px, solid, #ccc);

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }

      .p-autocomplete-items {
        .p-autocomplete-item {
          white-space: normal;

          &.p-highlight {
            color: #333 !important;
            @include background-color(#eaeaea !important);
          }
        }
      }
    }

    .p-autocomplete-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 10px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar {
      width: 8px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .p-autocomplete-loader {
      font-size: $heading-s-medium-font-size;
      font-weight: map-get($font-weights, "semi-bold");
      // color: $utl-theme-color;
    }
  }
}

//internal-recruiter-drop-down

.internal-recruiter-drop-down {
  .p-float-label {
    p-autocomplete {
      .p-autocomplete-panel {
        min-width: 400px;
        @include padding(left, top, right, bottom, 0, 0, 10, 0);
        width: 100%;
      }

      .p-autocomplete-panel::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: 10px;
        background-color: #f0f2f4;
      }

      .p-autocomplete-panel::-webkit-scrollbar {
        width: 8px;
        background-color: #f0f2f4;
      }

      .p-autocomplete-panel::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: none;
        background-color: rgba(0, 0, 0, 0.1);
      }

      .p-autocomplete-loader {
        font-size: $heading-s-medium-font-size;
        font-weight: map-get($font-weights, "semi-bold");
        // color: $utl-theme-color;
      }
    }
  }
}

//common css
.p-autocomplete {
  .p-autocomplete-panel {
    width: 100%;
    font-weight: 500;
  }

  .p-autocomplete-panel::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: #f0f2f4;
  }

  .p-autocomplete-panel::-webkit-scrollbar {
    width: 8px;
    background-color: #f0f2f4;
  }

  .p-autocomplete-panel::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: rgba(0, 0, 0, 0.1);
  }
}

// global search

#Global-action {
  span.autoCompleteTwo {
    width: calc(100% - 40px);
    display: inline-flex;

    &.gshomeinput-sec {
      .p-autocomplete-multiple-container {
        @include border-radius(0);
        gap: 0;
      }
    }

    .p-autocomplete-multiple-container {
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      @include padding(left, top, right, bottom, 10, 5, 10, 5);
      min-height: 42px;

      .p-autocomplete-input-token {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);

        input {
          font-family: $font-family;
          font-size: $text-default-size;
          width: 100%;
          font-weight: map-get($font-weights, "medium");
          height: auto;
          line-height: normal;
          box-shadow: none;
          @include padding(left, top, right, bottom, 5, 0, 0, 0);
        }
      }
    }
  }
}

/*************** Org chart search *******************/
.cls-orgsearchbox {
  width: calc(100% - 188px);
  position: relative;

  .cls-org-search {
    border-radius: 4px 0 0 4px;

    @include padding(left, top, right, bottom, 0, 0, 0, 0);
    display: inline-block;
    width: calc(100% - 112px);

    i.fa-sharp {
      font-size: 16px;
      @include margin(left, top, right, bottom, 12, 0, 0, 0);
    }

    .p-inputwrapper {
      width: 100%;

      .auto-One {
        width: calc(100% - 0px);
        @include padding(left, top, right, bottom, 15, 0, 0, 0);
        height: 36px;

        .p-inputtext {

          width: 100%;

          &:enabled {
            &:focus {
              box-shadow: none;
            }
          }
        }

        .p-autocomplete-dropdown {
          display: none;
        }

        .p-autocomplete-panel {
          top: 40px !important;

          ul {
            padding: 0;

            .p-autocomplete-list-item {
              padding: 11px 18px 11px 18px !important;

              .p-helper-clearfix {
                div {
                  font-size: $text-default-size;
                  color: $utl-text-color-12;
                  font-weight: map-get($font-weights, "medium");
                }
              }
            }
          }
        }

        .p-autocomplete-panel::-webkit-scrollbar-track {
          -webkit-box-shadow: none;
          border-radius: 10px;
          background-color: #f0f2f4;
        }

        .p-autocomplete-panel::-webkit-scrollbar {
          width: 8px;
          background-color: #f0f2f4;
        }

        .p-autocomplete-panel::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: none;
          background-color: rgba(0, 0, 0, 0.1);
        }

        .p-autocomplete-loader {
          font-size: $heading-s-medium-font-size;
          font-weight: map-get($font-weights, "semi-bold");
          // color: $utl-theme-color;
        }
      }
    }
  }

  .cls-searchbtn {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, regular);
    text-align: center;
    color: $utl-white;
    @include border-radius;
    @include background-color($utl-theme-color);
    border-color: $utl-theme-color;
    border-width: 1px;
    @include padding(left, top, right, bottom, 16, 10, 16, 10);
    @include margin(left, top, right, bottom, 9, 0, 0, 0);
    text-transform: uppercase;
    cursor: pointer;
  }
}

// float filter in team css
.float-filter {
  height: 32px;
  @include background-color($utl-white);
  @include border-radius;
  @include border(1.5px, solid, #e5e5e5);
  display: flex;
  align-items: center;
  position: absolute;
  top: -35px;
  left: -132px;

  img {
    @include margin(left, top, right, bottom, 4, 0, 0, 0);
  }

  p-autoComplete {
    width: 100%;

    span {
      width: 100%;

      input {
        @include background-color;
        width: 100%;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border(0px, solid, transparent);

        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }
        }
      }
    }

    .p-autocomplete-panel {
      width: 285px;

      .p-autocomplete-items {
        .p-autocomplete-item {
          div {
            display: flex;
            flex-direction: column;
            @include padding(left, top, right, bottom, 7, 0, 7, 0);
          }

          &.p-highlight {
            color: #333 !important;
            @include background-color(#eaeaea !important);
          }
        }
      }
    }

    .p-autocomplete-panel::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
      border-radius: 10px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar {
      width: 8px;
      background-color: #f0f2f4;
    }

    .p-autocomplete-panel::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: none;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

// new auto complete css

.cls-autochgdg {
  height: 36px;
  @include background-color($utl-white);
  @include border-radius;
  @include border(1.5px, solid, #e5e5e5);
  display: flex;
  align-items: center;

  img {
    @include margin(left, top, right, bottom, 4, 0, 0, 0);
  }

  p-autoComplete {
    width: 100%;

    span {
      width: 100%;

      input {
        @include background-color;
        width: 100%;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border(0px, solid, transparent);

        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }
        }
      }
    }
  }
}

.tagfield p-autocomplete span.autoCompleteOne input {
  border: 0;
  padding: 0;
  text-transform: uppercase;
  color: $utl-text-color-12;
  font-size: $text-small-size;
}

.tagfield p-autocomplete {
  ::-webkit-input-placeholder {
    font-size: 10px !important;
    color: $utl-text-color-12;
  }
}

.tagfield p-autocomplete {
  .p-autocomplete-panel {
    border: 0;
    left: -9px !important;
    top: 20px !important;
    min-width: 197px !important;
  }
}

.tagfield {
  border: 1px solid $utl-text-color-12;
  @include border-radius(4px);
  @include padding(left, top, right, bottom, 3, 0, 3, 0);
  height: 18px;
  line-height: 18px;
  background-color: #fff;
  min-width: 98px;
  width: 81px;
  position: relative;

  &:focus {
    box-shadow: 0 0 7px 0 rgb(0 0 0 / 19%);
  }

  .clear {
    line-height: 11px;

    &:hover {
      color: rgb(40 44 63 / 80%);
    }
  }
}

// auto search css

.cls-autosearchbox {
  height: 36px;
  @include background-color($utl-white);
  @include border-radius(0px);
  @include border-pos(bottom, 1.5px, solid, #e5e5e5);
  display: flex;
  align-items: center;

  img {
    @include margin(left, top, right, bottom, 4, 0, 0, 0);
  }

  p-autoComplete {
    width: 100%;

    span {
      width: 100%;

      input {
        @include background-color;
        width: 100%;
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include border(0px, solid, transparent);

        &:enabled {
          &:focus {
            box-shadow: none;
            border-color: #e3e3e3;
          }
        }
      }
    }
  }
}

.autoCompleteOne.proj-det input {
  border: 0 !important;
  border-color: transparent !important;
  color: $utl-text-color-12 !important;
  font-size: $text-name !important;
  font-weight: map-get($font-weights, "medium") !important;
  padding: 3px 0px 3px 2px !important;
  border: 1px solid transparent !important;
  border-radius: 4px !important;
  // margin-left: 9px;
}

.autoCompleteOne.proj-det {
  .p-float-label p-autocomplete span.autoCompleteOne {
    &:hover {
      border: 1px solid rgba(39, 44, 62, 0.3) !important;

    }
  }

}

.p-autocomplete-multiple-container::-webkit-scrollbar {
  display: none;
}

.career-prtl {
  .p-autocomplete-panel {
    box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
    max-height: 165px !important;
    position: absolute;
    overflow-y: auto;

    .p-autocomplete-list-item {
      @include padding(left, top, right, bottom, 10, 10, 20, 5 !important);
      @include margin(left, top, right, bottom, 0, 0, 0, 0 !important);
      border-color: $utl-white !important;
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, .04);

        span {
          font-weight: map-get($font-weights, "semi-bold");
        }
      }

      span {
        font-size: 13px;
        font-weight: map-get($font-weights, "medium");
        color: $utl-text-color-12;
      }
    }
  }

  &.p-autocomplete-multiple {
    width: 317px;

    .p-autocomplete-multiple-container {
      flex-grow: 1;
      flex-wrap: nowrap;
      border-bottom: 1px solid #8d8d8d;
      white-space: nowrap;
      overflow-x: auto;
      display: inline-flex;
      width: 320px;
      clear: left;
      cursor: text;
      list-style-type: none;
      align-items: baseline;
      gap: 0;

      &:hover {
        border-color: #8d8d8d !important;
      }

      .p-autocomplete-input-token {
        input {
          width: max-content;
          font-size: $text-small-size;
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          font-size: 13px;
          font-weight: map-get($font-weights, "medium");
          color: $primary-text-color;
        }
      }

      .p-autocomplete-token {
        @include margin(left, top, right, bottom, 0, 0, 8, 0);
        position: relative;
        line-height: 33px;

        &:after {
          content: ",";
          @include abs-pos($top: 5px, $right: 0px, $bottom: initial, $left: -7px);
          color: $primary-text-color;

        }

        &:first-child {

          &:before {
            content: ",";
            @include abs-pos($top: 5px, $right: 0px, $bottom: initial, $left: -4px);
            color: $primary-text-color;
          }

        }

        &:last-child {

          &:after {
            display: none;
          }

        }

        .p-autocomplete-token-icon {
          display: none;
        }

        @include padding(left, top, right, bottom, 0, 4, 0, 0);
        align-items: flex-end;
        background: transparent;
        // overflow: hidden;
        height: auto !important;

        .p-autocomplete-token-label {
          font-family: $font-family;
          font-size: 12px;
          font-weight: map-get($font-weights, "medium");
          color: $primary-text-color;
          // max-width: 100px;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          // overflow: hidden;

        }

      }
    }
  }

}

.p-float-label .p-inputwrapper-focus~label.nofloat {
  top: 0 !important;
}

.p-float-label .p-inputwrapper-filled~label.nofloat {
  top: 5px;
  font-size: 11px;
}


.p-autocomplete-panel {
  .p-autocomplete-items {
    .p-autocomplete-item.p-highlight {
    background:#eaeaea !important;
    }
  }
}