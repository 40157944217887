
/*******************
  Table td vertical middle
  *******************/

  .vm.table td,
  .vm.table th {
      vertical-align: middle;
  }

  .no-th-brd.table th {
      border: 0px;
  }

  .table.no-border tbody td {
      border: 0px;
  }


.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}


table th {
	font-weight: 400;
}

/*******************
  Table Cell
  *******************/

  .table-box {
	display: table;
	width: 100%;
}

.table.no-border tbody td {
	border: 0px;
}

.cell {
	display: table-cell;
	vertical-align: middle;
}

.table td,
.table th {
	border-color: #f3f1f1;
	font-size: 12px;
}

.table thead th,
.table th {
	font-weight: 500;
}

.table-hover tbody tr:hover {
	background: #e9edf2;
}

.lite-padding td {
	padding: 5px;
}

.v-middle td,
.v-middle th {
	vertical-align: middle;
}


/*******************
  Table-Layout
  ******************/

.table thead th,
.table th {
	border: 0px;
}

.color-table.primary-table thead th {
	background-color: #745af2;
	color: #ffffff;
}

.table-striped tbody tr:nth-of-type(odd) {
	background: #e9edf2;
}

.color-table.success-table thead th {
	background-color: #00bfa5;
	color: #ffffff;
}

.color-table.info-table thead th {
	background-color: #398bf7;
	color: #ffffff;
}

.color-table.warning-table thead th {
	background-color: #ffb22b;
	color: #ffffff;
}

.color-table.danger-table thead th {
	background-color: #ff5252;
	color: #ffffff;
}

.color-table.inverse-table thead th {
	background-color: #2f3d4a;
	color: #ffffff;
}

.color-table.dark-table thead th {
	background-color: #263238;
	color: #ffffff;
}

.color-table.red-table thead th {
	background-color: #fb3a3a;
	color: #ffffff;
}

.color-table.purple-table thead th {
	background-color: #7460ee;
	color: #ffffff;
}

.color-table.muted-table thead th {
	background-color: #eceff1;
	color: #262e3e;
}

.color-bordered-table.primary-bordered-table {
	border: 2px solid #745af2;
}

.color-bordered-table.primary-bordered-table thead th {
	background-color: #745af2;
	color: #ffffff;
}

.color-bordered-table.success-bordered-table {
	border: 2px solid #00bfa5;
}

.color-bordered-table.success-bordered-table thead th {
	background-color: #00bfa5;
	color: #ffffff;
}

.color-bordered-table.info-bordered-table {
	border: 2px solid #398bf7;
}

.color-bordered-table.info-bordered-table thead th {
	background-color: #398bf7;
	color: #ffffff;
}

.color-bordered-table.warning-bordered-table {
	border: 2px solid #ffb22b;
}

.color-bordered-table.warning-bordered-table thead th {
	background-color: #ffb22b;
	color: #ffffff;
}

.color-bordered-table.danger-bordered-table {
	border: 2px solid #ff5252;
}

.color-bordered-table.danger-bordered-table thead th {
	background-color: #ff5252;
	color: #ffffff;
}

.color-bordered-table.inverse-bordered-table {
	border: 2px solid #2f3d4a;
}

.color-bordered-table.inverse-bordered-table thead th {
	background-color: #2f3d4a;
	color: #ffffff;
}

.color-bordered-table.dark-bordered-table {
	border: 2px solid #263238;
}

.color-bordered-table.dark-bordered-table thead th {
	background-color: #263238;
	color: #ffffff;
}

.color-bordered-table.red-bordered-table {
	border: 2px solid #fb3a3a;
}

.color-bordered-table.red-bordered-table thead th {
	background-color: #fb3a3a;
	color: #ffffff;
}

.color-bordered-table.purple-bordered-table {
	border: 2px solid #7460ee;
}

.color-bordered-table.purple-bordered-table thead th {
	background-color: #7460ee;
	color: #ffffff;
}

.color-bordered-table.muted-bordered-table {
	border: 2px solid #99abb4;
}

.color-bordered-table.muted-bordered-table thead th {
	background-color: #99abb4;
	color: #ffffff;
}

.full-color-table.full-primary-table {
	background-color: #f1effd;
}

.full-color-table.full-primary-table thead th {
	background-color: #745af2;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-primary-table tbody td {
	border: 0;
}

.full-color-table.full-primary-table tr:hover {
	background-color: #745af2;
	color: #ffffff;
}

.full-color-table.full-success-table {
	background-color: #e8fdeb;
}

.full-color-table.full-success-table thead th {
	background-color: #00bfa5;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-success-table tbody td {
	border: 0;
}

.full-color-table.full-success-table tr:hover {
	background-color: #00bfa5;
	color: #ffffff;
}

.full-color-table.full-info-table {
	background-color: #cfecfe;
}

.full-color-table.full-info-table thead th {
	background-color: #398bf7;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-info-table tbody td {
	border: 0;
}

.full-color-table.full-info-table tr:hover {
	background-color: #398bf7;
	color: #ffffff;
}

.full-color-table.full-warning-table {
	background-color: #fff8ec;
}

.full-color-table.full-warning-table thead th {
	background-color: #ffb22b;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-warning-table tbody td {
	border: 0;
}

.full-color-table.full-warning-table tr:hover {
	background-color: #ffb22b;
	color: #ffffff;
}

.full-color-table.full-danger-table {
	background-color: #f9e7eb;
}

.full-color-table.full-danger-table thead th {
	background-color: #ef5350;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-danger-table tbody td {
	border: 0;
}

.full-color-table.full-danger-table tr:hover {
	background-color: #ff5252;
	color: #ffffff;
}

.full-color-table.full-inverse-table {
	background-color: #f6f6f6;
}

.full-color-table.full-inverse-table thead th {
	background-color: #2f3d4a;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-inverse-table tbody td {
	border: 0;
}

.full-color-table.full-inverse-table tr:hover {
	background-color: #2f3d4a;
	color: #ffffff;
}

.full-color-table.full-dark-table {
	background-color: rgba(43, 43, 43, 0.8);
}

.full-color-table.full-dark-table thead th {
	background-color: #263238;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-dark-table tbody td {
	border: 0;
	color: #ffffff;
}

.full-color-table.full-dark-table tr:hover {
	background-color: #263238;
	color: #ffffff;
}

.full-color-table.full-red-table {
	background-color: #f9e7eb;
}

.full-color-table.full-red-table thead th {
	background-color: #fb3a3a;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-red-table tbody td {
	border: 0;
}

.full-color-table.full-red-table tr:hover {
	background-color: #fb3a3a;
	color: #ffffff;
}

.full-color-table.full-purple-table {
	background-color: #f1effd;
}

.full-color-table.full-purple-table thead th {
	background-color: #7460ee;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-purple-table tbody td {
	border: 0;
}

.full-color-table.full-purple-table tr:hover {
	background-color: #7460ee;
	color: #ffffff;
}

.full-color-table.full-muted-table {
	background-color: rgba(152, 166, 173, 0.2);
}

.full-color-table.full-muted-table thead th {
	background-color: #99abb4;
	border: 0;
	color: #ffffff;
}

.full-color-table.full-muted-table tbody td {
	border: 0;
}

.full-color-table.full-muted-table tr:hover {
	background-color: #99abb4;
	color: #ffffff;
}


/*******************
  Table-Data Table
  ******************/

.dataTables_wrapper {
	padding-top: 10px;
}

.dt-buttons {
	display: inline-block;
	padding-top: 5px;
	margin-bottom: 15px;
}

.dt-buttons .dt-button {
	padding: 5px 15px;
	border-radius: 4px;
	background: #398bf7;
	color: #ffffff;
	margin-right: 3px;
}

.dt-buttons .dt-button:hover {
	background: #2f3d4a;
}

.dataTables_info,
.dataTables_length {
	display: inline-block;
}

.dataTables_length {
	margin-top: 10px;
}

.dataTables_length select {
	border: 0;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
	background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
	background-size: 0 2px, 100% 1px;
	background-repeat: no-repeat;
	background-position: center bottom, center calc(100% - 1px);
	background-color: transparent;
	-webkit-transition: background 0s ease-out;
	-o-transition: background 0s ease-out;
	transition: background 0s ease-out;
	padding-bottom: 5px;
}

.dataTables_length select:focus {
	outline: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
	background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
	background-size: 100% 2px, 100% 1px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

.dataTables_filter {
	float: right;
	margin-top: 10px;
}

.dataTables_filter input {
	border: 0;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
	background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
	background-size: 0 2px, 100% 1px;
	background-repeat: no-repeat;
	background-position: center bottom, center calc(100% - 1px);
	background-color: transparent;
	-webkit-transition: background 0s ease-out;
	-o-transition: background 0s ease-out;
	transition: background 0s ease-out;
	float: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	border-radius: 0;
	margin-left: 10px;
}

.dataTables_filter input:focus {
	outline: none;
	background-image: -webkit-gradient(linear, left top, left bottom, from(#398bf7), to(#398bf7)), -webkit-gradient(linear, left top, left bottom, from(#b1b8bb), to(#b1b8bb));
	background-image: -webkit-linear-gradient(#398bf7, #398bf7), -webkit-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: -o-linear-gradient(#398bf7, #398bf7), -o-linear-gradient(#b1b8bb, #b1b8bb);
	background-image: linear-gradient(#398bf7, #398bf7), linear-gradient(#b1b8bb, #b1b8bb);
	background-size: 100% 2px, 100% 1px;
	-webkit-box-shadow: none;
	box-shadow: none;
	-webkit-transition-duration: 0.3s;
	-o-transition-duration: 0.3s;
	transition-duration: 0.3s;
}

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled,
.bg-transparent {
	background: transparent;
}

table.dataTable thead .sorting_asc:after {
	content: "\f0de";
	margin-left: 10px;
	font-family: fontawesome;
	cursor: pointer;
}

table.dataTable thead .sorting_desc:after {
	content: "\f0dd";
	margin-left: 10px;
	font-family: fontawesome;
	cursor: pointer;
}

table.dataTable thead .sorting:after {
	content: "\f0dc";
	margin-left: 10px;
	font-family: fontawesome !important;
	cursor: pointer;
	color: rgba(50, 50, 50, 0.5);
}

.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 0.5em 1em;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	*cursor: hand;
	color: #67757c;
	border: 1px solid #ddd;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #ffffff !important;
	border: 1px solid #398bf7;
	background-color: #398bf7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #67757c;
	border: 1px solid #ddd;
	background: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: white;
	border: 1px solid #398bf7;
	background-color: #398bf7;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #67757c;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}


/*******************
  Table- responsive
  ******************/

.tablesaw-bar .btn-group label {
	color: #67757c !important;
}


/*******************
  Table- editable table
  ******************/

.dt-bootstrap {
	display: block;
}

.paging_simple_numbers .pagination .paginate_button {
	padding: 0px;
	background: #ffffff;
}

.paging_simple_numbers .pagination .paginate_button:hover {
	background: #ffffff;
}

.paging_simple_numbers .pagination .paginate_button a {
	padding: 3px 10px;
	border: 0px;
}

.paging_simple_numbers .pagination .paginate_button.active a,
.paging_simple_numbers .pagination .paginate_button:hover a {
	background: #398bf7;
	color: #ffffff;
}

.stylish-table tbody tr {
	border-left: 3px solid transparent;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
	border-color: #398bf7;
}


/*******************
  Table-Jsgrid table
  ******************/

.jsgrid-pager-page a,
.jsgrid-pager-current-page,
.jsgrid-pager-nav-button a {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 0.5em 1em;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	color: #67757c;
	border: 1px solid #ddd;
}

.jsgrid-pager-page a:hover,
.jsgrid-pager-nav-button a:hover {
	background-color: #398bf7;
	color: #ffffff;
}

.jsgrid-pager-current-page {
	background-color: #398bf7;
	color: #ffffff;
}

.jsgrid-pager-page,
.jsgrid-pager-nav-button {
	padding: 0;
}

.jsgrid-pager-page.jsgrid-pager-current-page {
	padding: 0.5em 1em !important;
}



/*********** Table css ***************/
.table td,
.table th {
  padding: 0.5rem !important;
  vertical-align: inherit !important;
}


/* Table Css */

/* table {
	line-height: 1.5em;
	border-collapse: collapse;
	border-spacing: 0;
	display: table;
	width: 100%;
	max-width: 100%;
	overflow: auto;
	word-break: normal;
	word-break: keep-all;
	background: #fff;
}

table tr th {
     margin: 0;
     padding: .5em 1em;
     background-color: #eceff1;
     font-size: 12px;
     font-weight: 500!important;
     text-align: left;
     color: #262e3e;
}

table tr td {
	margin: 0;
	padding: .5em 1em;
	font-size: 12px!important;
	font-weight: 400;
	text-align: left;
	color: #262e3e;
	border-bottom: 1px solid #eceff1;
}

table tr th a {
	color: #262e3e!important;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
} */

.ng2-smart-action {
	color: #00bfa5!important;
	font-size: 12px;
	font-weight: 500;
	text-align: left;
}

table tr th a.sort.asc,
table tr th a.sort.desc {
	font-weight: 600!important;
}

.ng2-smart-pagination .page-link-next,
.ng2-smart-pagination .page-link-prev {
	font-size: inherit!important;
}

.page-item.active .page-link {
	z-index: 2;
	color: #fff;
	background-color: #00bfa5;
	border-color: #00bfa5;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
	background-color: #00bfa5;
	border-color: #00bfa5;
}

.pagination {
	display: -ms-flexbox;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
	margin-top: 10px;
	float: right;
}

ng2-smart-table-pager {
	display: block!important;
}





/*---------------------- table loading-------------------------------  */

@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0
	}
	100% {
		background-position: 468px 0
	}
}

.animated-background-table-header {
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #cdd1d3 8%, #e6e6e6 18%, #cdd1d3 33%);
	background-size: 800px 104px;
	height: 15px;
	position: relative;
}

.animated-background-table-body {
	animation-duration: 0.8s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: #f6f7f8;
	background: linear-gradient(to right, #eceff1 8%, #e0e0e0 18%, #eceff1 33%);
	background-size: 800px 100%;
	height: 15px;
	position: relative;
}

.loader-table th {
	padding: 14px 25px 11px;
}

.loader-table td {
	padding: 15px 25px;
}
/* -------------------------------------css for table loader--------------------------- */

.l-w-16 {
	width: 16px;
}

.l-w-30 {
	width: 27px;
}

.l-w-50 {
	width: 50px;
}

.l-w-80 {
	width: 80px;
}

.l-w-100 {
	width: 100px;
}

.l-w-150 {
	width: 150px;
}

.l-w-200 {
	width: 200px;
}
/* -------------------------END animated data loader css---------------------------------- */


.customDataTable .ui-paginator-bottom,
.customDataTable_document .ui-paginator-bottom  {
	text-align: right;
	background: #fff;
}


.customDataTable .ui-chkbox .ui-chkbox-icon {
	display: inline !important;
}

/* .customDataTable .ui-paginator-prev, */


/* .customDataTable .ui-paginator-next, */
.customDataTable .ui-paginator-first,
.customDataTable_document .ui-paginator-first,

.customDataTable_document .ui-paginator-last,
.customDataTable .ui-paginator-last {
	display: none;
}

.customDataTable .ui-paginator-pages,
.customDataTable_document .ui-paginator-pages {
	display: inline-block;
	margin-top: 10px;
	padding: 0;
}

.customDataTable .ui-paginator-page,
.customDataTable_document .ui-paginator-page {
	margin: 0;
	padding: 6px 14px;
	border-radius: 0;
}

.customDataTable .ui-paginator .ui-paginator-page.ui-state-active,
.customDataTable_document .ui-paginator .ui-paginator-page.ui-state-active {
	background: #00c8b4;
	color: #ffffff;
	border-color: #00c8b4;
}

.customDataTable .ui-widget-header,
.customDataTable_document .ui-widget-header {
	border: none;
}

.customDataTable .ui-paginator-next,
.customDataTable_document .ui-paginator-next {
	display: inline-block;
	margin-top: 10px;
	padding: 3px 7px 4px 7px;
	border-radius: 0;
	margin-left: -5px;
}

.customDataTable .ui-paginator-prev,
.customDataTable_document .ui-paginator-prev {
	display: inline-block;
	margin-top: 10px;
	padding: 3px 7px 4px 7px;
	border-radius: 0;
	margin-right: -5px;
}

.customDataTable .ui-paginator-next .fa-forward:before,
.customDataTable .ui-paginator-prev .fa-backward:before,
.customDataTable_document .ui-paginator-next .fa-forward:before,
.customDataTable_document .ui-paginator-prev .fa-backward:before {
	content: none;
}

.customDataTable .ui-paginator-next .fa-forward,
.customDataTable_document .ui-paginator-next .fa-forward {
	background: url(../images/next-arrow-icon.png) no-repeat;
	width: 20px;
	height: 20px;
}

.customDataTable .ui-paginator-prev .fa-backward,
.customDataTable_document .ui-paginator-prev .fa-backward {
	background: url(../images/prev-arrow-icon.png) no-repeat;
	width: 20px;
	height: 20px;
}

.customDataTable .ui-state-default,
.customDataTable_document .ui-state-default  {
	border: 1px solid #d6d6d6;
}

/* .customDataTable tr td:nth-child(2),
.customDataTable tr th:nth-child(2) { width: 250px;}
.customDataTable tr td:nth-child(4),
.customDataTable tr th:nth-child(4) { width: 200px;}
.customDataTable tr td:nth-child(5),
.customDataTable tr th:nth-child(5) { width: 170px;} */

.customDataTable td, .customDataTable_document td { border: none; border-width: 0 !important;     word-break: break-all; }
/* .ui-widget-content {border-left: none !important; border-right: none !important; } */
.customDataTable .ui-state-default,
.customDataTable_document .ui-state-default {
    border: none 0 !important;
    border-width: 0 !important;
}
.customDataTable  tr, .customDataTable_document  tr { border-bottom:1px solid #eceff1; }
.ui-datatable tbody > tr.ui-widget-content {
    border-color: #eceff1 !important;
}

.customDataTable .ui-widget-header, .customDataTable_document .ui-widget-header {background: #fff !important;}
.customDataTable .ui-datatable th.ui-state-default,
.customDataTable_document .ui-datatable th.ui-state-default {
    background: #eceff1 !important;
    border-color: #eceff1 !important;
}
.customDataTable tr th {
    padding: 13px 10px 12px 10px !important;
    text-align: left;
 }

 /* .customDataTable tr th:nth-child(1) { text-align: center !important;} */

 .customDataTable  .dropdown-toggle::after,
 .customDataTable_document  .dropdown-toggle::after { display: none !important;}
 .customDataTable_document .ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd,
 .customDataTable .ui-datatable tbody > tr.ui-widget-content.ui-datatable-odd{
    background-color: transparent !important;
 }
 .customDataTable .ui-chkbox .ui-state-default {
    border: 1px solid #d6d6d6 !important;
}
 .customDataTable tbody > tr {
    background-color: transparent !important;
}

.customDataTable_document td {
    padding: 10px !important;
}
.customDataTable_document th {
	padding:12px 10px !important;
}
.customDataTable_document .ui-datatable-odd{
background: transparent !important;
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.customDataTable .mat-checkbox-layout {
    display: inline-block !important;
}
