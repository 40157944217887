// Bars
$orange-color: #EDB867;
$green-color: #50E3C2;
$blue-color: #4278F5;

// Stars
$star-default: #d2d2d2;
$star-active: #EDB867;
$star-selected: #EDB867;

$black: #252627;
$gray: #757575;
