@import "../../theme/main.scss";

p-carousel {
  .p-carousel-indicators {
    display: none;
  }

  .p-carousel-content {
    overflow: initial;

    .p-carousel-item {
      @include padding(left, top, right, bottom, 8, 0, 8, 8);
    }

    .p-carousel-container {
      position: relative;

      .p-carousel-items-content {
        margin: 0 -8px;
        width : auto;
      }

      .p-link {
        @include background-color(#000);
        position           : absolute;
        top                : 50%;
        margin-top         : -1rem;
        z-index            : 99;
        background-size    : 14px;
        background-repeat  : no-repeat;
        background-position: center;

        span {
          display: none;
        }

        &:hover {
          @include background-color(#000 !important);
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      .p-carousel-prev {
        left            : -22px;
        background-image: url(#{$assetPath}/icon/right-arrow-forward.svg);
        transform       : rotate(180deg);
      }

      .p-carousel-next {
        right           : -22px;
        background-image: url(#{$assetPath}/icon/right-arrow-forward.svg);
      }

      .p-disabled {
        display: none;
      }
    }
  }
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  background: url(#{$assetPath}/icon/right-arrow-forward.svg),
  #000;
  background-size    : 14px;
  background-repeat  : no-repeat;
  background-position: center;
}
// new social wall birthday carousel
.birthday-carousel
{
  .p-carousel  {
    .p-carousel-content {
      // max-height: 112px;
     
      width: 100%;
      // background-color: #7CD9CC;
      // background-image: url(#{$assetPath}/socialwall/birthday-bg.svg);
      @include border-radius(8px);
      .p-carousel-item {
        @include padding(left, top, right, bottom, 0, 0, 0, 16);
      }
    }
    .p-carousel-container {
      position: relative;
      .p-carousel-prev
      {
        left: -8px;
        width: 28px;
        height: 28px;
      }
      .p-carousel-next
      {
        top : 39%;
        width: 28px;
        height: 28px;
        margin-top: 0;
        right: -8px;
      }
      .p-carousel-items-content {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        width: 100%;
      }
      
 

    }
  }
}
// Insight Carousel
.component-slider-wrapper {
  .component-slider-wrapper-inner {
    p-carousel {
      .p-carousel-content {
        width: 100%;

        .p-carousel-items-content {
          @include padding(left, top, right, bottom, 0, 8, 0, 0);
          width : -webkit-fill-available;
          margin: 0;
          width : 100%;

          .p-carousel-item {
            @include padding(left, top, right, bottom, 4, 0, 4, 8);
            @include margin(left, top, right, bottom, 0, 0, 0, 0);
            flex       : 0 0 33.333333333333336% !important;



            .component-content {
              // box-shadow: inset 0 0 3px #01010138;
              @include padding(left, top, right, bottom, 8, 10, 8, 10);
              min-height     : 130px;
              font-size      : 14px;
              display        : flex;
              flex-direction : column;
              align-items    : flex-start;
              justify-content: space-between;
              cursor         : pointer;
            }
          }
        }
      }
    }
  }
}

#Report-carousel {
  .p-carousel {
    .p-carousel-indicators {
      display: flex;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      .p-carousel-indicator {
        button {
          width        : 0.5rem;
          height       : 0.5rem;
          transition   : background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius: 50%;
        }
      }
    }
  }
}

#hardware-search {
  .p-carousel {
    .p-carousel-indicators {
      display: flex;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      position: absolute;
      bottom  : 13px;
      margin  : auto;
      left    : 0;
      right   : 0;
      z-index : 9;

      .p-carousel-indicator {

        button {
          // @include background-color(transparent);
          background-color: #d8d8d8;
          width           : 10px;
          height          : 10px;
          transition      : background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius   : 50%;
          outline         : none;

          // @include border(1px, solid, $utl-theme-color);
          &:focus {
            outline   : none;
            box-shadow: none;
          }
        }

        &.p-highlight {
          button {
            @include background-color($utl-theme-color);
            box-shadow: none;
          }
        }
      }
    }
  }
}


#hardware-inv {
  .p-carousel {
    .p-carousel-indicators {
      display: flex;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      position: absolute;
      bottom  : 25px;
      margin  : auto;
      left    : 0;
      right   : 0;
      z-index : 9;

      .p-carousel-indicator {

        button {
          // @include background-color(transparent);
          background-color: #d8d8d8;
          width           : 10px;
          height          : 10px;
          transition      : background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius   : 50%;
          outline         : none;

          // @include border(1px, solid, $utl-theme-color);
          &:focus {
            outline   : none;
            box-shadow: none;
          }
        }

        &.p-highlight {
          button {
            // @include background-color($utl-theme-color);
            box-shadow: none;
          }
        }
      }
    }

    .p-carousel-content {
      overflow: initial;

      .p-carousel-item {
        @include padding(left, top, right, bottom, 0, 0, 0, 8);
      }

      .p-carousel-container {
        position: relative;

        .p-carousel-items-content {
          margin: 0;
          width : 100%;
        }

        .p-link {
          @include background-color(#000);
          position           : absolute;
          top                : 50%;
          margin-top         : -1rem;
          z-index            : 99;
          background-size    : 14px;
          background-repeat  : no-repeat;
          background-position: center;

          span {
            display: none;
          }

          &:hover {
            @include background-color(#000 !important);
          }

          &:focus {
            box-shadow: none !important;
          }
        }


      }
    }
  }
}


.car-inv {
  .p-carousel {
    .p-carousel-indicators {
      display: flex;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      position: absolute;
      bottom  : 6px;
      margin  : auto;
      left    : 0;
      right   : 50px;
      z-index : 9;

      .p-carousel-indicator {

        button {
          // @include background-color(transparent);
          background-color: #d8d8d8;
          width           : 10px;
          height          : 10px;
          transition      : background-color 0.2s, color 0.2s, box-shadow 0.2s;
          border-radius   : 50%;
          outline         : none;

          // @include border(1px, solid, $utl-theme-color);
          &:focus {
            outline   : none;
            box-shadow: none;
          }
        }

        &.p-highlight {
          button {
            @include background-color($utl-theme-color);
            box-shadow: none;
          }
        }
      }
    }

    .p-carousel-content {
      overflow: initial;

      .p-carousel-item {
        @include padding(left, top, right, bottom, 0, 0, 0, 8);
      }

      .p-carousel-container {
        position: relative;

        .p-carousel-items-content {
          margin: 0;
          width : 100%;
        }

        .p-link {
          @include background-color(#000);
          position           : absolute;
          top                : 50%;
          margin-top         : -1rem;
          z-index            : 99;
          background-size    : 14px;
          background-repeat  : no-repeat;
          background-position: center;
          display: none;
          span {
            display: none;
          }

          &:hover {
            @include background-color(#000 !important);
          }

          &:focus {
            box-shadow: none !important;
          }
        }


      }
    }
  }
}

// prediction

#prediction {
  .p-carousel {
    .p-carousel-content {
      .p-carousel-items-content {
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        width: 100%;
        .p-carousel-items-container {
          .p-carousel-item {
            @include padding(left, top, right, bottom, 0, 0, 0, 0);
            min-width: 100%;
            max-width: 100%;
            width    : 100%;

            .border-box {
              @include border-radius(2px);
              @include border(1px, solid, #d7d7d7);
              @include background-color;
              min-height: 150px;
              @include padding(left, top, right, bottom, 24, 15, 24, 34);

              .cls-lbl {
                font-family: $font-family;
                font-size  : $heading-extra-small-font-size;
                color      : $utl-text-color-12;
                line-height: 1.5;
                font-weight: map-get($font-weights, semi-bold);
                @include margin(left, top, right, bottom, 0, 0, 0, 0);
              }

              .cls-vlv {
                @extend .cls-lbl;
                font-weight  : map-get($font-weights, regular);
                white-space  : nowrap;
                text-overflow: ellipsis;
                overflow     : hidden;
              }
            }
          }

        }
      }

      .p-link {
        margin-top: -2.5rem;
      }
    }
  }
}