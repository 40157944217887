@import "../../theme/main.scss";

.post-update {
  .hrpost {
    p-fileupload {
      .chat-file-attach {
        .p-fileupload-buttonbar {
          @include margin(left, top, right, bottom, 0, 0, 0, 16);
        }

        .p-fileupload-content {
          @include margin(left, top, right, bottom, 0, 10, 0, 16);
          border: 0;
          background: transparent;

          .p-button-icon-only {
            @include abs-pos(6px, -4px, initial, initial);

          }
        }
      }
    }
  }

  p-fileupload {
    .chat-file-attach {
      .p-fileupload-buttonbar {
        height: 96px;

        span {
          &.p-button {
            @include border(0px, solid, transparent);

            &:focus {
              box-shadow: none;
            }
          }

          .p-button-label {
            display: none;
          }
        }

        p-button {
          display: none;
        }
      }

      .p-fileupload-content {
        @include padding(left, top, right, bottom, 3, 14, 3, 0);
        @include border(2px, dashed, #cfcfcf);
        @include border-radius(5px);
        @include background-color($utl-grey-color-11);

        .p-fileupload-files {
          .p-fileupload-row {
            display: inline-flex;
            @include background-color(transparent);
            position: relative;
            overflow: initial;
            padding: 0;
            @include border(1.5px, solid, $utl-blue-15);
            @include border-radius(2px);
            @include margin(left, top, right, bottom, 0, 0, 12.6, 16);
            max-width: 16%;
            flex: 0 0 16%;
            box-sizing: content-box;

            div {
              img {
                object-position: center;
                object-fit: contain;
              }

              &:first-child {
                width: 100%;
                height: 63px;
                margin-right: 0;
              }

              &:nth-child(2) {
                display: none;
              }

              &:nth-child(3) {
                display: none;
              }

              &:last-child {
                @include abs-pos(-17px, -4px, initial, initial);

                button {
                  background-image: url("/assets/images/close-dark.png") !important;
                  @include background-color($utl-white);
                  background-size: cover;
                  @include border-radius(50px);
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }
        }

        .p-fileupload-files>div {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          @include margin(left, top, right, bottom, -16, 0, -10, 0);
          @include padding(left, top, right, bottom, 16, 0, 0, 0);
        }
      }

      .p-progressbar {
        display: none;
      }
    }
  }

  .dropfile-msg {
    height: calc(96px - 4px);
    @include padding(left, top, right, bottom, 24, 12, 24, 12);

    p {
      font-size: $text-default-size;
      line-height: 1.6;

      b {
        color: $utl-blue-15;
        font-weight: map-get($font-weights, "medium");
        font-size: $text-default-size;
      }
    }
  }

  .has-no-file {
    .p-fileupload-content {
      display: none;
    }
  }

  .preview-box {
    @include border(1px, solid, #e5e5e5);
    @include background-color(#f5f5f5);
    width: 140px;
    height: 100px;
    box-sizing: content-box;
    position: relative;

    .delete-box {
      border: none;
      box-shadow: none;
      width: 17px;
      height: 17px;
      background: transparent;
      padding: 0;
      @include abs-pos($top: -8px, $right: -8px, $bottom: initial, $left: initial);
    }

    figure {
      width: 100%;
      height: 77px;
      @include background-color($utl-white);

      img {
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    .file-name {
      padding: 3px 30px 3px 3px;
      position: relative;
      display: flex;
      align-items: center;

      small {
        @include background-color($utl-theme-color);
        color: $utl-white;
        font-size: $text-midum-small-size;
        margin-right: 4px;
        display: flex;
        align-items: center;
        padding: 3px 3px;
        border-radius: 2px;
        font-weight: 600;
      }

      span {
        @include abs-pos($top: initial, $right: -1px, $bottom: -1px, $left: initial);
        width: 35px;
        display: block;

        img {
          width: 100%;
        }
      }

      b {
        @include text-truncate();
        color: $utl-text-color-4;
        font-weight: map-get($font-weights, "semi-bold");
        font-size: $text-medium-size;
        width: 100%;
        display: block;
      }
    }
  }
}

.individual-info {
  .post-update {
    p-fileupload {
      .chat-file-attach {
        .p-fileupload-content {
          padding: 0;
          @include border(0px, solid, transparent);
          background-color: $utl-white;

          .p-fileupload-files {
            .p-fileupload-row {
              width: 110px;
              max-width: auto;
              border-radius: 0;
              border: solid 1px #ddd;
              height: 105px;

              div {
                &:first-child {
                  height: 80px;

                  img {
                    object-fit: cover !important;
                  }
                }

                &:nth-child(2) {
                  display: block;
                  background: #f5f5f5;
                  position: absolute;
                  bottom: 0;
                  height: 25px;
                  width: 100%;
                  left: 0;
                  font-size: $text-medium-size;
                  font-weight: map-get($font-weights, "semi-bold");
                  color: $utl-text-color-4;
                  padding: 2px 4px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                }

                &:last-child {
                  button {
                    background-image: url("/assets/images/cross-red.png") !important;

                    &:hover {
                      background-size: cover !important;
                    }
                  }
                }
              }
            }
          }

          .p-progressbar {
            display: none;
          }
        }
      }
    }
  }
}

// profile upload

// p-upload css

.document-uploader {
  .p-fileupload {
    @include border(0px, dashed, transparent);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    @include border-radius(0);
    @include background-color(transparent);
    font-family: $font-family;
    font-size: inherit;

    .p-fileupload-buttonbar {
      @include border(0px, dashed, transparent);
      @include margin(left, top, right, bottom, 0, 0, 0, 0);
      @include border-radius(5px);
      @include background-color($utl-grey-color-11);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      height: 0;

      .p-fileupload-choose {
        width: 100%;
        height: 100%;
        @include background-color(transparent);
        position: relative;
        @include border(0px, dashed, transparent);
        z-index: 99;
        min-height: 96px;

        &:focus {
          box-shadow: none;
        }

        .p-button-icon-left {
          display: none;
        }

        .p-button-label {
          cursor: pointer;
          text-decoration: underline;
          font-family: $font-family;
          font-size: $text-medium-size;
          // color: $utl-theme-color;
          padding-top: 4%;
          // display: none;
        }
      }

      p-button {
        display: none;
      }
    }

    .p-fileupload-content {
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border(0px, dashed, #cfcfcf);
      @include border-radius(0px);
      @include background-color(transparent);

      p-progressbar {
        display: none;
      }

      .p-fileupload-files {
        display: none;
      }

      .dropfile-msg {
        position: absolute;
        top: -90px;
        left: 0;
        @include background-color(#f8f8f9);
        width: calc(100% - 4px);
        height: calc(90px - 4px);
        text-align: center;
        @include padding(left, top, right, bottom, 6, 6, 6, 6);
        z-index: 9;
        right: 0;
        margin: auto;

        p {
          font-size: $text-default-size;
          line-height: 1.6;

          b {
            color: $utl-theme-color;
            font-weight: map-get($font-weights, "medium");
            font-size: $text-default-size;
          }
        }
      }

      .uploadDiv {
        /* margin-bottom: 15px; */
        @include background-color($utl-grey-color-9);
        @include border(1px, solid, $utl-grey-color-14);
        @include padding(left, top, right, bottom, 0, 25, 0, 0);

        .file-text {
          font-family: $font-family;
          font-size: 24px;
          font-weight: map-get($font-weights, "bold");
          color: rgba(40, 49, 75, 0.16);
        }
      }
    }
  }
}

// REIMBURSEMENT REQUEST file upload
#Reimfileupload {
  .p-fileupload.p-fileupload-basic {
    .p-button.p-fileupload-choose {
      // color: $utl-theme-color;
      @include background-color(transparent);
      @include border(0px, solid, transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      font-size: $text-default-size;
      transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
      @include border-radius(0px);
      outline: 0 none;

      &:focus {
        box-shadow: none;
      }

      // &:hover {
      //   color: #73ded0;
      // }

      .p-button-icon-left {
        display: none;
      }
    }
  }
}

.loan-upload {
  @extend #Reimfileupload;

  .p-fileupload.p-fileupload-basic {
    .p-button.p-fileupload-choose {
      font-weight: map-get($font-weights, "semi-bold");
      font-size: $text-default-size;
    }
  }
}

// common upload css file

.cus-attacher {
  .p-fileupload {
    position: relative;

    .p-fileupload-buttonbar {
      @include background-color(transparent);
      @include border(0px, solid, transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      span.p-button {
        position: absolute;
        bottom: 12px;
        width: 100%;
        z-index: 9;
        @include background-color(transparent);
        color: $utl-blue-15;
        font-size: $text-default-size;
        font-weight: map-get($font-weights, "semi-bold");
        @include border(0px, solid, transparent);

        &:focus {
          box-shadow: none;
        }

        .p-button-icon {
          display: none;
        }
      }

      // span {
      //   width: 100%;
      //   height: 96px;
      //   @include background-color(transparent);

      //   &.p-button {
      //     @include border(0px, solid, transparent);

      //     &:focus {
      //       box-shadow: none;
      //     }

      //     .p-button-icon {
      //       display: none;
      //     }
      //   }

      // }
    }

    .p-fileupload-content {
      height: 96px;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      @include border-radius(5px);
      @include background-color($utl-grey-color-11);

      // z-index: 99;
      .p-fileupload-files {
        display: none;
      }

      p-progressbar {
        display: none;
      }
    }
  }

  .file-msg {
    height: calc(96px - 6px);
    @include padding(left, top, right, bottom, 24, 12, 24, 12);

    p {
      font-size: $text-default-size;
      line-height: 1.6;
      color: initial;

      b {
        color: $utl-blue-15;
        font-weight: map-get($font-weights, "medium");
        font-size: $text-default-size;
      }
    }
  }
}

// career portal upload design
/******************** fileshare ****************/
.previous-bg-img {
  .fileshare-container .fileshare-container {
    width: 66px;
    height: 66px;

    .upload-cont {
      height: 62px;
      @include border-radius;
    }
  }

  .chat-file-attach {
    .p-fileupload-buttonbar {
      height: 62px;
      @include border(0px, solid, transparent);

      span {
        &.p-button {
          @include border(0px, solid, transparent);

          &:focus {
            box-shadow: none;
          }
        }

        .p-button-label {
          display: none;
        }
      }

      p-button {
        display: none;
      }
    }

    .p-fileupload-content {
      padding: 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
      width: 100%;
      @include border(0px, solid, transparent);

      .p-fileupload-files {
        .p-fileupload-row {
          display: inline-flex;
          @include background-color(transparent);
          position: relative;
          overflow: initial;
          padding: 0;
          // @include border(2px, solid, $utl-theme-color);
          @include border-radius;
          @include margin(left, top, right, bottom, 0, 0, 0, 0);
          max-width: 100%;
          width: 100%;
          box-sizing: content-box;
          height: 62px;

          div {
            img {
              object-position: center;
              object-fit: contain;
            }

            &:first-child {
              width: 100%;
              height: 62px;
              margin-right: 0;
              @include padding(left, top, right, bottom, 0, 0, 0, 0);
            }

            &:nth-child(2) {
              display: none;
            }

            &:nth-child(3) {
              display: none;
            }

            &:last-child {
              @include abs-pos(-8px, -8px, initial, initial);
              @include padding(left, top, right, bottom, 0, 0, 0, 0);

              button {
                background-image: url("/assets/images/close-dark.png") !important;
                @include background-color($utl-white);
                background-size: cover;
                @include border-radius(50px);
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }

      // .p-fileupload-files>div {
      //   display: flex;
      //   justify-content: flex-start;
      //   flex-wrap: wrap;
      //   @include margin(left, top, right, bottom, 0, 0, 0, 0);
      //   @include padding(left, top, right, bottom, 0, 0, 0, 0);
      // }
    }

    .p-progressbar {
      display: none;
    }
  }

  .dropfile-msg {
    height: calc(96px - 4px);
    @include padding(left, top, right, bottom, 24, 12, 24, 12);

    p {
      font-size: $text-default-size;
      line-height: 1.6;

      b {
        color: $utl-blue-15;
        font-weight: map-get($font-weights, "medium");
        font-size: $text-default-size;
      }
    }
  }

  .has-no-file {
    .p-fileupload-content {
      display: none;
    }
  }

  .p-fileupload-content {
    .p-fileupload-files {
      width: 100%;
      display: table;

      .p-fileupload-row {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        height: 66px;
        @include border(2px, solid, $utl-theme-color);
        @include border-radius;

        div {
          font-size: 0;
          width: 100%;
          height: 100%;

          &:first-child {
            img {
              width: 100%;
              height: 100%;
              -o-object-fit: contain;
              object-fit: contain;
              -o-object-position: center;
              object-position: center;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
}

.file-share {
  label {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.43;
    color: $utl-grey-color-6;
    @include margin(left, top, right, bottom, 0, 0, 0, 8);
  }

  .colorrchange {
    @extend label;
    // border-width: 1.5px solid $utl-theme-color !important;
    font-weight: map-get($font-weights, "normal");
    color: #70798a !important;
    max-width: 5em;
  }

  .chat-file-attach .ui-fileupload-row>div {
    font-size: 0;
    width: 100%;
    height: 100%;

    button {
      position: absolute;
      right: -8px;
      top: -8px;
      background-image: url("/assets/images/icon/cross.png");
      @include background-color($primary-text-color);
      @include border-radius(50px);
      background-size: 8px !important;
      background-position: center !important;
      width: 18px;
      height: 18px;

      &:hover {
        background-image: url("/assets/images/icon/cross.png") !important;
        @include background-color($primary-text-color !important);
        @include border-radius(50px);
        background-size: 8px !important;
        background-position: center !important;
      }
    }
  }

  .fileshare-container {
    width: 66px;
    height: 66px;

    .upload-cont {
      height: 62px;
      @include border-radius;
    }
  }

  .dropfile-msg {
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
    @include background-color(#cfcfcf);

    img {
      width: 16px;
    }
  }

  .chat-file-attach .ui-fileupload-buttonbar {
    height: 62px;

    .ui-fileupload-choose {
      border: none !important;
    }
  }

  .ui-fileupload-content {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }

  .fileshare-container {
    padding: 0;
    margin: 0;
    height: 66px;
    // border: 2px solid #00bfa5;
    border-radius: 4px;

    .upload-cont {
      height: 62px;
      @include border-radius;

      img.cls-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }

      span.cls-cross {
        background-color: #28314b;
        color: #ffffff;
        top: -8px;
        right: -8px;
        bottom: initial;
        left: initial;
        position: absolute;
        z-index: 9;
        width: 18px;
        height: 18px;
        line-height: 18px;
        border-radius: 50px;
        font-size: 12px;
      }
    }
  }

  .dropfile-msg {
    img {
      width: 16px;
    }
  }

  .FileShareDialog {
    .fileshare-container {
      padding: 0;
      margin: 0;
      height: 66px;
      border: 0px solid #00bfa5;
      border-radius: 0px;
    }

    .has-no-file {
      .p-fileupload-content {
        display: none;
      }
    }
  }

  .pi-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  mat-hint {
    font-family: $font-family;
    font-size: $text-small-size;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.6;
    color: $utl-text-color-14;
    @include margin(left, top, right, bottom, 0, 4, 0, 0);
  }
}

.banner-file-share {
  width: 175px;

  label {
    font-family: $font-family;
    font-size: $text-default-size;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.43;
    color: $utl-grey-color-6;
    @include margin(left, top, right, bottom, 0, 0, 0, 8);
  }

  .ui-fileupload-content .ui-progressbar {
    display: none;
  }

  .chat-file-attach .ui-fileupload-content .ui-fileupload-files .ui-fileupload-row {
    padding: 0;
    margin: 0;
    height: 62px;
    // @include border(4px, solid, $utl-theme-color);
    // @include border-radius;
  }

  .chat-file-attach .ui-fileupload-row>div {
    font-size: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    button {
      position: absolute;
      right: -8px;
      top: -8px;
      background-image: url("/assets/images/icon/cross.png");
      @include background-color($primary-text-color);
      @include border-radius(50px);
      background-size: 8px !important;
      background-position: center !important;
      width: 18px;
      height: 18px;

      &:hover {
        background-image: url("/assets/images/icon/cross.png") !important;
        @include background-color($primary-text-color !important);
        @include border-radius(50px);
        background-size: 8px !important;
        background-position: center !important;
      }
    }
  }

  .fileshare-container {
    width: 175px;
    height: 66px;

    .upload-cont {
      height: 62px;
      @include border-radius;
    }
  }

  .dropfile-msg {
    width: 100%;
    height: 100%;
    padding: 0;
    top: 0;
    @include background-color(#cfcfcf);

    img {
      width: 15px;
    }
  }

  .chat-file-attach .ui-fileupload-buttonbar {
    height: 62px;

    .ui-fileupload-choose {
      border: none !important;
    }
  }

  .ui-fileupload-content {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
  }

  .previous-bg-img {
    .has-no-file {
      .fileshare-container {
        padding: 0;
        margin: 0;
        height: 66px;
        border: 0;
        border-radius: 4px;
      }
    }
  }

  .previous-bg-img {
    .fileshare-container {
      padding: 0;
      margin: 0;
      height: 66px;
      // border: 2px solid #00bfa5;
      border-radius: 4px;

      .upload-cont {
        height: 62px;
        @include border-radius;

        .chat-file-attach .p-fileupload-content .p-fileupload-files .p-fileupload-row {
          @include padding(left, top, right, bottom, 0, 0, 0, 0);
          @include margin(left, top, right, bottom, 0, 0, 0, 0);

          @include border(0px, solid, $utl-theme-color);
          @include border-radius;
        }

        img.cls-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }

        span.cls-cross {
          background-color: #28314b;
          color: #ffffff;
          top: -8px;
          right: -8px;
          bottom: initial;
          left: initial;
          position: absolute;
          z-index: 9;
          width: 18px;
          height: 18px;
          line-height: 18px;
          border-radius: 50px;
          font-size: 12px;
        }
      }
    }
  }

  .pi-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  mat-hint {
    font-family: $font-family;
    font-size: $text-small-size;
    font-weight: map-get($font-weights, "regular");
    line-height: 1.6;
    color: $utl-text-color-14;
    @include margin(left, top, right, bottom, 0, 4, 0, 0);
  }
}

.attch {
  .p-fileupload.p-fileupload-basic {
    .p-button.p-fileupload-choose {
      color: #06d79c !important;
      background-color: transparent !important;
      border-color: #00bfa5 !important;
      -webkit-box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2),
        0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
      box-shadow: 0 2px 2px 0 rgba(40, 190, 189, 0.14), 0 3px 1px -2px rgba(40, 190, 189, 0.2),
        0 1px 5px 0 rgba(40, 190, 189, 0.12) !important;
      -webkit-transition: 0.2s ease-in !important;
      -o-transition: 0.2s ease-in !important;
      transition: 0.2s ease-in !important;
      border: 1px solid !important;
      width: 164px;
      padding: 6px 15px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 15px;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        color: #fff !important;
        background-color: #04b381 !important;
        border-color: #04b381 !important;
      }

      .p-button-icon-left {
        display: none;
      }
    }
  }
}

.addtask {
  .document-uploader {
    .p-fileupload {
      border-radius: 5px;
      border: dashed 2px #cfcfcf;
      background-color: #f8f8f9;
    }

    .p-fileupload-content {
      .uploadDiv {
        @include padding(left, top, right, bottom, 24, 12, 24, 24);

        p {
          font-size: $text-default-size;
          line-height: 1.6;
          color: $utl-grey-color-4;

          b {
            color: $utl-theme-color;
            font-weight: map-get($font-weights, "medium");
            font-size: $text-default-size;
          }
        }
      }
    }
  }
}

// multiple file upload option - schedule interview
.multiple-fileopetion {
  .p-fileupload {
    .p-fileupload-buttonbar {
      @include background-color(transparent);
      @include padding(left, top, right, bottom, 0, 0, 0, 0);
      border: 0;
    }

    .p-fileupload-content {
      border: 0;
      @include padding(left, top, right, bottom, 0, 0, 0, 0);

      .p-fileupload-files {
        max-width: max-content;

        >div {
          display: flex;
          @include margin(left, top, right, bottom, 0, 0, 8, 0);
          flex-wrap: wrap;
        }
      }

      .p-progressbar {
        display: none;
      }

      .filename-new {
        @include padding(left, top, right, bottom, 16, 7, 16, 7);
        min-width: 33%;

        .nohoverbtn {

          &:hover,
          &:focus {
            @include background-color(transparent);
          }
        }
      }
    }
  }
}

// multiple image show
.wrapbox {
  display: flex;
  @include margin(left, top, right, bottom, 0, 0, 8, 0);
  flex-wrap: wrap;

  .filenamediv {
    @include padding(left, top, right, bottom, 16, 7, 16, 7);
    max-width: 44%;
  }

  .nohoverbtn-p {
    &.p-button:enabled {

      &:hover,
      &:focus {
        @include background-color(transparent);
      }
    }

  }
}

.linkedin-file-attach {
  .p-fileupload {
    .p-fileupload-buttonbar {
      background: transparent;
      padding: 0;
      border: 0;
      border-radius: 0;
      display: flex;
      justify-content: end;
      margin-right: -32px;
    }

  }

  p-fileupload {
    .hr-file-attach {
      flex-direction: column-reverse;
      display: flex;
      .p-fileupload-content {
        @include padding(left, top, right, bottom, 0, 0, 0, 0);
        border: 0;
        @include border-radius(0);
        @include background-color(transparent);

        .p-fileupload-files {
          max-width: initial !important;

          .p-fileupload-row {
            display: inline-flex;
            @include background-color(transparent);
            position: relative;
            overflow: initial;
            padding: 0;
            box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.3019607843);
            @include padding(left, top, right, bottom, 8, 8, 8, 8);
            @include margin(left, top, right, bottom, 0, 0, 10, 16);
            max-width: 14%;
            flex: 0 0 14%;
            box-sizing: content-box;

            .p-button-icon {
              display: none;
            }

          div {
            padding: 0;

              img {
                object-position: center;
                object-fit: cover;
                height: 63px;
                width: 100%;
              }

              &:first-child {
                width: 100%;
                height: 63px;
                margin-right: 0;
              }

              &:nth-child(2) {
                display: none;
              }

              &:nth-child(3) {
                display: none;
              }

              &:last-child {
                @include abs-pos(-10px, -5px, initial, initial);

              button {
                background-image: url("/assets/images/close-linkedin.svg") !important;
                @include background-color($utl-white);
                background-size: cover;
                @include border-radius(50%);
                width: 21px;
                height: 21px;
                border: 0;
              }
            }
          }
        }
      }

      .p-fileupload-files>div {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        @include margin(left, top, right, bottom, -16, 0, -10, 0);
        @include padding(left, top, right, bottom, 16, 0, 0, 0);
      }
    }

      .p-progressbar {
        display: none;
      }
    }
  }
}