@import "../../theme/main.scss";

// HRone Checkbox styling=================
.mat-mdc-checkbox {

  // margin-bottom: 12px;
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
  .mat-checkbox-layout {
    font-size: $text-default-size;
    color: $utl-text-color-12;
    font-weight: map-get($font-weights, "medium");
    line-height: 20px;
    margin-bottom: 0;
    max-width: 100%;

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mat-checkbox-inner-container {

      @include margin(left, top, right, bottom, 0, 0, 12, 0);
      // top:2px;
    }
  }
}

// ._icon-view-wrapper, .goal-policy, ._applicability, .check-box-alig, .wizard-steps, mat-tab-body{
//   .mat-checkbox {


//     .mat-checkbox-layout {

//       align-items: center;

//     }
//   }
// }

// HRone Radio styling=================


/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
/* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-radiobtn {
  margin-bottom: 12px;
  display: inline-block;

  .mat-mdc-radio-button {
    display: inline-block;

    label {
      font-size: $text-default-size;
      color: $utl-text-color-12;
      font-weight: map-get($font-weights, "medium");
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}


/************* Expansion css **************/
.mat-accordion {
  .kra-exp {
    @include margin(left, top, right, bottom, 0, 0, 0, 12);
    @include border-radius;
    box-shadow: none;

    .mat-expansion-panel-header {
      font-size: inherit;
      font-family: $font-family;
      display: flex;
      flex-direction: row;
      flex: 1;
      justify-content: space-between;
      align-items: flex-start;
      @include padding(left, top, right, bottom, 15, 16, 15, 16);


      .mat-expansion-indicator {
        transform-origin: 51% 68%;
        display: none;
        margin-right: 3px;
      }

      .mat-content {
        flex-direction: column;

        .mat-expansion-panel-header-description,
        .mat-expansion-panel-header-title {
          display: flex;
          flex-grow: 1;
          margin-right: 16px;
          justify-content: space-between;
     
          align-items: inherit;
        


          .mat-mdc-standard-chip {

            min-height: 1.142em;
            @include border(1px, solid, $utl-grey-color-16 !important);
            @include background-color($utl-grey-color-5 !important);
            font-family: $font-family;
            font-size: inherit;
            .mdc-evolution-chip__cell--primary{
              .mdc-evolution-chip__text-label{
                color: $utl-text-color-15 !important;
                font-size: 10px;
              }
            }
          }

          .cls-firstbox {
            // font-size: 14px;
            width: 65%;
            font-family: $font-family;
            .cls-krasubtext {
              font-size: 10px;
              color: $utl-text-color-15 !important;
              // font-weight: map-get($font-weights, "semi-bold");
              @include margin-em(left, top, right, bottom, 0, 0, 0, 1);


            }
          }

          .cls-secondbox {
            // font-size: 14px;
            font-family: $font-family;

            .cls-krasubtext {
              font-size: 10px;
              color: $utl-text-color-15 !important;
              font-weight: map-get($font-weights, "semi-bold");
              @include margin-em(left, top, right, bottom, 0, 0, 0, 0.714)
            }

            .cls-kpidata {
              font-size: $text-small-size;
              color: $primary-text-color;
              font-weight: map-get($font-weights, "semi-bold");
            }
          }

        }

      }

    }


    .mat-expansion-panel-content {

      .mat-expansion-panel-body {
        @include padding-em(left, top, right, bottom, 0, 0, 0, 0);
        @include margin(left, top, right, bottom, 0, 0, 0, 0);
        @include background-color($utl-grey-color-9);
        @include border-pos(top, 1px, solid, rgba(165, 165, 165, 0.21));
        font-size: inherit;

        .cls-kradropdown {
          @include padding(left, top, right, bottom, 0, 15.5, 0, 15.5);
          @include margin(left, top, right, bottom, 15, 0, 15, 0);
          @include border-pos(bottom, 1px, dashed, $utl-grey-color-10);
          font-size: $text-medium-size;
          color: $secondary-text-color;
          font-weight: map-get($font-weights, "medium");
          white-space: pre-wrap;
        }

        .cls-kradropdown:last-child {

          @include border-pos(bottom, 0px, dashed, $utl-grey-color-10);
        }
      }
    }
  }

}


.form-wrapper {
  .form-wrapper-inner {
    mat-accordion {
      mat-expansion-panel {
        .mat-expansion-panel-content {
          .mat-expansion-panel-body {
            background-color: #fff;
            border-top: none;
          }
        }
      }
    }
  }
}

/************* tab css ************/
/********** Goal tab **************/
#Goal-tab {
  @include border(1px, solid, $utl-grey-color-12);
  @include border-radius;
  position: relative;
  display: inline-block;

  .mat-mdc-radio-button {
    @include padding-em(left, top, right, bottom, 0, 0, 0, 0);
    @include margin(left, top, right, bottom, 0, 0, 0, 0);
    @include border-pos(right, 1.5px, solid, $utl-grey-color-12);


    .mdc-form-field {
      @include margin-em(left, top, right, bottom, 0, 0, 0, 0);

      .mdc-radio {
        display: none;
      }

      label {
        @include padding-em(left, right, top, bottom, 0.714, 0.714, 0.714, 0.714);
        cursor: pointer;
      }
    }


    .mat-mdc-radio-button.mat-radio-disabled .mat-radio-label-content {
      color: inherit;
      cursor: no-drop;
    }
  }

  .mat-mdc-radio-checked {
    @include background-color($color: $utl-theme-color);

    .mdc-form-field {
      label {
        color: $utl-white !important;

      }
    }
    
  }

  .mat-mdc-radio-checked:first-child {

    @include border-radius-pos(3px, 0, 0, 3px);
  }

  .mat-mdc-radio-checked:last-child {

    @include border-radius-pos(0, 3px, 3px, 0);
  }

  .mat-mdc-radio-button:last-child {
    @include border-pos(right, 0, solid, $utl-grey-color-12);
  }
}


.cls-competency {

  .mat-mdc-standard-chip {

    min-height: 1.142em;
    @include border(1px, solid, $utl-grey-color-16 !important);
    @include background-color($utl-grey-color-5 !important);
    font-family: $font-family;
    font-size: inherit;
    .mdc-evolution-chip__cell--primary{
      .mdc-evolution-chip__text-label{
        color: $utl-text-color-15 !important;
        font-size: 10px;
      }
    }
  }
  // .mat-mdc-standard-chip {
  //   min-height: 16px;
  //   @include border(1px, solid, $utl-grey-color-16 !important);
  //   @include background-color($utl-grey-color-5 !important);
  //   color: $utl-text-color-15 !important;
  // }
}



.form-styling {
  .form-group {
    @include margin(left, top, right, bottom, 0, 0, 0, 0);

    .p-float-label {
      @include padding-em(left, top, right, bottom, 0, 0, 0, 1.25);
    }

    .time-select {
      position: relative;
      margin: 14px 0 0;

      span {
        width: 100%;

        input {
          width: 100%;
        }
      }
    }
  }
}

.project-task .mat-expansion-indicator {
  display: block !important;
}

.project-task .mat-accordion .kra-exp .mat-expansion-panel-content .mat-expansion-panel-body {
  background: #f9fbfa;
}