@import '../../../theme/mixins';

@each $name, $glyph in $theme-mapper {
    $current-theme : map-get($theme-mapper, $name);

    body {
        &.#{$name} {
            .font-80 {
                font-size: 80px;
            }

            // no footer dialog
            .footerspace {
                .p-dialog-footer {
                    @include padding(left, top, right, bottom, 32, 16, 32, 16);
                }
            }

            .nofooterdialog {
                .p-dialog-footer {
                    border-top: 0 !important;
                    padding-top: 0px !important;
                }

            }

            // clearance card
            ._tasks-list {
                ul {
                    li {
                        .cls-important {
                            background-color: map-get($current-theme, theme-error);
                            border: 1px solid map-get($current-theme, theme-error);
                        }

                        .cls-prereq {
                            border: 1px solid map-get($current-theme, theme-primary);
                            background-color: map-get($current-theme, theme-backgroundPrimary);
                            color: map-get($current-theme, theme-primary);
                        }
                    }
                }
            }

            //  360 feedback
            .clsinboxheading {
                border-bottom: 2px solid map-get($current-theme, theme-contentPrimary);
            }

            // no hover class
            .no-hover {
                &:hover {

                    i,
                    span {
                        color: map-get($current-theme, theme-white) !important;
                    }
                }
            }

            // acknowledge hr handbook - html(coming up from the backend) content color change
            .cls-hrhandbook {
                .clsinner-html {

                    p,
                    span,
                    strong,
                    b {
                        color: map-get($current-theme, theme-contentPrimary) !important;
                    }
                }

                .clsanchor {
                    &:hover {
                        color: map-get($current-theme, theme-primaryLinkHover);
                    }
                }
            }

            // activity log - initiative
            .initiative-log {
                .workflow_base-contentbox {
                    background-color: map-get($current-theme, theme-primaryAccent);

                    &:after {
                        background-color: map-get($current-theme, theme-primaryAccent);
                    }
                }
            }

            // position assigned cards hover
            .summary-card {
                ul {
                    li {
                        border: 1px solid map-get($current-theme, theme-borderOpaque);

                        &:hover {
                            border: 1px, solid, map-get($current-theme, theme-primaryAccent);
                            border: 1px solid map-get($current-theme, theme-primaryAccent);
                            background-color: map-get($current-theme, theme-primaryAccent);
                        }
                    }
                }
            }

            // one on one reschedule flyer
            .cal-color {
                .mat-mdc-icon-button {
                    color: map-get($current-theme, theme-contentPrimary);
                }
            }

            // position assigned edit job cards hover state
            .referal-post-job {
                &.noborder {
                    ul li {
                        &:hover {
                            border: 0;
                           background-color: transparent;
                           border-bottom-color:unset;
                          }
                }
            }
                ul li {
                    &:hover {
                        border: 1px solid map-get($current-theme, theme-primary);
                        background-color: map-get($current-theme, theme-primaryAccent);
                    }
                }
            }

            // book travel email format link color 
            .email-link {
                .ql-snow .ql-editor a {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            //position assigned new tab top select boxes.custome-status {
            .custome-status {
                .status-inner {
                    border: 1px solid map-get($current-theme, theme-primary);
                }
            }

            // file attacher css
            .fileattacher {
                .p-fileupload {
                    background-color: transparent !important;

                    .p-button {
                        background-color: transparent !important;
                        border: 0px solid transparent;
                        // color: map-get($current-theme, theme-primary);

                        .p-button-icon-left {
                            display: none;
                        }
                    }

                    .p-fileupload-choose {
                        padding: 0;
                    }

                    .p-element {
                        .p-fileupload-choose {
                            .p-button-label {
                                color: map-get($current-theme, theme-positive);

                            }
                        }
                    }
                }
            }

            /************ QR-Code box css ***************/

            .QR_code {
                .p-dialog {
                    @extend .bgThemePrimary;
                    @extend .primaryBorder;

                    .p-dialog-header,
                    .p-dialog-content {
                        @extend .bgThemePrimary;
                    }

                    .btnclose {
                        .material-icons {
                            color: map-get($current-theme , theme-white);
                        }
                    }
                }
            }


            // investement diclaration css
            .cls-success,
            .cls-disabled {
                color: map-get($current-theme, theme-primary);
            }

            //  p-editor job opening form
            .jd-edit {
                .p-editor-container .p-editor-content .ql-editor {
                    color: map-get($current-theme, theme-contentPrimary);
                    background-color: map-get($current-theme, theme-backgroundPrimary);

                    p {
                        color: map-get($current-theme, theme-contentPrimary);
                    }
                }
            }

            // final clearance mat expansion - arrow icon bg
            .final-clearance {
                #panel-acc .mat-expansion-indicator {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                }
            }

            // add candidate dialog box 
            .cls-addcandidate .p-dialog .p-dialog-content .cls-content .cls-box.active {
                border-color: map-get($current-theme, theme-primary);
            }

            //helpdesk card
            .helpdeskupdate {
                .g-h {
                    &:before {
                        border-color: map-get($current-theme, theme-contentTertiary);
                    }
                }

                .cls-hrline {
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                }

                .chat-content-other::before {
                    border-right-color: map-get($current-theme, theme-primary);
                }

                .chat-content-me::before {
                    border-left-color: map-get($current-theme, theme-backgroundPrimary);
                }
            }

            .ticketStatus {
                .mat-mdc-tab-header-pagination-chevron {
                    border-color: map-get($current-theme, theme-contentPrimary);
                }
            }

            // settlement card 
            .comments-div {
                .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
                    color: map-get($current-theme, theme-contentPrimary);
                }
            }

            // salary structure page - revise ctc
            .updatesal {
                .cls-salarytitle:before {
                    color: map-get($current-theme, theme-contentPrimary);
                }

                .clstabview li.selected {
                    background-color: map-get($current-theme, theme-primaryAccent);
                    color: map-get($current-theme, theme-primary);
                }
            }

            // social profile - image upload icon visibility
            .image-update-visible {
                .p-fileupload {
                    background: transparent !important;

                }

                .emp-img-uploader {
                    background-image: url(/assets/images/photo-camera.png) !important;
                    @extend .bgThemePrimary;
                    background-size: 15px;
                    background-position: center;
                }
            }



            // add candidate dialog box 
            .cls-addcandidate .p-dialog .p-dialog-content .cls-content .cls-box.active {
                border-color: map-get($current-theme, theme-primary);
            }

            // snippet border class --- global
            .cls-headerborder {
                ._user-snippet {
                    hr {
                        border: 0px solid transparent;
                    }
                }
            }

            ._user-snippet {
                border: 1px solid map-get($current-theme, theme-borderOpaque);

                ._snippet-inner {
                    ._user-detail {
                        span.Profile_des {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                        .Goal_period {
                            @extend .primaryTxt;

                        }

                        h5 {
                            color: map-get($current-theme, theme-contentPrimary);
                        }

                    }

                    .clear_box {
                        .clear_text {
                            color: map-get($current-theme, theme-primary);
                        }
                    }

                    .cls-link {
                        &:hover {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }

                        span {


                            .cls-intpoint {
                                background-color: map-get($current-theme, theme-primary);

                            }
                        }
                    }
                }

                hr {
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                }
            }

            ._user-snippet-2 {
                background-color: map-get($current-theme, theme-backgroundPrimary);

                ._snippet-inner {
                    ._user-detail {
                        h5 {
                            @extend .contentPrimary;
                        }

                        span {
                            @extend .contentPrimary;
                        }
                    }

                    .snippet-cta {
                        a {
                            @extend .contentPrimary;
                        }
                    }

                    .cls-link {
                        &:hover {
                            background-color: map-get($current-theme, theme-backgroundTertiary);
                        }
                    }
                }
            }

            .bodrder-0 {
                border: 0px solid transparent;
            }

            // Request - asset page
            .request_header_base {
                .view_form {
                    .cls-heading {
                        @extend .contentPrimary;
                    }
                }

                .cls-heading,
                .cls-txt,
                .cls-hdtxt {
                    @extend .contentPrimary;
                }
            }

            //  Request - ATTENDANCE REGULARIZATION REQUEST
            .cls-attendancespace {

                .cls-heading,
                .cls-absdetail {
                    @extend .contentPrimary;
                }

                .cls-pendingrqe {
                    @extend .primaryTxt;
                }

                .cls-blanktext {
                    @extend .bg-primary-color;
                    @extend .blank-state-color;
                }

                .cls-absdetail {
                    @extend .bg-primary-color;
                }
            }

            // for calendar bg 
            .att-calendar .p-calendar .p-datepicker {
                @extend .bg-primary-color;
            }

            .cls-to {
                border: 1px solid map-get($current-theme, theme-primary);
            }

            .cls-tattextl {
                @extend .contentPrimary;
            }

            //variable page
            .request_email_base .tranferreq .cls-border {
                border: 1px solid map-get($current-theme, theme-borderOpaque);
                @extend .bg-Secondary;
            }

            // setup page cxo reports card ------ this is for all the gray cards appears on setup
            .card-gray {
                background-color: map-get($current-theme, theme-backgroundSecondary);

                .card-body {
                    .card-title {
                        &:before {
                            border-left: 2px solid map-get($current-theme, theme-primary);
                        }
                    }

                    .card-title-arc {
                        &:before {
                            border-left: 2px solid map-get($current-theme, theme-contentDisabled);
                        }
                    }
                }

            }

            // payroll view -- cxo page access page
            .dialog-wrapper .cls-headingmain {
                @extend .contentPrimary;
            }

            // Settings>> Organization>> customer>> New customer>> Use import -- circle outline
            .importsec {
                .circle {
                    border: 2px solid map-get($current-theme, theme-primary);
                    @extend .contentPrimary;
                }
            }

            // / organization >> Add role>> Define role>> Add role> >> tab color
            .group-tab {
                @extend .border-opaque;
            }

            .selected-group-tab-element {
                @extend .bgThemePrimary;
            }

            .clsAccessbox {
                @extend .bg-Secondary;
            }

            // checkbox filled state in role page -- setup 
            .clsmod {
                .filled-in:checked+label:after {
                    @extend .bgThemePrimary;
                    border: 2px solid map-get($current-theme, theme-primary);
                }
            }

            .hasOu {
                @extend .primaryTxt;
            }

            // expense page archieve - reportid color
            .loan_inbox {
                ._user-snippet {
                    .cls-spacing {
                        .cls-reportCode {
                            @extend .bgThemePrimary;
                        }
                    }
                }
            }

            // add experience - edit candidate
            .font-fix {
                .mat-mdc-checkbox .mdc-form-field label {
                    font-size: 12px !important;
                }
            }

            // create goal kra flyer
            .cls-Addkrabtn {
                @extend .primaryTxt;
                @extend .primaryBorder;
            }

            .cls-gybox {

                &.cls-maxhigh,
                &.cls-customhigh {
                    @extend .bgThemePrimary;
                }
            }

            // custom rating flyer -- review formula page for create goal
            .cls-ratingbtn {
                @extend .bg-accent-color;
                @extend .primaryTxt;
            }

            // sidebar selected icon and text color - Assign recommended goal create
            .clsleft-view {
                @extend .bg-Secondary;
            }

            .cls-KPIMain {
                .showmore {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            .cls-sidenav {

                li.selected,
                em,
                li:hover {
                    @extend .primaryTxt;
                }
            }

            // pip insight box border
            .tranferreq {
                @extend .border-opaque;
                border-radius: 4px;
            }

            // primary no fill button border /text
            .primary-no-fill {
                @extend .primaryBorder;
                @extend .primaryTxt;
            }

            .btn-light-green {
                @extend .primaryBorder;
                @extend .bgThemePrimary;
            }

            //  goal badge color bg

            // .cls-krabox,
            // .competency-box,
            // .card-header-strip {
            //     .cls-keyheading {
            //         @extend .bgThemePrimary;
            //     }
            // }

            .kra-txtbg {
                .cls-keyheading {
                    @extend .bgThemePrimary;
                }
            }

            // goal approval archieve  header 
            .goal-header {
                .inbox-top-panel {
                    padding-left: 0;
                }
            }

            .Goal_period {
                @extend .primaryTxt;
                left: 136px;
            }

            // announcement page preview
            .full-width-grey {
                &.p-sidebar {
                    .p-sidebar-content {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                    }
                }
            }

            // custom html prpgressbar slider
            input[type="range"] {
                &::-webkit-slider-runnable-track {
                    background-image: linear-gradient(90deg,
                            map-get($current-theme, theme-primary) var(--webkitProgressPercent),
                            map-get($current-theme, theme-backgroundSecondary) var(--webkitProgressPercent));
                }

                &::-moz-range-track {
                    background-image: linear-gradient(90deg,
                            map-get($current-theme, theme-primary) var(--webkitProgressPercent),
                            map-get($current-theme, theme-backgroundSecondary) var(--webkitProgressPercent));
                }

            }

            input[type=range] {
                &::-webkit-slider-thumb {
                    background-color: map-get($current-theme, theme-primary);
                    background-image: url(/assets/images/slider-drag.png);
                    background-size: 52%;
                    background-position: center center;
                    transform: rotate(90deg);
                }

                &::-moz-range-thumb {
                    background-color: map-get($current-theme, theme-primary);
                    background-image: url(/assets/images/slider-drag.png);
                    background-size: 52%;
                    background-position: center center;
                    transform: rotate(90deg);
                }
            }

            // expense report page -- report num color
            .cls-reportCode {
                @extend .bgThemePrimary;
                color: #fff;

                span {
                    color: #fff !important;
                }
            }

            // performance review 
            .loan_inbox {
                .cls-viewtxt {
                    @extend .primaryTxt;
                }
            }

            // stepper in performance review approval page
            .cls-stripbox {
                .stepper {
                    @extend .content-secondary;
                    border-top: 2px solid map-get($current-theme, theme-borderOpaque);

                    &:after {
                        border: 2px solid map-get($current-theme, theme-borderOpaque);
                    }
                }

                .cls-active {
                    @extend .primaryTxt;
                    border-top: 2px solid map-get($current-theme, theme-primary);

                    &:after {
                        border: 2px solid map-get($current-theme, theme-primary);
                    }
                }

                .cls-selected {
                    @extend .positiveTxt;
                    border-top: 2px solid map-get($current-theme, theme-positive);

                    &:after {
                        border: 2px solid map-get($current-theme, theme-positive);
                        background-color: map-get($current-theme, theme-positive);
                    }
                }
            }

            span.chips-design.green {
                background-color: map-get($current-theme, theme-positive) !important;
            }

            span.text-green {
                @extend .positiveTxt;
            }

            // performance review title in single line -- checkbox text
            .cls-proformance-review {
                .subtitle {
                    margin-bottom: 0;
                }

                .showmore {
                    color: map-get($current-theme, theme-primary) !important;
                }

                ._gp-header {
                    .badge {
                        @extend .bgThemePrimary;
                    }
                }

                .cls-wrapper {
                    ._green-box {
                        background-color: map-get($current-theme, theme-warning)
                    }
                }


                .mat-mdc-progress-bar {
                    &._badge-progress {

                        .mdc-linear-progress__bar,
                        .mdc-linear-progress__primary-bar {
                            .mdc-linear-progress__bar-inner {
                                border-color: map-get($current-theme, theme-warning)
                            }
                        }
                    }
                }


            }

            .cls-intibox {
                @extend .primaryTxt;
            }

            // profile page left panel color 
            #manage-list {
                &.mat-mdc-list[dense] {
                    &.candidate-page {
                        .mdc-list-item__content {
                            .mdc-list-item__primary-text {
                                a.activecolor {
                                    color: map-get($current-theme, theme-primary);
                                }
                            }
                        }
                    }

                    .mat-mdc-list-item {
                        .mdc-list-item__content {
                            .mdc-list-item__primary-text {
                                a.active {
                                    @extend .primaryTxt;
                                }
                            }
                        }
                    }
                }
            }

            // profile - family page btn
            .newadd-btn {
                @extend .btn-success;
            }

            //skills and  document page upload link -profile
            .labelUpload,
            .cls-upload-btn {
                @extend .primaryTxt;
            }

            // checkbox text break
            .checboxbreak {
                .mdc-form-field label {
                    white-space: break-spaces;
                }
            }

            // engage setting page - setup 
            .cls-Engage-setting {
                .mat-mdc-radio-group .active {
                    @extend .primaryBorder;
                    @extend .bgPrimaryAccent;
                }
            }

            // checkbox small size in engage setting page - setup edit reward
            .checkbox-sm {
                .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
                    width: 8px;
                    height: 3px;
                }
            }

            // engage - feedback template - setup page
            .question-form .section-select,
            .question-form .section-unselect {
                border-left: 2px solid map-get($current-theme, theme-primary) !important;

                .cls-section {
                    @extend .bgThemePrimary;

                    .clscut-img {
                        img {
                            display: none;
                        }

                        width: 0px;
                        height: 0px;
                        border-style: solid;
                        border-width: 41px 34px 0 0;
                        border-color: map-get($current-theme, theme-primary) transparent transparent transparent;
                        transform: rotate(0deg);
                    }
                }
            }


            //   engage - feedback template - setup page - add btn 
            .clsaddbutton {
                @extend .bgThemePrimary;
                border: 1px solid map-get($current-theme, theme-primary);
            }

            .template-sample {
                .selected {
                    border: 1px solid map-get($current-theme, theme-primary) !important;
                    @extend .bgPrimaryAccent;
                }

            }

            // change manager -actions toggle btns

            #TG-BTN .slider:before {
                @extend .bgThemePrimary;
            }

            // goal sheet separater color
            .goalborder-secondary {
                border: 4px solid map-get($current-theme, theme-backgroundSecondary);
            }

            // settlement card css

            ._review-card {
                ul {
                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                    background-color: map-get($current-theme, theme-backgroundTertiary);

                    li {

                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);

                        &:last-child {
                            border-bottom: none;
                        }

                        ._review-inner {
                            span {
                                border: 1px solid map-get($current-theme, theme-borderOpaque);


                            }

                        }

                        .btn-edit {
                            background-color: map-get($current-theme, theme-primary);

                        }

                    }
                }
            }

            // pip eval inititive detail page
            .inintiative-flyer {
                ._user-snippet {
                    border: none;

                }
            }

            // Goal checkin : Team insights
            .cls-chekinaccordion {
                .cls-hrline {
                    background-color: map-get($current-theme, theme-backgroundSecondary) !important;
                }
            }

            // goal policy setup
            #Goal-tab .mat-mdc-radio-checked {
                @extend .bgThemePrimary;
            }

            // Initiate induction approval
            .jd-text {
                .showmore {
                    color: map-get($current-theme, theme-primary) !important;
                }
            }

            // leave balance from profile 
            .leavebalance-spacing .clsgray-list .list-type li {
                &.selected {
                    @extend .primaryTxt;
                }
            }

            // survey pop up border remove
            .dialogContentInner {
                ._user-snippet {
                    border: 0;
                }

            }

            // hr desk card detail 
            .child-wrap {
                a {
                    @extend .primaryTxt;
                }
            }

            // file upload color for candidate page
            .document-uploader .p-fileupload .p-fileupload-buttonbar .p-fileupload-choose .p-button-label {
                @extend .hyperlink;
            }

            .tablefixedlayout {
                table {
                    table-layout: fixed;
                }
            }

            // goal sheet template
            .colored {
                p-inputswitch {
                    .p-inputswitch {
                        &.p-disabled {
                            .p-inputswitch-slider {
                                background-color: map-get($current-theme, theme-primary) !important;

                                &::before {
                                    background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                                }
                            }
                        }
                    }
                }
            }

            // badges counter theme
            .badges-list-wrapper {
                .green-circle {
                    @extend .bgThemePrimary;
                }
            }

            // badges comments attachment
            .comments-list {
                .user-remarks {
                    .attachments {
                        .cls-value {
                            @extend .text-success;
                        }
                    }
                }
            }

            // request page link color
            .clsviewdetail {
                @extend .text-success;
            }

            // setup - add project page /// visibility page
            .projectradiobtn {
                .mat-mdc-radio-group .mat-mdc-radio-checked {
                    @extend .bgThemePrimary;

                    .mdc-form-field label {
                        color: map-get($current-theme, theme-white) !important;
                    }
                }

            }

            // remove double border from imports
            .singlebordercls {
                .mat-mdc-tab-group {
                    .mat-mdc-tab-label-container {
                        .mat-mdc-tab-list {
                            .mat-mdc-tab-labels {

                                .mdc-tab {
                                    border-bottom: 0 !important;

                                }
                            }
                        }
                    }
                }
            }




            // change goal flyer from >> action
            .changegoal {
                .inbox-top-panel {
                    padding-left: 30px;
                    padding-right: 30px;

                    .header-investment {
                        width: auto !important;
                    }

                    // changegoal text 61236
                    .col-changegoal {
                        width: auto !important;
                    }

                    ._user-detail {
                        width: 114px;
                    }

                    .btn-fix {
                        width: 45% !important;
                    }

                    .arrow-right {
                        margin-left: 0rem !important;
                        margin-right: 6px !important;
                    }

                    .w-17 {
                        width: auto;
                    }

                    .cls-viewply {
                        padding-right: 15px;
                    }

                }

            }

            // helpdesk ticket detail page chips
            .clsgraphrow {
                &.ticketStatus {
                    .mat-mdc-chip-listbox {
                        .mdc-evolution-chip-set__chips {
                            .mat-mdc-chip {
                                &.mdc-evolution-chip--selected {
                                    @extend .bgThemePrimary;

                                    .mdc-evolution-chip__text-label {
                                        color: map-get($current-theme, theme-white) !important;
                                        font-size: 0.857em;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // job opening clone detail flyer select 

            .clonejob {
                mat-button-toggle-group {
                    border: 0 !important;
                }

                ul {
                    li {
                        .selectjob {
                            &:hover {
                                border: 1px solid map-get($current-theme, theme-primary) !important;
                            }
                        }
                    }
                }


                .mat-button-toggle-checked {

                    .selectjob {
                        @extend .primaryBorder;

                    }
                }
            }

            // interview feedback flyer extra space remove from interviewer field
            .inbox-pagealign {
                .interview-feedback {
                    .autoCompleteOne.p-autocomplete .p-autocomplete-multiple-container.p-disabled .p-autocomplete-input-token input {
                        &:empty {
                            display: none;
                        }

                    }
                }

                &.resumeshortlist {
                    .sidebar-body-height {
                        height: calc(100vh - 173px) !important;
                        padding: 0 !important;
                    }
                }

                .flyer-footer {
                    position: absolute;
                }
            }

            // resume shortlist
            .cls-selectborder {
                &.shortlist {
                    border: 1px solid map-get($current-theme, theme-positive);

                    .mat-mdc-select-value {
                        .mat-mdc-select-value-text {
                            .mat-mdc-select-min-line {
                                @extend .positiveTxt;
                            }
                        }

                    }

                    .mat-mdc-select-arrow-wrapper {
                        border-left: 1.5px solid map-get($current-theme, theme-positive);

                        .mat-mdc-select-arrow {
                            border-color: map-get($current-theme, theme-positive) !important;
                        }
                    }

                }

                &.reject {
                    border: 1px solid map-get($current-theme, theme-error);

                    .mat-mdc-select-value {
                        .mat-mdc-select-value-text {
                            .mat-mdc-select-min-line {
                                color: map-get($current-theme, theme-error);
                            }

                        }
                    }

                    .mat-mdc-select-arrow-wrapper {
                        border-left: 1.5px solid map-get($current-theme, theme-error);
                        padding: 5px 0 11px 10px;

                        .mat-mdc-select-arrow {
                            border-color: map-get($current-theme, theme-error) !important;
                        }
                    }

                }

                &.pending {
                    border: 1px solid map-get($current-theme, theme-warning);

                    .mat-mdc-select-value {
                        .mat-mdc-select-value-text {
                            .mat-mdc-select-min-line {
                                color: map-get($current-theme, theme-warning);
                            }

                        }
                    }

                    .mat-mdc-select-arrow-wrapper {
                        border-left: 1.5px solid map-get($current-theme, theme-warning);
                        padding: 5px 0 11px 10px;

                        .mat-mdc-select-arrow {
                            border-color: map-get($current-theme, theme-warning) !important;
                        }
                    }

                }

            }

            // timesheet table archive table strip color
            .table-stripeaccent {
                table thead tr th {
                    @extend .bgPrimaryAccent;
                }
            }


            // candidate
            .vertical-stepper {
                .icon-flow {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    @extend .bgPrimaryAccent;
                    line-height: 40px;
                }
            }

            .colorfix {
                .warningTxt {
                    color: map-get($current-theme, theme-warning) !important;
                }
            }

            // reports search from team
            .cls-reportserch {

                &:hover,
                &:focus {
                    border: 1.5px solid map-get($current-theme, theme-primary);
                }
            }

            // team page right btn enable in card slider
            .rightbtnvisible {
                p-carousel .p-carousel-content .p-carousel-container .p-disabled {
                    display: block;
                }
            }

            // my team Leaderboard select css
            ._team-select {
                .mat-mdc-select-trigger .mat-mdc-select-value .mat-mdc-select-value-text .mat-mdc-select-min-line {
                    font-size: 0.625rem;
                    font-weight: medium;
                }
            }

            // team page comment flyer -- leadership select box
            .cls-subsborder {
                .rectangle {
                    &:hover {
                        border-color: map-get($current-theme, theme-primary);
                    }
                }
            }

            // survey pop up height issue fix
            .survey-fix {
                .dialogContentInner {
                    .height-fixissue {
                        .mat-mdc-form-field-infix {
                            padding-top: 0 !important;
                        }
                    }
                }

            }

            // text area height ixxue fix on social profile 
            // employee other detail
            .textarea-heightfix {
                textarea {
                    height: 80px !important;
                }
            }

            // Engage dashboard - cxo
            #engage-gph,
            #performance-gph {

                .mat-mdc-tab-header {
                    border-bottom: 0 !important;


                    .mat-mdc-tab-label-container {
                        .mat-mdc-tab-list {

                            .mat-mdc-tab-labels {
                                @extend .primaryBorder;

                                .mdc-tab,
                                .mat-mdc-tab,
                                .mat-mdc-focus-indicator {
                                    border-bottom: 0 !important;
                                    border-right: 1px solid map-get($current-theme, theme-primary);
                                    @extend .primaryTxt;

                                    &:last-child {
                                        border-right: 1px solid map-get($current-theme, theme-primary);
                                    }

                                    .mdc-tab__content {
                                        .mdc-tab__text-label {
                                            @extend .whiteTxt;
                                        }
                                    }

                                    &.mdc-tab--active,
                                    .mdc-tab-indicator--active {
                                        @extend .bgThemePrimary;

                                        .mdc-tab__text-label {
                                            color: map-get($current-theme, theme-white) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            // request - variable
            .no-border-variable {
                .tranferreq {
                    border: 0 !important;
                }
            }

            // hr post images on feed - image modal
            .mainpage-imgbox {
                .p-galleria .p-galleria-close {
                    margin: 53px 0.5rem;
                    width: 40px;
                    height: 40px;

                    .p-galleria-close-icon {
                        font-size: 1.5rem;
                    }
                }

                .images-div {
                    display: flex;
                    height: calc(100vh - 100px);
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        max-height: 100%;
                        margin-top: 45px;
                    }
                }
            }

            // team calendar dashboard - all process modal
            .available-search-modal {
                .cls-chipsbox {
                    .list-link {
                        &.active-chip {
                            &:hover {
                                border: 1px solid #E8E8E8 !important;

                                &:before {
                                    display: none;
                                }
                            }

                        }

                        &.active-chip {
                            font-weight: 600;
                        }

                        &:hover,
                        &.active-chip {
                            border: 1px solid map-get($current-theme, theme-primary) !important;
                            position: relative;
                            @extend .bgPrimaryAccent;
                            @extend .content-secondary;

                            i {
                                @extend .primaryIcon;
                            }

                            &:before {
                                content: '';
                                position: absolute;
                                top: 0;
                                right: 0;
                                bottom: 0;
                                left: 0;
                                background: map-get($current-theme, theme-primary) !important;
                                opacity: 0.05;
                                z-index: -1;
                            }
                        }
                    }
                }
            }

            // team - highlight date
            .higlight-date {
                span {
                    background-color: map-get($current-theme, theme-secondaryButton);
                }
            }

            // hover states
            .todaybtn {
                &:hover {
                    background-color: rgba(0, 0, 0, .04);
                }
            }

            span.arrowhover {
                border-radius: 4px;

                i {
                    padding: 5px 8px;

                }

                &:hover {
                    background-color: map-get($current-theme, theme-backgroundDisabled);
                }
            }

            .hoverfull {
                padding: 2px 8px;
                border-radius: 4px;

                &:hover {
                    background-color: map-get($current-theme, theme-backgroundDisabled);
                }
            }

            // secondary color for team tabs
            section.calendar-main-container#calendarMainContainer {
                .group-btn {
                    mat-button-toggle-group {
                        border-color: map-get($current-theme, theme-secondaryButton) !important;

                        .mat-button-toggle-label-content {
                            color: map-get($current-theme, theme-contentSecondary) !important;
                            line-height: 28px;

                            * {
                                vertical-align: text-top;
                            }
                        }

                        .mat-button-toggle-checked .mat-button-toggle-label-content {
                            color: map-get($current-theme, theme-white) !important;
                            background-color: map-get($current-theme, theme-secondaryButton) !important;

                        }
                    }

                }
            }

            // hrone Ai pop pop up
            .ai-modal {
                .p-dialog .p-dialog-content .cls-blanktext {
                    height: calc(100vh - 302px);
                }

                .p-dialog-footer {
                    border: 0 !important;
                    padding-bottom: 24px;
                }
            }

            // mobile disclaimer
            .disclaimer {
                background-color: map-get($current-theme, theme-warningBackground);

                .mobileprogress .p-progressbar {
                    border-radius: 2px 2px 0px 0px;
                    @extend .bgThemePrimary;
                    border: 0px solid transparent;

                    .p-progressbar-value {
                        @extend .bgPrimaryAccent;
                        font-size: 0;
                    }
                }
            }

            .btn-outline-success {
                &.colorfix {
                    &:hover {
                        @extend .bgPrimaryAccent;
                    }
                }
            }

            // sidebar border added for teams
            .menu-item {
                &.addborder {
                    border: 1px solid map-get($current-theme, theme-contentTertiary);
                    width: 56px !important;

                    .menu-link {
                        border-radius: 0 !important;

                        &:hover {
                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                            color: map-get($current-theme, theme-primary) !important;
                        }
                    }
                }
            }

            // heading color underline - bug 62335
            .headingclr {
                .inbox-top-panel {
                    .flyer-with-snippet {
                        .header-title {
                            &::before {
                                background-color: map-get($current-theme, theme-contentPrimary);
                            }
                        }
                    }
                }
            }

            // asset pagination
            #hardware-inv {
                .p-carousel {
                    .p-carousel-indicators {

                        .p-carousel-indicator {

                            &.p-highlight {
                                button {
                                    @extend .bgThemePrimary;

                                }

                            }
                        }
                    }
                }
            }

            // asset pagination
            #hardware-inv {
                .p-carousel {
                    .p-carousel-indicators {

                        .p-carousel-indicator {

                            &.p-highlight {
                                button {
                                    @extend .bgThemePrimary;

                                }

                            }
                        }
                    }
                }
            }

            .line-0 {

                .mdc-line-ripple::before,
                .mdc-line-ripple::after {
                    border: 0;
                }

                &.h-58 {
                    height: 50px;
                }
            }

            // border on profile image
            .clsprofileimg {
                &.addborderprimary {
                    @extend .primaryBorder;
                }
            }

            // project refresh btn
            .projectmang {
                .btn-tertiary {

                    &:hover,
                    &:visited {
                        @extend .bg-accent-color;
                    }
                }
            }

            // career portal slider color
            .career-portal-slider {
                .slider {
                    &:before {
                        box-shadow: 0 2px 4px 0 map-get($current-theme, theme-primaryAccent);
                        @extend .bgThemePrimary;
                    }
                }
            }

            .file-share .colorrchange {
                border-bottom: 1.5px solid map-get($current-theme, theme-primary) !important
            }

            .cls-cpaccordion {
                .p-colorpicker {
                    .p-inputtext:enabled {
                        &:focus {
                            border-color: map-get($current-theme, theme-primary);
                        }

                        &:hover {
                            border-color: map-get($current-theme, theme-primary);
                        }
                    }
                }
            }



            // project management theming
            .project-theme {
                &.activestatus {
                    &:before {
                        @extend .bgPositive;
                    }
                }
            }

            // custom slide btn color changes
            .customslide {
                .mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
                    @extend .bgPositive;
                }

                .mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
                    border: 2px solid map-get($current-theme, theme-positive) !important;
                }
            }

            // team page
            .teamFilter {
                &.cust-team {
                    border-radius: 0;

                    p-autoComplete {
                        span {
                            input {
                                @extend .bg-Secondary;
                                border: 0;
                                padding: 0 10px 0 10px;


                            }
                        }

                        .p-autocomplete-input {
                            &:focus {
                                border-bottom: 0 !important;
                            }

                        }
                    }
                }
            }

            .grouplist {


                .mdc-tab-indicator--active {
                    @extend .bgThemePrimary;
                }

                .mat-mdc-focus-indicator,
                .mdc-tab-indicator__content--underline {
                    border-color: map-get($current-theme, theme-primary);
                }
            }

            .mat-mdc-tab-group {

                &.nospacetabs {
                    .mat-mdc-tab-header {
                        border-bottom: 0px !important;

                        .mat-mdc-tab-label-container .mat-mdc-tab-list .mat-mdc-tab-labels .mdc-tab {
                            border-bottom: 0px !important;
                            padding: 0 0 !important;
                        }

                    }
                }
            }

            //primengcheckbox
            .primengcheckbox {
                .p-checkbox .p-checkbox-box {
                    width: 16px;
                    height: 16px;

                    &.p-highlight {
                        @extend .bgThemePrimary;
                        border-color: map-get($current-theme, theme-primary);
                    }
                }
            }

            // blue hyperlink on browse files
            .cus-attacher {
                &.bluelink {
                    p-fileupload {
                        .p-fileupload {
                            .p-button-label {
                                color: map-get($current-theme, theme-primaryLink) !important;

                            }
                        }
                    }

                }

            }

            // mat-expansion panel css for goal checkin , company and team
            ._customize_tabs {

                .cls-chekinaccordion {
                    .mat-accordion {
                        .mat-expansion-panel:not([class*="mat-elevation-z"]) {
                            border: 1px solid map-get($current-theme, theme-borderOpaque);

                        }
                    }
                }
            }

            // multiple file upload option theme color - schedule interview
            .multiple-fileopetion {
                &.custom-validation {
                    .p-message {
                        .p-message-wrapper {
                            padding: 5px;
                        }

                        &.p-message-error {
                            @extend .warningnote;
                            @extend .warningBorder;
                            border-left-width: 3px !important;
                        }

                        .p-message-icon {
                            font-family: "Font Awesome 6 Pro";
                            font-size: 14px;
                            padding-left: 8px;
                            color: map-get($current-theme, theme-warning);
                            font-weight: 900;

                            &:before {
                                content: "\f071";
                            }


                        }

                        .p-message-detail {
                            color: map-get($current-theme, theme-contentTertiary);
                            margin-left: 4px;
                        }

                        .p-message-close {
                            i.p-message-close-icon {
                                color: map-get($current-theme, theme-contentTertiary);
                                font-size: 14px;
                            }

                        }
                    }

                }

                &.profile-picupload {
                    &.custom-pic {
                        .p-fileupload .p-fileupload-row>div {
                            padding: 0;
                            width: auto;
                            margin-right: 10px;

                            &:nth-child(1) img {
                                max-width: 100%;
                                border-radius: 100%;
                                object-fit: cover;
                                width: 32px;
                                height: 32px;
                            }

                            &:nth-child(2) {
                                @include padding(left, top, right, bottom, 16, 7, 16, 7);
                                min-width: 33%;
                                @extend .bg-Secondary;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                @extend .contentPrimary;
                                margin-right: 0px;
                            }

                            &:nth-child(3) {
                                display: none;
                            }

                            &:nth-child(4) {
                                .p-button {
                                    .pi {
                                        font-size: 12px;
                                    }

                                    width: 20px;
                                    @extend .contentPrimary;
                                    @extend .bg-Secondary;
                                    border: 0;
                                    @include padding(left, top, right, bottom, 16, 7, 16, 7);

                                    &:hover,
                                    &:focus {
                                        background-color: map-get($current-theme, theme-backgroundSecondary) !important;
                                        border: 0;
                                        outline: 0;
                                    }
                                }
                            }

                        }

                    }

                    .p-fileupload {
                        .p-button {
                            &.p-fileupload-choose {
                                background-color: transparent !important;
                                color: map-get($current-theme, theme-primary) !important;
                                border: 1px solid map-get($current-theme, theme-primary) !important;
                                padding: 8px 16px;

                                &.p-disabled {
                                    display: none;
                                }

                                &:hover {
                                    background-color: map-get($current-theme, theme-primary);
                                    color: map-get($current-theme, theme-white);
                                    border: 1px solid map-get($current-theme, theme-primary) !important;
                                }

                            }
                        }
                    }
                }

                .p-fileupload {
                    .p-button {
                        &.p-fileupload-choose {
                            @extend .btn-outline-success;
                            padding: 10px 24px 10px 16px;

                            &.p-disabled {
                                @extend .bg-disabled;
                                @extend .blank-state-color;
                                opacity: 1;
                                border-color: transparent !important;
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        &.p-button-icon-only {
                            width: auto;
                            padding: 0;
                        }
                    }

                }
            }

            // css horizontal stepper

            .cls-horizontalstepper {



                .mat-horizontal-stepper-wrapper {

                    .mat-horizontal-stepper-header-container {


                        .mat-horizontal-stepper-header {

                            .mat-step-icon-state-edit {
                                background-color: map-get($current-theme , theme-primary) !important;
                                border: 1px solid map-get($current-theme , theme-primary) !important;
                                color: map-get($current-theme , theme-white) !important;
                            }

                            .mat-step-icon {
                                background-color: transparent;
                                border: 1px solid map-get($current-theme , theme-borderOpaque);
                                color: map-get($current-theme , theme-black);
                            }

                            .mat-step-icon-selected {
                                background-color: map-get($current-theme , theme-primary);
                                border: 1px solid map-get($current-theme , theme-primary);
                                color: map-get($current-theme , theme-white);
                            }

                            .mat-step-label {
                                color: map-get($current-theme , theme-black);
                            }

                            .mat-step-label-selected {
                                color: map-get($current-theme , theme-primary);
                            }

                        }
                    }

                }

                &.mat-stepper-vertical {
                    .mat-step {
                        .mat-step-header {
                            .mat-step-icon {
                                background-color: transparent;
                                border: 1px solid map-get($current-theme , theme-borderOpaque);
                                color: map-get($current-theme , theme-black);

                            }

                            .mat-step-icon-selected {
                                background-color: map-get($current-theme , theme-primary);
                                border: 1px solid map-get($current-theme , theme-primary);
                                color: map-get($current-theme , theme-white);
                            }

                            .mat-step-label {
                                color: map-get($current-theme , theme-black);
                            }

                            .mat-step-label-selected {
                                color: map-get($current-theme , theme-primary);
                            }
                        }
                    }
                }
            }

            // 61109 loan request fix
            .loan-upload {
                .p-button-label {
                    text-decoration: underline;
                    @extend .text-success;
                }
            }

            // 61109 loan request fix
            .loan-upload {
                .p-button-label {
                    text-decoration: underline;
                    @extend .text-success;
                }
            }


            // placeholder fontsize - teams people
            .team-people {
                ::-webkit-input-placeholder {
                    @extend .content-tertiary;
                    font-size: 13px;
                }
            }




            // global search result css


            .cls-mainboxtab {
                .cls-tabbox {
                    .cls-menuiconhome {
                        border-bottom: 2px solid map-get($current-theme, theme-borderOpaque);
                        color: map-get($current-theme , theme-contentPrimary);

                        &.selected {
                            border-bottom: 2px solid map-get($current-theme, theme-primary);
                            color: map-get($current-theme , theme-primary);
                            font-weight: 700;

                        }
                    }

                    .cls-tab {
                        border-bottom: 2px solid map-get($current-theme, theme-borderOpaque);
                        color: map-get($current-theme , theme-contentPrimary);

                        &.selected {
                            border-bottom: 2px solid map-get($current-theme, theme-primary);
                            color: map-get($current-theme , theme-primary);
                            font-weight: 700;

                        }

                    }


                }

            }

            //onboarding screen
            .steps-section {
                .hoverdelete {
                    &:hover {
                        @extend .bgSecondaryAccent;
                    }
                }

                .hoversuccess {
                    &:hover {
                        @extend .bgPrimaryAccent;
                    }
                }
            }

            .sidebar-filter-new {
                .mat-expansion-panel-header {
                    @extend .contentPrimary;
                    @extend .border-opaque_bottom;
                    border-radius: 0px;

                    .mat-expansion-indicator::after {
                        border-width: 0 1px 1px 0;
                        @extend .text-success;
                    }
                }
            }

            // disable-button 
            .disabletxt {

                color: map-get($current-theme, theme-contentDisabled);
            }

            .arc-card .ranks-wof {
                &:after {
                    @extend .bgPrimaryAccent;
                }
            }


            // performance rating css

            .cls-my-table {

                .p-datatable {
                    .p-datatable-wrapper {
                        table.p-datatable-table {
                            border: 0 !important;

                            .p-datatable-thead {
                                tr {
                                    th {
                                        background-color: map-get($current-theme , theme-backgroundSecondary);
                                        padding: 5px;

                                        &.bg-clr {
                                            border: none !important;
                                        }

                                        &.bg-accent-clr {
                                            background-color: map-get($current-theme , theme-primaryAccent) !important;
                                        }

                                        &.bg-bottom-clr {

                                            border-top: 0 solid transparent !important;

                                        }
                                    }
                                }
                            }

                            tbody {
                                tr {
                                    td {
                                        background-color: map-get($current-theme , theme-backgroundPrimary);
                                        padding: 5px;


                                        &.bg-clr {
                                            border: none !important;
                                        }

                                        &.bg-accent-clr {
                                            background-color: map-get($current-theme , theme-primaryAccent) !important;
                                        }

                                        &.bg-bottom-clr {

                                            border-top: 0 solid transparent !important;

                                        }
                                    }

                                    &:nth-child(even) {
                                        td {
                                            background-color: map-get($current-theme , theme-backgroundPrimary);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }

            // global search progress bar css

            .cls-global-progressbar {
                .p-progressbar {
                    .p-progressbar-value {
                        background-color: map-get($current-theme , theme-primary);
                    }
                }
            }

            // p-autocomplte - placeholder text trim
            .refertext .p-autocomplete input.p-autocomplete-input {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }


            // integration > scim stylling
            .scim-container {
                &.geo-loc {
                    .mat-mdc-tab-body-wrapper {
                        padding: 0 16px 16px 16px;
                    }
                }
                &.project-screen {
                    .mat-mdc-tab-body-wrapper {
                        padding: 0;
                    }
                }
                &.api-itegration {
                    .mat-mdc-tab-body-wrapper {
                        padding: 0;
                        background-color: transparent;
                    }
                }

                .mat-mdc-tab-body-wrapper {
                    background-color: map-get($current-theme, theme-backgroundPrimary);
                    padding: 24px 16px 16px 16px;
                }

                .mat-mdc-tab-group {
                    &.fixtab
                    {
                        .mdc-tab {
                            min-width: 100px !important;
                        }
                    }
                   
                         
                    &.no-inherit {
                        .mdc-tab {
                            all: unset;
                            height: 40px;
                            background-color: transparent !important;
                            cursor: pointer;
                               &.mdc-tab--active {
                                background-color: transparent !important;
                            }
                        }
                    }

                    .mat-mdc-tab-header {
                        .mat-mdc-tab-label-container {
                            .mat-mdc-tab-list {
                                .mat-mdc-tab-labels {
                                    .mdc-tab {
                                        background-color: map-get($current-theme, theme-backgroundTertiary);
                                        border-bottom: 2px solid map-get($current-theme, theme-backgroundTertiary) !important;
                                        color: map-get($current-theme, theme-contentSecondary) !important;

                                        &:first-child {
                                            border-top-left-radius: 4px;
                                        }

                                        &:last-child {
                                            border-top-right-radius: 4px;
                                        }

                                        &.mdc-tab--active {
                                            background-color: map-get($current-theme, theme-backgroundPrimary);
                                            border-bottom: 2px solid map-get($current-theme, theme-primary) !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .scim-tabular-data {
                    background-color: map-get($current-theme, theme-backgroundSecondary);

                    .mat-mdc-form-field {
                        .mat-mdc-input-element {
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }

                    mat-form-field {
                        .mat-mdc-text-field-wrapper {
                            .mdc-line-ripple {
                                &::before {
                                    border-bottom-style: solid;
                                }
                            }
                        }
                    }

                }

                .scim-searchbar {
                    .p-autocomplete {
                        width: 380px;

                        .p-inputtext {
                            background-color: map-get($current-theme, theme-backgroundSecondary);
                            border-top: transparent;
                            border-left: transparent;
                            border-right: transparent;
                            width: 100%;
                            box-shadow: none;
                            height: 40px;
                            border-bottom-left-radius: 0;
                            border-bottom-right-radius: 0;
                            padding-left: 40px;
                        }
                    }

                    .scim-searchfiled {
                        .scim-search-icon {
                            left: 12px;
                            top: 14px;
                        }
                    }

                    .mat-mdc-form-field {
                        .mdc-line-ripple {
                            &::before {
                                bottom: 4px;
                                border-bottom-color: map-get($current-theme, theme-borderOpaque) !important;
                            }

                            &::after {
                                bottom: 4px;
                            }
                        }
                    }
                }

                .mat-mdc-table {
                    box-shadow: none;

                    .mdc-data-table__row {
                        td {
                            font-size: 13px;
                            padding: 8px 4px !important;

                            &:first-child {
                                padding-left: 20px !important;
                            }

                            &:last-child {
                                padding-right: 20px !important;
                            }
                        }
                    }

                    .mdc-data-table__header-row {
                        th {
                            padding: 12px 4px !important;
                            font-size: 13px !important;

                            &:first-child {
                                padding-left: 20px !important;
                            }

                            &:last-child {
                                padding-right: 20px !important;
                            }
                        }
                    }
                }
            }

            // p-autocomplte - placeholder text trim
            .refertext .p-autocomplete input.p-autocomplete-input {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            // module searchbar on insides css

            .cls-modulebar {
                border: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                .cls-modulebox {
                    .mat-mdc-form-field {
                        .mat-mdc-text-field-wrapper {
                            .mat-mdc-form-field-flex {
                                .mat-mdc-form-field-infix {
                                    padding: 7px 15px;
                                    min-height: auto;
                                }
                            }

                            .mdc-line-ripple {
                                &::before {
                                    border: 0;
                                }

                                &::after {
                                    border: 0;
                                }

                            }
                        }
                    }
                }
            }

            .cls-pass-protected {
                .mat-mdc-form-field-infix {
                    .mat-mdc-input-element {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                        border-top: transparent;
                        border-left: transparent;
                        border-right: transparent;
                        width: 100%;
                        box-shadow: none;
                        height: 48px;
                        border-bottom-left-radius: 0;
                        border-bottom-right-radius: 0;
                        padding-left: 44px;
                        font-size: 14px !important;
                    }

                    .pass-protected-search {
                        top: 42px;
                        left: 16px;
                    }
                }

                mat-form-field {
                    .mdc-line-ripple {
                        &::before {
                            border-bottom-color: map-get($current-theme, theme-borderOpaque) !important;
                        }
                    }
                }

                .cls-module-select {
                    width: 28%;

                    .mat-mdc-form-field-infix {
                        padding-top: 26px;
                        padding-bottom: 18px;
                    }

                    .mdc-line-ripple {
                        &::before {
                            bottom: 4px;
                            border-bottom-color: map-get($current-theme, theme-borderOpaque) !important;
                        }

                        &::after {
                            bottom: 4px;
                        }
                    }
                }
            }

            // share cv chip
            .rounded-chip {

                .autoCompleteOne {
                    &.greychip {
                        &.p-autocomplete {
                            .p-autocomplete-multiple-container {
                                .p-autocomplete-token {
                                    background-color: map-get($current-theme , theme-backgroundTertiary) !important;
                                    border-radius: 24px;
                                    line-height: 20px;
                                }
                            }
                        }
                    }

                }
            }

            // search result tab css

            .cls-anchglobal-tab {
                &.mat-mdc-tab-group {
                    .mat-mdc-tab-header {
                        border-bottom: 0px solid map-get($current-theme, theme-borderOpaque) !important;

                        .mat-mdc-tab-label-container {
                            .mat-mdc-tab-list {
                                .mat-mdc-tab-labels {
                                    border-bottom: 2px solid map-get($current-theme, theme-borderOpaque) !important;
                                    height: 40px;

                                    .mat-mdc-tab.mdc-tab {
                                        height: 40px;
                                    }
                                }
                            }
                        }
                    }

                }
            }


            // Team search
            .teamFilter {
                .no-border-autocomplete {
                    &.multichips {
                        .p-autocomplete-multiple {
                            .p-autocomplete-multiple-container {
                                height: 32px;
                                overflow-y: auto;
                                max-width: 515px;

                                .p-autocomplete-input-token input {
                                    padding-bottom: 0;
                                }

                                .p-autocomplete-token {
                                    border: 1px solid map-get($current-theme, theme-borderOpaque);
                                    margin-right: 10px;
                                    margin-bottom: 5px;
                                    border-radius: 3px;
                                    background: map-get($current-theme, theme-backgroundPrimary) !important;
                                    color: map-get($current-theme, theme-contentPrimary) !important;
                                    // max-width: 30%;

                                    .p-autocomplete-token-label,
                                    .p-autocomplete-token-icon {
                                        background: map-get($current-theme, theme-backgroundPrimary) !important;
                                        color: map-get($current-theme, theme-contentPrimary) !important;

                                    }
                                }
                            }
                        }
                    }

                }
            }

            // team new profile L1 menu design css
            .cls-sidemenu {
                .menus {
                    .sidebar-menu-newprofile {
                        .module_list {
                            a {

                                &.active,
                                &:hover {
                                    background-color: map-get($current-theme, theme-primaryAccent);
                                    color: map-get($current-theme, theme-primary);
                                    font-weight: bold;

                                }
                            }
                        }
                    }
                }
            }

            .cls-submenubox {
                li {

                    .menu-txt {
                        span {
                            color: map-get($current-theme , theme-contentPrimary);
                        }
                    }

                    &.selected {
                        .menu-txt {

                            background-color: map-get($current-theme , theme-backgroundPrimary);
                            border-radius: 4px;

                            span {
                                color: map-get($current-theme , theme-primary);

                            }
                        }
                    }
                }
            }

            .cls-tabbox {
                li.check {
                    color: map-get($current-theme , theme-contentPrimary);
                }

                li.selected {

                    background-color: map-get($current-theme , theme-backgroundPrimary);
                    border-radius: 4px;

                    span {
                        color: map-get($current-theme , theme-primary);
                        font-weight: 700 !important;
                    }
                }

            }

            // recruiter screen new - mat menu design 
            .recruit-dropdown {

                .mat-mdc-menu-content {
                    padding: 0;
                    max-height: calc(100vh - 45vh);

                    &:last-child {
                        padding-bottom: 0;
                    }
                }

                box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
                min-width: 230px;
                @extend .border-opaque;
                background-color: map-get($current-theme, theme-backgroundPrimary) !important;
                border-radius: 8px;

                svg {
                    display: none;
                }

                .mat-mdc-menu-item {
                    height: 38px;

                    &:hover {
                        @extend .bg-Secondary;
                    }

                    .mdc-list-item__primary-text {
                        font-size: 13px;
                        font-weight: 500;
                        height: auto;
                        line-height: 20px;
                    }

                    &.mat-mdc-menu-item-submenu-trigger {
                        padding-right: 16px;
                        padding-left: 16px;
                    }
                }

                &.withicon {
                    .mat-mdc-menu-item {
                        &:after {
                            display: block;
                            @extend .contentPrimary;
                            font-family: "Font Awesome 6 Sharp";
                            content: "\f054";
                            font-size: 11px;
                        }
                    }
                }

            }

            // final clearance sidebar css
            .cls-cardbox {
                .Rectdiv {
                    background-color: map-get($current-theme , theme-backgroundSecondary);

                    .Rectdiv-heading {

                        color: map-get($current-theme , theme-contentPrimary);

                        background-color: map-get($current-theme , theme-backgroundTertiary);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }

            // warning note
            .warning-note {
                border-left: 3px solid map-get($current-theme, theme-warning);
            }

            // P-timeline css on attandace calander

            .cls-timelinebox {
                width: 95%;

                .p-timeline-event {
                    .p-timeline-event-separator {

                        .p-timeline-event-marker {
                            background-color: map-get($current-theme , theme-contentOpaque);
                            width: 8px;
                            height: 8px;
                        }

                        .p-timeline-event-connector {
                            width: 1px;
                        }

                    }

                    .p-timeline-event-content {

                        flex: 0 0 100%;
                    }
                }
            }

            .height-set {
                .mat-mdc-form-field {
                    .mat-mdc-text-field-wrapper {
                        .mat-mdc-form-field-flex {
                            .mat-mdc-form-field-infix {
                                padding-top: 0 !important;
                                padding-bottom: 0 !important;
                                height: 28px;
                                min-height: auto;
                            }

                        }
                    }
                }
            }

            .filter-select-drop {
                .mat-mdc-form-field {
                    .mat-mdc-text-field-wrapper {

                        .mat-mdc-form-field-flex {
                            .mat-mdc-form-field-infix {
                                padding-top: 20px !important;

                                .mat-mdc-floating-label mat-label {
                                    font-size: 0.875rem;
                                }
                            }

                            .mat-mdc-floating-label {
                                top: 24px;
                            }
                        }
                    }
                }
            }


            .cls-main-heading {
                color: map-get($current-theme , theme-contentPrimary);
            }

            .social-wall-accordian {

                .mat-expansion-panel-spacing:last-child,
                >*:last-child:not(.mat-expansion-panel) .mat-expansion-panel-spacing {
                    margin-bottom: 24px !important;
                }

                .mat-expansion-panel {
                    @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    overflow: visible;

                    &:not([class*="mat-elevation-z"]) {
                        background-color: map-get($current-theme, theme-backgroundPrimary);
                        box-shadow: 0px 3px 4px rgba(56, 56, 56, 0.1) !important;
                        border: 0 !important;
                    }

                    @include margin(left, top, right, bottom, 0, 0, 0, 24);
                    @include padding(left, top, right, bottom, 16, 18, 16, 18);
                    @include border-radius(4px);

                    .mat-expansion-panel-header-description,
                    .mat-expansion-indicator::after {
                        color: #2E2E2E;
                        vertical-align: super !important;
                        margin-top: 3px;
                    }

                    .mat-expansion-panel-body {
                        @include padding(left, top, right, bottom, 0, 0, 0, 0);
                    }

                    .mat-expansion-panel-header {
                        @include padding(left, top, right, bottom, 0, 0, 0, 0);
                        align-items: start;
                        height: auto;

                        .acc-icon {
                            font-size: $heading-extra-small-font-size;
                            @include margin(left, top, right, bottom, 0, 0, 8, 0);
                            @include padding(left, top, right, bottom, 8, 8, 8, 8);
                            @include border-radius(4px);
                        }

                        img {
                            @include margin(left, top, right, bottom, 0, 0, 8, 0);
                            width: 28px;
                        }
                    }
                }

            }

            // dropdown --  custom generate challan -- hr desk
            .cls-pfdropdown {
                .p-dropdown {
                    border: 1px solid map-get($current-theme, theme-primary);

                    &:not(.p-disabled).p-focus {
                        box-shadow: none;
                    }

                    .p-dropdown-trigger {
                        @extend .primaryTxt;
                        width: auto;
                        @include padding(left, top, right, bottom, 0, 0, 16, 0);

                        .pi {
                            font-size: 13px;
                        }
                    }

                    .p-dropdown-label.p-placeholder {
                        font-family: $font-family;
                        font-size: 13px;
                        font-weight: map-get($font-weights, "semi-bold");
                        @extend .primaryTxt;
                        @include padding(left, top, right, bottom, 16, 10, 10, 10);
                    }

                    .p-dropdown-panel {
                        margin-top: 10px;

                        .p-dropdown-items-wrapper {
                            .p-dropdown-items {
                                .p-dropdown-item-group {
                                    font-family: $font-family;
                                    font-size: $text-medium-size;
                                    font-weight: map-get($font-weights, "bold");
                                    @extend .contentPrimary;
                                    @include background-color;
                                    @include padding(left, top, right, bottom, 14, 12, 14, 5);
                                }

                                .p-dropdown-item {
                                    font-family: $font-family;
                                    font-size: $text-medium-size;
                                    font-weight: map-get($font-weights, "regular");
                                    @extend .contentPrimary;

                                    &:hover {
                                        @include background-color(#f7f7f7);
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .custom-pointermap {

                .gm-style .gm-style-iw-c {
                    @include border-radius(2px);
                    padding: 0;
                    @extend .bgPrimaryAccent;
                    box-shadow: 0px 1px 3px 1px #00000026;
                }

                .gm-style-iw-d {
                    overflow-x: hidden !important;
                    overflow-y: auto !important;
                }

                .gm-style-iw-tc::after {
                    @extend .bgPrimaryAccent;
                }

                .gm-style-iw-chr {
                    display: none;
                }
            }

            .custom-mapinfo {
                @include padding(left, top, right, bottom, 16, 16, 16, 16);
                font-family: $font-family;
            }

            // candidate search page shadow
            .boxshadow-bottom {
                .p-sidebar-header {
                    position: relative;
                    z-index: 9;
                    box-shadow: 0px 1px 3px 0px #0000004D;
                }

            }

            // onboarding checklist accordian
            .mat-accordion.onboarding-check mat-expansion-panel {
                background-color: transparent;
                @extend .border-opaque;
                margin-bottom: 8px;
                @extend .border-radius4;

                .mat-expansion-panel-body {
                    @include padding(left, top, right, bottom, 34, 16, 34, 16);
                }

                mat-expansion-panel-header {
                    border: 0;
                    border-radius: 0;
                    @include margin(left, top, right, bottom, 16, 0, 16, 0);
                    @include padding(left, top, right, bottom, 0, 0, 0, 0);

                    &.mat-expanded {
                        @extend .border-opaque_bottom;
                        height: 54px;
                    }

                    .mat-expansion-panel-header-title {
                        justify-content: space-between;
                        margin-right: 0px;
                    }
                }

            }

            //  bulk candidate page 
            .mat-accordion.guarantor-detail {
                &.candidate-bulk {
                    .mat-expansion-panel {
                        .mat-expansion-panel-header {
                            height: 48px;

                            .mat-expansion-indicator {
                                margin-top: 0px;
                            }
                        }

                        .mat-expanded {
                            background-color: map-get($current-theme, theme-primaryAccent) !important;
                            border-top: 0 !important;
                        }
                    }
                }
            }

            .cls-galleria {
                &.onboard {
                    box-shadow: 0px 1px 3px 0px #00000029;

                    .p-galleria-thumbnail-container {
                        @extend .border-radius4;
                        padding: 0;
                    }

                    .p-button {
                        background: transparent;
                        border: 1px solid transparent;
                        width: auto;
                        padding: 0;
                        height: 15px;

                        .p-button-icon-only {
                            width: auto;
                            padding: 0;

                        }

                        .pi {
                            font-size: 10px;
                            @extend .contentPrimary;
                        }
                    }

                    .custom-galleria-footer {
                        text-align: right;
                        @extend .border-radius4;
                    }

                    .p-galleria-footer {
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        @extend .bg-primary-color;
                        border-radius: 0px 0 4px 4px;

                        .title-container {
                            margin-left: 5px;
                            margin-right: 5px;
                            font-size: 10px;
                        }
                    }
                }
            }

            .nofloat-offercode {
                &.mat-form-field-disabled {
                    .mat-mdc-floating-label {
                        top: 12px;
                    }

                }
            }


            .cls-actbox {
                &.mat-mdc-card {
                    box-shadow: none;

                    .mat-mdc-card-header {
                        background-color: map-get($current-theme, theme-backgroundSecondary);
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque);
                        padding: 12px;
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;

                        .mat-mdc-card-title {
                            line-height: normal;
                        }
                    }

                    .mat-mdc-card-content {
                        padding: 12px;

                        .cls-actchips {
                            &.mat-mdc-chip {
                                background-color: map-get($current-theme, theme-primaryAccent);
                                padding: 4px 8px;
                                height: 24px;
                                margin: 0 4px 4px 0;


                                .mdc-evolution-chip__cell {
                                    .mdc-evolution-chip__action {
                                        padding: 0;

                                        .mdc-evolution-chip__text-label {
                                            font-size: 12px;
                                            color: map-get($current-theme, theme-primary);
                                        }
                                    }
                                }
                            }
                        }

                        .cls-inactchips {
                            &.mat-mdc-chip {
                                background-color: map-get($current-theme, theme-backgroundTertiary);
                                padding: 4px 8px;
                                height: 24px;
                                margin: 0 4px 4px 0;


                                .mdc-evolution-chip__cell {
                                    .mdc-evolution-chip__action {
                                        padding: 0;

                                        .mdc-evolution-chip__text-label {
                                            font-size: 12px;
                                            color: map-get($current-theme, theme-contentPrimary);
                                        }
                                    }
                                }
                            }
                        }
                    }

                }
            }

            .cls-inactbox {
                @extend .cls-actbox;
            }

            // to hide perfect scroll x-axis scroll
            .hidex-scroll {
                .ps__rail-x {
                    display: none;
                }
            }

            .viewtask {
                .mat-form-field-appearance-outline {
                    @extend .bg-primary-color;
                }

                label,
                .mat-mdc-input-element,
                .mat-select-value-text {
                    @extend .contentPrimary;
                }
            }

            .team-proj {
                .mat-expansion-panel {
                    h3 {
                        @extend .contentPrimary;
                    }
                }
            }

            .addtask {
                .document-uploader {
                    .p-fileupload-content {
                        .uploadDiv {
                            p {
                                @extend .content-tertiary;

                                b {
                                    @extend .text-success;
                                }
                            }
                        }
                    }
                }
            }

            .taskview {
                ul {
                    li {
                        &:hover {
                            @extend .bg-Secondary;
                            @extend .border-opaque_bottom;
                            @extend .border-opaque_top;
                        }

                    }
                }
            }

            .theme-colorcheck {
                .mat-mdc-checkbox {
                    .mdc-checkbox__background {
                        background-color: map-get($current-theme, theme-primary) !important;
                    }
                }
            }

            // hide border bottom from mat-form-field
            .border-hide-bottom {
                .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
                    @include padding(left, top, right, bottom, 0, 12, 0, 0 !important);
                }

                .mat-mdc-icon-button.mat-mdc-button-base {
                    width: 18px;
                    height: 18px;
                    @include padding(left, top, right, bottom, 0, 0, 0, 0);
                }

                .mat-mdc-icon-button svg {
                    width: 16px;
                    height: 16px;
                    margin-left: 6px;
                }

                .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
                    @include padding(left, top, right, bottom, 0, 0, 0, 0 !important);
                }

                .mdc-line-ripple::before,
                .mdc-line-ripple::after {
                    border-bottom-width: 0px !important;
                }

                .with-bottomborder {
                    .mat-mdc-select {
                        .mat-mdc-select-trigger {
                            border-bottom: #e9edf2 1px solid;
                        }
                    }
                }
            }

            .header-white-tab {
                .mat-mdc-tab-header {
                    @extend .bg-primary-color;
                }
            }

            .cus-padding {
                @include padding-em(left, top, right, bottom, 0, 0, 2.142, 0);
                position: absolute;
                right: 0;
                top: 22px;
            }

            .flyer-do-tf {
                .cus-padding {
                    right: 55px;
                    top: auto;
                }

            }

            // api intergration accordian
            mat-accordion.transparentbg {
                .mat-expansion-panel {
                    background-color: transparent;

                    .mat-expansion-panel-header {
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        padding: 0 16px;
                        height: 40px;
                        @extend .border-radius4;

                        &:hover {
                            @extend .bgThemePrimary;

                            .mat-expansion-panel-header-title {
                                h3 {
                                    color: map-get($current-theme, theme-white) !important;
                                }

                            }

                            .mat-expansion-indicator::after {
                                color: map-get($current-theme, theme-white) !important;
                            }
                        }

                        &.mat-expanded {
                            height: 40px;

                            .mat-expansion-indicator {
                                margin-top: 0;
                            }
                        }

                        h3,
                        .mat-expansion-indicator::after {
                            @extend .text-success;
                        }
                    }
                }
            }

            // accent bg table
            table.accentbg {
                thead tr th {
                    @extend .bg-accent-color;
                }

                tr {
                    &:nth-child(even) {
                        td {
                            @extend .bg-accent-color;
                        }

                    }
                }

            }

            // Goal tab setup css

            .cls-goaltab-setup {
                &.mat-primary {
                    .mat-mdc-tab-header {
                        border-bottom: 1px solid map-get($current-theme, theme-borderOpaque) !important;

                        .mat-mdc-tab-label-container {
                            .mat-mdc-tab-list {
                                .mat-mdc-tab-labels {
                                    .mdc-tab {
                                        border-bottom: 0px solid transparent !important;

                                        &.mdc-tab--active {
                                            border-bottom: 2px solid map-get($current-theme, theme-borderFocus) !important;

                                            .mdc-tab__content {
                                                .mdc-tab__text-label {
                                                    color: map-get($current-theme, theme-contentPrimary) !important;
                                                    font-weight: 600 !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            // goal tab css
            .cls-goaltab {
                &.mat-primary {
                    .mat-mdc-tab-header {
                        border-bottom: 0px solid transparent !important;

                    }
                }
            }


            .nohoverbtn {

                &:hover,
                &:focus {
                    @include background-color(transparent !important);
                }
            }

            .candidate-pg {
                &.bulkpg {
                    .dowload-pdf.rightspace {
                        right: 30px;
                    }
                }

                .zoom-level {
                    bottom: 4%;
                }
            }

            .custom-pointermaptrips {
                google-map .map-container {
                    width: 100% !important;
                    height: 400px !important;
                }
            }

            .disabledframe:after {
                background: rgb(246 246 246 / 50%);
                position: absolute;
                content: "";
                height: 88%;
                width: 100%;
                z-index: 9;
                top: 45px;
                right: 0;
                left: 0;
            }

            .max-width20 {
                max-width: 135px;
            }
            .remove-empty
            {
                &:empty
                {
                    display: none;
                }
            }
            .fixedfooter
            {
                .filter-cta 
                {
                    position: sticky;
                    bottom: 0;
                    @extend .bg-primary-color;
                }
            }
            .change-pos-icon .p-menuitem-link {
                display: flex;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: space-between;
              }
              
        }

    }
}